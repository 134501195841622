
const huanjingyingxiang = [
  {
    subIssueTitle: '管理方针',
    subIssueTooltipContent: `对应GRI标准: 103, 307-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: A3.1`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '有关环境保护的管理方针及其要素，包括：a.政策；b.承诺；c.目标；d.责任；e.特定的行动（如流程、专案及倡议等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '因违反环境法律法规而受到的重大罚款和制裁',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '重大罚款货币总值及说明', key: 'ENVIRONMENTAL_INFLUENCE_1', unit: '元', yearDataList: ['', '', ''] },
          { title: '非货币制裁总数', key: 'ENVIRONMENTAL_INFLUENCE_2', unit: '件', yearDataList: ['', '', ''] },
          { title: '通过争端解决机制提起的案件数量', key: 'ENVIRONMENTAL_INFLUENCE_3', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '环境管理体系',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: A3.1`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '环境管理体系，及该管理体系所覆盖的地域范围等，并指出该管理体系是否经第三方审核或认证。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '环保能力提升',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: A3.1`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '提升员工环境保护意识、能力等相关活动，如环保培训、环保公益等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '环境保护培训',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '培训主题及次数', key: 'ENVIRONMENTAL_INFLUENCE_4', unit: '次', yearDataList: ['', '', ''] },
          { title: '培训覆盖的员工数量', key: 'ENVIRONMENTAL_INFLUENCE_5', unit: '人', yearDataList: ['', '', ''] },
          { title: '培训覆盖的员工占比', key: 'ENVIRONMENTAL_INFLUENCE_6', unit: '%', yearDataList: ['', '', ''] },
          { title: '培训投入费用', key: 'ENVIRONMENTAL_INFLUENCE_7', unit: '元', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '水资源',
    subIssueTooltipContent: `对应GRI标准: 303-1, 303-3<br>
    对应MSCI ESG指标: 水资源的压力<br>
    对应港交所ESG指引: A2.4`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '组织与水资源的互相影响，包括：a.水资源（或用水压力）对业务运营的具体影响；b.公司运营过程中求取适用水对水资源直接或间接的影响（如径流造成的影响）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: false,
      title: '水资源消耗当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '按类别统计总耗水量',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '自来水', key: 'ENVIRONMENTAL_INFLUENCE_8', unit: '吨', yearDataList: ['', '', ''] },
          { title: '海水', key: 'ENVIRONMENTAL_INFLUENCE_9', unit: '吨', yearDataList: ['', '', ''] },
          { title: '其他来源', key: 'ENVIRONMENTAL_INFLUENCE_10', unit: '吨', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述所订立的水资源使用效益目标，及为达成目标所开展的管理举措、成效及典型案例。管理举措包括：a.运营及生产等各环节开展节水措施；b.循环用水。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '物料',
    subIssueTooltipContent: `指用于生产和包装公司主要产品及服务的物料，包括原材料、外包装物料等。<br>
      对应GRI标准: 301-1, 301-2<br>
      对应MSCI ESG指标: 原材料来源，争议来源，包装材料&浪费，电子垃圾<br>
      对应港交所ESG指引: A2.5`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: false,
      title: '物料使用当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '按类别统计原材料总量',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '矿石', key: 'ENVIRONMENTAL_INFLUENCE_11', unit: '吨', yearDataList: ['', '', ''] },
          { title: '矿物', key: 'ENVIRONMENTAL_INFLUENCE_12', unit: '吨', yearDataList: ['', '', ''] },
          { title: '木材', key: 'ENVIRONMENTAL_INFLUENCE_13', unit: '吨', yearDataList: ['', '', ''] },
          { title: '其他类别', key: 'ENVIRONMENTAL_INFLUENCE_14', unit: '吨', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '按类别统计包装物料总量',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '塑料', key: 'ENVIRONMENTAL_INFLUENCE_15', unit: '吨', yearDataList: ['', '', ''] },
          { title: '纸类', key: 'ENVIRONMENTAL_INFLUENCE_16', unit: '吨', yearDataList: ['', '', ''] },
          { title: '金属', key: 'ENVIRONMENTAL_INFLUENCE_17', unit: '吨', yearDataList: ['', '', ''] },
          { title: '其他类别', key: 'ENVIRONMENTAL_INFLUENCE_18', unit: '吨', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '可回收产品及其包材总量', key: 'ENVIRONMENTAL_INFLUENCE_19', unit: '吨', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述组织采购原料对环境（如矿物对生态环境的影响）或利益相关方（如争议来源）的影响，及为降低影响而开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述组织所使用的包装物料、产品对环境或利益相关方的影响，及为降低影响而开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '能源',
    subIssueTooltipContent: `对应GRI标准: 103, 302, 302-1, 302-4, 302-5<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: A2, A2.3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '有效使用能源所遵守的国家、区域或行业等层级的能源法规或政策，及其对公司的影响。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: false,
      title: '能源使用当前期状态。',
      tooltipContent: '同时用于计算温室气体排放',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '固定源的燃料',
        tooltipContent: '固定源包括锅炉、焚烧炉等',
        taskYear: '2020',
        indicatorDataList: [{ title: '柴油', key: 'ENVIRONMENTAL_INFLUENCE_20', unit: '升', yearDataList: ['', '', ''] },
          { title: '液化石油气', key: 'ENVIRONMENTAL_INFLUENCE_21', unit: '升', yearDataList: ['', '', ''] },
          { title: '煤油', key: 'ENVIRONMENTAL_INFLUENCE_22', unit: '升', yearDataList: ['', '', ''] },
          { title: '木炭', key: 'ENVIRONMENTAL_INFLUENCE_23', unit: '吨', yearDataList: ['', '', ''] },
          { title: '煤气', key: 'ENVIRONMENTAL_INFLUENCE_24', unit: '立方米', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '移动源的燃料',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '电单车-无铅汽油', key: 'ENVIRONMENTAL_INFLUENCE_25', unit: '升', yearDataList: ['', '', ''] },
          { title: '私家车-无铅汽油', key: 'ENVIRONMENTAL_INFLUENCE_26', unit: '升', yearDataList: ['', '', ''] },
          { title: '私家车-柴油', key: 'ENVIRONMENTAL_INFLUENCE_27', unit: '升', yearDataList: ['', '', ''] },
          { title: '私人货车-无铅汽油', key: 'ENVIRONMENTAL_INFLUENCE_28', unit: '升', yearDataList: ['', '', ''] },
          { title: '私人货车-柴油', key: 'ENVIRONMENTAL_INFLUENCE_29', unit: '升', yearDataList: ['', '', ''] },
          { title: '私人货车-液化石油气', key: 'ENVIRONMENTAL_INFLUENCE_30', unit: '升', yearDataList: ['', '', ''] },
          { title: '公共小型巴士-柴油', key: 'ENVIRONMENTAL_INFLUENCE_31', unit: '升', yearDataList: ['', '', ''] },
          { title: '公共小型巴士-液化石油气', key: 'ENVIRONMENTAL_INFLUENCE_32', unit: '升', yearDataList: ['', '', ''] },
          { title: '轻型货车-无铅汽油', key: 'ENVIRONMENTAL_INFLUENCE_33', unit: '升', yearDataList: ['', '', ''] },
          { title: '轻型货车-柴油', key: 'ENVIRONMENTAL_INFLUENCE_34', unit: '升', yearDataList: ['', '', ''] },
          { title: '中型货车-柴油', key: 'ENVIRONMENTAL_INFLUENCE_35', unit: '升', yearDataList: ['', '', ''] },
          { title: '重型货车-柴油', key: 'ENVIRONMENTAL_INFLUENCE_36', unit: '升', yearDataList: ['', '', ''] },
          { title: '其他移动机器-柴油', key: 'ENVIRONMENTAL_INFLUENCE_37', unit: '升', yearDataList: ['', '', ''] },
          { title: '其他移动机器-液化石油气', key: 'ENVIRONMENTAL_INFLUENCE_38', unit: '千克', yearDataList: ['', '', ''] },
          { title: '其他移动机器-煤油', key: 'ENVIRONMENTAL_INFLUENCE_39', unit: '升', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '购买能源',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '电力', key: 'ENVIRONMENTAL_INFLUENCE_40', unit: 'MKW·h', yearDataList: ['', '', ''] },
          { title: '煤气', key: 'ENVIRONMENTAL_INFLUENCE_41', unit: '公升', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '出售能源',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '电力', key: 'ENVIRONMENTAL_INFLUENCE_40', unit: 'MKW·h', yearDataList: ['', '', ''] },
          { title: '煤气', key: 'ENVIRONMENTAL_INFLUENCE_41', unit: '公升', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述所订立的能源使用效益目标，及为达成目标所开展的管理举措、成效与典型案例，如绿色建筑、绿色照明系统等。其中，管理举措应包括：a.包括公司运营管理；b.降低产品和服务的能源。',
      tooltipContent: `披露目标时需包含以下4个元素：<br>
1）&nbsp;背景与基准年；<br>
2）目标覆盖的对象的范围及位置；<br>
3）预期结果及达成时间表；<br>
4）该目标是属于自愿还是强制（既有法例规定），若属于强制，需列明相关法例。`,
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '废气',
    subIssueTooltipContent: `对应GRI标准: 305-7<br>
      对应MSCI ESG指标: A1.1<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '废气排放所遵守的国家、区域或行业等层级的能源法规或政策，及其对公司的影响。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '废气排放当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '按类别统计汽车行驶里程',
        tooltipContent: '同时用于计算废气排放',
        taskYear: '2020',
        indicatorDataList: [{ title: '乘用车', key: 'ENVIRONMENTAL_INFLUENCE_42', unit: '公里', yearDataList: ['', '', ''] },
          { title: '轻型货车（<=2.5吨）', key: 'ENVIRONMENTAL_INFLUENCE_43', unit: '公里', yearDataList: ['', '', ''] },
          { title: '轻型货车（2.5-3.5吨）', key: 'ENVIRONMENTAL_INFLUENCE_44', unit: '公里', yearDataList: ['', '', ''] },
          { title: '轻型货车（3.5-5.5吨）', key: 'ENVIRONMENTAL_INFLUENCE_45', unit: '公里', yearDataList: ['', '', ''] },
          { title: '中型及重型货车（5.5-15吨）', key: 'ENVIRONMENTAL_INFLUENCE_46', unit: '公里', yearDataList: ['', '', ''] },
          { title: '中型及重型货车（>=15吨）', key: 'ENVIRONMENTAL_INFLUENCE_47', unit: '公里', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述所订立的减少废气排放的目标，及为达成目标所开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '温室气体',
    subIssueTooltipContent: `对应GRI标准: 305-1, 305-2, 305-3, 305-5<br>
      对应MSCI ESG指标: A1.2, A1.5<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述气候变化相关风险对业务直接或潜在的影响，及应对措施、成效及典型案例。气候变化相关风险对业务直接或潜在的影响，包括：a.风暴、火灾、海平面上升等实体风险；b.政策、技术、法律、市场、声誉等过程风险。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '温室气体排放当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '直接温室气体排放（范畴一）',
        tooltipContent: '范畴一：涵盖由公司拥有或控制的业务直接产生的温室气体排放',
        taskYear: '2020',
        indicatorDataList: [{ title: '新种植数目的数量', key: 'ENVIRONMENTAL_INFLUENCE_48', unit: '棵', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述所订立的减少温室气体排放的目标，及为达成目标所开展的管理举措、成效与典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '污水',
    subIssueTooltipContent: `对应GRI标准: 303-2, 303-4<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '污水所遵守的国家、区域或行业等层级的能源法规或政策，及其对公司的影响。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: false,
      title: '污水排放当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '废水排放量', key: 'ENVIRONMENTAL_INFLUENCE_49', unit: '吨', yearDataList: ['', '', ''] },
          { title: '化学需氧量（COD）', key: 'ENVIRONMENTAL_INFLUENCE_50', unit: '吨', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '为减少污水排放所开展的管理举措、成效及典型案例。减少污水包括：a.直接减少污水排放总量；b.提升污水循环利用。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '废弃物',
    subIssueTooltipContent: `对应GRI标准: 306-2, 306-3, 306-4<br>
      对应MSCI ESG指标: 有毒排放物&浪费<br>
      对应港交所ESG指引: A1.3, A1.4, A1.6`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述组织产生的主要废弃物类别，废弃物管理所遵守的国家、区域或行业等层级的能源法规或政策。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: false,
      title: '废弃物当前期状态。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '有害废弃物总量',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '废硒鼓', key: 'ENVIRONMENTAL_INFLUENCE_51', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '废电池等电子废弃物', key: 'ENVIRONMENTAL_INFLUENCE_52', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '废日光灯', key: 'ENVIRONMENTAL_INFLUENCE_53', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '其他有害废弃物（可自行填写所适用的指标）', key: 'ENVIRONMENTAL_INFLUENCE_54', unit: 'KG', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '无害废弃物总量',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '纸类', key: 'ENVIRONMENTAL_INFLUENCE_55', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '厨余垃圾', key: 'ENVIRONMENTAL_INFLUENCE_56', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '塑料', key: 'ENVIRONMENTAL_INFLUENCE_57', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '金属', key: 'ENVIRONMENTAL_INFLUENCE_58', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '木制品', key: 'ENVIRONMENTAL_INFLUENCE_59', unit: 'KG', yearDataList: ['', '', ''] },
          { title: '其他无害废弃物（可自行填写所适用的指标）', key: 'ENVIRONMENTAL_INFLUENCE_60', unit: 'KG', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '减少有害废弃物的目标，及为达成目标所开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '违规事件数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '因重大泄露、不当处置而受到的投诉、罚款或处罚的事件数量', key: 'ENVIRONMENTAL_INFLUENCE_61', unit: '件', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '减少无害废弃物的目标，及为达成目标所开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '违规事件数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '因不当处置而受到的投诉、罚款或处罚的事件数量', key: 'ENVIRONMENTAL_INFLUENCE_62', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '生物多样性保护',
    subIssueTooltipContent: `对应GRI标准: 304-1, 304-2, 304-3<br>
      对应MSCI ESG指标: 生物多样性&土地利用率<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '活动、产品和服务队生物多样性的影响（正面和负面），及为减少负面影响所开展的管理举措、成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default huanjingyingxiang
