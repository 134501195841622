import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  //  IPO问询函-搜函件
  ipo_inquiry_letters_search(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_letters_search, data: params })
  },
  // IPO问询函-搜问答
  ipo_inquiry_question_answer(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_question_answer, data: params })
  },
  // IPO问询函-检索条件
  ipo_inquiry_conditions() {
    return Axios({ method: 'GET', url: api.ipoInquiryLetters.ipo_inquiry_conditions })
  },
  // 获取IPO问询函-函件所有标签
  get_all_ipo_inquiry_tags() {
    return Axios({ method: 'GET', url: api.ipoInquiryLetters.get_all_ipo_inquiry_tags })
  },
  // 获取IPO问询函-问答所有标签
  get_all_qa_ipo_inquiry_tags() {
    return Axios({ method: 'GET', url: api.ipoInquiryLetters.get_all_qa_ipo_inquiry_tags })
  },
  // IPO问询函-检索条件公司联想接口
  ipo_inquiry_company_complete(params) {
    return Axios({ method: 'GET', url: api.ipoInquiryLetters.ipo_inquiry_company_complete, params })
  },
  // IPO问询函 函件详情数据
  ipo_inquiry_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_detail, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_detail })
  },
  // IPO问询函 问答详情数据
  ipo_inquiry_qa_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_qa_detail, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_qa_detail })
  },
  // IPO问询函 函件 进程
  ipo_inquiry_progress(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_progress, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_progress })
  },
  // IPO问询函 函件文件
  ipo_inquiry_progress_doc(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_progress_doc, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_progress_doc })
  },
  // IPO问询函 函件文件下载
  ipo_inquiry_download(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_download, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_download })
  },
  // IPO问询函 函件压缩包下载
  ipo_inquiry_zip_download(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoInquiryLetters.ipo_inquiry_zip_download, [['id', id]]), originUrl: api.ipoInquiryLetters.ipo_inquiry_zip_download })
  },
  // 统计分析 问题分布
  ipo_inquiry_question_distribution(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_question_distribution, data: params })
  },
  // 统计分析 被问询公司列表
  ipo_inquiry_statistical_company(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_statistical_company, data: params })
  },
  // IPO问询函  函件查询摘要
  ipo_inquiry_dashboard_search_abstract(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_dashboard_search_abstract, data: params })
  },
  // IPO问询函  统计分析 被问询公司总数
  ipo_inquiry_statistical_company_total(params) {
    return Axios({ method: 'POST', url: api.ipoInquiryLetters.ipo_inquiry_statistical_company_total, data: params })
  }
}
