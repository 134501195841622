import Api from '@/service/api'

export default {
  error500: `${Api.aliyunDomain}xiaoan/web_static/prompt/error500.png`,
  loading: `${Api.aliyunDomain}xiaoan/web_static/prompt/loading.gif`,
  nocollect: `${Api.aliyunDomain}xiaoan/web_static/prompt/no_collect.png`,
  noInfo: `${Api.aliyunDomain}xiaoan/web_static/prompt/no_info.png`,
  nowrite: `${Api.aliyunDomain}xiaoan/web_static/prompt/no_write.png`,
  searchNull: `${Api.aliyunDomain}xiaoan/web_static/prompt/search_null.png`,
  shareClose: `${Api.aliyunDomain}xiaoan/web_static/prompt/share_close.png`,
  questionIcon: `${Api.aliyunDomain}xiaoan/web_static/prompt/prompt_question_icon.png`,
  clue: `${Api.aliyunDomain}xiaoan/web_static/prompt/prompt_clue.png`,
  search: `${Api.aliyunDomain}xiaoan/web_static/prompt/prompt_search.png`
}
