import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 创建会议
  meetings_init(params, type) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.meetings_init, [['type', type]]), params: params, originUrl: api.meetingmanagement.meetings_init })
  },
  // 删除会议
  delete_meeting_by_id(params, meetingId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.operate_meeting, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.operate_meeting })
  },
  // 获取会议类型
  get_meeting_type_options(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_type_options, [['companyId', companyId]]), params: params, originUrl: api.meetingmanagement.get_meeting_type_options })
  },
  // 获取会议类型搜索条件
  meeting_type_search_condition() {
    return Axios({ method: 'GET', url: api.meetingmanagement.meeting_type_search_condition })
  },
  // 保存会议信息
  save_meeting_info(params, meetingId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.save_meeting_info, [['meetingId', meetingId]]), data: params, originUrl: api.meetingmanagement.save_meeting_info })
  },
  // 查询进行中的会议
  get_ongoing_meetings(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_ongoing_meetings, [['companyId', companyId]]), params: params, originUrl: api.meetingmanagement.get_ongoing_meetings })
  },
  // 查询全部会议
  search_meetings(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.search_meetings, data: params })
  },
  // 查询会议详情
  get_meeting_detail(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.operate_meeting, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.operate_meeting })
  },
  // 查询三会统计数据
  get_meeting_statistic_info(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_statistic_info, [['companyId', companyId]]), params: params, originUrl: api.meetingmanagement.get_meeting_statistic_info })
  },
  // 获取会议文件状态
  meeting_file_status(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meeting_file_status, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.meeting_file_status })
  },
  // 根据会议id获取文件列表
  get_meeting_files(meetingId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_files, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.get_meeting_files })
  },
  // 删除文件
  delete_meeting_file(params, fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.operate_meeting_file, [['fileId', fileId]]), params: params, originUrl: api.meetingmanagement.operate_meeting_file })
  },
  // 导出文件
  derive_meeting_file(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.derive_meeting_file, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.derive_meeting_file })
  },
  // 生成/更新文件
  meeting_files(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meeting_files, data: params })
  },
  // 生成出席情况报告
  generate_dong_jian_gao_analysis_file(params) {
    return Axios({ method: 'GET', url: api.meetingmanagement.generate_dong_jian_gao_analysis_file, params: params })
  },
  // 查询企业邮箱
  get_company_mail_box_setting_info(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.company_mail_box_setting_info, [['companyId', companyId]]), params: params, originUrl: api.meetingmanagement.company_mail_box_setting_info })
  },
  // 邮箱测试
  test_mail_box(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.test_mail_box, data: params })
  },
  // 发送邮箱验证码
  send_mailboxes_verify_code(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.send_mailboxes_verify_code, data: params })
  },
  // 设置默认发件人邮箱
  default_mailboxes_settings(companyId, mailBoxId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.default_mailboxes_settings, [['companyId', companyId], ['mailBoxId', mailBoxId]]), originUrl: api.meetingmanagement.default_mailboxes_settings })
  },
  // 保存邮箱
  update_company_mail_box_setting_info(params, companyId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.company_mail_box_setting_info, [['companyId', companyId]]), data: params, originUrl: api.meetingmanagement.company_mail_box_setting_info })
  },
  // 删除邮箱
  delete_company_mail_box_setting_info(params, companyId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.company_mail_box_setting_info, [['companyId', companyId]]), params: params, originUrl: api.meetingmanagement.company_mail_box_setting_info })
  },
  // 查看定时发送计划
  get_schedule_notify_info(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_schedule_notify_info, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.get_schedule_notify_info })
  },
  // 删除定时发送计划
  delete_schedule_notify_time(meetingId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.delete_schedule_notify_time, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.delete_schedule_notify_time })
  },
  // 修改定时发送时间
  update_schedule_notify_time(params, meetingId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.update_schedule_notify_time, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.update_schedule_notify_time })
  },
  // 获取通知发送记录
  get_sent_message_notify_info(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_sent_message_notify_info, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.get_sent_message_notify_info })
  },
  // 更新会议状态
  update_meeting_phrase(params, meetingId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.update_meeting_phrase, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.update_meeting_phrase })
  },
  // 查询企业管理成员是否变动
  check_enterprise_personnel_changes(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.check_enterprise_personnel_changes, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.check_enterprise_personnel_changes })
  },
  // 查询发送通知草稿
  get_meeting_message_notify(params, companyId, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_message_notify, [['companyId', companyId], ['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.get_meeting_message_notify })
  },
  // 查询出席和列席人员详细信息
  get_participant_info(params, companyId, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_participant_info, [['companyId', companyId], ['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.get_participant_info })
  },
  // 保存发送通知内容
  save_meeting_message_notify_info(params, meetingId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.save_meeting_message_notify_info, [['meetingId', meetingId]]), data: params, originUrl: api.meetingmanagement.save_meeting_message_notify_info })
  },
  // 获取三会模板
  get_meeting_template(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_template, [['companyId', companyId]]), originUrl: api.meetingmanagement.get_meeting_template })
  },
  // 检查三会模板是否重名
  check_meeting_template(institutionId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.check_meeting_template, [['institutionId', institutionId]]), params, originUrl: api.meetingmanagement.check_meeting_template })
  },
  // 三会模板重命名
  rename_meeting_template(fileId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.meeting_template_files, [['fileId', fileId]]), data: params, originUrl: api.meetingmanagement.meeting_template_files })
  },
  // 删除三会模板
  delete_meeting_template(fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.meeting_template_files, [['fileId', fileId]]), originUrl: api.meetingmanagement.meeting_template_files })
  },
  // 创建空白文档
  open_blank_meeting_template(institutionId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.open_blank_meeting_template, [['institutionId', institutionId]]), params, originUrl: api.meetingmanagement.open_blank_meeting_template })
  },
  // 三会带后缀的文件重命名
  rename_meeting_file(fileId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.operate_meeting_file, [['fileId', fileId]]), data: params, originUrl: api.meetingmanagement.operate_meeting_file })
  },
  // 用三会模板发起电子签名
  initiate_signature(meetingId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.initiate_signature, [['meetingId', meetingId]]), data, originUrl: api.meetingmanagement.initiate_signature })
  },
  // 获取三会签署文件信息
  get_meeting_signature_info(params) {
    return Axios({ method: 'GET', url: api.meetingmanagement.get_meeting_signature_info, params, originUrl: api.meetingmanagement.get_meeting_signature_info })
  },
  // 导出全部签署文件
  export_meeting_signature_info(meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.export_meeting_signature_info, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.export_meeting_signature_info })
  },
  // 模板设置-模板状态切换
  meeting_file_switch(params, fileId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.meeting_file_switch, [['fileId', fileId]]), originUrl: api.meetingmanagement.meeting_file_switch, params: params })
  },
  // 恢复至初始模板
  recover_meeting_template(fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.recover_meeting_template, [['fileId', fileId]]), originUrl: api.meetingmanagement.recover_meeting_template })
  },
  // 校验是否符合交易日规则
  check_trade_date_rules(params, orgId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.check_trade_date_rules, [['orgId', orgId]]), params, originUrl: api.meetingmanagement.check_trade_date_rules })
  },
  // 股东大会重命名议案文件名称
  meetings_proposal_file(params, fileId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.meetings_proposal_file, [['fileId', fileId]]), data: params, originUrl: api.meetingmanagement.meetings_proposal_file })
  },
  // 获取股东大会召开会议tab页信息
  get_hold_meeting(meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.stockholder_meeting_hold_meeting, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.stockholder_meeting_hold_meeting })
  },
  // 保存股东大会召开会议tab页信息
  save_hold_meeting(meetingId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.stockholder_meeting_hold_meeting, [['meetingId', meetingId]]), data: params, originUrl: api.meetingmanagement.stockholder_meeting_hold_meeting })
  },
  // 导出表决统计
  vote_count_export(meetingId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.vote_count_export, [['meetingId', meetingId]]), data: params, originUrl: api.meetingmanagement.vote_count_export })
  },
  // 更新议案文件顺序
  proposal_sequence(meetingId, params, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.proposal_sequence, [['meetingId', meetingId]]), params, data, originUrl: api.meetingmanagement.proposal_sequence })
  },
  // 获取当前公司所有的文件夹
  meetings_get_file_folder(meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_get_file_folder, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.meetings_get_file_folder })
  },
  // 保存、编辑文件夹
  meetings_update_file_folder(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meetings_update_file_folder, data: params })
  },
  // 删除文件夹
  meetings_delete_file_folder(folderId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.meetings_delete_file_folder, [['folderId', folderId]]), originUrl: api.meetingmanagement.meetings_delete_file_folder })
  },
  // 移动文件到文件夹
  meetings_move_file_folder(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meetings_move_file_folder, data: params })
  },
  // 获取上一次分析记录信息
  meetings_org_last_analysis_record(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_org_last_analysis_record, [['organizationId', organizationId]]), originUrl: api.meetingmanagement.meetings_org_last_analysis_record })
  },
  // 导入历史会议
  meetings_org_import(orgId, fileAnalysisId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_org_import, [['orgId', orgId], ['fileAnalysisId', fileAnalysisId]]), originUrl: api.meetingmanagement.meetings_org_import })
  },
  // 获取解析后的错误信息结果
  meetings_analysis_error_result(fileAnalysisId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_analysis_error_result, [['fileAnalysisId', fileAnalysisId]]), originUrl: api.meetingmanagement.meetings_analysis_error_result })
  },
  // 获取某次分析对应生成的会议集合
  meetings_analysis_meetings(params, analysisId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_analysis_meetings, [['analysisId', analysisId]]), params: params, originUrl: api.meetingmanagement.meetings_analysis_meetings })
  },
  // 获取某次分析对应生成的会议集合
  meetings_import_meeting_detail(meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_import_meeting_detail, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.meetings_import_meeting_detail })
  },
  // 复制会议
  meetings_copy_meeting(meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_copy_meeting, [['meetingId', meetingId]]), originUrl: api.meetingmanagement.meetings_copy_meeting })
  },
  // 分页查询当前公司的历史导入信息
  meetings_analysis_record(params, organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_analysis_record, [['organizationId', organizationId]]), params: params, originUrl: api.meetingmanagement.meetings_analysis_record })
  },
  // 删除导入失败的分析记录
  delete_meetings_analysis(analysisId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.delete_meetings_analysis, [['analysisId', analysisId]]), originUrl: api.meetingmanagement.delete_meetings_analysis })
  },
  // 校验邮箱是否存在
  meeting_contact_user_check_email(organizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meeting_contact_user_check_email, [['organizationId', organizationId]]), params: params, originUrl: api.meetingmanagement.meeting_contact_user_check_email })
  },
  // 获取三会联系人列表
  meeting_contact_user(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.get_meeting_contact_users, [['organizationId', organizationId]]), originUrl: api.meetingmanagement.get_meeting_contact_users })
  },
  // 保存三会联系人
  add_meeting_contact_user(organizationId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.add_meeting_contact_user, [['organizationId', organizationId]]), data: params, originUrl: api.meetingmanagement.add_meeting_contact_user })
  },
  // 删除三会联系人
  delete_meeting_contact_user(organizationId, userId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.operate_meeting_contact_user, [['organizationId', organizationId], ['userId', userId]]), originUrl: api.meetingmanagement.operate_meeting_contact_user })
  },
  // 更新三会联系人
  update_meeting_contact_user(organizationId, userId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.operate_meeting_contact_user, [['organizationId', organizationId], ['userId', userId]]), params: params, originUrl: api.meetingmanagement.operate_meeting_contact_user })
  },
  // 查询该企业的三会会议召开方式集合
  get_meeting_convene_form(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meeting_convene_form, [['organizationId', organizationId]]), originUrl: api.meetingmanagement.meeting_convene_form })
  },
  // 自定义三会会议召开方式
  update_meeting_convene_form(organizationId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.meetingmanagement.meeting_convene_form, [['organizationId', organizationId]]), data: params, originUrl: api.meetingmanagement.meeting_convene_form })
  },
  // 获取三会模板
  meetings_template_sms(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_template_sms, [['organizationId', organizationId]]), originUrl: api.meetingmanagement.meetings_template_sms })
  },
  // 保存短信模板
  update_meetings_template_files(templateSmsId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.update_meetings_template_files, [['templateSmsId', templateSmsId]]), data: params, originUrl: api.meetingmanagement.update_meetings_template_files })
  },
  // 导出文件
  meetings_files_export_running_task(params, meetingId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_files_export_running_task, [['meetingId', meetingId]]), params: params, originUrl: api.meetingmanagement.meetings_files_export_running_task })
  },
  // 文档管理获取三会文件接口
  meeting_document_management_files() {
    return Axios({ method: 'GET', url: api.meetingmanagement.meeting_document_management_files })
  },
  // 保存会议文件
  meeting_save_files(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meeting_save_files, data: params })
  },
  // 保存模板文件
  meeting_template_files_creation(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meeting_template_files_creation, data: params })
  },
  // 保存解析文件
  meeting_analysis_files_creation(params) {
    return Axios({ method: 'POST', url: api.meetingmanagement.meeting_analysis_files_creation, data: params })
  },
  save_mail_attachments(meetingId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.save_mail_attachments, [['meetingId', meetingId]]), data, originUrl: api.meetingmanagement.save_mail_attachments })
  },
  add_meeting_proposal_files(meetingId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.meetingmanagement.add_meeting_proposal_files, [['meetingId', meetingId]]), data, originUrl: api.meetingmanagement.add_meeting_proposal_files })
  },
  delete_proposal_file(meetingId, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.meetingmanagement.delete_proposal_file, [['meetingId', meetingId]]), params, originUrl: api.meetingmanagement.delete_proposal_file })
  },
  // 获取模板是否有更新
  meetings_template_files_has_update(fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_template_files_has_update, [['fileId', fileId]]), originUrl: api.meetingmanagement.meetings_template_files_has_update })
  },
  // 获取初始模板信息
  meetings_system_template_files_preview(fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.meetingmanagement.meetings_system_template_files_preview, [['fileId', fileId]]), originUrl: api.meetingmanagement.meetings_system_template_files_preview })
  }
}
