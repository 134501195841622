import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 问询函-搜函件
  inquiry_letters_search_v2(params) {
    return Axios({ method: 'POST', url: api.inquiryLetters.inquiry_letters_search_v2, data: params })
  },
  // 问询函件查询 用于PC端/H5问询函件检索
  inquiry_letters_search(params) {
    return Axios({ method: 'POST', url: api.inquiryLetters.inquiry_letters_search, data: params })
  },
  // 问询问答查询 用于PC端/H5问询函问答检索
  inquiry_question_answer_v3(params) {
    return Axios({ method: 'POST', url: api.inquiryLetters.inquiry_question_answer_v3, data: params })
  },
  // 问询函-筛选条件
  inquiry_letters_condition() {
    return Axios({ method: 'GET', url: api.inquiryLetters.inquiry_letters_condition })
  },
  // 问询函件详情
  inquiry_letters_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.inquiryLetters.inquiry_letters_detail, [['id', id]]), originUrl: api.inquiryLetters.inquiry_letters_detail })
  },
  // 问询函件问答详情
  get_inquiry_question_answer_detail(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.inquiryLetters.get_inquiry_question_answer_detail, [['id', id]]), originUrl: api.inquiryLetters.get_inquiry_question_answer_detail, params })
  },
  // 获取问询函所有标签
  get_all_inquiry_tags() {
    return Axios({ method: 'GET', url: api.inquiryLetters.get_all_inquiry_tags })
  },
  // 获取问询函搜问答所有标签
  get_all_qa_inquiry_tags() {
    return Axios({ method: 'GET', url: api.inquiryLetters.get_all_qa_inquiry_tags })
  },
  // 问询详下载
  inquiry_letters_download(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.inquiryLetters.inquiry_letters_download, [['id', id]]), originUrl: api.inquiryLetters.inquiry_letters_download })
  }
}
