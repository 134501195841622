<!--
 * @Description: 选项卡切换组件
-->
<template>
  <div>
    <el-tabs v-model="activeTab" @tab-click="handleClick" v-loading="loading" :before-leave="beforeLeaveFun">
      <el-tab-pane
        v-for="tab in tabList"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
        :link="tab.link ? tab.link : ''"
      >
        <xa-tooltip
          slot="label"
          :content="tab.tooltipContent"
          :hidePopper="!tab.tooltipContent"
          customClass="vxa-tab-pane-tooltip"
        >
          <span>{{ tab.label }}</span>
        </xa-tooltip>
        <slot :name="tab.name"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: 'VxaTab',
  data: function() {
    return {
      activeTab: this.value
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    // tablist存储tab列表的信息，每个tab必须包含name和label，可以包含转跳链接(link)
    tabList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    beforeLeaveFun: {
      type: Function,
      default: function() {
        return true
      }
    }
  },
  watch: {
    value: function(val) {
      this.activeTab = val
    }
  },
  methods: {
    // 当点击tab后同步绑定的tab,触发事件并传递被选中tab的索引
    handleClick(tab) {
      this.$emit('input', this.activeTab)
      this.$emit('click', tab.index)
    }
  }
}
</script>
<style lang="scss">
.vxa-tab-pane-tooltip {
  transform: translateY(10px);
}
</style>
