<!--
 * @Description: 折叠卡片组件
-->
<template>
  <el-collapse class="vxa-collapse-card" :class="{ 'vxa-collapse-card_blank': blank }" v-model="activeName">
    <el-collapse-item name="1" :disabled="!isFold">
      <template slot="title">
        <div class="header">
          <div class="header-item">
            <span class="title">{{ title }}</span>
            <template v-if="subTitle">
              <xa-tooltip v-if="subtitleType == 'tooltip'">
                <i class="iconfont web-shuoming"></i>
                <div slot="content" v-html="subTitle" v-if="dangerouslyUseHTMLString"></div>
              </xa-tooltip>
              <span class="subTitle" v-else>{{ subTitle }}</span>
            </template>
          </div>
          <div class="header-item">
            <slot name="right"></slot>
          </div>
        </div>
      </template>
      <slot></slot>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  name: 'VxaCollapseCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    // tooltip、text
    subtitleType: {
      type: String,
      default: 'tooltip'
    },
    // 是否可折叠
    isFold: {
      type: Boolean,
      default: false
    },
    // 是否收起
    packUp: {
      type: Boolean,
      default: false
    },
    // 内容是否为空
    blank: {
      type: Boolean,
      default: false
    },
    // 是否将 message 属性作为 HTML 片段处理
    dangerouslyUseHTMLString: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: ['1']
    }
  },
  created() {
    if (this.packUp) {
      this.activeName = []
    }
  }
}
</script>
<style lang="scss">
.vxa-collapse-card {
  background-color: white;
  border-radius: 4px;
  margin: 0 auto;
  overflow: hidden;
  border: none;

  &_blank .el-collapse-item__content {
    padding-bottom: 0;
  }
  .header {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;

    .header-item {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 15px;
        color: #999999;
        margin-left: 5px;
      }
      .subTitle {
        color: #aeaeae;
        font-size: 14px;
        margin-left: 5px;
      }
    }
    .title {
      font-size: 16px;
      color: #323232;
      font-weight: bold;
    }
  }
  .el-collapse-item {
    .el-collapse-item__header {
      border: none !important;
      padding: 0 16px;
    }
    .el-collapse-item__wrap {
      border-top: 1px solid #e3e3e3;
    }
  }
  .el-collapse-item__arrow {
    margin-right: 0;
    margin-left: 10px;
  }
  .el-collapse-item.is-disabled .el-collapse-item__header {
    cursor: auto;

    .el-collapse-item__arrow {
      display: none;
    }
  }
}
</style>
