import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 用户添加关注公司
  add_attention_company(code) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.lookuptables.add_attention_company, [['code', code]]), originUrl: api.lookuptables.add_attention_company })
  },
  // 内部员工获取年报数据
  get_internal_annual_report(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_internal_annual_report, params: params })
  },
  // 获取用户在这家公司设定好的时间
  get_permission_date(params) {
    return Axios({ method: 'GET', url: api.lookuptables.permission_date, params: params })
  },
  // 保存时间设置
  save_permission_date(params) {
    return Axios({ method: 'PUT', url: api.lookuptables.permission_date, params: params })
  },
  // 清除时间设置
  delete_permission_date(params) {
    return Axios({ method: 'DELETE', url: api.lookuptables.permission_date, params: params })
  },
  // 通过截止结束日期获取截止开始日期
  get_ten_close_day(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_ten_close_day, params: params })
  },
  // 获取最小的截止结束日期
  get_min_close_date(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.lookuptables.get_min_close_date, [['code', code]]), originUrl: api.lookuptables.get_min_close_date })
  },
  // 内部员工通过年份获取年报
  get_annual_report_by_year(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_annual_report_by_year, params: params })
  },
  // 获取内部员工的权限速查表(新)
  get_internal_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_internal_list, params: params })
  },
  // 保存数据校准
  save_internal_annual_reports(data) {
    return Axios({ method: 'POST', url: api.lookuptables.save_internal_annual_reports, data })
  },
  // 重置数据校准
  reset_internal_annual_report(params) {
    return Axios({ method: 'PUT', url: api.lookuptables.reset_internal_annual_report, params: params })
  },
  // 重置数据校准
  get_permission_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_permission_list, params: params })
  },
  // 通过code获取所属板块
  get_market_by_code(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.lookuptables.get_market_by_code, [['code', code]]), originUrl: api.lookuptables.get_market_by_code })
  },
  // 获取权限速查表
  getInsideListOut(params) {
    return Axios({ method: 'POST', url: api.lookuptables.getInsideListOut, params: params })
  },
  download_excel(params = {}) {
    return Axios({ method: 'GET', url: api.lookuptables.download_excel, params: params })
  },
  // 提示获取最新年报数据
  get_ten_days_close_price_mean(code, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.lookuptables.get_ten_days_close_price_mean, [['code', code]]), params: params, originUrl: api.lookuptables.get_ten_days_close_price_mean })
  },

  // 根据公司code获取该板块下的权限速查表累计计算事项列表
  get_cumulative_calculation_item_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_cumulative_calculation_item_list, params })
  },
  // 获取用户设置的日期
  get_date(params) {
    return Axios({ method: 'GET', url: api.lookuptables.date_setting, params })
  },
  // 保存用户设置的日期
  set_date(params) {
    return Axios({ method: 'POST', url: api.lookuptables.date_setting, params })
  },
  // 获取最新一期已审计的财报报告期
  latest_audited_report_date(params) {
    return Axios({ method: 'GET', url: api.lookuptables.latest_audited_report_date, params })
  },
  // 获取权限速查表累计计算指标数据
  indicator_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.indicator_list, params })
  },
  // 获取交易明细数据
  get_trade_data(params) {
    return Axios({ method: 'GET', url: api.lookuptables.trade_data, params })
  },
  // 保存交易明细数据，包括新增、修改
  set_trade_data(params, data) {
    return Axios({ method: 'POST', url: api.lookuptables.trade_data, params, data })
  },
  // 删除交易明细数据
  del_trade_data(params, data) {
    return Axios({ method: 'DELETE', url: api.lookuptables.trade_data, params, data })
  },
  // 获取事项下的指标选项
  indicator_options(params) {
    return Axios({ method: 'GET', url: api.lookuptables.indicator_options, params })
  },
  // 获取表格表头
  table_head(params) {
    return Axios({ method: 'GET', url: api.lookuptables.table_head, params })
  },

  // 获取权限速查事项列表
  get_permission_item_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_permission_item_list, params })
  },
  // 获取披露规则
  get_disclosure_rule(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.lookuptables.get_disclosure_rule, [['itemId', itemId]]), params: params, originUrl: api.lookuptables.get_disclosure_rule })
  },
  // 添加用户自定义披露规则
  add_disclosure_rule(params, data) {
    return Axios({ method: 'POST', url: api.lookuptables.add_disclosure_rule, params, data })
  },
  // 重置披露规则
  reset_disclosure_rule(itemId, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.lookuptables.reset_disclosure_rule, [['itemId', itemId]]), params, originUrl: api.lookuptables.reset_disclosure_rule })
  },

  // 获取交易对手列表
  get_counterparty_list(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_counterparty_list, params })
  },
  // 获取可选择的交易对手选项
  get_counterparty_option(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_counterparty_option, params })
  },
  // 获取可选择的交易对手选项
  get_affiliated_person_option(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_affiliated_person_option, params })
  },
  // 保存交易对手设置，包括新增、修改
  save_counterparty(params, data) {
    return Axios({ method: 'POST', url: api.lookuptables.save_counterparty, params, data })
  },
  // 重置披露规则
  delete_counterparty(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.lookuptables.delete_counterparty, [['id', id]]), originUrl: api.lookuptables.delete_counterparty })
  },
  // 查询公司信息（包括非上市）
  get_company_info(params) {
    return Axios({ method: 'GET', url: api.lookuptables.get_company_info, params })
  },
  // 导出交易明细
  export_trade_data(params) {
    return Axios({ method: 'GET', url: api.lookuptables.export_trade_data, params })
  }
}
