import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

// 法规笔记
export default {
  // 搜索法规笔记
  get_law_notes(params) {
    return Axios({ method: 'GET', url: api.lawnote.law_notes, params: params })
  },
  // 新增法规笔记
  add_law_notes(params) {
    return Axios({ method: 'POST', url: api.lawnote.law_notes, data: params })
  },
  // 更新笔记
  update_law_notes(params, noteId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.lawnote.update_law_notes, [['noteId', noteId]]), data: params, originUrl: api.lawnote.update_law_notes })
  },
  // 批量更新笔记
  batch_update_law_notes(params) {
    return Axios({ method: 'PUT', url: api.lawnote.batch_law_notes, data: params })
  },
  // 删除笔记
  delete_law_notes(params) {
    return Axios({ method: 'DELETE', url: api.lawnote.batch_law_notes, params: params })
  },
  // 检查是否有创建小安笔记权限
  xiaoan_note_permission(params) {
    return Axios({ method: 'GET', url: api.lawnote.xiaoan_note_permission, params: params })
  },
  // 查询做了笔记的法规
  law_notes_laws(params) {
    return Axios({ method: 'GET', url: api.lawnote.law_notes_laws, params: params })
  }
}
