<!--
 * @Description: 广告卡片组件
-->
<template>
  <el-collapse-transition>
    <div v-if="advert.length > 0">
      <el-card
        shadow="never"
        body-style="padding:0;"
        class="advertisement"
        v-for="(item, index) in advert"
        :key="index"
      >
        <a
          class="link"
          :href="item.advertUrl"
          target="_blank"
          rel="noopener noreferrer"
          :data-sa-id="module + '-adv-link-' + (index + 1)"
        >
          <img :src="item.advertPic" alt="" />
        </a>
      </el-card>
    </div>
  </el-collapse-transition>
</template>

<script>
export default {
  name: 'adv',
  props: {
    module: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      advert: [],
      enums: {
        '/rules': '法规检索',
        '/rules/compilation': '法规汇编',
        '/rules/compilation/:id/detail': '法规汇编',
        '/rules?havenote=1': '法规笔记',
        '/affiches': '公告检索',
        '/affiches?inputCode=可比公司': '可比公司公告',
        '/affiches?inputCode=关注公司': '关注公司公告',
        '/affiches?inputCode=本公司': '本公司公告',
        '/investor/company/list': '公司互动易',
        '/investor/company/overview': '公司互动易',
        '/investor/market-overview/overview': '互动易市场概览',
        '/investor/market-overview/leader-board': '互动易市场概览',
        '/investor/list': '互动易检索',
        '/investor/questions/:id': '投关问答详情',
        '/market-condition': '市场行情',
        '/ipo/list': 'IPO检索',
        '/ipo/audit-company/overview': 'IPO在审公司统计',
        '/ipo/audit-company/list': 'IPO在审公司统计',
        '/STAR-ipo/list': '科创板IPO检索',
        '/STAR-ipo/audit-company/overview': '科创板IPO在审公司统计',
        '/reports': '研报检索',
        '/reports?inputCode=本公司': '本公司研报',
        '/reports?inputCode=关注公司': '关注公司研报',
        '/reports?inputCode=可比公司': '可比公司研报',
        '/equityIncentive/personal/management': '股权激励',
        '/equityIncentive/file': '股权激励',
        '/equityIncentive/my/common': '股权激励',
        '/equityIncentive/qa/common': '股权激励',
        '/inhope-FAQ': '信乎',
        '/inhope-FAQ/questions/:id': '信乎',
        '/inhope-FAQ/topics/:id': '信乎',
        '/meeting/underway': '三会管理',
        '/meeting/all': '三会管理',
        '/meeting/statistics': '三会管理',
        '/meeting/template': '三会管理',
        '/online-test': '在线测试'
      }
    }
  },
  created() {
    this.getAdvertTypeList()
  },
  methods: {
    getAdvertTypeList() {
      this.$axios.common.get_advert_type_list().then(res => {
        const data = res.data.returnObject
        const tmp = data.filter(e => {
          return e.type == this.enums[this.staticToDynamic()]
        })

        if (tmp.length > 0) {
          this.getAdvertByType(tmp[0].id)
        }
      })
    },
    // 将路由还原为静态路由
    staticToDynamic() {
      let path = this.$route.path
      if (this.$route.params.id && this.$route.path.indexOf(this.$route.params.id) > -1) {
        path = this.$route.path.replace(`${this.$route.params.id}`, ':id')
      }
      return path
    },
    getAdvertByType(typeId) {
      this.advert = []
      this.$axios.common.get_advert_by_type({ typeId: typeId }).then(res => {
        this.advert = res.data.returnObject.map((e, i) => {
          return Object.assign({}, e, { advertPic: e.advertPic.replace('http://', 'https://') })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.advertisement {
  width: 100%;
  margin-bottom: 15px;
  .link {
    width: 100%;
    height: auto;
    display: inherit;
    font-size: 0;
  }
  img {
    width: 100%;
    border-radius: 3px;
  }
}
</style>
