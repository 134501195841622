import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 回答详情
  get_questions_details(questionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.inhopeFAQ.get_questions_details, [['questionId', questionId]]), originUrl: api.inhopeFAQ.get_questions_details })
  },
  // 提问关键字自动补全，只匹配标题
  xiaoan_qa_auto_complete_question_title(params) {
    return Axios({ method: 'POST', url: api.inhopeFAQ.xiaoan_qa_auto_complete_question_title, data: params })
  },
  // 最新问题
  get_latest_questions(params) {
    return Axios({ method: 'POST', url: api.inhopeFAQ.get_latest_questions, data: params })
  },
  // 相关问题
  search_related_questions(params, questionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.inhopeFAQ.search_related_questions, [['questionId', questionId]]), params: params, originUrl: api.inhopeFAQ.search_related_questions })
  },
  // 搜索相关话题
  listSearchRelatedTopics(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.listSearchRelatedTopics, params: params })
  },
  // 话题相关问题列表
  listTopicRelatedQuestions(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.listTopicRelatedQuestions, params: params })
  },
  // 随机搜索标题
  searchUserTiltle(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.searchUserTiltle, params: params })
  },
  // 获取问题板块
  xiaoan_qa_market_plates(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.xiaoan_qa_market_plates, params: params })
  },
  // 获取问题类别
  xiaoan_qa_question_types(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.xiaoan_qa_question_types, params: params })
  },
  // 获取回答来源的筛选条件
  xiaoan_qa_answer_source(params) {
    return Axios({ method: 'GET', url: api.inhopeFAQ.xiaoan_qa_answer_source, params: params })
  }
}
