import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

/**
 * @desc: 可比公司
 */
export default {
  // 获取可比公司
  get_company_compare(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.enterpriseCompany.company_compare, [['companyId', companyId]]), params: params, originUrl: api.enterpriseCompany.company_compare })
  },
  // 切换公司
  switch_company(companyId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.enterpriseCompany.switch_company, [['companyId', companyId]]), originUrl: api.enterpriseCompany.changeCompany })
  },
  // 根据关键字查询上市公司 {key: 关键字, pageNum: 页数}
  search_company(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.search_company, params: params })
  },
  // 根据关键字查询上市公司
  company_search(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.search_company, data: params })
  },
  // 添加可比公司
  add_company_compare(params, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.enterpriseCompany.company_compare, [['companyId', companyId]]), params: params, originUrl: api.enterpriseCompany.company_compare })
  },
  //
  send_exit_sms(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.send_exit_sms, params: params })
  },
  // 删除可比公司
  delete_company_compare(params, companyId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.enterpriseCompany.company_compare, [['companyId', companyId]]), params: params, originUrl: api.enterpriseCompany.company_compare })
  },
  // 添加续费企业订单
  add_enterprise_order(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.add_enterprise_order, params: params })
  },
  // 退出公司
  exit_company(params, companyId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.enterpriseCompany.exit_company, [['companyId', companyId]]), params: params, originUrl: api.enterpriseCompany.exit_company })
  },
  // 查询A股上市公司（过滤B股）
  a_stock_listed_companies(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.a_stock_listed_companies, params: params })
  },
  // 内部用户搜索服务公司
  search_infaith_company(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.search_infaith_company, params: params })
  },
  // 添加内部用户服务公司
  add_infaith_company(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.add_infaith_company, data: params })
  },
  // 删除内部用户服务公司
  delete_infaith_company(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.delete_infaith_company, params: params })
  },
  // 获取我服务的公司列表
  get_my_infaith_company_list(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_my_infaith_company_list, params: params })
  },
  // 获取服务公司
  get_served_companies(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_served_companies, params: params })
  },
  companies_dashboard_statistics_market_info(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.companies_dashboard_statistics_market_info, data: params })
  },
  companies_dashboard_statistics_announce_info(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.companies_dashboard_statistics_announce_info, data: params })
  },
  companies_dashboard_statistics_sentiment_info(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.companies_dashboard_statistics_sentiment_info, data: params })
  },
  // 获取服务公司行情数据
  served_companies_market_info(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.served_companies_market_info, params: params })
  },
  // 获取服务公司公告信息
  served_companies_announce_info(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.served_companies_announce_info, params: params })
  },
  // 获取服务公司舆情信息
  served_companies_sentiment_info(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.served_companies_sentiment_info, params: params })
  },
  // 设置置顶服务公司
  set_top_service_company(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.set_top_service_company, data: params })
  },
  // 取消设置置顶服务公司
  cancel_set_top_service_company(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.cancel_set_top_service_company, data: params })
  },
  // 服务公司舆情统计
  get_infaith_company_sentiment_total(params) {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_infaith_company_sentiment_total, params: params })
  },
  // 获取任职公司
  independent_director_companies() {
    return Axios({ method: 'GET', url: api.enterpriseCompany.independent_director_companies })
  },
  // 设置任职公司
  set_independent_director_companies(params) {
    return Axios({ method: 'PUT', url: api.enterpriseCompany.set_independent_director_companies, params: params })
  },
  // 查询用户dashborad页面类型
  get_dashboard_type() {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_dashboard_type })
  },
  // 信工咨询-获取企业成员列表
  infaith_get_enterprise_user_list(params) {
    return Axios({ method: 'GET', params, url: api.enterpriseCompany.infaith_get_enterprise_user_list })
  },
  // 获取项目角色权限
  get_project_all_permission(params) {
    return Axios({ method: 'GET', params, url: api.enterpriseCompany.get_project_all_permission })
  },
  get_project_role_permission(roleId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.enterpriseCompany.get_project_role_permission, [['roleId', roleId]]) })
  },
  // 保存成员前置校验
  check_can_save_user(data) {
    return Axios({ method: 'POST', data, url: api.enterpriseCompany.check_can_save_user })
  },
  // 新增成员
  save_enterprise_user(data) {
    return Axios({ method: 'POST', data, url: api.enterpriseCompany.save_enterprise_user })
  },
  // 编辑成员
  edit_enterprise_user(id, data) {
    return Axios({ method: 'PUT', data, url: Common.pathFilter(api.enterpriseCompany.edit_enterprise_user, [['id', id]]), originUrl: api.enterpriseCompany.edit_enterprise_user })
  },
  // 查看成员
  check_enterprise_user(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.enterpriseCompany.check_enterprise_user, [['id', id]]), originUrl: api.enterpriseCompany.check_enterprise_user })
  },
  // 删除成员
  delete_enterprise_user(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.enterpriseCompany.delete_enterprise_user, [['id', id]]), originUrl: api.enterpriseCompany.delete_enterprise_user })
  },
  // 岗位下拉选
  get_enterprise_user_position() {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_enterprise_user_position })
  },
  // 分部下拉选
  get_enterprise_user_sub_company() {
    return Axios({ method: 'GET', url: api.enterpriseCompany.get_enterprise_user_sub_company })
  },
  // 部门下拉选
  get_enterprise_user_company(params) {
    return Axios({ method: 'GET', params, url: api.enterpriseCompany.get_enterprise_user_company })
  },
  // 根据关键字查询可比公司
  compared_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.compared_companies_by_company_id_search, data: params })
  },
  // 增加可比公司
  add_compared_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.compared_companies_by_company_id, data: params })
  },
  // 删除可比公司
  delete_compared_companies(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.compared_companies_by_company_id, data: params })
  },
  // 根据关键字查询咨询可比公司
  infaith_compared_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.compared_companies_by_company_id_search, data: params })
  },
  // 企业-关注-查询全部公司
  company_search_with_focus_on_info(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.company_search_with_focus_on_info, data: params })
  },
  // 企业-查询关注公司
  focus_on_company_list_search(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.focus_on_company_list_search, data: params })
  },
  // 增加关注公司
  add_attentions_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.user_attentions_single, data: params })
  },
  // 删除关注公司
  delete_attentions_companies(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.user_attentions_single, data: params })
  },
  // 咨询公司-关注-查询全部
  served_companies_search_v2(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.served_companies_search_v2, data: params })
  },
  // 咨询公司 查询关注公司
  followed_companies_search(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.followed_companies_search, data: params })
  },
  // 咨询增加关注公司
  add_infaith_attentions_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.served_companies_single, data: params })
  },
  // 咨询删除关注公司
  delete_infaith_attentions_companies(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.served_companies_single, data: params })
  },
  // 独董-查询全部
  independent_companies_search_v2(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.independent_companies_search_v2, data: params })
  },
  // 独董-查询服务公司
  independent_director_companies_search(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.independent_director_companies_search, data: params })
  },
  // 独董增加服务公司
  add_independent_attentions_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.independent_director_companies_single, data: params })
  },
  // 独董删除服务公司
  delete_independent_attentions_companies(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.independent_director_companies_single, data: params })
  },
  // 监管-查询监管公司
  supervised_companies_search(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.supervised_companies_search, data: params })
  },
  // 增加监管公司
  add_supervised_attentions_companies(params) {
    return Axios({ method: 'POST', url: api.enterpriseCompany.supervised_companies_single, data: params })
  },
  // 删除监管公司
  delete_supervised_attentions_companies(params) {
    return Axios({ method: 'DELETE', url: api.enterpriseCompany.supervised_companies_single, data: params })
  }
}
