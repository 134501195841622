import affiche from './components/affiche'
import condition from './components/condition'
import disclosure from './components/disclosure'
import footer from './components/footer'
import header from './components/header'
import investor from './components/investor'
import message from './components/message'
import prompt from './components/prompt'
import rules from './components/rules'
import stockholder from './components/stockholder'
import user from './components/user'
import xinhu from './components/xinhu'

export default {
  affiche,
  condition,
  prompt,
  disclosure,
  footer,
  header,
  investor,
  message,
  rules,
  stockholder,
  user,
  xinhu
}
