import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 查看未读消息总数
  unread_count(params) {
    return Axios({ method: 'GET', url: api.user.unread_count, params: params })
  },
  // 删除收藏夹
  del_favorites_by_id(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.user.del_favorites_by_id, [['id', id]]), originUrl: api.user.del_favorites_by_id })
  },
  // 已添加的收藏夹的id
  get_added_favourite_ids(params) {
    return Axios({ method: 'GET', url: api.user.get_added_favourite_ids, params: params })
  },
  // 获取收藏夹内容
  get_favourite_content(params, favourableId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.get_favourite_content, [['favourableId', favourableId]]), params: params, originUrl: api.user.get_favourite_content })
  },
  // 删除收藏内容
  del_favourite_content_by_id: function (params, favouriteId, id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.user.del_favourite_content_by_id, [['favouriteId', favouriteId], ['id', id]]), params: params, originUrl: api.user.del_favourite_content_by_id })
  },
  // 添加收藏内容
  add_favourite_content(params, data) {
    return Axios({ method: 'POST', url: api.user.add_favourite_content, params, data })
  },
  // 判断是否收藏
  check_is_collect(params) {
    return Axios({ method: 'GET', url: api.user.check_is_collect, params: params })
  },
  // 获取默认收藏夹内容
  get_default_favourite_content(params) {
    return Axios({ method: 'GET', url: api.user.get_default_favourite_content, params: params })
  },
  // 获取法规汇编内的法规
  get_law_collection_content(params, lawCollectionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.get_law_collection_content, [['lawCollectionId', lawCollectionId]]), params: params, originUrl: api.user.get_law_collection_content })
  },
  // 获取法规汇编信息
  get_law_collection_info(lawCollectionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.get_law_collection_info, [['lawCollectionId', lawCollectionId]]), originUrl: api.user.get_law_collection_info })
  },
  // 搜索法规汇编
  law_collection_search(params) {
    return Axios({ method: 'GET', url: api.user.law_collection_search, params: params })
  },
  // 取消订阅法规汇编
  cancel_recommend(recommendId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.user.cancel_recommend, [['recommendId', recommendId]]), originUrl: api.user.cancel_recommend })
  },
  // 获取用户关注的法规汇编
  get_user_recommends(params) {
    return Axios({ method: 'GET', url: api.user.get_user_recommends, params: params })
  },
  // 获取用户收藏夹信息
  get_user_favorites(params) {
    return Axios({ method: 'GET', url: api.user.user_favorites, params: params })
  },
  // 编辑收藏夹
  update_favourite_name(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.user.update_favourite_name, [['id', id]]), params: params, originUrl: api.user.update_favourite_name })
  },
  // 根据id获取收藏夹
  get_favourite_by_Id(favouriteById) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.get_favourite_by_Id, [['favouriteById', favouriteById]]), originUrl: api.user.get_favourite_by_Id })
  },
  // 根据userId删除用户足迹
  del_user_track(params) {
    return Axios({ method: 'DELETE', url: api.user.user_track, params: params })
  },
  // 添加收藏夹
  add_user_favorites(params) {
    return Axios({ method: 'POST', url: api.user.user_favorites, params: params })
  },
  // 根据userId获取用户足迹
  get_user_track(params) {
    return Axios({ method: 'GET', url: api.user.user_track, params: params })
  },
  // 修改绑定手机号
  bind_user_mobile(params) {
    return Axios({ method: 'PUT', url: api.user.bind_user_mobile, params: params })
  },
  upload_img(params) {
    return Axios({ method: 'POST', url: api.user.upload_img, data: params })
  },
  modify_enterprise_member_mobile_code(params) {
    return Axios({ method: 'POST', url: api.user.modify_enterprise_member_mobile_code, params: params })
  },
  // 补全用户信息
  complete_user_info(params) {
    return Axios({ method: 'POST', url: api.user.complete_user_info, params: params })
  },
  // 获取用户职位
  get_positions(params) {
    return Axios({ method: 'GET', url: api.user.get_positions, params: params })
  },
  // 根据股票代码获取公司名称
  get_company_name_by_code(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.get_company_name_by_code, [['companyCode', companyCode]]), originUrl: api.user.get_company_name_by_code })
  },
  // 账号登录
  login_by_account(params) {
    return Axios({ method: 'POST', url: api.newuser.login_by_account, data: params })
  },
  // 短信登录/注册
  login_with_register_by_sms(params) {
    return Axios({ method: 'POST', url: api.newuser.login_with_register_by_sms, data: params })
  },
  // 微信登录
  login_by_wx(params) {
    return Axios({ method: 'POST', url: api.newuser.login_by_wx, data: params })
  },
  // 刷新JWT_token
  refresh_token() {
    return Axios({ method: 'POST', url: api.newuser.refresh_token })
  },
  // 查询用户所有权限
  get_user_permissions() {
    return Axios({ method: 'GET', url: api.newuser.get_user_permissions })
  },
  changebind_transaction() {
    return Axios({ method: 'POST', url: api.user.changebind_transaction })
  },
  // 校验验证码
  check_sms_code(code, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.check_sms_code, [['code', code]]), originUrl: api.user.check_sms_code, params })
  },
  // 发送验证码，增加短信上限校验
  send_sms_code(params) {
    return Axios({ method: 'POST', url: api.user.send_sms_code, params: params })
  },
  can_changebind(params) {
    return Axios({ method: 'PUT', url: api.user.can_changebind, params })
  },
  // 手机号注册
  mobile_register(params) {
    return Axios({ method: 'POST', url: api.user.mobile_register, params: params })
  },
  // 获取图片验证码
  get_image_code() {
    return Axios({ method: 'GET', url: api.user.get_image_code })
  },
  check_image_code(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.check_image_code, [['code', code]]), originUrl: api.user.check_image_code })
  },
  // 获取用户信息
  get_user_info(params = {}) {
    return Axios({ method: 'GET', url: api.user.get_user_info, params: params })
  },
  // 修改密码
  modify_login_password(params) {
    return Axios({ method: 'PUT', url: api.user.modify_login_password, params: params })
  },
  // 找回密码
  find_login_password(params) {
    return Axios({ method: 'POST', url: api.user.find_login_password, params: params })
  },
  // 修改邮箱
  update_email(params) {
    return Axios({ method: 'PUT', url: api.user.update_email, params: params })
  },
  // 获取用户公司类型
  get_company_type_info(params) {
    return Axios({ method: 'GET', url: api.user.get_company_type_info, params: params })
  },
  // 最新的热词搜索
  getHotWords(params = {}) {
    return Axios({ method: 'GET', url: api.user.getHotWords, params: params })
  },
  // 获取用户在该公司角色
  get_user_groups() {
    return Axios({ method: 'GET', url: api.user.get_user_groups })
  },
  // 校验公司code与类型是否匹配
  check_market_with_company_code(params = {}, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.check_market_with_company_code, [['code', code]]), params: params, originUrl: api.user.check_market_with_company_code })
  },
  // 法规汇编下载
  download_law_collection(params = {}) {
    return Axios({ method: 'GET', url: api.user.download_law_collection, params: params })
  },
  // 绑定微信
  bind_weixin(params) {
    return Axios({ method: 'POST', url: api.newuser.bind_weixin, params: params })
  },
  // 解绑微信
  unbind_weixin(params) {
    return Axios({ method: 'PUT', url: api.newuser.bind_weixin, params: params })
  },
  // 个人中心-编辑-自动补全公司code
  company_auto_fill(params) {
    return Axios({ method: 'GET', url: api.user.company_auto_fill, params: params })
  },
  // 用户知晓用户协议
  confirm_user_agreement(params) {
    return Axios({ method: 'PUT', url: api.user.confirm_user_agreement, params: params })
  },
  // 校验该用户是否已注册
  check_user_is_existence(params) {
    return Axios({ method: 'GET', url: api.user.check_user_is_existence, params: params })
  },
  // 查询用户常用功能
  get_user_common_modules(params) {
    return Axios({ method: 'GET', url: api.user.user_common_modules, params: params })
  },
  // 修改用户常用功能
  set_user_common_modules(params) {
    return Axios({ method: 'POST', url: api.user.user_common_modules, data: params })
  },
  // 查询用户关注公司
  get_focus_on_company_list(params) {
    return Axios({ method: 'GET', url: api.user.focus_on_company_list, params: params })
  },
  // 退出登录
  user_logout() {
    return Axios({ method: 'POST', url: api.user.user_logout })
  },
  // 检查该法规汇编是否还存在
  check_law_exist(lawCollectionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.user.check_law_exist, [['lawCollectionId', lawCollectionId]]), originUrl: api.user.check_law_exist })
  },
  // 获取人员信息（在职离职人员信息）
  get_user_info_by_userId(params) {
    return Axios({ method: 'GET', url: api.newuser.get_user_info_by_userId, params })
  }
}
