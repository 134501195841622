<!--
 * @Description: 显示导航选项卡和相关内容
-->
<template>
  <div class="vxa-nav-tab" @mouseenter="clearHover = false">
    <div class="tabs-nav-box vxa-min-container" v-if="!(title == '' && doc == '')">
      <div class="vxa-container">
        <div class="box-navs vxa-flex-box justify-between is-align-center">
          <div>
            <p class="tab-title-p">
              <span v-html="title"></span>
              <slot name="title-side-block"></slot>
            </p>
            <p v-html="doc"></p>
          </div>
          <slot name="right-btn"></slot>
        </div>
        <div style="display: flex; font-size: 16px">
          <div
            style="width: 180px; display: inline-block; margin-right: 1px; background-color: rgba(255, 255, 255, 0.08)"
            v-if="needChangeCompany"
            @mouseover="haveHover = true"
            @mouseleave="haveHover = false"
          >
            <change-company
              :haveHover="haveHover"
              :showAllCompany="showAllCompany"
              @set-company="setCompany"
              style="height: 42px"
            ></change-company>
          </div>
          <div style="flex-grow: 1">
            <ul class="box-tabs">
              <template v-for="(list, index) in tabPane">
                <li
                  v-if="!list.hide"
                  :key="index"
                  :class="{ active: list.active, 'clear-hover': clearHover }"
                  @click="handleClick(index)"
                >
                  <a href="javascript:void(0)"
                    ><span class="text">{{ list.name }}</span></a
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <slot name="content"></slot>
    <slot></slot>
  </div>
</template>

<script>
/**
 * @params title { String }
 * @params doc { String }
 * @params tab { Array }
 * **/
import changeCompany from '@/components/global/changeCompanyNew.vue'
export default {
  name: 'VxaNavTab',
  components: { changeCompany },
  props: {
    tab: {
      type: [Array, String],
      default: () => []
    },
    needChangeCompany: {
      type: Boolean,
      default: false
    },
    isNotSupportUnlisted: {
      type: Boolean,
      default: true
    },
    showAllCompany: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabPane: [],
      name: '',
      title: '',
      doc: '',
      haveHover: false,
      clearHover: false
    }
  },
  watch: {
    $route: function (to, form) {
      if (to.path !== form.path) {
        this.init()
      }
    },
    tab: function () {
      this.tabPane = this.tab
      this.init()
    }
  },
  created() {
    this.tabPane = this.tab
    this.init()
  },
  methods: {
    init() {
      this.tabPane.forEach((e, i) => {
        if (e.path.includes(this.$route.path)) {
          this.title = e.title || e.name
          this.doc = e.doc
          e.active = true
          this.$emit('change', i)
        } else {
          if (e.replacePathObject) {
            e.active = false
            e.replacePathObject.forEach(ele => {
              if (this.$common.pathRegx(ele.path, ele.ids).test(this.$route.path)) {
                this.title = e.title || e.name
                this.doc = e.doc
                e.active = true
                this.$emit('change', i)
              }
            })
          } else {
            e.active = false
          }
        }
      })
    },
    setCompany(item) {
      this.$emit('setCompany', item)
    },
    handleClick(index) {
      if (this.tab[index]?.targetBlank) {
        // 新打开窗口前覆盖hover样式，返回页面的mouseenter事件内恢复
        this.clearHover = true
        this.$common.openWindow(this.tab[index].link)
      } else {
        this.$emit('handle', index)
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vxa-nav-tab {
  .tabs-nav-box {
    width: 100%;
    height: 166px;
    background-image: url(#{$aliyunDomain}xiaoan/web_static/banner/headerBanner.png);
    background-size: 100% 100%;
    color: #ffffff;
    .box-navs {
      height: 124px;
      p {
        font-size: 12px;
        font-weight: normal;
      }
      .tab-title-p {
        font-size: 30px;
        font-weight: bold;
        padding: 0 0 4px 0;
        display: flex;
        align-items: center;
      }
    }
    .box-tabs {
      height: 42px;
      display: flex;
      align-items: center;
      li {
        background-color: rgba(255, 255, 255, 0.08);
        color: #ffffff;
        width: 198px;
        margin-right: 1px;
        display: inline-block;
        height: 100%;
        text-align: center;
        line-height: 42px;
        position: relative;
        cursor: pointer;
        &.active {
          color: #ffb148;
          border-bottom: 2px solid #ffb148;
          .text {
            color: #ffb148;
            font-size: 16px;
          }
        }
        &:hover {
          background-color: #ffb148;
          color: #ffffff;
          .text {
            color: #fff;
            border-bottom: 2px solid #ffb148;
          }
        }
        &.clear-hover {
          &:hover {
            background-color: rgba(255, 255, 255, 0.08);
            color: #ffffff;
            .text {
              border: none;
            }
          }
        }
        .text {
          color: #fff;
          height: 42px;
          text-align: center;
          position: relative;
          display: inline-block;
        }
      }
    }
  }
}
</style>
