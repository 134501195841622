
const gongsizhili = [
  {
    subIssueTitle: '治理架构',
    subIssueTooltipContent: `对应GRI标准: 102-18<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括公司的治理架构，包括董事会、监事会、股东大会、专门委员会等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '董事会及其委员会的组成',
    subIssueTooltipContent: `对应GRI标准: 102-22<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括执行董事或非执行董事；独立成员；治理机构的任期；性别；未被充分代表的社会群体成员；与经济、环境、社会议题有关的能力；利益相关方代表。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '女性董事数量', key: 'COMPANY_GOVERNANCE_1', unit: '人', yearDataList: ['', '', ''] },
          { title: '男性董事数量', key: 'COMPANY_GOVERNANCE_2', unit: '人', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '董事会主席的独立性',
    subIssueTooltipContent: `对应GRI标准: 102-23<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '董事会的主席是否兼任公司中的行政职位；如果主席还兼任行政职位，说明其在公司管理层的职能及做出此等安排的原因。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '董事会及其委员会的提名和甄选过程',
    subIssueTooltipContent: `对应GRI标准: 102-24<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括利益相关方（包括股东）是否以及如何参与；是否以及如何考虑多样性；是否以及如何考虑独立性；是否以及如何考虑与经济、环境和社会议题相关的专长和经验。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '利益冲突与相关信息披露',
    subIssueTooltipContent: `对应GRI标准: 102-25<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括a.董事会确保避免和控制利益冲突的过程。 b.是否向利益相关方披露利益冲突，至少应包括：跨董事会成员身份；与供应商或其他利益相关方交叉持股；控股股东的存在；关联方披露。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '三会运作',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 公司治理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司董事会、监事会、股东大会等会议的召开情况统计。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '董事会会议次数', key: 'COMPANY_GOVERNANCE_3', unit: '次', yearDataList: ['', '', ''] },
          { title: '监事会会议次数', key: 'COMPANY_GOVERNANCE_4', unit: '次', yearDataList: ['', '', ''] },
          { title: '股东大会会议次数', key: 'COMPANY_GOVERNANCE_5', unit: '次', yearDataList: ['', '', ''] },
          { title: '专门委员会会议次数', key: 'COMPANY_GOVERNANCE_6', unit: '次', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '信息披露',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括信息披露的制度建设与相关文件、信息披露的管理方法与流程、信息披露的方式与途径、年度披露情况统计。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '发布公告数量', key: 'COMPANY_GOVERNANCE_7', unit: '个', yearDataList: ['', '', ''] },
          { title: '平台解答问题数量', key: 'COMPANY_GOVERNANCE_8', unit: '个', yearDataList: ['', '', ''] },
          { title: '参与相关培训次数', key: 'COMPANY_GOVERNANCE_9', unit: '次', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '投资者关系管理',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括投资者关系管理制度与办法名称、投资者活动开展情况、投资者沟通情况统计、其他投资者关系管理的实践与效果。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '接待投资者调研次数', key: 'COMPANY_GOVERNANCE_10', unit: '次', yearDataList: ['', '', ''] },
          { title: '接待投资者人次', key: 'COMPANY_GOVERNANCE_11', unit: '人次', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '风险管理与内部控制',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括风险管理与内部控制的制度名称、风险管理组织架构、风险管理体系、培训等管理活动。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '风险管理培训', key: 'COMPANY_GOVERNANCE_12', unit: '次', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '党建工作',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括党组织建设、党建活动、党建案例、培训等内容。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '反腐败',
    subIssueTooltipContent: `对应GRI标准: 205-2<br>
      对应MSCI ESG指标: 公司治理-腐败与不稳定性<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括反腐败制度名称、反腐政策和流程、面向不同层次员工开展的反腐廉洁培训、检查等内容。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '贪污诉讼事件数', key: 'COMPANY_GOVERNANCE_13', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '税收透明度',
    subIssueTooltipContent: `对应GRI标准: 207-1, 207-2<br>
      对应MSCI ESG指标: 公司治理-税收透明度<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括纳税情况、公司是否制定了税务战略、确保合规的方法、税务管理组织架构、对非法行为的举报机制等内容。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '纳税总额', key: 'COMPANY_GOVERNANCE_14', unit: '元', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '不当竞争行为',
    subIssueTooltipContent: `对应GRI标准: 206-1<br>
      对应MSCI ESG指标: 公司治理-反竞争行为<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括公司在报告期内未决或已完成的关于不当竞争行为和违反反托拉斯和反垄断法，且该组织已被确定为参与者的法律诉讼的数量、已完成的法律诉讼的主要结果，包括任何裁决或判决。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '不当竞争行为法律诉讼数量', key: 'COMPANY_GOVERNANCE_15', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '公司治理年度重大事件',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括股本架构改变、兼并重组、投资并购、制度修订等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '荣誉认可',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司治理类的荣誉认可、被纳入的指数。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default gongsizhili
