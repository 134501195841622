import common from '../utils/common'
import redirectUrl from './redirectUrl'
import * as Sentry from '@sentry/vue'

const errorCallback = (e) => { // 模块加载错误处理，for bug3121
  console.error(e)
  if (common.ispc()) { // 对应bug 3644
    const pattern = /Loading.+chunk .+ failed/g
    const isChunkLoadFailed = e.message.match(pattern)
    if (isChunkLoadFailed) {
      Sentry.captureException(new Error('问题排查 | chunk模块加载失败'))
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  }
}

// 首页及空路由
const home = r => require.ensure([], () => r(require('@/view/home')), errorCallback, 'home')
const index = r => require.ensure([], () => r(require('@/view/index/index')), errorCallback, 'home')
const emptyRouterView = r => require.ensure([], () => r(require('@/components/emptyRouterView/empty')), errorCallback, 'home')

// dashboard主页
const dashboard = r => require.ensure([], () => r(require('@/view/dashboard/index')), errorCallback, 'dashboardenterprises')

// 法规相关
const rule = r => require.ensure([], () => r(require('@/view/rules/rule.vue')), errorCallback, 'rule')
const compilationIndex = r => require.ensure([], () => r(require('@/view/compilation/compilationIndex.vue')), errorCallback, 'rule')
const ruleCollect = r => require.ensure([], () => r(require('@/view/compilation/rule/ruleCollect.vue')), errorCallback, 'rule')
const ruleCollectInfo = r => require.ensure([], () => r(require('@/view/compilation/rule/ruleCollectInfo')), errorCallback, 'rule')
const ruleDetail = r => require.ensure([], () => r(require('@/view/rules/ruleDetail')), errorCallback, 'rule')
const ruleEvolution = r => require.ensure([], () => r(require('@/view/rules/evolution/ruleEvolution.vue')), errorCallback, 'rule')
const citationDetail = r => require.ensure([], () => r(require('@/view/citationPage/citationCaseLetter.vue')), errorCallback, 'rule') // 案例、问询函引用详情页

// 公告相关
const affiche = r => require.ensure([], () => r(require('@/view/affiche/affiche')), errorCallback, 'affiche')
const afficheDown = r => require.ensure([], () => r(require('@/view/affiche/afficheDown')), errorCallback, 'affiche')
const afficheDetails = r => require.ensure([], () => r(require('@/view/affiche/afficheDetails')), errorCallback, 'affiche')

// 行情相关
const market = r => require.ensure([], () => r(require('@/view/condition/market')), errorCallback, 'condition')
const company = r => require.ensure([], () => r(require('@/view/condition/company')), errorCallback, 'condition')
const abnormal = r => require.ensure([], () => r(require('@/view/condition/abnormal')), errorCallback, 'condition')

// IPO案例库相关
const ipoCasesIndex = r => require.ensure([], () => r(require('@/view/ipoCases/ipoCasesIndex')), errorCallback, 'ipoCases')
const ipoCasesList = r => require.ensure([], () => r(require('@/view/ipoCases/caseSearch/ipoCasesList')), errorCallback, 'ipoCases')
const ipoCasesOverview = r => require.ensure([], () => r(require('@/view/ipoCases/statisticsOverview/ipoCasesOverview')), errorCallback, 'ipoCases')
const ipoListingStandards = r => require.ensure([], () => r(require('@/view/ipoCases/listingStandard/ipoListingStandards')), errorCallback, 'ipoCases')
const ipoCaseLibraryDetail = r => require.ensure([], () => r(require('@/view/ipoCases/caseDetail/ipoCaseLibraryDetail')), errorCallback, 'ipoCases')

// 监管动态相关
const activities = r => require.ensure([], () => r(require('@/view/enterprises/activities')), errorCallback, 'activitiesenterprises')

// 违规案例相关
const casesSearch = r => require.ensure([], () => r(require('@/view/cases/search/index')), errorCallback, 'cases')
const caseDetails = r => require.ensure([], () => r(require('@/view/cases/detail/index')), errorCallback, 'cases')

// 问询函件相关
const inquiryLetters = r => require.ensure([], () => r(require('@/view/inquiryLetters/inquiryLetters')), errorCallback, 'inquiryLetters')
const inquiryLettersDetail = r => require.ensure([], () => r(require('@/view/inquiryLetters/inquiryLettersDetail')), errorCallback, 'inquiryLetters')

// 定期报告工具
const reportSearch = r => require.ensure([], () => r(require('@/view/periodicReportChapter/reportSearch/reportSearch')), errorCallback, 'periodicReportChapter')
const regularReportDisclosureDate = r => require.ensure([], () => r(require('@/view/periodicReportChapter/regularReportDisclosureDate/regularReportDisclosureDate')), errorCallback, 'periodicReportChapter')
const regularReportIndex = r => require.ensure([], () => r(require('@/view/periodicReportChapter/regularReportIndex')), errorCallback, 'periodicReportChapter')

// IPO问询函件相关
const ipoInquiryLettersIndex = r => require.ensure([], () => r(require('@/view/ipoInquiryLetters/index')), errorCallback, 'ipoInquiryLetters')
const ipoInquiryLettersSearch = r => require.ensure([], () => r(require('@/view/ipoInquiryLetters/search/index')), errorCallback, 'ipoInquiryLetters')
const ipoInquiryLettersAnalysis = r => require.ensure([], () => r(require('@/view/ipoInquiryLetters/analysis/index')), errorCallback, 'ipoInquiryLetters')
const ipoInquiryLettersDetail = r => require.ensure([], () => r(require('@/view/ipoInquiryLetters/detail/index')), errorCallback, 'ipoInquiryLetters')

// 股权激励案例库相关
const incentiveCase = r => require.ensure([], () => r(require('@/view/incentiveCase/incentiveCase')), errorCallback, 'planResource')
const equityIncentive = r => require.ensure([], () => r(require('@/view/incentiveCase/equityCases/equityList/equityIncentive')), errorCallback, 'planResource')
const employeeCase = r => require.ensure([], () => r(require('@/view/incentiveCase/esopCases/employeeList/employeeCase.vue')), errorCallback, 'planResource')
const equityCaseDetail = r => require.ensure([], () => r(require('@/view/incentiveCase/components/equityCaseDetail.vue')), errorCallback, 'planResource')
const equityCaseCompare = r => require.ensure([], () => r(require('@/view/incentiveCase/equityCases/equityCaseCompare/equityCaseCompare.vue')), errorCallback, 'planResource')
const employeeCaseCompare = r => require.ensure([], () => r(require('@/view/incentiveCase/esopCases/employeeCaseCompare/employeeCaseCompare.vue')), errorCallback, 'planResource')

// 电子签名相关
const signature = r => require.ensure([], () => r(require('@/view/signature')), errorCallback, 'signature')
const signatureIndex = r => require.ensure([], () => r(require('@/view/signature/signature/index')), errorCallback, 'signature')
const signatureIssueDetail = r => require.ensure([], () => r(require('@/view/signature/signature/detail')), errorCallback, 'signature')
const signatureIssueEdit = r => require.ensure([], () => r(require('@/view/signature/signature/edit')), errorCallback, 'signature')
const signatureCompanySeal = r => require.ensure([], () => r(require('@/view/signature/companySeal')), errorCallback, 'signature')
const signatureManageSeal = r => require.ensure([], () => r(require('@/view/signature/companySeal/manageSeal')), errorCallback, 'signature')
const signatureAddSeal = r => require.ensure([], () => r(require('@/view/signature/companySeal/manageSeal/addSeal')), errorCallback, 'signature')
const changeSealManager = r => require.ensure([], () => r(require('@/view/signature/companySeal/manageSeal/changeSealManager')), errorCallback, 'signature')
const signaturePositionAdjustment = r => require.ensure([], () => r(require('@/view/signature/signature/edit/setPdfOptions')), errorCallback, 'signature')
const securityIllustrate = r => require.ensure([], () => r(require('@/view/signature/securityIllustrate')), errorCallback, 'signature')
const electronicSignatureIntroduction = r => require.ensure([], () => r(require('@/view/signature/electronicSignatureIntroduction')), errorCallback, 'signature') // 电子签名宣传页（独董套餐下通过菜单访问）
const electronicSignatureBindWechat = r => require.ensure([], () => r(require('@/view/signature/signature/edit/electronicSignatureBindWechat')), errorCallback, 'signature') // 电子签名引导用户绑定微信页面
const guideToWechatSignature = r => require.ensure([], () => r(require('@/view/signature/guideToWechatSignature')), errorCallback, 'signature') // pc端打开签名链接时跳转至此页面引导用户
const signaturePreviewPDF = r => require.ensure([], () => r(require('@/view/signature/signature/detail/previewPDF')), errorCallback, 'signature')
const companySealPage = r => require.ensure([], () => r(require('@/view/signature/companySeal/sealPage')), errorCallback, 'signature')
const signatureEnterpriseAuthentication = r => require.ensure([], () => r(require('@/view/signature/companySeal/authentication')), errorCallback, 'signature')

// 三会相关
const meetingIndex = r => require.ensure([], () => r(require('@/view/meeting/meetingIndex')), errorCallback, 'meeting')
const meetingUnderway = r => require.ensure([], () => r(require('@/view/meeting/onGoingMeetings/onGoingMeetings')), errorCallback, 'meeting')
const meetingAll = r => require.ensure([], () => r(require('@/view/meeting/allMeetings/allMeetings')), errorCallback, 'meeting')
const meetingStatistics = r => require.ensure([], () => r(require('@/view/meeting/meetingStatistics/meetingStatistics')), errorCallback, 'meeting')
const meetingSetIndex = r => require.ensure([], () => r(require('@/view/meeting/meetingNormalSet/index')), errorCallback, 'meeting')
const meetingTemplate = r => require.ensure([], () => r(require('@/view/meeting/meetingNormalSet/meetingTemplate')), errorCallback, 'meeting')
const meetingMessage = r => require.ensure([], () => r(require('@/view/meeting/meetingNormalSet/meetingMessage/meetingMessage')), errorCallback, 'meeting')
const meetingSetup = r => require.ensure([], () => r(require('@/view/meeting/meetingNormalSet/meetingSetup/meetingSetup')), errorCallback, 'meeting')
const meetingEdit = r => require.ensure([], () => r(require('@/view/meeting/meetingEdit/meetingEdit')), errorCallback, 'meeting')
const meetingOnlineEdit = r => require.ensure([], () => r(require('@/view/meeting/onlineEdit/onlineEdit')), errorCallback, 'meeting')
const batchEntryHistoryMeeting = r => require.ensure([], () => r(require('@/view/meeting/meetingImport/batchEntryHistoryMeeting')), errorCallback, 'meeting')

// 公司规章制度相关
const regulation = r => require.ensure([], () => r(require('@/view/regulation/regulation')), errorCallback, 'regulation')
const regulationLibrary = r => require.ensure([], () => r(require('@/view/regulation/regulationLibrary')), errorCallback, 'regulationLibrary')
const regulationManage = r => require.ensure([], () => r(require('@/view/regulation/regulationManage')), errorCallback, 'regulationManage')

// ESG相关
const esg = r => require.ensure([], () => r(require('@/view/esg/esg')), errorCallback, 'esg')
const esgTask = r => require.ensure([], () => r(require('@/view/esg/esgTask')), errorCallback, 'esg')
const esgTaskCreate = r => require.ensure([], () => r(require('@/view/esg/esgTaskCreate')), errorCallback, 'esg')
const esgTaskDetail = r => require.ensure([], () => r(require('@/view/esg/taskAllot')), errorCallback, 'esg')
const esgSubtask = r => require.ensure([], () => r(require('@/view/esg/esgSubtask')), errorCallback, 'esg')
const esgSubtaskDetail = r => require.ensure([], () => r(require('@/view/esg/esgFillIssue')), errorCallback, 'esg')
const esgReport = r => require.ensure([], () => r(require('@/view/esg/esgReport')), errorCallback, 'esg')
const esgSubsidiary = r => require.ensure([], () => r(require('@/view/esg/esgSubsidiary')), errorCallback, 'esg')
const esgConfiguration = r => require.ensure([], () => r(require('@/view/esg/esgConfiguration')), errorCallback, 'esg')

// 独董数据库相关
const independentDirectorDatabaseIndex = r => require.ensure([], () => r(require('@/view/independentDirectorDatabase/index')), errorCallback, 'independentDirectorDatabase')
const independentDirectorDatabaseOverview = r => require.ensure([], () => r(require('@/view/independentDirectorDatabase/overview')), errorCallback, 'independentDirectorDatabase')
const independentDirectorDatabaseList = r => require.ensure([], () => r(require('@/view/independentDirectorDatabase/list')), errorCallback, 'independentDirectorDatabase')

// 智能报告相关
const smartReportList = r => require.ensure([], () => r(require('@/view/smartReport/list/list')), errorCallback, 'smartReport')
const smartDailyReportList = r => require.ensure([], () => r(require('@/view/smartReport/list/daily/dailyList.vue')), errorCallback, 'smartReport')
const smartVisualReportList = r => require.ensure([], () => r(require('@/view/smartReport/list/visual/visualList.vue')), errorCallback, 'smartReport')
const smartSummaryReportList = r => require.ensure([], () => r(require('@/view/smartReport/list/summary/summaryList.vue')), errorCallback, 'smartReport')
const smartSentimentReportList = r => require.ensure([], () => r(require('@/view/smartReport/list/sentiment/sentimentReportList.vue')), errorCallback, 'smartReport')
const regularSmartReportEdit = r => require.ensure([], () => r(require('@/view/smartReport/edit/dailyEdit')), errorCallback, 'dailysmartReport')
const sentimentSmartReportEdit = r => require.ensure([], () => r(require('@/view/smartReport/edit/sentimentEdit')), errorCallback, 'dailysmartReport')
const summarySmartReportEdit = r => require.ensure([], () => r(require('@/view/smartReport/edit/summaryEdit')), errorCallback, 'dailysmartReport')
const visualSmartReportEdit = r => require.ensure([], () => r(require('@/view/smartReport/edit/visualEdit')), errorCallback, 'dailysmartReport')
const smartReportDetailTransit = r => require.ensure([], () => r(require('@/view/smartReport/edit/editTransit')), errorCallback, 'smartReport')
const propaganda = r => require.ensure([], () => r(require('@/view/smartReport/propaganda/propaganda')), errorCallback, 'smartReport')

// 内幕信息知情人
const insiderIndex = r => require.ensure([], () => r(require('@/view/insider/insiderIndex.vue')), errorCallback, 'insider')
const insiderManagement = r => require.ensure([], () => r(require('@/view/insider/insiderManagement/index.vue')), errorCallback, 'insider')
const majorIssuesManagement = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/index.vue')), errorCallback, 'insider')
const addMajorIssues = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/addMajorIssues.vue')), errorCallback, 'insider')
const editMemorandum = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/editMemorandum/editMemorandum.vue')), errorCallback, 'insider')
const majorIssuesDetail = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/majorIssuesDetail/index.vue')), errorCallback, 'insider')
const addInsideInfo = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/addInsideInfo/addInsideInfo.vue')), errorCallback, 'insider')
const signatureInitiate = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/signatureInitiate/index.vue')), errorCallback, 'insider')
const memorandumFileEdit = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/memorandumFileEdit.vue')), errorCallback, 'insider')
const registerInsider = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/registerInsider/index.vue')), errorCallback, 'insider')
const insiderEnrollDetail = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/insiderEnrollDetail/index.vue')), errorCallback, 'insider')
const majorIssuesManagementPdf = r => require.ensure([], () => r(require('@/view/insider/setPdfOptions/index.vue')), errorCallback, 'insider')
const majorIssuesManagementManual = r => require.ensure([], () => r(require('@/view/insider/majorIssuesManagement/majorIssuesManagementManual/index.vue')), errorCallback, 'insider')

// 权限速查相关
const transactionStandardLookup = r => require.ensure([], () => r(require('@/view/lookupTables/index')), errorCallback, 'lookupTables')
const accessTables = r => require.ensure([], () => r(require('@/view/lookupTables/accessTables/index')), errorCallback, 'lookupTables')
const lookupViewDetail = r => require.ensure([], () => r(require('@/view/lookupTables/accessTables/viewDetail')), errorCallback, 'lookupTables')
const cumulativeCalculation = r => require.ensure([], () => r(require('@/view/lookupTables/cumulativeCalculation/index.vue')), errorCallback, 'lookupTables')
const customDisclosureStandards = r => require.ensure([], () => r(require('@/view/lookupTables/customStandard/index.vue')), errorCallback, 'lookupTables')
const cumulativeCalculationEdit = r => require.ensure([], () => r(require('@/view/lookupTables/cumulativeCalculation/edit.vue')), errorCallback, 'lookupTables')

// 财务分析相关
const finance = r => require.ensure([], () => r(require('@/view/finance/finance')), errorCallback, 'financeenterprises')
const financeMain = r => require.ensure([], () => r(require('@/view/finance/financeMain')), errorCallback, 'financeenterprises')
const companyFinance = r => require.ensure([], () => r(require('@/view/finance/companyFinance/index')), errorCallback, 'financeenterprises')
const financeRatioAnalysis = r => require.ensure([], () => r(require('@/view/finance/companyFinance/financeRatioAnalysis')), errorCallback, 'financeenterprises')
const financeFinancials = r => require.ensure([], () => r(require('@/view/finance/financeFinancials')), errorCallback, 'financeenterprises')
const financeComparison = r => require.ensure([], () => r(require('@/view/finance/financeComparison/financeComparison')), errorCallback, 'financeenterprises')
const financeInterindustryComparison = r => require.ensure([], () => r(require('@/view/finance/financeComparison/financeInterindustryComparison')), errorCallback, 'financeenterprises')
const financeComparableComparison = r => require.ensure([], () => r(require('@/view/finance/financeComparison/financeComparableComparison')), errorCallback, 'financeenterprises')
const financeFollowComparison = r => require.ensure([], () => r(require('@/view/finance/financeComparison/financeFollowComparison')), errorCallback, 'financeenterprises')
const financeAnalyse = r => require.ensure([], () => r(require('@/view/finance/financeAnalyse')), errorCallback, 'financeenterprises')
const exportManage = r => require.ensure([], () => r(require('@/view/finance/importFinanceManage.vue')), errorCallback, 'financeenterprises')

// 公告编制工具相关
const afficheWorkOut = r => require.ensure([], () => r(require('@/view/afficheWorkOut/index')), errorCallback, 'financeenterprises')
const afficheWorkOutInventory = r => require.ensure([], () => r(require('@/view/afficheWorkOut/inventory/index')), errorCallback, 'financeenterprises')
const afficheWorkOutMatter = r => require.ensure([], () => r(require('@/view/afficheWorkOut/matter/index')), errorCallback, 'financeenterprises')
const afficheWorkOutEdit = r => require.ensure([], () => r(require('@/view/afficheWorkOut/matter/edit')), errorCallback, 'financeenterprises')

// 信披清单相关
const disclosure = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/index')), errorCallback, 'disclosure')
const disclosureChecklist = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureChecklist/index.vue')), errorCallback, 'disclosure')
const disclosureProceed = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureProceed/index.vue')), errorCallback, 'disclosure')
const disclosureArchive = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureArchive/index.vue')), errorCallback, 'disclosure')
const addDisclosureTemplate = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureChecklist/addIssueTemplate/index.vue')), errorCallback, 'disclosure')
const editIssueTemplate = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureChecklist/editIssueTemplate/index.vue')), errorCallback, 'disclosure')
const addIssue = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureChecklist/addIssue/index.vue')), errorCallback, 'disclosure')
const editIssue = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureProceed/editIssue/index.vue')), errorCallback, 'disclosure')
const setRemind = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureProceed/setRemind/index.vue')), errorCallback, 'disclosure')
const checkIssue = r => require.ensure([], () => r(require('@/view/informationDisclosure/disclosure/disclosureArchive/checkIssue/index.vue')), errorCallback, 'disclosure')

// 公告复核管理
const announcementReview = r => require.ensure([], () => r(require('@/view/announcementReviewManagement/index.vue')), errorCallback, 'announcementReviewManagement')

// 小安日历相关
const calendarIndex = r => require.ensure([], () => r(require('@/view/calendar/index.vue')), errorCallback, 'calendar')
const calendar = r => require.ensure([], () => r(require('@/view/calendar/calendar/calendar.vue')), errorCallback, 'calendar')
const cIndex = r => require.ensure([], () => r(require('@/view/calendar/calendar/index.vue')), errorCallback, 'calendar')
const calendarRemind = r => require.ensure([], () => r(require('@/view/calendar/backlog/index.vue')), errorCallback, 'calendar')

// 定期报告复核相关
const reviewReport = r => require.ensure([], () => r(require('@/view/reviewReport/reportManage/index')), errorCallback, 'reviewReport')
const reviewReportDetail = r => require.ensure([], () => r(require('@/view/reviewReport/reportDetail/index')), errorCallback, 'reviewReport')
const businessTips = r => require.ensure([], () => r(require('@/view/reviewReport/businessTips/index')), errorCallback, 'reviewReport')

// 舆情相关
const sentimentIndexNew = r => require.ensure([], () => r(require('@/view/sentiment/index')), errorCallback, 'sentiment')
const mineCompanySentiment = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/index')), errorCallback, 'sentiment')
const mineCompanySentimentList = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/sentimentList/index')), errorCallback, 'sentiment')
const sentimentOverview = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/sentimentOverview/index')), errorCallback, 'sentiment')
const mineSentimentReport = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/sentimentReport/index')), errorCallback, 'sentiment')
const mineSentimentReportList = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/sentimentReport/list')), errorCallback, 'sentiment')
const industrySentiment = r => require.ensure([], () => r(require('@/view/sentiment/industrySentiment/index')), errorCallback, 'sentiment')
const industrySentimentList = r => require.ensure([], () => r(require('@/view/sentiment/industrySentiment/sentimentList/index')), errorCallback, 'sentiment')
const industrySentimentOverview = r => require.ensure([], () => r(require('@/view/sentiment/industrySentiment/sentimentOverview/index')), errorCallback, 'sentiment')
const compareCompanySentiment = r => require.ensure([], () => r(require('@/view/sentiment/compareCompanySentiment/index')), errorCallback, 'sentiment')
const compareCompanySentimentList = r => require.ensure([], () => r(require('@/view/sentiment/compareCompanySentiment/sentimentList/index')), errorCallback, 'sentiment')
const compareCompanySentimentOverview = r => require.ensure([], () => r(require('@/view/sentiment/compareCompanySentiment/sentimentOverview/index')), errorCallback, 'sentiment')
const marketSentiment = r => require.ensure([], () => r(require('@/view/sentiment/marketSentiment/index')), errorCallback, 'sentiment')
const marketSentimentList = r => require.ensure([], () => r(require('@/view/sentiment/marketSentiment/sentimentList/index')), errorCallback, 'sentiment')
const allNetSentiment = r => require.ensure([], () => r(require('@/view/sentiment/allNetSentiment/index')), errorCallback, 'sentiment')
const allNetSentimentList = r => require.ensure([], () => r(require('@/view/sentiment/allNetSentiment/sentimentList/index')), errorCallback, 'sentiment')
const customSentiment = r => require.ensure([], () => r(require('@/view/sentiment/customSentiment/index')), errorCallback, 'sentiment')
const customSentimentList = r => require.ensure([], () => r(require('@/view/sentiment/customSentiment/sentimentList/index')), errorCallback, 'sentiment')
const editReport = r => require.ensure([], () => r(require('@/view/sentiment/mineCompanySentiment/sentimentReport/editReport')), errorCallback, 'sentiment')
const sentimentPlans = r => require.ensure([], () => r(require('@/view/sentiment/sentimentPlan/index')), errorCallback, 'sentiment')
const sentimentsDetail = r => require.ensure([], () => r(require('@/view/sentiment/sentimentDetail/index')), errorCallback, 'sentiment')

// 股东行为分析相关
const stockholder = r => require.ensure([], () => r(require('@/view/stockholder/stockholder')), errorCallback, 'datastockholder')
// 股东数据管理相关
const dataManage = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/dataManage')), errorCallback, 'dataManagestockholder')
const rosterExport = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/rosterExport/rosterExport')), errorCallback, 'rosterExportstockholder')
const groupSet = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/groupSet')), errorCallback, 'datastockholder')
const investorManage = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/investorManage')), errorCallback, 'dataManagestockholder')
const relationshipResearch = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/relationshipResearch')), errorCallback, 'datastockholder')
const surveyRecords = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/surveyRecords')), errorCallback, 'surveyRecordstockholder')
const exportReport = r => require.ensure([], () => r(require('@/view/stockholder/dataManage/exportReport')), errorCallback, 'dataManagestockholder')
// 股东对比分析相关
const comparisonAnalysis = r => require.ensure([], () => r(require('@/view/stockholder/comparisonAnalysis/comparisonAnalysis')), errorCallback, 'Comparisonstockholder')
const stockholderComparison = r => require.ensure([], () => r(require('@/view/stockholder/comparisonAnalysis/stockholderComparison')), errorCallback, 'Comparisonstockholder')
const subitemComparison = r => require.ensure([], () => r(require('@/view/stockholder/comparisonAnalysis/subitemComparison')), errorCallback, 'Comparisonstockholder')
const stockholderStructure = r => require.ensure([], () => r(require('@/view/stockholder/comparisonAnalysis/stockholderStructure')), errorCallback, 'Comparisonstockholder')
const groupComparison = r => require.ensure([], () => r(require('@/view/stockholder/comparisonAnalysis/groupComparison')), errorCallback, 'Comparisonstockholder')
// 最新股东分析相关
const newStockholderAnalysis = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/newStockholderAnalysis')), errorCallback, 'newStockholderAnalysisstockholder')
const importantStock = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/importantStock')), errorCallback, 'newStockholderAnalysisstockholder')
const stockStruct = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/stockStruct')), errorCallback, 'newStockholderAnalysisstockholder')
const stockPositionAnalysis = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/positionAnalysis')), errorCallback, 'newStockholderAnalysisstockholder')
const stockRollOfficial = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/stockRollOfficial')), errorCallback, 'newStockholderAnalysisstockholder')
const marketInstitution = r => require.ensure([], () => r(require('@/view/stockholder/newStockholderAnalysis/marketInstitution')), errorCallback, 'newStockholderAnalysisstockholder')
// 股东个人信息相关
const institutionIndex = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionIndex')), errorCallback, 'institutionstockholder')
const institutionAccountInfo = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionAccountInfo')), errorCallback, 'institutionstockholder')
const institutionBaseInfo = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionBaseInfo')), errorCallback, 'institutionstockholder')
const institutionManageInfo = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionManageInfo')), errorCallback, 'institutionstockholder')
const institutionOtherInfo = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionOtherInfo')), errorCallback, 'institutionstockholder')
const institutionRelation = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionRelation')), errorCallback, 'institutionstockholder')
const institutionRisk = r => require.ensure([], () => r(require('@/view/stockholder/institutionPerson/institutionRisk')), errorCallback, 'institutionstockholder')
// 股东趋势分析相关
const trendAnalysis = r => require.ensure([], () => r(require('@/view/stockholder/trendAnalysis/trendAnalysis')), errorCallback, 'trendAnalysisstockholder')
// 机构股东分析相关
const institutionalShareholders = r => require.ensure([], () => r(require('@/view/stockholder/institutionalShareholders/institutionalShareholders')), errorCallback, 'institutionalShareholders')
const latestHoldings = r => require.ensure([], () => r(require('@/view/stockholder/institutionalShareholders/latestHoldings')), errorCallback, 'institutionalShareholders')
const comparativeAnalysis = r => require.ensure([], () => r(require('@/view/stockholder/institutionalShareholders/comparativeAnalysis')), errorCallback, 'institutionalShareholders')

// 互动易相关
const investorIndex = r => require.ensure([], () => r(require('@/view/investor/investorIndex')), errorCallback, 'investorSearch')
const investor = r => require.ensure([], () => r(require('@/view/investor/investor/investorSearch')), errorCallback, 'investorSearch')
const investorAnswer = r => require.ensure([], () => r(require('@/view/investor/investorAnswer')), errorCallback, 'investorSearch')
const investorPersonal = r => require.ensure([], () => r(require('@/view/investor/investorPersonal')), errorCallback, 'investorSearch')
const investorLeaderboard = r => require.ensure([], () => r(require('@/view/investor/investorLeaderboard')), errorCallback, 'investorSearch')
const investorLeaderboardBrief = r => require.ensure([], () => r(require('@/view/investor/leaderboard/investorLeaderboardBrief')), errorCallback, 'investorAll')
const investorLeaderboardOverview = r => require.ensure([], () => r(require('@/view/investor/leaderboard/investorLeaderboardOverview')), errorCallback, 'investorAll')

// 研报相关
const researchReportSearch = r => require.ensure([], () => r(require('@/view/researchReport/search/index')), errorCallback, 'researchReport')
const researchReportDetail = r => require.ensure([], () => r(require('@/view/researchReport/detail/index')), errorCallback, 'researchReport')

// 市值分析相关
const capAnalysis = r => require.ensure([], () => r(require('@/view/capAnalysis/capAnalysis')), errorCallback, 'capAnalysis')
const exportAnalysisReport = r => require.ensure([], () => r(require('@/view/capAnalysis/exportAnalysisReport')), errorCallback, 'capAnalysis')
const companyCap = r => require.ensure([], () => r(require('@/view/capAnalysis/companyCap')), errorCallback, 'capAnalysis')
const significantEvent = r => require.ensure([], () => r(require('@/view/capAnalysis/significantEvent')), errorCallback, 'capAnalysis')
const capForecast = r => require.ensure([], () => r(require('@/view/capAnalysis/capForecast')), errorCallback, 'capAnalysis')
const multiDimensionalAnalysis = r => require.ensure([], () => r(require('@/view/capAnalysis/multiDimensionalAnalysis')), errorCallback, 'capAnalysis')
const marketOverview = r => require.ensure([], () => r(require('@/view/capAnalysis/marketOverview')), errorCallback, 'capAnalysis')
const simbleSenimentList = r => require.ensure([], () => r(require('@/view/sentiment/simbleSenimentList/index')), errorCallback, 'sentiment') // 市值分析引用舆情

// 小安问答相关
const inhopeFAQIndex = r => require.ensure([], () => r(require('@/view/inhopeFAQ/inhopeFAQIndex.vue')), errorCallback, 'xinhu')
const inhopeFAQList = r => require.ensure([], () => r(require('@/view/inhopeFAQ/inhopeFAQList.vue')), errorCallback, 'xinhu')
const inhopeFAQQuestionDetail = r => require.ensure([], () => r(require('@/view/inhopeFAQ/inhopeFAQQuestionDetail.vue')), errorCallback, 'xinhu')

// 知识共享平台相关
const knowledgeCenterIndex = r => require.ensure([], () => r(require('@/view/knowledgeCenter/knowledgeCenterIndex.vue')), errorCallback, 'internalXinHh')
const knowledgeCenterMain = r => require.ensure([], () => r(require('@/view/knowledgeCenter/components/knowledgeCenterMain.vue')), errorCallback, 'internalXinHh')
const knowledgeCenterAnswerDetail = r => require.ensure([], () => r(require('@/view/knowledgeCenter/components/knowledgeCenterAnswerDetail.vue')), errorCallback, 'internalXinHh')

// 在线测试相关
const onlineTestHome = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestHome')), errorCallback, 'onlineTest')
const onlineTest = r => require.ensure([], () => r(require('@/view/onlineTest/index')), errorCallback, 'onlineTest')
const onlineTestMain = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestMain')), errorCallback, 'onlineTest')
const onlineTestAnswer = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestAnswer')), errorCallback, 'onlineTest')
const onlineTestResult = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestResult')), errorCallback, 'onlineTest')
const onlineTestResultDetail = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestResultDetail')), errorCallback, 'onlineTest')
const onlineTestResultCredential = r => require.ensure([], () => r(require('@/view/onlineTest/onlineTestResultCredential')), errorCallback, 'onlineTest')

// 云译相关
const yunyiUpload = r => require.ensure([], () => r(require('@/view/yunyi/upload/yunyiUpload')), errorCallback, 'yunyi')

// 小安AI合规助手相关
const complianceAssistant = r => require.ensure([], () => r(require('@/view/ai/complianceAssistant/index')), errorCallback, 'complianceAssistant')

// 监控管理
const monitor = r => require.ensure([], () => r(require('@/view/monitor/index')), errorCallback, 'monitor')
const monitorMainnav = r => require.ensure([], () => r(require('@/view/monitor/mainnav')), errorCallback, 'monitor')
const monitorManage = r => require.ensure([], () => r(require('@/view/monitor/manage/index')), errorCallback, 'monitor')

const monitorDetail = r => require.ensure([], () => r(require('@/view/monitor/center/detail/index')), errorCallback, 'monitor')
const monitorEdit = r => require.ensure([], () => r(require('@/view/monitor/manage/monitorEdit/index')), errorCallback, 'monitor')
const monitoringCenter = r => require.ensure([], () => r(require('@/view/monitor/center/index')), errorCallback, 'monitor')
const dataSearch = r => require.ensure([], () => r(require('@/view/monitor/dataSearch/index')), errorCallback, 'monitor')

// 资源中心相关
const resourceCenterWhitePaper = r => require.ensure([], () => r(require('@/view/resourceCenter/whitePaper/whitePaper')), errorCallback, 'whitePaper')
const resourceCenterWhitePaperDetail = r => require.ensure([], () => r(require('@/view/resourceCenter/whitePaper/detail/whitePaperDetail.vue')), errorCallback, 'whitePaper')
const resourceCenterSelectedPublication = r => require.ensure([], () => r(require('@/view/resourceCenter/selectedPublication/selectedPublication')), errorCallback, 'selectedPublication')
const resourceCenterSelectedPublicationDetail = r => require.ensure([], () => r(require('@/view/resourceCenter/selectedPublication/detail/selectedPublicationDetail')), errorCallback, 'selectedPublication')
const resourceCenterLatestActivity = r => require.ensure([], () => r(require('@/view/resourceCenter/latestActivity/latestActivity')), errorCallback, 'latestActivity')

// 企业管理相关
const admin = r => require.ensure([], () => r(require('@/view/admin/admin')), errorCallback, 'enterprisesAdmin')
const adminMembers = r => require.ensure([], () => r(require('@/view/admin/members')), errorCallback, 'enterprisesAdmin')
const enterpriseMemberManage = r => require.ensure([], () => r(require('@/view/admin/enterpriseMemberManage')), errorCallback, 'enterprisesAdmin')
const adminApplication = r => require.ensure([], () => r(require('@/view/admin/application')), errorCallback, 'enterprisesAdmin')
const adminApplicationCompare = r => require.ensure([], () => r(require('@/view/admin/components/application/compare.vue')), errorCallback, 'enterprisesAdmin')
const adminApplicationSetEmail = r => require.ensure([], () => r(require('@/view/admin/components/application/setEmail.vue')), errorCallback, 'enterprisesAdmin')
const organizationOperation = r => require.ensure([], () => r(require('@/view/admin/organizationOperation')), errorCallback, 'enterprisesAdmin')
const projectGroup = r => require.ensure([], () => r(require('@/view/admin/projectGroup/projectGroup')), errorCallback, 'enterprisesAdmin')
const projectGroupItem = r => require.ensure([], () => r(require('@/view/admin/projectGroup/components/projectGroupItem')), errorCallback, 'enterprisesAdmin')
const projectRole = r => require.ensure([], () => r(require('@/view/admin/projectGroup/components/projectRole')), errorCallback, 'enterprisesAdmin')
const projectGroupDetail = r => require.ensure([], () => r(require('@/view/admin/projectGroup/components/projectGroupDetail')), errorCallback, 'enterprisesAdmin')
const checkRolePermission = r => require.ensure([], () => r(require('@/view/admin/projectGroup/checkRolePermission/index')), errorCallback, 'enterprisesAdmin')

// 个人中心相关
const login = r => require.ensure([], () => r(require('@/view/passport/loginLog')), errorCallback, 'user')
const register = r => require.ensure([], () => r(require('@/view/passport/registerLog')), errorCallback, 'user')
const findPassword = r => require.ensure([], () => r(require('@/view/passport/findPassword')), errorCallback, 'user')
const personInfo = r => require.ensure([], () => r(require('@/view/passport/personInfo')), errorCallback, 'user')
const purchaseApplication = r => require.ensure([], () => r(require('@/view/purchaseApplication/purchaseApplication')), errorCallback, 'user')
const strategy = r => require.ensure([], () => r(require('@/view/purchaseApplication/strategy')), errorCallback, 'user')
const successApplication = r => require.ensure([], () => r(require('@/view/purchaseApplication/successApplication')), errorCallback, 'user')
const manage = r => require.ensure([], () => r(require('@/view/user/manage/manage.vue')), errorCallback, 'user')
const account = r => require.ensure([], () => r(require('@/view/user/manage/account')), errorCallback, 'user')
const person = r => require.ensure([], () => r(require('@/view/passport/person.vue')), errorCallback, 'user')
const changeCompany = r => require.ensure([], () => r(require('@/view/user/manage/changeCompany')), errorCallback, 'change')
const follow = r => require.ensure([], () => r(require('@/view/user/follow/follow.vue')), errorCallback, 'user')
const followCompanies = r => require.ensure([], () => r(require('@/view/user/follow/companies.vue')), errorCallback, 'followUsers')
const followRegulations = r => require.ensure([], () => r(require('@/view/user/follow/regulations.vue')), errorCallback, 'followUsers')

const userCenterHome = r => require.ensure([], () => r(require('@/view/user/index.vue')), errorCallback, 'user')
const served = r => require.ensure([], () => r(require('@/view/user/served/index.vue')), errorCallback, 'user')
const servedCompanies = r => require.ensure([], () => r(require('@/view/user/served/companies.vue')), errorCallback, 'followUsers')
const adminComparableCompanies = r => require.ensure([], () => r(require('@/view/user/comparable/index.vue')), errorCallback, 'followUsers')
const favorite = r => require.ensure([], () => r(require('@/view/user/collect/favorite.vue')), errorCallback, 'user')
const favoriteDetail = r => require.ensure([], () => r(require('@/view/user/collect/favoriteDetail')), errorCallback, 'collect')
const works = r => require.ensure([], () => r(require('@/view/user/works/works.vue')), errorCallback, 'user')
const worksNoteLaw = r => require.ensure([], () => r(require('@/view/user/works/noteLaw.vue')), errorCallback, 'works')
const worksNoteList = r => require.ensure([], () => r(require('@/view/user/works/noteList.vue')), errorCallback, 'works')

// 独董相关
const dashboardPropaganda = r => require.ensure([], () => r(require('@/view/independentDirector/dashboardPropaganda')), errorCallback, 'dashboardPropaganda')
const independentDirector = r => require.ensure([], () => r(require('@/view/independentDirector/index')), errorCallback, 'independentDirector')
const onlinePay = r => require.ensure([], () => r(require('@/view/independentDirector/onlinePay')), errorCallback, 'onlinePay')
const purchaseRecord = r => require.ensure([], () => r(require('@/view/user/purchaseRecord')), errorCallback, 'independentDirector') // 我的购买记录
const myPurchaseRecord = r => require.ensure([], () => r(require('@/view/user/purchaseRecord/components/myPurchaseRecord')), errorCallback, 'independentDirector') // 购买记录
const invoiceProgress = r => require.ensure([], () => r(require('@/view/user/purchaseRecord/components/invoiceProgress')), errorCallback, 'independentDirector') // 开票进度
const applyForInvoicing = r => require.ensure([], () => r(require('@/view/user/purchaseRecord/applyForInvoicing')), errorCallback, 'independentDirector') // 申请开票
const independentDirectorDutyList = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/index')), errorCallback, 'independentDirectorDutyList') // 独董履职清单首页
const checkList = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/checkList')), errorCallback, 'independentDirectorDutyList') // 已归档事项
const ongoingMatters = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/ongoingMatters')), errorCallback, 'independentDirectorDutyList') // 进行中事项
const archivedMatters = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/archivedMatters')), errorCallback, 'independentDirectorDutyList') // 履职清单页面
const ongoingMattersDetail = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/ongoingMattersDetail')), errorCallback, 'independentDirectorDutyList') // 进行中事项详情
const archivedMattersDetail = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/archivedMattersDetail')), errorCallback, 'independentDirectorDutyList') // 已归档事项详情
const ongoingCommunicationDetail = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/ongoingCommunicationDetail')), errorCallback, 'independentDirectorDutyList') // 进行中的沟通记录详情
const archivedCommunicationDetail = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/archivedCommunicationDetail')), errorCallback, 'independentDirectorDutyList') // 已归档沟通记录详情
const dutyListGuide = r => require.ensure([], () => r(require('@/view/independentDirectorDutyList/guide')), errorCallback, 'independentDirectorDutyList') // 独董履职清单指引

// 权益变动
const changeEquityIndex = r => require.ensure([], () => r(require('@/view/changeEquity/index')), errorCallback, 'changeEquity')
const approvalList = r => require.ensure([], () => r(require('@/view/changeEquity/myApproval/myApprovalList/approvalList.vue')), errorCallback, 'changeEquity')
const shareholderForm = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/shareholderForm/shareholderForm.vue')), errorCallback, 'changeEquity')
const shareholderList = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/shareholderList/shareholderList.vue')), errorCallback, 'changeEquity')
const companyInformationSet = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/companyInformationSet/companyInformationSet.vue')), errorCallback, 'changeEquity')
const createConsultationItem = r => require.ensure([], () => r(require('@/view/changeEquity/consultationMatters/createConsultationItem/index.vue')), errorCallback, 'changeEquity') // 创建咨询事项
const editConsultationItem = r => require.ensure([], () => r(require('@/view/changeEquity/consultationMatters/editConsultationItem/index.vue')), errorCallback, 'changeEquity') // 编辑咨询事项
const checkConsultationItem = r => require.ensure([], () => r(require('@/view/changeEquity/consultationMatters/checkConsultationItem/index.vue')), errorCallback, 'changeEquity') // 查看咨询事项
const subsequentEvent = r => require.ensure([], () => r(require('@/view/changeEquity/consultationMatters/subsequentEvent/index.vue')), errorCallback, 'changeEquity') // 追加/编辑期后事项
const shareholderDetail = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/shareholderDetail/shareholderDetail.vue')), errorCallback, 'changeEquity')
const consultationList = r => require.ensure([], () => r(require('@/view/changeEquity/consultationMatters/consultationList/consultationList.vue')), errorCallback, 'changeEquity')
const approvalMatter = r => require.ensure([], () => r(require('@/view/changeEquity/myApproval/approvalMatter/index.vue')), errorCallback, 'changeEquity')
const checkApproval = r => require.ensure([], () => r(require('@/view/changeEquity/myApproval/checkApproval/index.vue')), errorCallback, 'changeEquity')
const operateLog = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/operateLog/index.vue')), errorCallback, 'changeEquity')
const operateLogEnterPrise = r => require.ensure([], () => r(require('@/view/changeEquity/shareholderInformation/operateLogEnterPrise/index.vue')), errorCallback, 'changeEquity')

// 其他相关
const indexNoPermission = r => require.ensure([], () => r(require('@/view/index/indexNoPermission')), errorCallback, 'home')
const agreement = r => require.ensure([], () => r(require('@/view/index/agreement')), errorCallback, 'home')
const privacy = r => require.ensure([], () => r(require('@/view/index/privacy')), errorCallback, 'home')
const redirect = r => require.ensure([], () => r(require('@/view/redirect/redirect')), errorCallback, 'user')
const wxTransfer = r => require.ensure([], () => r(require('@/view/user/manage/wxTransfer')), errorCallback, 'user')
const previewFile = r => require.ensure([], () => r(require('@/view/filePage/previewFile')), errorCallback, 'filePermission') // 文件权限
const editFile = r => require.ensure([], () => r(require('@/view/filePage/editFile/index')), errorCallback, 'filePermission') // 文件权限
const regulator = r => require.ensure([], () => r(require('@/view/enterprises/enterpriseDashboardWrapper.vue')), errorCallback, 'regulatorenterprises')
const repair = r => require.ensure([], () => r(require('@/view/repair/repair')), errorCallback, 'user')
const onlineServiceIndex = r => require.ensure([], () => r(require('@/view/onlineService/onlineServiceIndex')), errorCallback, 'onlineService') // 在线咨询
const networkError = r => require.ensure([], () => r(require('@/view/error/networkError')), errorCallback, 'error404')
const error404 = r => require.ensure([], () => r(require('@/view/error/error404')), errorCallback, 'error404')

// 专题数据相关
const refinancing = r => require.ensure([], () => r(require('@/view/refinancing/index')), errorCallback, 'refinancing')
const noPublicOfferingOverView = r => require.ensure([], () => r(require('@/view/refinancing/noPublicOffering/detail/noPublicOffering.vue')), errorCallback, 'refinancing')
const noPublicOfferingList = r => require.ensure([], () => r(require('@/view/refinancing/noPublicOffering/list/noPublicOfferingList.vue')), errorCallback, 'refinancing')
const convertibleBonds = r => require.ensure([], () => r(require('@/view/refinancing/convertibleBonds/convertibleBonds.vue')), errorCallback, 'refinancing')
const convertibleBondsDetail = r => require.ensure([], () => r(require('@/view/refinancing/convertibleBonds/convertibleBondsDetail/convertibleBondsDetail.vue')), errorCallback, 'refinancing')

// 独董履职管理
const independentDirectorPerformanceManageIndex = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/index')), errorCallback, 'independentDirectorPerformanceManage')
const planManage = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/planManage/index')), errorCallback, 'independentDirectorPerformanceManage')
const editProject = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/planManage/editProject/index')), errorCallback, 'independentDirectorPerformanceManage')
const performanceRecord = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/performanceRecord/index')), errorCallback, 'independentDirectorPerformanceManage')
const addTodoList = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/planManage/addTodoList/index')), errorCallback, 'independentDirectorPerformanceManage')
const templateManage = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/templateManage/index')), errorCallback, 'independentDirectorPerformanceManage')
const reportManage = r => require.ensure([], () => r(require('@/view/independentDirectorPerformanceManage/reportManage/index')), errorCallback, 'independentDirectorPerformanceManage')

// 文档管理
const documentManagement = r => require.ensure([], () => r(require('@/view/documentManagement/documentManagement')), errorCallback, 'documentManagement')
const documentManagementCenter = r => require.ensure([], () => r(require('@/view/documentManagement/documentManagementCenter/documentManagementCenter')), errorCallback, 'documentManagement')
const documentReviewResult = r => require.ensure([], () => r(require('@/view/documentManagement/documentReview/documentReviewResult')), errorCallback, 'documentManagement')
const securityIntroduction = r => require.ensure([], () => r(require('@/view/documentManagement/securityIntroduction/index')), errorCallback, 'documentManagement')

/**
 * @params loginState 0 无需登录 1 需要登录
 * @params hideAside true 页面隐藏侧边栏
 * @params asideOption 侧边栏配置项
 * @params publicPage 是否是多项目公用页面（例如文件预览页）
 * @params whiteList 不进行权限校验的白名单
 * @params redirectPathAfterSwitchProfile 套餐切换后重定向的path 例：电子签名事项详情的redirectPathAfterSwitchProfile：/electronic-signature/index(电子签名首页)
 * @params meta.menuKey 当前路由对应高亮的菜单标识
 * @params hideFooter 隐藏底部栏
 * **/
const URL = [
  {
    path: '/',
    name: 'home',
    component: home,
    redirect: '/index',
    children: [
      // 兼容历史url
      ...redirectUrl,
      { path: '/index', name: 'index', component: index, meta: { loginState: 0, title: '信公小安 - 让董办工作更安心' }, alias: ['/'] },
      // dashboard主页
      {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard,
        meta: { loginState: 1, title: '信公小安企业版', contactModules: [{ module: 'ENTERPRISE_EDITION_HOME' }] }
      },

      // 法规模块
      {
        path: '/rule-entry',
        name: 'rule-entry',
        redirect: '/rules',
        component: emptyRouterView,
        children: [
          {
            path: '/rules',
            name: 'rule',
            component: rule,
            meta: {
              loginState: 0,
              title: '法规检索',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'LAW' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          },
          {
            path: '/compilation',
            name: 'compilationIndex',
            redirect: '/rules/compilation',
            component: compilationIndex,
            children: [
              {
                path: '/rules/compilation',
                name: 'ruleCollect',
                component: ruleCollect,
                meta: { loginState: 1, title: '法规汇编', contactModules: [{ module: 'LAW', resource: 'BASIC' }] }
              },

              {
                path: '/rules/compilation/:id/detail',
                name: 'ruleCollectInfo',
                component: ruleCollectInfo,
                meta: {
                  loginState: 1,
                  title: '法规汇编详情',
                  menuKey: '/rules/compilation',
                  contactModules: [{ module: 'LAW', resource: 'BASIC' }]
                }
              }
            ]
          },
          {
            path: '/rules/:id',
            name: 'ruleDetail',
            component: ruleDetail,
            meta: {
              loginState: 0,
              hideFooter: true,
              title: '法规详情',
              menuKey: '/rules',
              asideOption: ['note', 'collect', 'track'],
              contactModules: [{ module: 'LAW' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          },
          {
            path: '/rules-evolution/:id',
            name: 'ruleEvolution',
            component: ruleEvolution,
            meta: {
              loginState: 1,
              hideFooter: true,
              title: '法规修订对比',
              menuKey: '/rules',
              asideOption: [],
              contactModules: [{ module: 'LAW' }]
            }
          }, {
            path: '/case-letter/:id',
            name: 'citationDetail',
            component: citationDetail,
            meta: {
              loginState: 1,
              title: '引用详情',
              menuKey: '大数据',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'LAW' }]
            }
          }]
      },

      // 公告模块
      {
        path: '/affiche-entry',
        name: 'affiche-entry',
        redirect: '/affiches',
        component: emptyRouterView,
        children: [
          {
            path: '/affiches',
            name: 'affiche',
            component: affiche,
            meta: {
              loginState: 0,
              title: '公告检索',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'ANNOUNCEMENT' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          },
          {
            path: '/affiches/download',
            name: 'afficheDown',
            component: afficheDown,
            meta: {
              loginState: 1, title: '公告年度合集', menuKey: '/affiches-level-two', contactModules: [{ module: 'ANNOUNCEMENT' }]
            }
          },
          {
            path: '/affiches/:id',
            name: 'afficheContent',
            component: afficheDetails,
            meta: {
              loginState: 0,
              hideFooter: true,
              title: '公告详情',
              menuKey: '/affiches-level-two',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'ANNOUNCEMENT' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          }
        ]
      },

      // 行情模块
      {
        path: '/market-condition-entry',
        name: 'market-condition-entry',
        redirect: '/market-condition',
        component: emptyRouterView,
        children: [{
          path: '/market-condition',
          name: 'market',
          component: market,
          meta: { loginState: 1, title: '市场行情', contactModules: [{ module: 'MARKET_DATA', resource: 'BASIC' }] }
        },
        {
          path: '/company-condition',
          name: 'company',
          component: company,
          meta: { loginState: 1, title: '公司行情', contactModules: [{ module: 'MARKET_DATA', resource: 'BASIC' }] }
        },
        {
          path: '/abnormal-condition',
          name: 'abnormal',
          component: abnormal,
          meta: { loginState: 1, title: '异常波动报告', contactModules: [{ module: 'MARKET_DATA', resource: 'BASIC' }] }
        }]
      },
      // IPO案例库
      {
        path: '/ipo-case-library-entry',
        name: 'ipoCaseLibraryEntry',
        component: emptyRouterView,
        redirect: '/ipo-case-library-index',
        children: [
          {
            path: '/ipo-case-library-index',
            name: 'ipoCasesIndex',
            component: ipoCasesIndex,
            redirect: '/ipo-case-libraries',
            children: [
              {
                path: '/ipo-case-libraries',
                name: 'ipoCasesList',
                component: ipoCasesList,
                meta: {
                  loginState: 1,
                  title: 'IPO案例检索',
                  asideOption: ['collect', 'track'],
                  contactModules: [{ module: 'IPO_CASE_LIBRARY' }]
                }
              },
              {
                path: '/ipo-case-library-analysis',
                name: 'ipoCasesOverview',
                component: ipoCasesOverview,
                meta: {
                  loginState: 1,
                  title: '统计分析',
                  asideOption: ['collect', 'track'],
                  contactModules: [{ module: 'IPO_CASE_LIBRARY' }]
                }
              }
            ]
          },
          {
            path: '/listing-standard',
            name: 'ipoListingStandards',
            component: ipoListingStandards,
            meta: {
              loginState: 1,
              title: '上市标准',
              menuKey: '/ipo-case-libraries-level-two',
              contactModules: [{ module: 'IPO_CASE_LIBRARY' }]
            }
          },
          {
            path: '/ipo-case-libraries/:id',
            name: 'ipoCaseLibraryDetail',
            component: ipoCaseLibraryDetail,
            meta: {
              loginState: 1,
              title: 'IPO案例',
              asideOption: ['collect', 'track'],
              hideFooter: true,
              menuKey: '/ipo-case-libraries',
              contactModules: [{ module: 'IPO_CASE_LIBRARY' }]
            }
          }
        ]
      },

      // 监管动态模块
      {
        path: '/supervision-entry',
        name: 'supervision-entry',
        redirect: '/supervision',
        component: emptyRouterView,
        children: [{
          path: '/supervision',
          name: 'activities',
          component: activities,
          meta: { loginState: 0, title: '最新监管动态' }
        }]
      },

      // 违规案例模块
      {
        path: '/violation-cases-entry',
        name: 'violation-cases-entry',
        redirect: '/violation-cases',
        component: emptyRouterView,
        children: [{
          path: '/violation-cases',
          name: 'casesSearch',
          component: casesSearch,
          meta: {
            loginState: 0,
            title: '违规案例检索',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'VIOLATION_CASE' }],
            whiteList: {
              authorityRole: ['OUTER'],
              loginState: 0
            }
          }
        },
        {
          path: '/violation-cases/:id',
          name: 'caseDetail',
          component: caseDetails,
          meta: {
            loginState: 0,
            hideFooter: true,
            title: '案例详情',
            menuKey: '/violation-cases-level-two',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'VIOLATION_CASE' }],
            whiteList: {
              authorityRole: ['OUTER'],
              loginState: 0
            }
          }
        }]
      },

      // 问询函模块
      {
        path: '/inquiry-letters-entry',
        name: 'inquiry-letters-entry',
        redirect: '/inquiry-letters',
        component: emptyRouterView,
        children: [{
          path: '/inquiry-letters',
          name: 'inquiryLetters',
          component: inquiryLetters,
          meta: {
            loginState: 0,
            title: '问询函件检索',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'INQUIRY_LETTER' }],
            whiteList: {
              authorityRole: ['OUTER'],
              loginState: 0
            }
          }
        },
        {
          path: '/inquiry-letters/:id',
          name: 'inquiryLettersDetail',
          component: inquiryLettersDetail,
          meta: {
            loginState: 0,
            hideFooter: true,
            title: '问询函件检索',
            menuKey: '/inquiry-letters',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'INQUIRY_LETTER' }],
            whiteList: {
              authorityRole: ['OUTER'],
              loginState: 0
            }
          }
        }]
      },

      // 定期报告工具
      {
        path: '/regular-report-tool',
        name: 'regular-report-tool',
        redirect: '/regular-report-tool/semiannual',
        component: regularReportIndex,
        children: [{
          path: '/regular-report-tool/semiannual',
          name: 'reportSearch',
          component: reportSearch,
          meta: {
            loginState: 1,
            title: '半年度报告检索',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'REGULAR_REPORT_TOOL' }]
          }
        },
        {
          path: '/regular-report-tool/third-quarter',
          name: 'reportSearch',
          component: reportSearch,
          meta: {
            loginState: 1,
            title: '三季度报告检索',
            asideOption: ['collect', 'track'],
            contactModules: [{ module: 'REGULAR_REPORT_TOOL' }]
          }
        },
        {
          path: '/regular-report-disclosure-date',
          name: 'regularReportDisclosureDate',
          component: regularReportDisclosureDate,
          meta: {
            loginState: 1,
            title: '定期报告披露时间',
            asideOption: ['collect', 'track'],
            contactModules: [
              { module: 'REGULAR_REPORT_TOOL' }
            ]

          }
        }]
      },

      // IPO问询函
      {
        path: '/ipo-inquiry-letters-entry',
        name: 'ipoInquiryLettersEntry',
        component: emptyRouterView,
        redirect: '/ipo-inquiry-letters',
        children: [
          {
            path: '/ipo-inquiry-letters-index',
            name: 'ipoInquiryLettersIndex',
            component: ipoInquiryLettersIndex,
            redirect: '/ipo-inquiry-letters',
            children: [
              {
                path: '/ipo-inquiry-letters',
                name: 'ipoInquiryLettersSearch',
                component: ipoInquiryLettersSearch,
                meta: {
                  loginState: 1,
                  title: 'IPO问询函检索',
                  contactModules: [{ module: 'IPO_INQUIRY_LETTER' }]
                }
              },
              {
                path: '/ipo-inquiry-letters-analysis',
                name: 'ipoInquiryLettersAnalysis',
                component: ipoInquiryLettersAnalysis,
                meta: {
                  loginState: 1,
                  title: '统计分析',
                  contactModules: [{ module: 'IPO_INQUIRY_LETTER' }],
                  hideFooter: true
                }
              }
            ]
          },
          {
            path: '/ipo-inquiry-letters/:id',
            name: 'ipoInquiryLettersDetail',
            component: ipoInquiryLettersDetail,
            meta: {
              loginState: 0,
              title: 'IPO问询函件',
              hideFooter: true,
              menuKey: '/ipo-inquiry-letters',
              asideOption: ['zoom'],
              contactModules: [{ module: 'IPO_INQUIRY_LETTER' }]
            }
          }
        ]
      },
      // 监控
      {
        path: '/monitor',
        name: 'monitor',
        redirect: '/monitor/home',
        component: monitor,
        children: [
          {
            path: '/monitor/home',
            name: 'monitorMainnav',
            redirect: '/monitor/center',
            component: monitorMainnav,
            meta: { loginState: 1, title: '监控助手', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }] },
            children: [
              {
                path: '/monitor/center',
                name: 'monitoringCenter',
                component: monitoringCenter,
                meta: { loginState: 1, title: '监控中心', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }] }
              },
              {
                path: '/monitor/manage',
                name: 'monitorManage',
                component: monitorManage,

                meta: { loginState: 1, title: '监控管理', menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }] }
              },
              {
                path: '/monitor/dataSearch',
                name: 'dataSearch',
                component: dataSearch,
                meta: { loginState: 1, title: '重点交易数据查询', menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }] }
              }
            ]
          },
          {
            path: '/monitor/detail/:id',
            name: 'monitorDetail',
            component: monitorDetail,
            meta: { loginState: 1, title: '监控详情', hideFooter: true, menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }] }
          },
          {
            path: '/monitor/manage/add',
            name: 'monitorAdd',
            component: monitorEdit,
            meta: { loginState: 1, title: '添加自定义监控', hideFooter: true, menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }], type: 'add' }
          },
          {
            path: '/monitor/manage/edit/:id',
            name: 'monitorEdit',
            component: monitorEdit,
            meta: { loginState: 1, title: '编辑监控', hideFooter: true, menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/monitor/manage', type: 'edit' }
          },
          {
            path: '/monitor/manage/view/:id',
            name: 'monitorView',
            component: monitorEdit,
            meta: { loginState: 1, title: '查看监控', hideFooter: true, menuKey: '/monitor/center', contactModules: [{ module: 'MONITOR_ASSISTANT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/monitor/manage', type: 'view' }
          }
        ]
      },

      // 股权激励案例库模块
      {
        path: '/incentive-case-entry',
        name: 'incentive-case-entry',
        redirect: '/incentive-case',
        component: emptyRouterView,
        meta: { loginState: 1, title: '股权激励案例库', contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }] },
        children: [
          {
            path: '/incentive-case',
            name: 'incentiveCase',
            redirect: '/incentive-case/equity-incentive',
            component: incentiveCase,
            meta: { loginState: 1, title: '股权激励案例库', breadcrumb: false, contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }] },
            children: [
              {
                path: '/incentive-case/equity-incentive',
                name: 'equityIncentive',
                component: equityIncentive,
                meta: { loginState: 1, title: '股权激励案例', contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }], asideOption: ['addCase'] }
              },
              {
                path: '/incentive-case/esop',
                name: 'employeeCase',
                component: employeeCase,
                meta: { loginState: 1, title: '员工持股案例', contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }], asideOption: ['addCase'] }
              }
            ]
          },
          {
            path: '/incentive-case/equity-incentive/compare',
            name: 'incentiveCaseDetail',
            component: equityCaseCompare,
            meta: {
              loginState: 1,
              title: '股权激励案例对比',
              hideFooter: true,
              menuKey: '/incentive-case/equity-incentive',
              contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }]
            }
          },
          {
            path: '/incentive-case/esop/compare',
            name: 'employeeCaseDetail',
            component: employeeCaseCompare,
            meta: {
              loginState: 1,
              title: '员工持股计划案例对比',
              menuKey: '/incentive-case/esop',
              hideFooter: true,
              contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }]
            }
          },
          {
            path: '/incentive-case/equity-incentive/:id',
            name: 'incentiveCaseDetail',
            component: equityCaseDetail,
            meta: { loginState: 1, title: '股权激励案例详情', menuKey: '/incentive-case/equity-incentive', contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }], asideOption: ['addCase'], hideFooter: true }
          },
          {
            path: '/incentive-case/esop/:id',
            name: 'employeeCaseDetail',
            component: equityCaseDetail,
            meta: { loginState: 1, title: '员工持股计划案例详情', menuKey: '/incentive-case/esop', contactModules: [{ module: 'INCENTIVE_CASE_LIBRARY' }], asideOption: ['addCase'], hideFooter: true }
          }
        ]
      },

      // 专题数据模块
      {
        path: '/private-placement-entry',
        name: 'private-placement-entry',
        redirect: '/private-placement',
        component: emptyRouterView,
        children: [
          {
            path: '/refinancing',
            name: 'refinancing',
            redirect: '/private-placement',
            component: refinancing,
            children: [
              {
                path: '/private-placement',
                name: 'private-placement',
                component: noPublicOfferingList,
                meta: { loginState: 0, title: '非公开发行股票案例', contactModules: [{ module: 'REFINANCING_CASE_LIBRARY' }] }
              },
              {
                path: '/convertible-bonds',
                name: 'convertibleBonds',
                component: convertibleBonds,
                meta: { loginState: 0, title: '可转债发行案例', contactModules: [{ module: 'REFINANCING_CASE_LIBRARY' }] }
              }
            ]
          },
          {
            path: '/private-placement/:id',
            name: 'private-placement',
            component: noPublicOfferingOverView,
            meta: { loginState: 0, title: '非公开发行股票案例', menuKey: '/private-placement', hideFooter: true, contactModules: [{ module: 'REFINANCING_CASE_LIBRARY' }] }
          },
          {
            path: '/convertible-bonds/:id',
            name: 'convertibleBondsDetail',
            component: convertibleBondsDetail,
            meta: { loginState: 0, title: '可转债详情', menuKey: '/convertible-bonds', hideFooter: true, contactModules: [{ module: 'REFINANCING_CASE_LIBRARY' }] }
          }
        ]
      },

      // 电子签名模块
      {
        path: '/electronic-signature-entry',
        name: 'electronic-signature-entry',
        redirect: '/electronic-signature',
        component: emptyRouterView,
        children: [
          {
            path: '/electronic-signature',
            name: 'signature',
            redirect: '/electronic-signature/index',
            component: signature,
            children: [
              // 电子签名列表页
              {
                path: '/electronic-signature/index',
                name: 'signatureIndex',
                component: signatureIndex,
                meta: { loginState: 1, title: '电子签名', contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'BASIC' }] }
              },
              // 企业用印
              {
                path: '/electronic-signature/company-seal',
                name: 'signatureCompanySeal',
                component: signatureCompanySeal,
                meta: { loginState: 1, title: '企业用印管理', contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'COMPANY_SEAL' }] }
              },
              {
                path: '/electronic-signature/security-illustrate',
                name: 'securityIllustrate',
                component: securityIllustrate,
                meta: { loginState: 0, title: '合规说明', menuKey: '/electronic-signature/index' }
              }
            ]
          },
          {
            path: '/electronic-signature/:id/adjust-position',
            name: 'positionAdjustment',
            component: signaturePositionAdjustment,
            meta: { loginState: 1, title: '设置签名位置', menuKey: '/electronic-signature/index', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE' }], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          },
          {
            path: '/electronic-signature/:id/detail',
            name: 'issueDetail',
            component: signatureIssueDetail,
            meta: { loginState: 1, title: '电子签名事项详情', menuKey: '/electronic-signature/index', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          },
          {
            path: '/electronic-signature/:id/edit',
            name: 'issueEdit',
            component: signatureIssueEdit,
            meta: { loginState: 1, title: '电子签名事项详情', menuKey: '/electronic-signature/index', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          },
          {
            path: '/electronic-signature/company-seal/manage-seal',
            name: 'signatureManageSeal',
            component: signatureManageSeal,
            meta: { loginState: 1, title: '管理印章', menuKey: '/electronic-signature/company-seal', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'COMPANY_SEAL' }], redirectPathAfterSwitchProfile: '/electronic-signature/company-seal' }
          },

          {
            path: '/electronic-signature/company-seal/manage-seal/add',
            name: 'signatureAddSeal',
            component: signatureAddSeal,
            meta: { loginState: 1, title: '新增印章', menuKey: '/electronic-signature/company-seal', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'COMPANY_SEAL' }], redirectPathAfterSwitchProfile: '/electronic-signature/company-seal' }
          },
          {
            path: '/electronic-signature/company-seal/change-seal-manager',
            name: 'changeSealManager',
            component: changeSealManager,
            meta: { loginState: 1, title: '修改印章管理员', menuKey: '/electronic-signature/company-seal', hideFooter: true, contactModules: [{ module: 'ELECTRONIC_SIGNATURE', resource: 'COMPANY_SEAL' }], redirectPathAfterSwitchProfile: '/electronic-signature/company-seal' }
          },
          // 勿删 独立董事从导航栏进入电子签名，显示此页面
          {
            path: '/electronic-signature-introduction',
            name: 'electronicSignatureIntroduction',
            component: electronicSignatureIntroduction,
            meta: { loginState: 0, title: '电子签名', hideFooter: true }
          },
          {
            path: '/electronic-signature/bind-wechat',
            name: 'electronicSignatureBindWechat',
            component: electronicSignatureBindWechat,
            meta: { loginState: 0, title: '绑定微信说明', menuKey: '/electronic-signature/index' }
          },
          {
            path: '/guideToWechatSignature',
            name: 'guideToWechatSignature',
            component: guideToWechatSignature,
            meta: { loginState: 0, title: '电子签名', menuKey: '/electronic-signature/index' }
          },
          {
            path: '/electronic-signature/previewPDF',
            name: 'signaturePreviewPDF',
            component: signaturePreviewPDF,
            meta: { loginState: 1, title: '文件预览', publicPage: true, contactModules: [], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          },
          // 企业用印 (股权激励用)
          {
            path: '/company-seal-page',
            name: 'companySealPgae',
            component: companySealPage,
            meta: { loginState: 0, title: '企业用印', publicPage: true, contactModules: [], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          },
          {
            path: '/electronic-signature/company-seal/authentication',
            name: 'signatureEnterpriseAuthentication',
            component: signatureEnterpriseAuthentication,
            meta: { loginState: 0, title: '企业实名认证', publicPage: true, contactModules: [], redirectPathAfterSwitchProfile: '/electronic-signature/index' }
          }
        ]
      },

      // 三会管理模块
      {
        path: '/meeting',
        name: 'meeting',
        component: emptyRouterView,
        redirect: '/meeting/underway',
        children: [
          // 勿删，兼容用户收藏指引页面的情况
          {
            path: '/meeting/guide',
            meta: { title: '三会管理' }
          },
          {
            path: '/meeting/index',
            name: 'meetingIndex',
            component: meetingIndex,
            redirect: '/meeting/underway',
            children: [
              {
                path: '/meeting/underway',
                name: 'meetingUnderway',
                component: meetingUnderway,
                meta: { loginState: 1, title: '进行中会议', contactModules: [{ module: 'MEETING' }] }
              },
              {
                path: '/meeting/all',
                name: 'meetingAll',
                component: meetingAll,
                meta: { loginState: 1, title: '全部会议', contactModules: [{ module: 'MEETING' }] }
              },
              {
                path: '/meeting/statistics',
                name: 'meetingStatistics',
                component: meetingStatistics,
                meta: { loginState: 1, title: '三会统计', contactModules: [{ module: 'MEETING' }] }
              },
              {
                path: '/meeting/set/index',
                name: 'meetingSetIndex',
                component: meetingSetIndex,
                redirect: '/meeting/template',
                children: [
                  {
                    path: '/meeting/template',
                    name: 'meetingTemplate',
                    component: meetingTemplate,
                    meta: { loginState: 1, title: '文件模板设置', contactModules: [{ module: 'MEETING' }] }
                  },
                  {
                    path: '/meeting/message',
                    name: 'meetingMessage',
                    component: meetingMessage,
                    meta: { loginState: 1, title: '短信模板设置', menuKey: '/meeting/template', contactModules: [{ module: 'MEETING' }] }
                  },
                  {
                    path: '/meeting/setup',
                    name: 'meetingSetup',
                    component: meetingSetup,
                    meta: { loginState: 1, title: '会议设置', menuKey: '/meeting/template', contactModules: [{ module: 'MEETING' }] }
                  }
                ]
              }
            ]
          },
          {
            path: '/meeting/:id/edit',
            name: 'meetingEdit',
            component: meetingEdit,
            meta: { loginState: 1, title: '三会详情', hideFooter: true, menuKey: '/meeting/underway', contactModules: [{ module: 'MEETING' }], redirectPathAfterSwitchProfile: '/meeting/underway' }
          },
          {
            path: '/meeting/files/:id/online-edit',
            name: 'meetingOnlineEdit',
            component: meetingOnlineEdit,
            meta: { loginState: 1, title: '三会管理', menuKey: '/meeting/underway', contactModules: [{ module: 'MEETING' }], redirectPathAfterSwitchProfile: '/meeting/underway' }
          },
          {
            path: '/meeting/import-meeting',
            name: 'batchEntryHistoryMeeting',
            component: batchEntryHistoryMeeting,
            meta: { loginState: 1, title: '批量导入历史会议', hideFooter: true, menuKey: '/meeting/all', contactModules: [{ module: 'MEETING' }], redirectPathAfterSwitchProfile: '/meeting/underway' }
          }
        ]
      },

      // 公司制度模块
      {
        path: '/company-system',
        name: 'regulation',
        redirect: '/company-system/library',
        component: regulation,
        children: [
          {
            path: '/company-system/library',
            name: 'regulationLibrary',
            component: regulationLibrary,
            meta: { loginState: 1, title: '治理制度清单', contactModules: [{ module: 'COMPANY_REGULATION' }], serviceCompany: true }
          },
          {
            path: '/company-system/manage',
            name: 'regulationManage',
            component: regulationManage,
            meta: { loginState: 1, title: '公司规章制度', contactModules: [{ module: 'COMPANY_REGULATION' }], serviceCompany: true }
          }
        ]
      },

      // ESG模块
      {
        path: '/esg',
        name: 'esg',
        redirect: '/esg/task',
        component: esg,
        children: [
          {
            path: '/esg/task',
            name: 'esgTask',
            component: esgTask,
            meta: { loginState: 1, title: '任务分配', contactModules: [{ module: 'ESG', resource: 'TASK' }] }
          },
          {
            path: '/esg/task/create',
            name: 'esgTaskCreate',
            component: esgTaskCreate,
            meta: { loginState: 1, title: '创建任务', menuKey: '/esg/task', contactModules: [{ module: 'ESG', resource: 'TASK' }] }
          },
          {
            path: '/esg/task/:id',
            name: 'esgTaskDetail',
            component: esgTaskDetail,
            meta: { loginState: 1, title: '分配指标', menuKey: '/esg/task', contactModules: [{ module: 'ESG', resource: 'TASK' }], redirectPathAfterSwitchProfile: '/esg/task' }
          },
          {
            path: '/esg/subtask',
            name: 'esgSubtask',
            component: esgSubtask,
            meta: { loginState: 1, title: '任务填报', contactModules: [{ module: 'ESG', resource: 'SUBTASK' }] }
          },
          {
            path: '/esg/subtask/:id',
            name: 'esgSubtaskDetail',
            component: esgSubtaskDetail,
            meta: { loginState: 1, title: '任务填报详情', menuKey: '/esg/subtask', contactModules: [{ module: 'ESG', resource: 'SUBTASK' }], redirectPathAfterSwitchProfile: '/esg/subtask' }
          },
          {
            path: '/esg/report',
            name: 'esgReport',
            component: esgReport,
            meta: { loginState: 1, title: '报告管理', contactModules: [{ module: 'ESG', resource: 'ESG_REPORT' }] }
          },
          {
            path: '/esg/subsidiary',
            name: 'esgSubsidiary',
            component: esgSubsidiary,
            meta: { loginState: 1, title: '子公司管理', contactModules: [{ module: 'ESG', resource: 'SUBSIDIARY' }] }
          },
          {
            path: '/esg/configuration',
            name: 'esgConfiguration',
            component: esgConfiguration,
            meta: { loginState: 1, title: '权限设置', contactModules: [{ module: 'ESG', resource: 'PERMISSION_CONTROL' }] }
          }
        ]
      },

      // 独董数据库模块
      {
        path: '/independent-director-database',
        name: 'independentDirectorDatabaseIndex',
        redirect: '/independent-director-database/overview',
        component: independentDirectorDatabaseIndex,
        children: [
          {
            path: '/independent-director-database/overview',
            name: 'independentDirectorDatabaseOverview',
            component: independentDirectorDatabaseOverview,
            meta: { loginState: 1, title: '独董数据库', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DATABASE', resource: 'BASIC' }] }
          },
          {
            path: '/independent-director-database/list',
            name: 'independentDirectorDatabaseList',
            component: independentDirectorDatabaseList,
            meta: { loginState: 1, title: '独董数据库', menuKey: '/independent-director-database/overview', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DATABASE', resource: 'BASIC' }] }
          }
        ]
      },

      // 智能报告模块
      {
        path: '/smart-report-entry',
        name: 'smart-report-entry',
        redirect: '/board-office-tools/index',
        component: emptyRouterView,
        children: [
          {
            path: '/board-office-tools/index',
            name: 'smartReportList',
            redirect: '/smart-report/regular-report',
            component: smartReportList,
            meta: { loginState: 1, title: '智能报告', contactModules: [] },
            children: [
              {
                path: '/smart-report/regular-report',
                component: smartDailyReportList,
                meta: { loginState: 1, title: '日常报告', contactModules: [{ module: 'REGULAR_REPORT', resource: 'BASIC' }] }
              },
              {
                path: '/smart-report/visual-periodic-report',
                component: smartVisualReportList,
                meta: { loginState: 1, title: '可视化定期报告', contactModules: [{ module: 'VISUAL_PERIODIC_REPORT', resource: 'BASIC' }] }
              },
              {
                path: '/smart-report/summary-report',
                component: smartSummaryReportList,
                meta: { loginState: 1, title: '董办总结报告', contactModules: [{ module: 'SUMMARY_REPORT', resource: 'BASIC' }] }
              }
            ]
          },
          {
            path: '/smart-report/smart-sentiment',
            name: 'sentimentReportList',
            component: smartSentimentReportList,
            meta: { loginState: 1, title: '智能舆情报告', contactModules: [{ module: 'SMART_SENTIMENT_REPORT', resource: 'BASIC' }], serviceCompany: true }
          },
          // 日常报告
          {
            path: '/smart-report/regular-report/:id',
            name: 'regularSmartReportEdit',
            component: regularSmartReportEdit,
            meta: { loginState: 1, title: '智能报告详情', menuKey: '/smart-report/regular-report', contactModules: [{ module: 'REGULAR_REPORT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/smart-report/regular-report', hideFooter: true }
          },
          // 舆情报告
          {
            path: '/smart-report/smart-sentiment/:id',
            name: 'sentimentSmartReportEdit',
            component: sentimentSmartReportEdit,
            meta: { loginState: 1, title: '智能报告详情', menuKey: '/smart-report/smart-sentiment', contactModules: [{ module: 'SMART_SENTIMENT_REPORT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/smart-report/smart-sentiment', serviceCompany: true }
          },
          // 董办总结报告
          {
            path: '/smart-report/summary-report/:id',
            name: 'summarySmartReportEdit',
            component: summarySmartReportEdit,
            meta: { loginState: 1, title: '智能报告详情', menuKey: '/smart-report/summary-report', contactModules: [{ module: 'SUMMARY_REPORT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/smart-report/summary-report', hideFooter: true }
          },
          // 可视化报告
          {
            path: '/smart-report/visual-periodic-report/:id',
            name: 'visualSmartReportEdit',
            component: visualSmartReportEdit,
            meta: { loginState: 1, title: '智能报告详情', menuKey: '/smart-report/visual-periodic-report', contactModules: [{ module: 'VISUAL_PERIODIC_REPORT', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/smart-report/visual-periodic-report', hideFooter: true }
          },
          {
            path: '/board-office-tools/sentiment-report/:id/edit',
            name: 'smartReportDetailTransit',
            component: smartReportDetailTransit,
            meta: { loginState: 1, title: '智能报告详情', contactModules: [{ module: 'SMART_SENTIMENT_REPORT', resource: 'BASIC' }], serviceCompany: true }
          },
          {
            path: '/board-office-tools/smart-report/:id/edit',
            name: 'smartReportDetailTransit',
            component: smartReportDetailTransit,
            meta: { loginState: 1, title: '智能报告详情', contactModules: [] }
          },
          {
            path: '/board-office-tools/smart-report/propaganda',
            name: 'propaganda',
            component: propaganda,
            meta: { loginState: 1, title: '智能报告', menuKey: '/smart-report/regular-report-level-two', contactModules: [] }
          }
        ]
      },

      // 内幕信息知情人
      {
        path: '/insider',
        name: 'insider',
        redirect: '/insider/index',
        component: emptyRouterView,
        children: [
          {
            path: '/insider/index',
            name: 'insiderIndex',
            component: insiderIndex,
            redirect: '/insider/insider-management',
            children: [
              {
                path: '/insider/insider-management',
                name: 'insider-management',
                component: insiderManagement,
                meta: { loginState: 1, title: '内幕信息知情人管理', hideFooter: true, contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
              },
              {
                path: '/insider/major-issues-management',
                name: 'major-issues-management',
                component: majorIssuesManagement,
                meta: { loginState: 1, title: '重大事项管理', hideFooter: true, contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
              }
            ]
          },
          {
            path: '/insider/major-issues-management/issue/add',
            name: 'add-major-issues',
            component: addMajorIssues,
            meta: { loginState: 1, title: '新增事项', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/issue/:id/edit',
            name: 'add-major-issues',
            component: addMajorIssues,
            meta: { loginState: 1, title: '编辑事项', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/memorandum/:id/edit',
            name: 'edit-memorandum',
            component: editMemorandum,
            meta: { loginState: 1, title: '编制备忘录', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/:id/details',
            name: 'major-issues-detail',
            component: majorIssuesDetail,
            meta: { loginState: 1, title: '查看事项', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/:id/supplement',
            name: 'add-inside-info',
            component: addInsideInfo,
            meta: { loginState: 1, title: '补充内幕信息', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/:id/signature/initiate',
            name: 'signature-initiate',
            component: signatureInitiate,
            meta: { loginState: 1, title: '发起签名', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/memorandum/:fileId/files',
            name: 'memorandum-file-edit',
            component: memorandumFileEdit,
            meta: { loginState: 1, title: '签署备忘录', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {

            path: '/insider/major-issues-management/enroll/:id/edit',
            name: 'register-insider',
            component: registerInsider,
            meta: { loginState: 1, title: '登记知情人', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {

            path: '/insider/major-issues-management/enroll/:id/edit/details',
            name: 'insider-enroll-detail',
            component: insiderEnrollDetail,
            meta: { loginState: 1, title: '查看详情', hideFooter: true, menuKey: '/insider/major-issues-management', contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/:id/pdf',
            name: 'major-issues-management-pdf',
            component: majorIssuesManagementPdf,
            meta: { loginState: 1, title: '设置签名位置', hideFooter: true, contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          },
          {
            path: '/insider/major-issues-management/manual',
            name: 'major-issues-management-manual',
            component: majorIssuesManagementManual,
            meta: { loginState: 1, title: '内幕信息知情人登记说明', hideFooter: true, contactModules: [{ module: 'INSIDER_MANAGEMENT', resource: 'BASIC' }] }
          }
        ]
      },

      // 权限速查模块
      {
        path: '/transaction-standard-lookup-entry',
        name: 'transaction-standard-lookup-entry',
        redirect: '/transaction-standard-lookup-home',
        component: emptyRouterView,
        children: [
          {
            path: '/transaction-standard-lookup-home',
            name: 'transactionStandardLookup',
            redirect: '/transaction-standard-lookup',
            component: transactionStandardLookup,
            meta: { loginState: 1, title: '权限速查表', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }] },
            children: [
              {
                path: '/transaction-standard-lookup',
                name: 'accessTables',
                component: accessTables,
                meta: { loginState: 1, title: '权限速查表', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }], serviceCompany: true }
              }, {
                path: '/transaction-standard-lookup/cumulative-calculation',
                name: 'cumulativeCalculation',
                component: cumulativeCalculation,
                meta: { loginState: 1, title: '累计计算管理', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }], serviceCompany: true }
              }
            ]
          },
          {
            path: '/transaction-standard-lookup/detail',
            name: 'lookupViewDetail',
            component: lookupViewDetail,
            meta: { loginState: 1, title: '自定义审议披露标准', hideFooter: true, menuKey: '/transaction-standard-lookup', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/transaction-standard-lookup', serviceCompany: true }
          },
          {
            path: '/transaction-standard-lookup/custom-disclosure-standards',
            name: 'customDisclosureStandards',
            component: customDisclosureStandards,
            meta: { loginState: 1, title: '自定义审议披露标准', hideFooter: true, menuKey: '/transaction-standard-lookup', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/transaction-standard-lookup', serviceCompany: true }
          }, {
            path: '/transaction-standard-lookup/cumulative-calculation/edit',
            name: 'cumulativeCalculationEdit',
            component: cumulativeCalculationEdit,
            meta: { loginState: 1, title: '编辑交易明细', hideFooter: true, menuKey: '/transaction-standard-lookup/cumulative-calculation', contactModules: [{ module: 'DISCLOSURE_THRESHOLD', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/transaction-standard-lookup/cumulative-calculation', serviceCompany: true }
          }
        ]
      },

      // 财务分析模块
      {
        path: '/finance-analysis-entry',
        name: 'finance-analysis-entry',
        redirect: '/finance-analysis/main',
        component: emptyRouterView,
        children: [
          {
            path: '/finance-analysis',
            name: 'finance',
            redirect: '/finance-analysis/company-data',
            component: finance,
            children: [
              {
                path: '/finance-analysis/company-data',
                name: 'companyFinance',
                component: companyFinance,
                meta: { loginState: 1, title: '主要财务数据', contactModules: [{ module: 'FINANCE_ANALYSIS' }] },
                redirect: '/finance-analysis/main',
                children: [
                  {
                    path: '/finance-analysis/main',
                    name: 'financeMain',
                    component: financeMain,
                    meta: { loginState: 1, title: '主要财务数据', menuKey: '/finance-analysis/company-data', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  },
                  {
                    path: '/finance-analysis/company-report',
                    name: 'financials',
                    component: financeFinancials,
                    meta: { loginState: 1, title: '公司财务报表', menuKey: '/finance-analysis/company-data', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  },
                  {
                    path: '/finance-analysis/company-data/ratio-analysis',
                    name: 'financeRatioAnalysis',
                    component: financeRatioAnalysis,
                    meta: { loginState: 1, title: '比率分析', menuKey: '/finance-analysis/company-data', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  }
                ]
              },
              {
                path: '/finance-analysis/data-compare',
                name: 'comparison',
                component: financeComparison,
                meta: { loginState: 1, title: '财务数据对比', contactModules: [{ module: 'FINANCE_ANALYSIS' }] },
                redirect: '/finance-analysis/interindustry-company-compare',
                children: [
                  {
                    path: '/finance-analysis/interindustry-company-compare',
                    name: 'financeInterindustryComparison',
                    component: financeInterindustryComparison,
                    meta: { loginState: 1, title: '同业公司对比', menuKey: '/finance-analysis/data-compare', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  },
                  {
                    path: '/finance-analysis/comparable-company-compare',
                    name: 'financeComparableComparison',
                    component: financeComparableComparison,
                    meta: { loginState: 1, title: '可比公司对比', menuKey: '/finance-analysis/data-compare', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  },
                  {
                    path: '/finance-analysis/follow-company-compare',
                    name: 'financeFollowComparison',
                    component: financeFollowComparison,
                    meta: { loginState: 1, title: '关注公司对比', menuKey: '/finance-analysis/data-compare', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
                  }
                ]
              },
              {
                path: '/finance-analysis/annual-report',
                name: 'analysis',
                component: financeAnalyse,
                meta: { loginState: 1, title: '年度财务分析报告', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
              }
            ]
          },
          {
            path: '/import-finance-analysis',
            name: 'exportManage',
            component: exportManage,
            meta: { loginState: 1, title: '导入财务数据管理', menuKey: '/finance-analysis/main-level-two', contactModules: [{ module: 'FINANCE_ANALYSIS' }], serviceCompany: true }
          }
        ]
      },
      // 公告编制工具模块
      {
        path: '/announcement_writing-entry',
        name: 'announcement_writing-entry',
        redirect: '/announcement_writing',
        component: emptyRouterView,
        children: [
          {
            path: '/announcement_writing',
            name: 'afficheWorkOut',
            redirect: '/announcement-writing/checklist',
            component: afficheWorkOut,
            meta: { loginState: 1, title: '公告编制工具', hideFooter: true, contactModules: [{ module: 'ANNOUNCEMENT_WRITING_TOOL' }], serviceCompany: true },
            children: [
              {
                path: '/announcement-writing/checklist',
                name: 'afficheWorkOutInventory',
                component: afficheWorkOutInventory,
                meta: { loginState: 1, title: '公告编制清单', hideFooter: true, contactModules: [{ module: 'ANNOUNCEMENT_WRITING_TOOL' }], serviceCompany: true }
              },
              {
                path: '/announcement-writing/underway-issues',
                name: 'afficheWorkOutMatter',
                component: afficheWorkOutMatter,
                meta: { loginState: 1, title: '拟披露事项', hideFooter: true, contactModules: [{ module: 'ANNOUNCEMENT_WRITING_TOOL' }], serviceCompany: true }
              },
              {
                path: '/announcement-writing/archived-issues',
                name: 'afficheWorkOutMatter',
                component: afficheWorkOutMatter,
                meta: { loginState: 1, title: '已归档事项', hideFooter: true, contactModules: [{ module: 'ANNOUNCEMENT_WRITING_TOOL' }], serviceCompany: true }
              }
            ]
          },
          {
            path: '/announcement-writing/underway-issues/:id',
            name: 'afficheWorkOutEdit',
            component: afficheWorkOutEdit,
            meta: { loginState: 1, title: '披露事项详情', menuKey: '/announcement-writing/underway-issues', hideFooter: true, contactModules: [{ module: 'ANNOUNCEMENT_WRITING_TOOL' }], redirectPathAfterSwitchProfile: '/announcement-writing/underway-issues', serviceCompany: true },
            children: []
          }
        ]
      },

      // 信息披露清单模块
      {
        path: '/disclosure/checklist-entry',
        name: 'disclosure-entry',
        redirect: '/disclosure/checklist',
        component: emptyRouterView,
        children: [
          {
            path: 'disclosure',
            name: 'disclosure',
            redirect: '/disclosure/checklist',
            component: disclosure,
            children: [
              {
                path: '/disclosure/checklist',
                name: 'checklist',
                component: disclosureChecklist,
                meta: { loginState: 1, title: '信息披露清单', contactModules: [{ module: 'DISCLOSURE_LIST' }], serviceCompany: true }
              },
              {
                path: '/disclosure/underway-issues',
                name: 'proceed',
                component: disclosureProceed,
                meta: { loginState: 1, title: '进行中事项', contactModules: [{ module: 'DISCLOSURE_LIST' }], serviceCompany: true }
              },
              {
                path: '/disclosure/archived-issues',
                name: 'archive',
                component: disclosureArchive,
                meta: { loginState: 1, title: '已归档事项', contactModules: [{ module: 'DISCLOSURE_LIST' }], serviceCompany: true }
              }
            ]
          },
          {
            path: '/disclosure/tempalte/add',
            name: 'addDisclosureTemplate',
            component: addDisclosureTemplate,
            meta: { loginState: 1, hideFooter: true, title: '信息披露清单', menuKey: '/disclosure/checklist', contactModules: [{ module: 'DISCLOSURE_LIST' }], serviceCompany: true }
          },
          {
            path: '/disclosure/tempalte/:id/edit',
            name: 'editIssueTemplate',
            component: editIssueTemplate,
            meta: { loginState: 1, hideFooter: true, title: '信息披露清单', menuKey: '/disclosure/checklist', contactModules: [{ module: 'DISCLOSURE_LIST' }], redirectPathAfterSwitchProfile: '/disclosure/checklist', serviceCompany: true }
          },
          {
            path: '/disclosure/issue/add',
            name: 'addIssue',
            component: addIssue,
            meta: { loginState: 1, hideFooter: true, title: '信息披露清单', menuKey: '/disclosure/checklist', contactModules: [{ module: 'DISCLOSURE_LIST' }], serviceCompany: true }
          },

          {
            path: '/disclosure/issue/:id/edit',
            name: 'editIssue',
            component: editIssue,
            meta: { loginState: 1, hideFooter: true, title: '进行中事项', menuKey: '/disclosure/underway-issues', contactModules: [{ module: 'DISCLOSURE_LIST' }], redirectPathAfterSwitchProfile: '/disclosure/underway-issues', serviceCompany: true }
          },
          {
            path: '/disclosure/issue/:id/set-remind',
            name: 'setRemind',
            component: setRemind,
            meta: { loginState: 1, hideFooter: true, title: '进行中事项', menuKey: '/disclosure/underway-issues', contactModules: [{ module: 'DISCLOSURE_LIST' }], redirectPathAfterSwitchProfile: '/disclosure/underway-issues', serviceCompany: true }
          },
          {
            path: '/disclosure/issue/:id',
            name: 'checkIssue',
            component: checkIssue,
            meta: { loginState: 1, hideFooter: true, title: '已归档事项', menuKey: '/disclosure/archived-issues', contactModules: [{ module: 'DISCLOSURE_LIST' }], redirectPathAfterSwitchProfile: '/disclosure/archived-issues', serviceCompany: true }
          }
        ]
      },
      // 公告复核管理
      {
        path: '/announcement-review',
        name: 'announcementReview',
        component: announcementReview,
        meta: { loginState: 1, hideFooter: true, title: '公告智能复核', contactModules: [] }
      },
      // 小安日历模块
      {
        path: '/calendar',
        name: 'calendar',
        redirect: '/calendars/index',
        component: calendarIndex,
        children: [
          {
            path: '/calendar',
            name: 'calendar',
            redirect: '/calendars/index',
            component: calendar,
            children: [
              {
                path: '/calendars/index',
                name: 'cIndex',
                component: cIndex,
                meta: { loginState: 1, title: '我的日历', contactModules: [{ module: 'XIAOAN_CALENDAR' }] }
              },
              {
                path: '/calendars/events/new',
                name: 'cAddEvent',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '添加提醒',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }]
                }
              },
              {
                path: '/calendars/events/search',
                name: 'cSearchEvent',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '搜索事件',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }]
                }
              },
              {
                path: '/calendars/events/:id',
                name: 'cEventDetail',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '事件详情',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }],
                  redirectPathAfterSwitchProfile: '/calendars/index'
                }
              },
              {
                path: '/calendars/pending-tasks-events/:id',
                name: 'remindDetail',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '事件详情',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }],
                  redirectPathAfterSwitchProfile: '/calendars/index'
                }
              },
              {
                path: '/calendars/:id/edit',
                name: 'cEdit',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '编辑日历',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }],
                  redirectPathAfterSwitchProfile: '/calendars/index'
                }
              },

              {
                path: '/calendars/:id/sharing/link',
                name: 'cShareLink',
                component: cIndex,
                meta: {
                  loginState: 1,
                  title: '共享链接',
                  menuKey: '/calendars/index',
                  contactModules: [{ module: 'XIAOAN_CALENDAR' }],
                  redirectPathAfterSwitchProfile: '/calendars/index'
                }
              }
            ]
          },
          {
            path: '/pending-tasks/list',
            name: 'pendingTasks',
            component: calendarRemind,
            meta: {
              loginState: 1,
              title: '我的待办',
              menuKey: '/pending-tasks/list',
              contactModules: [{ module: 'XIAOAN_CALENDAR' }],
              redirectPathAfterSwitchProfile: '/pending-tasks/list'
            }
          }
        ]
      },

      // 定期报告复核模块
      {
        path: '/review-report-entry',
        name: 'review-report-entry',
        redirect: '/review-report',
        component: emptyRouterView,
        children: [
          {
            path: '/review-report',
            name: 'reviewReport',
            component: reviewReport,
            meta: {
              loginState: 1,
              hideAside: true,
              title: '定期报告复核',
              contactModules: [{ module: 'REVIEW_REPORT', resource: 'BASIC' }],
              hideFooter: true
            }
          },
          {
            path: '/review-report/:id',
            name: 'reviewReportDetail',
            component: reviewReportDetail,
            meta: {
              loginState: 1,
              hideAside: true,
              title: '定期报告复核',
              menuKey: '/review-report',
              contactModules: [{ module: 'REVIEW_REPORT', resource: 'BASIC' }],
              hideFooter: true,
              redirectPathAfterSwitchProfile: '/review-report'
            }
          },
          {
            path: '/review-report/:id/business-tips',
            name: 'businessTips',
            component: businessTips,
            meta: {
              loginState: 1,
              hideAside: true,
              title: '业务性提示汇总',
              menuKey: '/review-report',
              contactModules: [{ module: 'REVIEW_REPORT', resource: 'BASIC' }],
              hideFooter: true,
              redirectPathAfterSwitchProfile: '/review-report'
            }
          }
        ]
      },

      // 舆情监控模块
      {
        path: '/sentiment-entry',
        name: 'sentiment-entry',
        redirect: '/sentiment',
        component: emptyRouterView,
        children: [{
          path: '/sentiment',
          name: 'sentiment-index',
          redirect: '/sentiment/company',
          component: sentimentIndexNew,
          children: [
            {
              path: '/sentiment/company',
              name: 'sentiment-company',
              redirect: '/sentiment/company/overview',
              component: mineCompanySentiment,
              meta: { loginState: 1, title: '本公司舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/company/list',
                  name: 'sentiment-company-list',
                  component: mineCompanySentimentList,
                  meta: { loginState: 1, title: '本公司舆情', tabShow: 1, menuKey: '/sentiment/company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                },
                {
                  path: '/sentiment/company/overview',
                  name: 'sentimentOverview',
                  component: sentimentOverview,
                  meta: { loginState: 1, title: '本公司舆情', tabShow: 1, menuKey: '/sentiment/company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                },

                {
                  path: '/sentiment/company/report',
                  name: 'mineSentimentReport',
                  redirect: '/sentiment/company/report/list',
                  component: mineSentimentReport,
                  meta: { loginState: 1, title: '本公司舆情', tabShow: 1, menuKey: '/sentiment/company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
                  children: [
                    {
                      path: '/sentiment/company/report/list',
                      name: 'mineSentimentReportList',
                      component: mineSentimentReportList,
                      meta: { loginState: 1, title: '本公司舆情', tabShow: 1, menuKey: '/sentiment/company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                    }

                  ]
                }
              ]
            },
            {
              path: '/sentiment/industry',
              name: 'sentiment-industry',
              redirect: '/sentiment/industry/overview',
              component: industrySentiment,
              meta: { loginState: 1, title: '同业公司舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/industry/list',
                  name: 'industrySentimentList',
                  component: industrySentimentList,
                  meta: { loginState: 1, title: '同业公司舆情', tabShow: 1, menuKey: '/sentiment/industry/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                },
                {
                  path: '/sentiment/industry/overview',
                  name: 'industrySentimentOverview',
                  component: industrySentimentOverview,
                  meta: { loginState: 1, title: '同业公司舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                }
              ]
            },
            {
              path: '/sentiment/compare-company',
              name: 'compareCompanySentiment',
              redirect: '/sentiment/compare-company/overview',
              component: compareCompanySentiment,
              meta: { loginState: 1, title: '可比公司舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/compare-company/list',
                  name: 'compareCompanySentimentList',
                  component: compareCompanySentimentList,
                  meta: { loginState: 1, title: '可比公司舆情', tabShow: 1, menuKey: '/sentiment/compare-company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                },
                {
                  path: '/sentiment/compare-company/overview',
                  name: 'compareCompanySentimentOverview',
                  component: compareCompanySentimentOverview,
                  meta: { loginState: 1, title: '可比公司舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                }
              ]
            },
            {
              path: '/sentiment/market',
              name: 'marketSentiment',
              redirect: '/sentiment/market/list',
              component: marketSentiment,
              meta: { loginState: 1, title: '资本市场舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/market/list',
                  name: 'marketSentimentList',
                  component: marketSentimentList,
                  meta: { loginState: 1, title: '资本市场舆情', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                }
              ]
            },
            {
              path: '/sentiment/all-net',
              name: 'allNetSentiment',
              redirect: '/sentiment/all-net/list',
              component: allNetSentiment,
              meta: { loginState: 1, title: '全网检索', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/all-net/list',
                  name: 'allNetSentimentList',
                  component: allNetSentimentList,
                  meta: { loginState: 1, title: '全网检索', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                }
              ]
            },
            {
              path: '/sentiment/custom',
              name: 'customSentiment',
              redirect: '/sentiment/custom/list',
              component: customSentiment,
              meta: { loginState: 1, title: '自定义方案', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'] },
              children: [
                {
                  path: '/sentiment/custom/list',
                  name: 'customSentimentList',
                  component: customSentimentList,
                  meta: { loginState: 1, title: '自定义方案', tabShow: 1, contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
                }
              ]
            },
            {
              path: '/sentiment/company/report/config',
              name: 'editReport',
              component: editReport,
              meta: { loginState: 1, title: '舆情报告', tabShow: 1, menuKey: '/sentiment/company/overview', contactModules: [{ module: 'SENTIMENT' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
            },
            {
              path: '/sentiment/default-plan/edit',
              name: 'sentiment-plans',
              component: sentimentPlans,
              meta: { loginState: 1, title: '方案设置', tabShow: 1, menuKey: '/sentiment/custom/list', contactModules: [{ module: 'SENTIMENT', resource: 'SETTINGS' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
            },
            {
              path: '/sentiment/custom-plan/add',
              name: 'customSentimentPlan',
              component: sentimentPlans,
              meta: { loginState: 1, title: '创建监控方案', tabShow: 1, menuKey: '/sentiment/custom/list', contactModules: [{ module: 'SENTIMENT', resource: 'SETTINGS' }], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
            },
            {
              path: '/sentiment/custom-plan/:planId/edit',
              name: 'editCustomSentimentPlan',
              component: sentimentPlans,
              meta: {
                loginState: 1,
                title: '编辑监控方案',
                tabShow: 1,
                menuKey: '/sentiment/custom/list',
                contactModules: [{ module: 'SENTIMENT', resource: 'SETTINGS' }],
                asideOption: ['collect', 'track', 'feedback'],
                redirectPathAfterSwitchProfile: '/sentiment/custom/list',
                serviceCompany: true
              }
            }
          ]
        },
        {
          path: '/sentiments/:id',
          name: 'sentimentsDetail',
          component: sentimentsDetail,
          meta: { loginState: 1, hideFooter: true, title: '舆情详情', menuKey: '/sentiment/company/overview-level-two', contactModules: [], asideOption: ['collect', 'track', 'feedback'], serviceCompany: true }
        }]
      },

      // 股东行为分析模块（添加股行分子路由时需要在对应menuList中修改路径,用于刷新时获取高亮tab）
      {
        path: '/gxf',
        name: 'gxf',
        component: emptyRouterView,
        redirect: '/stockholders',
        children: [
          {
            path: '/stockholders',
            name: 'stockholder',
            redirect: '/stockholders/import-roster',
            component: stockholder,
            children: [
              {
                path: '/stockholders/data-manage',
                name: 'dataManage',
                redirect: '/stockholders/import-roster',
                component: dataManage,
                children: [
                  {
                    path: '/stockholders/import-roster',
                    name: 'rosterExport',
                    component: rosterExport,
                    meta: {
                      loginState: 1,
                      title: '股东名册导入及管理',
                      menuKey: '/stockholders/import-roster',
                      needDateCount: 0,
                      contactModules: [{ module: 'GXF' }],
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/group-set',
                    name: 'groupSet',
                    component: groupSet,
                    meta: {
                      loginState: 1,
                      title: '股东分组及设置',
                      menuKey: '/stockholders/import-roster',
                      contactModules: [{ module: 'GXF' }],
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/investor-manage',
                    name: 'investorManage',
                    component: investorManage,
                    meta: {
                      loginState: 1,
                      title: '投资者数据库管理',
                      menuKey: '/stockholders/import-roster',
                      needDateCount: 0,
                      contactModules: [{ module: 'GXF' }],
                      serviceCompany: true
                    }
                  }
                ]
              },
              {
                path: '/stockholders/comparison/analysis',
                name: 'comparisonAnalysis',
                redirect: '/stockholders/comparison/overview/analysis',
                component: comparisonAnalysis,
                children: [
                  {
                    path: '/stockholders/comparison/overview/analysis',
                    name: 'comparisonAnalysis-stockholderComparison',
                    component: stockholderComparison,
                    meta: {
                      loginState: 1,
                      title: '股东对比分析总览',
                      needDateCount: 2,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/comparison/analysis',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/comparison/item/analysis',
                    name: 'comparisonAnalysis-subitemComparison',
                    component: subitemComparison,
                    meta: {
                      loginState: 1,
                      title: '分项股东对比分析',
                      menuKey: '/stockholders/comparison/overview/analysis',
                      needDateCount: 2,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/comparison/item/analysis',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/comparison/structure/analysis',
                    name: 'comparisonAnalysis-stockholderStructure',
                    component: stockholderStructure,
                    meta: {
                      loginState: 1,
                      title: '按股东结构对比',
                      menuKey: '/stockholders/comparison/overview/analysis',
                      needDateCount: 2,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/comparison/structure/analysis',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/comparison/group/analysis',
                    name: 'comparisonAnalysis-groupComparison',
                    component: groupComparison,
                    meta: {
                      loginState: 1,
                      title: '分组对比分析',
                      menuKey: '/stockholders/comparison/overview/analysis',
                      needDateCount: 2,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/comparison/group/analysis',
                      serviceCompany: true
                    }
                  }
                ]
              },
              {
                path: '/stockholders/newest/analysis',
                name: 'newStockholderAnalysis',
                redirect: '/stockholders/important',
                component: newStockholderAnalysis,
                children: [
                  {
                    path: '/stockholders/important',
                    name: 'newStockholderAnalysis-importantStock',
                    component: importantStock,
                    meta: {
                      loginState: 1,
                      title: '重要股东',
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/important',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/structure',
                    name: 'newStockholderAnalysis-stockStruct',
                    component: stockStruct,
                    meta: {
                      loginState: 1,
                      title: '股东结构',
                      haveCirculationStock: true,
                      menuKey: '/stockholders/important',
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/structure',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/position-analysis',
                    name: 'newStockholderAnalysis-positionAnalysis',
                    component: stockPositionAnalysis,
                    meta: {
                      loginState: 1,
                      title: '股东持仓分析',
                      haveCirculationStock: true,
                      menuKey: '/stockholders/important',
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/position-analysis',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/roster',
                    name: 'newStockholderAnalysis-stockRollOfficial',
                    component: stockRollOfficial,
                    meta: {
                      loginState: 1,
                      title: '股东名册',
                      haveCirculationStock: true,
                      menuKey: '/stockholders/important',
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/roster',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/investor/search',
                    name: 'newStockholderAnalysis-marketInstitution',
                    component: marketInstitution,
                    meta: {
                      loginState: 1,
                      title: '全市场投资者检索',
                      menuKey: '/stockholders/important',
                      contactModules: [{ module: 'GXF' }],
                      serviceCompany: true
                    }
                  }
                ]
              },
              {
                path: '/stockholders/trend',
                name: 'trendAnalysis',
                component: trendAnalysis,
                meta: {
                  loginState: 1,
                  title: '股东趋势分析',
                  needDateCount: 0,
                  contactModules: [{ module: 'GXF' }],
                  serviceCompany: true
                }
              },
              // 机构股东分析
              {
                path: '/stockholders/institution/analysis',
                name: 'InstitutionalShareholders',
                component: institutionalShareholders,
                redirect: '/stockholders/newest/shareholding',
                children: [
                  {
                    path: '/stockholders/newest/shareholding',
                    name: 'institutionalShareholders-latestHoldings',
                    component: latestHoldings,
                    meta: {
                      loginState: 1,
                      title: '机构股东最新持股情况',
                      needDateCount: 1,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/newest/shareholding',
                      serviceCompany: true
                    }
                  },
                  {
                    path: '/stockholders/institution/comparison/analysis',
                    name: 'institutionalShareholders-comparativeAnalysis',
                    component: comparativeAnalysis,
                    meta: {
                      loginState: 1,
                      title: '机构股东对比分析',
                      menuKey: '/stockholders/newest/shareholding',
                      needDateCount: 2,
                      haveCirculationStock: true,
                      contactModules: [{ module: 'GXF' }],
                      redirectPathAfterSwitchProfile: '/stockholders/institution/comparison/analysis',
                      serviceCompany: true
                    }
                  }
                ]
              },
              // 勿删，兼容用户收藏指引页面的情况
              {
                path: '/stockholders/operation-video',
                meta: {
                  title: '新手教学'
                }
              }
            ]
          },
          {
            path: '/stockholders/:id/detail',
            name: 'institutionIndex',
            redirect: '/stockholders/:id/detail',
            component: institutionIndex,
            children: [
              {
                path: '/stockholders/:id/detail',
                name: 'institutionAccountInfo',
                component: institutionAccountInfo,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster',
                  serviceCompany: true
                }
              },
              {
                path: '/stockholders/:id/detail/baseinfo',
                name: 'institutionBaseInfo',
                component: institutionBaseInfo,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster',
                  serviceCompany: true
                }
              },
              {
                path: '/stockholders/:id/detail/production',
                name: 'institutionManageInfo',
                component: institutionManageInfo,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster',
                  serviceCompany: true
                }
              },
              {
                path: '/stockholders/:id/detail/other',
                name: 'institutionOtherInfo',
                component: institutionOtherInfo,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster',
                  serviceCompany: true
                }
              },
              {
                path: '/stockholders/:id/detail/relation',
                name: 'institutionRelation',
                component: institutionRelation,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster'
                }
              },
              {
                path: '/stockholders/:id/detail/risk',
                name: 'institutionRisk',
                component: institutionRisk,
                meta: {
                  loginState: 1,
                  title: '股东详情',
                  menuKey: '/stockholders/import-roster',
                  contactModules: [{ module: 'GXF' }],
                  redirectPathAfterSwitchProfile: '/stockholders/roster',
                  serviceCompany: true
                }
              }
            ]
          },
          {
            path: '/stockholders/survey-records/add',
            name: 'investorManage-relationshipResearch',
            component: relationshipResearch,
            meta: {
              loginState: 1,
              title: '投资者数据库管理',
              menuKey: '/stockholders/import-roster',
              needDateCount: 0,
              contactModules: [{ module: 'GXF' }],
              hideFooter: true,
              serviceCompany: true
            }
          },
          {
            path: '/stockholders/survey-records',
            name: 'investorManage-surveyRecords',
            component: surveyRecords,
            meta: {
              loginState: 1,
              title: '投资者数据库管理',
              menuKey: '/stockholders/import-roster',
              needDateCount: 0,
              contactModules: [{ module: 'GXF' }],
              hideFooter: true,
              serviceCompany: true
            }
          },
          {
            path: '/stockholders/export-report',
            name: 'exportReport',
            component: exportReport,
            meta: {
              loginState: 1,
              title: '导出分析报告',
              menuKey: '/stockholders/import-roster',
              needDateCount: 0,
              contactModules: [{ module: 'GXF' }],
              hideFooter: true,
              serviceCompany: true
            }
          }
        ]
      },

      // 互动易模块
      {
        path: '/investor',
        name: 'investor-index',
        redirect: '/investor/list',
        component: investorIndex,
        children: [
          {
            path: '/investor/list',
            name: 'investor',
            component: investor,
            meta: {
              loginState: 1,
              title: '投资者关系',
              contactModules: [{ module: 'INVESTOR_QA' }],
              asideOption: ['collect', 'track']
            }
          },
          {
            path: '/investor/questions/:id',
            name: 'investor-answer',
            component: investorAnswer,
            meta: {
              loginState: 1,
              title: '投资者关系',
              menuKey: '/investor/list',
              contactModules: [{ module: 'INVESTOR_QA' }],
              asideOption: ['collect', 'track']
            }
          },
          {
            path: '/investor/asker',
            name: 'investor-personal',
            component: investorPersonal,
            meta: {
              loginState: 1,
              title: '个人投资者关系',
              menuKey: '/investor/list',
              contactModules: [{ module: 'INVESTOR_QA' }]
            }
          },
          {
            path: '/investor/leaderboard',
            name: 'investor-leaderboard',
            redirect: '/investor/market-overview/overview',
            component: investorLeaderboard,
            children: [
              {
                path: '/investor/market-overview/leader-board',
                name: 'investor-leaderboard-brief',
                component: investorLeaderboardBrief,
                meta: {
                  loginState: 1,
                  title: '投资者问答排行榜',
                  check: 'brief',
                  menuKey: '/investor/market-overview/overview',
                  contactModules: [{ module: 'INVESTOR_QA' }]
                }
              },
              {
                path: '/investor/market-overview/overview',
                name: 'investor-leaderboard-overview',
                component: investorLeaderboardOverview,
                meta: {
                  loginState: 1,
                  title: 'e互动市场概览',
                  check: 'overview',
                  contactModules: [{ module: 'INVESTOR_QA' }]
                }
              }
            ]
          }
        ]
      },

      // 研报模块
      {
        path: '/reports-entry',
        name: 'researchReportIndex',
        redirect: '/reports',
        component: emptyRouterView,
        children: [
          {
            path: '/reports',
            name: 'researchReportSearch',
            component: researchReportSearch,
            meta: {
              loginState: 0,
              title: '研报检索',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'RESEARCH_REPORT' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          },
          {
            path: '/reports/:id',
            name: 'researchReportDetail',
            component: researchReportDetail,
            meta: {
              loginState: 0,
              title: '研报详情',
              hideFooter: true,
              menuKey: '/reports',
              asideOption: ['collect', 'share', 'track', 'zoom'],
              contactModules: [{ module: 'RESEARCH_REPORT' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          }
        ]
      },

      // 市值分析模块
      {
        path: '/cap-analysis',
        name: 'capAnalysis',
        redirect: '/cap-analysis/company-cap',
        component: capAnalysis,
        children: [
          {
            path: '/cap-analysis/export-analysis-report',
            name: 'exportAnalysisReport',
            component: exportAnalysisReport,
            meta: {
              loginState: 1,
              title: '导出报告',
              menuKey: '/cap-analysis/company-cap-level-two',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true,
              hideFooter: true
            }
          },
          {
            path: '/cap-analysis/company-cap',
            name: 'companyCap',
            component: companyCap,
            meta: {
              loginState: 1,
              title: '公司市值',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true
            }
          },
          {
            path: '/cap-analysis/significant-event',
            name: 'significantEvent',
            component: significantEvent,
            meta: {
              loginState: 1,
              title: '重大事项',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true
            }
          },
          {
            path: '/cap-analysis/cap-forecast',
            name: 'capForecast',
            component: capForecast,
            meta: {
              loginState: 1,
              title: '市值预测',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true
            }
          },
          {
            path: '/cap-analysis/multi-dimensional-analysis',
            name: 'multiDimensionalAnalysis',
            component: multiDimensionalAnalysis,
            meta: {
              loginState: 1,
              title: '对比分析',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true
            }
          },
          {
            path: '/cap-analysis/market-overview',
            name: 'marketOverview',
            component: marketOverview,
            meta: {
              loginState: 1,
              title: '市场概览',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              serviceCompany: true
            }
          },
          {
            path: '/cap-analysis/significant-event/sentiment',
            name: 'simbleSenimentList',
            component: simbleSenimentList,
            meta: {
              loginState: 1,
              title: '舆情列表',
              tabShow: 1,
              menuKey: '/cap-analysis/company-cap-level-two',
              contactModules: [{ module: 'CAP_ANALYSIS' }],
              asideOption: ['feedback'],
              serviceCompany: true
            }
          }
        ]
      },

      // 小安问答模块
      {
        path: '/inhope-FAQ',
        name: 'inhopeFaqIndex',
        component: inhopeFAQIndex,
        children: [
          {
            path: '/inhope-FAQ',
            name: 'inhopeFaqList',
            component: inhopeFAQList,
            meta: {
              loginState: 0,
              title: '小安问答',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'XIAOAN_QA' }],
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          },
          {
            path: '/inhope-FAQ/questions/:id',
            name: 'inhopeFaqQuestionDetail',
            component: inhopeFAQQuestionDetail,
            meta: {
              loginState: 0,
              title: '小安问答',
              asideOption: ['collect', 'track'],
              contactModules: [{ module: 'XIAOAN_QA' }],
              menuKey: '/inhope-FAQ',
              whiteList: {
                authorityRole: ['OUTER'],
                loginState: 0
              }
            }
          }
        ]
      },

      // 知识共享平台模块
      {
        path: '/knowledge-center',
        name: 'knowledgeCenterIndex',
        component: knowledgeCenterIndex,
        children: [
          {
            path: '/knowledge-center',
            name: 'knowledgeCenterMain',
            component: knowledgeCenterMain,
            meta: {
              loginState: 1,
              title: '知识共享平台',
              contactModules: [{ module: 'INTERNAL_WIKI' }]
            }
          },
          {
            path: '/knowledge-center/questions/:id',
            name: 'knowledgeCenterAnswerDetail',
            component: knowledgeCenterAnswerDetail,
            meta: {
              loginState: 1,
              title: '知识共享平台',
              menuKey: '/knowledge-center',
              contactModules: [{ module: 'INTERNAL_WIKI' }]
            }
          }
        ]
      },

      // 在线测试模块
      {
        path: '/online-test-entry',
        name: 'online-test-entry',
        redirect: '/online-test',
        component: emptyRouterView,
        children: [
          {
            name: 'onlineTestHome',
            path: '/online-test',
            component: onlineTestHome,
            meta: { loginState: 1, title: '在线测试', menuKey: '/online-test', contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }] }
          },
          {
            name: 'onlineTest',
            path: '/online-test/questionnaires',
            component: onlineTest,
            children: [
              {
                name: 'onlineTestMain',
                path: '/online-test/questionnaires/:id',
                component: onlineTestMain,
                meta: {
                  loginState: 1,
                  title: '在线测试',
                  menuKey: '/online-test',
                  contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }],
                  redirectPathAfterSwitchProfile: '/online-test'
                }
              },
              {
                name: 'onlineTestAnswer',
                path: '/online-test/questionnaires/:id/answer',
                component: onlineTestAnswer,
                meta: { loginState: 1, title: '在线测试', menuKey: '/online-test', contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/online-test' }
              },
              {
                name: 'onlineTestResult',
                path: '/online-test/questionnaires/:id/result',
                component: onlineTestResult,
                meta: { loginState: 1, title: '在线测试', menuKey: '/online-test', contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/online-test' }
              },
              {
                name: 'onlineTestResultDetail',
                path: '/online-test/questionnaires/:id/result-detail',
                component: onlineTestResultDetail,
                meta: { loginState: 1, title: '在线测试', menuKey: '/online-test', contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/online-test' }
              },
              {
                name: 'onlineTestResultCredential',
                path: '/online-test/questionnaires/:id/credential',
                component: onlineTestResultCredential,
                meta: { loginState: 1, title: '在线测试', menuKey: '/online-test', contactModules: [{ module: 'ONLINE_TEST', resource: 'BASIC' }], redirectPathAfterSwitchProfile: '/online-test' }
              }
            ]
          }
        ]
      },

      // 云译模块
      {
        path: '/yunyi',
        name: 'yunyi',
        component: yunyiUpload,
        meta: { loginState: 1, title: '云译', contactModules: [{ module: 'XIAOAN_TRANSLATION', resource: 'BASIC' }] }
      },

      // AI助手模块
      {
        path: '/ai-assistant',
        name: 'complianceAssistant',
        component: complianceAssistant,
        meta: {
          loginState: 1,
          hideAside: true,
          title: 'AI助手',
          contactModules: [{ module: 'AI_ASSISTANT', resource: 'BASIC' }],
          hideFooter: true
        }
      },

      // 资源中心模块
      {
        path: '/resource-center-entry',
        name: 'resource-center-entry',
        component: emptyRouterView,
        redirect: '/resource-center/white-paper',
        children: [{
          path: '/resource-center/white-paper',
          name: 'whitePapers',
          component: resourceCenterWhitePaper,
          meta: {
            loginState: 0,
            title: '白皮书'
          }
        },
        {
          path: '/resource-center/white-paper/:id',
          name: 'whitePapersDetail',
          component: resourceCenterWhitePaperDetail,
          meta: {
            loginState: 0,
            title: '白皮书详情',
            menuKey: '/resource-center/white-paper'
          }
        },
        {
          path: '/resource-center/selected-publication',
          name: 'selectedPublication',
          component: resourceCenterSelectedPublication,
          meta: {
            loginState: 0,
            title: '精选刊物'
          }
        },
        {
          path: '/resource-center/selected-publication/:id',
          name: 'selectedPublicationDetail',
          component: resourceCenterSelectedPublicationDetail,
          meta: {
            loginState: 0,
            title: '精选刊物详情',
            menuKey: '/resource-center/selected-publication'
          }
        },
        {
          path: '/resource-center/latest-activity',
          name: 'latestActivity',
          component: resourceCenterLatestActivity,
          meta: {
            loginState: 0,
            title: '最新活动'
          }
        }]
      },

      // 企业管理
      {
        path: '/admin-entry',
        name: 'admin-entry',
        redirect: '/admin',
        component: emptyRouterView,
        children: [
          {
            path: '/admin',
            name: 'enterprises-admin',
            redirect: '/admin/organization-member-manage',
            component: admin,
            children: [
              {
                path: '/admin/organization-member-manage',
                name: 'admin-members',
                component: adminMembers,
                meta: { loginState: 1, title: '组织成员管理', contactModules: [{ module: 'COMPANY_SETTING', resource: 'BASIC' }] }
              },
              {
                path: '/admin/enterprise-member-manage',
                name: 'enterpriseMemberManage',
                component: enterpriseMemberManage,
                meta: { loginState: 1, title: '企业成员管理', contactModules: [{ module: 'INFAITH_USER_SETTING', resource: 'BASIC' }] }
              },
              {
                path: '/admin/project-group-manage',
                name: 'projectGroup',
                redirect: '/admin/project-group-manage/index',
                component: projectGroup,
                meta: { loginState: 1, title: '项目组查询', contactModules: [{ module: 'INFAITH_PROJECT_QUERY', resource: 'BASIC' }] },
                children: [
                  {
                    path: '/admin/project-group-manage/index',
                    name: 'projectGroupItem',
                    component: projectGroupItem,
                    meta: { loginState: 1, title: '项目组', contactModules: [{ module: 'INFAITH_PROJECT_QUERY', resource: 'BASIC' }] }
                  },
                  {
                    path: '/admin/project-group-manage/role',
                    name: 'projectRole',
                    component: projectRole,
                    meta: { loginState: 1, title: '项目角色', contactModules: [{ module: 'INFAITH_PROJECT_QUERY', resource: 'BASIC' }] }
                  }
                ]
              },
              {
                path: '/admin/application-management',
                name: 'admin-application',
                redirect: '/admin/application-management/compare-companies',
                component: adminApplication,
                children: [
                  {
                    path: '/admin/application-management/compare-companies',
                    name: 'admin-application-compare',
                    component: adminApplicationCompare,
                    meta: { loginState: 1, title: '可比公司设置', contactModules: [{ module: 'COMPANY_SETTING', resource: 'BASIC' }] }
                  },
                  {
                    path: '/admin/application-management/set-email',
                    name: 'admin-application-setEmail',
                    component: adminApplicationSetEmail,
                    meta: { loginState: 1, title: '邮箱设置', contactModules: [{ module: 'COMPANY_SETTING', resource: 'SETTINGS' }] }
                  }
                ]
              }
            ]
          },
          {
            path: '/admin/role/:id/permission/check',
            name: 'checkRolePermission',
            component: checkRolePermission,
            meta: { loginState: 1, title: '查看权限', contactModules: [{ module: 'COMPANY_SETTING', resource: 'BASIC' }] }
          },
          {
            path: '/organize-manage',
            name: 'admin-organize-operatie',
            component: organizationOperation,
            meta: { loginState: 1, title: '组织成员管理', contactModules: [{ module: 'COMPANY_SETTING', resource: 'BASIC' }] }
          },
          {
            path: '/project-group-detail/:id',
            name: 'projectGroupDetail',
            component: projectGroupDetail,
            meta: { loginState: 1, title: '查看项目组', contactModules: [{ module: 'COMPANY_SETTING', resource: 'BASIC' }] }
          }
        ]
      },

      // 个人中心模块
      {
        path: '/user-center-entry',
        name: 'user-center-entry',
        component: emptyRouterView,
        redirect: '/resource-center/white-paper',
        children: [
          {
            path: '/login',
            name: 'login',
            component: login,
            meta: { loginState: 0, title: '登录' }
          },
          {
            path: '/register/:step',
            name: 'register',
            component: register,
            meta: { loginState: 0, title: '注册' }
          },
          {
            path: '/find-password',
            name: 'findPassword',
            component: findPassword,
            meta: { loginState: 0, title: '找回密码' }
          },
          {
            path: '/complete-personal-info',
            name: 'personInfo',
            component: personInfo,
            meta: { loginState: 0, title: '个人信息' }
          },
          {
            path: '/purchase-application',
            name: 'purchaseApplication',
            component: purchaseApplication,
            meta: { loginState: 0, title: '申请企业版' }
          },
          {
            path: '/strategy',
            name: 'strategy',
            component: strategy,
            meta: { loginState: 0, title: '攻略详情' }
          },
          {
            path: '/purchase-success',
            name: 'successApplication',
            component: successApplication,
            meta: { loginState: 0, title: '申请企业版' }
          },

          // 个人中心页面
          {
            path: '/user-center',
            name: 'user-center',
            component: userCenterHome,
            redirect: '/served',
            children: [
              {
                path: '/served',
                name: 'served',
                component: served,
                redirect: '/served/companies',
                children: [
                  {
                    path: '/served/companies',
                    name: 'servedCompanies',
                    component: servedCompanies,
                    meta: { loginState: 1, title: '服务公司' }
                  }, {
                    path: '/served/adminComparableCompanies',
                    name: 'servedAdminComparableCompanies',
                    component: adminComparableCompanies,
                    meta: { loginState: 1, title: '管理可比公司' }
                  }
                ]
              },
              {
                path: '/follow',
                name: 'follow',
                component: follow,
                redirect: '/follow/companies',
                children: [
                  {
                    path: '/follow/companies',
                    name: 'followCompanies',
                    component: followCompanies,
                    meta: { loginState: 1, title: '关注公司' }
                  },
                  {
                    path: '/follow/compilations',
                    name: 'followRegulations',
                    component: followRegulations,
                    meta: { loginState: 1, title: '关注法规合集' }
                  },
                  {
                    path: '/follow/adminComparableCompanies',
                    name: 'followAdminComparableCompanies',
                    component: adminComparableCompanies,
                    meta: { loginState: 1, title: '管理可比公司' }
                  }
                ]
              },
              {
                path: '/collections',
                name: 'favorite',
                component: favorite,
                meta: { loginState: 1, title: '我的收藏' },
                alias: ['/favorite-rules', '/favorite-affiche', '/favorite-cases', '/favorite-IPO', '/favorite-report']
              },
              {
                path: '/collections/:id',
                name: 'favoriteDetail',
                component: favoriteDetail,
                meta: { loginState: 1, title: '收藏夹内容', redirectPathAfterSwitchProfile: '/collections' }
              },
              {
                path: '/creation',
                name: 'works',
                component: works,
                redirect: '/creation/rule-notes',
                children: [
                  {
                    path: '/creation/rule-notes',
                    name: 'worksNoteLaw',
                    component: worksNoteLaw,
                    meta: { loginState: 1, title: '法规笔记' }
                  },
                  {
                    path: '/creation/rule-notes/:id/related',
                    name: 'worksNoteList',
                    component: worksNoteList,
                    meta: { loginState: 1, title: '法规笔记' }
                  }
                ]
              },

              // 独董 - 我的购买记录
              {
                path: '/purchase-record',
                name: 'purchaseRecord',
                component: purchaseRecord,
                redirect: '/purchase-record/my-record',
                children: [
                  {
                    path: '/purchase-record/my-record',
                    name: 'myPurchaseRecord',
                    component: myPurchaseRecord,
                    meta: { loginState: 1, title: '购买记录', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_HOME' }] }
                  },
                  {
                    path: '/purchase-record/invoice-progress',
                    name: 'invoiceProgress',
                    component: invoiceProgress,
                    meta: { loginState: 1, title: '开票进度', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_HOME' }] }
                  }
                ]
              },
              // 账号管理
              {
                path: '/manage',
                name: 'manage',
                component: manage,
                redirect: '/manage/account',
                children: [
                  {
                    path: '/manage/account',
                    name: 'account',
                    component: account,
                    meta: { loginState: 1, title: '账号信息' }
                  },
                  {
                    path: '/manage/person',
                    name: 'person',
                    component: person,
                    meta: { loginState: 1, title: '个人信息' }
                  },
                  {
                    path: '/manage/switch-company',
                    name: 'changeCompany',
                    component: changeCompany,
                    meta: { loginState: 1, title: '所属企业' }
                  }
                ]
              }
            ]
          }

        ]
      },

      // 独董相关模块
      {
        path: '/independent-director-entry',
        name: 'independent-director-entry',
        component: emptyRouterView,
        redirect: '/dashboard/independent-director',
        children: [
          // 独董宣传落地页
          {
            path: '/dashboard/propaganda',
            name: 'dashboardPropaganda',
            component: dashboardPropaganda,
            meta: { loginState: 0, title: '独董助手' }
          },
          {
            path: '/dashboard/independent-director',
            name: 'independentDirector',
            component: independentDirector,
            meta: { loginState: 1, title: '独董助手', keepAlive: true, contactModules: [{ module: 'INDEPENDENT_DIRECTOR_HOME' }] }
          },
          {
            path: '/dashboard/independent-director/online-pay',
            name: 'onlinePay',
            component: onlinePay,
            meta: { loginState: 1, title: '独董助手' }
          },
          // 申请开票
          {
            path: '/invoice/apply',
            name: 'applyForInvoicing',
            component: applyForInvoicing,
            meta: { loginState: 1, title: '申请开票', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_HOME' }] }
          },
          // 独董履职清单
          {
            path: '/independent-director/duty-list',
            name: 'independentDirectorDutyList',
            component: independentDirectorDutyList,
            redirect: '/independent-director/duty-list/checklist',
            children: [
              {
                path: '/independent-director/duty-list/checklist',
                name: 'checkList',
                component: checkList,
                meta: { loginState: 1, title: '独董履职清单', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
              },
              {
                path: '/independent-director/duty-list/ongoing-matters',
                name: 'ongoingMatters',
                component: ongoingMatters,
                meta: { loginState: 1, title: '进行中的事项', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
              },
              {
                path: '/independent-director/duty-list/archived-matters',
                name: 'archivedMatters',
                component: archivedMatters,
                meta: { loginState: 1, title: '已归档事项', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
              }
            ]
          },
          {
            path: '/independent-director/duty-list/ongoing-matters/:id',
            name: 'ongoingMattersDetail',
            component: ongoingMattersDetail,
            meta: { loginState: 1, title: '独董履职事项详情', menuKey: '/independent-director/duty-list/ongoing-matters', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
          },
          {
            path: '/independent-director/duty-list/archived-matters/:id',
            name: 'archivedMattersDetail',
            component: archivedMattersDetail,
            meta: { loginState: 1, title: '独董履职事项详情', menuKey: '/independent-director/duty-list/archived-matters', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
          },
          {
            path: '/independent-director/duty-list/ongoing-matters/communication-records/:id',
            name: 'ongoingCommunicationDetail',
            component: ongoingCommunicationDetail,
            meta: { loginState: 1, title: '独董履职事项详情', menuKey: '/independent-director/duty-list/ongoing-matters', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
          },
          {
            path: '/independent-director/duty-list/archived-matters/communication-records/:id',
            name: 'archivedCommunicationDetail',
            component: archivedCommunicationDetail,
            meta: { loginState: 1, title: '独董履职事项详情', menuKey: '/independent-director/duty-list/archived-matters', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_DUTY' }] }
          },
          {
            path: '/independent-director/duty-list/guide',
            name: 'dutyListGuide',
            component: dutyListGuide,
            meta: { loginState: 1, title: '独董履职清单', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_HOME' }] }
          }]
      },
      // 独董履职管理
      {
        path: '/independent-director/performance-manage',
        name: 'independent-director-performance-manage',
        component: emptyRouterView,
        redirect: '/independent-director-performance-manage/index',
        children: [
          {
            path: '/independent-director-performance-manage/index',
            name: 'independentDirectorPerformanceManageIndex',
            component: independentDirectorPerformanceManageIndex,
            redirect: '/independent-director-plan',
            meta: { loginState: 0, title: '独董履职清单', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] },
            children: [
              {
                path: '/independent-director-plan',
                name: 'planManage',
                component: planManage,
                meta: { loginState: 1, title: '履职计划管理', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
              },
              {
                path: '/independent-director-record',
                name: 'onlinePay',
                component: performanceRecord,
                meta: { loginState: 1, title: '独董履职记录', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
              },
              {
                path: '/independent-director-report',
                name: 'reportManage',
                component: reportManage,
                meta: { loginState: 1, title: '独董报告管理', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
              },
              {
                path: '/independent-director-template',
                name: 'templateManage',
                component: templateManage,
                meta: { loginState: 1, title: '报告模版管理', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
              }
            ]
          },
          {
            path: '/independent-director-template/:id/edit',
            name: 'meetingOnlineEdit',
            component: meetingOnlineEdit,
            meta: { loginState: 1, title: '文件编辑', menuKey: '/independent-director-template', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
          },
          {
            path: '/independent-director-plan/:id/edit',
            name: 'editProject',
            component: editProject,
            meta: { loginState: 1, title: '履职计划管理', menuKey: '/independent-director-plan', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
          },
          {
            path: '/independent-director-plan/to-do-list',
            name: 'addTodoList',
            component: addTodoList,
            meta: { loginState: 1, title: '添加待办', menuKey: '/independent-director-plan', contactModules: [{ module: 'INDEPENDENT_DIRECTOR_PERFORM' }] }
          }
        ]
      },
      // 文档管理
      {
        path: '/document-management',
        name: 'documentManagement',
        component: documentManagement,
        redirect: '/document-management-center',
        children: [
          {
            path: '/document-management-center',
            name: 'documentManagementCenter',
            component: documentManagementCenter,
            meta: { loginState: 1, hideFooter: true, title: '文档管理中心', contactModules: [{ module: 'DOCUMENT_MANAGEMENT_CENTER' }] }
          }
        ]
      },
      {
        path: '/document-review/result',
        name: 'documentReviewResult',
        component: documentReviewResult,
        meta: { loginState: 1, hideFooter: true, title: '查看复核结果', contactModules: [] }
      },
      {
        path: '/document-management-center/security-introduction',
        name: 'securityIntroduction',
        component: securityIntroduction,
        meta: { loginState: 0, hideFooter: true, title: '安全体系介绍', menuKey: '/document-management-center', contactModules: [] }
      },
      // 权益变动
      {
        path: '/change-equity',
        name: 'changeEquity',
        component: emptyRouterView,
        redirect: '/change-equity/index',
        children: [
          {
            path: '/change-equity/index',
            name: 'changeEquityIndex',
            component: changeEquityIndex,
            redirect: '/shareholder-information',
            meta: { loginState: 0, title: '权益变动', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] },
            children: [
              {
                path: '/consultation-matters',
                name: 'consultationList',
                component: emptyRouterView,
                redirect: '/consultation-matter/consultation-list',
                meta: {
                  loginState: 1,
                  title: '咨询事项管理',
                  contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }]
                },
                children: [
                  {
                    path: '/consultation-matter/consultation-list',
                    name: 'consultationList',
                    component: consultationList,
                    meta: { loginState: 1, title: '咨询事项管理', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] },
                    children: []
                  }

                ]
              },
              {
                path: '/shareholder-information',
                name: 'shareholder-information',
                redirect: '/shareholder-information/list',
                component: emptyRouterView,
                children: [
                  {
                    path: '/shareholder-information/add',
                    name: 'shareholderForm',
                    component: shareholderForm,
                    meta: { loginState: 1, menuKey: '/shareholder-information/list', title: '添加股东信息', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }], hideFooter: true },
                    children: []
                  },
                  {
                    path: '/shareholder-information/edit',

                    name: 'shareholderForm',
                    component: shareholderForm,
                    meta: { loginState: 1, menuKey: '/shareholder-information/list', title: '编辑股东信息', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }], hideFooter: true },
                    children: []
                  },
                  {
                    path: '/shareholder-information/list',
                    name: 'shareholderList',
                    component: shareholderList,
                    meta: { loginState: 1, title: '股东信息管理', keepAlive: true, contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] },
                    children: []
                  },
                  {
                    path: '/company-information/edit',
                    name: 'companyInformationSet',
                    component: companyInformationSet,
                    meta: { loginState: 1, menuKey: '/shareholder-information/list', hideFooter: true, title: '设置公司信息', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] },
                    children: []
                  },
                  {
                    path: '/shareholder-information/:id/view',
                    name: 'shareholderDetail',
                    component: shareholderDetail,
                    meta: { loginState: 1, menuKey: '/shareholder-information/list', title: '查看股东信息', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }], hideFooter: true }
                  }
                ]
              },
              {
                path: '/my-approval',
                name: 'myApproval',
                component: emptyRouterView,
                redirect: '/my-approval/list',
                meta: { loginState: 1, title: '我的审批', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] },
                children: [
                  {
                    path: '/my-approval/list',
                    name: 'approvaList',

                    component: approvalList,
                    meta: { loginState: 1, title: '待我审批', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
                  }
                ]
              }
            ]
          },
          {
            path: '/shareholder-information/:id/log',
            name: 'operateLog',

            component: operateLog,
            meta: { loginState: 1, menuKey: '/shareholder-information/list', title: '查看操作日志', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }], hideFooter: true }
          },
          {
            path: '/shareholder-information/log',
            name: 'operateLogEnterPrise',

            component: operateLogEnterPrise,
            meta: { loginState: 1, menuKey: '/shareholder-information/list', title: '查看操作日志', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }], hideFooter: true }
          },
          {
            path: '/consultation-matter/add',

            name: 'createConsultationItem',
            component: createConsultationItem,
            meta: { loginState: 1, hideFooter: true, menuKey: '/consultation-matter/consultation-list', title: '新增咨询事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/consultation-matter/:id/edit',

            name: 'editConsultationItem',
            component: editConsultationItem,
            meta: { loginState: 1, hideFooter: true, menuKey: '/consultation-matter/consultation-list', title: '编辑咨询事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/consultation-matter/:id/view',

            name: 'checkConsultationItem',
            component: checkConsultationItem,
            meta: { loginState: 1, hideFooter: true, menuKey: '/consultation-matter/consultation-list', title: '查看咨询事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/consultation-matter/:id/add-matter',
            name: 'additionalPostPeriodMattersPage',
            component: subsequentEvent,
            meta: { loginState: 1, hideFooter: true, menuKey: '/consultation-matter/consultation-list', title: '追加期后事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/consultation-matter/:id/edit-matter',
            name: 'editAdditionalPostPeriodMattersPage',
            component: subsequentEvent,
            meta: { loginState: 1, hideFooter: true, menuKey: '/consultation-matter/consultation-list', title: '编辑期后事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/my-approval/:id/approval',

            name: 'approvalMatter',
            component: approvalMatter,
            meta: { loginState: 1, hideFooter: true, menuKey: '/my-approval/list', title: '审批事项', contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          },
          {
            path: '/my-approval/:id/view',
            name: 'checkApproval',
            component: checkApproval,
            meta: { loginState: 1, title: '查看审批', menuKey: '/my-approval/list', hideFooter: true, contactModules: [{ module: 'EQUITY_CHANGE_MANAGE' }] }
          }
        ]
      },
      // 其他模块
      { path: '/no-permission', name: 'indexNoPermission', component: indexNoPermission, meta: { loginState: 1, title: '无权限访问' } },
      {
        path: '/agreement',
        name: 'agreement',
        component: agreement,
        meta: { loginState: 0, title: '信公小安用户协议' }
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: privacy,
        meta: { loginState: 0, title: '信公小安隐私条款' }
      },
      {
        path: '/redirect',
        name: 'redirect',
        component: redirect,
        meta: { loginState: 0, title: '跳转中' }
      },
      {
        path: '/wx-transfer',
        name: 'wxTransfer',
        component: wxTransfer,
        meta: { loginState: 1, title: '信公小安' }
      },
      {
        path: '/file-preview/:id',
        name: 'previewFile',
        component: previewFile,
        meta: { loginState: 0, title: '文件预览', publicPage: true, tabShow: 0 }
      },
      {
        path: '/file-edit/:id',
        name: 'editFile',
        component: editFile,
        meta: { loginState: 0, title: '文件编辑', publicPage: true, tabShow: 0 }
      },

      {
        path: '/dashboard/regulator',
        name: 'regulator',
        component: regulator,
        meta: { loginState: 1, title: '信公小安 - 企业概览', contactModules: [{ module: 'INFAITH_HOME' }] }
      },
      // 系统维护的时候 自动弹出的页面
      {
        path: '/repair',
        name: 'repair',
        component: repair,
        meta: { loginState: 0, title: '系统维护中' }
      },
      { path: '/online-service', name: 'onlineServiceIndex', component: onlineServiceIndex, meta: { loginState: 0, hideAside: true, title: '在线咨询' } },
      {
        path: '/network-error',
        name: 'network-error',
        meta: { loginState: 0, title: '信公小安' },
        component: networkError
      },
      {
        path: '/404',
        name: 'error-404',
        meta: { loginState: 0, title: '页面不存在' },
        component: error404
      },
      {
        path: '/*',
        name: 'error-url',
        redirect: '/404'
      }
    ]
  }
]
export default URL
