import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取用户信息
  online_test_user(params) {
    return Axios({ method: 'GET', url: api.onlineTest.online_test_user, params: params })
  },
  // 获取协会列表
  get_assciation_info() {
    return Axios({ method: 'GET', url: api.onlineTest.get_assciation_info })
  },
  // 提交确认信息
  update_user_info(params) {
    return Axios({ method: 'PUT', url: api.onlineTest.update_user_info, data: params })
  },
  // 获取问卷配置信息
  get_test_property_info_by_test_name(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_test_property_info_by_test_name, [['testName', testName]]), originUrl: api.onlineTest.get_test_property_info_by_test_name })
  },
  // 开始答题，用于后端开始答题计时
  start_exercise(testName) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.onlineTest.start_exercise, [['testName', testName]]), originUrl: api.onlineTest.start_exercise })
  },
  // 获取问卷答题情况 不包括问题和选项
  get_user_exercise_status(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_user_exercise_status, [['testName', testName]]), originUrl: api.onlineTest.get_user_exercise_status })
  },
  // 获取问卷答题情况 包括问题和选项
  get_user_exercise_info(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_user_exercise_info, [['testName', testName]]), originUrl: api.onlineTest.get_user_exercise_info })
  },
  // 获取答题总览
  get_execrise_result_info(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_execrise_result_info, [['testName', testName]]), originUrl: api.onlineTest.get_execrise_result_info })
  },
  // 回答一道问题
  record_user_exercise_info(params, paperId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.onlineTest.record_user_exercise_info, [['paperId', paperId]]), data: params, originUrl: api.onlineTest.record_user_exercise_info })
  },
  // 获取相关课程
  get_related_courses(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_related_courses, [['testName', testName]]), originUrl: api.onlineTest.get_related_courses })
  },
  // 获取所有答题结果 包含正确答案
  get_answer_detail(testName) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.onlineTest.get_answer_detail, [['testName', testName]]), originUrl: api.onlineTest.get_answer_detail })
  },
  // 获取用户可使用的题库
  get_available_question_bank() {
    return Axios({ method: 'GET', url: api.onlineTest.get_available_question_bank })
  },
  // 获取在线测试题库
  get_question_bank() {
    return Axios({ method: 'GET', url: api.onlineTest.get_question_bank })
  }
}
