/*
 * @Description: 在线支付相关接口
 * @Author: huamin.zhou
 */

import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 查询所有商品
  payment_commodities() {
    return Axios({ method: 'GET', url: api.payment.payment_commodities })
  },
  // 轮训订单状态
  order_status(orderNo) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.payment.order_status, [['orderNo', orderNo]]), params: {}, originUrl: api.payment.order_status })
  },
  // 创建订单
  create_order(params) {
    return Axios({ method: 'POST', url: api.payment.create_order, params: params })
  },
  // 查询订单状态
  payment_order_status(params, orderNo) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.payment.payment_order_status, [['orderNo', orderNo]]), params: params, originUrl: api.payment.payment_order_status })
  },
  // 开发票
  apply_invoice(params, orderNo) {
    return Axios({ method: 'POST', url: api.payment.apply_invoice, params: params })
  }
}
