
const yuangong = [
  {
    subIssueTitle: '员工结构',
    subIssueTooltipContent: `对应GRI标准: 401-1<br>
      对应MSCI ESG指标: 劳工管理<br>
      对应港交所ESG指引: B1.1, B1.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括员工按性别、雇佣类别（如全职或兼职）、年龄组别及地区划分的雇佣结构；按性别、年龄组别及地区划分的员工流失率（员工流失比率反映在报告期內自愿离职或因解雇、退休或身故而与发行人解除雇佣关系的员工人数）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '年初员工总数', key: 'EMPLOYEE_1', unit: '人', yearDataList: ['', '', ''] },
          { title: '年初女性员工总数', key: 'EMPLOYEE_2', unit: '人', yearDataList: ['', '', ''] },
          { title: '年初男性员工总数', key: 'EMPLOYEE_3', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末员工总数', key: 'EMPLOYEE_4', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末女性员工总数', key: 'EMPLOYEE_5', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末男性员工总数', key: 'EMPLOYEE_6', unit: '人', yearDataList: ['', '', ''] },
          { title: '新进员工数量', key: 'EMPLOYEE_7', unit: '人', yearDataList: ['', '', ''] },
          { title: '新进女性员工数量', key: 'EMPLOYEE_8', unit: '人', yearDataList: ['', '', ''] },
          { title: '新进男性员工数量', key: 'EMPLOYEE_9', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末全职员工数量', key: 'EMPLOYEE_10', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末兼职员工数量', key: 'EMPLOYEE_11', unit: '人', yearDataList: ['', '', ''] },
          { title: '自愿离职或因解雇、退休或身故而与发行人解除雇佣关系的员工人数', key: 'EMPLOYEE_12', unit: '人', yearDataList: ['', '', ''] },
          { title: '自愿离职或因解雇、退休或身故而与发行人解除雇佣关系的女性员工人数', key: 'EMPLOYEE_13', unit: '人', yearDataList: ['', '', ''] },
          { title: '自愿离职或因解雇、退休或身故而与发行人解除雇佣关系的男性员工人数', key: 'EMPLOYEE_14', unit: '人', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '权益保障',
    subIssueTooltipContent: `对应GRI标准: 405-2, 406-1, 408-1, 409-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B1, B4`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括人权政策、招聘政策、工作时数、平等机会、招聘多元化、反歧视等政策、遵守哪些相关法规（列出名称）；有关防止童工或强制劳动的政策、在发生使用童工或强制劳动时的处理措施。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '年末工会员工数量', key: 'EMPLOYEE_15', unit: '人', yearDataList: ['', '', ''] },
          { title: '男性员工薪酬总数', key: 'EMPLOYEE_16', unit: '元', yearDataList: ['', '', ''] },
          { title: '女性员工薪酬总数', key: 'EMPLOYEE_17', unit: '元', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '福利政策',
    subIssueTooltipContent: `对应GRI标准: 401-2, 401-3<br>
      对应MSCI ESG指标: 劳工管理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括员工福利政策与福利体系，福利至少包括：人寿保险、卫生保健、伤残保险、育儿假、退休金等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '集体协议覆盖总人数', key: 'EMPLOYEE_18', unit: '人', yearDataList: ['', '', ''] },
          { title: '育儿假结束后实际返岗的员工总数', key: 'EMPLOYEE_19', unit: '人', yearDataList: ['', '', ''] },
          { title: '年末育儿假结束后应返岗的员工总数', key: 'EMPLOYEE_20', unit: '人', yearDataList: ['', '', ''] },
          { title: '员工社保覆盖人数', key: 'EMPLOYEE_21', unit: '人', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '员工薪酬',
    subIssueTooltipContent: `对应GRI标准: 102-35<br>
      对应MSCI ESG指标: 公司治理, 劳工管理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括高管薪酬政策与结构、普通员工的薪酬政策、决定薪酬的过程（是否聘任第三方顾问）、薪酬结构、激励措施、高管薪酬与ESG议题挂钩情况。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '支付员工薪酬', key: 'EMPLOYEE_22', unit: '元', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '员工参与与沟通',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 劳工管理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括基层员工参与企业管理的政策与渠道、员工敬业度调查、员工满意度调查、公司管理层与基层员工沟通的渠道建设情况。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '员工满意度', key: 'EMPLOYEE_23', unit: '%', yearDataList: ['', '', ''] },
          { title: '员工敬业度', key: 'EMPLOYEE_24', unit: '%', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '工会建设',
    subIssueTooltipContent: `对应GRI标准: 407-1<br>
      对应MSCI ESG指标: 劳工管理<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括工会组织建设情况、开展活动。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '员工培训',
    subIssueTooltipContent: `对应GRI标准: 404-1, 404-2<br>
      对应MSCI ESG指标: 人力资本发展<br>
      对应港交所ESG指引: B3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括有关提升员工工作能力、知识技能的政策、员工技能提升方案、培训体系、培训活动等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '培训时长', key: 'EMPLOYEE_25', unit: '小时', yearDataList: ['', '', ''] },
          { title: '培训活动数量', key: 'EMPLOYEE_26', unit: '场', yearDataList: ['', '', ''] },
          { title: '参与培训的员工总数', key: 'EMPLOYEE_27', unit: '人', yearDataList: ['', '', ''] },
          { title: '培训支出', key: 'EMPLOYEE_28', unit: '元', yearDataList: ['', '', ''] },
          { title: '参与培训的男性员工人数', key: 'EMPLOYEE_29', unit: '人', yearDataList: ['', '', ''] },
          { title: '参与培训的女性员工人数', key: 'EMPLOYEE_30', unit: '人', yearDataList: ['', '', ''] },
          { title: '参与培训的高管人数', key: 'EMPLOYEE_31', unit: '人', yearDataList: ['', '', ''] },
          { title: '参与培训的中层管理者人数', key: 'EMPLOYEE_32', unit: '人', yearDataList: ['', '', ''] },
          { title: '参与培训的基层员工人数', key: 'EMPLOYEE_33', unit: '人', yearDataList: ['', '', ''] },
          { title: '女性员工平均培训时长', key: 'EMPLOYEE_34', unit: '小时', yearDataList: ['', '', ''] },
          { title: '男性员工平均培训时长', key: 'EMPLOYEE_35', unit: '小时', yearDataList: ['', '', ''] },
          { title: '高管平均培训时长', key: 'EMPLOYEE_36', unit: '小时', yearDataList: ['', '', ''] },
          { title: '中层管理层平均培训时长', key: 'EMPLOYEE_37', unit: '小时', yearDataList: ['', '', ''] },
          { title: '基层员工平均培训时长', key: 'EMPLOYEE_38', unit: '小时', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '员工发展',
    subIssueTooltipContent: `对应GRI标准: 404-3<br>
      对应MSCI ESG指标: 人力资本发展<br>
      对应港交所ESG指引: B3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括员工晋升与发展通道、晋升与发展政策、员工领导力培养、职业发展考评政策与开展情况。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '工作与生活平衡',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 人力资本发展<br>
      对应港交所ESG指引: B3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括员工举办的文体娱乐活动开展情况、公司保障员工工作与生活平衡的其他措施。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default yuangong
