import Axios from '@/service/http'
import api from '@/service/api'

export default {
  // 获取访问用户详情信息
  online_service_customer(params) {
    return Axios({ method: 'GET', url: api.onlineService.online_service_customer, params: params })
  },
  // 更新用户在七鱼的标签信息
  online_service_tag(params) {
    return Axios({ method: 'POST', url: api.onlineService.online_service_tag, params: params })
  },
  // 保存用户路径
  access_detail(params) {
    return Axios({ method: 'POST', url: api.onlineService.access_detail, data: params })
  }
}
