<!--
 * @Description: 人员选择组件
-->
<template>
  <div
    class="vxa-staffSelectInput"
    :class="{ 'vxa-staffSelectInput_error': isRequiredError }"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.97)"
  >
    <vxa-staff-input
      :size="size"
      v-model="valueList"
      :disabled="disabled"
      :dataInited="dataInited"
      :multiple="multiple"
      :btnText="btnText"
      :extraInfo="extraInfo"
      :showCertStatus="showCertStatus"
      :fixedUserList="fixedUserList"
      @selectClick="selectClick"
      @delete="deleteClick"
    />
    <vxa-staff-select
      :title="title"
      :showDialog.sync="showDialog"
      :multiple="multiple"
      :columns="columns"
      :requestUserList="requestUserList"
      :selectedPersonList="valueList"
      :allUserList="allUserList"
      :isShowAllUserName="isShowAllUserName"
      :showCertStatus="showCertStatus"
      :fixedUserList="fixedUserList"
      :userRoles="userRoles"
      :showMeetingContact="showMeetingContact"
      :duplicationCheck="duplicationCheck"
      :needAppendToBody="needAppendToBody"
      :checkRowDisabled="checkRowDisabled"
      :bottomTip="bottomTip"
      @saveSelect="saveSelect"
      @userRequested="userRequested"
    />
    <span class="error-text" v-show="isRequiredError">请选择必填项</span>
  </div>
</template>

<script>
import VxaStaffInput from './input'
import VxaStaffSelect from './dialog'

export default {
  name: 'VxaStaffSelectInput',
  components: {
    'vxa-staff-input': VxaStaffInput,
    'vxa-staff-select': VxaStaffSelect
  },
  props: {
    // 例: [{ userId: 'xxxxxxxxxxxxxxxx' }] userId必传
    value: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // multiple为true时，设置选择按钮文案
    btnText: {
      type: String,
      default: '选择'
    },
    title: {
      type: String,
      require: false,
      default: ''
    },
    // requestUserList为false时，使用传入的allUserList作为用户列表
    allUserList: {
      type: Array,
      default: () => []
    },
    // 是否需要通过请求获取用户列表，可通过外部传入固定列表
    requestUserList: {
      type: Boolean,
      default: true
    },
    // 配置dialog内table的列 例：[{ key: 'mobile', name: '手机号' }]
    columns: {
      type: Array,
      default: () => []
    },
    // 需要展示出来的额外信息，该信息同时会被视为必要信息，若不存在会有标红提示  例：{ key: 'mobile', name: '手机号' }
    extraInfo: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      require: false,
      default: '拼命加载中'
    },
    // 是否需要在table下方展示已选用户姓名
    isShowAllUserName: {
      type: Boolean,
      default: false
    },
    // 显示用户认证状态
    showCertStatus: {
      type: Boolean,
      default: false
    },
    // 固定添加人员 人员列表，且不可删除
    // [id1,id2]
    fixedUserList: {
      type: Array,
      default: () => {
        return []
      }
    },
    userRoles: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否展示三会联系人
    showMeetingContact: {
      type: Boolean,
      default: false
    },
    // 重名校验
    duplicationCheck: {
      type: Boolean,
      default: false
    },
    needAppendToBody: {
      type: Boolean,
      default: false
    },
    checkRowDisabled: [Function],
    bottomTip: [String]
  },
  data() {
    return {
      showDialog: false,
      isRequiredError: false,
      dataInited: false,
      userListInited: false
    }
  },
  computed: {
    valueList: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.fixedUserList?.length) {
          // 添加固定不可删除人员之后，不再继续运行下去。保证init方法只运行一边
          const addFixedUserList = []
          this.fixedUserList.forEach(user => {
            if (!this.valueList.some(item => item && item.userId == user)) {
              addFixedUserList.push({
                userId: user
              })
            }
          })
          if (addFixedUserList.length) {
            this.valueList = addFixedUserList.concat(this.valueList)
            return
          }
        }
        // 数据初始化，不支持外部多次更改value值
        if (val.length > 0 && !this.userListInited) {
          this.init()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async init() {
      let userList = []
      const originalValueList = this.valueList.filter(user => !!user) // 清除初始化user数组中的undefined
      const userInfo = this.$storage.getUserInfo()
      const params = {
        userIds: originalValueList.map(item => item.userId),
        showCertStatus: this.showCertStatus ? '1' : null
      }
      try {
        const res = await this.$axios.manage.search_org_users(userInfo.companyId, params)
        if (this.showMeetingContact) {
          const meetingRes = await this.$axios.meetingmanagement.meeting_contact_user(userInfo.companyId)
          userList = (res.data.returnObject.list || []).concat(meetingRes.data.returnObject || [])
        } else {
          userList = res.data.returnObject.list || []
        }
        // 防止value双向绑定导致重复触发，value的watch，重复调用init方法
        this.userListInited = true
      } catch (error) {
        console.log(error)
      }

      // 通过传入数据的userId匹配服务端返回的用户列表，补全传入用户列表的用户信息，再过滤掉无法匹配到userId的数据项
      this.valueList = originalValueList
        .map(user => {
          return userList.find(item => item.userId === user.userId)
        })
        .filter(user => !!user)
      this.dataInited = true

      this.$nextTick(() => {
        this.checkValueList()
      })
      setTimeout(() => {
        this.$emit('onDataInited')
      })
    },
    selectClick() {
      this.showDialog = true
    },
    /**
     * @description 判断两个数组是否有差异
     * @param {Array} newArr 可能修改过的数组
     * @param {Array} sourceArr 原数组
     * @param {String} key 对比的键,默认为userId
     */
    isEqualArr(newArr, sourceArr, key = 'userId') {
      let isEqual = false
      if (newArr.length !== sourceArr.length) {
        isEqual = true
      } else {
        for (let i = 0; i < sourceArr.length; i++) {
          const mainKey = sourceArr[i][key]
          isEqual = newArr.findIndex(v => v[key] === mainKey) === -1
          if (isEqual) {
            break
          }
        }
      }
      return isEqual
    },
    saveSelect(selectedList) {
      this.valueList = selectedList
      if (this.isEqualArr(selectedList, this.valueList)) {
        // 表示数组发生了变化
        this.$emit('change')
      }
      this.$emit('saveSelect')

      this.$nextTick(() => {
        this.validate()
        this.checkValueList()
      })
    },
    deleteClick() {
      this.validate()
      this.$emit('deleteSelect')
    },
    // 处理valueList数据，若存在所需额外信息，则标红
    checkValueList() {
      this.valueList = this.valueList.map(user => {
        if (this.extraInfo && this.extraInfo.key) {
          if (!user[this.extraInfo.key]) {
            user.invalid = true
          }
        }
        return user
      })
    },
    // 校验是否通过额外必要信息校验
    checkInvalid() {
      if (!this.extraInfo || !this.extraInfo.key) {
        return true
      }
      return !this.valueList.some(user => !user[this.extraInfo.key])
    },
    validate() {
      if (!this.required) {
        return true
      }
      this.isRequiredError = !this.valueList || this.valueList.length <= 0
      return this.valueList && this.valueList.length > 0
    },
    userRequested() {
      this.userListInited = true
      this.dataInited = true
    }
  }
}
</script>

<style lang="scss" scoped>
.vxa-staffSelectInput {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 32px;
  font-size: 0;
  vertical-align: middle;

  &_error /deep/.vxa-staffSelectInput-input .vxa-staffSelectInput-innerInput {
    border-color: #fb4319;
  }
  .error-text {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    line-height: 22px;
    color: #fb4319;
    white-space: nowrap;
  }
  /deep/ .el-loading-mask {
    .el-loading-spinner {
      margin-top: -20px;
      font-size: 14px;
      .el-loading-text {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
</style>
