import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 作者自动提示
  research_report_author_auto_complete(params) {
    return Axios({ method: 'GET', url: api.researchReport.research_report_author_auto_complete, params: params })
  },
  reportDetail(params) {
    return Axios({ method: 'GET', url: api.researchReport.reportDetail, params: params })
  },
  // 获取行业报告相关内容
  releaseAffiche_I(params) {
    return Axios({ method: 'GET', url: api.researchReport.releaseAffiche_I, params: params })
  },
  // 获取综合报告相关内容
  releaseAffiche_T(params) {
    return Axios({ method: 'GET', url: api.researchReport.releaseAffiche_T, params: params })
  },
  // 获取公司报相关内容
  releaseAffiche_C(params) {
    return Axios({ method: 'GET', url: api.researchReport.releaseAffiche_C, params: params })
  },
  // 获取下拉选项值
  get_research_report_filter_conditions() {
    return Axios({ method: 'GET', url: api.researchReport.get_research_report_filter_conditions })
  },
  // 研报检索
  research_reports_search(params) {
    return Axios({ method: 'POST', url: api.researchReport.research_reports_search, data: params })
  },
  // 研报检索
  research_reports_search_v3(params) {
    return Axios({ method: 'POST', url: api.researchReport.research_reports_search_v3, data: params })
  },
  get_report_detail(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.researchReport.get_report_detail, [['reportId', reportId]]), originUrl: api.researchReport.get_report_detail })
  },
  // 获取相关研报
  get_related_research_reports(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.researchReport.get_related_research_reports, [['reportId', reportId]]), originUrl: api.researchReport.get_related_research_reports })
  }
}
