/*
 * @Description: 智能报告接口定义
 * @Author: huamin.zhou
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 获取行业信息
  get_company_info(params) {
    return Axios({ method: 'GET', url: api.smart_report.get_company_info, params })
  },
  delete_supplement_sentiments(params) {
    return Axios({ method: 'DELETE', url: api.smart_report.delete_supplement_sentiments, params })
  },
  update_supplement_sentiments(data) {
    return Axios({ method: 'PUT', url: api.smart_report.update_supplement_sentiments, data })
  },
  get_supplement_sentiments(params) {
    return Axios({ method: 'GET', url: api.smart_report.get_supplement_sentiments, params })
  },
  supplement_sentiment(data) {
    return Axios({ method: 'POST', url: api.smart_report.supplement_sentiment, data })
  },
  /**
   * @param organizationId {string}
   */
  initialization_required_reportId(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.initialization_required_reportId, [['organizationId', organizationId]]), originUrl: api.smart_report.initialization_required_reportId })
  },
  delete_sentiment_report(reportId, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.smart_report.sentiment_report, [['reportId', reportId]]), originUrl: api.smart_report.sentiment_report, params })
  },
  query_sentiment_report(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.sentiment_report, [['reportId', reportId]]), originUrl: api.smart_report.sentiment_report })
  },
  // 复制并创建
  copy_sentiment_report(reportId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.sentiment_report, [['reportId', reportId]]), params: params, originUrl: api.smart_report.sentiment_report })
  },
  save_sentiment_report(data, reportId, params) {
    return Axios({ method: 'PUT', url: api.smart_report.save_sentiment_report, data: data, params: params, originUrl: api.smart_report.sentiment_report })
  },
  new_report(organizationId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.smart_report.smart_reports, [['organizationId', organizationId]]), params: params, originUrl: api.smart_report.smart_reports })
  },
  // 查询智能报告列表
  get_smart_report_list(organizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.smart_reports, [['organizationId', organizationId]]), params: params, originUrl: api.smart_report.smart_reports })
  },
  // 获取下载智能报告的taskId
  get_smart_report_task_id(companyId, reportId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.get_smart_report_task_id, [['companyId', companyId], ['reportId', reportId]]), params: params, originUrl: api.smart_report.get_smart_report_task_id })
  },
  // 获取可视化报告-财务分析模块筛选项
  financial_indicators() {
    return Axios({ method: 'GET', url: api.smart_report.financial_indicators })
  },
  get_shareholder_number(params) {
    return Axios({ method: 'POST', url: api.smart_report.get_shareholder_number, data: params })
  },
  get_top_ten_shareholder(params) {
    return Axios({ method: 'POST', url: api.smart_report.get_top_ten_shareholder, data: params })
  },
  corporate_governance(params) {
    return Axios({ method: 'POST', url: api.smart_report.corporate_governance, data: params })
  },
  main_business(companyId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.main_business, [['companyId', companyId]]), params, originUrl: api.smart_report.main_business })
  },
  financial_indicators_data(params) {
    return Axios({ method: 'POST', url: api.smart_report.financial_indicators_data, data: params })
  },
  market_performance_message(params) {
    return Axios({ method: 'POST', url: api.smart_report.market_performance_message, data: params })
  },
  disclosure_date(companyId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.disclosure_date, [['companyId', companyId]]), params, originUrl: api.smart_report.disclosure_date })
  },
  reporting_period(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.reporting_period, [['companyCode', companyCode]]), originUrl: api.smart_report.reporting_period })
  },
  // 查询该组织下的董办总结报告
  search_director_office_summary_report(organizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.director_office_summary_report, [['organizationId', organizationId]]), params, originUrl: api.smart_report.director_office_summary_report })
  },
  create_director_office_summary_report(organizationId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.smart_report.director_office_summary_report, [['organizationId', organizationId]]), originUrl: api.smart_report.director_office_summary_report })
  },
  // 查询董办总结报告配置信息
  query_director_office_summary_reports_by_reportId(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.operate_director_office_summary_reports, [['reportId', reportId]]), originUrl: api.smart_report.operate_director_office_summary_reports })
  },
  // 删除董办总结报告
  delete_director_office_summary_reports(reportId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.smart_report.operate_director_office_summary_reports, [['reportId', reportId]]), originUrl: api.smart_report.operate_director_office_summary_reports })
  },
  // 复制并创建董办总结报告
  copy_and_create_director_office_summary_reports(reportId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.operate_director_office_summary_reports, [['reportId', reportId]]), params, originUrl: api.smart_report.operate_director_office_summary_reports })
  },
  // 保存董办总结报告
  save_director_office_summary_reports(data, reportId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.smart_report.operate_director_office_summary_reports, [['reportId', reportId]]), data: data, params: params, originUrl: api.smart_report.operate_director_office_summary_reports })
  },
  // 获取下载董办总结报告的taskId
  get_director_office_summary_reports_taskId(companyId, reportId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.smart_report.get_director_office_summary_reports_taskId, [['companyId', companyId], ['reportId', reportId]]), params: params, originUrl: api.smart_report.get_director_office_summary_reports_taskId })
  },
  // 获取报告预设值
  get_director_office_summary_reports_presets(params) {
    return Axios({ method: 'GET', url: api.smart_report.get_director_office_summary_reports_presets, params })
  },
  // 获取报告周期
  get_director_office_summary_reports_periods(params) {
    return Axios({ method: 'GET', url: api.smart_report.get_director_office_summary_reports_periods, params })
  },
  // 查看日常报告
  query_daily_report(reportId) {
    return Axios({ method: 'GET', url: api.smart_report.daily_samrt_report, params: { reportId: reportId } })
  },
  // 创建日常报告
  create_daily_report(params) {
    return Axios({ method: 'POST', url: api.smart_report.daily_samrt_report, params: params })
  },
  // 保存日常报告
  save_daily_report(data, id, params) {
    return Axios({ method: 'PUT', url: api.smart_report.daily_samrt_report, params: params, data: data })
  },
  // 复制并创建日常报告
  copy_and_create_daily_report(params) {
    return Axios({ method: 'GET', url: api.smart_report.daily_samrt_report, params: params })
  },
  // 删除日常报告
  delete_daily_report(params) {
    return Axios({ method: 'DELETE', url: api.smart_report.daily_samrt_report, params: params })
  },
  // 创建可视化报告
  create_visual_report(params) {
    return Axios({ method: 'POST', url: api.smart_report.visual_report, params: params })
  },
  // 查看可视化报告
  query_visual_report(reportId) {
    return Axios({ method: 'GET', url: api.smart_report.visual_report, params: { reportId: reportId } })
  },
  // 保存可视化报告
  save_visual_report(data, id, params) {
    return Axios({ method: 'PUT', url: api.smart_report.visual_report, params: params, data: data })
  },
  // 复制并创建可视化报告
  copy_and_create_visual_report(params) {
    return Axios({ method: 'GET', url: api.smart_report.visual_report, params: params })
  },
  // 删除可视化报告
  delete_visual_report(params) {
    return Axios({ method: 'DELETE', url: api.smart_report.visual_report, params })
  }

}
