import Api from '@/service/api'

export default {
  gxfinfo: `${Api.aliyunDomain}xiaoan/web_static/message/msg_gxf_info.png`,
  messageadmin: `${Api.aliyunDomain}xiaoan/web_static/message/msg_admin.png`,
  messageinvestor: `${Api.aliyunDomain}xiaoan/web_static/message/msg_investor.jpg`,
  messageremind: `${Api.aliyunDomain}xiaoan/web_static/message/msg_remind.png`,
  sxy: `${Api.aliyunDomain}xiaoan/web_static/ilearn/msg-ilearn.jpg`,
  esg: `${Api.aliyunDomain}xiaoan/web_static/message/esg.jpg`,
  case: `${Api.aliyunDomain}xiaoan/web_static/message/msg_case.png`,
  signature: `${Api.aliyunDomain}xiaoan/web_static/message/msg_signature.png`,
  monitor: `${Api.aliyunDomain}xiaoan/web_static/message/msg_monitor.png`,
  backlog: `${Api.aliyunDomain}xiaoan/web_static/message/msg_backlog.png`,
  equityChangeMsg: `${Api.aliyunDomain}xiaoan/web_static/message/equityChangeMsg.png`
}
