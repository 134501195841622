import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 增加信批事项
  add_info_closure_item_main(data) {
    return Axios({ method: 'POST', url: api.disclosure.add_info_closure_item_main, data })
  },
  // 删除信批事项
  delete_closure_item_main(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.disclosure.operate_disclosure_list_items, [['itemId', itemId]]), originUrl: api.disclosure.operate_disclosure_list_items })
  },
  // 查询系统事项模板
  get_info_disclosure_event_by_company_id(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.get_info_disclosure_event_by_company_id, [['companyId', companyId]]), originUrl: api.disclosure.get_info_disclosure_event_by_company_id })
  },
  // 获取信批事项
  get_info_disclosure_item_main(data = {}) {
    return Axios({ method: 'POST', url: api.disclosure.get_info_disclosure_item_main, data })
  },
  // 查询自定义事项模板
  get_custom_template_by_user_id(params) {
    return Axios({ method: 'GET', url: api.disclosure.get_custom_template_by_user_id, params: params })
  },
  // 查询系统默认模板要点详情
  get_info_by_item_id(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.operate_disclosure_list_items, [['itemId', itemId]]), originUrl: api.disclosure.operate_disclosure_list_items })
  },
  // 归档
  archived_item_main(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.disclosure.archived_item_main, [['itemId', itemId]]), originUrl: api.disclosure.archived_item_main })
  },
  // 获取企业版用户信息
  get_enterprise_user_list(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.get_enterprise_user_list, [['companyId', companyId]]), originUrl: api.disclosure.get_enterprise_user_list })
  },
  // 修改信批事项
  update_info_disclosure_item_main(params, itemId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.disclosure.operate_disclosure_list_items, [['itemId', itemId]]), data: params, originUrl: api.disclosure.operate_disclosure_list_items })
  },
  generate_annual_financial_report(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.generate_annual_financial_report, [['companyCode', companyCode]]), params: params, originUrl: api.disclosure.generate_annual_financial_report })
  },
  delete_doc_record(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.disclosure.delete_doc_record, [['id', id]]), originUrl: api.disclosure.delete_doc_record })
  },
  get_doc_record_list(params) {
    return Axios({ method: 'GET', url: api.disclosure.get_doc_record_list, params: params })
  },
  // 检查财务分析报告是否被变更
  check_update(companyCode, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.check_update, [['companyCode', companyCode]]), params: params, originUrl: api.disclosure.check_update })
  },
  get_export_report_time_list(params) {
    return Axios({ method: 'GET', url: api.disclosure.get_export_report_time_list, params: params })
  },
  create_custom_template(data) {
    return Axios({ method: 'POST', url: api.disclosure.operate_custom_template, data })
  },
  get_custom_template(params) {
    return Axios({ method: 'GET', url: api.disclosure.operate_custom_template, params })
  },
  delete_custom_template(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.disclosure.operate_custom_template_by_id, [['itemId', itemId]]), originUrl: api.disclosure.operate_custom_template_by_id })
  },
  edit_custom_template(itemId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.disclosure.operate_custom_template_by_id, [['itemId', itemId]]), data, originUrl: api.disclosure.operate_custom_template_by_id })
  },
  download_template_file(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.download_template_file, [['itemId', itemId]]), params, originUrl: api.disclosure.download_template_file })
  },
  validte_disclosure_template_name(params) {
    return Axios({ method: 'GET', url: api.disclosure.validte_disclosure_template_name, params })
  },
  validte_disclosure_issue_name(params) {
    return Axios({ method: 'GET', url: api.disclosure.validte_disclosure_issue_name, params })
  },
  get_issue_detail(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.get_issue_detail, [['itemId', itemId]]), params, originUrl: api.disclosure.get_issue_detail })
  },
  check_can_archive_issue(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.check_can_archive_issue, [['itemId', itemId]]), params, originUrl: api.disclosure.check_can_archive_issue })
  },
  // 信披清单导出事项
  export_disclosure_item_events(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.disclosure.export_disclosure_item_events, [['itemId', itemId]]), originUrl: api.disclosure.export_disclosure_item_events })
  }

}
