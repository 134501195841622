import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取违规案例详情信息
  get_violation_case_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.cases.get_violation_case_detail, [['id', id]]), originUrl: api.cases.get_violation_case_detail })
  },
  // 获取违规案例筛选条件
  get_violation_case_filter_conditions(params) {
    return Axios({ method: 'GET', url: api.cases.get_violation_case_filter_conditions, params: params })
  },
  // 违规案例查询
  violation_case_search(params) {
    return Axios({ method: 'POST', url: api.cases.violation_case_search, data: params })
  },
  // 违规案例查询（新版-带模糊搜索）
  search_violation_case_list(params) {
    return Axios({ method: 'POST', url: api.cases.search_violation_case_list, data: params })
  },
  // 违规案例标题自动补全
  neeq_companies_auto_complete(params) {
    return Axios({ method: 'GET', url: api.cases.neeq_companies_auto_complete, params: params })
  },
  // 查询公司code所属板块
  get_market_by_company_code(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.cases.get_market_by_company_code, [['companyCode', companyCode]]), originUrl: api.cases.get_market_by_company_code })
  },
  batch_download_violation_case(params) {
    return Axios({ method: 'POST', url: api.cases.batch_download_violation_case, data: params })
  },
  // 获取下载html文本内容的下载文件地址
  get_download_html_url_by_id(id) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.cases.get_download_html_url_by_id, [['id', id]]), originUrl: api.cases.get_download_html_url_by_id })
  }
}
