/*
 * @Description:兼容历史Url至外部链接的代码
 * @Author: lei.wu@infaith.com.cn
 */
const redirectUrl = [
  // 三会
  {
    path: '/meeting/guide',
    redirectUrl: 'https://help.in-hope.cn/ac97/9ac7'
  },
  // 股行分
  {
    path: '/stockholders/operation-video',
    redirectUrl: 'https://help.in-hope.cn/ac97/bafa'
  }
]

export default redirectUrl
