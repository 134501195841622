import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 投资者关系
  // 获取e互动搜索的筛选条件
  interact_conditions(params) {
    return Axios({ method: 'GET', url: api.investor.interact_conditions, params: params })
  },
  // 搜索(因参数companyCode可能的情况不止一种，故不在此处进行统一替换，只在具体调用处进行有选择性的替换)
  investor_search(params) {
    return Axios({ method: 'POST', url: api.investor.investor_search, data: params })
  },
  interact_user_questions(params) {
    return Axios({ method: 'POST', url: api.investor.interact_user_questions, data: params })
  },
  // 根据公司代码获取跳转链接
  get_href_by_company_code(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_href_by_company_code, [['companyCode', companyCode]]), params: {}, originUrl: api.investor.get_href_by_company_code })
  },
  get_relation_question(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_relation_question, [['id', id]]), params: params, originUrl: api.investor.get_relation_question })
  },
  // 问答详情
  get_question_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_question_detail, [['id', id]]), originUrl: api.investor.get_question_detail })
  },
  // 问答详情
  get_invoster_question_company(params) {
    return Axios({ method: 'GET', url: api.investor.get_invoster_question_company, params: params })
  },
  // 获取市场平均数据
  get_market_average_summary(params) {
    return Axios({ method: 'GET', url: api.investor.get_market_average_summary, params: params })
  },
  // 获取市场平均数据
  get_max_question_count_user(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_max_question_count_user, [['companyCode', companyCode]]), params: params, originUrl: api.investor.get_max_question_count_user })
  },
  // 公司与行业对比数据接口
  get_company_market_compare_data(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_company_market_compare_data, [['companyCode', companyCode]]), params: params, originUrl: api.investor.get_company_market_compare_data })
  },
  // 获取公司市场排名
  get_company_index_from_market(params) {
    return Axios({ method: 'GET', url: api.investor.get_company_index_from_market, params: params })
  },
  // 获取同行业问答情况及公司排名
  get_industry_index_data(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_industry_index_data, [['companyCode', companyCode]]), params: params, originUrl: api.investor.get_industry_index_data })
  },
  // 获取公司问答情况总览
  get_company_summary_by_enum(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.investor.get_company_summary_by_enum, [['companyCode', companyCode]]), params: params, originUrl: api.investor.get_company_summary_by_enum })
  },
  // 获取市场问答情况总览
  get_market_summary_by_enum(params) {
    return Axios({ method: 'GET', url: api.investor.get_market_summary_by_enum, params: params })
  },
  // 获取前十排行榜
  get_top_ten_by_type(params) {
    return Axios({ method: 'GET', url: api.investor.get_top_ten_by_type, params: params })
  },
  // e互动数据下载
  download_interact(data) {
    return Axios({ method: 'POST', url: api.investor.download_interact, data })
  },
  // 下载本公司全部数据
  download_all_company_interact(data) {
    return Axios({ method: 'POST', url: api.investor.download_all_company_interact, data })
  }
}
