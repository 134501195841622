import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取所有证监会行业，获取当前公司所属行业
  get_all_csrc_industry: function () {
    return Axios({ method: 'GET', url: api.planningResourcePool.get_all_csrc_industry })
  },
  // 股权激励案例检索
  retrieve_case(data) {
    return Axios({
      method: 'POST',
      url: api.planningResourcePool.retrieve_case,
      data
    })
  },
  // 员工持股案例检索
  employee_case(data, params) {
    return Axios({
      method: 'POST',
      url: api.planningResourcePool.employee_case,
      data,
      params
    })
  },
  // 股权激励案例方案详情
  retrieve_case_detail(caseId) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.planningResourcePool.retrieve_case_detail, [['caseId', caseId]]),
      originUrl: api.planningResourcePool.retrieve_case_detail
    })
  },
  // 股权激励案例方案详情
  employee_case_detail(caseId) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.planningResourcePool.employee_case_detail, [['caseId', caseId]]),
      originUrl: api.planningResourcePool.employee_case_detail
    })
  },
  // 案例基本信息
  base_information(caseId) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.planningResourcePool.base_information, [['caseId', caseId]]),
      originUrl: api.planningResourcePool.base_information
    })
  },
  // 案例公司信息
  company_information(caseId) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.planningResourcePool.company_information, [['caseId', caseId]]),
      originUrl: api.planningResourcePool.company_information
    })
  },
  // 董监高薪酬水平
  salaries_level(params) {
    return Axios({
      method: 'GET',
      url: api.planningResourcePool.salaries_level,
      params
    })
  },
  // 本公司三年财务数据分析
  finance_last_three_years(params) {
    return Axios({
      method: 'GET',
      url: api.planningResourcePool.finance_last_three_years,
      params
    })
  },
  // 本公司三年财务数据详情
  finance_details_last_three_years(params) {
    return Axios({
      method: 'GET',
      url: api.planningResourcePool.finance_details_last_three_years,
      params
    })
  },
  // 增加新对比案例
  add_compare_cases(params, companyId, caseId) {
    return Axios({
      method: 'POST',
      url: Common.pathFilter(api.planningResourcePool.add_compare_cases, [['companyId', companyId], ['caseId', caseId]]),
      originUrl: api.planningResourcePool.add_compare_cases,
      params
    })
  },
  //  删除对比案例
  delete_compare_cases(params, companyId, caseId) {
    return Axios({
      method: 'DELETE',
      url: Common.pathFilter(api.planningResourcePool.delete_compare_cases, [['companyId', companyId], ['caseId', caseId]]),
      originUrl: api.planningResourcePool.delete_compare_cases,
      params
    })
  },
  //  批量删除对比案例
  batch_delete_compare_cases(params, companyId) {
    return Axios({
      method: 'DELETE',
      url: Common.pathFilter(api.planningResourcePool.batch_delete_compare_cases, [['companyId', companyId]]),
      originUrl: api.planningResourcePool.batch_delete_compare_cases,
      params
    })
  },
  // 当前用户案例库简要对比列表
  contrastive_case(params, companyId) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.planningResourcePool.contrastive_case, [['companyId', companyId]]),
      originUrl: api.planningResourcePool.contrastive_case,
      params
    })
  },
  // 股权激励案例对比明细
  contrastive_detail() {
    return Axios({
      method: 'GET',
      url: api.planningResourcePool.contrastive_detail
    })
  },
  // 员工持股计划案例对比明细
  employee_contrastive_detail() {
    return Axios({
      method: 'GET',
      url: api.planningResourcePool.employee_contrastive_detail
    })
  },
  equity_incentive_summary(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.planningResourcePool.equity_incentive_summary, [['caseId', caseId]]), originUrl: api.planningResourcePool.equity_incentive_summary })
  },
  process_stock_price(caseId, toolId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.planningResourcePool.process_stock_price, [['caseId', caseId], ['toolId', toolId]]), params, originUrl: api.planningResourcePool.process_stock_price })
  },
  process_detail(caseId, toolId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.planningResourcePool.process_detail, [['caseId', caseId], ['toolId', toolId]]), originUrl: api.planningResourcePool.process_detail })
  },
  get_sw_industry_list() {
    return Axios({ method: 'GET', url: api.planningResourcePool.get_sw_industry_list })
  },
  incentive_follow_company(params) {
    return Axios({ method: 'GET', url: api.planningResourcePool.incentive_follow_company, params })
  },
  income_trial(caseId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.planningResourcePool.income_trial, [['caseId', caseId]]), originUrl: api.planningResourcePool.income_trial, params })
  },
  contrastive_cases_income_trial(params) {
    return Axios({ method: 'GET', url: api.planningResourcePool.contrastive_cases_income_trial, params })
  }
}
