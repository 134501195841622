import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  sentiment_csrc_industries(params) {
    return Axios({ method: 'GET', url: api.sentiment.sentiment_csrc_industries, params })
  },
  chart_sentiment_and_stock_trend: function (params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.chart_sentiment_and_stock_trend, [['companyId', companyId]]), data: params, originUrl: api.sentiment.chart_sentiment_and_stock_trend })
  },
  chart_sentiment_tendency: function (params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.chart_sentiment_tendency, [['companyId', companyId]]), data: params, originUrl: api.sentiment.chart_sentiment_tendency })
  },
  get_default_sentiment_monitor_plan: function (companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.sentiment_monitor_plan, [['companyId', companyId]]), originUrl: api.sentiment.sentiment_monitor_plan })
  },
  get_sentiment_monitor_plans_by_plan_id: function (planId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.operate_sentiment_monitor_plans, [['planId', planId]]), originUrl: api.sentiment.operate_sentiment_monitor_plans })
  },
  delete_sentiment_monitor_plans: function (planId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.sentiment.operate_sentiment_monitor_plans, [['planId', planId]]), originUrl: api.sentiment.operate_sentiment_monitor_plans })
  },
  update_sentiment_monitor_plans: function (params = {}, planId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.sentiment.operate_sentiment_monitor_plans, [['planId', planId]]), data: params, originUrl: api.sentiment.operate_sentiment_monitor_plans })
  },
  create_sentiment_monitor_plans: function (params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.sentiment_monitor_plan, [['companyId', companyId]]), data: params, originUrl: api.sentiment.sentiment_monitor_plan })
  },
  // 公司舆情列表
  list_company_sentiments: function (params = {}) {
    return Axios({ method: 'POST', url: api.sentiment.list_company_sentiments, data: params })
  },
  // 行业舆情列表
  industry_company_sentiments: function (params = {}) {
    return Axios({ method: 'POST', url: api.sentiment.industry_company_sentiments, data: params })
  },
  // 市场舆情搜索
  list_market_sentiments: function (params = {}) {
    return Axios({ method: 'POST', url: api.sentiment.list_market_sentiments, data: params })
  },
  // 生成报告列表
  sentiment_report: function (params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.sentiment_report, [['companyId', companyId]]), params: params, originUrl: api.sentiment.sentiment_report })
  },
  // 删除生成报告
  delete_sentiment_report: function (id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.sentiment.delete_sentiment_report, [['id', id]]), originUrl: api.sentiment.delete_sentiment_report })
  },
  sentiment_company_info: function (params = {}) {
    return Axios({ method: 'GET', url: api.sentiment.sentiment_company_info, params: params })
  },
  company_sentiments(params) {
    return Axios({ method: 'POST', url: api.sentiment.company_sentiments, data: params })
  },
  // 获取推送舆情的消息列表
  get_sentiment_message_list_by_key(params = {}, planId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_sentiment_message_list_by_key, [['planId', planId]]), params: params, originUrl: api.sentiment.get_sentiment_message_list_by_key })
  },
  // 查询可比公司舆情
  compare_company_sentiments(params) {
    return Axios({ method: 'POST', url: api.sentiment.compare_company_sentiments, data: params })
  },
  // 情感纠错（列表情感纠错）
  sentiment_error_correction(params) {
    return Axios({ method: 'POST', url: api.sentiment.sentiment_error_correction, data: params })
  },
  // 舆情监控系统及其他反馈入口(侧边悬浮按钮)
  sentiment_surveillance_system(params) {
    return Axios({ method: 'POST', url: api.sentiment.sentiment_surveillance_system, data: params })
  },
  // 溯源分析
  get_traceability_list(data) {
    return Axios({ method: 'POST', url: api.sentiment.get_traceability_list, data })
  },
  // 获取舆情用户偏好
  get_sentiment_user_preferences(companyId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_sentiment_user_preferences, [['companyId', companyId]]), params, originUrl: api.sentiment.get_sentiment_user_preferences })
  },
  // 更新用户偏好设置
  update_sentiment_user_preferences(data, params, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.update_sentiment_user_preferences, [['companyId', companyId]]), data, params, originUrl: api.sentiment.update_sentiment_user_preferences })
  },
  // 公司最新舆情
  get_company_lasted_sentiment(params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.get_company_lasted_sentiment, [['companyId', companyId]]), data: params, originUrl: api.sentiment.get_company_lasted_sentiment })
  },
  // 公司各渠道舆情来源统计
  get_company_media_type_statics(params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.get_company_media_type_statics, [['companyId', companyId]]), data: params, originUrl: api.sentiment.get_company_media_type_statics })
  },
  // 公司舆情媒体量分布
  get_media_num_statics(params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.get_media_num_statics, [['companyId', companyId]]), data: params, originUrl: api.sentiment.get_media_num_statics })
  },
  // 公司舆情媒体量变化趋势
  get_media_change_trend(params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.get_media_change_trend, [['companyId', companyId]]), data: params, originUrl: api.sentiment.get_media_change_trend })
  },
  // 公司舆情媒体量变化趋势
  get_source_trend_change(params = {}, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.get_source_trend_change, [['companyId', companyId]]), data: params, originUrl: api.sentiment.get_source_trend_change })
  },
  // 公司舆情导出表格
  get_excel_url(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_excel_url, [['companyId', companyId]]), params, originUrl: api.sentiment.get_excel_url })
  },
  // 获取任务进度
  get_excel_task(params = {}) {
    return Axios({ method: 'GET', url: api.sentiment.get_excel_task, params })
  },
  // 获取舆情详细信息
  get_sentiment_details(sentimentId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_sentiment_details, [['sentimentId', sentimentId]]), params, originUrl: api.sentiment.get_sentiment_details })
  },
  // 判断业务客户
  judge_is_business_customers(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.judge_is_business_customers, [['companyId', companyId]]), originUrl: api.sentiment.judge_is_business_customers })
  },

  chart_industry_sentiment_tendency: function (params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.chart_industry_sentiment_tendency, [['companyId', companyId]]), params, originUrl: api.sentiment.chart_industry_sentiment_tendency })
  },
  // 行业最新舆情
  get_industry_lasted_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_lasted_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_lasted_sentiment })
  },
  // 行业热门舆情
  get_industry_hot_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_hot_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_hot_sentiment })
  },
  // 行业舆情情感趋势
  get_industry_emotion_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_emotion_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_emotion_trend })
  },
  // 行业媒体分布
  get_industry_media_distribution(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_media_distribution, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_media_distribution })
  },
  // 行业媒体变化趋势
  get_industry_media_change_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_media_change_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_media_change_trend })
  },
  // 行业舆情来源
  get_industry_source_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_source_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_source_sentiment })
  },
  // 行业舆情来源变化趋势
  get_industry_source_change_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_industry_source_change_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_industry_source_change_trend })
  },
  // 可比公司舆情查询
  get_compare_company_sentiment_list(params) {
    return Axios({ method: 'POST', url: api.sentiment.get_compare_company_sentiment_list, data: params })
  },

  // 可比公司舆情分布
  get_compare_company_sentiment_distribution(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_sentiment_distribution, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_sentiment_distribution })
  },
  // 可比公司最新舆情
  get_compare_company_lasted_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_lasted_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_lasted_sentiment })
  },
  // 可比公司热门舆情
  get_compare_company_hot_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_hot_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_hot_sentiment })
  },
  // 可比公司舆情情感趋势
  get_compare_company_emotion_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_emotion_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_emotion_trend })
  },
  // 可比公司媒体分布
  get_compare_company_media_distribution(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_media_distribution, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_media_distribution })
  },
  // 可比公司媒体变化趋势
  get_compare_company_media_change_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_media_change_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_media_change_trend })
  },
  // 可比公司舆情来源
  get_compare_company_source_sentiment(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_source_sentiment, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_source_sentiment })
  },
  // 可比公司舆情来源变化趋势
  get_compare_company_source_change_trend(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_compare_company_source_change_trend, [['companyId', companyId]]), params, originUrl: api.sentiment.get_compare_company_source_change_trend })
  },
  // 全网舆情
  get_all_net_sentiment_list(params = {}) {
    return Axios({ method: 'POST', url: api.sentiment.get_all_net_sentiment_list, data: params, originUrl: api.sentiment.get_all_net_sentiment_list })
  },
  // 自定义舆情
  get_custom_sentiment_list(params = {}) {
    return Axios({ method: 'POST', url: api.sentiment.get_custom_sentiment_list, data: params, originUrl: api.sentiment.get_custom_sentiment_list })
  },
  // 获取监控方案列表
  get_sentiment_monitor_List(params = {}, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_sentiment_monitor_List, [['companyId', companyId]]), params, originUrl: api.sentiment.get_sentiment_monitor_List })
  },
  // 资本市场溯源分析
  get_market_trace_analysis(params) {
    return Axios({ method: 'POST', url: api.sentiment.get_market_trace_analysis, data: params })
  },
  // 生成舆情报告
  sentiment_reports_generate(data, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.sentiment.sentiment_reports_generate, [['companyId', companyId]]), data, originUrl: api.sentiment.sentiment_reports_generate })
  },
  // 溯源分析公司
  get_traceability_companyId(rel) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.get_traceability_companyId, [['rel', rel]]), originUrl: api.sentiment.get_traceability_companyId })
  },
  get_sentiment_report_dialog(data) {
    return Axios({ method: 'POST', url: api.sentiment.get_sentiment_report_dialog, data })
  },
  get_sentiment_summary_remain_count() {
    return Axios({ method: 'GET', url: api.sentiment.get_sentiment_summary_remain_count })
  },
  company_sentiment_summary(sentimentId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.company_sentiment_summary, [['sentimentId', sentimentId]]), originUrl: api.sentiment.company_sentiment_summary })
  },
  market_sentiment_summary(sentimentId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.sentiment.market_sentiment_summary, [['sentimentId', sentimentId]]), originUrl: api.sentiment.market_sentiment_summary })
  }
}
