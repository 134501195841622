import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 查询子公司列表
  get_organizations_subsidiaries(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.organizations_subsidiaries, [['organizationId', organizationId]]), originUrl: api.esg.organizations_subsidiaries })
  },
  // 修改子公司名称
  edit_subsidiary(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.esg.operate_subsidiary, [['id', id]]), data: params, originUrl: api.esg.operate_subsidiary })
  },
  // 添加子公司
  add_organizations_subsidiaries(params, organizationId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.organizations_subsidiaries, [['organizationId', organizationId]]), data: params, originUrl: api.esg.organizations_subsidiaries })
  },
  // 删除子公司
  delete_subsidiary(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.esg.operate_subsidiary, [['id', id]]), originUrl: api.esg.operate_subsidiary })
  },
  // 修改成员所属子公司接口
  editMember_subsidiary_relation(params, memberId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.esg.member_subsidiary_relation, [['memberId', memberId]]), data: params, originUrl: api.esg.member_subsidiary_relation })
  },
  // 查询成员列表
  get_organizations_members(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.organizations_members, [['organizationId', organizationId]]), originUrl: api.esg.organizations_members })
  },
  // 任务分配指标列表
  issue_allocations(taskId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.issue_allocations, [['taskId', taskId]]), originUrl: api.esg.issue_allocations })
  },
  // 议题、填报人获取
  issue_allocation(taskId, subsidiaryId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.issue_allocation, [['taskId', taskId], ['subsidiaryId', subsidiaryId]]), originUrl: api.esg.issue_allocation })
  },
  // 保存填报数据
  issue_allocation_draft(params, taskId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.issue_allocation_draft, [['taskId', taskId]]), data: params, originUrl: api.esg.issue_allocation_draft })
  },
  // 填报任务下发
  issue_allocation_send(params, taskId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.issue_allocation_send, [['taskId', taskId]]), data: params, originUrl: api.esg.issue_allocation_send })
  },
  // 获取任务列表
  organizations_tasks(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.organizations_tasks, [['organizationId', organizationId]]), originUrl: api.esg.organizations_tasks })
  },
  // 查询任务详情
  query_task(params, taskId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.operate_task, [['taskId', taskId]]), params: params, originUrl: api.esg.operate_task })
  },
  // 提交任务
  submit_task(params, organizationId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.organizations_tasks, [['organizationId', organizationId]]), data: params, originUrl: api.esg.organizations_tasks })
  },
  // 删除任务
  delete_task(taskId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.esg.operate_task, [['taskId', taskId]]), originUrl: api.esg.operate_task })
  },
  // 保存任务(创建记录)
  task_draft(params, organizationId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.task_draft, [['organizationId', organizationId]]), data: params, originUrl: api.esg.task_draft })
  },
  // 更新任务状态
  update_task_status(params, taskId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.esg.task_status, [['taskId', taskId]]), data: params, originUrl: api.esg.task_status })
  },
  // 获取填报任务列表
  organizations_subtasks(params, organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.organizations_subtasks, [['organizationId', organizationId]]), params: params, originUrl: api.esg.organizations_subtasks })
  },
  // 查询填报数据
  get_filling_data(taskId, informantId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.get_filling_data, [['taskId', taskId], ['informantId', informantId]]), originUrl: api.esg.get_filling_data })
  },
  // 保存填报数据
  save_filling_data(params, taskId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.filling_data, [['taskId', taskId]]), data: params, originUrl: api.esg.filling_data })
  },
  // 填报数据保存并通知
  save_and_notify_filling_data(params, taskId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.filling_data_notify, [['taskId', taskId]]), data: params, originUrl: api.esg.filling_data_notify })
  },
  // 提交审核填报数据
  filling_data_submitAudit(params, taskId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.filling_data_submitAudit, [['taskId', taskId]]), data: params, originUrl: api.esg.filling_data_submitAudit })
  },
  // 更新填报任务状态
  update_subtask_status(params, taskId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.esg.subtask_status, [['taskId', taskId]]), data: params, originUrl: api.esg.subtask_status })
  },
  // 获取报告可选年份列表
  get_report_years(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.report_years, [['organizationId', organizationId]]), originUrl: api.esg.report_years })
  },
  // 获取报告列表
  get_organizations_reports(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.organizations_reports, [['organizationId', organizationId]]), originUrl: api.esg.organizations_reports })
  },
  // 生成报告
  generate_report(params) {
    return Axios({ method: 'POST', url: api.esg.generate_report, data: params })
  },
  // 删除报告
  delete_report(reportId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.esg.delete_report, [['reportId', reportId]]), originUrl: api.esg.delete_report })
  },
  // 记录点击咨询服务按钮次数
  contact_record(params) {
    return Axios({ method: 'POST', url: api.esg.contact_record, data: params })
  },
  // 获取权限设置列表
  permission_control_list(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.esg.permission_control_list, [['companyId', companyId]]), originUrl: api.esg.permission_control_list })
  },
  // 添加/删除ESG用户组
  add_esg_member(params, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.esg.add_esg_member, [['companyId', companyId]]), data: params, originUrl: api.esg.add_esg_member })
  }
}
