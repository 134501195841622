import Axios from '@/service/http'
import api from '@/service/api'
import Storage from '@/service/storage'
import Common from '@/utils/common'
export default {
  Axios: Axios,
  // 添加分组
  add_holder_groups(params) {
    return Axios({ method: 'POST', url: api.gxfApi.holder_groups, params: params })
  },
  // 删除分组
  delete_holder_groups_by_id(groupId, params = {}) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.gxfApi.operate_holder_groups_by_id, [['groupId', groupId]]), originUrl: api.gxfApi.operate_holder_groups_by_id, params })
  },
  // 删除控股股东
  delete_holder_by_id(groupId, holderId, params = {}) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.gxfApi.delete_holder_by_id, [['groupId', groupId], ['holderId', holderId]]), originUrl: api.gxfApi.delete_holder_by_id, params })
  },
  // 修改组信息
  edit_holder_groups_by_id(params, groupId, codeParams = {}) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.gxfApi.operate_holder_groups_by_id, [['groupId', groupId]]), data: params, params: codeParams, originUrl: api.gxfApi.operate_holder_groups_by_id })
  },
  // 修改警戒线
  edit_guard_line(params, groupId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.gxfApi.edit_guard_line, [['groupId', groupId]]), params: params, originUrl: api.gxfApi.edit_guard_line })
  },
  // 设置控股股东
  set_controlling_shareholders(params, groupId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.gxfApi.set_controlling_shareholders, [['groupId', groupId]]), params: params, originUrl: api.gxfApi.set_controlling_shareholders })
  },
  // 根据关键词查询股东
  query_shareholder_by_name(params) {
    return Axios({ method: 'GET', url: api.gxfApi.query_shareholder_by_name, params: params })
  },

  // 获取机构热词
  get_organizations_hot_words(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_organizations_hot_words, params: params })
  },
  // 获取股东信息
  get_shareholders_by_institution(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_shareholders_by_institution, params: params })
  },
  // 自动填充机构热词
  organizations_hot_words_auto_fill(params) {
    return Axios({ method: 'GET', url: api.gxfApi.organizations_hot_words_auto_fill, params: params })
  },
  // 编辑投资者
  update_investors_data(params) {
    return Axios({ method: 'PUT', url: api.gxfApi.operate_investors_data, params: params })
  },
  // 编辑投资者
  delete_investors_data(params) {
    return Axios({ method: 'DELETE', url: api.gxfApi.operate_investors_data, params: params })
  },
  // 搜素自动填充
  investor_auto_complete(params) {
    return Axios({ method: 'GET', url: api.gxfApi.investor_auto_complete, params: params })
  },
  // 导出数据库
  export_investors_data(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.export_investors_data, [['code', code]]), originUrl: api.gxfApi.export_investors_data, params: params })
  },
  // 获取投资者信息byId
  get_investors_data_by_id(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_investors_data_by_id, [['id', id]]), params: params, originUrl: api.gxfApi.get_investors_data_by_id })
  },
  // 投资者数据库管理
  search_investor(params) {
    return Axios({ method: 'GET', url: api.gxfApi.search_investor, params: params })
  },
  // 投资者数据库调研记录管理
  invest_researches_records_by_id(id, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.invest_researches_records_by_id, [['id', id]]), originUrl: api.gxfApi.invest_researches_records_by_id, params })
  },
  search_participants(params) {
    return Axios({ method: 'GET', url: api.gxfApi.search_participants, params: params })
  },
  invest_researches_records(params) {
    return Axios({ method: 'GET', url: api.gxfApi.invest_researches_records, params: params })
  },
  edit_investor_research_record(params, id, codeParams = {}) {
    const formData = new FormData()
    const paramsData = { ...params, ...{ securityCode: Storage.getSelectInfo().companyCode } }
    Object.keys(paramsData).forEach((e) => {
      formData.append(e, paramsData[e])
    })
    return Axios({ method: 'PUT', url: Common.pathFilter(api.gxfApi.edit_investor_research_record, [['id', id]]), data: formData, params: codeParams, originUrl: api.gxfApi.edit_investor_research_record })
  },
  download_investor_research_record(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.download_investor_research_record, [['id', id]]), params: params, originUrl: api.gxfApi.download_investor_research_record })
  },
  delete_investor_research_record(id, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.gxfApi.delete_investor_research_record, [['id', id]]), originUrl: api.gxfApi.delete_investor_research_record, params })
  },
  add_investor_research_record(params, codeParams = {}) {
    const formData = new FormData()
    const paramsData = { ...params, ...{ securityCode: Storage.getSelectInfo().companyCode } }
    Object.keys(paramsData).forEach((e) => {
      formData.append(e, paramsData[e])
    })
    return Axios({ method: 'POST', url: api.gxfApi.add_investor_research_record, data: formData, params: codeParams })
  },
  //  获取股价对比
  get_stock_price_comparison(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_stock_price_comparison, params: params })
  },
  //  分组股东对比-股东组对比信息
  holder_groups_comparison(params) {
    return Axios({ method: 'GET', url: api.gxfApi.holder_groups_comparison, params: params })
  },
  //  机构股东对比分析
  get_institution_group_comparison_info(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_institution_group_comparison_info, params: params })
  },
  //  重要股东概览
  get_important_holder_overview(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_important_holder_overview, params: params })
  },
  //  重要股东-最新前十大股东
  get_lasted_important_holder_info(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_lasted_important_holder_info, params: params })
  },
  //  重要股东-最新前十大流通股东
  get_lasted_circulation_shareholders(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_lasted_circulation_shareholders, params: params })
  },
  //  生成股东趋势分析报告
  generate_holder_trend_report(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.generate_holder_trend_report, [['code', code]]), params: params, originUrl: api.gxfApi.generate_holder_trend_report })
  },
  //  限售股解禁时间表详情
  get_lock_stock_time_detail(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_lock_stock_time_detail, [['code', code]]), params: params, originUrl: api.gxfApi.get_lock_stock_time_detail })
  },
  //  限售股解禁时间表
  get_lock_stock_time_list(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_lock_stock_time_list, [['code', code]]), originUrl: api.gxfApi.get_lock_stock_time_list, params })
  },
  //  获取公募基金持股情况分析
  get_public_holder(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_public_holder, [['code', code]]), params: params, originUrl: api.gxfApi.get_public_holder })
  },
  //  获取潜在基金持股情况分析
  get_potential_fund_holder_by_stock_code(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_potential_fund_holder_by_stock_code, params: params })
  },
  //  获取潜在基金持股情况明细
  get_potential_fund_holder_details(fundId, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_potential_fund_holder_details, [['fundId', fundId]]), originUrl: api.gxfApi.get_potential_fund_holder_details, params })
  },
  //  获取股份整体质押情况
  get_stock_pledge(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_stock_pledge, [['code', code]]), originUrl: api.gxfApi.get_stock_pledge, params })
  },
  //  融资融券情况分析（列表、图表）
  list_and_chart_RZRQ_analysis(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.list_and_chart_RZRQ_analysis, [['code', code]]), params: params, originUrl: api.gxfApi.list_and_chart_RZRQ_analysis })
  },
  //  融资融券情况分析（列表、图表）数据导出
  list_and_chart_RZRQ_analysis_down(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.list_and_chart_RZRQ_analysis_down, [['code', code]]), params: params, originUrl: api.gxfApi.list_and_chart_RZRQ_analysis_down })
  },
  //  沪、深股通持股情况分析（列表、图表）
  list_and_chart_SHSZGT_stock_ownership_analysis(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.list_and_chart_SHSZGT_stock_ownership_analysis, [['code', code]]), params: params, originUrl: api.gxfApi.list_and_chart_SHSZGT_stock_ownership_analysis })
  },
  //  沪、深股通持股情况分析（列表、图表）数据导出
  list_and_chart_SHSZGT_stock_ownership_analysis_down(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.list_and_chart_SHSZGT_stock_ownership_analysis_down, [['code', code]]), params: params, originUrl: api.gxfApi.list_and_chart_SHSZGT_stock_ownership_analysis_down })
  },
  // 点击“家数”获取详情
  get_public_manager_info_list(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_public_manager_info_list, [['code', code]]), params: params, originUrl: api.gxfApi.get_public_manager_info_list })
  },
  // 点击“产品”获取详情
  get_public_product_info_list(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_public_product_info_list, [['code', code]]), params: params, originUrl: api.gxfApi.get_public_product_info_list })
  },
  // 查询上传记录
  get_rolls_upload_records(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.rolls_upload_records, params })
  },
  // 获取名册管理数据
  get_rolls_statistics(params) {
    return Axios({ method: 'GET', url: api.gxfApi.rolls_statistics, params: params })
  },
  // 查询上传记录
  holder_roll_official(params) {
    return Axios({ method: 'POST', url: api.gxfApi.holder_roll_official, params: params })
  },
  // 机构股东最新持股情况导出
  institution_shareholder_excel(params) {
    return Axios({ method: 'GET', url: api.gxfApi.institution_shareholder_excel, params: params })
  },
  // 根据上传Id删除导入记录
  delete_rolls_by_ids(params) {
    return Axios({ method: 'DELETE', url: api.gxfApi.delete_rolls_by_ids, params: params })
  },
  //  根据用户ID清除所有上传记录
  delete_rolls_upload_records(params = {}) {
    return Axios({ method: 'DELETE', url: api.gxfApi.rolls_upload_records, params })
  },
  analysis_user_roll(params, codeParams = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.analysis_user_roll, data: params, params: codeParams })
  },
  // 获取文件解析结果
  file_analysis_result(taskId, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.file_analysis_result, [['taskId', taskId]]), originUrl: api.gxfApi.file_analysis_result, params })
  },
  // 查询最近一次上传任务的id
  get_latest_analyis_task_id(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_latest_analyis_task_id, params })
  },
  // 获取名册期数(同时返回流通股数)
  get_lasted_record_date(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_lasted_record_date, params: params })
  },
  // 获取名册期数
  get_lasted_record_date_with_circulate_all_amount(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_lasted_record_date_with_circulate_all_amount, params: params })
  },
  // 股东人员信息
  search_shareholders(params) {
    return Axios({ method: 'GET', url: api.gxfApi.search_shareholders, params: params })
  },
  // 股东名册分享
  shareholders_list_share(params, codeParams = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.shareholders_list_share, data: params, params: codeParams })
  },
  // 股东名册分享状态
  shareholders_list_share_result(params, codeParams = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.shareholders_list_share_result, data: params, params: codeParams })
  },
  // 获取股东性质枚举
  get_holder_groups_types(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_holder_groups_types, params })
  },
  // 增加投资者
  add_investors_data(params = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.operate_investors_data, params: params })
  },
  // 根据id添加投资者数据
  add_investor_by_id(id, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.gxfApi.add_investor_by_id, [['id', id]]), originUrl: api.gxfApi.add_investor_by_id, params })
  },
  // 获取组信息
  get_holder_groups(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.holder_groups, params: params })
  },
  // 分项股东对比-股东性质对比信息
  get_holder_property_comparison_info(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_holder_property_comparison_info, params: params })
  },
  // 股东对比分析总览-获取股东对比信息
  shareholders_comparison(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.shareholders_comparison, params: params })
  },
  // 股东对比分析总览-获取一键对比信息
  get_multi_period_comparison_info(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_multi_period_comparison_info, params: params })
  },
  // 导出一键对比Excel报告
  multi_comparison_analysis_excel(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.multi_comparison_analysis_excel, [['companyCode', companyCode]]), params: params, originUrl: api.gxfApi.multi_comparison_analysis_excel })
  },
  // 导出机构股东对比分析excel
  comparison_analysis_excel(params) {
    return Axios({ method: 'GET', url: api.gxfApi.comparison_analysis_excel, params: params })
  },
  // 获取股东对比变动图
  get_holder_comparison_chart(params = {}, codeParams = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.get_holder_comparison_chart, data: params, params: codeParams })
  },
  search_shareholder_list(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.search_shareholder_list, params: params })
  },
  shareholders_structure_by_holding_stocks(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.shareholders_structure_by_holding_stocks, params: params })
  },
  shareholders_structure_by_shareholder_nature(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.shareholders_structure_by_shareholder_nature, params: params })
  },
  get_credit_guarantee_info(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_credit_guarantee_info, params: params })
  },
  shareholders_structure_by_shareholder_location(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.shareholders_structure_by_shareholder_location, params: params })
  },
  // 个人信息
  get_shareholder_info(id, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_shareholder_info, [['id', id]]), originUrl: api.gxfApi.get_shareholder_info, params })
  },
  get_concerted_share_changes(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_concerted_share_changes, params: params })
  },
  // 查询股东基本信息
  get_shareholders_basic_info(id, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_shareholders_basic_info, [['id', id]]), originUrl: api.gxfApi.get_shareholders_basic_info, params })
  },
  // 查询股东基本信息
  get_other_products_share_changes(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_other_products_share_changes, params: params })
  },
  // 查询股东基本信息
  get_other_share_holding(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_other_share_holding, params: params })
  },
  // 查询股东基本信息
  get_public_value_report(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_public_value_report, params: params })
  },
  // 查询股东机构的工商信息
  official_data_institution_business(id, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.official_data_institution_business, [['id', id]]), originUrl: api.gxfApi.official_data_institution_business, params })
  },
  // 查询旗下其他产品持有公司股份的情况的持股变动图
  get_other_products_hold_changes_chart(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_other_products_hold_changes_chart, params: params })
  },
  // 获取趋势分析头部的数量统计
  get_general_info_by_stock_code(stockCode, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_general_info_by_stock_code, [['stockCode', stockCode]]), originUrl: api.gxfApi.get_general_info_by_stock_code, params })
  },
  // 获取同行业股东户数
  list_same_industry_shareholders(stockCode, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.list_same_industry_shareholders, [['stockCode', stockCode]]), originUrl: api.gxfApi.list_same_industry_shareholders, params })
  },
  // 股东户数及平均持股情况分析
  get_stock_holder_and_share_info_by_stock_code(stockCode, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_stock_holder_and_share_info_by_stock_code, [['stockCode', stockCode]]), originUrl: api.gxfApi.get_stock_holder_and_share_info_by_stock_code, params })
  },
  // 股东户数与股价情况分析
  get_stock_holder_and_stock_price_by_stock_code(stockCode, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_stock_holder_and_stock_price_by_stock_code, [['stockCode', stockCode]]), originUrl: api.gxfApi.get_stock_holder_and_stock_price_by_stock_code, params })
  },
  // 获取用户所绑定的股票代码
  get_user_stock_code(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_user_stock_code, params })
  },
  // 生成股东分析报告
  derive_holder_analysis_report(data, code, params = {}) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.gxfApi.derive_holder_analysis_report, [['code', code]]), data, params, originUrl: api.gxfApi.derive_holder_analysis_report })
  },
  filter_delisting_auto_complete(params) {
    return Axios({ method: 'GET', url: api.gxfApi.filter_delisting_auto_complete, params: params })
  },
  // 获取趋势报告导出参数
  get_report_periods(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_report_periods, params: params })
  },
  // 获取基金经理
  get_fund_manger(id, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_fund_manger, [['id', id]]), originUrl: api.gxfApi.get_fund_manger, params })
  },
  // 获取基金经理产品
  get_fund_manger_product(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_fund_manger_product, params: params })
  },
  // 机构股东对比分析
  // 查看未匹配股东
  get_unmatched_shareholders(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_unmatched_shareholders, params })
  },
  // 获取机构股东分组
  institution_group_holders(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.institution_group_holders, params: params })
  },
  // 上传股东名册历史数据
  upload_history_data(data = {}, params = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.upload_history_data, data: data, params })
  },
  // 根据名册类型及summaryIds删除无效名册数据
  rollData_of_rollType(params) {
    return Axios({ method: 'DELETE', url: api.gxfApi.rollData_of_rollType, params: params })
  },
  // 获取仅分析流通股开关状态
  get_circulation_stock_status(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.circulation_stock_status, params: params })
  },
  // 修改仅分析流通股开关状态
  set_circulation_stock_status(params) {
    return Axios({ method: 'PUT', url: api.gxfApi.circulation_stock_status, params: params })
  },
  // 修改股东名册流通总股本
  set_shareholder_rolls_circulation_stock(params, codeParams = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.shareholder_rolls_circulation_stock, data: params, params: codeParams })
  },
  // 股东对比分析户数变化
  get_stock_comparison_of_number(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_stock_comparison_of_number, params: params })
  },
  // 获取code所属板块（仅返回SH SZ OTHER，在股行分模块中使用）
  get_market_in_gxf(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_market_in_gxf, [['code', code]]), originUrl: api.gxfApi.get_market_in_gxf, params })
  },
  // 获取股东分析范围
  get_analysis_range(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_analysis_range, params: params })
  },
  // 获取用户分析选项设置
  get_analysis_option(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.analysis_option, params })
  },
  shareholder_rolls_verify(params, codeParams) {
    return Axios({ method: 'POST', url: api.gxfApi.shareholder_rolls_verify, data: params, params: codeParams })
  },
  // 获取文件校验结果
  shareholder_rolls_verify_result(taskId, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.shareholder_rolls_verify_result, [['taskId', taskId]]), originUrl: api.gxfApi.shareholder_rolls_verify_result, params })
  },
  // 获取名册股东历史持仓统计数据
  holders_historical_position(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.holders_historical_position, params: params })
  },
  // 获取历史持仓统计数据是否已准备
  holders_historical_position_ready(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.holders_historical_position_ready, params: params })
  },
  // 根据股东Id获取该股东历史持仓数据
  holders_historical_position_detail(holderId, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.holders_historical_position_detail, [['holderId', holderId]]), originUrl: api.gxfApi.holders_historical_position_detail, params })
  },
  // 导出股东历史持仓统计excel文件
  holders_historical_position_excel(params = {}) {
    return Axios({ method: 'POST', url: api.gxfApi.holders_historical_position_excel, params: params })
  },
  // 导出某个股东历史持仓excel文件
  holders_single_historical_position_excel(holderId, params = {}) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.gxfApi.holders_single_historical_position_excel, [['holderId', holderId]]), originUrl: api.gxfApi.holders_single_historical_position_excel, params })
  },
  // 选择期数自定义生成数据时查询统计状态
  holders_historical_position_calculation(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.holders_historical_position_calculation, params: params })
  },
  shareholder_rolls_uncommitted(params) {
    return Axios({ method: 'GET', url: api.gxfApi.shareholder_rolls_uncommitted, params: params })
  },
  get_by_holding_quantity(params) {
    return Axios({ method: 'GET', url: api.gxfApi.get_by_holding_quantity, params: params })
  },
  single_excel_export(params) {
    return Axios({ method: 'GET', url: api.gxfApi.single_excel_export, params })
  },
  //  替换/v1/company/{统一社会信用代码/注册号}/relation_list
  risk_storm_code_relation_list(code, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.risk_storm_code_relation_list, [['code', code]]), originUrl: api.gxfApi.risk_storm_code_relation_list, params })
  },
  //  替换/v1/company/{注册号/统一社会信用代码}/risk
  risk_storm_code_risk(code, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.risk_storm_code_risk, [['code', code]]), originUrl: api.gxfApi.risk_storm_code_risk, params })
  },
  //  替换/v1/company/{注册号/统一社会信用代码}/ktgg
  risk_storm_code_court_announcement(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.risk_storm_code_court_announcement, [['code', code]]), originUrl: api.gxfApi.risk_storm_code_court_announcement, params: params })
  },
  //  替换/v1/company/{注册号/统一社会信用代码}/cpws
  risk_storm_code_judgment_documents(params, code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.risk_storm_code_judgment_documents, [['code', code]]), originUrl: api.gxfApi.risk_storm_code_judgment_documents, params: params })
  },
  // 按股东结构对比-多期股东持股集中度结构对比数据
  multi_periods_by_holding_stocks(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.multi_periods_by_holding_stocks, params: params })
  },
  // 按股东结构对比-两期股东持股集中度结构对比数据
  two_periods_by_holding_stocks(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.two_periods_by_holding_stocks, params: params })
  },
  // 按股东结构对比-多期股东按持股数量结构对比数据
  multi_periods_by_holding_quantity(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.multi_periods_by_holding_quantity, params: params })
  },
  // 按股东结构对比-两期股东按持股数量结构对比数据
  two_periods_by_holding_quantity(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.two_periods_by_holding_quantity, params: params })
  },
  // 按股东结构对比-多期股东地理位置结构对比数据
  multi_periods_by_shareholder_location(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.multi_periods_by_shareholder_location, params: params })
  },
  // 按股东结构对比-两期股东地理位置结构对比数据
  two_periods_by_shareholder_location(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.two_periods_by_shareholder_location, params: params })
  },
  // 按股东结构对比-多期信用担保账户结构对比数据
  multi_periods_by_credit_guarantee_account(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.multi_periods_by_credit_guarantee_account, params: params })
  },
  // 按股东结构对比-两期信用担保账户结构对比数据
  two_periods_by_credit_guarantee_account(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.two_periods_by_credit_guarantee_account, params: params })
  },

  // 获取机构类型
  get_institution_types(params = {}) {
    return Axios({ method: 'GET', url: api.gxfApi.get_institution_types, params: params })
  },
  // 同行业公司组件，左侧所有同行业公司
  get_companies_same_industry_companies(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_companies_same_industry_companies, [['code', code]]), originUrl: api.gxfApi.get_companies_same_industry_companies, params: params })
  },
  // 同行业公司组件，右侧已选择同行业公司
  get_companies_same_industry_selected_companies(code, params = {}) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.get_companies_same_industry_selected_companies, [['code', code]]), originUrl: api.gxfApi.get_companies_same_industry_selected_companies, params: params })
  },
  // 同行业公司组件，添加同行业公司
  add_same_industry_selected_companies(code, params, codeParams = {}) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.gxfApi.same_industry_selected_companies, [['code', code]]), originUrl: api.gxfApi.same_industry_selected_companies, data: params, params: codeParams })
  },
  // 同行业公司组件，删除同行业公司
  delete_same_industry_selected_companies(code, params, codeParams = {}) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.gxfApi.same_industry_selected_companies, [['code', code]]), originUrl: api.gxfApi.same_industry_selected_companies, data: params, params: codeParams })
  },
  // 同行业公司组件，恢复同行业公司
  same_industry_default(code, params = {}) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.gxfApi.same_industry_default, [['code', code]]), originUrl: api.gxfApi.same_industry_default, params })
  },
  // 同行业公司组件，左侧有同行业公司
  same_industry_search(params) {
    return Axios({ method: 'POST', url: api.gxfApi.same_industry_search, data: params })
  },
  // 同行业公司组件，右侧已选择同行业公司
  same_industry_selected_companies_search(params) {
    return Axios({ method: 'POST', url: api.gxfApi.same_industry_selected_companies_search, data: params })
  },
  // 增加同行业公司组件
  add_same_industry_companies(params) {
    return Axios({ method: 'POST', url: api.gxfApi.same_industry_selected_companies_edit, data: params })
  },
  // 删除同行业公司组件
  delete_same_industry_companies(params) {
    return Axios({ method: 'DELETE', url: api.gxfApi.same_industry_selected_companies_edit, data: params })
  },
  // 获取名册默认共享人
  get_default_collaborators(params) {
    return Axios({ method: 'GET', params, url: api.gxfApi.default_collaborators })
  },
  post_default_collaborators(data, params) {
    return Axios({ method: 'POST', url: api.gxfApi.default_collaborators, data, params })
  },
  holding_detail_excel(code, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.gxfApi.holding_detail_excel, [['code', code]]), originUrl: api.gxfApi.holding_detail_excel, params })
  }

}
