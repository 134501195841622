// @Description: 公告编制工具
// @Author: luchuang.wei
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取公告类别树
  get_announcement_type_tree(organizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.get_announcement_type_tree, [['organizationId', organizationId]]), params: params, originUrl: api.announcement.get_announcement_type_tree })
  },
  // 获取详情信息
  get_announcement_node_detail(nodeId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.get_announcement_node_detail, [['nodeId', nodeId]]), params: params, originUrl: api.announcement.get_announcement_node_detail })
  },
  // 下载某个节点下所有模板
  download_all_file_template(nodeId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.download_all_file_template, [['nodeId', nodeId]]), originUrl: api.announcement.download_all_file_template })
  },
  // 创建拟披露事项
  create_disclosure(data) {
    return Axios({ method: 'POST', url: api.announcement.create_disclosure, data })
  },
  // 查询拟披露事项列表
  get_disclosure_list(data) {
    return Axios({ method: 'POST', url: api.announcement.get_disclosure_list, data })
  },
  // 删除拟披露事项
  delete_disclosure(disclosureId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.announcement.operate_disclosure, [['disclosureId', disclosureId]]), originUrl: api.announcement.operate_disclosure })
  },
  // 获取当前公司下非创建会议阶段的所有会议以及会议文件
  get_meetings_files(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.get_meetings_files, [['organizationId', organizationId]]), originUrl: api.announcement.get_meetings_files })
  },
  // 公告编制工具 - 电子签名文件库
  get_signature_files_library(params) {
    return Axios({ method: 'GET', url: api.announcement.get_signature_files_library, params })
  },
  // 获取拟披露事项详情
  get_disclosure_detail(disclosureId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.operate_disclosure, [['disclosureId', disclosureId]]), params, originUrl: api.announcement.operate_disclosure })
  },
  // 新增披露事项文件
  add_choose_file(disclosureId, params, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.announcement.add_choose_file, [['disclosureId', disclosureId]]), params, data, originUrl: api.announcement.add_choose_file })
  },
  // 删除事项下的文件
  delete_announcement_file(disclosureId, fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.announcement.delete_announcement_file, [['disclosureId', disclosureId], ['fileId', fileId]]), originUrl: api.announcement.delete_announcement_file })
  },
  // 实时查询披露事项文件列表(仅获取列表数据)
  get_announcement_file(disclosureId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.operate_announcement_file, [['disclosureId', disclosureId]]), originUrl: api.announcement.operate_announcement_file })
  },
  // 实时更新披露事项文件列表
  set_announcement_file(disclosureId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.announcement.operate_announcement_file, [['disclosureId', disclosureId]]), data, originUrl: api.announcement.operate_announcement_file })
  },
  // 实时更新文件排序
  set_announcement_file_order(disclosureId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.announcement.set_announcement_file_order, [['disclosureId', disclosureId]]), data, originUrl: api.announcement.set_announcement_file_order })
  },
  // 保存拟披露事项
  save_disclosure(data) {
    return Axios({ method: 'POST', url: api.announcement.save_disclosure, data })
  },
  // 下载所选的文件
  download_disclosure(disclosureId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.announcement.download_disclosure, [['disclosureId', disclosureId]]), params, originUrl: api.announcement.download_disclosure })
  },
  // 查询归档事项列表
  get_all_disclosure(organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.get_all_disclosure, [['organizationId', organizationId]]), originUrl: api.announcement.get_all_disclosure })
  },
  // 实时查询披露事项披露要点
  get_disclosure_point(disclosureId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.operate_disclosure_point, [['disclosureId', disclosureId]]), originUrl: api.announcement.operate_disclosure_point })
  },
  // 实时更新披露要点
  set_disclosure_point(disclosureId, params, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.announcement.operate_disclosure_point, [['disclosureId', disclosureId]]), params, data, originUrl: api.announcement.operate_disclosure_point })
  },
  // 获取事项报批材料
  get_materials_submitted(disclosureId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.announcement.get_materials_submitted, [['disclosureId', disclosureId]]), originUrl: api.announcement.get_materials_submitted })
  },
  // 根据异步taskid获取文件oss地址
  get_announcement_file_by_task_id(params) {
    return Axios({ method: 'GET', url: api.announcement.get_announcement_file_by_task_id, params })
  },
  // 文档管理中心获取公告编制工具事项文件接口
  announcement_preparation_document_management_files() {
    return Axios({ method: 'GET', url: api.announcement.announcement_preparation_document_management_files })
  },
  // 保存文件
  document_management_files_upload(params) {
    return Axios({ method: 'POST', url: api.announcement.document_management_files_upload, params })
  }
}
