import Vue from 'vue'
import Store from '@/store'
import notify from './notify.vue'

const Component = Vue.extend(notify)

let instance = null

function vxanotify(options) {
  if (!instance) {
    instance = new Component({
      el: document.createElement('div'),
      data() {
        return options
      }
    })

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
      instance.isShowNotify = true
    })
    Store.commit('VXA_NOTIFY_EXIST', true)
  } else {
    if (instance.isShowNotify) {
      Object.assign(instance, options)
      Store.commit('VXA_NOTIFY_EXIST', true)
    }
  }
}
vxanotify.close = () => {
  if (instance) {
    instance.isShowNotify = false
    instance.remainingTime = 0
    instance.progressValue = 0
    // 此处需要销毁，否则在股行分模块页面比较长的情况，下拉到底部会显示出来
    if (instance?.$el?.parentNode) {
      instance.$el.parentNode.removeChild(instance.$el)
    }
    instance = null
    Store.commit('VXA_NOTIFY_EXIST', false)
  }
}

export default {
  install() {
    Vue.prototype.$vxaNotify = vxanotify
  }
}
