import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 重大事项知情人
  major_issues_insider_list(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_insider_list, data: params })
  },
  // 判断名称是否重复
  major_issues_exist_item_name(params) {
    return Axios({ method: 'GET', url: api.insider.major_issues_exist_item_name, params: params })
  },
  // 外部创建重大事项
  major_issues_item_name(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_item_name, data: params })
  },
  // 新增/编辑一个知情人
  major_issues_insider(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_insider, data: params })
  },
  // 新增/编辑一个知情人
  major_issues_insider_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_insider_detail, [['id', id]]), originUrl: api.insider.major_issues_insider_detail })
  },
  // 获取新增/编辑知情人亲属下拉选择数据
  major_issues_insider_short_info(params = {}) {
    return Axios({ method: 'GET', url: api.insider.major_issues_insider_short_info, params: params })
  },
  // 获取所有下拉条件
  major_issues_insider_pull_down_condition() {
    return Axios({ method: 'GET', url: api.insider.major_issues_insider_pull_down_condition })
  },
  // 删除知情人时判断相关引用
  major_issues_insider_quote(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_insider_quote, [['id', id]]), originUrl: api.insider.major_issues_insider_quote })
  },
  // 查询解析excel结果
  major_issues_insider_analyze_excel_result(params, fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_insider_analyze_excel_result, [['fileId', fileId]]), params: params, originUrl: api.insider.major_issues_insider_analyze_excel_result })
  },
  // 导入知情人excel
  major_issues_insider_importExcel(params, fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_insider_importExcel, [['fileId', fileId]]), params: params, originUrl: api.insider.major_issues_insider_importExcel })
  },
  // 重大事项 列表
  major_issues_item_list(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_item_list, data: params })
  },
  // 删除知情人
  major_issues_insider_delete(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.insider.major_issues_insider_delete, [['id', id]]), originUrl: api.insider.major_issues_insider_delete })
  },
  // 重大事项 保存(有id)/更新(无id)
  major_issues_item_save(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_item_save, data: params })
  },
  // 重大事项管理- 获取所有下拉条件
  major_issues_item_pull_down_condition() {
    return Axios({ method: 'GET', url: api.insider.major_issues_item_pull_down_condition })
  },
  // 重大事项管理- 详情
  major_issues_item_detail(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_detail, [['itemId', itemId]]), params: params, originUrl: api.insider.major_issues_item_detail })
  },
  // 重大事项管理- 删除
  major_issues_item_delete(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.insider.major_issues_item_delete, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_delete })
  },
  // 获取所有备忘录数据
  major_issues_item_memorandum_detail(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_memorandum_detail, [['itemId', itemId]]), params: params, originUrl: api.insider.major_issues_item_memorandum_detail })
  },
  // 保存/更新 备忘录数据
  major_issues_item_memorandum(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_item_memorandum, data: params })
  },
  // 生成备忘录签署文件
  major_issues_item_memorandum_generate_word(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_memorandum_generate_word, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_memorandum_generate_word })
  },
  // 生成承诺函签署文件支持在线编辑
  major_issues_item_commitment_letter_generate_word(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_commitment_letter_generate_word, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_commitment_letter_generate_word })
  },
  // 事项 知情人列表
  major_issues_issues_insider_list(itemId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.insider.major_issues_issues_insider_list, [['itemId', itemId]]), data: params, originUrl: api.insider.major_issues_issues_insider_list })
  },
  // 事项 知情人 去补充 保存数据
  major_issues_supplement_insider_save(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_supplement_insider_save, data: params })
  },
  // 自主登记 详情
  major_issues_self_registration(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_self_registration, [['itemId', itemId]]), originUrl: api.insider.major_issues_self_registration })
  },
  // 短信记录
  major_issues_sms_record(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_sms_record, data: params })
  },
  // 补充内幕知情人 下拉
  major_issues_supplement_insider_pull_down() {
    return Axios({ method: 'GET', url: api.insider.major_issues_supplement_insider_pull_down })
  },
  // 自主登记 初始化详情
  major_issues_self_registration_init(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_self_registration_init, data: params })
  },
  // 事项 去补充 保存初始化数据
  major_issues_supplement_insider_init(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_supplement_insider_init, data: params })
  },
  // 自主登记 保存（无id）/更新（有id）
  major_issues_self_registration_saveOrUpdate(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_self_registration_saveOrUpdate, data: params })
  },
  major_issues_get_signature_by_item(itemId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.insider.major_issues_get_signature_by_item, [['itemId', itemId]]), params, originUrl: api.insider.major_issues_get_signature_by_item })
  },
  major_issues_get_signature_by_item_info(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_get_signature_by_item_info, [['itemId', itemId]]), params, originUrl: api.insider.major_issues_get_signature_by_item_info })
  },
  major_issues_initiation_signature(data) {
    return Axios({ method: 'POST', url: api.insider.major_issues_initiation_signature, data })
  },
  // 事项 知情人删除
  major_issues_issues_insider_delete(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.insider.major_issues_issues_insider_delete, [['id', id]]), originUrl: api.insider.major_issues_issues_insider_delete })
  },
  // 事项类型列表
  major_issues_signature_list(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_signature_list, data: params })
  },
  // 事项 知情人 更新
  major_issues_issues_insider_update(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_issues_insider_update, data: params })
  },
  // 支持事项知情人进行数据在补充和重新发起签名
  major_issues_item_insider_unsigned(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_insider_unsigned, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_insider_unsigned })
  },
  // 支持事项知情人进行数据在补充和重新发起签名（归档时用）
  major_issues_item_unsigned(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_unsigned, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_unsigned })
  },
  // 归档
  major_issues_item_file(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_file, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_file })
  },
  // 重大事项签名-删除签名合同
  major_issues_signature_detail_delete(itemId, businessId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.insider.major_issues_signature_detail_delete, [['itemId', itemId], ['businessId', businessId]]), originUrl: api.insider.major_issues_signature_detail_delete })
  },
  // 重大事项签名-下载签名合同相关
  major_issues_signature_download(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_signature_download, data: params })
  },
  // 重大事项签名-签署情况查询-单个签署状况查询
  major_issues_signature_detail(itemId, businessId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_signature_detail, [['itemId', itemId], ['businessId', businessId]]), originUrl: api.insider.major_issues_signature_detail, params })
  },
  // 重大事项签名-设置为手动签署
  major_issues_signature_manual_sign(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_signature_manual_sign, data: params })
  },
  // 重大事项签名-手动发送提醒
  major_issues_signature_manual_notify(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_signature_manual_notify, data: params })
  },
  // 重大事项签名-删除签名合同
  major_issues_signature_detail_withdraw(itemId, businessId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.insider.major_issues_signature_detail_withdraw, [['itemId', itemId], ['businessId', businessId]]), originUrl: api.insider.major_issues_signature_detail_withdraw })
  },
  // 导出word登记表
  major_issues_item_exportWord(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_exportWord, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_exportWord })
  },
  // 导出excel登记表
  major_issues_item_exportExcel(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.insider.major_issues_item_exportExcel, [['itemId', itemId]]), originUrl: api.insider.major_issues_item_exportExcel })
  },
  // 自主登记发送记录
  major_issues_self_registration_send_record(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_self_registration_send_record, data: params })
  },
  // 查看重大事项权限配置
  major_issues_permission() {
    return Axios({ method: 'GET', url: api.insider.major_issues_permission })
  },
  // 修改重大事项权限配置
  update_major_issues_permission(params) {
    return Axios({ method: 'POST', url: api.insider.major_issues_permission, data: params })
  },
  // 查看当前用户重大事项权限配置
  major_issues_user_permission() {
    return Axios({ method: 'GET', url: api.insider.major_issues_user_permission })
  }
}
