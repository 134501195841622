import Api from '@/service/api'

export default {
  c1: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_c1.png`,
  c2: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_c2.png`,
  c3: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_c3.png`,
  c4: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_c4.png`,
  c5: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_c5.png`,
  danger: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_danger.png`,
  delete: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_delete.png`,
  edit: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_edit.png`,
  holder: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_holder.png`,
  investment: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_investment.png`,
  left: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_left.png`,
  member: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_member.png`,
  menubg: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_menubg.png`,
  right: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_right.png`,
  set: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_set.png`,
  show: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_show.png`,
  tips: `${Api.aliyunDomain}xiaoan/web_static/gxf/stockholder_tips.png`
}
