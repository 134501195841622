<!--
 * @Description: 空白页
-->
<template>
  <div
    class="vxa-no-info"
    :class="{ 'vxa-no-info_img': !onlyText, 'vxa-no-info_text': onlyText, 'vxa-no-info_absolute': absolute }"
  >
    <template v-if="onlyText">
      <span class="not_info_text"><slot>暂无内容</slot></span>
      <p><slot name="btn"></slot></p>
    </template>
    <template v-else-if="custom">
      <slot name="img"></slot>
      <slot name="btn"></slot>
    </template>
    <template v-else>
      <img class="defaultImg" :src="imgSrc" />
      <p v-if="altText" class="alt-text" v-html="altText"></p>
      <slot name="btn"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VxaNoInfo',
  props: {
    onlyText: {
      type: Boolean,
      default: false
    },
    // 用于法规、公告等详情页，覆盖底部内容
    absolute: {
      type: Boolean,
      default: false
    },
    altText: {
      type: String,
      default: '暂无内容'
    },
    imgSrc: {
      type: String,
      default: function() {
        return `${this.$api.aliyunDomain}enterpriseApply/6a82fe59d43d432c8adafc528f7ec186.png`
      }
    },
    custom: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.vxa-no-info {
  &_text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 12px;

    .not_info_text {
      color: #8d8d8d;
    }
  }
  &_img {
    width: 100%;
    height: 100%;
    min-height: 450px;
    color: #ffb148;
    text-align: center;
    padding-top: 150px;
    font-size: 14px;
    p {
      height: 20px;
      margin: 0;
      padding: 0;
      margin-top: 10px;
      opacity: 0.8;
    }
  }
  &_absolute {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    padding-top: 300px;
  }
}
</style>
