import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 获取表格菜单
  table_dicts(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.table_dicts, [['reportId', reportId]]), originUrl: api.reviewReport.table_dicts })
  },
  // 获取具体表的复核结果
  check_result_tables(reportId, tableId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.check_result_tables, [['reportId', reportId], ['tableId', tableId]]), originUrl: api.reviewReport.check_result_tables })
  },
  // 指定表格重新复核
  selected_tables_check(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.selected_tables_check, [['reportId', reportId]]), data: params, originUrl: api.reviewReport.selected_tables_check })
  },
  // 定期报告复核下拉框列表数据
  search_conditions() {
    return Axios({ method: 'GET', url: api.reviewReport.search_conditions })
  },
  // 定期报告列表页搜索
  search_regular_reports(params) {
    return Axios({ method: 'GET', url: api.reviewReport.regular_reports, params })
  },
  // 创建报告
  create_regular_reports(params) {
    return Axios({ method: 'POST', url: api.reviewReport.regular_reports, data: params })
  },
  // 编辑报告
  edit_regular_reports(reportId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.reviewReport.edit_regular_reports, [['reportId', reportId]]), originUrl: api.reviewReport.edit_regular_reports, data: params })
  },
  // 删除报告
  delete_regular_reports(reportId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.reviewReport.delete_regular_reports, [['reportId', reportId]]), originUrl: api.reviewReport.delete_regular_reports })
  },
  // 获取报告的协作人（包含创建者）
  get_collaborators(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.collaborator, [['reportId', reportId]]), originUrl: api.reviewReport.collaborators })
  },
  // 编辑报告的协作人
  set_collaborators(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.collaborator, [['reportId', reportId]]), data: params, originUrl: api.reviewReport.collaborators })
  },
  // 删除OSS上的定期报告
  delete_oss_file(fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.reviewReport.delete_oss_file, [['fileId', fileId]]), originUrl: api.reviewReport.delete_oss_file })
  },
  // 查询补录的财务指标数据
  get_additional_financial_data(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.get_additional_financial_data, [['reportId', reportId]]), originUrl: api.reviewReport.get_additional_financial_data })
  },
  // 保存补录的财务指标数据
  save_additional_financial_data(reportId, tableId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.save_additional_financial_data, [['reportId', reportId], ['tableId', tableId]]), data: params, originUrl: api.reviewReport.save_additional_financial_data })
  },
  analysis_roll_v2(params) {
    return Axios({ method: 'POST', url: api.reviewReport.analysis_roll_v2, data: params })
  },
  // 获取当前定期报告最新解析任务id
  get_latest_analysis_task_id(params) {
    return Axios({ method: 'GET', url: api.reviewReport.get_latest_analysis_task_id, params })
  },
  // 查询当前定期报告名册上传记录
  get_upload_records(params) {
    return Axios({ method: 'GET', url: api.reviewReport.get_upload_records, params })
  },
  // 复核前10名股东持股情况
  check_top_10_shareholders(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.check_top_10_shareholders, [['reportId', reportId]]), params, originUrl: api.reviewReport.check_top_10_shareholders })
  },
  // 复核单个表
  check_single_table(reportId, tableId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.check_single_table, [['reportId', reportId], ['tableId', tableId]]), originUrl: api.reviewReport.check_single_table })
  },
  // 保存详情页用户对该表自定义选项，以用于复核单表
  save_table_check_param(reportId, tableId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.save_table_check_param, [['reportId', reportId], ['tableId', tableId]]), data: params, originUrl: api.reviewReport.save_table_check_param })
  },
  // 查询用户的自定义复核参数
  get_table_check_param(reportId, tableId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.get_table_check_param, [['reportId', reportId], ['tableId', tableId]]), originUrl: api.reviewReport.get_table_check_param })
  },
  // 忽略/取消忽略单元格错误
  ignore_cell_error(reportId, tableId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.reviewReport.ignore_cell_error, [['reportId', reportId], ['tableId', tableId]]), params, originUrl: api.reviewReport.ignore_cell_error })
  },
  // 上传用户在富文本编辑器中的上传的表格
  post_additional_table(reportId, tableId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.post_additional_table, [['reportId', reportId], ['tableId', tableId]]), data: params, originUrl: api.reviewReport.post_additional_table })
  },
  // 查询解析excel失败原因
  analyze_excel_error_result(reportId, tableId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.analyze_excel_error_result, [['reportId', reportId], ['tableId', tableId]]), params, originUrl: api.reviewReport.analyze_excel_error_result })
  },
  // 获取用户在富文本编辑器中的上传的表格
  get_additional_table(reportId, tableId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.get_additional_table, [['reportId', reportId], ['tableId', tableId]]), originUrl: api.reviewReport.get_additional_table })
  },
  // 重置表格复核状态
  reset_table_check_result(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.reset_table_check_result, [['reportId', reportId]]), data: params, originUrl: api.reviewReport.reset_table_check_result })
  },
  // 定期报告名册类型校验
  regularReport_register_verify(params) {
    return Axios({ method: 'POST', url: api.reviewReport.regularReport_register_verify, data: params })
  },
  // 获取定期报告文件校验结果
  regularReport_register_verify_result(taskId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.regularReport_register_verify_result, [['taskId', taskId]]), params, originUrl: api.reviewReport.regularReport_register_verify_result })
  },
  // 保存在详情页用户对前10名股东复核时的自定义选项
  save_top_10_shareholders_param(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.save_top_10_shareholders_param, [['reportId', reportId]]), data: params, originUrl: api.reviewReport.save_top_10_shareholders_param })
  },
  // 保存主要财务数据用户录入数据(不复核)
  no_review_save_additional_financial_data(reportId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.reviewReport.no_review_save_additional_financial_data, [['reportId', reportId]]), data: params, originUrl: api.reviewReport.no_review_save_additional_financial_data })
  },
  // 获取报告中所有表格数据
  periodic_reports_check_result_tables(reportId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.reviewReport.periodic_reports_check_result_tables, [['reportId', reportId]]), originUrl: api.reviewReport.periodic_reports_check_result_tables })
  },
  // 删除定期报告所上传名册文件
  delete_regularReport_roll(reportId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.reviewReport.delete_regularReport_roll, [['reportId', reportId]]), originUrl: api.reviewReport.delete_regularReport_roll })
  },
  // 获取功能更新日志
  get_feature_release_note() {
    return Axios({ method: 'GET', url: api.reviewReport.get_feature_release_note })
  },
  // 编辑表格忽略错误备注
  edit_table_error_remark(reportId, tableId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.reviewReport.edit_table_error_remark, [['reportId', reportId], ['tableId', tableId]]), params, originUrl: api.reviewReport.edit_table_error_remark })
  },
  disclosure_date_search(data) {
    return Axios({ method: 'POST', url: api.reviewReport.disclosure_date_search, data })
  },
  periodic_reports_report_dates() {
    return Axios({ method: 'GET', url: api.reviewReport.periodic_reports_report_dates })
  },
  disclosure_date_excel(data) {
    return Axios({ method: 'POST', url: api.reviewReport.disclosure_date_excel, data })
  }

}
