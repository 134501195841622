import Cookies from 'js-cookie'
import HOSTS from '@/service/host'
const env = process.env.VUE_APP_XA_ENV
let cookieHost = HOSTS[env].cookieHost
// 如果是本地域名(yarn start模式)，如果还是使用HOSTS[env].cookieHost，会往.test(dev等).in-hope.com.cn里面写cookie
// 而我们需要的是往本地域名写cookie
if (process.env.NODE_ENV === 'development') {
  cookieHost = window.location.hostname
}

// 设置字段存储位置，可选项：cookie、localStorage、sessionStorage
const storeKeyMap = {
  'editGuideGialog': 'cookie', // 是否展示宣传弹窗
  'propagandDialog': 'cookie', // 是否展示宣传弹窗
  'JWT': 'cookie',
  'JWT_updateTime': 'cookie',
  'ESOP_COORDINATOR_JWT': 'cookie', // 股权激励用户token
  'ESOP_INCENTIVE_OBJECT_JWT': 'cookie', // 股权激励用户token
  'showInform': 'cookie',
  'userId': 'cookie',
  'userInfo': 'cookie',
  'selectInfo': 'cookie',
  'tipShowObj': 'cookie',
  'selectPageNum': 'cookie',
  'regulation_index': 'cookie',
  'companyType': 'cookie',
  'clientTimeDiff': 'cookie',
  'inquiryLetterSearchGuide': 'cookie', // 问询函件搜索引导
  'lawHistoryViewGuide': 'cookie', // 法规历史沿革搜索引导
  'lawHistoryEvolutionGuide': 'cookie', // 法规历史对比 页面引导
  'complianceAssistantDisclaimer': 'cookie', // AI合规助手免责声明
  'openCalendarEventRemindTip': 'cookie', // 系统创建定期报告窗口期事件提示用户前往日历事项开启提醒
  'infaithDirector': 'cookie', // 标志是否信公咨询点击独董
  'registerInfo': 'sessionStorage',
  'percentage': 'sessionStorage',
  'commented': 'sessionStorage',
  'regulator_company': 'sessionStorage',
  'color': 'localStorage',
  'calendarList': 'localStorage',
  'SELECT_COMPANY_LIST': 'localStorage',
  // 市值分析导出报告相关参数
  'CAPANALYSIS_OPTIONS': 'localStorage',
  'calendarSelectCompany': 'localStorage',
  'isFirstEnterDutyList': 'localStorage',
  'isAppAdvertisementClicked': 'localStorage', // 是否首次点击宣传弹窗
  'isClickSentimentTrialTip': 'localStorage', // 是否点击舆情顶部的提示关闭按钮
  'enterpriseUserCompanyInfo': 'localStorage', // 财务分析模块enterpriseUser选择的公司
  'readHistory': 'localStorage', // 已读记录
  'independentDirectorPerformanceManageGuide': 'localStorage', // 是否出现过独董履职管理的指引
  'announcementTextModeGuide': 'localStorage',
  'infaithDashboardClosedServiceHint': 'localStorage', // 信公咨询首页 服务变更提示 是否点击了关闭
  'equityChangeStockerTip': 'localStorage', // 权益变动-股东信息-是否点击关闭提示
  'financeCompanyGuide': 'localStorage', // 是否出现过财务分析-公司财务数据的指引
  'periodicReportChapterGuide': 'localStorage', // 是否出现"定期报告拆解"的指引
  'hideExpireDialogReviewReportType': 'localStorage', // 隐藏定期报告过期弹窗的类型
  'latestUpdateDailyCreateTime': 'localStorage', // 定期报告功能更新日志最新创建时间
  'majorIssuesManagementTip': 'localStorage', // 内幕信息知情人 - 重大事项管理，banner
  'stockholderExportBtnGuide': 'localStorage', // 股行分 - 导出按钮引导页
  'equityChangeServedCompany': 'localStorage', // 权益变动管理 - 股东信息管理 - 选中的服务公司
  'meetingSendMessageGuide': 'localStorage', // 三会管理 - 发送通知阶段
  'financeLocationInterIndustry': 'localStorage', // 财务分析 - 同业公司 财务指标展示位置
  'financeLocationComparable': 'localStorage', // 财务分析 - 可比公司 财务指标展示位置
  'financeLocationFollow': 'localStorage' // 财务分析 - 关注公司 财务指标展示位置
}

// 存储过期时间默认值，单位：天
const EXPIRES = 7

function _getKeyStore(key) {
  // 获取当前key配置存储位置，默认值：localStorage
  const storeKey = storeKeyMap[key] || 'localStorage'
  return _storage[storeKey]
}

// 判断一个字符串是否json字符串，若是，转成json后返回，否则返回原字符串
function parseJsonString(str) {
  if (typeof str != 'string') {
    return str
  }
  try {
    const obj = JSON.parse(str)
    if (obj) {
      return obj
    }
  } catch (error) {
    // console.log(error)
  }
  return str
}

class _cookie {
  set(key, value, expires) {
    Cookies.set(this.getKey(key), value, { expires: expires, domain: cookieHost })
  }

  get(key) {
    return Cookies.get(this.getKey(key)) || ''
  }

  remove(key) {
    Cookies.set(this.getKey(key), '', { expires: -1, domain: cookieHost })
  }

  clear() {
    for (const key in storeKeyMap) {
      storeKeyMap[key] === 'cookie' && this.remove(key)
    }
  }

  getKey(key) {
    return `${env}_${key}`
  }
}

class _localStorage {
  set(key, value, expires) {
    // 计算存储过期时间戳
    const expiresTime = new Date().getTime() + expires * 24 * 60 * 60 * 1000
    try {
      localStorage.setItem(key, JSON.stringify({
        data: value,
        expiresTime: expires > 0 ? expiresTime : null // expires为负值代表永不过期
      }))
    } catch (error) {
      console.log(error)
    }
  }

  get(key) {
    let keyVal = localStorage.getItem(key)
    if (!keyVal) {
      return ''
    }
    // 判断存储内容是否已过期
    try {
      keyVal = parseJsonString(keyVal)
      // expiresTime为空代表永不过期
      if (!keyVal.expiresTime || keyVal.expiresTime > new Date().getTime()) {
        return keyVal.data
      }
      return ''
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  remove(key) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  }

  clear() {
    try {
      localStorage.clear()
    } catch (error) {
      console.log(error)
    }
  }
}

class _sessionStorage {
  set(key, value) {
    try {
      sessionStorage.setItem(key, value)
    } catch (error) {
      console.log(error)
    }
  }

  get(key) {
    try {
      return sessionStorage.getItem(key)
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  remove(key) {
    try {
      sessionStorage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  }

  clear() {
    try {
      sessionStorage.clear()
    } catch (error) {
      console.log(error)
    }
  }
}

const _storage = {
  cookie: new _cookie(),
  localStorage: new _localStorage(),
  sessionStorage: new _sessionStorage()
}

export default {
  /**
   *
   * @param {*} key
   * @param {*} value
   * @param {*} expires 过期时间，单位：天，默认值：EXPIRES
   */
  set(key, value, expires = EXPIRES) {
    // key、value为空或者key非字符串直接return
    if (!key || typeof key !== 'string' || (!value && value !== 0 && value !== false && value !== null)) {
      return
    }
    if (typeof value === 'object') {
      try {
        value = JSON.stringify(value)
      } catch (error) {
        console.log(error)
      }
    }
    _getKeyStore(key).set(key, value, expires)
  },
  get(key) {
    if (!key || typeof key !== 'string') {
      return
    }
    const value = _getKeyStore(key).get(key)
    return parseJsonString(value)
  },
  remove(key) {
    if (!key || typeof key !== 'string') {
      return
    }
    _getKeyStore(key).remove(key)
  },
  clear() {
    for (const key in _storage) {
      _storage[key].clear()
    }
  },
  // 业务方法
  isLogin() {
    return !!this.get('JWT')
  },
  // userInfo各字段解释：
  // applyEnterprise: false   //是否已申请企业版
  // authorityRole: "ENTERPRISE" // 用户角色
  // fundamentalRole: "ENTERPRISE" // 用户基本角色 和authorityRole不同的是，用户在切换公司/内部/监管时 本字段不会改变
  // companyCode: "688001" // 公司股票代码
  // companyId: "144159290e5a4f1497299ed86ed17793" // 公司id
  // companyName: "浙江东方金融控股集团股份有限公司" // 个人信息公司全称
  // companyTypeOne: "上市公司"  // 个人信息公司类型
  // companyTypeTwo: "科创板" // 个人信息公司所属板块
  // email: "" // 用户邮箱
  // enterpriseCompanyCode: "300451" // 企业版公司股票代码
  // enterpriseCompanyFullCode: "SZ300451" // 企业版公司股票代码全称
  // enterpriseCompanyName: "创业慧康" // 企业版公司名称全称
  // equityincentCompanyId: "144159290e5a4f1497299ed86ed17793" // 股权激励公司id
  // equityincentStatus: 1 // 股权激励权限
  // xiaoAnUser: false   // 是否是小安用户，用于小安笔记(专业用户)
  // idCardNo: null // 用户证件号码
  // isRoot: false // 是否是当前企业版管理员
  // lastLoginDate: 1587367281000 // 最后登录时间
  // mobile: "13106269637" // 用户手机号
  // outerType: 0  // 是否企业版个人用户，1代表是
  // password: null    // 密码
  // position: null     // 职位
  // realName: "jerry" // 用户姓名
  // state: true  //  无用
  // status: null  // 无用
  // stockType: null   // 个人用户公司类型 0 场内沪深股票 1场外 新三板股票 2港股
  // userId: // 用户id，同id，请使用userId
  // userName: "inhope_elttx" // 用户昵称
  // weixinAvatar: null  // 个人头像
  // weixinUnionId: null   // 微信id
  // weixinUsername: null  // 微信名称
  // 获取用户信息
  getUserInfo() {
    return this.get('userInfo') || {}
  },
  // 获取信公咨询选择公司信息
  getSelectInfo() {
    return this.get('selectInfo') || {}
  },
  // 获取企业版用户设置的公司
  getEnterpriseUserCompanyInfo() {
    return this.get('enterpriseUserCompanyInfo')
  },
  // 获取已读记录
  getReadHistory() {
    return this.get('readHistory') || {}
  }
}
