import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  law_title_auto_complete(params) {
    return Axios({ method: 'GET', url: api.rule.law_title_auto_complete, params: params })
  },
  /* 获取筛选的类 */
  get_rule_search_conditions(params) {
    return Axios({ method: 'GET', url: api.rule.get_rule_search_conditions, params: params })
  },
  get_hot_words(params, type) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_hot_words, [['type', type]]), params: params, originUrl: api.rule.get_hot_words })
  },
  // 法规详情(新)
  get_rule_details(params, id, extraHeaders) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_rule_details, [['id', id]]), params: params, originUrl: api.rule.get_rule_details, extraHeaders })
  },
  get_law_chapters(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_law_chapters, [['id', id]]), originUrl: api.rule.get_law_chapters })
  },
  get_relation_case(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_relation_case, [['id', id]]), params: params, originUrl: api.rule.get_relation_case })
  },
  get_relation_laws(params, id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_relation_laws, [['id', id]]), params: params, originUrl: api.rule.get_relation_laws })
  },
  get_law_compare_law_list(params) {
    return Axios({ method: 'GET', url: api.rule.get_law_compare_law_list, params })
  },
  get_law_history(params) {
    return Axios({ method: 'GET', url: api.rule.get_law_history, params })
  },
  get_compare_lawList(params) {
    return Axios({ method: 'GET', url: api.rule.get_compare_lawList, params })
  },
  save_search_behavior(params) {
    return Axios({ method: 'POST', url: api.rule.save_search_behavior, data: params })
  },
  // 获取用户搜索类型
  search_type_setting(params) {
    return Axios({ method: 'GET', url: api.rule.search_type_setting, params: params })
  },
  // 设置用户搜索类型
  post_search_type_setting(params) {
    return Axios({ method: 'POST', url: api.rule.search_type_setting, data: params })
  },
  download_word_law(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.download_word_law, [['id', id]]), originUrl: api.rule.download_word_law })
  },
  download_pdf_law(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.download_pdf_law, [['id', id]]), originUrl: api.rule.download_pdf_law })
  },
  batch_download_laws(params, lawList) {
    return Axios({ method: 'POST', url: api.rule.batch_download_laws, originUrl: api.rule.batch_download_laws, params, data: lawList })
  },
  get_directory_condition(params) {
    return Axios({ method: 'GET', url: api.rule.get_directory_condition, params })
  },
  laws_compare(params) {
    return Axios({ method: 'POST', url: api.rule.laws_compare, data: params })
  },
  get_law_catalog(params) {
    return Axios({ method: 'GET', url: api.rule.get_law_catalog, params })
  },
  vague_rule_search(params) {
    return Axios({ method: 'POST', url: api.rule.vague_rule_search, data: params })
  },
  search_guide_record() {
    return Axios({ method: 'GET', url: api.rule.search_guide_record })
  },
  get_law_chapter_summary(lawId, chapterId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_law_chapter_summary, [['lawId', lawId], ['chapterId', chapterId]]), originUrl: api.rule.get_law_chapter_summary })
  },
  get_law_summary(lawId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_law_summary, [['lawId', lawId]]), originUrl: api.rule.get_law_chapter_summary })
  },
  get_law_summary_remain_count() {
    return Axios({ method: 'GET', url: api.rule.get_law_summary_remain_count })
  },
  get_summary_info(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_summary_info, [['id', id]]), originUrl: api.rule.get_summary_info })
  },
  get_rule_reference_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_rule_reference_detail, [['id', id]]), originUrl: api.rule.get_rule_reference_detail })
  },
  // 获取法规标签，不包含标签对应的法规总量
  get_laws_tags(params) {
    return Axios({ method: 'GET', url: api.rule.get_laws_tags, params })
  },
  // 获取法规标签包含对应的法规总量
  get_laws_tags_related_law_number(params) {
    return Axios({ method: 'GET', url: api.rule.get_laws_tags_related_law_number, params })
  },
  // 下载法规对比word
  download_laws_compare_word(data) {
    return Axios({ method: 'POST', url: api.rule.download_laws_compare_word, data })
  },
  // 校验当前法规链路是否已发生变化
  laws_compare_checkLawList(params) {
    return Axios({ method: 'GET', url: api.rule.laws_compare_checkLawList, params })
  },
  // 获取相关解读
  get_related_interpretations(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.rule.get_related_interpretations, [['id', id]]), originUrl: api.rule.get_related_interpretations })
  }
}
