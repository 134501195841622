import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 类型消息全部设为阅读
  set_all_message_read_status(params) {
    return Axios({ method: 'PUT', url: api.userMsg.set_all_message_read_status, params: params })
  },
  // 单条消息设置为已阅读
  set_single_message_read_status(messageId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.userMsg.set_single_message_read_status, [['messageId', messageId]]), originUrl: api.userMsg.set_single_message_read_status })
  }
}
