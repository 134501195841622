<!--
 * @Description: 申请权限弹窗
-->
<template>
  <div>
    <xa-dialog
      custom-class="vxa-authDialog"
      title="小安提示"
      :visible.sync="authDialog"
      @close="closeDialog"
      width="520px"
      center
      top="28vh"
      append-to-body
    >
      <div class="message">
        <p>您的账号暂未开通{{ moduleName && permissionDialog == 2 ? `【${moduleName}】模块` : '该功能' }}权限</p>
        <p>如需试用体验该功能，请联系小安管理员(工作时间9:00-18:00)</p>
        <br />
        <p style="color: #ffb148">联系电话: 021-20740322</p>
        <p style="color: #ffb148">客服微信: xiaoan201707</p>
      </div>
      <div slot="footer">
        <template>
          <xa-button type="primary" class="btn" @click="toEnterprise">去申请</xa-button>
        </template>
      </div>
    </xa-dialog>
  </div>
</template>

<script>
import Constant from '@/utils/constant.json'

export default {
  name: 'permissionDialog',
  data: function() {
    return {
      authDialog: false,
      timeout: null
    }
  },
  props: {
    permissionDialog: {
      type: Number,
      default: 0
    }
  },
  computed: {
    permissionDialogModule() {
      return this.$store.state.storeData.permissionDialogModule
    },
    moduleName() {
      return this.permissionDialogModule ? Constant.permissionModules[this.permissionDialogModule] : ''
    }
  },
  watch: {
    // 0：不展示无权限弹窗  1：普通无权限弹窗  2：明确无权限模块的弹窗
    permissionDialog: function(val) {
      this.authDialog = val !== 0
    }
  },
  methods: {
    toEnterprise() {
      this.authDialog = false
      this.$emit('permissionDialogChange', false)
      this.timeout = setTimeout(() => {
        this.$router.push({ path: '/purchase-application' })
      }, 600)
    },
    closeDialog() {
      this.authDialog = false
      this.$emit('permissionDialogChange', false)
    }
  },
  beforeCreate() {
    clearTimeout(this.timeout)
  }
}
</script>

<style lang="scss">
.vxa-authDialog {
  .el-dialog__body {
    padding: 16px 25px 10px;
  }
  .message {
    text-align: center;
    padding: 0 19px;
    p {
      line-height: 20px;
    }
  }
  .btn.xa-button {
    font-size: 16px;
    height: 35px;
    padding: 0;
    width: 150px;
  }
}
</style>
