<!--
 * @Description: 公司名称和代码，自动补全搜索框
-->
<template>
  <div class="autocomplete-wrapper">
    <xa-autocomplete
      type="text"
      class="companyAutocomplete"
      :class="{ 'show-company-group': isShowCompanyGroup }"
      :popper-class="popperClass"
      :placeholder="placeholder"
      placement="bottom-end"
      v-model="inputValue"
      value-key="code"
      :maxlength="100"
      :size="size"
      :fetch-suggestions="querySearchCodeAsync"
      :disabled="disabled || hasCheckedGroup"
      @select="handleSelect"
      @keyup.13.native="searchMethod"
    >
      <template slot-scope="{ item }">
        <span class="code" v-html="$common.highLight(item.code, inputValue, true, true)"></span>
        <span class="name" v-html="$common.highLight(item.name, inputValue, true)"></span>
      </template>
      <div slot="append" class="append-wrapper" v-if="isShowCompanyGroup">
        <xa-button class="clear-btn" text @click="clearGroup" v-if="hasCheckedGroup">清空</xa-button>
        <xa-button class="group-btn" type="primary" @click="openGroupDialog" size="medium">组合</xa-button>
      </div>
    </xa-autocomplete>
    <xa-dialog title="公司组合" :visible.sync="visible" append-to-body width="620px" class="company-group-set-dialog">
      <div class="groups-wrapper">
        <xa-checkbox-group v-model="checkedGroupList">
          <xa-checkbox :label="item" v-for="item in companyGroupOptions" :key="item"></xa-checkbox>
        </xa-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <xa-button @click="cancelSetGroup">取消</xa-button>
        <xa-button type="primary" @click="setGroup">确认</xa-button>
      </div>
    </xa-dialog>
  </div>
</template>

<script>
export default {
  name: 'VxaCompanyAutocomplete',
  data: function() {
    return {
      companyGroupOptions: [
        'A+H股',
        'A+B股',
        '沪股通',
        '深股通'
      ],
      checkedGroupList: [],
      visible: false
    }
  },
  props: {
    value: [String, Number],
    size: {
      type: String,
      default: 'large'
    },
    // 默认选中后值为公司code，为false返回code + name，例：000001平安银行
    valueOnlyCode: {
      type: Boolean,
      default: true
    },
    // 区分不同板块的公司code自动补全接口调用
    plateType: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '证券代码/简称'
    },
    // INHOPE-29846 允许搜索沪深北的退市公司和拟上市公司
    supportAbnormalCompany: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: 'company-autocomplete-popper'
    },
    isShowCompanyGroup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasCheckedGroup() {
      if (!this.inputValue) {
        return false
      }
      const splitInputValue = this.inputValue.split('；')
      if (splitInputValue.some(item => this.companyGroupOptions.includes(item))) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleSelect(evt) {
      if (!this.valueOnlyCode) {
        this.inputValue = `${evt.code}${evt.name}`
      }
      this.$emit('select', evt)
    },
    querySearchCodeAsync(queryString, cb) {
      let queryPromise
      if (this.plateType === 'NEEQ') {
        queryPromise = this.$axios.cases.neeq_companies_auto_complete({ key: queryString || '' })
      } else {
        if (this.supportAbnormalCompany) {
          queryPromise = this.$axios.affiche.companies_auto_complete({ keyword: queryString || '' })
            .then(res => {
              const originResult = res.data?.returnObject
              if (originResult && Array.isArray(originResult)) {
                res.data.returnObject = originResult.map(item => ({
                  code: item.companyCode,
                  name: item.companyName,
                  fullCode: item.companyFullCode,
                  fullName: item.companyFullName
                }))
              }
              return res
            })
        } else {
          queryPromise = this.$axios.affiche.get_related_company({ key: queryString || '' })
        }
      }
      queryPromise
        .then(res => {
          cb(res.data.returnObject)
        })
        .catch(() => {
          cb([])
        })
    },
    searchMethod() {
      this.$emit('enterClick')
    },
    clearGroup() {
      this.inputValue = ''
      this.checkedGroupList = []
      this.searchMethod()
    },
    openGroupDialog() {
      this.checkedGroupList = []
      let splitInputValue = []
      if (this.inputValue) {
        splitInputValue = this.inputValue.split('；')
      }
      splitInputValue.forEach(item => {
        if (this.companyGroupOptions.includes(item)) {
          this.checkedGroupList.push(item)
        }
      })
      this.visible = true
    },
    cancelSetGroup() {
      this.visible = false
    },
    setGroup() {
      this.visible = false
      const _this = this
      function compareFn(a, b) {
        const aIndex = _this.companyGroupOptions.findIndex(item => item === a)
        const bIndex = _this.companyGroupOptions.findIndex(item => item === b)
        return aIndex - bIndex
      }
      if (this.checkedGroupList.length > 0) {
        this.checkedGroupList.sort(compareFn)
        this.inputValue = this.checkedGroupList.join('；')
      } else {
        this.inputValue = ''
      }
      this.searchMethod()
    }
  }
}
</script>

<style lang="scss">
.company-group-set-dialog {
  .el-dialog__body {
    .groups-wrapper {
      .el-checkbox-group {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.autocomplete-wrapper {
  width: 100%;
  .xa-autocomplete {
    &.show-company-group {
      /deep/ .el-input {
        .el-input__inner {
          border-right: none !important;
        }
      }
    }
    /deep/ .el-input {
      .el-input-group__append {
        width: 50px;
        padding: 0;
        flex-shrink: 0;
        border: none;
        .append-wrapper {
          position: relative;
          width: 100%;
          .group-btn {
            font-size: 12px;
            width: 100%;
            min-width: 0px;
            padding: 0;
            border-radius: 0 4px 4px 0;
          }
          .clear-btn {
            position: absolute;
            left: -32px;
            top: 0px;
            z-index: 1;
            color: #ffb148;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
