<!--
 * @Description: 页面弹出提示框（导出或下载提示框）
 * @Author: lei.wu
-->

<template>
  <div class="vxa-notify" :class="{ visiable: isShowNotify }">
    <div class="notify-icon">
      <i class="el-icon-loading"></i>
    </div>
    <div class="notification__closeBtn el-icon-close" @click="isShowNotify = false"></div>
    <div class="notify-content">
      <p class="notify-text">
        文件生成中<span v-show="remainingTime > 0">，剩余{{ remainingTime }}秒</span>
      </p>
      <xa-progress :percentage="progressValue" :animation="false" :showWord="false" :height="10"></xa-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxaNotify',
  data: function() {
    return {
      isShowNotify: false,
      remainingTime: 0,
      progressValue: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.vxa-notify {
  min-height: 52px;
  position: fixed;
  z-index: -1;
  top: 80px;
  right: 0;
  display: flex;
  width: 330px;
  padding: 14px 26px 14px 40px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%);
  overflow: hidden;
  transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s, bottom 0.3s;
  &.visiable {
    z-index: 9999;
    right: 16px;
  }
  .notify-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #ffb148;
  }
  .notification__closeBtn {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
    color: #909399;
    font-size: 16px;
    &:hover {
      color: #ffb148;
    }
  }
  .notify-content {
    width: 100%;
    .notify-text {
      font-size: 14px;
      line-height: 21px;
      color: #606266;
      text-align: justify;
      margin-bottom: 10px;
    }
  }
}
</style>
