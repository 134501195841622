export const capAnalysis = {
  state: {
    capAnalysisTimeRange: []
  },
  getters: {
    CAP_ANALYSIS_TIME_RANGE: state => state.capAnalysisTimeRange
  },
  mutations: {
    CAP_ANALYSIS_SET_TIME_RANGE(state, val) {
      state.capAnalysisTimeRange = val
    }
  }
}

export default capAnalysis
