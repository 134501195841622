import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 创建事项 - 事项名称前置校验
  check_signature_name(params) {
    return Axios({ method: 'GET', url: api.signature.check_signature_name, params: params })
  },
  // 创建新的电子签事项
  create_signature(params) {
    return Axios({ method: 'POST', url: api.signature.create_signature, params: params })
  },
  // 获取电子签事项
  get_signature_by_itemId(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.signature_item, [['itemId', itemId]]), originUrl: api.signature.signature_item })
  },
  // 保存电子签事项
  save_signature_item(params) {
    return Axios({ method: 'POST', url: api.signature.save_signature_item, data: params })
  },
  // 保存电子签事项的签名人员
  save_sign_users(params, itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.save_sign_users, [['itemId', itemId]]), data: params, originUrl: api.signature.save_sign_users })
  },
  // 删除签名文件
  delete_signature_item_file(fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.signature_item_file, [['fileId', fileId]]), originUrl: api.signature.signature_item_file })
  },
  // 发起电子签事项
  send_signature(params) {
    return Axios({ method: 'POST', url: api.signature.send_signature, data: params })
  },
  // 保存文件库中选择的文件列表
  save_file_library_select(params) {
    return Axios({ method: 'POST', url: api.signature.save_file_library_select, data: params })
  },
  // 单独获取文件列表
  get_sign_files_by_itemId(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_sign_files_by_itemId, [['itemId', itemId]]), params: params, originUrl: api.signature.get_sign_files_by_itemId })
  },
  // 获取事项列表
  get_signature_items(params) {
    return Axios({ method: 'GET', url: api.signature.get_signature_items, params: params })
  },
  // 删除签名事项
  delete_signature_item(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.signature_item, [['itemId', itemId]]), originUrl: api.signature.signature_item })
  },
  // 获取签名文件的签名位置等详情
  get_signature_item_file(fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.signature_item_file, [['fileId', fileId]]), originUrl: api.signature.signature_item_file })
  },
  // 根据文件id获取文件相关合同签署情况
  signature_status(fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.signature_status, [['fileId', fileId]]), originUrl: api.signature.signature_status })
  },
  // 根据transactionId获取文件相关合同用印情况
  seal_status(transactionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.seal_status, [['transactionId', transactionId]]), originUrl: api.signature.seal_status })
  },
  // 根据私有url获取预览url
  file_preview_url(data) {
    return Axios({ method: 'POST', url: api.signature.file_preview_url, data })
  },
  // 校验当前profile下是否存在已经实名认证的企业
  check_authenticated_seal_org() {
    return Axios({ method: 'GET', url: api.signature.check_authenticated_seal_org })
  },
  // 校验用户是否是管理员
  check_admin() {
    return Axios({ method: 'GET', url: api.signature.check_admin })
  },
  // 保存签名文件的签名位置等详情
  save_sign_position(params, fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.save_sign_position, [['fileId', fileId]]), data: params, originUrl: api.signature.save_sign_position })
  },
  // 获取签名事项详情
  get_sign_item_detail(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_sign_item_detail, [['itemId', itemId]]), params: params, originUrl: api.signature.get_sign_item_detail })
  },
  // 事项签署情况 - 非定向签署
  get_undirected_sign_item_detail(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_undirected_sign_item_detail, [['itemId', itemId]]), originUrl: api.signature.get_undirected_sign_item_detail })
  },
  // 非定向签署 - 标记为人工签署
  undirected_terminate(fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.undirected_terminate, [['fileId', fileId]]), originUrl: api.signature.undirected_terminate })
  },
  // 终止签署
  terminate_signing(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.terminate_signing, [['itemId', itemId]]), originUrl: api.signature.terminate_signing })
  },
  // 打包签名文件
  genarate_pack_file(params, itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.genarate_pack_file, [['itemId', itemId]]), params: params, originUrl: api.signature.genarate_pack_file })
  },
  // 判断全部存证函文件是否生成完毕
  pre_check(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.pre_check, [['itemId', itemId]]), originUrl: api.signature.pre_check })
  },
  // 导出全部存证函
  deposit_confirmation_collection(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.deposit_confirmation_collection, [['itemId', itemId]]), originUrl: api.signature.deposit_confirmation_collection })
  },
  // 下载全部存证函-新
  get_deposit_confirmation_collection_v2(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.deposit_confirmation_collection_v2, [['itemId', itemId]]), originUrl: api.signature.deposit_confirmation_collection_v2 })
  },
  // 根据ossKey获取临时地址
  preview_url(params) {
    return Axios({ method: 'GET', url: api.signature.preview_url, params })
  },
  // 删除事项发起后的签名文件
  delete_sign_file_after_sponsor(fileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.delete_sign_file_after_sponsor, [['fileId', fileId]]), originUrl: api.signature.delete_sign_file_after_sponsor })
  },
  // 发起事项前的校验
  item_validation(params, itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.item_validation, [['itemId', itemId]]), params: params, originUrl: api.signature.item_validation })
  },
  // 发送文件签署提醒
  file_sign_reminder(params, fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.file_sign_reminder, [['fileId', fileId]]), data: params, originUrl: api.signature.file_sign_reminder })
  },
  // 删除事项提醒信息
  delete_issue_remind_info(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.delete_issue_remind_info, [['itemId', itemId]]), originUrl: api.signature.delete_issue_remind_info })
  },
  // 设置手工签名人员名单
  set_manual_sign_users(data, fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.set_manual_sign_users, [['fileId', fileId]]), originUrl: api.signature.set_manual_sign_users, data })
  },
  // 批量发送文件签署提醒
  batch_notification(data, itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.batch_notification, [['itemId', itemId]]), data, originUrl: api.signature.batch_notification })
  },
  // 获取公司签署信息
  get_sign_info() {
    return Axios({ method: 'GET', url: api.signature.get_sign_info })
  },
  // 更改文件是否需要签署状态接口
  change_need_sign_status(data) {
    return Axios({ method: 'PUT', url: api.signature.change_need_sign_status, data })
  },
  // 更新拖拽文件顺序
  fileOrder(itemId, data) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.signature.fileOrder, [['itemId', itemId]]), data, originUrl: api.signature.fileOrder })
  },
  // 草稿事项操作权限校验
  check_user_is_admin_or_initiator(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.check_user_is_admin_or_initiator, [['itemId', itemId]]), originUrl: api.signature.check_user_is_admin_or_initiator })
  },
  // 获取事项未签署完成用户
  get_unSign_users(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_unSign_users, [['itemId', itemId]]), originUrl: api.signature.get_unSign_users })
  },
  // 一键发送签署提醒前置校验
  send_reminder_pre_check(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.send_reminder_pre_check, [['itemId', itemId]]), originUrl: api.signature.send_reminder_pre_check })
  },
  // 根据批量上传批次id获取对应任务id
  batch_file_upload_task(params) {
    return Axios({ method: 'GET', url: api.signature.batch_file_upload_task, params })
  },
  // 查询事项文件夹列表
  get_folders(params) {
    return Axios({ method: 'GET', url: api.signature.get_folders, params })
  },
  // 保存事项文件夹
  save_folder(data) {
    return Axios({ method: 'POST', url: api.signature.save_folder, data })
  },
  // 删除事项文件夹
  delete_folders(folderId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.delete_folders, [['folderId', folderId]]), originUrl: api.signature.delete_folders })
  },
  // 移动事项至文件夹
  move_signature_to_folder(data) {
    return Axios({ method: 'POST', url: api.signature.move_signature_to_folder, data })
  },
  // 获取是否需要导出存证函
  deposit_confirmation_export(itemId, fileId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.deposit_confirmation_export, [['itemId', itemId], ['fileId', fileId]]), originUrl: api.signature.deposit_confirmation_export })
  },
  // 电子签名存正下载
  undirected_sign_deposit_collection(itemId, fileId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.undirected_sign_deposit_collection, [['itemId', itemId], ['fileId', fileId]]), originUrl: api.signature.undirected_sign_deposit_collection })
  },
  // 根据contractId获取合同下载地址 - 目前仅支持汇总签
  download_summary_signature(contractId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.download_summary_signature, [['contractId', contractId]]), originUrl: api.signature.download_summary_signature })
  },
  // 保存上传文件
  electronic_signature_upload_files(data) {
    return Axios({ method: 'POST', url: api.signature.electronic_signature_upload_files, data })
  },

  // 企业用印管理
  // 获取开户行列表
  bank_list() {
    return Axios({ method: 'GET', url: api.signature.bank_list })
  },
  // 添加企业认证
  organization_authentication(data) {
    return Axios({ method: 'POST', url: api.signature.organization_authentication, data })
  },
  // 根据公司代码获取统一社会信用代码
  credit_code(params) {
    return Axios({ method: 'GET', url: api.signature.credit_code, params })
  },
  // 进行企业信息验证
  verify_organization_information(data) {
    return Axios({ method: 'POST', url: api.signature.verify_organization_information, data })
  },
  // 发起打款
  initiate_payment(data) {
    return Axios({ method: 'POST', url: api.signature.initiate_payment, data })
  },
  // 验证打款金额
  verify_payment_amount(data) {
    return Axios({ method: 'POST', url: api.signature.verify_payment_amount, data })
  },
  // 查询企业实名认证状态信息
  organization_authentication_status(params) {
    return Axios({ method: 'GET', url: api.signature.organization_authentication_status, params })
  },
  // 验签
  sign_verify(data) {
    return Axios({ method: 'POST', url: api.signature.sign_verify, data })
  },

  // 获取用印企业列表
  seal_organization() {
    return Axios({ method: 'GET', url: api.signature.seal_organization })
  },
  // 获取企业用印记录
  seal_record(sealOrganizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.seal_record, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.seal_record, params })
  },
  // 删除用印企业
  delete_seal_organizations(sealOrganizationId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.delete_seal_organizations, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.delete_seal_organizations })
  },
  // 企业认证成功后重新认证
  organization_re_authentication(sealOrganizationId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.organization_re_authentication, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.organization_re_authentication })
  },
  // 继续认证
  continueCertification(sealOrganizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.continueCertification, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.continueCertification })
  },
  // 获取合同信息
  contractInfo(params) {
    return Axios({ method: 'GET', url: api.signature.contractInfo, params })
  },
  get_seal_pic_url(data) {
    return Axios({ method: 'POST', url: api.signature.get_seal_pic_url, data })
  },

  // 印章管理
  get_seals(sealOrganizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_seals, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.get_seals, params })
  },
  // 获取企业实名认证重定向地址
  redirect_url(params) {
    return Axios({ method: 'GET', url: api.signature.redirect_url, params })
  },
  // 保存印章信息
  set_seal(data) {
    return Axios({ method: 'POST', url: api.signature.set_seal, data })
  },
  // 获取企业印章管理员
  get_seal_manager(sealOrganizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_seal_manager, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.get_seal_manager })
  },
  //
  set_seal_manager(sealOrganizationId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.set_seal_manager, [['sealOrganizationId', sealOrganizationId]]), originUrl: api.signature.set_seal_manager, data })
  },
  // 校验印章是否在途
  check_deal(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.check_deal, [['id', id]]), originUrl: api.signature.check_deal })
  },
  // 删除印章
  delete_seal(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.delete_seal, [['id', id]]), originUrl: api.signature.delete_seal })
  },
  // 获取印章信息
  get_seal_info(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_seal_info, [['id', id]]), originUrl: api.signature.get_seal_info })
  },
  // 获取企业下所有印章
  get_seal_organizations() {
    return Axios({ method: 'GET', url: api.signature.get_seal_organizations })
  },
  // 获取印章授权人列表
  get_seal_authorized_users(sealId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.get_seal_authorized_users, [['sealId', sealId]]), originUrl: api.signature.get_seal_authorized_users })
  },
  // 获取事项用印文件列表
  seal_file(itemId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.seal_file, [['itemId', itemId]]), originUrl: api.signature.seal_file, params })
  },
  // 校验是否有法大大遗留事项
  fdd_item_remain_check(params) {
    return Axios({ method: 'GET', url: api.signature.fdd_item_remain_check, params })
  },
  // 查询签署相关状态
  paperless_sign_status(params) {
    return Axios({ method: 'GET', url: api.signature.paperless_sign_status, params })
  },
  // 获取事项短信记录
  signature_sms_record(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.signature_sms_record, [['itemId', itemId]]), originUrl: api.signature.signature_sms_record })
  },
  // 重新发送短信
  re_send_sms(smsId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.re_send_sms, [['smsId', smsId]]), originUrl: api.signature.re_send_sms })
  },
  // 校验事项发起状态
  check_initiation_status(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.check_initiation_status, [['itemId', itemId]]), originUrl: api.signature.check_initiation_status })
  },
  // 仅用印勾选结果保存
  save_seal_only_result(itemId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.save_seal_only_result, [['itemId', itemId]]), data, originUrl: api.signature.save_seal_only_result })
  },
  // 仅用印校验
  seal_only_pre_check(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.seal_only_pre_check, [['itemId', itemId]]), originUrl: api.signature.seal_only_pre_check })
  },
  // 事项下文件变更为仅用印
  change_file_to_seal_only(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.change_file_to_seal_only, [['itemId', itemId]]), originUrl: api.signature.change_file_to_seal_only })
  },
  // 轮询查询文件上传状态接口
  file_upload_result(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.signature.file_upload_result, [['itemId', itemId]]), originUrl: api.signature.file_upload_result })
  },
  // 删除事项下上传失败的文件
  batch_del_upload_failed_file(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.batch_del_upload_failed_file, [['itemId', itemId]]), originUrl: api.signature.batch_del_upload_failed_file })
  },
  // 重新发起事项
  re_initiate_signature(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.re_initiate_signature, [['itemId', itemId]]), originUrl: api.signature.re_initiate_signature })
  },
  // 重新发起事项下部分文件
  re_initiate_matter(itemId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.re_initiate_matter, [['itemId', itemId]]), data, originUrl: api.signature.re_initiate_matter })
  },
  // 已发起事项 - 批量文件删除
  re_initiate_del_matter(itemId, data) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.signature.re_initiate_del_matter, [['itemId', itemId]]), data, originUrl: api.signature.re_initiate_del_matter })
  },
  // 归档事项
  signature_filing(itemId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.signature_filing, [['itemId', itemId]]), originUrl: api.signature.signature_filing })
  },
  // 文档管理中心获取电子签名文件接口
  electronic_signature_document_management_files() {
    return Axios({ method: 'GET', url: api.signature.electronic_signature_document_management_files })
  },
  // 敏感词列表
  get_sensitive_words() {
    return Axios({ method: 'GET', url: api.signature.get_sensitive_words })
  },
  // 自定义内容重发
  set_re_send_custom_sms(data) {
    return Axios({ method: 'POST', url: api.signature.set_re_send_custom_sms, data })
  },

  // 获取可导入文件列表
  electronic_signature_get_importable_files(params) {
    return Axios({ method: 'GET', url: api.signature.electronic_signature_get_importable_files, params })
  },
  // 导入校验接口
  electronic_signature_check_import_file(params) {
    return Axios({ method: 'GET', url: api.signature.electronic_signature_check_import_file, params })
  },
  // 导入签名人员接口
  electronic_signature_import_sign_user(itemId, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.signature.electronic_signature_import_sign_user, [['itemId', itemId]]), data, originUrl: api.signature.electronic_signature_import_sign_user })
  }
}
