import Vue from 'vue'
import Axios from 'axios'
import router from '@/router'
import Storage from '@/service/storage'
import * as Sentry from '@sentry/vue'
import qs from 'qs'
import Api from '@/service/api'
import parser from 'ua-parser-js'

Axios.defaults.timeout = 600000
Axios.defaults.withCredentials = false
Axios.interceptors.request.use(async config => {
  const parserUA = parser(navigator.userAgent)
  const originUrl = config.originUrl || config.url
  config.headers['origin-type'] = 'WEB'
  config.headers['App-Name'] = parserUA?.browser?.name === 'WeChat' ? 'embedWebAppBrowser' : 'WEB'

  config.headers['Set-Cookie'] = null
  config.headers['Origin-Url'] = '/' + originUrl.replace(Api.api, '')
  config.headers['Browser-Name'] = parserUA?.browser?.name || 'Others'
  config.headers['Os-Name'] = parserUA?.os?.name || 'Others'
  if (config.extraHeaders) {
    Object.keys(config.extraHeaders).forEach(key => {
      config.headers[key] = config.extraHeaders[key]
    })
  }

  const JWT = Storage.get('JWT')
  if (!JWT && (config.url.indexOf('authentication/refresh_token') > -1)) {
    console.log(document.cookie)
    console.log(`JWT值为：${JWT} | 是否是undefined： ${JWT === undefined}`)
    Sentry.captureException(new Error('问题排查 | refresh_token jwt 为空'))
    if (config.url.indexOf('authentication/refresh_token') > -1) {
      return Promise.reject(new Error('JWT为空')) // 取消该请求
    }
  }
  config.headers.Authorization = config.specifiedToken || JWT
  config.headers['User-Id'] = Storage.getUserInfo().userId

  const t = Vue.prototype.$common.getSignTime()
  const sign = Vue.prototype.$common.getSign(t)
  if (config.method.toLocaleLowerCase() == 'get' || config.method.toLocaleLowerCase() == 'GET') {
    if (!config.params) {
      config.params = {}
    }
    config.paramsSerializer = (p) => { // 固定写法
      return qs.stringify(p, { indices: false })
    }
  }
  config.params = Object.assign({}, config.params, {
    sign,
    t
  })
  return config
}, error => {
  return Promise.reject(error)
})
// 响应拦截器
Axios.interceptors.response.use(response => {
  const retrunCode = response.data.returnCode

  if (retrunCode == 100 || (retrunCode === -1 && response.data.returnMsg === '用户未登录')) {
    if (!response.config.autoLogin) {
      Vue.prototype.$auth.clearAuth(`接口返回用户未登录, ${response.config.url}`)
      router.push('/login?redirect_url=' + location.href)
    }
    return response
  } else if (retrunCode == 500) {
    // 服务端返回业务code为500，也上报钉钉、sentry
    errorCollection({
      isServerError500: true, // 标识是否是服务端code 500的异常
      config: response.config,
      response
    })
    return response
  } else {
    return response
  }
}, error => {
  console.error(error)
  if (error.response?.data.returnCode === 'A000109') {
    Vue.prototype.$auth.clearAuth(`接口返回用户未登录, ${error.response.config.url}`)
    router.push('/login?redirect_url=' + location.href)
  }
  if (error.message != 'JWT为空') {
    if (!ifShieldError(error)) {
      errorCollection(error)
    }
  }
  return Promise.reject(error)
})
function ifShieldError(error) { // 是否过滤掉错误
  // Network Error是狂刷新时，请求被cancel引起的，不需要将错误发钉钉
  if (error.message == 'Network Error' || !error.response) {
    return true
  }
  // status=40时 是百度爬虫引起的 不需要将错误发钉钉
  if (error.response.status == 40) {
    return true
  }
  // status == 500时并且returnCode有值说明是正常业务逻辑，不需要抛错
  if (error.response.status == 500 && error.response.data.returnCode) {
    // A0402 token解密失败 | A0403 token已过期
    if (error.response.data.returnCode == 'A000402' || error.response.data.returnCode == 'A000403') {
      Vue.prototype.$auth.clearAuth(`token过期或解密失败, ${error.config.url}`)
      router.push({ path: '/login' })
    }
    // A000410 多浏览器登录被踢出
    if (error.response.data.returnCode == 'A000410') {
      Vue.prototype.$xaConfirm({
        title: '小安提示',
        message: error.response.data.returnMsg,
        showClose: false,
        showCancelButton: false
      }).then(() => {
        Vue.prototype.$auth.clearAuth(`多浏览器登录被踢出, ${error.config.url}`)
        router.push({ path: '/login' })
      })
    }
    // A0404 接口无权限，提示用户
    if (error.response.data.returnCode == 'A000404') {
      if (Storage.getUserInfo().userId === '1744033081d911e8960352549577cc48') {
        Sentry.captureException(new Error(`问题排查 | 接口无权限${error.config.url}`))
      }
      Vue.prototype.$xaConfirm({
        title: '小安提示',
        message:
          '您的账号没有该操作权限，您可以选择如下操作',
        cancelButtonText: '去首页',
        confirmButtonText: '留在此页面',
        showClose: false
      }).then(() => {
        location.reload()
      }).catch(() => {
        location.href = '/'
      })
    }
    // A0903 签名校验失败，用户客户端时间不正常
    if (error.response.data.returnCode == 'A000903') {
      const serverTime = error.response.data.returnObject.serverTime
      const clientTime = new Date().getTime()
      if (Math.abs(serverTime - clientTime) < 60000) {
        Storage.remove('clientTimeDiff')
      } else {
        Storage.set('clientTimeDiff', serverTime - clientTime)
      }
      location.reload()
    }
    return true
  }
  return false
}
function errorCollection(error) {
  // 本地调试，或者开发环境 不抛出这类警告
  if (process.env.NODE_ENV === 'development' || ['development', 'development2'].includes(process.env.VUE_APP_XA_ENV)) {
    return
  }
  if (error.config.url != Api.errorCollection.error_collection_to_ding) { // 非errorCollectionToDing请求出错
    const param = {
      application: 'xiaoan-enterprise',
      content: 'Error: 未知错误'
    }
    let errMsg = 'API异常'
    if (error.response) {
      errMsg = errMsg + ' status:' + error.response.status
    }
    // 服务端catch异常后，httpcode 200，returnCode为500
    if (error.isServerError500) {
      errMsg = 'API异常 returnCode: 500'
    }
    if (error.config.params && error.config.params.t) {
      const currentTime = new Date().getTime()
      var intervalTime = currentTime - error.config.params.t
    }
    param.content = `Error: ${errMsg}
API:${error.config.url}
URL:${location.href}
method:${error.config.method}
data:${JSON.stringify(error.config.data)}
params:${JSON.stringify(error.config.params)}
intervalTime:${intervalTime}
msg:${(error.response && error.response.data && JSON.stringify(error.response.data)) || error.message}
userAgent:${navigator.userAgent}
selectInfo:${JSON.stringify(Storage.getSelectInfo())}
userId:${Storage.getUserInfo().userId || ''}
JWT:${Storage.get('JWT') || ''}
`
    // 过滤50X的服务端接口报错，减少钉钉告警群消息数
    // 接口异常服务端应有异常日志，无需重复记录
    if (error.response.status && error.response.status.toString().substring(0, 2) !== '50') {
      Vue.prototype.$axios.errorCollection.error_collection_to_ding(param)
    }

    // 接口出现异常上报sentry
    Sentry.withScope((scope) => {
      scope.setContext('requestInfo', {
        method: error.config.method,
        msg: (error.response && error.response.data && JSON.stringify(error.response.data)) || error.message,
        data: JSON.parse(error.config.data || '{}'),
        params: error.config.params,
        selectInfo: Storage.getSelectInfo()
      })
      scope.setFingerprint([error.config.url, error.response.status]) // 手动设置指纹规则，避免不同api异常上报因stacktrace相同而被归为同一issue
      const errorStatus = `${error.response ? error.response.status : ''}`
      Sentry.captureException(new Error(`请求异常${error.isServerError500 ? 'returnCode: 500' : errorStatus} API: ${error.config.url}`))
    })
  }
}
export default Axios
