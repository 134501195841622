<!--
 * @Description: 选择员工成员的对话框
-->
<template>
  <div id="vxa-staffSelectInput-dialog" class="vxa-staffSelectInput-dialog" v-show="showDialog">
    <xa-dialog
      custom-class="staffSelectInput-dialog"
      :title="title || '选择人员'"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      :before-close="cancleDialog"
      destroy-on-close
      top="8vh"
      :appendToBody="needAppendToBody"
      :width="requestUserList ? '1040px' : '720px'"
    >
      <div class="content-box">
        <vxa-tab v-if="showMeetingContact" class="tab-box" :tabList="menuList" v-model="activeTab" @click="handleMenu">
        </vxa-tab>
        <div v-if="activeTab == 'systemContact'" :style="{ 'padding-top': showMeetingContact ? '50px' : '0' }">
          <el-row :gutter="16" v-loading="pageLoading">
            <el-col :span="requestUserList ? 7 : 0" class="organizationTree">
              <div class="organizationTitle">机构</div>
              <div class="tree-wrapper">
                <organization-tree
                  :organizationList="organizationList"
                  @selectChange="selectChange"
                ></organization-tree>
              </div>
            </el-col>
            <el-col :span="requestUserList ? 17 : 24">
              <div class="system-filter-box">
                <div class="filter-box">
                  <xa-input
                    style="width: 284px"
                    v-model="searchKeyword"
                    :placeholder="searchPlaceholder"
                    @keyup.enter.native="filterUserList()"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-search" @click="filterUserList()"></i>
                  </xa-input>
                  <xa-select
                    style="margin-left: 12px; width: 190px"
                    class="requiredSelect"
                    v-model="identityId"
                    placeholder="全部身份"
                    clearable
                    @change="filterUserList()"
                  >
                    <xa-option
                      v-for="item in identityList"
                      :key="item.identityId"
                      :label="item.identityName"
                      :value="item.identityId"
                    >
                    </xa-option>
                  </xa-select>
                </div>
                <xa-button
                  v-if="requestUserList"
                  class="managementMember"
                  type="primary"
                  @click="managementMemberClick()"
                  >管理成员列表</xa-button
                >
              </div>
              <xa-table
                ref="selectTable"
                :class="{ 'select-table': true, 'selection-hidden': selectionHidden }"
                :value="userList"
                :max-height="!requestUserList && isShowAllUserName ? 380 : 380"
                style="width: 100%; font-size: 14px"
                :style="{ height: !requestUserList && isShowAllUserName ? '380px' : '380px' }"
                @select="handleSelection"
                @select-all="handleSelectionAll"
              >
                <xa-table-column v-if="multiple" key="Checked" type="selection" :selectable="rowSelectable" width="45">
                </xa-table-column>
                <xa-table-column v-else key="Radio" width="28">
                  <template slot-scope="scope">
                    <xa-radio v-model="radioUserId" :label="scope.row.userId" class="person-radio"></xa-radio>
                  </template>
                </xa-table-column>
                <xa-table-column
                  v-for="(column, index) in columns"
                  :key="index"
                  :label="column.name"
                  :width="column.key === 'mobile' ? '100' : column.width"
                >
                  <template slot-scope="scope">
                    <div
                      v-htmlWithXssFilter="
                        scope.row.organizationName ? scope.row.organizationName.split(',').join(';<br>') : '--'
                      "
                      v-if="column.key === 'organizationName'"
                    ></div>
                    <div
                      v-htmlWithXssFilter="
                        scope.row.identityName ? scope.row.identityName.split(',').join(';<br>') : '--'
                      "
                      v-else-if="column.key === 'identityName'"
                    ></div>
                    <template v-else-if="column.key === 'weixinId'">
                      <span v-if="!!scope.row.weixinId">已绑定</span>
                      <span style="color: #ffb148" v-else>未绑定</span>
                    </template>
                    <template v-else-if="column.key === 'realName'">
                      <xa-tooltip
                        :content="scope.row.realName || scope.row.userRealName || scope.row.name"
                        :hidePopper="!scope.row.realName && !scope.row.userRealName && !scope.row.name"
                        auto-check-hide
                      >
                        <span v-line-clamp:18="2" class="content-width">{{
                          scope.row.realName || scope.row.userRealName || scope.row.name || '--'
                        }}</span>
                      </xa-tooltip>
                    </template>
                    <template v-else-if="column.key === 'mobile'">
                      <span v-if="scope.row.mobile">{{ scope.row.areaCode + '-' + scope.row.mobile }}</span>
                      <span v-else>--</span>
                    </template>
                    <template v-else-if="column.key === 'userCertStatusEnum'">
                      <div class="user-cert-status-box" v-if="scope.row[column.key]">
                        <img
                          :src="userCertStatus[scope.row[column.key]].url"
                          :alt="userCertStatus[scope.row[column.key]].label"
                        />
                        {{ userCertStatus[scope.row[column.key]].label }}
                        <xa-tooltip
                          v-if="scope.row[column.key] === 'AUDIT_UN_PASS'"
                          :content="scope.row.auditFailReason || '暂无失败信息'"
                        >
                          <i class="iconfont web-shuoming"></i>
                        </xa-tooltip>
                        <xa-tooltip v-else-if="scope.row[column.key] === 'COMPLETE_MOBILE'">
                          <div slot="content" style="width: 350px">
                            小安电子签系统已升级，支持通过接收验证码完成意愿
                            认证。该用户的实名认证信息中暂无手机号，系统将在
                            签署时引导用户补全。您可选择该用户并正常发起签名。
                          </div>
                          <i class="iconfont web-shuoming"></i>
                        </xa-tooltip>
                      </div>
                    </template>
                    <span v-else>{{ scope.row[column.key] }}</span>
                  </template>
                </xa-table-column>
              </xa-table>
              <div style="margin-top: 6px" v-line-clamp="2" v-if="!requestUserList && isShowAllUserName">
                已选：{{ selectedUserNames }}
              </div>
            </el-col>
          </el-row>
          <div v-if="!hiddenBottomTip" class="prompt-box" v-html="bottomTip"></div>
          <div class="operationArea">
            <xa-button style="width: 110px" @click="cancleDialog">取 消</xa-button>
            <xa-button
              style="width: 110px"
              type="primary"
              class="vxa-staffSelectInput-dialog-confirm-location"
              @click="submitClick"
              >确 认</xa-button
            >
          </div>
        </div>
        <div v-else style="padding-top: 50px">
          <div class="filter-box">
            <div class="left-filter">
              <xa-input
                class="searchInput"
                v-model="contactName"
                placeholder="搜索三会联系人"
                @keyup.enter.native="searchContact()"
              >
                <i slot="suffix" class="el-input__icon el-icon-search" @click="searchContact()"></i>
              </xa-input>
              <xa-select
                style="margin-left: 12px"
                class="requiredSelect"
                v-model="contactIdentity"
                placeholder="全部身份"
                clearable
                @change="searchContact()"
              >
                <xa-option
                  v-for="item in identityList"
                  :key="item.identityId"
                  :label="item.identityName"
                  :value="item.identityId"
                >
                </xa-option>
              </xa-select>
            </div>
            <span class="message-span">如无需通过手机号创建用户，您可在添加参会成员处管理三会联系人</span>
          </div>
          <div class="meeting-content" v-loading="meetingContactLoading">
            <xa-table
              ref="selectContactTable"
              v-model="filterMeetingContactList"
              max-height="453"
              @select="handleMeetingSelection"
              @select-all="handleMeetingSelectionAll"
              :class="{ 'select-table': true, 'selection-hidden': meetingSelectionHidden }"
            >
              <xa-table-column v-if="multiple" key="MeetingChecked" type="selection" width="50"> </xa-table-column>
              <xa-table-column v-else key="MeetingRadio" width="50">
                <template slot-scope="scope">
                  <xa-radio v-model="radioUserId" :label="scope.row.userId"></xa-radio>
                </template>
              </xa-table-column>
              <xa-table-column label="姓名" prop="name" min-width="100"></xa-table-column>
              <xa-table-column label="身份" prop="identityName" min-width="100">
                <template slot-scope="scope">
                  <div
                    v-htmlWithXssFilter="scope.row.identityName ? scope.row.identityName.split(',').join(';<br>') : ''"
                  ></div>
                </template>
              </xa-table-column>
              <xa-table-column label="邮箱" prop="email" min-width="100"></xa-table-column>
            </xa-table>
          </div>
          <div class="operationArea">
            <xa-button style="width: 110px" type="simple" @click="cancleDialog">取 消</xa-button>
            <xa-button style="width: 110px" type="primary" @click="submitClick">确 认</xa-button>
          </div>
        </div>
      </div>
    </xa-dialog>
  </div>
</template>

<script>
import OrganizationTree from '@/components/Tree/organizationTree.vue'
import { cloneDeep } from 'lodash'
export default {
  name: 'VxaStaffSelect',
  components: {
    OrganizationTree
  },
  data() {
    return {
      pageLoading: false,
      searchKeyword: '',
      identityId: null,
      identityList: [],
      organizationList: [],
      currentOrganization: null,
      userList: [],
      selectedUserList: [],
      radioUserId: '',
      selectionHidden: false, // 是否隐藏table全选框。
      meetingSelectionHidden: false, // 是否隐藏table全选框。
      userCertStatus: {
        UN_AUTHENTICATION: {
          type: 'UN_AUTHENTICATION',
          label: '未认证',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/notCertified.png`
        },
        VERIFIED: {
          type: 'VERIFIED',
          label: '认证成功',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/success.png`
        },
        COMMIT_AUDIT: {
          type: 'COMMIT_AUDIT',
          label: '审核中',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/examining.png`
        },
        AUDIT_UN_PASS: {
          type: 'AUDIT_UN_PASS',
          label: '认证失败',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/fail.png`
        },
        COMPLETE_MOBILE: {
          type: 'COMPLETE_MOBILE',
          label: '待补全手机号',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/success.png`
        }
      },
      activeTab: 'systemContact',
      menuList: [
        {
          active: true,
          label: '系统联系人',
          name: 'systemContact'
        },
        {
          active: false,
          label: '三会联系人',
          name: 'meetingContact'
        }
      ],
      contactName: '',
      contactIdentity: null,
      meetingContactList: [],
      filterMeetingContactList: [],
      meetingContactLoading: false,
      /**
       * 是否需要重置数据，通常在dialog显示的时候需要重置数据,
       * 特殊情况：重名时会隐藏当前dialog，弹出confirm弹窗,当用户点击取消需要重新展示当前dialog，这个时候不需要重置数据
       */
      resetData: true
    }
  },
  props: {
    title: {
      type: String,
      require: false,
      default: ''
    },
    showDialog: {
      type: Boolean,
      require: true,
      default: false
    },
    selectedPersonList: {
      type: Array,
      require: false,
      default: () => []
    },
    selectedIdList: {
      type: Array,
      require: false,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    // requestUserList 为false时，使用传入的allUserList作为用户列表
    allUserList: {
      type: Array,
      default: () => []
    },
    // 是否需要通过请求获取用户列表，可通过外部传入固定列表
    requestUserList: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    // 是否需要在table下方展示已选用户姓名
    isShowAllUserName: {
      type: Boolean,
      default: false
    },
    // 显示用户认证状态
    showCertStatus: {
      type: Boolean,
      default: false
    },
    // 固定添加人员 人员列表，且不可删除
    // [id1,id2]
    fixedUserList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 展示身份成员组：空数组表示展示全部， -1:主管理员 0: 管理员 1:企业版成员
    userRoles: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否需要隐藏底部提示文案
    hiddenBottomTip: {
      type: Boolean,
      default: false
    },
    // 是否展示三会联系人
    showMeetingContact: {
      type: Boolean,
      default: false
    },
    // 重名校验
    duplicationCheck: {
      type: Boolean,
      default: false
    },
    needAppendToBody: {
      type: Boolean,
      default: false
    },
    // 判断当前行是否应该是disabled状态
    checkRowDisabled: [Function],
    bottomTip: {
      type: String,
      default: '注：未填写邮箱或未绑定微信无法接收邮件及微信通知，请提醒企业成员及时完善并绑定。'
    }
  },
  computed: {
    searchPlaceholder: function () {
      if (this.requestUserList) {
        return `在${this.currentOrganization ? this.currentOrganization.organizationName : '全部成员'}中搜索`
      } else {
        return '请输入姓名或手机号'
      }
    },
    selectedUserNames: function () {
      return this.selectedUserList.map(item => item.realName || item.userRealName || item.name).join('、')
    }
  },
  watch: {
    showDialog: {
      handler(val) {
        if (val) {
          if (this.resetData) {
            this.activeTab = 'systemContact'
            this.searchKeyword = ''
            this.identityId = null
            if (this.requestUserList) {
              this.currentOrganization = this.organizationList[0]
            } else {
              this.userList = JSON.parse(JSON.stringify(this.allUserList))
            }
            this.selectedUserList = this.selectedPersonList.map(item => {
              let findUser = this.userList.find(user => user.userId == item.userId)
              if (!findUser) {
                findUser = this.meetingContactList.find(user => user.userId == item.userId)
              }
              return findUser || this.$common.deepCopy(item)
            })
            // 三会联系人
            this.contactName = ''
            this.contactIdentity = null
            // 获取数据
            this.getAllEnterpriseIdentity()
            this.filterUserList()
            this.initTableSelect()
            // 获取三会会议联系人数据
            if (this.showMeetingContact) {
              this.getMeetingsContactUser()
            }
            if (!this.multiple) {
              this.radioUserId = this.selectedUserList.length > 0 ? this.selectedUserList[0].userId : ''
            }
          } else {
            this.initTableSelect()
            this.initContactTableSelect()
            this.resetData = true
          }
        }
      },
      deep: true,
      immediate: true
    },
    radioUserId() {
      if (this.requestUserList) {
        return
      }
      const selectedUser = this.userList.find(item => item.userId == this.radioUserId)
      this.selectedUserList = selectedUser ? [selectedUser] : []
    }
  },
  mounted() {
    if (this.requestUserList) {
      this.getEnterpriseOrgs()
    }
  },
  methods: {
    getAllEnterpriseIdentity() {
      this.$axios.manage.get_org_identities().then(res => {
        this.identityList = res.data.returnObject || []
      })
    },
    managementMemberClick() {
      if (this.$common.hasPermission('COMPANY_SETTING', 'USER_LIST', 'VIEW')) {
        this.cancleDialog()
        this.$common.openWindow('/admin/organization-member-manage')
      } else {
        this.$xaToast({ type: 'info', message: '您不是企业管理员，请联系您的企业管理员进行修改' })
      }
    },
    // 查询机构组织
    getEnterpriseOrgs() {
      if (!this.$storage.getUserInfo().companyId) {
        return
      }
      this.loading = true
      this.$axios.manage
        .get_enterprise_orgs(this.$storage.getUserInfo().companyId)
        .then(res => {
          this.organizationList = res.data.returnObject || []
          if (this.organizationList.length > 0) {
            this.currentOrganization = cloneDeep(this.organizationList[0])
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectChange(currentOrganization) {
      if (this.currentOrganization.id == currentOrganization.id) {
        return
      }
      this.currentOrganization = currentOrganization
      this.searchKeyword = ''
      this.identityId = null
      this.filterUserList()
    },
    initTableSelect() {
      this.$nextTick(() => {
        if (!this.$refs.selectTable) {
          return
        }
        if (this.multiple) {
          this.userList.forEach((user, index) => {
            const selected = this.selectedUserList.find(item => user.userId == item.userId)
            this.$nextTick(() => {
              this.$refs.selectTable.toggleRowSelection(user, !!selected)
            })
          })
        }
      })
    },
    rowSelectable(row) {
      if (
        (this.fixedUserList?.length && this.fixedUserList.some(user => row.userId === user)) ||
        (this.checkRowDisabled && this.checkRowDisabled(row))
      ) {
        // 固定创建者选中状态不可修改
        return false
      } else {
        return true
      }
    },
    handleSelection(selectList, row) {
      const index = selectList.findIndex(item => item.userId == row.userId)
      if (index >= 0) {
        this.selectedUserList.push(row)
      } else {
        this.selectedUserList = this.selectedUserList.filter(item => item.userId != row.userId)
      }
    },
    handleSelectionAll(selectList) {
      if (selectList.length == 0) {
        // 全选勾除
        this.userList.forEach(item => {
          const selected = this.selectedUserList.findIndex(items => item.userId == items.userId)
          this.selectedUserList.splice(selected, 1)
        })
        return
      }
      // 除指定人员外，全部勾除
      if (this.fixedUserList?.length && selectList.length === this.fixedUserList.length) {
        this.userList.forEach(item => {
          if (!this.fixedUserList.some(user => item.userId === user)) {
            const selected = this.selectedUserList.findIndex(items => item.userId == items.userId)
            this.selectedUserList.splice(selected, 1)
          }
        })
        return
      }
      selectList.forEach(item => {
        const index = this.selectedUserList.findIndex(selectedItem => item.userId == selectedItem.userId)
        if (index < 0) {
          this.selectedUserList.push(item)
        }
      })
    },
    filterUserList() {
      if (this.requestUserList) {
        this.listUserInfoByCompanyId()
      } else {
        this.filterTableList()
      }
    },
    // 通过接口获取筛选人员列表
    listUserInfoByCompanyId() {
      const params = {
        keyword: this.searchKeyword,
        organizationId: this.currentOrganization?.moduleEnum === 'ROOT' ? '' : this.currentOrganization?.id,
        identityId: this.identityId,
        showCertStatus: this.showCertStatus ? '1' : null,
        userGroups: this.userRoles
      }
      this.pageLoading = true
      this.$axios.manage
        .search_org_users(this.$storage.getUserInfo().companyId, params)
        .then(res => {
          this.selectionHidden = true
          if (!this.searchKeyword && !this.identityId) {
            this.selectionHidden = false
          }
          this.userList = res.data.returnObject.list || []
          this.userList.forEach(item => {
            item.organization = item.organizationId ? item.organizationId.split(',') : []
            item.organization = item.organization.map(oID => parseInt(oID))
            item.identityId = item.identityId ? item.identityId.split(',') : []
            item.emailChecked = !item.email
            item.source = 1
          })
          this.initTableSelect()
          this.$emit('userRequested')
        })
        .catch(e => {
          this.userList = []
        })
        .finally(() => {
          this.pageLoading = false
        })
    },
    // 仅前端筛选人员列表
    filterTableList() {
      const allUserList = JSON.parse(JSON.stringify(this.allUserList))
      if (!this.searchKeyword && !this.identityId) {
        this.selectionHidden = false
        this.userList = allUserList
      } else {
        this.selectionHidden = true
        this.userList = allUserList.filter(item => {
          let isValue = true
          if (
            this.searchKeyword &&
            (item.realName || '').indexOf(this.searchKeyword) === -1 &&
            (item.userRealName || '').indexOf(this.searchKeyword) === -1 &&
            (item.name || '').indexOf(this.searchKeyword) === -1 &&
            (item.mobile || '').indexOf(this.searchKeyword) === -1
          ) {
            isValue = false
          }
          if (this.identityId) {
            const identity = this.identityList.find(item => item.identityId === this.identityId)
            const identityNameList = item.identityName.split(',')
            if (identity && !identityNameList.includes(identity.identityName)) {
              isValue = false
            }
          }
          return isValue
        })
      }
      this.initTableSelect()
    },
    cancleDialog() {
      this.$emit('update:showDialog', false)
    },
    submitClick() {
      if (!this.duplicationCheck) {
        this.submitData()
        return
      }
      // 重名校验
      const names = this.selectedUserList.map(item => item.realName || item.userRealName || item.name)
      const nameSet = new Set(names)
      if (nameSet.size < names.length) {
        // 由于会产生双层dialog，所以隐藏选择成员dialog
        this.cancleDialog()
        this.$xaConfirm({
          message: '所选成员存在相同姓名，是否继续添加？',
          cancelButtonText: '取 消',
          confirmButtonText: '确 认'
        })
          .then(() => {
            this.submitData()
          })
          .catch(() => {
            this.resetData = false
            this.$emit('update:showDialog', true)
          })
      } else {
        this.submitData()
      }
    },
    submitData() {
      if (!this.multiple) {
        const selected = this.userList.find(item => item.userId == this.radioUserId)
        const selectedMeeting = this.meetingContactList.find(item => item.userId == this.radioUserId)
        this.selectedUserList = selected || selectedMeeting ? [selected || selectedMeeting] : []
      }
      this.$emit('saveSelect', this.selectedUserList)
      this.cancleDialog()
    },
    // tab切换
    handleMenu() {
      if (this.activeTab == this.menuList[0].name) {
        this.initTableSelect()
      }
      if (this.activeTab == this.menuList[1].name) {
        this.initContactTableSelect()
      }
    },
    // 搜索三会联系人
    searchContact() {
      if (this.meetingContactList.length > 0) {
        this.getFilterMeetingContactList()
        this.initContactTableSelect()
      } else {
        this.getMeetingsContactUser()
      }
    },
    handleMeetingSelection(selectList, row) {
      const index = selectList.findIndex(item => item.userId == row.userId)
      if (index >= 0) {
        this.selectedUserList.push(row)
      } else {
        this.selectedUserList = this.selectedUserList.filter(item => item.userId != row.userId)
      }
    },
    handleMeetingSelectionAll(selectList) {
      if (selectList.length == 0) {
        // 全选勾除
        this.userList.forEach(item => {
          const selected = this.selectedUserList.findIndex(items => item.userId == items.userId)
          this.selectedUserList.splice(selected, 1)
        })
        return
      }
      selectList.forEach(item => {
        const index = this.selectedUserList.findIndex(selectedItem => item.userId == selectedItem.userId)
        if (index < 0) {
          this.selectedUserList.push(item)
        }
      })
    },
    // 三会联系人选中
    initContactTableSelect() {
      this.$nextTick(() => {
        if (!this.$refs.selectContactTable) {
          return
        }
        this.filterMeetingContactList.forEach((user, index) => {
          const selected = this.selectedUserList.find(item => user.userId == item.userId)
          this.$nextTick(() => {
            this.$refs.selectContactTable.toggleRowSelection(user, !!selected)
          })
        })
      })
    },
    // 获取当前公司下所有三会联系人
    getMeetingsContactUser() {
      this.meetingContactLoading = true
      const organizationId = this.$storage.getUserInfo().companyId
      this.$axios.meetingmanagement
        .meeting_contact_user(organizationId)
        .then(res => {
          this.meetingContactList = res.data.returnObject || []
          // 参会人员来源1：系统联系人；2：三会联系人
          this.meetingContactList.forEach(item => {
            item.source = 2
          })
          this.getFilterMeetingContactList()
          this.initContactTableSelect()
        })
        .finally(() => {
          this.meetingContactLoading = false
        })
    },
    // 过滤三会筛选条件
    getFilterMeetingContactList() {
      this.meetingSelectionHidden = this.contactName || this.contactIdentity
      this.filterMeetingContactList = this.meetingContactList.filter(
        item =>
          item.name.includes(this.contactName) &&
          (!this.contactIdentity || (item.identityId || []).split(',').includes(this.contactIdentity))
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.staffSelectInput-dialog {
  .el-table--scrollable-y .el-table__body-wrapper {
    overflow: hidden;
    overflow-y: auto;
  }
  /deep/ .xa-table .el-table .el-table__body-wrapper td {
    padding: 8px 0;
  }
  /deep/ .el-table::before {
    height: 0;
  }
  /deep/ .el-table td {
    border-bottom: 1px solid #ebeef5 !important;
  }
  .prompt-box {
    margin-top: 10px;
    font-size: 13px;
    color: #aeaeae;
  }

  .select-table {
    min-height: 150px;
  }
  /* 禁用全选 */
  .selection-hidden {
    /deep/ .el-table__header-wrapper {
      .el-table__header {
        .el-table-column--selection {
          .cell {
            display: none !important;
          }
        }
      }
    }
  }
  /deep/ .el-table thead {
    color: #323232;
  }
  /deep/ .el-dialog__body {
    td .cell {
      line-height: 18px;
    }
  }
  /deep/ .el-dialog__wrapper {
    &.dialog-fade-enter-active {
      -ms-animation: none;
    }
    &.dialog-fade-leave-active {
      -ms-animation: none;
    }
  }
  .content-box {
    position: relative;
    min-height: 500px;
    /deep/.el-tabs__item {
      height: 50px;
      line-height: 50px;
    }
    /deep/.el-tabs__nav-wrap::after {
      height: 1px;
    }
    .tab-box {
      position: absolute;
      top: -13px;
      width: 100%;
    }

    .filter-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-filter {
        display: flex;
        align-items: center;
        .search-input {
          margin-right: 10px;
          width: 400px;
          font-size: 12px;
          /deep/ .el-input {
            input {
              font-size: 12px !important;
            }
          }
        }
      }

      .message-span {
        color: #999;
      }
    }
    .meeting-content {
      margin-top: 10px;
      height: 453px;
      width: 100%;
    }
  }
  .organizationTree {
    background: rgba(246, 246, 246, 1);
    .organizationTitle {
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      font-weight: bold;
      margin-left: 4px;
    }
  }
  .system-filter-box {
    margin: 6px 0 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-box {
      display: flex;
    }
  }
  .tree-wrapper {
    width: 270px;
    height: 378px;
    overflow-y: auto;
  }
  .searchInput {
    width: 400px !important;
    input {
      height: 28px;
    }
    .el-icon-search {
      color: #aeaeae;
    }
  }
  .requiredSelect {
    /deep/ .el-select {
      input {
        height: 32px !important;
      }
    }
  }
  .managementMember {
    width: 100px;
    height: 28px;
    padding: 0;
    line-height: 28px;
    float: right;
  }
  .vxa-footer-page-pagination {
    text-align: center;
    margin-top: 12px;
  }
  .operationArea {
    margin-top: 24px;
    text-align: center;
  }
  .user-cert-status-box {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    .iconfont {
      margin-left: 4px;
      color: #f08880;
    }
  }

  .person-radio {
    /deep/.el-radio__label {
      padding-left: 10px;
    }
  }
}
/deep/ .el-dialog--center {
  .el-dialog__body {
    padding: 10px 20px 20px !important;
    .el-radio__label {
      display: none;
    }
    .el-input__inner {
      border-color: #e5e5e5 !important;
    }
  }
}
</style>
