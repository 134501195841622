import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 批量添加监管公司
  add_supervise_company_list(params) {
    return Axios({ method: 'POST', url: api.superviseCompany.supervise_company_list, data: params })
  },
  // 批量删除监管公司
  delete_supervise_company_list(params) {
    return Axios({ method: 'DELETE', url: api.superviseCompany.supervise_company_list, params: params })
  },
  // 通过userId获取用户所有的监管公司
  get_all_supervise_company_by_user(organizationId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.superviseCompany.get_all_supervise_company_by_user, [['organizationId', organizationId]]), originUrl: api.superviseCompany.get_all_supervise_company_by_user, params })
  },
  // 通过userId搜索用户的监管公司行情等详细信息
  get_supervise_company_list(params, organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.superviseCompany.get_supervise_company_list, [['organizationId', organizationId]]), params: params, originUrl: api.superviseCompany.get_supervise_company_list })
  },
  // 获取监管公司舆情统计
  get_supervise_company_sentiment_total(params, organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.superviseCompany.get_supervise_company_sentiment_total, [['organizationId', organizationId]]), params: params, originUrl: api.superviseCompany.get_supervise_company_sentiment_total })
  },
  // 根据关键词搜索上市公司
  search_company_by_keyword(params) {
    return Axios({ method: 'GET', url: api.superviseCompany.search_company_by_keyword, params: params })
  }
}
