<!--
 * @Description: 提示设置监管公司或服务公司的对话框
-->
<template>
  <xa-dialog
    title="提示"
    :close-on-click-modal="false"
    :visible.sync="regulatorDialog"
    width="30%"
    center
    custom-class="regulatorDialog"
  >
    <span v-if="$auth.isSuperviseUser()">你还未设置监管公司，前往设置。</span>
    <span v-else>你还未设置服务公司，前往设置。</span>
    <span slot="footer" class="dialog-footer">
      <xa-button type="primary" @click="goSetCompany">确 定</xa-button>
    </span>
  </xa-dialog>
</template>

<script>
export default {
  name: 'regulatorDialog',
  props: {
    regulatorDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goSetCompany() {
      this.$router.push('/dashboard/regulator')
    }
  }
}
</script>

<style lang="scss">
.regulatorDialog {
  .el-dialog--center .el-dialog__body {
    padding: 25px;
  }
  .el-dialog__footer {
    padding: 0 10px 20px;
  }
}
</style>
