import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 获取定期报告拆解章节目录
  periodic_report_chapter_directory(params) {
    return Axios({ method: 'GET', url: api.periodicReportChapter.periodic_report_chapter_directory, params })
  },
  // 获取下拉选
  periodic_report_chapter_selections() {
    return Axios({ method: 'GET', url: api.periodicReportChapter.periodic_report_chapter_selections })
  },
  // 获取定期报告拆解章节列表
  periodic_report_chapter_list(params) {
    return Axios({ method: 'POST', url: api.periodicReportChapter.periodic_report_chapter_list, data: params })
  },
  // 获取目录设置
  directory_settings(params) {
    return Axios({ method: 'GET', url: api.periodicReportChapter.directory_settings, params })
  },
  // 保存目录设置
  save_directory_settings(params) {
    return Axios({ method: 'POST', url: api.periodicReportChapter.directory_settings, data: params })
  },
  // 获取目录设置下拉选
  directory_selections() {
    return Axios({ method: 'GET', url: api.periodicReportChapter.directory_selections })
  },
  // 获取定期报告拆解章节内容
  periodic_report_chapter_content(chapterId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.periodicReportChapter.periodic_report_chapter_content, [['chapterId', chapterId]]), originUrl: api.periodicReportChapter.periodic_report_chapter_content, data: params })
  },
  // 获取定期报告拆解章节内容 - 全文检索时，传入公告id
  periodic_report_chapter_content_announcements(announcementId, params) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.periodicReportChapter.periodic_report_chapter_content_announcements, [['announcementId', announcementId]]), originUrl: api.periodicReportChapter.periodic_report_chapter_content_announcements, data: params })
  }
}
