const data = {
  state: {
    deletedLawNoteId: '',
    lawNoteUpdate: false, // 是否侧边栏更新了笔记
    asideNoteState: false, // 打开侧边栏笔记
    ruleDetailNoteModel: false // 法规详情页笔记模式
  },
  mutations: {
    DELETE_LAW_NOTE: (state, data) => {
      state.deletedLawNoteId = data
    },
    LAW_NOTE_UPDATE: (state, data) => {
      state.lawNoteUpdate = data
    },
    ASIDE_NOTE_STATE: (state, data) => {
      state.asideNoteState = data
    },
    RULE_DETAIL_NOTE_MODEL: (state, data) => {
      state.ruleDetailNoteModel = data
    }
  }
}

export default data
