/*
 * @Description:履职清单
 * @Author: lilin.yang@infaith.com.cn
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 独董履职清单
  duty_list_topic() {
    return Axios({ method: 'GET', url: api.dutyList.duty_list_topic })
  },
  // 创建独董履职事项
  create_issue(params) {
    return Axios({ method: 'POST', url: api.dutyList.create_issue, data: params })
  },
  // 获取事项详情
  performance_duty_detail(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.performance_duty_detail, [['itemId', itemId]]) })
  },
  // 修改事项要点的状态
  put_performance_duty_detail(params, itemId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.dutyList.performance_duty_detail, [['itemId', itemId]]), data: params, originUrl: api.dutyList.performance_duty_detail })
  },
  delete_performance_duty_detail(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.dutyList.performance_duty_detail, [['itemId', itemId]]) })
  },
  // 新增询问表
  post_questionnaire(params) {
    return Axios({ method: 'POST', url: api.dutyList.post_questionnaire, data: params })
  },
  // 获取“进行中”/"已归档"的独董履职事项中的公司列表，供筛选项使用
  // 默认不传参数查进行中 status传1查已归档
  get_duty_company(params) {
    return Axios({ method: 'GET', url: api.dutyList.get_duty_company, params })
  },
  // 搜索独董履职事项
  duty_search(params) {
    return Axios({ method: 'GET', url: api.dutyList.duty_search, params })
  },
  // 删除事项
  delete_matter(itemId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.dutyList.delete_matter, [['itemId', itemId]]), originUrl: api.dutyList.delete_matter })
  },
  // 归档事项
  issue_filing(itemId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.dutyList.issue_filing, [['itemId', itemId]]), originUrl: api.dutyList.issue_filing })
  },
  // 获取事项的类型、议题和要点的标题信息
  get_item_base_info(itemId, pointId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.get_item_base_info, [['itemId', itemId], ['pointId', pointId]]), originUrl: api.dutyList.get_item_base_info })
  },
  // 获取事项要点的操作记录
  get_item_operating_record(itemId, pointId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.get_item_operating_record, [['itemId', itemId], ['pointId', pointId]]), originUrl: api.dutyList.get_item_operating_record })
  },
  // 获取要点的沟通记录
  get_item_communication_record(itemId, pointId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.get_item_communication_record, [['itemId', itemId], ['pointId', pointId]]), originUrl: api.dutyList.get_item_communication_record })
  },
  // 获取要点的沟通记录
  get_issue_communication_record(itemId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.get_issue_communication_record, [['itemId', itemId]]), originUrl: api.dutyList.get_issue_communication_record })
  },
  // 获取问卷答复记录
  get_issue_answer_record(questionnaireId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.dutyList.get_issue_answer_record, [['questionnaireId', questionnaireId]]), originUrl: api.dutyList.get_issue_answer_record })
  },
  // 修改问卷要点的状态
  update_question_status(params, itemId, questionnaireId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.dutyList.update_question_status, [['itemId', itemId], ['questionnaireId', questionnaireId]]), data: params, originUrl: api.dutyList.update_question_status })
  }
}
