import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

// 财务数据
export default {
  upload_files(params) {
    return Axios({ method: 'POST', url: api.indicator.upload_files, data: params })
  },
  main_indicator() {
    return Axios({ method: 'GET', url: api.indicator.main_indicator })
  },
  indicator_digest(params) {
    return Axios({ method: 'GET', url: api.indicator.indicator_digest, params: params })
  },
  list_financial_data(params) {
    return Axios({ method: 'GET', url: api.indicator.list_financial_data, params: params })
  },
  indicator_chart(params) {
    return Axios({ method: 'GET', url: api.indicator.indicator_chart, params: params })
  },
  indicator_report(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.indicator.indicator_report, [['companyId', companyId]]), params: params, originUrl: api.indicator.indicator_report })
  },
  get_report_period(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.indicator.get_report_period, [['companyId', companyId]]), originUrl: api.indicator.get_report_period })
  },
  // 财务报告所有指标
  get_indicator_finance_preference(params) {
    return Axios({ method: 'GET', url: api.indicator.get_indicator_finance_preference, params })
  },
  // 保存指标偏好
  indicator_save_preference(data) {
    return Axios({ method: 'POST', url: api.indicator.indicator_save_preference, data })
  },
  // 获取导入财务数据列表
  import_financial_data(params) {
    return Axios({ method: 'GET', url: api.indicator.import_financial_data, params: params })
  },
  // 更改导入财务数据启用状态
  import_financial_data_status(id, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.indicator.import_financial_data_status, [['id', id]]), originUrl: api.indicator.import_financial_data_status, params: params })
  },
  // 根据id删除导入的数据
  del_import_financial_data(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.indicator.del_import_financial_data, [['id', id]]), originUrl: api.indicator.del_import_financial_data })
  },
  // 导入财务数据前置校验
  pre_check(params) {
    return Axios({ method: 'GET', url: api.indicator.pre_check, params: params })
  },
  // 导入财务数据
  financial_data_import(data) {
    return Axios({ method: 'POST', url: api.indicator.financial_data_import, data: data })
  },
  // 获取导入财务数据模板
  get_template_file() {
    return Axios({ method: 'GET', url: api.indicator.get_template_file })
  },
  // 获取当前公司下，用户导入财务数据提醒状态
  financial_data_notify() {
    return Axios({ method: 'GET', url: api.indicator.financial_data_notify })
  },
  // 修改当前公司下，用户导入财务数据提醒状态
  update_financial_data_notify() {
    return Axios({ method: 'PUT', url: api.indicator.financial_data_notify })
  },
  financial_data_share(params, id) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.indicator.financial_data_share, [['id', id]]), originUrl: api.indicator.financial_data_share, data: params })
  },
  // 比率分析页面-财务报告所有指标
  radio_indicators(params) {
    return Axios({ method: 'GET', url: api.indicator.radio_indicators, params: params })
  },
  // 获取比率分析页面数据
  analysis_radio_indicators(params) {
    return Axios({ method: 'POST', url: api.indicator.radio_indicators, data: params })
  },
  // 比率分析页面, - 导出报告
  radio_reports_generate(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.indicator.radio_reports_generate, [['companyCode', companyCode]]), originUrl: api.indicator.radio_reports_generate, data: params })
  },
  // 比率分析页面 - 获取导出报告地址
  radio_download_path(params) {
    return Axios({ method: 'GET', url: api.indicator.radio_download_path, params: params })
  },
  // 比率分析 - 默认选中报告期
  latest_reporting_period(params) {
    return Axios({ method: 'GET', url: api.indicator.latest_reporting_period, params: params })
  },
  // 获取单季度财务报告起始年份
  company_quarter_fin_data_start_year() {
    return Axios({ method: 'GET', url: api.indicator.company_quarter_fin_data_start_year })
  },
  // 主要财务数据导出excel
  companies_finance_digest_export(params) {
    return Axios({ method: 'GET', url: api.indicator.companies_finance_digest_export, params: params })
  },
  // 获取证监会或申万同行业股票代码
  company_stocks_same_industry_company(params) {
    return Axios({ method: 'GET', url: api.indicator.company_stocks_same_industry_company, params: params })
  }
}
