import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // ipo 筛选项
  filter_conditions(params) {
    return Axios({ method: 'GET', url: api.publish.filter_conditions, params: params })
  },
  get_ipo_industries() {
    return Axios({ method: 'GET', url: api.publish.get_ipo_industries })
  }
}
