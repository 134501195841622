<!--
 * @Description: 基础顶部banner banner + 标题 + 简介
 * @Author: lei.wu
-->
<template>
  <div
    class="baseBanner"
    :style="{ 'background-image': `url(${bgImg})`, 'background-size': bgImgSize, height: `${bgImgHeight}px` }"
  >
    <div class="content-box content">
      <p class="title">{{ title }}<slot name="titleRight"></slot></p>
      <p class="introduction">{{ introduction }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxaBaseBanner',
  data: function () {
    return {}
  },
  props: {
    bgImg: {
      type: String,
      default: function () {
        return `${this.$api.aliyunDomain}xiaoan/web_static/banner/headerBanner.png`
      },
      required: false
    },
    bgImgSize: {
      type: String,
      default: '100% 100%',
      required: true
    },
    bgImgHeight: {
      type: String,
      default: '166',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    introduction: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.baseBanner {
  padding-top: 40px;
  width: 100%;
  height: 166px;
  background-position: center;
  color: #ffffff;
  .content-box {
    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 42px;
      display: flex;
      align-items: center;
    }
    .introduction {
      margin-top: 8px;
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
    }
  }
}
</style>
