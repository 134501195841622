import promiseFinally from 'promise.prototype.finally'
import moment from 'moment'
import Vue from 'vue'
import store from '@/store'
import App from '@/App'
import router from '@/router'
import Http from '@/service/http'
import Regx from '@/utils/regx'
import Storage from '@/service/storage'
import axios from '@/axios/index'
import Auth from '@/service/auth'
import common from '@/utils/common'
import ImgUri from '@/config/image/index'
import lineClamp from 'vue-line-clamp'
import api from '@/service/api'

import * as Sentry from '@sentry/vue'
import Xiaoanui from '@xiaoan/ui'
import '@xiaoan/ui/lib/xiaoan-ui.css'
import 'umy-ui/lib/theme-chalk/index.css'// 引入样式

import ElementUI from '@xiaoan/element-ui'
import '@/styles/eleui.scss'

import Components from '@/utils/components'
import directive from '@/config/directive.js'

import '@/styles/reset.scss'
import '@/styles/common.scss'
import '@/styles/vxa-theme.scss'
import '@/styles/image-theme.scss'
import '@/styles/animate.scss'

import vxaNotify from '@/components/notify/index.js'

// edge不支持promise.finally，增加垫片
promiseFinally.shim()

directive.openDirective(Vue)

Components(Vue)

Vue.use(ElementUI, { size: 'medium' })
Vue.use(Xiaoanui)
Vue.use(lineClamp)
Vue.use(vxaNotify)
const xaMoment = (val) => {
  if (typeof val == 'string') {
    val = val.replace(/-/gi, '/')
  }
  return moment(val)
}
Object.setPrototypeOf(xaMoment, moment)
Vue.prototype.$moment = xaMoment
Vue.prototype.$http = Http
Vue.prototype.$regx = Regx
Vue.prototype.$storage = Storage
Vue.prototype.$auth = Auth
Vue.prototype.$common = common
Vue.prototype.$axios = axios
Vue.prototype.$image = ImgUri
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$api = api

window._xiaoanConfig = {
  XA_ENV: process.env.VUE_APP_XA_ENV
}

window.sensors = {
  quick: function () { }
}

Vue.config.productionTip = false
Vue.config.errorHandler = (error, vm, info) => {
  console.error('抛出全局异常')
  console.error(error)
  const errorKey = error.toString()

  // 当网络状况不佳时，发送请求失败 不抛出这类警告
  // 2d83/e.exports 根据排查是页面重定向时接口被cancel导致
  // 接口请求状态为500的错误不上报至钉钉
  if (error.stack.indexOf('Network Error') >= 0 || error.stack.indexOf('FtD3') >= 0 || error.stack.indexOf('2d83/e.exports') >= 0 || error.stack.indexOf('Error: Request failed with status code 500') >= 0) {
    return
  }
  // 本地起的服务，或者是dev环境，不发告警群
  if (process.env.NODE_ENV === 'development' || ['development', 'development2'].includes(process.env.VUE_APP_XA_ENV)) {
    return
  }

  const param = {
    application: 'xiaoan-enterprise',
    content: 'Error: 未知错误'
  }
  param.content = `Error:URL:${location.href}
userId:${Storage.getUserInfo().userId || ''}
info:${info}
stack:${JSON.stringify(error.stack)}`
  let errorReportedToDingDing = store.state.storeData.errorReportedToDingDing
  const repeatError = errorReportedToDingDing.find(errorItem => errorItem.errorKey === errorKey)
  const currentTimestamp = xaMoment().valueOf()
  if (repeatError && (currentTimestamp - repeatError.timestamp <= 60 * 1000)) {
    // 1分钟之内重复触发的错误不上报至钉钉
    return
  }
  Vue.prototype.$axios.errorCollection.error_collection_to_ding(param)
  errorReportedToDingDing = errorReportedToDingDing.filter(errorItem => errorItem.errorKey !== errorKey)
  errorReportedToDingDing.push({ errorKey, timestamp: currentTimestamp })
  store.commit('ERROR_REPORTED_TO_DINGDING', errorReportedToDingDing)
}

// 生产环境上报异常
if (['production', 'pre'].includes(process.env.VUE_APP_XA_ENV)) {
  Sentry.init({
    Vue,
    dsn: 'https://6229d9e77b84729b78570f2ed405e1a1@sentry.in-hope.cn/2',
    environment: process.env.VUE_APP_XA_ENV
  })
  Sentry.setUser({ 'id': Storage.getUserInfo().userId || '' })
  Sentry.setContext('character', {
    JWT: Storage.get('JWT') || '',
    userInfo: Storage.getUserInfo()
  })
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
