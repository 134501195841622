<!--
 * @Description: 自定义分页组件，支持在当前页码和总页数之间切换，并在到达第一页或最后一页时禁用相应的切换按钮
-->
<template>
  <div class="customPage">
    <div class="imgBox" @click="subtraction">
      <img :src="$image.affiche.left" :class="{ prohibit: this.flip == 1 }" />
    </div>

    <span>
      <p style="color: #f0b148">{{ flip }}</p>
      / {{ total }}</span
    >
    <div class="imgBox" @click="addition">
      <img :src="$image.affiche.left" :class="{ prohibit: this.flip == this.total }" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'customPage',
  props: {
    flip: {
      type: Number,
      required: true,
      default: 1
    },
    total: {
      type: Number,
      required: true,
      default: 50
    }
  },
  methods: {
    subtraction() {
      this.$emit('subtraction')
    },
    addition() {
      this.$emit('addition')
    }
  }
}
</script>
<style lang="scss" scoped>
.customPage {
  font-size: 14px;
  color: #8d8d8d;
  float: right;
  .imgBox {
    height: 100%;
    width: 10px;
    display: inline-block;
    img {
      cursor: pointer;
    }
    .prohibit {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  span {
    margin: 0;
    p {
      display: inline-block;
    }
  }
  .imgBox:last-child {
    img {
      transform: rotate(180deg);
    }
  }
}
</style>
