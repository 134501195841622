<template>
  <div id="filterContainer">
    <div class="panel">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filterContainer',
  props: {},
  created() {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
#filterContainer {
  width: 100%;
  display: flex;
  .panel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

/deep/ .filter-container-item-box {
  position: relative;
  .el-input,
  .el-input__inner {
    input {
      text-align: left !important;
      padding-left: 75px !important;
    }
  }
  .item-label {
    position: absolute;
    top: 0;
    left: 10px;
    width: 55px;
    font-size: 12px;
    line-height: 32px;
    margin-right: 10px;
    font-weight: normal;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1000;
  }
}
</style>
