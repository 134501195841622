/*
 * @Description:兼容历史Url的代码
 * @Author: lilin.yang@infaith.com.cn
 */
const redirectUrl = [
  // 注册
  {
    path: '/register',
    redirect: '/register/1'
  },
  // 日历
  {
    path: '/calendar/eventDetaile',
    redirect: '/calendar/eventDetail'
  },
  {
    path: '/calendar/index',
    redirect: '/calendars/index'
  },
  {
    path: '/calendar/addEvent',
    redirect: '/calendars/events/new'
  },
  {
    path: '/calendar/searchEvent',
    redirect: '/calendars/events/search'
  },
  {
    path: '/calendar/eventDetail',
    redirect: to => {
      // path: /calendars/events/:id
      return `/calendars/events/${to.query.id}`
    }
  },
  {
    path: '/calendar/editCalendar',
    redirect: to => {
      // path: /calendars/:id/edit
      return `/calendars/${to.query.id || 'tempId'}/edit`
    }
  },
  {
    path: '/calendar/shareLink',
    redirect: to => {
      // path: /calendars/:id/sharing/link
      return `/calendars/${to.query.id || 'tempId'}/sharing/link`
    }
  },
  // 法规
  {
    path: '/rule',
    redirect: '/rules'
  },
  {
    path: '/ruleDetail',
    redirect: to => {
      // path: /rules/:id
      return `/rules/${to.query.id}`
    }
  },
  {
    path: '/rule/details',
    redirect: to => {
      // path: /rules/:id
      return `/rules/${to.query.id}`
    }
  },
  {
    path: '/rules/:id/detail',
    redirect: to => {
      // path: /rules/:id
      return `/rules/${to.params.id}`
    }
  },
  {
    path: '/ruleCollect',
    redirect: '/rules/compilation'
  },
  {
    path: '/ruleCollectInfo',
    redirect: to => {
      // path: /rules/compilation/:id/detail
      return `/rules/compilation/${to.query.id}/detail`
    }
  },
  // 权限速查
  {
    path: '/accessTables',
    redirect: '/accessTables/mainTable'
  },
  {
    path: '/accessTables/viewDetail',
    redirect: '/accessTables/mainTable'
  },
  // 财务分析
  {
    path: '/finance/analyse',
    redirect: '/finance-analysis/annual-report'
  },
  // 公告
  {
    path: '/affiche',
    redirect: '/affiches'
  },
  {
    path: '/afficheContent',
    redirect: to => {
      // path: /affiches/:id
      return `/affiches/${to.query.id}`
    }
  },
  {
    path: '/affiche/afficheDown',
    redirect: '/affiches/download'
  },
  {
    path: '/affiche/details',
    redirect: to => {
      // path: /affiches/:id
      return `/affiches/${to.query.id}`
    }
  },
  // 违规案例
  {
    path: '/cases',
    redirect: '/violation-cases'
  },
  {
    path: '/case',
    redirect: '/violation-cases'
  },
  {
    path: '/caseDetail',
    redirect: '/case/details'
  },
  {
    path: '/case/details',
    redirect: to => {
      // path: /violation-cases/:id
      return `/violation-cases/${to.query.id}`
    }
  },
  {
    path: '/violation-case/:id',
    redirect: to => {
      // path: /violation-cases/:id
      return `/violation-cases/${to.params.id}`
    }
  },
  // 研报
  {
    path: '/report',
    redirect: '/reports'
  },
  {
    path: '/reportDetail',
    redirect: '/report/details'
  },
  {
    path: '/report/details',
    redirect: to => {
      // path: /reports/:id
      return `/reports/${encodeURIComponent(to.query.id)}`
    }
  },
  {
    path: '/report/:id/detail',
    redirect: to => {
      // 第一次跳转已经加密，第二次无需加密
      // path: /reports/:id
      return `/reports/${to.params.id}`
    }
  },
  {
    path: '/reports/:id/detail',
    redirect: to => {
      // path: /reports/:id
      return `/reports/${to.params.id}`
    }
  },
  // 三会
  {
    path: '/meetingUnderway',
    redirect: '/meeting/underway'
  },
  {
    path: '/meetingInfo',
    redirect: '/meeting/guide'
  },
  {
    path: '/meetingIndex',
    redirect: '/meeting/index'
  },
  {
    path: '/meetingAll',
    redirect: '/meeting/all'
  },
  {
    path: '/meetingStatistics',
    redirect: '/meeting/statistics'
  },
  {
    path: '/meetingTemplate',
    redirect: '/meeting/template'
  },
  {
    path: '/meetingEdit',
    redirect: to => {
      // path: /meeting/:meetingId/edit
      return `/meeting/${to.query.meetingId}/edit`
    }
  },
  {
    path: '/onlineEdit',
    redirect: to => {
      // path: /meeting/:fileId/online-edit
      return `/meeting/files/${to.query.fileId}/online-edit`
    }
  },
  // 监管动态
  {
    path: '/activities',
    redirect: '/supervision'
  },
  // 股行分
  {
    path: '/stockholder',
    redirect: '/stockholders'
  },
  {
    path: '/stockholder/rosterExport',
    redirect: '/stockholders/import-roster'
  },
  {
    path: '/stockholder/dataManage',
    redirect: '/stockholders/data-manage'
  },
  {
    path: '/stockholder/groupSet',
    redirect: '/stockholders/group-set'
  },
  {
    path: '/stockholder/investorManage',
    redirect: '/stockholders/investor-manage'
  },
  {
    path: '/stockholder/relationshipResearch',
    redirect: '/stockholders/survey-records/add'
  },
  {
    path: '/stockholder/surveyRecords',
    redirect: '/stockholders/survey-records'
  },
  {
    path: '/stockholder/comparisonAnalysis',
    redirect: '/stockholders/comparison/analysis'
  },
  {
    path: '/stockholder/stockholderComparison',
    redirect: '/stockholders/comparison/overview/analysis'
  },
  {
    path: '/stockholder/subitemComparison',
    redirect: '/stockholders/comparison/item/analysis'
  },
  {
    path: '/stockholder/groupComparison',
    redirect: '/stockholders/comparison/group/analysis'
  },
  {
    path: '/stockholder/newStockholderAnalysis',
    redirect: '/stockholders/newest/analysis'
  },
  {
    path: '/stockholder/importantStock',
    redirect: '/stockholders/important'
  },
  {
    path: '/stockholder/stockStruct',
    redirect: '/stockholders/structure'
  },
  {
    path: '/stockholder/stockRollOfficial',
    redirect: '/stockholders/roster'
  },
  {
    path: '/stockholder/marketInstitution',
    redirect: '/stockholders/investor/search'
  },
  {
    path: '/stockholder/institutionAccountInfo',
    redirect: to => {
      // path: /stockholders/:id/detail
      return `/stockholders/${to.query.id}/detail`
    }
  },
  {
    path: '/stockholder/institutionBaseInfo',
    redirect: to => {
      // path: /stockholders/:id/detail/baseinfo
      return `/stockholders/${to.query.id}/detail/baseinfo`
    }
  },
  {
    path: '/stockholder/institutionManageInfo',
    redirect: to => {
      // path: /stockholders/:id/detail/production
      return `/stockholders/${to.query.id}/detail/production`
    }
  },
  {
    path: '/stockholder/institutionOtherInfo',
    redirect: to => {
      // path: /stockholders/:id/detail/other
      return `/stockholders/${to.query.id}/detail/other`
    }
  },
  {
    path: '/stockholder/institutionRelation',
    redirect: to => {
      // path: /stockholders/:id/detail/relation
      return `/stockholders/${to.query.id}/detail/relation`
    }
  },
  {
    path: '/stockholder/institutionRisk',
    redirect: to => {
      // path: /stockholders/:id/detail/risk
      return `/stockholders/${to.query.id}/detail/risk`
    }
  },
  {
    path: '/stockholder/trendAnalysis',
    redirect: '/stockholders/trend'
  },
  {
    path: '/stockholder/institutionalShareholders',
    redirect: '/stockholders/institution/analysis'
  },
  {
    path: '/stockholder/comparativeAnalysis',
    redirect: '/stockholders/institution/comparison/analysis'
  },
  {
    path: '/stockholder/latestHoldings',
    redirect: '/stockholders/newest/shareholding'
  },
  {
    path: '/stockholder/teachingVideo',
    redirect: '/stockholders/operation-video'
  },
  // 舆情
  {
    path: '/sentiment/market-list',
    redirect: '/sentiment/market/list'
  },
  {
    path: '/sentiment/customized-list',
    redirect: '/sentiment/custom/list'
  },
  // 电子签名
  {
    path: '/electronic-signature/issue/detail',
    redirect: to => {
      // path: /electronic-signature/:signatureId/detail
      return `/electronic-signature/${to.query.signatureId}/detail`
    }
  },
  {
    path: '/electronic-signature/issue/edit',
    redirect: to => {
      // path: /electronic-signature/:signatureId/edit
      return `/electronic-signature/${to.query.signatureId}/edit`
    }
  },
  {
    path: '/electronic-signature/position/adjustment',
    redirect: to => {
      // path: /electronic-signature/:signatureId/adjust-position
      return `/electronic-signature/${to.query.signatureId}/adjust-position`
    }
  },
  // 创业板
  {
    path: '/chuangyeban',
    redirect: '/GEM'
  },
  // 公司规章制度
  {
    path: '/regulation/library',
    redirect: '/company-system/library'
  },
  {
    path: '/regulation/manage',
    redirect: '/company-system/manage'
  },
  // ESG
  {
    path: '/esg/subtask/detail',
    redirect: to => {
      return `/esg/subtask/${to.query.taskId}`
    }
  },
  {
    path: '/esg/task/detail',
    redirect: to => {
      return `/esg/task/${to.query.taskId}`
    }
  },
  // IPO
  {
    path: '/ipo/search',
    redirect: '/ipo/list'
  },
  {
    path: '/ipo/details',
    redirect: to => {
      // path: /ipo/:id
      return `/ipo/${to.query.id}`
    }
  },
  {
    path: '/ipo/retrial/overview',
    redirect: '/ipo/audit-company/overview'
  },
  {
    path: '/ipo/retrial/list',
    redirect: '/ipo/audit-company/list'
  },
  {
    path: '/ipo/will/overview',
    redirect: '/ipo/audit-company'
  },
  {
    path: '/ipo/will/list',
    redirect: '/ipo/audit-company'
  },
  {
    path: '/kechuangban/kechuangbanIpo',
    redirect: '/STAR-ipo/list'
  },
  {
    path: '/chuangyeban/chuangyebanIpo',
    redirect: '/stib-ipo/list'
  },
  {
    path: '/kechuangban/kechuangbanTrend/kcbOverview',
    redirect: '/STAR-ipo/audit-company/overview'
  },
  {
    path: '/kechuangban/kechuangbanTrend/kcbList',
    redirect: '/STAR-ipo/audit-company/list'
  },
  {
    path: '/kechuangban/main',
    redirect: '/STAR-ipo/information-column'
  },
  {
    path: '/kechuangban/expertRead',
    redirect: '/STAR-ipo/expert-interpretation'
  },
  {
    path: '/chuangyeban/main',
    redirect: '/GEM'
  },
  {
    path: '/chuangyeban/cybExpertRead',
    redirect: '/GEM/expert-interpretation'
  },
  {
    path: '/chuangyeban/news',
    redirect: '/GEM/information'
  },
  // 信披清单
  {
    path: '/disclosure/proceed',
    redirect: '/disclosure/underway-issues'
  },
  {
    path: '/disclosure/archive',
    redirect: '/disclosure/archived-issues'
  },
  // E互动
  {
    path: '/investor/company/list',
    redirect: '/investor/list'
  },
  {
    path: '/investor/search',
    redirect: '/investor/list'
  },
  {
    path: '/investor/answer',
    redirect: to => {
      // path: /investor/questions/:id
      return `/investor/questions/${to.query.id}`
    }
  },
  {
    path: '/investor/personal',
    redirect: '/investor/asker'
  },
  {
    path: '/investor/leaderboard/overview',
    redirect: '/investor/market-overview/overview'
  },
  {
    path: '/investor/leaderboard/brief',
    redirect: '/investor/market-overview/leader-board'
  },
  // 权限速查表
  {
    path: '/accessTables/mainTable',
    redirect: '/transaction-standard-lookup'
  },
  // 财务分析
  {
    path: '/finance',
    redirect: '/finance-analysis/main'
  },
  {
    path: '/finance/main',
    redirect: '/finance-analysis/main'
  },
  {
    path: '/finance/financials',
    redirect: '/finance-analysis/company-report'
  },
  {
    path: '/finance/comparison',
    redirect: '/finance-analysis/data-compare'
  },
  {
    path: '/finance/analysis',
    redirect: '/finance-analysis/annual-report'
  },
  // 小安问答
  {
    path: '/xhList',
    redirect: '/inhope-FAQ'
  },
  {
    path: '/xhQuestionDetail',
    redirect: to => {
      // path: /inhope-FAQ/questions/:id
      return `/inhope-FAQ/questions/${to.query.questionId}`
    }
  },
  {
    path: '/xhTopic',
    redirect: to => {
      // path: /inhope-FAQ/topics/:id
      return `/inhope-FAQ/topics/${to.query.topicId}`
    }
  },
  {
    path: '/inhope-FAQ/topics/:id',
    redirect: to => {
      return '/inhope-FAQ'
    }
  },
  // 知识共享平台
  {
    path: '/internalxh',
    redirect: '/knowledge-center'
  },
  {
    path: '/internalxh/main',
    redirect: '/knowledge-center'
  },
  {
    path: '/internalxh/xhAnswerDetail',
    redirect: to => {
      // path: /knowledge-center/questions/:id
      return `/knowledge-center/questions/${to.query.questionId}`
    }
  },
  // 在线测试
  {
    path: '/online-test/home',
    redirect: '/online-test'
  },
  {
    path: '/online-test/start/:id',
    redirect: to => {
      // path: /online-test/questionnaires/:id
      return `/online-test/questionnaires/${to.params.id}`
    }
  },
  {
    path: '/online-test/answer/:id',
    redirect: to => {
      // path: /online-test/questionnaires/:id/answer
      return `/online-test/questionnaires/${to.params.id}/answer`
    }
  },
  {
    path: '/online-test/result/:id',
    redirect: to => {
      // path: /online-test/questionnaires/:id/result
      return `/online-test/questionnaires/${to.params.id}/result`
    }
  },
  {
    path: '/online-test/resultDetail/:id',
    redirect: to => {
      // path: /online-test/questionnaires/:id/result-detail
      return `/online-test/questionnaires/${to.params.id}/result-detail`
    }
  },
  {
    path: '/online-test/resultCredential/:id',
    redirect: to => {
      // path: /online-test/questionnaires/:id/result-detail
      return `/online-test/questionnaires/${to.params.id}/credential`
    }
  },
  // 用户相关
  {
    path: '/index/no-permission',
    redirect: '/no-permission'
  },
  {
    path: '/personInfo',
    redirect: '/complete-personal-info'
  },
  {
    path: '/findPassword',
    redirect: '/find-password'
  },
  {
    path: '/manage/purchaseApplication',
    redirect: '/purchase-application'
  },
  {
    path: '/manage/successApplication',
    redirect: '/purchase-success'
  },
  {
    path: '/manage/changeCompany',
    redirect: '/manage/switch-company'
  },
  {
    path: '/wxTransfer',
    redirect: '/wx-transfer'
  },
  {
    path: '/follow/regulations',
    redirect: '/follow/compilations'
  },
  {
    path: '/works',
    redirect: '/creation'
  },
  {
    path: '/works/noteLaw',
    redirect: '/creation/rule-notes'
  },
  {
    path: '/works/noteList',
    redirect: to => {
      // path: /creation/rule-notes/:id/related
      return `/creation/rule-notes/${to.query.lawId}/related`
    }
  },
  {
    path: '/changeMobile',
    redirect: '/change-mobile'
  },
  {
    path: '/changeEmail',
    redirect: '/change-email'
  },
  {
    path: '/changePassword',
    redirect: '/change-password'
  },
  {
    path: '/favorite',
    redirect: '/collections'
  },
  {
    path: '/collect/collectDetails',
    redirect: to => {
      // path: /collections/:id
      return `/collections/${to.query.id}`
    }
  },
  {
    path: '/collect/addCollect',
    redirect: '/collections/add'
  },
  {
    path: '/admin/organize-member-manage',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/members',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/application',
    redirect: '/admin/application-management'
  },
  {
    path: '/admin/members/manage',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/user-management/members',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/members/organization',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/user-management/organization',
    redirect: '/admin/organization-member-manage'
  },
  {
    path: '/admin/application/compare',
    redirect: '/admin/application-management/compare-companies'
  },
  {
    path: '/admin/application/collect',
    redirect: '/admin/application-management/team-collections'
  },
  {
    path: '/admin/application/setEmail',
    redirect: '/admin/application-management/set-email'
  },
  {
    path: '/admin/application/addCollect',
    redirect: '/admin/application-management/add-collection'
  },
  {
    path: '/online-service/index',
    redirect: '/online-service'
  },
  {
    path: '/admin/application-management/team-collections',
    redirect: '/collections'
  },
  {
    path: '/admin/application-management/add-collection',
    redirect: '/collections'
  },
  {
    path: '/collections/add',
    redirect: '/collections'
  },
  // 舆情模块 跳转至 全网舆情
  {
    path: '/sentiment/report',
    redirect: '/sentiment/all-net/list'
  },
  {
    path: '/sentiment/plans',
    redirect: '/sentiment/all-net/list'
  },
  {
    path: '/ai-compliance-assistant',
    redirect: '/ai-assistant'
  },
  {
    path: '/choiceness',
    redirect: '/index'
  },
  {
    path: '/investor/company/overview',
    redirect: '/investor/list'
  },
  // IPO 跳转至 IPO案例库列表页
  {
    path: '/ipo-entry',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/company-being-reviewed',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/company-being-reviewed/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/company-being-reviewed/overview',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/audit-company',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/audit-company/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/audit-company/overview',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo/:id',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/information-column',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/expert-interpretation',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/kechuangban/info',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/audit-company',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/audit-company/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/audit-company/overview',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/chuangyeban/info',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo/audit-company',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo/audit-company/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo/audit-company/overview',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/STAR-ipo/:id',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/stib-ipo/:id',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo/audit-company',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo/audit-company/overview',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo/audit-company/list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse/info',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/bse-ipo/:id',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo-case-library-list',
    redirect: '/ipo-case-libraries'
  },
  {
    path: '/ipo-case-library-detail',
    redirect: to => {
      // path: /ipo-case-libraries/:id
      return `/ipo-case-libraries/${to.query.id}`
    }
  },
  {
    path: '/document-review',
    redirect: '/announcement-review'
  }
]

export default redirectUrl
