/*
 * @Description: esg公共数据
 * @Author: hailong.qi
 */

import gongsigaikuang from './esg/gongsigaikuang'
import gongsizhili from './esg/gongsizhili'
import esgguanli from './esg/esgguanli'
import yewuyunying from './esg/yewuyunying'
import yuangong from './esg/yuangong'
import huanjingyingxiang from './esg/huanjingyingxiang'
import zhiyejiankanganquan from './esg/zhiyejiankanganquan'
import gongyinglianguanli from './esg/gongyinglianguanli'
import shequgongyi from './esg/shequgongyi'
import chanpinhefuwu from './esg/chanpinhefuwu'

const esg = {
  state: {
    currentIssueData: [],
    taskId: '',
    gongsigaikuang: gongsigaikuang,
    gongsizhili: gongsizhili,
    esgguanli: esgguanli,
    yewuyunying: yewuyunying,
    yuangong: yuangong,
    huanjingyingxiang: huanjingyingxiang,
    zhiyejiankanganquan: zhiyejiankanganquan,
    gongyinglianguanli: gongyinglianguanli,
    shequgongyi: shequgongyi,
    chanpinhefuwu: chanpinhefuwu
  },
  getters: {
    CURRENT_ISSUE_DATA: state => state.currentIssueData,
    TASK_ID: state => state.taskId,
    GONG_SI_GAI_KUANG: state => state.gongsigaikuang,
    GONG_SI_ZHI_LI: state => state.gongsizhili,
    ESG_GUAN_LI: state => state.esgguanli,
    YE_WU_YUN_YING: state => state.yewuyunying,
    YUAN_GONG: state => state.yuangong,
    HUAN_JING_YING_XIANG: state => state.huanjingyingxiang,
    ZHI_YE_JIAN_KANG_AN_QUAN: state => state.zhiyejiankanganquan,
    GONG_YING_LIAN_GUAN_LI: state => state.gongyinglianguanli,
    SHE_QU_GONG_YI: state => state.shequgongyi,
    CHAN_PIN_HE_FU_WU: state => state.chanpinhefuwu
  },
  mutations: {
    CURRENT_ISSUE_DATA: (state, data) => {
      state.currentIssueData = data
    },
    TASK_ID: (state, data) => {
      state.taskId = data
    }
  }
}

export default esg
