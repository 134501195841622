import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 内部信乎搜索
  search_internal_wiki_questions(params) {
    return Axios({ method: 'POST', url: api.knowledgeCenter.search_internal_wiki_questions, data: params })
  },
  // 近期关注问题
  latest_attentions_questions(params) {
    return Axios({ method: 'GET', url: api.knowledgeCenter.latest_attentions_questions, params: params })
  },
  // 获取所有板块
  internal_wiki_get_plates(params) {
    return Axios({ method: 'GET', url: api.knowledgeCenter.internal_wiki_get_plates, params: params })
  },
  // 获取所有类别
  get_questions_types(params) {
    return Axios({ method: 'GET', url: api.knowledgeCenter.get_questions_types, data: params })
  },
  // 关键字自动补全
  auto_complete_question_title(params) {
    return Axios({ method: 'POST', url: api.knowledgeCenter.auto_complete_question_title, data: params })
  },
  // 答案详情页
  // 写回答
  write_answer(params, questionId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.knowledgeCenter.write_answer, [['questionId', questionId]]), data: params, originUrl: api.knowledgeCenter.write_answer })
  },
  // 答案详情列表
  get_answers_by_questionId(params, questionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.knowledgeCenter.get_answers_by_questionId, [['questionId', questionId]]), params: params, originUrl: api.knowledgeCenter.get_answers_by_questionId })
  },
  // 修改答案
  modify_answer(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.knowledgeCenter.operate_answer, [['id', id]]), data: params, originUrl: api.knowledgeCenter.operate_answer })
  },
  // 删除答案
  del_answer(params, id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.knowledgeCenter.operate_answer, [['id', id]]), data: params, originUrl: api.knowledgeCenter.operate_answer })
  },
  // 问题模块
  // 创建问题
  create_question(params) {
    return Axios({ method: 'POST', url: api.knowledgeCenter.create_question, data: params })
  },
  // 修改问题
  modify_question(params, questionId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.knowledgeCenter.operate_question, [['questionId', questionId]]), data: params, originUrl: api.knowledgeCenter.operate_question })
  },
  // 删除问题
  delete_question(params, questionId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.knowledgeCenter.operate_question, [['questionId', questionId]]), data: params, originUrl: api.knowledgeCenter.operate_question })
  },
  // 问题详情
  question_detail(params, questionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.knowledgeCenter.question_detail, [['questionId', questionId]]), params: params, originUrl: api.knowledgeCenter.question_detail })
  },
  // 获取相关问题
  get_related_questions(params, questionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.knowledgeCenter.get_related_questions, [['questionId', questionId]]), params: params, originUrl: api.knowledgeCenter.get_related_questions })
  },
  // 取消关注问题
  cancle_attention_question(questionId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.knowledgeCenter.attention_question, [['questionId', questionId]]), originUrl: api.knowledgeCenter.attention_question })
  },
  // 关注问题
  attention_question(params, questionId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.knowledgeCenter.attention_question, [['questionId', questionId]]), params: params, originUrl: api.knowledgeCenter.attention_question })
  },
  get_all_questioner(params) {
    return Axios({ method: 'GET', url: api.knowledgeCenter.get_all_questioner, params: params })
  },
  get_all_answerer(params) {
    return Axios({ method: 'GET', url: api.knowledgeCenter.get_all_answerer, params: params })
  }
}
