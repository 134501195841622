import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
import Storage from '@/service/storage'

export default {
  // 通过关键字（简称/代码/拼音首字母）模糊查找AB股主板公司（沪深北板块，包括退市公司）（匿名接口），包括退市公司和拟上市公司（有code）
  companies_auto_complete(params) {
    return Axios({ method: 'GET', url: api.affiche.companies_auto_complete, params: params })
  },
  get_related_company(params) {
    return Axios({ method: 'GET', url: api.affiche.get_related_company, params: params })
  },
  // 获取年份列表
  get_announcement_year_list(params) {
    return Axios({ method: 'GET', url: api.affiche.get_announcement_year_list, params: params })
  },
  // 根据generateId查询主键
  get_annual_reports(params) {
    return Axios({ method: 'GET', url: api.affiche.get_annual_reports, params: params })
  },
  // 下载公告
  download_annual_report(params) {
    return Axios({ method: 'GET', url: api.affiche.download_annual_report, params: params })
  },
  // 删除公告合集
  delete_annual_report(params) {
    return Axios({ method: 'DELETE', url: api.affiche.delete_annual_report, params: params })
  },
  //  公告查询
  announcements_dashboard_search(params) {
    return Axios({ method: 'POST', url: Storage.isLogin() ? api.affiche.announcements_dashboard_search : api.affiche.announcements_dashboard_search_anonymous, data: params })
  },
  // 公告筛选条件
  get_affiche_search_contidion() {
    return Axios({ method: 'GET', url: api.affiche.get_affiche_search_contidion })
  },
  //  公告分类目录树
  get_announcements_category() {
    return Axios({ method: 'GET', url: api.affiche.get_announcements_category })
  },
  //  评级信息查询
  rating_infos() {
    return Axios({ method: 'GET', url: api.affiche.rating_infos })
  },
  // 公告详情
  get_announcement_detail_by_id(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.affiche.get_announcement_detail_by_id, [['id', id]]), originUrl: api.affiche.get_announcement_detail_by_id })
  },
  batch_download_announcement(data, params) {
    return Axios({ method: 'POST', url: api.affiche.batch_download_announcement, data: data, params: params })
  },
  //  批量查询公告摘要
  dashboard_search_abstract_by_id(params) {
    return Axios({ method: 'POST', url: api.affiche.dashboard_search_abstract_by_id, data: params })
  },
  //  获取公告内容接口
  announcements_content(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.affiche.announcements_content, [['id', id]]) })
  },
  vague_affiche_search(params) {
    return Axios({ method: 'POST', url: api.affiche.vague_affiche_search, data: params })
  },
  vague_adjacent_announcement_search(params) {
    return Axios({ method: 'POST', url: api.affiche.vague_adjacent_announcement_search, data: params })
  },
  vague_same_company_aggregation(params) {
    return Axios({ method: 'POST', url: api.affiche.vague_same_company_aggregation, data: params })
  },
  vague_dashboard_search_abstract_by_id(params) {
    return Axios({ method: 'POST', url: api.affiche.vague_dashboard_search_abstract_by_id, data: params })
  }

}
