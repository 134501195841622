const axiosFiles = require.context('./../axios', true, /\.js$/)

const modules = axiosFiles.keys().reduce((modules, modulePath) => {
  const filename = modulePath.replace(/^\.\/(.*)\/.*$/, '$1')
  const value = axiosFiles(modulePath)
  modules[filename] = value.default
  return modules
}, {})

export default modules
