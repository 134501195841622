
const zhiyejiankanganquan = [
  {
    subIssueTitle: '管理方针',
    subIssueTooltipContent: `对应GRI标准: 103<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: B2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '职业健康安全的管理方针及其要素，包括：a.政策；b.承诺；c.目标；d.责任；e.特定的行动（如流程、专案及倡议等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '职业健康安全管理体系',
    subIssueTooltipContent: `对应GRI标准: 403-1, 403-8<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '职业健康安全的管理体系，及该管理体系所覆盖的工作者、活动和工作场所范围，并指出该管理体系是否经第三方审核或认证。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '非员工：非公司正式员工，但工作和/或工作场所受组织控制的工作者',
        taskYear: '2020',
        indicatorDataList: [{ title: '管理体系覆盖的所有员工（以及非员工）的工作者数量', key: 'OCCUPATIONAL_HEALTH_SAFETY_1', unit: '人', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '劳资方健康安全委员会',
    subIssueTooltipContent: `对应GRI标准: 403-4<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '健康安全委员会的责任、管理团队规模与资质、员工及非员工参与人数、参与方式与渠道、会议频率、决策权限等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '健康安全委员会成员组成',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '管理团队资质及数量', key: 'OCCUPATIONAL_HEALTH_SAFETY_2', unit: '人', yearDataList: ['', '', ''] },
          { title: '劳工代表组成及数量', key: 'OCCUPATIONAL_HEALTH_SAFETY_3', unit: '人', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '危害识别、风险评估和实践调查',
    subIssueTooltipContent: `对应GRI标准: 403-2<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '识别工作相关危害和评估风险的常规和非常规流程，以及消除危害并将风险最小化的流程，及年度典型案例。包括环境和职业健康于安全应急预案等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '工作场所保护',
    subIssueTooltipContent: `对应GRI标准: 403-7, 403-9, 403-10<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: B2.2, B2.3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: `保障工作人员健康安全的管理措施、成效及典型案例，包括加强劳保用品防护、提升设备健康与安全性能、改善工作环境、必要安全信息提示等。<br>
        保障特定工作人员健康安全的管理举措、成效及典型案例，包括对女性（例如孕妇、产妇或乳母）、残疾人等。`,
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '工作场所保护投入费用',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '劳保用品投入费用', key: 'OCCUPATIONAL_HEALTH_SAFETY_4', unit: '元', yearDataList: ['', '', ''] },
          { title: '改善工作环境软、硬件设施投入费用', key: 'OCCUPATIONAL_HEALTH_SAFETY_5', unit: '元', yearDataList: ['', '', ''] },
          { title: '设备维修、更换新设备所投入费用', key: 'OCCUPATIONAL_HEALTH_SAFETY_6', unit: '元', yearDataList: ['', '', ''] },
          { title: '其他用于工作场所保护的投入费用', key: 'OCCUPATIONAL_HEALTH_SAFETY_7', unit: '元', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '预防和减少与工作直接相关的职业病的管理举措、成效及典型案例，职业病包括生理和心理等范畴。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '安全事故数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '每年因工亡故的人数', key: 'OCCUPATIONAL_HEALTH_SAFETY_8', unit: '人', yearDataList: ['', '', ''] },
          { title: '每年因工亡故人数占比', key: 'OCCUPATIONAL_HEALTH_SAFETY_9', unit: '%', yearDataList: ['', '', ''] },
          { title: '因工伤损失的工作日数', key: 'OCCUPATIONAL_HEALTH_SAFETY_10', unit: '人·天', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '非工作场所保护',
    subIssueTooltipContent: `对应GRI标准: 403-6<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '为应对与工作无关的重大健康风险，向工作者提供的任何资源参与的健康促进服务和计划，成效及典型案例。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '职业健康安全能力建设',
    subIssueTooltipContent: `对应GRI标准: 403-5<br>
      对应MSCI ESG指标: 健康&安全<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '提升员工工作职业健康与安全意识、能力等相关活动，如职业健康安全培训、安全演练、安全知识问答比赛等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '职业健康安全培训',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '培训主题及次数', key: 'OCCUPATIONAL_HEALTH_SAFETY_11', unit: '次', yearDataList: ['', '', ''] },
          { title: '培训覆盖的员工数量', key: 'OCCUPATIONAL_HEALTH_SAFETY_12', unit: '人', yearDataList: ['', '', ''] },
          { title: '培训覆盖的员工占比', key: 'OCCUPATIONAL_HEALTH_SAFETY_13', unit: '%', yearDataList: ['', '', ''] },
          { title: '培训投入费用', key: 'OCCUPATIONAL_HEALTH_SAFETY_14', unit: '元', yearDataList: ['', '', ''] }]
      }]
    }]
  }
]

export default zhiyejiankanganquan
