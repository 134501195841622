const DEFAULT_PREVENTRECLICK_TIME = 500 // 防连点默认时间
const xss = require('xss')
const xssOptions = {
  whiteList: {
    span: ['style', 'class'],
    br: []
  }
}
const xssBlacklistOptions = {
  whiteList: {
    a: []
  },
  onTag: function (tag, html, options) {
    if (options.isWhite) {
      return ''
    } else {
      return html
    }
  }
}

export default {
  openDirective(Vue) {
    Vue.directive('preventReClick', {
      inserted(el, bind) {
        let isClickEnabled = true
        el.addEventListener('click', function () {
          if (isClickEnabled) {
            isClickEnabled = false
            el.style.pointerEvents = 'none'
            setTimeout(function () {
              isClickEnabled = true
              el.style.pointerEvents = 'auto'
            }, bind.value || DEFAULT_PREVENTRECLICK_TIME)
          }
        })
      }
    })
    Vue.directive('htmlWithXssFilter', {
      bind(el, { value }) {
        el.innerHTML = xss(value, xssOptions)
      },
      update(el, { value }) {
        el.innerHTML = xss(value, xssOptions)
      }
    })
    Vue.directive('htmlXssFilterTag', {
      bind(el, { value }) {
        el.innerHTML = xss(value, xssBlacklistOptions)
      },
      update(el, { value }) {
        el.innerHTML = xss(value, xssBlacklistOptions)
      }
    })
  }
}
