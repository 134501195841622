import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取团队默认收藏夹
  get_team_default_favorite(params) {
    return Axios({ method: 'GET', url: api.teamFavourite.get_team_default_favorite, params: params })
  },
  // 根据用户Id获取收藏夹
  get_favourite_by_userId(params) {
    return Axios({ method: 'GET', url: api.teamFavourite.get_favourite_by_userId, params: params })
  },
  // 根据Id获取收藏夹信息
  get_team_favourite_info_by_id(favouriteId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.teamFavourite.get_team_favourite_info_by_id, [['favouriteId', favouriteId]]), originUrl: api.teamFavourite.get_team_favourite_info_by_id })
  },
  // 编辑团队收藏夹
  update_favourite_name(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.teamFavourite.users_favourites, [['id', id]]), params: params, originUrl: api.teamFavourite.users_favourites })
  },
  // 删除团队收藏夹
  delete_team_favourites_by_id(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.teamFavourite.users_favourites, [['id', id]]), originUrl: api.teamFavourite.users_favourites })
  },
  // 新建团队收藏夹
  add_team_favourite(params) {
    return Axios({ method: 'POST', url: api.teamFavourite.add_team_favourite, params: params })
  }
}
