import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  import_members(params) {
    return Axios({ method: 'POST', url: api.manage.import_members, data: params })
  },
  // 企业管理
  get_org_info_by_id(companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.get_org_info_by_id, [['companyId', companyId]]), originUrl: api.manage.get_org_info_by_id })
  },
  enterprise_edition_apply(params) {
    return Axios({ method: 'POST', url: api.manage.enterprise_edition_apply, params: params })
  },
  search_org_users(companyId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.search_org_users, [['companyId', companyId]]), params: params, originUrl: api.manage.search_org_users })
  },
  get_org_users(params, companyId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.manage.get_org_users, [['companyId', companyId]]), data: params, originUrl: api.manage.get_org_users })
  },
  add_orgs_users(data, params) {
    return Axios({ method: 'POST', url: api.manage.add_orgs_users, originUrl: api.manage.add_orgs_users, data, params })
  },
  edit_orgs_users(params, companyId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.manage.edit_orgs_users, [['companyId', companyId]]), data: params, originUrl: api.manage.edit_orgs_users })
  },
  delete_orgs_users(companyId, removeUserId, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.manage.delete_orgs_users, [['companyId', companyId], ['removeUserId', removeUserId]]), params, originUrl: api.manage.delete_orgs_users })
  },
  // 记录用户页面使用数据
  browsing_records(params) {
    return Axios({ method: 'PUT', url: api.manage.browsing_records, params: params })
  },
  // 查询企业机构
  get_enterprise_orgs(companyId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.get_enterprise_orgs, [['companyId', companyId]]), originUrl: api.manage.get_enterprise_orgs, params: params })
  },
  // 添加企业机构
  add_enterprise_orgs(params) {
    return Axios({ method: 'POST', url: api.manage.add_enterprise_orgs, data: params })
  },
  // 删除组织机构
  delete_enterprise_orgs(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.manage.operate_enterprise_orgs, [['id', id]]), originUrl: api.manage.operate_enterprise_orgs })
  },
  // 编辑组织机构
  edit_enterprise_orgs(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.manage.operate_enterprise_orgs, [['id', id]]), data: params, originUrl: api.manage.operate_enterprise_orgs })
  },
  // 获取用户公司列表
  get_user_orgs(params) {
    return Axios({ method: 'GET', url: api.manage.get_user_orgs, params: params })
  },
  // 获取身份列表
  get_org_identities(params) {
    return Axios({ method: 'GET', url: api.manage.get_org_identities, params: params })
  },
  // 获取企业用户组列表
  get_user_groups_by_id(params, organizationId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.get_user_groups_by_id, [['organizationId', organizationId]]), params: params, originUrl: api.manage.get_user_groups_by_id })
  },
  // 获取用户组及管理员信息
  get_user_groups_and_admins(params) {
    return Axios({ method: 'GET', url: api.manage.get_user_groups_and_admins, params: params })
  },
  // 根据手机号获取成员信息
  get_member_info_by_mobile(companyId, mobile) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.manage.get_member_info_by_mobile, [['companyId', companyId], ['mobile', mobile]]),
      originUrl: api.manage.get_member_info_by_mobile
    })
  },
  // 获取用户套餐
  get_users_profiles() {
    return Axios({ method: 'GET', url: api.manage.get_users_profiles })
  },
  // 切换套餐
  change_profiles(profileId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.manage.change_profiles, [['profileId', profileId]]), originUrl: api.manage.change_profiles })
  },
  // 编辑成员手机号码
  edit_users_mobile(data, companyId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.manage.edit_users_mobile, [['companyId', companyId]]), data, originUrl: api.manage.edit_users_mobile, params })
  },
  save_event_tracking(data) {
    return Axios({ method: 'POST', url: api.manage.save_event_tracking, data })
  },
  // 解析名册文件
  import_member_analysis(params, fileAnalysisId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.import_member_analysis, [['fileAnalysisId', fileAnalysisId]]), params: params, originUrl: api.manage.import_member_analysis })
  },
  // 获取最新的解析文件id
  analysis_task_ids_latest() {
    return Axios({ method: 'GET', url: api.manage.analysis_task_ids_latest })
  },
  // 获取文件解析结果
  import_member_analysis_result(fileAnalysisId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.import_member_analysis_result, [['fileAnalysisId', fileAnalysisId]]), originUrl: api.manage.import_member_analysis_result })
  },
  // 获取所属板块
  equitychange_market() {
    return Axios({ method: 'GET', url: api.manage.equitychange_market })
  },
  // 获取业务/产品类型
  equitychange_business_type() {
    return Axios({ method: 'GET', url: api.manage.equitychange_business_type })
  },
  // 项目列表
  equitychange_project(params) {
    return Axios({ method: 'GET', url: api.manage.equitychange_project, params: params })
  },
  // 获取项目详情
  equitychange_project_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.equitychange_project_detail, [['id', id]]), originUrl: api.manage.equitychange_project_detail })
  },
  // 项目角色列表
  equitychange_project_role(params) {
    return Axios({ method: 'GET', url: api.manage.equitychange_project_role, params: params })
  },
  equity_distribuion(id, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.manage.equity_distribution, [['id', id]]), originUrl: api.manage.equity_distribution, params })
  }

}
