
const meeting = {
  state: {
    meetingDetailInfo: {
      name: '',
      temporary: false,
      sessionNum: 1,
      sequenceNum: 1,
      conveneForm: 1,
      contactUserId: '',
      contactUserName: '',
      conveneTime: '',
      notificationTime: '',
      meetingViewPermissionEnum: 'PUBLIC',
      designeeList: []
    },
    canVoteParticipantInfoList: [],
    noVoteParticipantInfoList: [],
    meetingProposalInfoList: [],
    presentLawyerInfoList: [],
    holdMeeting: [],
    voteList: [],
    participantInfo: {
      canVoteParticipant: [],
      presentLawyer: [],
      noVoteParticipant: []
    },
    fileList: [], // 会议资料
    proposalList: [] // 议案资料
  },
  getters: {
    MEETING_DETAIL_INFO: state => state.meetingDetailInfo,
    CAN_VOTE_PARTICIPANT_INFO_LIST: state => state.canVoteParticipantInfoList,
    NO_VOTE_PARTICIPANT_INFO_LIST: state => state.noVoteParticipantInfoList,
    MEETING_PROPOSAL_INFO_LIST: state => state.meetingProposalInfoList,
    PRESENT_LAWYER_INFO_LIST: state => state.presentLawyerInfoList,
    HOLD_MEETING: state => state.holdMeeting,
    VOTE_LIST: state => state.voteList,
    PARTICIPANT_INFO: state => state.participantInfo,
    FILE_LIST: state => state.fileList,
    PROPOSAL_LIST: state => state.proposalList
  },
  mutations: {
    MEETING_DETAIL_INFO: (state, data) => {
      state.meetingDetailInfo = data
    },
    CAN_VOTE_PARTICIPANT_INFO_LIST: (state, data) => {
      state.canVoteParticipantInfoList = data
    },
    NO_VOTE_PARTICIPANT_INFO_LIST: (state, data) => {
      state.noVoteParticipantInfoList = data
    },
    MEETING_PROPOSAL_INFO_LIST: (state, data) => {
      state.meetingProposalInfoList = data
    },
    PRESENT_LAWYER_INFO_LIST: (state, data) => {
      state.presentLawyerInfoList = data
    },
    HOLD_MEETING: (state, data) => {
      state.holdMeeting = data
    },
    VOTE_LIST: (state, data) => {
      state.voteList = data
    },
    PARTICIPANT_INFO: (state, data) => {
      state.participantInfo = data
    },
    FILE_LIST: (state, data) => {
      state.fileList = data
    },
    PROPOSAL_LIST: (state, data) => {
      state.proposalList = data
    }
  }
}

export default meeting
