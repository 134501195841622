import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取监控列表
  get_company_monitor_rules(params) {
    return Axios({ method: 'GET', url: api.monitor.get_company_monitor_rules, params })
  },
  // 获取未启用监控助手数量
  get_disable_rules_nums(params) {
    return Axios({ method: 'GET', url: api.monitor.get_disable_rules_nums, params })
  },
  // 修改监控规则开启状态
  put_company_monitor_rules_status(id, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.monitor.put_company_monitor_rules_status, [['id', id]]), params, originUrl: api.monitor.put_company_monitor_rules_status })
  },
  // 获取监控规则
  get_monitor_rule_template(id, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.monitor.get_monitor_rule_template, [['id', id]]), params, originUrl: api.monitor.get_monitor_rule_template })
  },
  // 重名检测
  check_monitor_name(params) {
    return Axios({ method: 'GET', url: api.monitor.check_monitor_name, params })
  },
  // 校验监控是否存在
  check_monitor_existence(params) {
    return Axios({ method: 'GET', url: api.monitor.check_monitor_existence, params })
  },
  // 获取新的规则
  get_monitor_rule_template_change(data) {
    return Axios({ method: 'POST', url: api.monitor.get_monitor_rule_template_change, data })
  },
  // 查看指标常用场景
  get_indicator_usage(params) {
    return Axios({ method: 'GET', url: api.monitor.get_indicator_usage, params })
  },
  // 保存监控规则
  save_monitor_rule(data) {
    return Axios({ method: 'POST', url: api.monitor.save_monitor_rule, data })
  },
  // 删除自定义监控
  delete_monitor_rule(id, params) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.monitor.delete_monitor_rule, [['id', id]]), params, originUrl: api.monitor.delete_monitor_rule })
  },
  // 监控中心列表获取
  get_monitor_center_list(data) {
    return Axios({ method: 'POST', url: api.monitor.get_monitor_center_list, data })
  },
  // 监控提醒详情
  get_monitor_detail(params) {
    return Axios({ method: 'GET', url: api.monitor.get_monitor_detail, params })
  },
  // 获取区间交易数据
  get_range_transaction_data(params) {
    return Axios({ method: 'GET', url: api.monitor.get_range_transaction_data, params })
  },
  // 区间交易数据-详情
  get_range_transaction_detail(params) {
    return Axios({ method: 'GET', url: api.monitor.get_range_transaction_detail, params })
  },
  // 异常事件数据查询
  get_abnormal_event_data(data) {
    return Axios({ method: 'POST', url: api.monitor.get_abnormal_event_data, data })
  },
  // 参考指标数据
  get_monitor_indicator_data(params) {
    return Axios({ method: 'GET', url: api.monitor.get_monitor_indicator_data, params })
  },
  // 获取指标常用场景
  get_monitor_data_usage(params) {
    return Axios({ method: 'GET', url: api.monitor.get_monitor_data_usage, params })
  },
  // 区间交易数据-导出
  monitor_data_range_export(params) {
    return Axios({ method: 'GET', url: api.monitor.monitor_data_range_export, params })
  },
  // 下载行情数据
  download_monitor_data(params) {
    return Axios({ method: 'GET', url: api.monitor.download_monitor_data, params })
  }
}
