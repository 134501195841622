
const chanpinhefuwu = [
  {
    subIssueTitle: '管理方针',
    subIssueTooltipContent: `对应GRI标准: 103<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B6`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '客户服务（产品质量与安全等）的管理方针及其要素，包括：a.政策；b.承诺；c.目标；d.责任；e.特定的行动（如流程、专案及倡议等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '质量管理体系',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '产品与服务质量的管理体系，及该管理体系所覆盖的地域范围等，并指出该管理体系是否经第三方审核或认证。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '管理工具',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '客户服务管理所采用的的系统，及其所遵守的标准/规范。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '知识产权',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B6.3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '维护及保障知识产权有关的制度及措施。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '知识产品相关数据绩效',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '申请的知识产权数量', key: 'PRODUCT_SERVICE_1', unit: '个', yearDataList: ['', '', ''] },
          { title: '授权的知识产权数量', key: 'PRODUCT_SERVICE_2', unit: '个', yearDataList: ['', '', ''] },
          { title: '申请注册的商标数量', key: 'PRODUCT_SERVICE_3', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '产品和服务的质量与安全',
    subIssueTooltipContent: `对应GRI标准: 416-1, 416-2<br>
      对应MSCI ESG指标: 产品安全&产品质量, 化学品安全<br>
      对应港交所ESG指引: B6.1, B6.4`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '对产品和服务类别的健康与安全影响的评估。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '评估比例',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '评估其健康与安全影响得到改善的重要产品或服务类别的百分比', key: 'PRODUCT_SERVICE_4', unit: '%', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '产品质量管理方针、管理活动及成效等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '质量管理活动成效',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '开展质量培训的次数', key: 'PRODUCT_SERVICE_5', unit: '次', yearDataList: ['', '', ''] },
          { title: '质量培训覆盖人数', key: 'PRODUCT_SERVICE_6', unit: '人次', yearDataList: ['', '', ''] },
          { title: '产品合格率', key: 'PRODUCT_SERVICE_7', unit: '%', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '产品召回',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '召回产品种类/数量', key: 'PRODUCT_SERVICE_8', unit: '件', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '违规事件数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '因产品和服务的质量与安全而受到罚款或处罚的事件数量', key: 'PRODUCT_SERVICE_9', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '客户服务',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B6.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '客户沟通渠道。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '产品和服务客户投诉的应对办法，包括制度、流程等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '客户服务',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '客户服务响应时间', key: 'PRODUCT_SERVICE_10', unit: '小时', yearDataList: ['', '', ''] },
          { title: '客户满意度', key: 'PRODUCT_SERVICE_11', unit: '%', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '客户隐私保护',
    subIssueTooltipContent: `对应GRI标准: 418-1<br>
      对应MSCI ESG指标: 隐私数据安全, 金融产品安全<br>
      对应港交所ESG指引: B6.5`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '合法获得客户信息。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '保护客户隐私（包括消费者信息等）安全的制度和措施。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '违规事件数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '因泄露、盗窃或丢失客户资料而受到的投诉、罚款或处罚的事件数量', key: 'PRODUCT_SERVICE_12', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '营销与标识',
    subIssueTooltipContent: `对应GRI标准: 417-1, 417-2, 417-3<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '产品信息和标识方面所遵守的法律法规，及合规概况。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '产品的组成、安全使用、处置对环境或社会的影响等的说明。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '违规事件统计数量统计及说明',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '因营销与标识而受到罚款或处罚的事件数量', key: 'PRODUCT_SERVICE_13', unit: '件', yearDataList: ['', '', ''] }]
      }]
    }]
  }
]

export default chanpinhefuwu
