import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 查询历史的云译文件翻译数据
  get_translate_file(params) {
    return Axios({ method: 'GET', url: api.yunyi.translate_file, params: params })
  },
  // 删除云译翻译文件
  deleteTranslateFile(params, cloudId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.yunyi.delete_translate_file, [['cloudId', cloudId]]), params: params, originUrl: api.yunyi.delete_translate_file })
  },
  add_evaluate(params) {
    return Axios({ method: 'POST', url: api.yunyi.add_evaluate, params: params })
  },
  // 获取用户使用云译的剩余可用字符数
  remaining_str(params) {
    return Axios({ method: 'GET', url: api.yunyi.remaining_str, params: params })
  },
  // 获取token信息
  trans_wai_token() {
    return Axios({ method: 'GET', url: api.yunyi.trans_wai_token })
  },
  // 云译页面数据埋点接口
  trans_statics(params) {
    return Axios({ method: 'POST', url: api.yunyi.trans_statics, data: params })
  },
  // 获取购买链接
  trans_purchase_url() {
    return Axios({ method: 'GET', url: api.yunyi.trans_purchase_url })
  },
  // 获取云译同传跳转url
  trans_tongchuan_url() {
    return Axios({ method: 'GET', url: api.yunyi.trans_tongchuan_url })
  },
  // 翻译上传的文件
  misc_translation_file(params) {
    return Axios({ method: 'POST', url: api.yunyi.misc_translation_file, data: params })
  }
}
