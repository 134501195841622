
const inquiryLetter = {
  state: {
    inquiryTypeTreeKey: '',
    inquiryTagTreeKey: '',
    inquiryTypeTreeSelectModel: 'single',
    inquiryTagTreeSelectModel: 'single',
    inquirySearchLoading: false,
    inquiryTypeTreeRenderKey: 0,
    tagTreeRenderKey: 0
  },
  getters: {
    INQUIRY_TYPE_TREE_KEY: state => state.inquiryTypeTreeKey,
    INQUIRY_TAG_TREE_KEY: state => state.inquiryTagTreeKey,
    INQUIRY_TYPE_TREE_SELECT_MODEL: state => state.inquiryTypeTreeSelectModel,
    INQUIRY_TAG_TREE_SELECT_MODEL: state => state.inquiryTagTreeSelectModel,
    INQUIRY_SEARCH_LOADING: state => state.inquirySearchLoading,
    INQUIRY_TYPE_TREE_RENDER_KEY: state => state.inquiryTypeTreeRenderKey,
    TAG_TREE_RENDER_KEY: state => state.tagTreeRenderKey
  },
  mutations: {
    INQUIRY_TYPE_TREE_KEY: (state, data) => {
      state.inquiryTypeTreeKey = data
    },
    INQUIRY_TAG_TREE_KEY: (state, data) => {
      state.inquiryTagTreeKey = data
    },
    INQUIRY_TYPE_TREE_SELECT_MODEL: (state, data) => {
      state.inquiryTypeTreeSelectModel = data
    },
    INQUIRY_TAG_TREE_SELECT_MODEL: (state, data) => {
      state.inquiryTagTreeSelectModel = data
    },
    INQUIRY_SEARCH_LOADING: (state, data) => {
      state.inquirySearchLoading = data
    },
    INQUIRY_TYPE_TREE_RENDER_KEY: (state, data) => {
      state.inquiryTypeTreeRenderKey = data
    },
    TAG_TREE_RENDER_KEY: (state, data) => {
      state.tagTreeRenderKey = data
    }
  }
}

export default inquiryLetter
