<!--
  保持el-card原有属性:header、body-style、shadow
  新增属性:header-style(主要功能是用于设置是否省略底边框)、pat-color
-->
<template>
  <el-card
    :shadow="shadow"
    :bodyStyle="bodyStyle"
    class="highlight-subtitle"
    :class="showBottomLine ? 'bottom-divider' : ''"
  >
    <div slot="header" class="header" :style="headerStyle">
      <div class="pat" :style="{ backgroundColor: patColor }"></div>
      <div v-if="header">{{ header }}</div>
      <slot v-else name="header"></slot
      ><!-- 如果header内容有多部分, 使用标签包裹一下并设置width:100% -->
    </div>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  name: 'VxaCard',
  props: {
    patColor: {
      type: String,
      default: '#ffb148'
    },
    shadow: {
      type: String,
      default: 'never'
    },
    header: {
      type: String,
      default: ''
    },
    bodyStyle: {
      type: Object,
      default: () => ({ padding: '6px 0px 0px 0px' }) // 内容区与header文字间留20px间隙,其余部分不要留空
    },
    headerStyle: {
      //* 不需要默认的底边框时,可传入空对象
      type: Object,
      default: () => ({ borderBottom: '1px solid #e3e3e3' })
    },
    showBottomLine: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  .pat {
    width: 4px;
    height: 16px;
    margin-right: 8px;
    border-radius: 2px;
  }
}

.el-card {
  border: none;
  border-radius: 0px;
  /deep/ .el-card__header {
    padding: 0;
    border: none;
    height: 44px;
    display: flex;
    align-items: center;
  }
}
.bottom-divider {
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    margin-top: 24px;
    background-color: #eeeeee;
    display: block;
  }
}
</style>
