import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 提交问题
  submit_question(params) {
    return Axios({ method: 'POST', url: api.complianceAssistant.submit_question, data: params })
  },
  // 合规助手提交问题
  submit_law_question(params) {
    return Axios({ method: 'POST', url: api.complianceAssistant.submit_law_question, data: params })
  },
  // 停止生成
  cancel_generate(answerId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.complianceAssistant.cancel_generate, [['answerId', answerId]]), originUrl: api.complianceAssistant.cancel_generate })
  },
  // 会话列表
  session_list(type) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.complianceAssistant.session_list, [['type', type]]), originUrl: api.complianceAssistant.session_list })
  },
  // 获取剩余提问次数
  get_remain_count(type) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.complianceAssistant.get_remain_count, [['type', type]]), originUrl: api.complianceAssistant.get_remain_count })
  },
  // 会话详情
  session_detail(sessionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.complianceAssistant.session_detail, [['sessionId', sessionId]]), originUrl: api.complianceAssistant.session_detail })
  },
  // 合规助手会话详情
  law_session_detail(sessionId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.complianceAssistant.law_session_detail, [['sessionId', sessionId]]), originUrl: api.complianceAssistant.law_session_detail })
  },
  // 更新会话信息
  save_submit_title(sessionId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.complianceAssistant.save_session_info, [['sessionId', sessionId]]), data: params, originUrl: api.complianceAssistant.save_session_info })
  },
  // 删除历史会话
  delete_session(sessionId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.complianceAssistant.save_session_info, [['sessionId', sessionId]]), originUrl: api.complianceAssistant.save_session_info })
  },
  // 埋点统计
  chat_counting(params) {
    return Axios({ method: 'POST', url: api.complianceAssistant.chat_counting, data: params })
  }
}
