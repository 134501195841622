
const gongyinglianguanli = [
  {
    subIssueTitle: '管理方针',
    subIssueTooltipContent: `对应GRI标准: 103<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B5`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '供应商的管理方针及其要素，包括：a.政策；b.承诺；c.目标；d.责任；e.特定的行动（如流程、专案及倡议等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '管理工具',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B5.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '供应链管理所采用的的系统，及其所遵守的标准/规范。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '供应商当前期状态',
    subIssueTooltipContent: `对应GRI标准: 414-2<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B5.1`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括供应链中的供应商总数、供应商地区分布等。',
      tooltipContent: '若无法统计全部供应商数量，需说明统计范围并说明原因。',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '供应商总数', key: 'SUPPLY_CHAIN_1', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '供应商准入',
    subIssueTooltipContent: `对应GRI标准: 414-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B5.4`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '供应商准入原则，及筛选供应商所采用的工具（如评估表、系统等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '新入供应商数量', key: 'SUPPLY_CHAIN_2', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '供应商社会影响评估',
    subIssueTooltipContent: `对应GRI标准: 414-2<br>
      对应MSCI ESG指标: 争议来源<br>
      对应港交所ESG指引: B5.3`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '识别和评估供应链中具有显著（实际或潜在）的社会影响的程序、结果及对应开展的举措、成效与典型案例。包括：a.自主审核；b.尽职调查；c.第三方审验等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '经确定为具有实际和潜在重大负面社会影响的供应商数量', key: 'SUPPLY_CHAIN_3', unit: '个', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '改进行动方案及跟进活动。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '经确定为具有实际和潜在重大负面社会影响，且经评估后同意改进的供应商数量', key: 'SUPPLY_CHAIN_4', unit: '个', yearDataList: ['', '', ''] }]
      }]
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '不合规的后果。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '经确定为具有实际和潜在重大负面社会影响，且经评估后决定终止关系的供应商数量', key: 'SUPPLY_CHAIN_5', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '供应商社会影响管理',
    subIssueTooltipContent: `对应GRI标准: 414-2<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B5.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '为预防、减轻或补救供应链中存在的实际和潜在负面社会影响而开展的管理举措、成效及典型案例。包括但不限于：a.供应商培训；b.能力构建活动等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default gongyinglianguanli
