/*
 * @Description:市值分析相关接口
 * @Author: lilin.yang@infaith.com.cn
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 分页获取其他行业日统计数据
  list_other_industry_cap_day_distribute(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.list_other_industry_cap_day_distribute, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.list_other_industry_cap_day_distribute })
  },
  // 获取最大市值
  get_max_market_value(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.get_max_market_value, params: params })
  },
  // A股市场整体市值分布
  cap_distribution_of_AShare(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.cap_distribution_of_AShare, params: params })
  },
  // 公司市值变动概览图表
  cap_variation_chart(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.cap_variation_chart, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.cap_variation_chart })
  },
  // 公司市值变动情况表格
  cap_statistic_chart(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.cap_statistic_chart, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.cap_statistic_chart })
  },
  // 相关指数涨跌变动概览图表
  related_index_day_pctChange(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.related_index_day_pctChange, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.related_index_day_pctChange })
  },
  // 相关指数涨跌变动表格
  related_index_range_pctChange(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.related_index_range_pctChange, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.related_index_range_pctChange })
  },
  // 重大事项图表 公告
  stock_trend_and_significant_event_announcement(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_announcement, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_announcement })
  },
  // 重大事项图表 违规事项
  stock_trend_and_significant_event_case_law(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_case_law, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_case_law })
  },
  // 重大事项图表 研究报告
  stock_trend_and_significant_event_report(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_report, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_report })
  },
  // 重大事项图表 公司舆情
  stock_trend_and_significant_event_sentiment(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_sentiment, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_sentiment })
  },
  // 重大事项图表 股价走向信息
  stock_trend_and_significant_event_stock(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_stock, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_stock })
  },
  // 重大事项图表 异动情况
  stock_trend_and_significant_event_stock_change(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_trend_and_significant_event_stock_change, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_trend_and_significant_event_stock_change })
  },
  // 查询股价变动区间概览
  stock_price_rang_overview(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_price_rang_overview, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.stock_price_rang_overview })
  },
  // 公司最近N年现金分红概览
  annual_dividend_overview(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.annual_dividend_overview, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.annual_dividend_overview })
  },
  // 重大事项-公告
  significant_announcements(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.significant_announcements, [['companyCode', companyCode]]), data: params, originUrl: api.capAnalysis.significant_announcements })
  },
  // 重大事项-研报
  significant_reports(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.significant_reports, [['companyCode', companyCode]]), data: params, originUrl: api.capAnalysis.significant_reports })
  },
  // 重大事项-舆情
  significant_caseLaws(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.significant_caseLaws, [['companyCode', companyCode]]), data: params, originUrl: api.capAnalysis.significant_caseLaws })
  },
  // 重大事项-舆情
  significant_sentiments(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.significant_sentiments, [['companyCode', companyCode]]), data: params, originUrl: api.capAnalysis.significant_sentiments })
  },
  // 研报- 评级调整
  rating_changes() {
    return Axios({ method: 'GET', url: api.capAnalysis.rating_changes })
  },
  // 分析师预测情况接口
  analysts_forecast(params) {
    return Axios({ method: 'POST', url: api.capAnalysis.analysts_forecast, data: params })
  },
  // 分析师预测表接口
  analysts_forecast_detail(params) {
    return Axios({ method: 'POST', url: api.capAnalysis.analysts_forecast_detail, data: params })
  },
  // 归母净利润接口
  forecast_chart(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.forecast_chart, params: params })
  },
  // 业绩预测 描述接口
  forecast_describe(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.forecast_describe, params: params })
  },
  // 详细指标预测和最新预测接口
  indicator_forecast(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.indicator_forecast, params: params })
  },
  // 市值概览对比
  market_value_comparison(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.market_value_comparison, [['companyCode', companyCode]]), params: params, originUrl: api.capAnalysis.market_value_comparison })
  },
  // 市值概览对比 v2
  market_value_comparison_v2(companyCode, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.market_value_comparison_v2, [['companyCode', companyCode]]), data, originUrl: api.capAnalysis.market_value_comparison_v2 })
  },
  // 模糊搜索可比公司
  search_companies_company_info(companyCode, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.search_companies_company_info, [['companyCode', companyCode]]), data, originUrl: api.capAnalysis.search_companies_company_info })
  },
  // 估值情况对比
  valuation_situation_comparison(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.valuation_situation_comparison, params: params })
  },
  // 市值变动图
  market_value_changes(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.market_value_changes, params: params })
  },
  // 年度分红情况
  annual_dividends(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.annual_dividends, params: params })
  },
  // 获取公司类型
  stock_market(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capAnalysis.stock_market, [['code', code]]) })
  },
  // 导出分析报告
  cap_analysis_report(params, companyCode) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.capAnalysis.cap_analysis_report, [['companyCode', companyCode]]), data: params })
  },
  // 获取市值分析报告导出选项
  report_export_option(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.report_export_option, params: params })
  },
  // 市场橄榄-统计分析图表数据
  distribution_daily_in_years(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.distribution_daily_in_years, params: params })
  },
  // 获取所有证监会二级行业
  get_scrc_level_two(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.get_scrc_level_two, params })
  },
  // 获取所有申万三级行业
  get_sw_level_three(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.get_sw_level_three, params })
  },
  get_industry_interval_data(params) {
    return Axios({ method: 'GET', url: api.capAnalysis.get_industry_interval_data, params })
  }
}
