/*
 * @Description:
 * @Author: lilin.yang@infaith.com.cn
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 不再试用
  delete_and_switch(profileId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.independentDirector.delete_and_switch, [['profileId', profileId]]), originUrl: api.independentDirector.delete_and_switch })
  },
  // 任职公司行情
  independent_director_companies_statistics(params) {
    return Axios({ method: 'GET', url: api.independentDirector.independent_director_companies_statistics, params: params })
  },
  // 市值对比分析
  market_value_comparison(params) {
    return Axios({ method: 'GET', url: api.independentDirector.market_value_comparison, params: params })
  },
  // 查询所有公司
  companies_search(params) {
    return Axios({ method: 'GET', url: api.independentDirector.companies_search, params: params })
  },
  market_value_changes(params) {
    return Axios({ method: 'GET', url: api.independentDirector.market_value_changes, params: params })
  },
  // 点击试用
  independent_director_trial(comboId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.independentDirector.independent_director_trial, [['comboId', comboId]]), originUrl: api.independentDirector.independent_director_trial })
  },
  // 获取使试用状态
  query_combos(comboId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.independentDirector.query_combos, [['comboId', comboId]]), originUrl: api.independentDirector.query_combos })
  },
  // 自动切换独董
  active_profiles(params) {
    return Axios({ method: 'PUT', url: api.independentDirector.active_profiles, params: params })
  },
  // 获取最新的任职公司舆情（最近5条）
  latest_list() {
    return Axios({ method: 'GET', url: api.independentDirector.latest_list })
  },
  // 查询沪深北以及新三板的违规案例
  by_all_markets_case(params) {
    return Axios({ method: 'POST', url: api.independentDirector.by_all_markets_case, data: params })
  },
  // 查询沪深北以及新三板的公告
  by_all_markets_affiche(params) {
    return Axios({ method: 'POST', url: api.independentDirector.by_all_markets_affiche, data: params })
  },
  // 获取
  invoices_status(params) {
    return Axios({ method: 'GET', url: api.independentDirector.invoices_status, params })
  },
  // 查询我的购买记录
  user_orders() {
    return Axios({ method: 'GET', url: api.independentDirector.user_orders })
  }
}
