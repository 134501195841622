
const gxf = {
  state: {
    marketType: [],
    isShowImportantStockTopOverview: false,
    exportDataComponentLoading: false,
    exportStockHolderInfoComponentLoading: false,
    earliestHoldingPeriod: '', // 股东持仓分析-名册期数，开始时间
    lastHoldingPeriod: '', // 股东持仓分析-名册期数，结束时间
    positionAnalysisLoading: false // 股东持仓分析-数据是否生成中
  },
  getters: {
    MARKET_TYPE: state => state.marketType,
    IS_SHOW_IMPORTANT_STOCK_TOP_OVERVIEW: state => state.isShowImportantStockTopOverview,
    EXPORT_DATA_COMPONENT_LOADING: state => state.exportDataComponentLoading,
    EXPORT_STOCK_HOLDER_INFO_COMPONENT_LOADING: state => state.exportStockHolderInfoComponentLoading,
    EARLIEST_HOLDING_PERIOD: state => state.earliestHoldingPeriod,
    LAST_HOLDING_PERIOD: state => state.lastHoldingPeriod,
    POSITION_ANALYSIS_LOADING: state => state.positionAnalysisLoading
  },
  mutations: {
    MARKET_TYPE: (state, data) => {
      state.marketType = data
    },
    IS_SHOW_IMPORTANT_STOCK_TOP_OVERVIEW: (state, data) => {
      state.isShowImportantStockTopOverview = data
    },
    EXPORT_DATA_COMPONENT_LOADING: (state, data) => {
      state.exportDataComponentLoading = data
    },
    EXPORT_STOCK_HOLDER_INFO_COMPONENT_LOADING: (state, data) => {
      state.exportStockHolderInfoComponentLoading = data
    },
    EARLIEST_HOLDING_PERIOD: (state, data) => {
      state.earliestHoldingPeriod = data
    },
    LAST_HOLDING_PERIOD: (state, data) => {
      state.lastHoldingPeriod = data
    },
    POSITION_ANALYSIS_LOADING: (state, data) => {
      state.positionAnalysisLoading = data
    }
  }
}

export default gxf
