import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 获取年份
  report_years(params) {
    return Axios({ method: 'GET', url: api.independentDirectorDatabase.report_years, params: params })
  },
  // 查询数据概览
  data_overview(reportYear) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.independentDirectorDatabase.data_overview, [['reportYear', reportYear]]), originUrl: api.independentDirectorDatabase.data_overview })
  },
  // 查询独立董事列表
  independent_directors(params) {
    return Axios({ method: 'POST', url: api.independentDirectorDatabase.independent_directors, data: params })
  },
  // 独立董事列表查询条件
  independent_director_database_conditions() {
    return Axios({ method: 'GET', url: api.independentDirectorDatabase.independent_director_database_conditions })
  }
}
