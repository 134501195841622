const data = {
  state: {
    calendarSort: '',
    editStatus: '',
    currentEvent: '', // 查看当前日期的事件
    watchCurrent: '', // 回到今天
    inputEvent: '', // 查询事件
    watchEventDate: '', // 查看事件的日期
    currentMonths: [], // 当前月份集合
    selectDate: '', // 选中日期
    cScreen: '', // 筛选日历
    updateBeginAndEnd: []// 当前起始结束日期区间
  },
  mutations: {
    CALENDAR_SORT(state, data) {
      state.calendarSort = data
    },
    EDIT_STATUS(state, data) {
      state.editStatus = data
    },
    C_SCREEN(state, data) {
      state.cScreen = data
    },
    CURRENT_EVENT(state, data) {
      state.currentEvent = data
    },
    WATCH_CURRENT: (state, data) => {
      state.watchCurrent = data
    },
    INPUT_EVENT: (state, data) => {
      state.inputEvent = data
    },
    WATCH_EVENT_DATE: (state, data) => {
      state.watchEventDate = data
    },
    UPDATE_BEGIN_AND_END: (state, data) => {
      state.updateBeginAndEnd = data
    },
    SELECT_DATE: (state, data) => {
      state.selectDate = data
    },
    CURRENT_MONTHS: (state, data) => {
      state.currentMonths = data
    }
  }
}

export default data
