
const shequgongyi = [
  {
    subIssueTitle: '管理方针',
    subIssueTooltipContent: `对应GRI标准: 103<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B8`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '当地社区的管理方针及其要素，包括：a.政策；b.承诺；c.目标；d.责任；e.特定的行动（如流程、专案及倡议等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '社区参与',
    subIssueTooltipContent: `对应GRI标准: 413-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B8.1, B8.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '当地社区参与企业运营的方式，包括参与渠道、参与程度、公开的申诉流程等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '当地社区组织', key: 'COMMUNITY_BENEFIT_1', unit: '人次', yearDataList: ['', '', ''] },
          { title: '当地社区民众', key: 'COMMUNITY_BENEFIT_2', unit: '人次', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '影响评估',
    subIssueTooltipContent: `对应GRI标准: 413-1, 413-2<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: B8.1, B8.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '基于参与过程的社会影响评估和持续监测，包括性别影响评估、环境影响评估等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公开披露环境和社会影响评估结果。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '对当地社区有实际或潜在重大负面影响的运营点，包括运营点位置和产生的重大负面影响等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '社区发展',
    subIssueTooltipContent: `对应GRI标准: 413-1<br>
      对应MSCI ESG指标: 社区机会（通讯需求、卫生保健需求、融资需求、营养健康）<br>
      对应港交所ESG指引: B8.1, B8.2`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '基于当地社区需求的当地社区发展计划，包括发展目标、管理机制、监察机制等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    },
    {
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '专注贡献的范畴（健康、教育、环境、劳工需求、文化、体育等），及所投放的资源（包括时间、金钱等）。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '开展的公益活动',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '公益捐赠', key: 'COMMUNITY_BENEFIT_3', unit: '元', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '开展的扶贫项目',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '扶贫投入金额', key: 'COMMUNITY_BENEFIT_4', unit: '元', yearDataList: ['', '', ''] }]
      },
      {
        fillBlockType: 'quantifyData',
        title: '员工志愿者管理',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '志愿者人数', key: 'COMMUNITY_BENEFIT_5', unit: '人', yearDataList: ['', '', ''] },
          { title: '志愿者服务时长', key: 'COMMUNITY_BENEFIT_6', unit: '小时', yearDataList: ['', '', ''] },
          { title: '志愿者参与人次', key: 'COMMUNITY_BENEFIT_7', unit: '人次', yearDataList: ['', '', ''] }]
      }]
    }]
  }
]

export default shequgongyi
