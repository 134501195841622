import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  resource_center_click(params) {
    return Axios({ method: 'POST', url: api.resourceCenter.resource_center_click, params })
  },
  white_papers(params, data) {
    return Axios({ method: 'POST', url: api.resourceCenter.white_papers, params, data })
  },
  white_papers_detail(id) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.resourceCenter.white_papers_detail, [['id', id]]),
      originUrl: api.resourceCenter.white_papers_detail
    })
  },
  publication_categories() {
    return Axios({
      method: 'GET',
      url: api.resourceCenter.publication_categories
    })
  },
  selected_publication(params) {
    return Axios({ method: 'GET', url: api.resourceCenter.selected_publication, params })
  },
  selected_publication_detail(id) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.resourceCenter.selected_publication_detail, [['id', id]]),
      originUrl: api.resourceCenter.selected_publication_detail
    })
  },
  activities(params, data) {
    return Axios({ method: 'POST', url: api.resourceCenter.activities, params: params, data: data })
  }
}
