import { render, staticRenderFns } from "./organizationTree.vue?vue&type=template&id=32d0cf6c&scoped=true"
import script from "./organizationTree.vue?vue&type=script&lang=js"
export * from "./organizationTree.vue?vue&type=script&lang=js"
import style0 from "./organizationTree.vue?vue&type=style&index=0&id=32d0cf6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d0cf6c",
  null
  
)

export default component.exports