<!--
 * @Description: 排序栏组件
-->
<template>
  <div id="sortBar">
    <div class="sort-type-block" v-show="sortConfig.length > 0">
      <div
        class="sort-type"
        :class="{ active: item.isActive }"
        v-for="item in sortConfig"
        :key="item.name"
        @click="sortChange(item)"
      >
        <span class="sort-name">{{ item.name }}</span>
        <span v-show="item.type">
          <svg class="icon-symbol" aria-hidden="true" v-show="item.type == 'asc' && item.isActive">
            <use xlink:href="#web-shengxu"></use>
          </svg>
          <svg class="icon-symbol" aria-hidden="true" v-show="!item.isActive">
            <use xlink:href="#web-zhengchang"></use>
          </svg>
          <svg class="icon-symbol" aria-hidden="true" v-show="item.type == 'desc' && item.isActive">
            <use xlink:href="#web-jiangxu"></use>
          </svg>
        </span>
      </div>
    </div>
    <div class="center-content">
      <slot name="centerContent"></slot>
    </div>
    <div class="right-content">
      <slot name="rightContentPrepend"></slot>
      <div class="total-num-block" v-show="isShowTotalNum || isShowSelectedNum">
        <span v-show="isShowTotalNum">找到结果</span>
        <span v-show="isShowTotalNum" class="total-num">{{ totalNum }}</span>
        <span v-show="isShowTotalNum">个</span>
        <span v-show="isShowTotalNum && isShowSelectedNum && selectedNum > 0">，</span>
        <span v-show="isShowSelectedNum && selectedNum > 0"
          >已选中<span class="total-num">{{ selectedNum }}</span
          >个</span
        >
        <xa-button v-show="isShowSelectedNum && selectedNum > 0" text @click="clearSelected"
          ><i class="iconfont web-qingchu"></i>清除已选</xa-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxaSortBar',
  data: function() {
    return {}
  },
  props: {
    // 排序配置 rule: 排序规则 type: 排序方式（无升降序功能type为undefined）
    sortConfig: {
      type: Array,
      default: () => []
    },
    // 是否展示总数
    isShowTotalNum: {
      type: Boolean,
      default: false
    },
    // 是否展示选中数
    isShowSelectedNum: {
      type: Boolean,
      default: false
    },
    totalNum: {
      type: [Number, String],
      default: 0
    },
    selectedNum: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    sortChange(item) {
      this.sortConfig.forEach(e => {
        if (e.name == item.name) {
          e.isActive = true
          if (e.type) {
            switch (item.type) {
              case 'asc':
                e.type = 'desc'
                break
              case 'default':
                e.type = 'desc'
                break
              case 'desc':
                e.type = 'asc'
                break
            }
          }
          this.$emit('handleSelectSort', { sortRule: e.rule, sortType: e.type })
        } else {
          e.isActive = false
          if (e.type) {
            e.type = 'default'
          }
        }
      })
    },
    clearSelected() {
      this.$emit('clearSelected')
    }
  }
}
</script>

<style lang="scss" scoped>
#sortBar {
  flex: 1;
  padding: 0 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #f6f6f6;
  box-sizing: border-box;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  font-size: 13px;
  color: #8d8d8d;
  .sort-type-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .sort-type {
      margin-right: 50px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      &.active {
        .sort-name {
          color: #ffb148;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .iconfont {
        font-size: 14px;
      }
    }
  }
  .right-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .total-num-block {
      color: #8d8d8d;
      font-size: 14px;
      .total-num {
        color: #ffb148;
        margin: 0 8px;
      }
      .xa-button {
        color: #8d8d8d;
        font-size: 12px;
        .iconfont {
          margin-left: 12px;
          font-size: 12px;
        }
        &:hover {
          color: #ffb148;
        }
      }
    }
  }
}
</style>
