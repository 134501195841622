import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

// 资金
export default {
  get_company_capital_today(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capital.get_company_capital_today, [['companyCode', companyCode]]), originUrl: api.capital.get_company_capital_today })
  },
  company_tenDay_capitalFlow(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.capital.company_tenDay_capitalFlow, [['companyCode', companyCode]]), originUrl: api.capital.company_tenDay_capitalFlow })
  }
}
