const data = {
  state: {
    onlineTestLoading: false,
    onlineTestUserInfo: {},
    onlineTestPaperStatus: { status: 0 },
    paperPropertyInfo: {
      defaultAssciation: '湖南省上市公司协会',
      openTime: [] // 开放答题时间列表
    },
    paperInValidTimeSection: null // null初始值 数据尚未获取到，false不在有效时间段，true在有效时间段

  },
  mutations: {
    ONLINE_TEST_LOADING: (state, data) => {
      state.onlineTestLoading = data
    },
    ONLINE_TEST_USER_INFO: (state, data) => {
      state.onlineTestUserInfo = data
    },
    ONLINE_TEST_PAPER_STATUS: (state, data) => {
      state.onlineTestPaperStatus = data
    },
    PAPER_PROPERTY_INFO: (state, data) => {
      state.paperPropertyInfo = data
    },
    PAPER_INVALID_TIME_SECTION: (state, data) => {
      state.paperInValidTimeSection = data
    },
    ONLINE_TEST_START_TIME: (state, data) => {
      state.onlineTestPaperStatus.answerStartTime = data
    },
    ONLINE_TEST_ANSWER_QUESTION: (state, data) => {
      if (data.status !== undefined) {
        state.onlineTestPaperStatus.status = data.status
      }
      if (data.currentQuestionIndexNo !== undefined) {
        state.onlineTestPaperStatus.currentQuestionIndexNo = data.currentQuestionIndexNo
      }
    }
  }
}

export default data
