const protocol = document.location.protocol == 'https:' ? 'https:' : 'http:'
const devDomain = protocol + '//' + 'api.dev.in-hope.com.cn'
const testDomain = protocol + '//' + 'api.test.in-hope.com.cn'
const preDomain = protocol + '//' + 'api.pre.in-hope.cn'
const prodDomain = protocol + '//' + 'api.in-hope.cn'

const hostByEnv = {
  'dev': devDomain,
  'development': devDomain,
  'development2': devDomain,
  'testing': testDomain,
  'testing2': testDomain,
  'pre': preDomain,
  'production': prodDomain
}

const host = {
  dev: {
    host: protocol + '//xiaoan.dev.in-hope.com.cn/', // 域名 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.dev.in-hope.com.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.dev.in-hope.com.cn',
    esopHost: protocol + '//esop.dev.in-hope.com.cn',
    cookieHost: window.location.hostname, // cookie域名
    wsHost: protocol + '//ws.dev.in-hope.com.cn/',
    appid: 'wx3ca2d017b99dc1ac',
    mobileHost: protocol + '//wx.dev.in-hope.com.cn', // 移动端地址
    resourceHost: protocol + '//xiaoan-dev.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  development: {
    host: protocol + '//xiaoan.dev.in-hope.com.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.dev.in-hope.com.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.dev.in-hope.com.cn',
    esopHost: protocol + '//esop.dev.in-hope.com.cn',
    cookieHost: '.dev.in-hope.com.cn', // cookie域名
    wsHost: protocol + '//ws.dev.in-hope.com.cn/',
    appid: 'wx3ca2d017b99dc1ac',
    mobileHost: protocol + '//wx.dev.in-hope.com.cn',
    resourceHost: protocol + '//xiaoan-dev.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  development2: {
    host: protocol + '//xiaoan.dev2.in-hope.com.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.dev2.in-hope.com.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.dev2.in-hope.com.cn',
    esopHost: protocol + '//esop.dev2.in-hope.com.cn',
    cookieHost: '.dev2.in-hope.com.cn', // cookie域名
    wsHost: protocol + '//ws.dev.in-hope.com.cn/',
    appid: 'wx3ca2d017b99dc1ac',
    mobileHost: protocol + '//wx.dev2.in-hope.com.cn',
    resourceHost: protocol + '//xiaoan-dev.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  testing: {
    host: protocol + '//xiaoan.test.in-hope.com.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.test.in-hope.com.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.test.in-hope.com.cn',
    esopHost: protocol + '//esop.test.in-hope.com.cn',
    cookieHost: '.test.in-hope.com.cn', // cookie域名
    wsHost: protocol + '//ws.test.in-hope.com.cn/',
    appid: 'wx97298db2175d79ae',
    mobileHost: protocol + '//wx.test.in-hope.com.cn',
    resourceHost: protocol + '//xiaoan-test.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  testing2: {
    host: protocol + '//xiaoan.test2.in-hope.com.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.test2.in-hope.com.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.test2.in-hope.com.cn',
    esopHost: protocol + '//esop.test2.in-hope.com.cn',
    cookieHost: '.test2.in-hope.com.cn', // cookie域名
    wsHost: protocol + '//ws.test.in-hope.com.cn/',
    appid: 'wx97298db2175d79ae',
    mobileHost: protocol + '//wx.test2.in-hope.com.cn',
    resourceHost: protocol + '//xiaoan-test.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  pre: {
    host: protocol + '//xiaoan.pre.in-hope.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.pre.in-hope.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.pre.in-hope.cn',
    esopHost: protocol + '//esop.pre.in-hope.cn',
    cookieHost: '.pre.in-hope.cn', // cookie域名
    wsHost: protocol + '//ws.pre.in-hope.cn/',
    appid: 'wx77936358b3699982',
    mobileHost: protocol + '//wx.pre.in-hope.cn',
    resourceHost: protocol + '//an-announcement.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  },
  production: {
    host: protocol + '//xiaoan.in-hope.cn/', // 当前网站域名 不要替换
    enterpriseHost: protocol + '//{{code}}.in-hope.cn/', // 企业版代码域名
    ilearnHost: protocol + '//ilearn.in-hope.cn',
    esopHost: protocol + '//esop.in-hope.cn',
    cookieHost: '.in-hope.cn', // cookie域名
    wsHost: protocol + '//ws.in-hope.cn/',
    appid: 'wxff02d74bb4a459d0',
    mobileHost: protocol + '//wx.in-hope.cn',
    resourceHost: protocol + '//an-announcement.oss-cn-shanghai.aliyuncs.com/' // 资源地址
  }
}

function completeHost() {
  for (const key in host) {
    Object.assign(host[key], {
      api: hostByEnv[key] + '/'
    })
  }
}

completeHost()

export default host
