/*
 * @Description: 独董履职管理
 * @Author: lilin.yang
 */

import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 获取独董履职计划列表
  get_project_list(params) {
    return Axios({ method: 'GET', url: api.performance.get_project_list, params })
  },
  // 获取独董履职计划的事项列表
  get_project_item_list(projectId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.performance.get_project_item_list, [['projectId', projectId]]), params, originUrl: api.performance.get_project_item_list })
  },
  // 创建独董履职计划
  create_project(data, params) {
    return Axios({ method: 'POST', url: api.performance.create_project, data, params })
  },
  // 将独董履职计划的事项标记为完成
  change_project_status(projectId, itemId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.performance.change_project_status, [['projectId', projectId], ['itemId', itemId]]), originUrl: api.performance.change_project_status })
  },
  // 删除独董履职计划
  delete_project(projectId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.performance.delete_project, [['projectId', projectId]]), originUrl: api.performance.delete_project })
  },
  // 获取履职计划详情
  get_project_item_detail(projectId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.performance.get_project_item_detail, [['projectId', projectId]]), originUrl: api.performance.get_project_item_detail })
  },
  // 获取企业下的独董用户
  get_performance_duty_user(params) {
    return Axios({ method: 'GET', url: api.performance.get_performance_duty_user, params })
  },
  // 保存独董履职计划事项，包括新增和修改
  edit_project_item(projectId, params, data) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.performance.edit_project_item, [['projectId', projectId]]), params, data, originUrl: api.performance.edit_project_item })
  },
  // 删除独董履职计划下的事项
  delete_project_item(projectId, data) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.performance.delete_project_item, [['projectId', projectId]]), data, originUrl: api.performance.delete_project_item })
  },
  // 获取记录类型筛选项
  get_record_type_select_option() {
    return Axios({ method: 'GET', url: api.performance.get_record_type_select_option })
  },
  // 获取记录来源筛选项
  get_record_source_select_option() {
    return Axios({ method: 'GET', url: api.performance.get_record_source_select_option })
  },
  // 获取独董履职计划列表
  get_performance_record_list(params) {
    return Axios({ method: 'GET', url: api.performance.get_performance_record_list, params })
  },
  // 新增履职记录
  create_performance_record(data, params) {
    return Axios({ method: 'POST', url: api.performance.create_performance_record, data, params })
  },
  // 获取履职记录详情
  check_performance_record_detail(recordId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.performance.check_performance_record_detail, [['recordId', recordId]]), originUrl: api.performance.check_performance_record_detail })
  },
  // 编辑履职记录
  edit_performance_record(recordId, data) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.performance.edit_performance_record, [['recordId', recordId]]), originUrl: api.performance.check_performance_record_detail, data })
  },
  // 获取所属年度筛选项
  get_record_statistics_option(params) {
    return Axios({ method: 'GET', params, url: api.performance.get_record_statistics_option })
  },
  // 统计履职记录
  get_record_statistics_data(params) {
    return Axios({ method: 'GET', params, url: api.performance.get_record_statistics_data })
  },
  // 删除履职记录
  delete_performance_record(recordId, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.performance.delete_performance_record, [['recordId', recordId]]), originUrl: api.performance.delete_performance_record })
  },
  // 获取三会会议列表
  get_meeting_list() {
    return Axios({ method: 'GET', url: api.performance.get_meeting_list })
  },
  // 从三会导入履职记录
  save_performance_record_from_meeting(data) {
    return Axios({ method: 'POST', data, url: api.performance.save_performance_record_from_meeting })
  },
  // 从三会导入履职记录
  add_to_do_list(data) {
    return Axios({ method: 'POST', data, url: api.performance.add_to_do_list })
  },
  // 获取模板列表
  get_report_template_list(params) {
    return Axios({ method: 'GET', url: api.performance.get_report_template_list, params })
  },
  // 模版基础信息修改
  modify_report_template(templateId, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.performance.modify_report_template, [['templateId', templateId]]), originUrl: api.performance.modify_report_template, params })
  },
  // 删除模版
  delete_report_template(templateId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.performance.delete_report_template, [['templateId', templateId]]), originUrl: api.performance.delete_report_template })
  },
  // 获取模板类型选项
  get_report_template_types() {
    return Axios({ method: 'GET', url: api.performance.get_report_template_types })
  },
  // 新增模版
  add_report_template(data) {
    return Axios({ method: 'POST', url: api.performance.add_report_template, data })
  },
  // 恢复至初始模板
  restore_report_template(params) {
    return Axios({ method: 'PUT', url: api.performance.restore_report_template, params })
  },
  // 获取记录中已存在的类型
  get_record_existing_types() {
    return Axios({ method: 'GET', url: api.performance.get_record_existing_types })
  },
  // 获取报告中已存在的独董接口
  get_report_existing_users() {
    return Axios({ method: 'GET', url: api.performance.get_report_existing_users })
  },
  // 获取报告中已存在的年度
  get_report_existing_years() {
    return Axios({ method: 'GET', url: api.performance.get_report_existing_years })
  },
  // 获取报告中已存在的模板类型
  get_report_existing_types() {
    return Axios({ method: 'GET', url: api.performance.get_report_existing_types })
  },
  // 获取独董报告列表
  get_report_list(params) {
    return Axios({ method: 'GET', url: api.performance.get_report_list, params })
  },
  // 删除独董报告
  delete_report_list(reportId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.performance.delete_report_list, [['reportId', reportId]]), originUrl: api.performance.delete_report_list })
  },
  // 异步生成报告接口
  generate_independent_director_report(data) {
    return Axios({ method: 'POST', url: api.performance.generate_independent_director_report, data })
  },
  // 文档管理中心获取独董报告文件接口
  independent_director_document_management_files() {
    return Axios({ method: 'GET', url: api.performance.independent_director_document_management_files })
  },
  // 获取履职筛选条件
  get_record_condititon_list(params) {
    return Axios({ method: 'POST', url: api.performance.get_record_condititon_list, params })
  },
  // 导出履职记录
  export_performance_record(params) {
    return Axios({ method: 'POST', url: api.performance.export_performance_record, params })
  },
  // 导出履职计划excel
  export_performance_duty_by_projectId(projectId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.performance.export_performance_duty_by_projectId, [['projectId', projectId]]), originUrl: api.performance.export_performance_duty_by_projectId, params })
  }
}
