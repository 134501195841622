import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  search(params) {
    return Axios({ method: 'POST', url: api.noPublicOffering.search, data: params })
  },
  search_conditions() {
    return Axios({ method: 'GET', url: api.noPublicOffering.search_conditions })
  },
  tag_tree() {
    return Axios({ method: 'GET', url: api.noPublicOffering.tag_tree })
  },
  // 非公开发行案例公司基本信息
  noPublicOfferingCompanyInfo(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_company_info, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_company_info })
  },
  // 非公开发行案例详情
  noPublicOfferingOverView(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_over_view, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_over_view })
  },
  // 是否构成关联交易
  noPublicOfferingRelatedTransaction(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_related_transation, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_related_transation })
  },
  // 案例公司财务数据
  noPublicOfferingFinancialData(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_financial_data, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_financial_data })
  },
  // 年度分红
  noPublicOfferingCompanyAnnualDividend(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_company_annual_dividend, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_company_annual_dividend })
  },
  // 非公开发行新股上市发行详情
  noPublicOfferingNewListedOverview(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_new_listed_overview, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_new_listed_overview })
  },
  // 非公开发行股价走势图
  noPublicOfferingStockPriceTrend(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_stock_price_trend, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_stock_price_trend })
  },
  // 非公开发行进程时间轴
  noPublicOfferingTimeLine(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_time_line, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_time_line })
  },
  // 获取中介机构信息
  noPublicOfferingCaseIntermediary(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.noPublicOffering.no_public_offering_case_intermediary, [['caseId', caseId]]), originUrl: api.noPublicOffering.no_public_offering_case_intermediary })
  }
}
