import Vue from 'vue'
import Router from 'vue-router'
import Common from '@/utils/common'
import Storage from '@/service/storage'
import Auth from '@/service/auth'
import Store from '@/store'
import routes from './config'
import Api from '@/axios/index'
import ServiceApi from '@/service/api'
import redirectUrlOut from './redirectUrlOut'
import device from 'current-device'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: routes
})

/**
 * @params loginState 页面访问权限配置
 * @params function（baiDuTongJi）百度统计
 * **/
const baiDuTongJi = () => {
  let sc;
  (function () {
    if ((sc = document.getElementById('baidu'))) {
      sc.parentNode.removeChild(sc)
    }
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?c95493fb76f792b34d53bd9e3a1b71ee'
    hm.id = 'baidu'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}

async function getUserPermissions() {
  const userInfo = Storage.getUserInfo()
  try {
    const res = await Api.user.get_user_permissions()
    Store.commit('PERMISSIONS', res.data.returnObject)
    // 更新localstorage内权限缓存信息
    if (userInfo.id) {
      const permissionStorageKey = `permission-${userInfo.id}-${userInfo.companyId || '--'}`
      Storage.set(permissionStorageKey, res.data.returnObject)
    }
  } catch (error) {
    try {
      const res = await Api.user.get_user_permissions()
      Store.commit('PERMISSIONS', res.data.returnObject)
      // 更新localstorage内权限缓存信息
      if (userInfo.id) {
        const permissionStorageKey = `permission-${userInfo.id}-${userInfo.companyId || '--'}`
        Storage.set(permissionStorageKey, res.data.returnObject)
      }
    } catch (error) {
      console.log('error.response.data:', JSON.stringify(error.response?.data))
      console.log('error.response.request.responseText:', error.response?.request?.responseText)
      console.log(error)

      if (userInfo.id) {
        const permissionStorageKey = `permission-${userInfo.id}-${userInfo.companyId || '--'}`
        const permissionStorage = Storage.get(permissionStorageKey)
        if (permissionStorage && permissionStorage.userId) {
          Store.commit('PERMISSIONS_STORAGE', permissionStorage)
        } else {
          return 'networkError'
        }
      } else {
        return 'networkError'
      }
    }
  }
}

async function getCompanyTypeByUserId() {
  try {
    const res = await Api.stock.get_company_type_by_userId()
    if (res.data.returnCode == '0000') {
      // 组织类型，0：上市；1：非上市；2：信公咨询；3：监管；4：小安
      Storage.set('companyType', res.data.returnObject)
      Store.commit('UPDATEHAS_COMPANY_TYPE', true)
    }
  } catch (error) {
    console.log(error)
  }
}

function checkPermission(to) {
  let isNoPersonalPermission
  let isNoOrganizationalPermission
  const userInfo = Storage.getUserInfo()
  const whiteList = to.meta.whiteList
  if (whiteList?.authorityRole?.includes(userInfo.authorityRole) || (whiteList?.loginState === 0 && !Storage.isLogin())) {
    isNoPersonalPermission = false
    isNoOrganizationalPermission = false
    return { isNoPersonalPermission, isNoOrganizationalPermission }
  }
  if (to.meta.contactModules && to.meta.contactModules.length > 0) {
    // 个人是否无响应权限
    isNoPersonalPermission = to.meta.contactModules.some(({ module, resource, operate }) => {
      return !Common.hasPermission(module, resource || 'BASIC', operate)
    })
    // 个人所在公司是否无响应权限
    isNoOrganizationalPermission = to.meta.contactModules.some(({ module, resource, operate }) => {
      return !Common.hasPermission(module, resource || 'BASIC', operate, true)
    })
  }
  return { isNoPersonalPermission, isNoOrganizationalPermission }
}

router.beforeEach(async (to, from, next) => {
  // 移动端设备访问时，跳转H5页面显示
  if (device.tablet() || device.mobile()) {
    const fullPath = Common.guidanceOldFormMobileRoute(to)
    // universal links唤起iOS APP失败，重定向到wx app下载页
    if (to.redirectedFrom?.indexOf('/app') > -1) {
      location.href = `${ServiceApi.mobileHost}/app/download`
      return
    }
    if (fullPath.indexOf('?') < 0) {
      location.href = ServiceApi.mobileHost + fullPath + '?fromPC=true'
    } else {
      location.href = ServiceApi.mobileHost + fullPath + '&fromPC=true'
    }
    return
  }
  // 空路由跳转到 主页
  if (to.name === null) {
    next({ path: '/' })
  }
  // 判断是否是信公小云
  if (['xiaoyun.dev.in-hope.com.cn', 'xiaoyun.dev2.in-hope.com.cn', 'xiaoyun.test.in-hope.com.cn', 'xiaoyun.test2.in-hope.com.cn', 'xiaoyun.pre.in-hope.cn', 'xiaoyun.in-hope.cn'].includes(document.domain)) {
    Store.commit('SET_XIAO_YUN', true)
    // 小云域名未登录状态下，无法访问非index等几个页面，直接重定向到小安
    if (!Storage.isLogin() && !['/index', '/login', '/register/1'].includes(to.path)) {
      if (from.fullPath !== '/') {
        location.href = location.href.replace('xiaoyun', 'xiaoan').replace(from.path, to.path)
      } else {
        location.href = location.href.replace('xiaoyun', 'xiaoan')
      }
      return
    }
  } else {
    Store.commit('SET_XIAO_YUN', false)
  }
  const userInfo = Storage.getUserInfo()
  // 获取公司类型
  if (!Store.state.storeData.hasCompanyType && Storage.isLogin()) {
    await getCompanyTypeByUserId()
  }
  // 登录用户未完善个人信息时
  const urlList_info = ['/complete-personal-info', '/register/2', '/agreement', '/privacy', '/company-seal-page']
  // 个人信息必填字段 若存在一个为空 即需要跳转到 完善个人信息页面
  const requiredPersonInfoKeyList = ['realName', 'companyTypeOne', 'companyTypeTwo', 'companyName']
  if (Storage.isLogin() && !urlList_info.includes(to.path) && !requiredPersonInfoKeyList.every(key => userInfo[key])) {
    next({ path: '/complete-personal-info' })
    return
  }

  // 信公研习社地址重定向
  if (to.path === '/404' && to.redirectedFrom && to.redirectedFrom.indexOf('ilearn/') > -1) {
    window.location.href = `${ServiceApi.ilearnHost}${to.redirectedFrom.replace('ilearn/', '')}`
    return
  }

  // 小信所长重定向
  if (to.path === '/404' && to.redirectedFrom && to.redirectedFrom.indexOf('infaith-bilibili') > -1) {
    window.location.href = 'https://space.bilibili.com/1613309826?spm_id_from=333.337.0.0'
    return
  }

  // 董办2022页面特殊处理，重定向回H5页面
  if (to.path === '/404' && to.redirectedFrom && to.redirectedFrom.indexOf('year2022') > -1) {
    window.location.href = `${ServiceApi.mobileHost}${to.redirectedFrom}`
    return
  }

  // 股权激励地址重定向
  if (to.path.indexOf('equityIncentive') > -1) {
    window.location.href = ServiceApi.esopHost
    return
  }

  // 未登录用户权限判断
  if (to.meta.loginState >= 1 && !Storage.isLogin()) {
    next({ path: '/login', query: { redirect_url: to.fullPath } })
    return
  }

  // 获取用户所有权限
  // 用户已登录且未获取permissions
  if (Storage.get('JWT') && !Store.state.storeData.hasGetPermissions && to.path !== '/network-error') {
    const result = await getUserPermissions()
    if (result === 'networkError') {
      next({ path: 'network-error', query: { redirect_url: to.fullPath } })
      return
    }
  }

  // 校验是否有访问当前路由的权限
  const { isNoPersonalPermission, isNoOrganizationalPermission } = checkPermission(to)
  if (isNoPersonalPermission) {
    if (to.meta.contactModules && to.meta.contactModules.length > 0) {
      Store.commit('PERMISSION_DIALOG_MODULE', to.meta.contactModules[0].module)
    } else {
      Store.commit('PERMISSION_DIALOG_MODULE', '')
    }
    // 若新开tab直接访问，则当前用户窗口一片空白，重定向到首页
    if (from.fullPath === '/') {
      if (to.meta.contactModules && to.meta.contactModules.length > 0) {
        Store.commit('PERMISSION_PAGE_MODULE', to.meta.contactModules[0].module)
      } else {
        Store.commit('PERMISSION_PAGE_MODULE', '')
      }
      next({ path: '/no-permission' })
      return
    }
    if (isNoOrganizationalPermission) {
      Store.commit('SHOW_PERMISSION_DIALOG', 2)
    } else {
      Vue.prototype.$xaToast({ type: 'error', message: '您没有该模块权限，请联系管理员' })
    }
    return
  }
  // 针对信公小云首页及登陆页title更改
  if (Store.state.storeData.isXiaoYun) {
    if (to.path == '/index') {
      document.title = '信公小云'
    } else if (to.path == '/dashboard/regulator') {
      document.title = to.meta.title.replace('小安', '小云')
    } else {
      window.document.title = to.meta.title
    }
  } else {
    window.document.title = to.meta.title
  }
  if (process.env.VUE_APP_XA_ENV === 'production') {
    baiDuTongJi()
  }

  // 判断是否登录且已修改过初识密码、以及完善个人信息
  if (Storage.isLogin() && userInfo.remindEditPwd !== 0 && requiredPersonInfoKeyList.every(key => userInfo[key])) {
    // 外部用户不能通过路由进入内部信乎，跳转到信乎页面
    let selectCL
    switch (userInfo.authorityRole) {
      case 'OUTER':
        if (Auth.isIndependentDirectorUser() && to.fullPath != '/dashboard/independent-director') {
          const list = Storage.get('SELECT_COMPANY_LIST') || []
          if (list.length < 1) {
            next({ path: '/dashboard/independent-director' })
            return
          }
        }
        break
      case 'ENTERPRISE':
        if (to.fullPath.split('/').includes('knowledge-center')) {
          next({ path: '/inhope-FAQ' })
          return
        }
        break
      case 'INFAITH':
        if (Store.state.storeData.isXiaoYun) {
          if (!Storage.get('SELECT_COMPANY_LIST')) {
            await Auth.getAllEspecialCompany(userInfo, false)
          }
          selectCL = Storage.get('SELECT_COMPANY_LIST') || []
          if (selectCL.length <= 0 && to.fullPath != '/dashboard/regulator' && Common.hasPermission('INFAITH_HOME', 'BASIC')) { // 内部用户 如果没有设置服务的公司 则必须到dashboard页面设置
            next({ path: '/dashboard/regulator' })
            Vue.prototype.$xaConfirm({
              message: '<p class="text-l">系统已对接OA - 项目管理系统自动抓取项目信息，并获取服务公司列表。您暂无服务公司。同时，您也暂未设置关注公司，为保证您正常使用系统，请先设置关注公司。</p><br /><p class="text-l">服务公司说明：<br/>只要您是某项目的项目组成员，且该项目状态为预立项 / 立项 / 执行中，则该项目中的服务客户会被作为您的“服务公司”。</p>',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              showClose: false,
              confirmButtonText: '立即设置'
            })
              .then(() => {
                Vue.prototype.$eventBus.$emit('showCompanySetDialog')
              })
            return
          }
        }
        break
      case 'ADMIN':
        break
    }
    // 企业版页面拦截
    if ((to.fullPath == '/index' || to.fullPath == '/') && !Auth.isOuterUser(userInfo.authorityRole)) {
      next({ path: Auth.getHomeUrlByRole(userInfo.authorityRole) })
    }
  }
  // 切换页面 遇到顶部
  if (to.path != from.path) {
    Common.scrollToTop()
  }
  // 匹配需要重定向至外部url的path
  const redirectItem = redirectUrlOut.find(item => item.path === to.fullPath)
  if (redirectItem) {
    location.href = redirectItem.redirectUrl
    return
  }

  next()
})
router.afterEach((to, from) => {
  ysf('track', '进入页面', {
    '页面名称': to.meta.title,
    '页面路径': to.fullPath
  })
})
export default router
