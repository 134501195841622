import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  current_company_default_industry() {
    return Axios({ method: 'GET', url: api.ipoCases.current_company_default_industry })
  },
  nearly_year_ipo_industry_issue(params) {
    return Axios({ method: 'GET', url: api.ipoCases.nearly_year_ipo_industry_issue, params: params })
  },
  dashboard_ipo_list(params) {
    return Axios({ method: 'POST', url: api.ipoCases.dashboard_ipo_list, data: params })
  },
  // ipo案例库列表过滤条件
  ipo_case_filter_conditions(params) {
    return Axios({ method: 'GET', url: api.ipoCases.ipo_case_filter_conditions, params: params })
  },
  // ipo案例库列表
  ipo_case_search(params) {
    return Axios({ method: 'POST', url: api.ipoCases.ipo_case_search, data: params })
  },
  // ipo案例库上市标准
  ipo_case_listing_standard(params) {
    return Axios({ method: 'GET', url: api.ipoCases.ipo_case_listing_standard, params: params })
  },
  // 获取IPO案例库详情-公司信息
  ipo_case_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoCases.ipo_case_detail, [['id', id]]), originUrl: api.ipoCases.ipo_case_detail })
  },
  // IPO发行情况统计
  issuance_status_statistics(params) {
    return Axios({ method: 'GET', url: api.ipoCases.issuance_status_statistics, params: params })
  },
  // IPO募集规模统计
  fundraising_scale_statistics(params) {
    return Axios({ method: 'GET', url: api.ipoCases.fundraising_scale_statistics, params: params })
  },
  // IPO案例库进程历时统计
  process_stage_statistics(params) {
    return Axios({ method: 'GET', url: api.ipoCases.process_stage_statistics, params: params })
  },
  // 中介机构发行情况统计
  intermediary_issuance_statistics(params) {
    return Axios({ method: 'GET', url: api.ipoCases.intermediary_issuance_statistics, params: params })
  },
  // 获取IPO案例库详情-问询与回复
  ipo_case_inquiry_reply(id, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoCases.ipo_case_inquiry_reply, [['id', id]]), originUrl: api.ipoCases.ipo_case_inquiry_reply, params })
  },
  // 获取IPO案例库详情-财务概述
  ipo_case_financial_info(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoCases.ipo_case_financial_info, [['id', id]]), originUrl: api.ipoCases.ipo_case_financial_info })
  },
  // 获取IPO案例库详情-财务概述
  ipo_case_raised_funds_use(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.ipoCases.ipo_case_raised_funds_use, [['id', id]]), originUrl: api.ipoCases.ipo_case_raised_funds_use })
  },
  // 获取IPO进程
  ipo_case_company_process(params) {
    return Axios({ method: 'GET', url: api.ipoCases.ipo_case_company_process, params: params })
  },
  // 过会情况-趋势
  approved_statistics_trend(params) {
    return Axios({ method: 'GET', url: api.ipoCases.approved_statistics_trend, params: params })
  },
  // 过会情况-统计
  approved_statistics(params) {
    return Axios({ method: 'GET', url: api.ipoCases.approved_statistics, params: params })
  }
}
