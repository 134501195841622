import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 左侧筛选数据
  tag_tree() {
    return Axios({ method: 'GET', url: api.convertibleBonds.convertible_bond_tag_tree })
  },
  // 列表筛选条件
  convertible_bond_search_conditions() {
    return Axios({ method: 'GET', url: api.convertibleBonds.convertible_bond_search_conditions })
  },
  // 列表筛选条件
  convertible_bond_issue_case(params) {
    return Axios({ method: 'POST', url: api.convertibleBonds.convertible_bond_issue_case, data: params })
  },
  // 根据案例id 查询对应的公司详情
  convertible_bond_company_info(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.convertibleBonds.convertible_bond_company_info, [['caseId', caseId]]), originUrl: api.convertibleBonds.convertible_bond_company_info })
  },
  // 根据案例id 查询对应的案例详情
  convertible_bond_overview(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.convertibleBonds.convertible_bond_overview, [['caseId', caseId]]), originUrl: api.convertibleBonds.convertible_bond_overview })
  },
  // 可转债行进程时间轴
  convertible_bond_time_line(caseId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.convertibleBonds.convertible_bond_time_line, [['caseId', caseId]]), originUrl: api.convertibleBonds.convertible_bond_time_line })
  },
  // 获取十大持有人数据
  convertible_bond_cases_holder(params) {
    return Axios({ method: 'GET', url: api.convertibleBonds.convertible_bond_cases_holder, params })
  },
  // 获取转股溢价率数据
  convertible_bond_cases_premium_rate(params) {
    return Axios({ method: 'GET', url: api.convertibleBonds.convertible_bond_cases_premium_rate, params })
  }

}
