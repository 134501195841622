
const gongsigaikuang = [
  {
    subIssueTitle: '公司名称与组织架构',
    subIssueTooltipContent: `对应GRI标准: 102-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司全称与简称、公司组织架构图。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '活动、品牌、产品和服务',
    subIssueTooltipContent: `对应GRI标准: 102-2<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括a.组织活动说明；b.主要品牌、产品和服务，包括在某些市场任何被禁产品或服务的说明。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '公司总部的位置',
    subIssueTooltipContent: `对应GRI标准: 102-3<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司总部的省份城市。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '经营位置',
    subIssueTooltipContent: `对应GRI标准: 102-4<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司运营业务所在国的数量，在其中运营大量业务的国家名称，和报告中涵盖的议题相关的国家名称。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '运营业务所在国数量', key: 'COMPANY_SURVEY_1', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '所有权的性质及法律形式',
    subIssueTooltipContent: `对应GRI标准: 102-5<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '所有权的性质及法律形式。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '服务的市场与行业',
    subIssueTooltipContent: `对应GRI标准: 102-6<br>
      对应MSCI ESG指标: 环境机会, 社区机会<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括提供产品和服务所在的地理位置；服务的行业；客户和受益人的类型。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '公司规模',
    subIssueTooltipContent: `对应GRI标准: 102-7<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括员工总数；净销售额或净收入；按债务和权益细分的总市值；所提供的产品或服务数量等各类代表公司规模的定量指标。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '员工总数', key: 'COMPANY_SURVEY_2', unit: '人', yearDataList: ['', '', ''] },
          { title: '总资产', key: 'COMPANY_SURVEY_3', unit: '元', yearDataList: ['', '', ''] },
          { title: '总市值', key: 'COMPANY_SURVEY_4', unit: '元', yearDataList: ['', '', ''] },
          { title: '营业收入', key: 'COMPANY_SURVEY_5', unit: '元', yearDataList: ['', '', ''] },
          { title: '产量/服务总数', key: 'COMPANY_SURVEY_6', unit: '', needCustomUnit: true, yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '公司文化与行为规范',
    subIssueTooltipContent: `对应GRI标准: 102-16<br>
      对应MSCI ESG指标: 商业道德<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司的价值观、使命、愿景、原则、道德诚信准则、行为规范。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '外部倡议',
    subIssueTooltipContent: `对应GRI标准: 102-12<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '组织参与或支持的外界发起的经济、环境、社会章程、原则或其他倡议。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '协会成员资格',
    subIssueTooltipContent: `对应GRI标准: 102-13<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '组织加入的主要行业协会或其他协会，以及国家或国际性倡议组织。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '荣誉认可',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司及其子公司获得的重要荣誉奖项与颁奖单位。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '公司年度重大事件',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司本年度发生的重大事件。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default gongsigaikuang
