// 电子签名信息存储
const signature = {
  state: {
    folderId: null // 选中文件夹id
  },
  mutations: {
    SET_FOLDER_ID: (state, data) => {
      state.folderId = data
    }
  },
  getters: {
    GET_FOLDER_ID: state => state.folderId
  }
}
export default signature
