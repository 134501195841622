import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

// 制度
export default {
  add_institutions_attachment(params) {
    return Axios({ method: 'POST', url: api.institution.add_institutions_attachment, data: params })
  },
  select_all_for_company_catalog(params, catalogId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.institution.select_all_for_company_catalog, [['catalogId', catalogId]]), params: params, originUrl: api.institution.select_all_for_company_catalog })
  },
  select_all_by_catalog_type(params, catalogType) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.institution.select_all_by_catalog_type, [['catalogType', catalogType]]), params: params, originUrl: api.institution.select_all_by_catalog_type })
  },
  search_list_company_catalog(params) {
    return Axios({ method: 'GET', url: api.institution.search_list_company_catalog, params: params })
  },
  edit_regulations(params, id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.institution.edit_regulations, [['id', id]]), data: params, originUrl: api.institution.edit_regulations })
  },
  edit_company_catalogs_by_catalogId(params, catalogId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.institution.company_catalogs_by_catalogId, [['catalogId', catalogId]]), params: params, originUrl: api.institution.company_catalogs_by_catalogId })
  },
  delete_regulations(params, regulationsId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.institution.delete_regulations, [['regulationsId', regulationsId]]), params: params, originUrl: api.institution.delete_regulations })
  },
  delete_company_catalogs_by_catalogId(catalogId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.institution.company_catalogs_by_catalogId, [['catalogId', catalogId]]), originUrl: api.institution.company_catalogs_by_catalogId })
  },
  add_company_catalog(params) {
    return Axios({ method: 'POST', url: api.institution.add_company_catalog, params: params })
  },
  list_all_for_governance_cataLog(params, catalogId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.institution.list_all_for_governance_cataLog, [['catalogId', catalogId]]), params: params, originUrl: api.institution.list_all_for_governance_cataLog })
  },
  document_management_files() {
    return Axios({ method: 'GET', url: api.institution.document_management_files })
  }
}
