
const yewuyunying = [
  {
    subIssueTitle: '业务发展战略',
    subIssueTooltipContent: `对应GRI标准: 102-26<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括高层对公司发展方向与战略的认识、内外部讲话。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '公司市场机遇与风险分析',
    subIssueTooltipContent: `对应GRI标准: 102-15<br>
      对应MSCI ESG指标: 清洁技术的市场机遇, 可再生能源的市场机遇, 绿色建筑的市场机遇, 通讯可及性, 医疗健康可及性, 金融可及性, 营养健康可及性, 金融环境不稳定性<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司业务发展的市场机遇与风险。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '气候变化的影响',
    subIssueTooltipContent: `对应GRI标准: 201-2<br>
      对应MSCI ESG指标: 气候变化<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述气候变化或极端天气对公司业务营销的程度与公司应对气候变化的管理措施与政策。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '公司业务的经济影响',
    subIssueTooltipContent: `对应GRI标准: 203-2<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司业务对区域经济发展的影响、对产业发展的影响等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '商业模式创新',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司的商业模式与创新之处。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '技术研发',
    subIssueTooltipContent: `对应GRI标准: 无<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司重点的研发项目与技术创新项目、创新之处、所获荣誉、专利情况等。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [{ title: '研发投入', key: 'SERVICE_OPERATION_1', unit: '元', yearDataList: ['', '', ''] },
          { title: '所获专利数目', key: 'SERVICE_OPERATION_2', unit: '个', yearDataList: ['', '', ''] }]
      }]
    }]
  },
  {
    subIssueTitle: '重点项目',
    subIssueTooltipContent: `对应GRI标准: 203-1<br>
      对应MSCI ESG指标: 无<br>
      对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司报告期内的重点项目与重要的经济、社会与环境意义。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default yewuyunying
