import Axios from '@/service/http'
import api from '@/service/api'

// 互动e
export default {
  // e互动TOP10
  top_ten_interact_summary(params) {
    return Axios({ method: 'POST', url: api.interact.top_ten_interact_summary, data: params })
  }
}
