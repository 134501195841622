/*
 * @Description: lei.wu@infaith.com.cn
 * @Author: lei.wu
 */
import Axios from '@/service/http'
import api from '@/service/api'

export default {
  // 获取广告详情
  get_advert_by_type(params) {
    return Axios({ method: 'GET', url: api.common.get_advert_by_type, params: params })
  },
  // 获取广告分类
  get_advert_type_list(params) {
    return Axios({ method: 'GET', url: api.common.get_advert_type_list, params: params })
  },
  // 获取广告banner
  get_advert_banner(params) {
    return Axios({ method: 'GET', url: api.common.get_advert_banner, params: params })
  },
  // 获取宣传弹窗配置
  get_web_popup() {
    return Axios({ method: 'GET', url: api.common.get_web_popup })
  },
  // 弹窗点击统计
  web_popup_click_count(params) {
    return Axios({ method: 'PUT', url: api.common.web_popup_click_count, data: params })
  },
  // 查询用户的偏好设置
  user_preference(params) {
    return Axios({ method: 'GET', url: api.common.user_preference, params })
  },
  // 保存用户偏好设置
  update_user_preference(params) {
    return Axios({ method: 'POST', url: api.common.update_user_preference, data: params })
  },
  // 根据关键词模糊查找公司
  related_company_search(params) {
    return Axios({ method: 'GET', url: api.common.related_company_search, params })
  }
}
