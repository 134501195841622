import headerTitle from '../components/global/headerTitle'
import adv from '../components/global/adv'
import regulatorCompany from '../components/global/regulatorCompany'
import regulatorDialog from '../components/global/regulatorDialog'
import VxaNoInfo from '@/components/common/noInfo'
import VxaCardNoInfo from '@/components/common/cardNoInfo.vue'
import VxaProgress from '@/components/progress/progress'
import VxaSortBar from '@/components/common/sortBar'
import VxaCompanyAutocomplete from '@/components/common/companyAutocomplete'
import VxaBaseBanner from '@/components/common/baseBanner'
import VxaStaffSelectInput from '@/components/common/selectInput/main'
import VxaCollapseCard from '@/components/common/collapseCard'
import VxaNavTab from '@/components/global/navTab.vue'
import VxaTab from '@/components/common/vxaTab.vue'
import FilterContainerItem from '@/components/FilterContainer/FilterContainerItem'
import FilterContainer from '@/components/FilterContainer'
import VxaCard from '@/components/common/card.vue'

const components = [headerTitle, adv, regulatorCompany, regulatorDialog]
const vxa_components = [
  VxaNoInfo,
  VxaCardNoInfo,
  VxaProgress,
  VxaSortBar,
  VxaCompanyAutocomplete,
  VxaBaseBanner,
  VxaStaffSelectInput,
  VxaCollapseCard,
  // 业务绑定的tab组件
  VxaNavTab,
  // 通用的tab组件
  VxaTab,
  FilterContainerItem,
  FilterContainer,
  VxaCard
]

export default (Vue) => {
  components.map((v, i) => {
    Vue.component('v-' + v.name, v)
  })
  vxa_components.map((v, i) => {
    Vue.component(v.name, v)
  })
}
