import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

/**
 * @desc: 权益对比
 */
export default {
  // 导出增持过程比例计算
  export_consultation_items(id, params) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.equityChange.export_consultation_items,
        [['id', id]]),
      params,
      originUrl: api.equityChange.export_consultation_items
    })
  },
  /**
   * @param id 股东id
   */
  get_total_share(orgId, params) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.equityChange.get_total_share, [['orgId', orgId]]),
      params,
      originUrl: api.equityChange.get_total_share
    })
  },
  /**
   * @param id 股东id
   */
  get_share_ratio(id) {
    return Axios({
      method: 'GET',
      url: Common.pathFilter(api.equityChange.get_share_ratio, [['id', id]]),
      originUrl: api.equityChange.get_share_ratio
    })
  },
  create_base_info(orgId, data, params) {
    return Axios({ method: 'POST', params, url: Common.pathFilter(api.equityChange.create_base_info, [['orgId', orgId]]), data, originUrl: api.equityChange.create_base_info })
  },
  edit_shareholder_info(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_shareholder_info, [['id', id]]), data, originUrl: api.equityChange.edit_shareholder_info })
  },
  get_transaction_record(orgId, shareholderId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.transaction_record, [['orgId', orgId], ['shareholderId', shareholderId]]), originUrl: api.equityChange.transaction_record })
  },
  add_transaction_record(data, params) {
    return Axios({ method: 'POST', params, url: api.equityChange.add_transaction_record, data })
  },
  edit_transaction_record(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_transaction_record, [['id', id]]), data, originUrl: api.equityChange.edit_transaction_record })
  },
  create_important_point(data, params) {
    return Axios({ method: 'PUT', url: api.equityChange.important_point, data, params })
  },
  update_important_point(data, params) {
    return Axios({ method: 'POST', url: api.equityChange.important_point, data, params })
  },
  get_compny_info(orgId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_compny_info, [['orgId', orgId]]), originUrl: api.equityChange.get_compny_info })
  },
  get_share_holder_statistics(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_share_holder_statistics, [['id', id]]), originUrl: api.equityChange.get_share_holder_statistics })
  },
  del_share_holder_share(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.del_share_holder_share, [['id', id]]), originUrl: api.equityChange.del_share_holder_share })
  },
  delete_share_holder_share(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_share_holder_share, [['id', id]]), originUrl: api.equityChange.delete_share_holder_share })
  },
  add_share_holder_share(id, data, params) {
    return Axios({ method: 'POST', params, url: Common.pathFilter(api.equityChange.add_share_holder_share, [['id', id]]), originUrl: api.add_share_holder_share, data })
  },
  edit_share_holder_share(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_share_holder_share, [['id', id]]), originUrl: api.edit_share_holder_share, data })
  },
  edit_share_statistics(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_share_statistics, [['id', id]]), originUrl: api.edit_share_statistics, data })
  },
  get_share_holder_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_share_holder_info, [['id', id]]), originUrl: api.equityChange.get_share_holder_info })
  },
  get_shareholder_information(orgId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_shareholder_information, [['orgId', orgId]]), originUrl: api.equityChange.get_shareholder_information })
  },
  get_shareholder_base_info(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_shareholder_base_info, [['id', id]]), originUrl: api.equityChange.get_shareholder_base_info })
  },
  delete_shareholder_information_list(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_shareholder_information_list, [['id', id]]), originUrl: api.equityChange.delete_shareholder_information_list })
  },
  get_company_information(orgId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_company_information, [['orgId', orgId]]), originUrl: api.equityChange.get_company_information })
  },
  edit_company_information(orgId, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_company_information, [['orgId', orgId]]), originUrl: api.equityChange.edit_company_information, data })
  },
  get_window_period_list(orgId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_window_period_list, [['orgId', orgId]]), originUrl: api.equityChange.get_window_period_list, params })
  },
  edit_window_period_detail(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_window_period_detail, [['id', id]]), originUrl: api.equityChange.edit_window_period_detail, data })
  },
  add_window_period_detail(data, params) {
    return Axios({ method: 'POST', params, url: api.equityChange.add_window_period_detail, data })
  },
  delete_window_period_detail(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_window_period_detail, [['id', id]]), originUrl: api.equityChange.delete_window_period_detail })
  },
  get_shareholder_list(shareholderId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_shareholder_list, [['shareholderId', shareholderId]]), originUrl: api.equityChange.get_shareholder_list })
  },
  add_shareholder_information(data, params) {
    return Axios({ method: 'PUT', url: api.equityChange.add_shareholder_information, data, params })
  },
  delete_shareholder_information(commitmentId, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_shareholder_information, [['commitmentId', commitmentId]]), originUrl: api.equityChange.delete_shareholder_information })
  },
  edit_shareholder_information(data, params) {
    return Axios({ method: 'POST', params, url: api.equityChange.edit_shareholder_information, data })
  },
  get_concerted_action_person_list(orgId, shareholderId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_concerted_action_person_list, [['orgId', orgId], ['shareholderId', shareholderId]]), originUrl: api.equityChange.get_concerted_action_person_list })
  },
  edit_concerted_action_person(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_concerted_action_person, [['id', id]]), originUrl: api.equityChange.edit_concerted_action_person, data })
  },
  add_concerted_action_person(data, params) {
    return Axios({ method: 'POST', params, url: api.equityChange.add_concerted_action_person, data })
  },
  delete_concerted_action_person(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_concerted_action_person, [['id', id]]), originUrl: api.equityChange.delete_concerted_action_person })
  },
  get_shareholders_concerted_action_person(orgId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_shareholders_concerted_action_person, [['orgId', orgId]]), originUrl: api.equityChange.get_shareholders_concerted_action_person })
  },
  get_violation_item_list(shareholderId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_violation_item_list, [['shareholderId', shareholderId]]), originUrl: api.equityChange.get_violation_item_list })
  },
  add_violation_item(data, params) {
    return Axios({ method: 'PUT', params, url: api.equityChange.add_violation_item, data })
  },
  delete_violation_item(violationId, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_violation_item, [['violationId', violationId]]), originUrl: api.equityChange.delete_violation_item })
  },
  edit_violation_item(data, params) {
    return Axios({ method: 'POST', params, url: api.equityChange.edit_violation_item, data })
  },
  get_consultation_items_compliance_view(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.consultation_items_compliance_view, [['id', id]]), originUrl: api.equityChange.consultation_items_compliance_view })
  },
  update_consultation_items_compliance_view(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.consultation_items_compliance_view, [['id', id]]), originUrl: api.equityChange.consultation_items_compliance_view, data })
  },
  consultation_items_procedure_view(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.consultation_items_procedure_view, [['id', id]]), originUrl: api.equityChange.consultation_items_procedure_view })
  },
  get_service_company_shareholder_info(orgId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_service_company_shareholder_info, [['orgId', orgId]]), originUrl: api.equityChange.get_service_company_shareholder_info })
  },
  get_consultation_matters_detail(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_consultation_matters_detail, [['id', id]]), originUrl: api.equityChange.get_consultation_matters_detail })
  },
  edit_consultation_matters_detail(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_consultation_matters_detail, [['id', id]]), data, originUrl: api.equityChange.edit_consultation_matters_detail })
  },
  save_consultation_matters_detail(data, params) {
    return Axios({ method: 'POST', params, data, url: api.equityChange.save_consultation_matters_detail })
  },
  post_approval_consulting_matters(id, data, params) {
    return Axios({ method: 'POST', params, url: Common.pathFilter(api.equityChange.post_approval_consulting_matters, [['id', id]]), data, originUrl: api.equityChange.post_approval_consulting_matters })
  },
  get_approval_consulting_matters(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_approval_consulting_matters, [['id', id]]), originUrl: api.equityChange.get_approval_consulting_matters })
  },
  edit_approval_consulting_matters(id, data, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.edit_approval_consulting_matters, [['id', id]]), data, originUrl: api.equityChange.edit_approval_consulting_matters })
  },
  delete_transaction_record(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_transaction_record, [['id', id]]), originUrl: api.equityChange.delete_transaction_record })
  },
  important_point_list(shareholderId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.important_point_list, [['shareholderId', shareholderId]]), originUrl: api.equityChange.important_point_list })
  },
  delete_important_point(pointId, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_important_point, [['pointId', pointId]]), originUrl: api.equityChange.delete_important_point })
  },
  important_point_param(orgId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.important_point_param, [['orgId', orgId]]), originUrl: api.equityChange.important_point_param })
  },
  consultation_items(data) {
    return Axios({ method: 'POST', url: api.equityChange.consultation_items, data })
  },
  get_project_member(orgId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_project_member, [['orgId', orgId]]), originUrl: api.equityChange.get_project_member })
  },
  delete_consultation_item(id, params) {
    return Axios({ method: 'DELETE', params, url: Common.pathFilter(api.equityChange.delete_consultation_item, [['id', id]]), originUrl: api.equityChange.delete_consultation_item })
  },
  post_matter_consultation_item(id, data, params) {
    return Axios({ method: 'PUT', data, params, url: Common.pathFilter(api.equityChange.post_matter_consultation_item, [['id', id]]), originUrl: api.equityChange.post_matter_consultation_item })
  },
  get_post_matter_consultation_item(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_post_matter_consultation_item, [['id', id]]), originUrl: api.equityChange.get_post_matter_consultation_item })
  },
  put_approval(itemId, approvalId, data, params) {
    return Axios({ method: 'PUT', data, params, url: Common.pathFilter(api.equityChange.put_approval, [['itemId', itemId], ['approvalId', approvalId]]), originUrl: api.equityChange.put_approval })
  },
  get_matters_approval_record(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_matters_approval_record, [['id', id]]), originUrl: api.equityChange.get_matters_approval_record })
  },
  pending_approval(params) {
    return Axios({ method: 'GET', url: api.equityChange.pending_approval, params })
  },
  approved(params) {
    return Axios({ method: 'GET', url: api.equityChange.approved, params })
  },
  submit_approved(id, params) {
    return Axios({ method: 'POST', params, url: Common.pathFilter(api.equityChange.submit_approved, [['id', id]]), originUrl: api.equityChange.submit_approved })
  },
  get_btn_permission(orgId, params) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.equityChange.get_btn_permission, [['orgId', orgId]]), params, originUrl: api.equityChange.get_btn_permission })
  },
  get_shareholder_operate(params) {
    return Axios({ method: 'GET', params, url: api.equityChange.get_shareholder_operate })
  },
  get_shareholder_operate_detail(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_shareholder_operate_detail, [['id', id]]), originUrl: api.equityChange.get_shareholder_operate_detail })
  },
  get_reduce_plan_detail(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_reduce_plan_detail, [['id', id]]), originUrl: api.equityChange.get_reduce_plan_detail })
  },
  post_reduce_plan_detail(id, data, params) {
    return Axios({ method: 'POST', params, data, url: Common.pathFilter(api.equityChange.post_reduce_plan_detail, [['id', id]]), originUrl: api.equityChange.post_reduce_plan_detail })
  },
  edit_reduce_plan_detail(id, params, data) {
    return Axios({ method: 'PUT', params, data, url: Common.pathFilter(api.equityChange.edit_reduce_plan_detail, [['id', id]]), originUrl: api.equityChange.edit_reduce_plan_detail })
  },
  get_share_change_notice(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_share_change_notice, [['id', id]]), originUrl: api.equityChange.get_share_change_notice })
  },
  get_shareholders_instant_compliance(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.get_shareholders_instant_compliance, [['id', id]]), originUrl: api.equityChange.get_shareholders_instant_compliance })
  },
  decrease_export_data(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.decrease_export_data, [['id', id]]), originUrl: api.equityChange.decrease_export_data })
  },
  validate_person_business_type(params) {
    return Axios({ method: 'GET', params, url: api.equityChange.validate_person_business_type })
  },
  check_expiration(itemId, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.check_expiration, [['itemId', itemId]]), originUrl: api.equityChange.check_expiration })
  },
  refresh_item_data(itemId, params) {
    return Axios({ method: 'PUT', params, url: Common.pathFilter(api.equityChange.refresh_item_data, [['itemId', itemId]]), originUrl: api.equityChange.refresh_item_data })
  },
  check_shareholders_existence(id, params) {
    return Axios({ method: 'GET', params, url: Common.pathFilter(api.equityChange.check_shareholders_existence, [['id', id]]), originUrl: api.equityChange.check_shareholders_existence })
  }
}
