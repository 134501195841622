import Vue from 'vue'
import Vuex from 'vuex'

import storeData from './modules/storeData'
import meeting from './modules/meeting'
import esg from './modules/esg'
import gxf from './modules/gxf'
import signature from './modules/signature'
import inquiryLetter from './modules/inquiryLetter'
import onlineTest from './modules/onlineTest'
import calendar from './modules/calendar'
import rule from './modules/rule'
import capAnalysis from './modules/capAnalysis'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  modules: {
    storeData,
    meeting,
    esg,
    gxf,
    signature,
    inquiryLetter,
    onlineTest,
    calendar,
    rule,
    capAnalysis
  }
})

export default store
