export default {
  printStyle: `
    <style>
      * {font-size: 17px;font-weight: 300;line-height: 26px;font-family: Helvetica, Tahoma, Arial, "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, "Micro Hei", SimSun, 宋体, Heiti, 黑体, sans-serif !important; }
      body {background-color: #f6f6f6;max-width: 900px;height: auto;margin: 0 auto;margin-bottom: 20px;text-align: justify;word-spacing: 0;color: #323232;font-family: Helvetica, Tahoma, Arial, "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, "Micro Hei", SimSun, 宋体, Heiti, 黑体, sans-serif !important;}
      pre {white-space: pre-wrap;word-wrap: break-word;margin: 5px 0;line-height: 20px;font-weight: normal; }
      p { margin: 0 0 16px 0;text-align: justify;box-sizing: border-box;text-indent: 2em;word-break: break-all;text-justify: distribute; }
    </style>
  `,
  rulePrintStyle: `
    <style>
      .rules-title_printf * {font-size: 14px;line-height: 24px;font-family: Helvetica, Tahoma, Arial, "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, "Micro Hei", SimSun, 宋体, Heiti, 黑体, sans-serif !important; margin: 0;}
      .printf-title {font-size: 20px;font-weight: bold;text-align: center;padding: 20px 0;}
      .printf-title * {font-size: 20px;font-weight: bold;}
      .text-container_nohover {position: relative;padding: 6px 16px;}
      .text-container_bold > *:first-child {font-weight: bold;}
      h2 + p, h3 + p, h4 + p,p + p, div + div {margin-top: 6px;}
      a {text-decoration: none; word-wrap: break-word; }
      table {padding: 0 !important;width: 100% !important;border-spacing: 0; border: 1px solid #666;border-collapse: collapse; }
      table td {width: 0 !important;text-shadow: none !important;word-break: break-all !important;word-wrap: break-word !important;margin: 0 !important;vertical-align: middle;border-collapse: collapse;padding: 0 10px;border-right: 1px solid #666;border-bottom: 1px solid #666; }
      table td p {text-indent: 0;margin: 5px 0; }
      img {width: 90%;}
      p {text-indent: 0;}
      .text-container_not_bold attachment { display: block;}
      .text-container_not_bold > * { text-indent: 2em;}
      .text-container_bold > *:first-child {font-weight: bold;}
      .text-container_bold > *:not(:first-child) {text-indent: 2em;}
    </style>
  `,
  casePrintStyle: `
    <style>
      h1,  h1 * {font-weight: bold;padding: 0;margin: 0;line-height: 25px;text-align: center; }
      h1 {padding: 20px 0 20px 0; }
      h1 + p {margin: 0; }
      div {color: #323232; }
      h2,  h2 * {margin: 0 0 16px 0;font-weight: bolder;font-weight: bold;text-indent: 2em; }
      h3,  h3 * { box-sizing: border-box;margin: 0 0 20px 0; color: #323232;font-weight: bold;text-indent: 2em; }
      h4,  h4 * {border-radius: 2px !important;margin: 0 0 5px 0 !important;text-indent: 2em !important;text-decoration: line-through !important;font-weight: normal; }
      h5,  h5 * {margin: 0;text-align: right !important;color: #323232;font-weight: normal; }
      h6,  h6 * {font-size: 14px !important;font-style: oblique !important;margin: 15px 0; }
      a {text-decoration: none; word-wrap: break-word; }
      a:hover,  a:visited,  a:link,  a:active {color: #3D3DF2;text-decoration: none !important; }
      p cite,  p cite * {color: #3D3DF2;font-style: italic !important; }
      table {padding: 0 !important;width: 100% !important;border-spacing: 0;border: 0;border-collapse: collapse; }
      table td {width: 0 !important;text-shadow: none !important;word-break: break-all !important;word-wrap: break-word !important;margin: 0 !important;vertical-align: middle;border-collapse: collapse;padding: 0 10px;/*3423*/ }
      table td p {text-indent: 0;margin: 5px 0; }
      table > tbody > tr > td {border-color: #aeaeae !important; }
      img {width: 90%;}
      .timeliness {display:none}
      .hide-print {display:none}
      #content_header, #content_header > * {padding:20px 0;text-align: center;font-size: 24px;font-weight: bold;color: #323232}
      #html-contents{width: auto !important;}
      .functionality{display: none !important;}
      .details{ background-color: #fff; padding: 10px;border-radius: 10px}
    </style>
  `,
  disclosurePrintStyle: `
    <style>
      .title-flex{display:flex; line-height:30px;}
      .fun {display:none}
      .about {line-height:40px;}
      td,th{border:solid #e3e3e3; border-width:0px 1px 1px 0px; padding:10px 0px;text-align: left;}
      th {border-bottom: 0;}
      th .cell{font-weight: bold;}
      table{border:solid #e3e3e3; border-width:1px 0px 0px 1px;}
      img {display:none;}
      table {border-left:1px solid #e3e3e3;}
      .is-left {text-align: left;}
    </style>
  `,
  shareHolderTypes: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21',
  natureOptions: [
    {
      value: '20',
      label: '指数型公募基金'
    },
    {
      value: '21',
      label: '非指数型公募基金'
    },
    {
      value: '1',
      label: '其他公募基金'
    },
    {
      value: '2',
      label: '私募基金'
    },
    {
      value: '3',
      label: '证券公司资管产品'
    },
    {
      value: '4',
      label: '基金公司资管产品'
    },
    {
      value: '5',
      label: '期货公司资管产品'
    },
    {
      value: '15',
      label: '保险公司资管产品'
    },
    {
      value: '17',
      label: '其他资管产品'
    },
    {
      value: '6',
      label: '一般机构'
    },
    {
      value: '7',
      label: 'QFII'
    },
    {
      value: '8',
      label: 'RQFII'
    },
    {
      value: '9',
      label: '香港结算公司'
    },
    {
      value: '10',
      label: '汇金公司'
    },
    {
      value: '11',
      label: '中证金'
    },
    {
      value: '12',
      label: '社保基金'
    },
    {
      value: '13',
      label: '年金计划'
    },
    {
      value: '14',
      label: '养老金产品'
    },
    {
      value: '16',
      label: '保险资金证券投资账户'
    },
    {
      value: '18',
      label: '其他机构或产品'
    },
    {
      value: '19',
      label: '自然人'
    }
  ],
  // T: 会议日期
  // R: 股权登记日
  // B: 定期报告：年度报告/一季报/半年报/三季报
  // P: 披露日期 = 会议日期
  // Y: 业绩报告：业绩预告/业绩快报
  // C: 差异化分红送转：是/否  (非必填)
  // SE: 日常事件开始时间，结束时间
  eventTemplateConfig: [
    // sh
    { id: 0, name: '召开临时股东大会', valueList: 'T,R', nameSample: '20XX年第一次临时股东大会' },
    { id: 1, name: '召开年度股东大会', valueList: 'T,R', nameSample: '20XX年年度股东大会' },
    { id: 2, name: '定期报告窗口期', valueList: 'B,P', nameSample: '年度报告/半年度报告/一季报/三季报窗口期' },
    { id: 3, name: '业绩预告、业绩快报窗口期', valueList: 'Y,P', nameSample: '业绩预告/业绩快报窗口期' },
    // {id: 4, name: '定期报告内幕信息知情人报备', valueList: 'T,R,', nameSample: ''},
    { id: 5, name: '权益分派实施(无上市可转债或有上市可转债但尚未进入转股期)', valueList: 'P,R,C', nameSample: '实施利润分配方案' },
    { id: 6, name: '权益分派实施(有上市可转债且已进入转股期)', valueList: 'P,R,C', nameSample: '实施利润分配方案' },
    { id: 7, name: '召开年度董事会', valueList: 'T', nameSample: '第一届董事会第一次会议' },
    { id: 8, name: '召开年度监事会', valueList: 'T', nameSample: '第一届监事会第一次会议' },
    // sz
    { id: 10, name: '召开临时股东大会', valueList: 'T,R', nameSample: '20XX年第一次临时股东大会' },
    { id: 11, name: '召开年度股东大会', valueList: 'T,R', nameSample: '20XX年年度股东大会' },
    { id: 12, name: '定期报告窗口期', valueList: 'B,P', nameSample: '年度报告/半年度报告/一季报/三季报窗口期' },
    { id: 13, name: '业绩预告、业绩快报窗口期', valueList: 'Y,P', nameSample: '业绩预告/业绩快报窗口期' },
    { id: 15, name: '权益分派实施', valueList: 'P,R', nameSample: '实施利润分配方案' },
    // {id: 16, name: '权益分派实施(有上市可转债且已进入转股期)', valueList: 'T,R,C', nameSample: ''},
    { id: 17, name: '召开年度董事会', valueList: 'T', nameSample: '第一届董事会第一次会议' },
    { id: 18, name: '召开年度监事会', valueList: 'T', nameSample: '第一届监事会第一次会议' },
    { id: 19, name: '日常事件', valueList: 'SE', nameSample: '' },
    // HG T日：董事会召开日 S日：股东大会召开日 R日：回购股份届满日
    { id: 20, name: '回购股份（集中竞价方式）', valueList: 'HG', nameSample: '回购股份（集中竞价方式）' },
    { id: 21, name: '回购股份（集中竞价方式）', valueList: 'HG', nameSample: '回购股份（集中竞价方式）' },
    // bj
    { id: 23, name: '回购股份（集中竞价方式）', valueList: 'HG', nameSample: '回购股份（集中竞价方式）' },
    { id: 24, name: '召开临时股东大会', valueList: 'T,R', nameSample: '20XX年第一次临时股东大会' },
    { id: 25, name: '召开年度股东大会', valueList: 'T,R', nameSample: '20XX年年度股东大会' },
    { id: 26, name: '定期报告窗口期', valueList: 'B,P', nameSample: '年度报告/半年度报告/一季报/三季报窗口期' },
    { id: 27, name: '业绩预告、业绩快报窗口期', valueList: 'Y,P', nameSample: '业绩预告/业绩快报窗口期' },
    // {id: 28, name: '定期报告内幕信息知情人报备', valueList: 'T,R,', nameSample: ''},
    { id: 28, name: '权益分派实施', valueList: 'P,R', nameSample: '实施利润分配方案' },
    { id: 29, name: '召开年度董事会', valueList: 'T', nameSample: '第一届董事会第一次会议' },
    { id: 30, name: '召开年度监事会', valueList: 'T', nameSample: '第一届监事会第一次会议' },
    // cy
    { id: 40, name: '回购股份（集中竞价方式）', valueList: 'HG', nameSample: '回购股份（集中竞价方式）' },
    { id: 50, name: '召开临时股东大会', valueList: 'T,R', nameSample: '20XX年第一次临时股东大会' },
    { id: 60, name: '召开年度股东大会', valueList: 'T,R', nameSample: '20XX年年度股东大会' },
    { id: 70, name: '定期报告窗口期', valueList: 'B,P', nameSample: '年度报告/半年度报告/一季报/三季报窗口期' },
    { id: 80, name: '业绩预告、业绩快报窗口期', valueList: 'Y,P', nameSample: '业绩预告/业绩快报窗口期' },
    { id: 90, name: '权益分派实施', valueList: 'P,R', nameSample: '实施利润分配方案' },
    { id: 100, name: '召开年度董事会', valueList: 'T', nameSample: '第一届董事会第一次会议' },
    { id: 110, name: '召开年度监事会', valueList: 'T', nameSample: '第一届监事会第一次会议' },
    // nqcx
    { id: 120, name: '召开临时股东大会', valueList: 'T,R', nameSample: '20XX年第一次临时股东大会' },
    { id: 130, name: '召开年度股东大会', valueList: 'T,R', nameSample: '20XX年年度股东大会' },
    { id: 140, name: '年度报告窗口期', valueList: 'B,P', nameSample: '年度报告窗口期' },
    { id: 150, name: '业绩预告、业绩快报窗口期', valueList: 'Y,P', nameSample: '业绩预告/业绩快报窗口期' },
    { id: 160, name: '权益分派实施', valueList: 'P,R', nameSample: '实施利润分配方案' },
    { id: 170, name: '召开年度董事会', valueList: 'T', nameSample: '第一届董事会第一次会议' },
    { id: 180, name: '召开年度监事会', valueList: 'T', nameSample: '第一届监事会第一次会议' }
  ]
}
