<!--
 * @Description: 小card的空数据组件
 * @Author: wenfan.hu@infaith.com.cn
-->
<template>
  <div class="small-module-no-info-root">
    <img class="empty-img" alt="暂无数据" :src="`${$api.aliyunDomain}xiaoan/web_static/empty/ic_empty_small_module.png`" />
    <slot name="desc">
      <div class="desc-container">{{ altText }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'VxaCardNoInfo',
  props: {
    altText: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style scoped lang="scss">
.small-module-no-info-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .empty-img {
    align-self: center;
    width: 200px;
    margin-bottom: 20px;
  }

  .desc-container {
    color: #aeaeae;
    font-size: 14px;
    line-height: normal;
    text-align: center;
  }
}
</style>
