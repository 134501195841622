/*
 * @Description: lei.wu@infaith.com.cn
 * @Author: lei.wu
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  /**
   * 获取实时行情
   * @param {str} companyCode 公司代码
   */
  get_realtime_quotes(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.company.get_realtime_quotes, [['code', companyCode]]), originUrl: api.company.get_realtime_quotes })
  },
  // 获取省市列表
  get_company_districts() {
    return Axios({ method: 'GET', url: api.company.get_company_districts })
  },
  // 简况
  get_company_info_by_company_code(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.company.get_company_info_by_company_code, [['companyCode', companyCode]]), originUrl: api.company.get_company_info_by_company_code })
  },
  // 可转债债券基本信息
  get_convert_bond_company_info(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.company.get_convert_bond_company_info, [['code', code]]), originUrl: api.company.get_convert_bond_company_info })
  }
}
