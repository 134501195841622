import HOSTS from '@/service/host' // HOSTS抽离
const HOST = HOSTS[process.env.VUE_APP_XA_ENV]
export default {
  host: HOST.host,
  api: HOST.api,
  enterpriseHost: HOST.enterpriseHost,
  ilearnHost: HOST.ilearnHost,
  esopHost: HOST.esopHost,
  cookieHost: HOST.cookieHost,
  wsHost: HOST.wsHost,
  appid: HOST.appid,
  mobileHost: HOST.mobileHost, // 分享对应手机端链接配置
  resourceHost: HOST.resourceHost, // 资源地址
  aliyunDomain: 'https://an-announcement.oss-cn-shanghai.aliyuncs.com/', // 阿里云图片域名
  gxfApi: {
    // 上传 部分
    rolls_upload_records: HOST.api + 'gxf/shareholder_rolls/upload_records',
    rolls_statistics: HOST.api + 'gxf/shareholder_rolls/statistics', // 获取名册管理数据
    delete_rolls_by_ids: HOST.api + 'gxf/shareholder_rolls/by_ids', // 根据上传Id删除导入记录
    analysis_user_roll: HOST.api + 'gxf/shareholder_registers/analysis', // 解析名册文件
    shareholder_rolls_verify: HOST.api + 'gxf/shareholder_register/verify', // 名册类型校验
    file_analysis_result: HOST.api + 'gxf/shareholder_rolls/analysis_tasks/{taskId}/analysis_result',
    get_latest_analyis_task_id: HOST.api + 'gxf/shareholder_rolls/analysis_task_ids/latest',
    // 股东人员信息
    search_shareholders: HOST.api + 'gxf/shareholders/search/by_condition', // 股东人员信息
    shareholders_list_share: HOST.api + 'gxf/shareholders_list/share', // 股东名册分享
    shareholders_list_share_result: HOST.api + 'gxf/shareholders_list/share_result', // 股东名册分享状态
    // 股东分组及设置
    holder_groups: HOST.api + 'gxf/holder_groups', // 添加分组
    operate_holder_groups_by_id: HOST.api + 'gxf/holder_groups/{groupId}', // 删除分组
    delete_holder_by_id: HOST.api + 'gxf/holder_groups/{groupId}/holders/{holderId}', // 删除控股股东
    edit_guard_line: HOST.api + 'gxf/holder_groups/{groupId}/guard_line', // 修改警戒线
    get_lasted_record_date: HOST.api + 'gxf/lasted_record_date', // 获取最新期数
    get_lasted_record_date_with_circulate_all_amount: HOST.api + 'gxf/roll/latest_record_date', // 获取最新期数(同时返回流通股数)
    get_holder_groups_types: HOST.api + 'gxf/holder_groups/types', // 获取股东性质枚举
    set_controlling_shareholders: HOST.api + 'gxf/holder_groups/{groupId}/controlling_shareholders', // 设置控股股东
    holder_roll_official: HOST.api + 'gxf/shareholder_rolls/export', // 导出股东名册
    institution_shareholder_excel: HOST.api + 'gxf/companies/institution_shareholder/excel', // 机构股东最新持股情况导出
    query_shareholder_by_name: HOST.api + 'gxf/shareholder_rolls/latest', // 查询股东
    get_organizations_hot_words: HOST.api + 'gxf/organizations/hot_words', // 获取机构热词
    get_shareholders_by_institution: HOST.api + 'gxf/shareholders/top_ten/by_institution', // 获取股东信息
    organizations_hot_words_auto_fill: HOST.api + 'gxf/organizations/hot_words/auto_fill', // 自动填充机构热词
    default_collaborators: HOST.api + 'gxf/default_collaborators', // 获取/提交名册默认共享人
    holding_detail_excel: HOST.api + 'gxf/companies/{code}/public_funds/holding_detail/excel', // 导出公募基金持股明细excel
    // 投资者数据库管理
    search_investor: HOST.api + 'gxf/investors_data/search', // 搜索投资者
    get_investors_data_by_id: HOST.api + 'gxf/investors_data/{id}', // 获取投资者信息byId
    export_investors_data: HOST.api + 'gxf/companies/{code}/investors_data/export', // 导出数据库
    investor_auto_complete: HOST.api + 'gxf/investors_data/search_conditions/auto_complete', // 搜索自动填充
    operate_investors_data: HOST.api + 'gxf/investors_data', // 删除调研记录
    add_investor_by_id: HOST.api + 'gxf/investors_data/{id}', // 根据id添加投资者数据
    // 投资者数据库调研记录管理
    add_investor_research_record: HOST.api + 'gxf/invest_researches/records', // 增加调研记录
    delete_investor_research_record: HOST.api + 'gxf/invest_researches/records/{id}', // 删除投资调研记录
    download_investor_research_record: HOST.api + 'gxf/invest_researches/records/{id}/download', // 下载投资调研记录
    edit_investor_research_record: HOST.api + 'gxf/invest_researches/records/{id}', // 修改投资调研记录
    search_participants: HOST.api + 'gxf/invest_researches/records/participants/search', // 获取参与人
    invest_researches_records: HOST.api + 'gxf/invest_researches/records', // 查询投资调研记录
    invest_researches_records_by_id: HOST.api + 'gxf/invest_researches/records/{id}', // 编辑调研记录
    // 股东对比
    multi_comparison_analysis_excel: HOST.api + 'gxf/companies/{companyCode}/shareholder_information/comparison_analysis/excel', // 导出一键对比Excel报告
    comparison_analysis_excel: HOST.api + 'gxf/institution_shareholder/comparison_analysis/excel', // 导出机构股东对比分析excel
    holder_groups_comparison: HOST.api + 'gxf/holder_groups/comparison', // 分组股东对比-股东组对比信息
    get_institution_group_comparison_info: HOST.api + 'gxf/institution_groups/shareholders/comparison', // 机构股东对比分析
    get_holder_comparison_chart: HOST.api + 'gxf/shareholders/comparison_chart', // 获取股东对比变动图
    shareholders_comparison: HOST.api + 'gxf/shareholders/comparison', // 股东对比分析总览-获取股东对比信息
    get_holder_property_comparison_info: HOST.api + 'gxf/shareholders/property/comparison', // 分项股东对比-股东性质对比信息
    get_multi_period_comparison_info: HOST.api + 'gxf/shareholders/multi_period/comparison', // 股东对比分析总览-获取一键对比信息
    get_stock_price_comparison: HOST.api + 'gxf/stocks/price/comparison', // 获取股价对比/ 获取股价对比
    get_stock_comparison_of_number: HOST.api + 'gxf/shareholders/comparison_of_number', // 股东对比分析户数变化
    multi_periods_by_holding_stocks: HOST.api + 'gxf/shareholders_structure/multi_periods/comparison/by_holding_stocks', // 多期股东持股集中度结构对比数据
    two_periods_by_holding_stocks: HOST.api + 'gxf/shareholders_structure/two_periods/comparison/by_holding_stocks', // 两期股东持股集中度结构对比数据
    multi_periods_by_holding_quantity: HOST.api + 'gxf/shareholders_structure/multi_periods/comparison/by_holding_quantity', // 多期股东按持股数量结构对比数据
    two_periods_by_holding_quantity: HOST.api + 'gxf/shareholders_structure/two_periods/comparison/by_holding_quantity', // 两期股东按持股数量结构对比数据
    multi_periods_by_shareholder_location: HOST.api + 'gxf/shareholders_structure/multi_periods/comparison/by_shareholder_location', // 多期股东地理位置结构对比数据
    two_periods_by_shareholder_location: HOST.api + 'gxf/shareholders_structure/two_periods/comparison/by_shareholder_location', // 两期股东地理位置结构对比数据
    multi_periods_by_credit_guarantee_account: HOST.api + 'gxf/shareholders_structure/multi_periods/comparison/by_credit_guarantee_account', // 多期信用担保账户结构对比数据
    two_periods_by_credit_guarantee_account: HOST.api + 'gxf/shareholders_structure/two_periods/comparison/by_credit_guarantee_account', // 两期信用担保账户结构对比数据
    // shareholder-list-controller : 股东名单
    search_shareholder_list: HOST.api + 'gxf/shareholders_list/search', // 股东名单搜索
    shareholders_structure_by_holding_stocks: HOST.api + 'gxf/shareholders_structure/by_holding_stocks', // 按持股集中度搜索
    shareholders_structure_by_shareholder_nature: HOST.api + 'gxf/shareholders_structure/by_shareholder_nature', // 按股东性质
    get_credit_guarantee_info: HOST.api + 'gxf/credit_guarantee/users/statistics', // 信用担保用户情况
    shareholders_structure_by_shareholder_location: HOST.api + 'gxf/shareholders_structure/by_shareholder_location', // 按股东地理位置
    // 最新股东分析
    get_important_holder_overview: HOST.api + 'gxf/important_shareholders/overview', // 重要股东概览
    get_lasted_important_holder_info: HOST.api + 'gxf/important_shareholders/top_ten/latest', // 重要股东-最新前十大股东
    get_lasted_circulation_shareholders: HOST.api + 'gxf/circulation_shareholders/top_ten/latest', // 重要股东-最新前十大流通股东
    get_by_holding_quantity: HOST.api + 'gxf/shareholders_structure/by_holding_quantity', // 按股东持股数量获取持股情况
    // 个人信息
    get_shareholders_basic_info: HOST.api + 'gxf/shareholders/official_data/{id}/basic_info', // 查询股东基本信息
    get_concerted_share_changes: HOST.api + 'gxf/persons_acting_in_concert/hold_changes', // 查询一致行动人持股变动情况
    get_other_products_share_changes: HOST.api + 'gxf/other_products/hold_changes', // 查询旗下其他产品持有公司股份的情况
    get_other_share_holding: HOST.api + 'gxf/shareholders/other_share_holding', //  查询其他持股情况
    get_public_value_report: HOST.api + 'gxf/companies/net_value/daily_report', // 查询净值日报
    get_fund_manger: HOST.api + 'gxf/shareholder_rolls/{id}/fund_managers', // 获取基金经理
    get_fund_manger_product: HOST.api + 'gxf/fund_managers/fund_products', // 获取基金经理产品
    get_shareholder_info: HOST.api + 'gxf/shareholders/official_data/{id}', // 查询股东账户信息
    get_other_products_hold_changes_chart: HOST.api + 'gxf/other_products/hold_changes_chart',
    official_data_institution_business: HOST.api + 'gxf/shareholders/official_data/{id}/institution_business', // 查询股东机构的工商信息

    // 股东持仓分析
    holders_historical_position: HOST.api + 'gxf/holders/historical_position', // 获取名册股东历史持仓统计数据
    holders_historical_position_ready: HOST.api + 'gxf/holders/historical_position/ready', // 获取历史持仓统计数据是否已准备
    holders_historical_position_detail: HOST.api + 'gxf/holders/{holderId}/historical_position', // 获取最近一次名册合并后的历史持仓计算记录
    holders_historical_position_excel: HOST.api + 'gxf/holders/historical_position/statistics/excel', // 导出股东历史持仓统计excel文件
    holders_single_historical_position_excel: HOST.api + 'gxf/holders/{holderId}/historical_position/excel', // 导出某个股东历史持仓excel文件
    holders_historical_position_calculation: HOST.api + 'gxf/holders/historical_position/calculation', // 选择期数自定义生成数据时查询统计状态

    // 股东趋势分析
    derive_holder_analysis_report: HOST.api + 'gxf/companies/{code}/shareholders/analysis_report', // 生成股东分析报告
    generate_holder_trend_report: HOST.api + 'gxf/companies/{code}/shareholders/trend_report', // 生成股东趋势分析报告
    get_general_info_by_stock_code: HOST.api + 'gxf/companies/{stockCode}/top_statistics', // 获取趋势分析头部的数量统计
    get_lock_stock_time_detail: HOST.api + 'gxf/companies/{code}/restricted_stock/unrestricted_schedule/details', // 限售股解禁时间表详情
    get_lock_stock_time_list: HOST.api + 'gxf/companies/{code}/restricted_stock/unrestricted_schedule', // 限售股解禁时间表
    get_public_holder: HOST.api + 'gxf/companies/{code}/same_industry_companies/public_fund/hold_analysis', // 获取公募基金持股情况分析
    get_potential_fund_holder_by_stock_code: HOST.api + 'gxf/potential_funds/stock_holding/analysis', // 获取潜在基金持股情况分析
    get_potential_fund_holder_details: HOST.api + 'gxf/potential_funds/{fundId}/stock_holding/details', // 获取潜在基金持股情况明细
    get_stock_pledge: HOST.api + 'gxf/companies/{code}/stocks/pledge_analysis', // 获取股份整体质押情况
    get_user_stock_code: HOST.api + 'gxf/user/stock_code', // 获取用户所绑定的股票代码
    list_and_chart_RZRQ_analysis: HOST.api + 'gxf/companies/{code}/securities_margin_trading/analysis', // 融资融券情况分析（列表、图表）
    list_and_chart_RZRQ_analysis_down: HOST.api + 'gxf/companies/{code}/securities_margin_trading/analysis_file', // 融资融券情况分析（列表、图表）数据导出
    list_and_chart_SHSZGT_stock_ownership_analysis: HOST.api + 'gxf/companies/{code}/shanghai_shenzhen_tong/stocks/hold_analysis', // 沪、深股通持股情况分析（列表、图表）
    list_and_chart_SHSZGT_stock_ownership_analysis_down: HOST.api + 'gxf/companies/{code}/shanghai_shenzhen_tong/stocks/hold_analysis_file', // 沪、深股通持股情况分析（列表、图表）数据导出
    list_same_industry_shareholders: HOST.api + 'gxf/companies/{stockCode}/same_industry/number_of_shareholders', // 同行业股东数
    get_stock_holder_and_share_info_by_stock_code: HOST.api + 'gxf/companies/{stockCode}/number_of_shareholders_and_avg_shareholding', // 获取近12个报告期股东户数及平均持股情况分析
    get_stock_holder_and_stock_price_by_stock_code: HOST.api + 'gxf/companies/{stockCode}/number_of_shareholders_and_stock_prices', // 获取近12个报告期股东户数及股价情况分析
    get_public_manager_info_list: HOST.api + 'gxf/companies/{code}/stock_holding/public_fund/manage_companies', // 点击“家数”获取详情
    get_public_product_info_list: HOST.api + 'gxf/companies/{code}/stock_holding/public_fund/products', // 点击“产品”获取详情
    get_report_periods: HOST.api + 'gxf/report_periods', // 获取趋势报告导出参数
    filter_delisting_auto_complete: HOST.api + 'companies/delisting', // 股行分自动补全接口（剔除退市公司）
    get_companies_same_industry_companies: HOST.api + 'gxf/companies/{code}/same_industry/companies', // 同行业公司组件，左侧所有同行业公司
    get_companies_same_industry_selected_companies: HOST.api + 'gxf/companies/{code}/same_industry/selected_companies', // 同行业公司组件，右侧已选择同行业公司
    same_industry_selected_companies: HOST.api + 'gxf/companies/{code}/same_industry/selected_companies', // 同行业公司组件，添加同行业公司;同行业公司组件，删除同行业公司
    same_industry_default: HOST.api + 'gxf/companies/{code}/same_industry/default', // 同行业公司组件，恢复同行业公司
    same_industry_search: HOST.api + 'gxf/companies/by_company_code/same_industry/search', // 同行业公司组件，左侧有同行业公司
    same_industry_selected_companies_search: HOST.api + 'gxf/companies/by_company_code/same_industry/selected_companies/search', // 同行业公司组件，右侧已选择同行业公司
    same_industry_selected_companies_edit: HOST.api + 'gxf/companies/by_company_code/same_industry/selected_companies', // 同行业公司组件，增加、删除
    // 机构股东分析
    get_unmatched_shareholders: HOST.api + 'gxf/shareholder_rolls/unmatch', // 查看未匹配股东
    institution_group_holders: HOST.api + 'gxf/holder_groups/shareholders', // 获取机构股东分组
    upload_history_data: HOST.api + 'gxf/shareholder_rolls/handle_unmatch_history_data', // 处理股东名册历史数据-post
    rollData_of_rollType: HOST.api + 'gxf/shareholder_rolls/by_reserved_type', // 删除某期保留类型之外的名册数据
    circulation_stock_status: HOST.api + 'gxf/shareholder_rolls/analysis/circulationStock', // 仅分析流通股开关状态
    shareholder_rolls_circulation_stock: HOST.api + 'gxf/shareholder_rolls/circulationStock', // 获取/修改股东名册流通总股本
    get_market_in_gxf: HOST.api + 'company/stocks/{code}/market', //  获取code所属板块（仅返回SH SZ OTHER，在股行分模块中使用）
    get_analysis_range: HOST.api + 'gxf/shareholders/analysis_range', // 获取股东分析范围
    analysis_option: HOST.api + 'gxf/shareholder_rolls/analysis/option', // 获取分析选项
    shareholder_rolls_verify_result: HOST.api + 'gxf/shareholder_rolls/verify_tasks/{taskId}/verify_result', // 获取文件校验结果
    shareholder_rolls_uncommitted: HOST.api + 'gxf/shareholder_rolls/unmatch/uncommitted', // 查看所选名册期数中是否存在未提交的未匹配股东
    single_excel_export: HOST.api + 'gxf/download/excel', // 表格支持单独下载excel
    risk_storm_code_relation_list: HOST.api + 'gxf/shareholders/risk_storm/{code}/relation_list',
    risk_storm_code_risk: HOST.api + 'gxf/shareholders/risk_storm/{code}/risk',
    risk_storm_code_court_announcement: HOST.api + 'gxf/shareholders/risk_storm/{code}/court_announcement',
    risk_storm_code_judgment_documents: HOST.api + 'gxf/shareholders/risk_storm/{code}/judgment_documents',
    get_institution_types: HOST.api + 'gxf/institution/types' // 获取机构类型
  },
  user: {
    changebind_transaction: HOST.api + 'user/modify_mobile_transaction', // 获取换绑凭证
    check_sms_code: HOST.api + 'misc/sms/codes/{code}/valid', // 校验验证码
    send_sms_code: HOST.api + 'misc/sms/code/v2', // 发送验证码(版本2)
    can_changebind: HOST.api + 'user/modify_mobile_transaction/modify_status', // 是否可以换绑
    mobile_register: HOST.api + 'users/registrations/mobile/from_desktop', // 手机号注册
    get_image_code: HOST.api + 'misc/kaptcha/v2', // 查询图片验证码和本次uuid
    get_user_info: HOST.api + 'user', // 获取用户信息
    modify_login_password: HOST.api + 'user/login_password/desktop', // 修改密码
    find_login_password: HOST.api + 'users/login_password/find_back/desktop', // 找回密码
    update_email: HOST.api + 'user/email', // 修改邮箱
    get_company_type_info: HOST.api + 'users/company_infos', // 获取用户公司类型
    company_auto_fill: HOST.api + 'companies', // 全市场公司代码自动补全
    get_positions: HOST.api + 'users/positions', // 获取用户职位
    get_company_name_by_code: HOST.api + 'companies/{companyCode}/name', // 根据股票代码获取公司名称
    complete_user_info: HOST.api + 'users/complete_user_info', // 补全用户信息
    bind_user_mobile: HOST.api + 'user/bound_mobile/v2', // 修改绑定手机号
    upload_img: HOST.api + 'misc/invoices/imgs/upload',
    confirm_user_agreement: HOST.api + 'user/confirm_user_agreement', // 用户知晓用户协议
    modify_enterprise_member_mobile_code: HOST.api + 'misc/sms/modify_enterprise_member_mobile/code', // 成员管理更换手机号验证码
    user_track: HOST.api + 'user/browsing', // 用户足迹
    user_favorites: HOST.api + 'user/favorites', // 添加收藏夹
    get_favourite_by_Id: HOST.api + 'users/favorites/{favouriteById}', // 根据id获取收藏夹
    update_favourite_name: HOST.api + 'users/favorites/{id}/name', // 编辑收藏夹
    get_user_recommends: HOST.api + 'user/recommends',
    cancel_recommend: HOST.api + 'user/recommends/{recommendId}', // 取消订阅法规汇编
    law_collection_search: HOST.api + 'lawCollection/search', // 搜索法规汇编
    download_law_collection: HOST.api + 'laws/laws_collection', // 法规汇编下载
    get_law_collection_info: HOST.api + 'lawCollection/{lawCollectionId}/attributes', // 获取法规汇编信息
    get_law_collection_content: HOST.api + 'lawCollection/{lawCollectionId}/details/desktop', // 获取法规汇编内的法规
    get_favourite_content: HOST.api + 'user/favourites/{favourableId}/collections', // 获取收藏夹内容
    get_default_favourite_content: HOST.api + 'user/favorites/default', // 获取默认收藏夹内容
    add_favourite_content: HOST.api + 'user/favourites/collections/v2', // 添加收藏内容
    check_is_collect: HOST.api + 'users/check_is_collect', // 判断是否已经收藏
    get_added_favourite_ids: HOST.api + 'users/is_collect_id', // 已添加的收藏夹的id
    del_favorites_by_id: HOST.api + 'users/favorites/{id}', // 删除收藏夹
    del_favourite_content_by_id: HOST.api + 'user/favourites/{favouriteId}/collections/{id}', // 删除收藏内容
    unread_count: HOST.api + 'user/messages/unread_count', // 未读消息总数
    get_user_groups: HOST.api + 'org/user/groups', // 获取用户在该公司角色
    check_market_with_company_code: HOST.api + 'company/stocks/{code}/market/check',
    check_user_is_existence: HOST.api + 'users/existence', // 校验该用户是否已注册(区号+手机号)
    user_common_modules: HOST.api + 'user/common_menus', // 查询/修改用户常用功能
    focus_on_company_list: HOST.api + 'company/user/focus_on_company_list', // 查询用户关注公司
    user_logout: HOST.api + 'user/authentication_token/logout', // 退出登录
    check_law_exist: HOST.api + 'lawCollection/{lawCollectionId}/checkIsExist' // 检查该法规汇编是否还存在
  },
  newuser: {
    login_by_account: HOST.api + 'user/authentication/login_by_account', // 账号登录
    login_with_register_by_sms: HOST.api + 'user/authentication/login_with_register_by_sms', // 短信登录/注册
    login_by_wx: HOST.api + 'user/authentication/login_by_wx', // 微信登录
    refresh_token: HOST.api + 'user/authentication_token/refresh', // 刷新token
    get_user_permissions: HOST.api + 'user/permissions',
    bind_weixin: HOST.api + 'user/mobile/bind_weixin', // 绑定/解绑微信
    get_user_info_by_userId: HOST.api + 'user/information/detail' // 获取人员信息（在职离职人员信息）
  },
  rule: {
    law_title_auto_complete: HOST.api + 'laws/titles/auto_complete',
    get_rule_search_conditions: HOST.api + 'laws/search_conditions/v2',
    get_hot_words: HOST.api + 'misc/hot_words/{type}',
    get_rule_details: HOST.api + 'laws/v2/{id}', // 法规详情(新)
    get_law_chapters: HOST.api + 'laws/{id}/chapters', // 查询法规详情分片返回
    save_search_behavior: HOST.api + 'big-data/search_behavior/record',
    search_type_setting: HOST.api + 'big-data/search_type_setting/record', // 获取-设置用户设置搜索类型
    get_relation_laws: HOST.api + 'laws/{id}/related_laws/v2', // 相关法规
    get_relation_case: HOST.api + 'laws/{id}/related_cases/v2', // 相关案例
    get_law_history: HOST.api + 'laws/history', // 历史沿革卡片
    get_law_compare_law_list: HOST.api + 'laws/compare/lawList', // 法规对比数据
    download_word_law: HOST.api + 'laws/{id}/download/word', // 法规侧边栏下载
    download_pdf_law: HOST.api + 'laws/{id}/download/pdf', // 法规pdf下载
    batch_download_laws: HOST.api + 'laws/download/batch', // 批量下载法规
    laws_compare: HOST.api + 'laws/compare', // 法规历史沿革对比
    get_compare_lawList: HOST.api + 'laws/compare/lawList', // 修订对比左侧法规列表
    get_directory_condition: HOST.api + 'laws/compare/search_conditions', // 获取历史沿革对比页面目录下拉筛选项
    get_law_catalog: HOST.api + 'laws/catalog', // 获取法规目录
    vague_rule_search: HOST.api + 'laws/search/v4',
    search_guide_record: HOST.api + 'big-data/search_guide/record', // 获取搜索新手指引记录
    get_law_chapter_summary: HOST.api + 'laws/{lawId}/chapter/{chapterId}/summary', // 查询法规章节摘要
    get_law_summary: HOST.api + 'laws/{lawId}/summary', // 查询整篇法规摘要
    get_law_summary_remain_count: HOST.api + 'laws/summary/remain/count', // 获取生成摘要剩余次数
    get_summary_info: HOST.api + 'laws/{id}/reference/summary', // 获取法规章节引用信息
    get_rule_reference_detail: HOST.api + 'laws/{id}/reference/detail', // 取法规章节引用detail
    get_laws_tags: HOST.api + 'laws/tags', // 获取法规标签，不包含标签对应的法规总量
    get_laws_tags_related_law_number: HOST.api + 'laws/tags/related_law_number', // 获取法规标签包含对应的法规总量
    download_laws_compare_word: HOST.api + 'laws/compare/word', // 下载法规对比word
    laws_compare_checkLawList: HOST.api + 'laws/compare/checkLawList', // 校验当前法规链路是否已发生变化
    get_related_interpretations: HOST.api + 'laws/{id}/related_interpretations' // 获取相关解读
  },
  affiche: {
    companies_auto_complete: HOST.api + 'companies/auto_complete', // 通过关键字（简称/代码/拼音首字母）模糊查找AB股主板公司（沪深北板块，包括退市公司）（匿名接口），包括退市公司和拟上市公司（有code）
    get_related_company: HOST.api + 'companies/related_company',
    get_announcements_category: HOST.api + 'announcements/category', // 公告类型树
    get_affiche_search_contidion: HOST.api + 'announcements/search_condition_web', // 公告检索条件
    announcements_dashboard_search: HOST.api + 'announcements/dashboard_search', // 公告查询
    announcements_dashboard_search_anonymous: HOST.api + 'announcements/dashboard_search_anonymous', // 公告查询-未登录调用
    rating_infos: HOST.api + 'announcements/rating_infos', // 评级信息查询
    get_announcement_detail_by_id: HOST.api + 'announcements/{id}', // 公告详情
    get_announcement_year_list: HOST.api + 'announcement/year_list', // 获取年份列表
    get_annual_reports: HOST.api + 'announcement/annual_reports/v2', // 根据generateId查询主键
    download_annual_report: HOST.api + 'announcement/annual_report/download/v2', // 下载公告合集
    delete_annual_report: HOST.api + 'announcement/annual_report', // 删除公告年度合集
    batch_download_announcement: HOST.api + 'announcements/download/batch', // 根据id下载公告
    dashboard_search_abstract_by_id: HOST.api + 'announcements/dashboard_search/abstract', // 批量查询公告摘要
    announcements_content: HOST.api + 'announcements/{id}/content', // 获取公告内容
    vague_affiche_search: HOST.api + 'announcements/search/v2',
    vague_adjacent_announcement_search: HOST.api + 'announcements/adjacent_announcement/search/v2', // 相邻公告搜索
    vague_same_company_aggregation: HOST.api + 'announcements/same_company_aggregation/search/v2', // 公告搜索
    vague_dashboard_search_abstract_by_id: HOST.api + 'announcements/abstract/search/v2' // 批量查询公告摘要

  },
  cases: {
    get_violation_case_detail: HOST.api + 'violation_cases/{id}', // 获取违规案例详情信息
    get_violation_case_filter_conditions: HOST.api + 'violation_case/filter_conditions_web', // 获取违规案例筛选条件
    violation_case_search: HOST.api + 'violation_case/search', // 违规案例查询
    search_violation_case_list: HOST.api + 'violation_case/search/v3', // 违规案例查询（新版-待模糊搜索）
    neeq_companies_auto_complete: HOST.api + 'companies/neeq', // 新三板公司code自动补全
    get_market_by_company_code: HOST.api + 'companies/{companyCode}/market',
    batch_download_violation_case: HOST.api + 'violation_case/download/batch_v2',
    get_download_html_url_by_id: HOST.api + 'violation_case/download_html/{id}' // 获取下载html文本内容的下载文件地址
  },
  researchReport: {
    research_report_author_auto_complete: HOST.api + 'research_report/author_auto_complete', // 作者自动提示
    get_report_detail: HOST.api + 'research_reports/v2/{reportId}', // 研报详情
    get_related_research_reports: HOST.api + 'research_reports/{reportId}/related_reports', // 获取相关研报
    get_research_report_filter_conditions: HOST.api + 'research_report/filter_conditions', // 获取下拉选项值
    research_reports_search: HOST.api + 'research_reports/search', // 研报检索
    research_reports_search_v3: HOST.api + 'research_reports/search/v3' // 研报检索
  },
  lookuptables: {
    add_attention_company: HOST.api + 'permission/attention/{code}', // 用户添加关注公司
    get_internal_annual_report: HOST.api + 'permission/data_source', // 内部员工获取年报数据 - 删
    get_annual_report_by_year: HOST.api + 'permission/data_source_detail', // 内部员工通过年份获取年报
    get_internal_list: HOST.api + 'permission/detail_data', // 获取内部员工的权限速查表(新)
    save_internal_annual_reports: HOST.api + 'permission/custom_data', // [内部]保存数据校准
    reset_internal_annual_report: HOST.api + 'permission/custom_data/reset', // [内部]重置数据校准
    get_ten_close_day: HOST.api + 'permission/ten_close_day', // 通过截止结束日期获取截止开始日期
    permission_date: HOST.api + 'permission/permission_date', // 获取、清除用户在这家公司设定好的时间
    get_min_close_date: HOST.api + 'company/{code}/trade_date', // 获取最小的截止结束日期
    get_permission_list: HOST.api + 'permission/list', // 外部用户获取权限速查表
    get_market_by_code: HOST.api + 'companies/stocks/{code}/market', // 通过code获取所属板块
    get_ten_days_close_price_mean: HOST.api + 'company/stocks/{code}/ten_days_close_price_mean', // 获取前10个交易日市值平均值列表，即（查看明细）
    download_excel: HOST.api + 'permission/export_excel', // 下载
    get_cumulative_calculation_item_list: HOST.api + 'permission/cumulative_calculation/item_list', // 根据公司code获取该板块下的权限速查表累计计算事项列表
    date_setting: HOST.api + 'permission/cumulative_calculation/date_setting', // 用户设置的日期
    latest_audited_report_date: HOST.api + 'permission/cumulative_calculation/latest_audited_report_date', // 获取最新一期已审计的财报报告期
    indicator_list: HOST.api + 'permission/cumulative_calculation/indicator_list', // 获取权限速查表累计计算指标数据
    trade_data: HOST.api + 'permission/cumulative_calculation/trade_data', // 交易明细数据
    indicator_options: HOST.api + 'permission/cumulative_calculation/indicator_options', // 获取事项下的指标选项
    table_head: HOST.api + 'permission/cumulative_calculation/table_head', // 获取表格表头
    get_company_info: HOST.api + 'companies/companyInfo', // 查询公司信息（包括非上市）
    export_trade_data: HOST.api + 'permission/cumulative_calculation/trade_data/download_excel', // 导出交易明细
    // 权限速查 - 自定义披露标准
    get_permission_item_list: HOST.api + 'permission/item_list', // 获取权限速查事项列表
    get_disclosure_rule: HOST.api + 'permission/disclosure_rule/{itemId}', // 获取披露规则
    add_disclosure_rule: HOST.api + 'permission/disclosure_rule', // 添加用户自定义披露规则
    reset_disclosure_rule: HOST.api + 'permission/disclosure_rule/{itemId}/reset', // 重置披露规则
    get_counterparty_list: HOST.api + 'permission/cumulative_calculation/counterparty_list', // 获取交易对手列表
    get_counterparty_option: HOST.api + 'permission/cumulative_calculation/counterparty_option', // 获取可选择的交易对手选项
    get_affiliated_person_option: HOST.api + 'permission/cumulative_calculation/affiliated_person_option', // 获取可选择的同一关联人选项
    save_counterparty: HOST.api + 'permission/cumulative_calculation/counterparty', // 保存交易对手设置，包括新增、修改
    delete_counterparty: HOST.api + 'permission/cumulative_calculation/counterparty/{id}' // 删除交易对手
  },
  calendar: {
    // 日历
    get_close_dates: HOST.api + 'calendar/close_dates', // 获取休市日期
    user_calendars: HOST.api + 'user/calendars', // 创建日历
    get_calendar_by_user_id: HOST.api + 'calendar/canlendar/getCalendarByUserId', // 获取所属自己的日历
    operate_calendar: HOST.api + 'calendars/{calendarId}', // 操作个人日历
    checked_status: HOST.api + 'calendars/checked_status/batch', // 修改日历选中状态
    calendar_share_link: HOST.api + 'calendars/{calendarId}/sharing/link', // 共享日历链接
    calendar_share_users: HOST.api + 'calendars/{calendarId}/sharing/users', // 日历共享人
    modify_calendar_color: HOST.api + 'calendars/{calendarId}/color', // 修改日历颜色
    get_share_calendar_info: HOST.api + 'calendar/sharing', // 获取共享日历信息
    add_shared_calendar: HOST.api + 'calendars/sharing', // 添加他人共享的日历
    get_inviters_by_eventId: HOST.api + 'calendar/event/{eventId}/inviters', // 获取通知对象
    // 日常事件
    add_normal_event: HOST.api + 'calendar/events/templates/{eventTemplateId}/generate', // 日常事件
    calendar_events_by_id: HOST.api + 'calendar/events/{eventId}', // 编辑普通事件详情
    get_calendar_events: HOST.api + 'calendar/events', //  根据时间段获取事件列表
    get_calendar_templates: HOST.api + 'calendar/templates', // 获取所有模板
    search_event_list: HOST.api + 'calendar/events/search', // 根据事件名称搜索个人事件列表
    enterprise_events: HOST.api + 'calendar/events/enterprise_events', // dashbord获取待办事项
    // 模板事件
    template_events: HOST.api + 'calendar/template_events', // 模板事件
    check_date: HOST.api + 'calendar/template_events/{templateEventId}/date/check', // 检查股东大会召开日和股权登记日是否合法
    edit_template_event: HOST.api + 'calendar/calendarTemplateEvent/editTemplateEvent', // 编辑模板事件
    get_template_event_contains: HOST.api + 'calendar/template_event/contains', // 获取事件节点信息
    get_template_event_info: HOST.api + 'calendar/calendarTemplateEvent/getTemplateEventInfo',
    delete_template_event: HOST.api + 'calendar/template_events/{eventId}', // 删除事件
    disclosure_tip: HOST.api + 'calendars/disclosure/tip', // 查询需要弹窗提醒的披露事件、设置不再提醒
    update_template_event_contain: HOST.api + 'calendar/template_event/contain', // 更新单个提醒时间
    calculate_normal_event_confirm_date: HOST.api + 'calendar/event/confirm_date', // 计算自定义事件提醒时间
    get_calendar_backlogs: HOST.api + 'calendar/backlogs', // 查询待办列表
    calendar_backlog: HOST.api + 'calendar/backlog', // 新增待办
    get_backlogs_source_enums: HOST.api + 'calendar/backlog/source_enums', // 待办来源
    user_finish_backlog: HOST.api + 'calendar/backlog/{id}/user/finish', // 参与人完成待办
    finish_backlog: HOST.api + 'calendar/backlog/{id}/finish', // 创建人完成待办
    get_backlog_detail: HOST.api + 'calendar/backlog/{id}', // 查询待办详情
    dashboard_get_backlogs: HOST.api + 'calendar/dashboard/backlogs', // 获取首页我的待办
    del_backlog: HOST.api + 'calendar/backlog/{id}', // 删除待办
    get_orgs_user: HOST.api + 'orgs/user' // 获取企业下用户信息
  },
  // 消息推送
  userMsg: {
    set_all_message_read_status: HOST.api + 'user/messages/sitemsg/read_status', // 类型消息全部设为阅读
    set_single_message_read_status: HOST.api + 'messages/{messageId}/read_status' // 单条消息设置为已阅读
  },
  inhopeFAQ: {
    search_related_questions: HOST.api + 'xiaoan_qa/questions/{questionId}/related_questions/search', // 相关问题
    xiaoan_qa_auto_complete_question_title: HOST.api + 'xiaoan_qa/titles/auto_complete', // 提问关键字自动补全，只匹配标题
    get_latest_questions: HOST.api + 'xiaoan_qa/questions/latest', // 最新问题
    get_questions_details: HOST.api + 'xiaoan_qa/questions/{questionId}/details', // 回答详情
    xiaoan_qa_market_plates: HOST.api + 'xiaoan_qa/market_plates', // 获取问题板块
    xiaoan_qa_question_types: HOST.api + 'xiaoan_qa/question_types', // 获取问题类别
    xiaoan_qa_answer_source: HOST.api + 'xiaoan_qa/answer_source' // 获取回答来源
  },
  knowledgeCenter: {
    search_internal_wiki_questions: HOST.api + 'internal_wiki/questions/search', // 内部信乎搜索
    latest_attentions_questions: HOST.api + 'internal_wiki/questions/latest_attentions', // 近期关注问题
    internal_wiki_get_plates: HOST.api + 'internal_wiki/question/plates', // 获取所有板块
    get_questions_types: HOST.api + 'internal_wiki/questions/types', // 获取所有类别
    get_all_questioner: HOST.api + 'internal_wiki/questions/questioners', // 获取提问人
    get_all_answerer: HOST.api + 'internal_wiki/questions/respondents', // 获取回答人
    auto_complete_question_title: HOST.api + 'internal_wiki/questions/titles/auto_complete', // 关键字自动补全
    get_answers_by_questionId: HOST.api + 'internal_wiki/questions/{questionId}/answers/v2', // 答案详情列表
    write_answer: HOST.api + 'internal_wiki/questions/{questionId}/answers', // 答案详情列表 / 写回答
    operate_answer: HOST.api + 'internal_wiki/answers/{id}', // 修改、删除答案
    create_question: HOST.api + 'internal_wiki/user/questions', // 创建问题
    operate_question: HOST.api + 'internal_wiki/user/questions/{questionId}', // 修改、删除问题
    question_detail: HOST.api + 'internal_wiki/questions/{questionId}/details', // 问题详情
    get_related_questions: HOST.api + 'internal_wiki/questions/{questionId}/related_questions', // 获取相关问题
    attention_question: HOST.api + 'internal_wiki/questions/{questionId}/attentions' // 关注问题
  },
  supervise: {
    get_supervision_news: HOST.api + 'supervision/news/v2' // 监管动态
  },
  // 财务数据
  indicator: {
    indicator_save_preference: HOST.api + 'company/indicators/preference', // 保存指标偏好
    get_indicator_finance_preference: HOST.api + 'company/finance/main_indicator/preference', // 获取偏好指标
    upload_files: HOST.api + 'companies/finance_analysis/financial_upload_files', // 保存导入的财务数据文件
    main_indicator: HOST.api + 'company/finance/main_indicator/v3',

    indicator_digest: HOST.api + 'companies/finance/digest',
    list_financial_data: HOST.api + 'company/stocks/financial_reports',
    indicator_chart: HOST.api + 'companies/finance/indicator/chart',
    indicator_report: HOST.api + 'company/stocks/{companyId}/finance/same_industry/indicator/reports',
    get_report_period: HOST.api + 'companies/{companyId}/finance/latest_report_period', // 根据公司code获取财报的最新报告期
    import_financial_data: HOST.api + 'companies/finance_analysis/import_financial_data', // 获取导入财务数据列表
    import_financial_data_status: HOST.api + 'companies/finance_analysis/import_financial_data/{id}/enable_status', // 更改导入财务数据启用状态
    del_import_financial_data: HOST.api + 'companies/finance_analysis/import_financial_data/{id}', // 根据id删除导入的财务数据
    pre_check: HOST.api + 'companies/finance_analysis/financial_data/import/pre_check', // 导入财务数据前置校验
    financial_data_import: HOST.api + 'companies/finance_analysis/financial_data/import', // 导入财务数据
    get_template_file: HOST.api + 'companies/finance_analysis/financial_data/template_file', // 获取导入财务数据模板
    financial_data_notify: HOST.api + 'companies/finance_analysis/financial_data/notify', // 获取、修改当前公司下，用户导入财务数据提醒状态
    financial_data_share: HOST.api + 'companies/finance_analysis/financial_data/{id}/share', // 保存共享财务数据
    radio_indicators: HOST.api + 'company/stocks/analysis/ratio/indicators', // 比率分析页面,财务报告所有指标;获取列表数据
    radio_reports_generate: HOST.api + 'company/stocks/{companyCode}/analysis/ratio/reports/generate', // 比率分析页面 - 导出报告
    radio_download_path: HOST.api + 'company/stocks/analysis/ratio/download_path/by_task_id', // 比率分析页面 - 获取导出报告地址
    latest_reporting_period: HOST.api + 'company/stocks/analysis/ratio/latest_reporting_period', // 比率分析 - 默认选中报告期
    company_quarter_fin_data_start_year: HOST.api + 'company/quarter_fin_data/start_year', // 获取单季度财务报告起始年份
    companies_finance_digest_export: HOST.api + 'companies/finance/digest/export', // 主要财务数据导出excel
    company_stocks_same_industry_company: HOST.api + 'company/stocks/same_industry_company' // 申万 和证监会
  },
  // 移动端分享连接
  share: {
    share_violation_cases: HOST.mobileHost + '/violation-cases/',
    share_research_reports: HOST.mobileHost + '/reports/',
    share_rules: HOST.mobileHost + '/rules/',
    share_affiches: HOST.mobileHost + '/affiches/',
    share_inquiry_letters: HOST.mobileHost + '/inquiry-letters/'
  },
  // 企业管理
  manage: {
    import_members: HOST.api + 'orgs/import_member', // 导入成员（通过excel文件批量导入）
    enterprise_edition_apply: HOST.api + 'org/enterprise_edition/apply', // 申请企业版 股行分等
    get_org_info_by_id: HOST.api + 'orgs/{companyId}',
    search_org_users: HOST.api + 'orgs/{companyId}/users/search',
    get_org_users: HOST.api + 'orgs/{companyId}/users',
    add_orgs_users: HOST.api + 'orgs/users',
    edit_orgs_users: HOST.api + 'orgs/{companyId}/users',
    delete_orgs_users: HOST.api + 'orgs/{companyId}/users/{removeUserId}',
    browsing_records: HOST.api + 'org/browsing_records', // 记录用户页面使用数据
    get_enterprise_orgs: HOST.api + 'orgs/{companyId}/enterprise_orgs', // 根据企业ID查找企业的所有组织机构
    add_enterprise_orgs: HOST.api + 'org/enterprise_orgs', // 添加企业机构
    operate_enterprise_orgs: HOST.api + 'org/enterprise_orgs/{id}', // 根据机构ID删除/编辑组织机构
    get_user_orgs: HOST.api + 'user/orgs', // 根据用户获取公司列表
    get_org_identities: HOST.api + 'orgs/org_identities', // 获取身份列表
    get_user_groups_by_id: HOST.api + 'org/{organizationId}/user/groups', // 获取企业用户组列表
    get_member_info_by_mobile: HOST.api + 'orgs/{companyId}/users/{mobile}', // 根据手机号获取成员信息
    get_user_groups_and_admins: HOST.api + 'org/companies/user_groups/and/admins', // 获取用户组及管理员信息
    get_users_profiles: HOST.api + 'users/profiles', // 获取套餐列表
    change_profiles: HOST.api + 'users/active_profiles/{profileId}', // 切换套餐
    edit_users_mobile: HOST.api + 'orgs/{companyId}/users/mobile', // 编辑成员手机号码
    save_event_tracking: HOST.api + 'misc/event_tracking', // 保存埋点记录
    import_member_analysis: HOST.api + 'orgs/import_member/{fileAnalysisId}/analysis', // 解析名册文件
    analysis_task_ids_latest: HOST.api + 'orgs/import_member/analysis_task_ids/latest', // 获取最新的解析文件id
    import_member_analysis_result: HOST.api + 'orgs/import_member/{fileAnalysisId}/analysis/result', // 获取解析结果
    equitychange_market: HOST.api + 'equitychange/market', // 获取所属板块
    equitychange_business_type: HOST.api + 'equitychange/business_type', // 获取业务/产品类型
    equitychange_project: HOST.api + 'equitychange/project', // 项目列表
    equitychange_project_detail: HOST.api + 'equitychange/project/{id}', // 获取项目详情
    equitychange_project_role: HOST.api + 'equitychange/project/role', // 项目角色列表
    equity_distribution: HOST.api + 'equitychange/shareholders/{id}/equity/distribution' // 查询公司除权除息，且方案为包含转增、送股、配股信息
  },
  // 三会
  meetingmanagement: {
    meetings_init: HOST.api + 'meetings/types/{type}/init', // 创建会议
    operate_meeting: HOST.api + 'meetings/{meetingId}', // 删除会议
    get_meeting_type_options: HOST.api + 'meeting/companies/{companyId}/organizations', // 获取会议类型
    meeting_type_search_condition: HOST.api + 'meeting/type/search_condition', // 获取会议类型搜索条件
    save_meeting_info: HOST.api + 'meetings/{meetingId}/information', // 保存会议信息
    get_ongoing_meetings: HOST.api + 'companies/{companyId}/meetings', // 查询进行中的会议
    search_meetings: HOST.api + 'meetings/search', // 查询会议详情
    get_meeting_statistic_info: HOST.api + 'companies/{companyId}/meetings/statistics', // 查询三会统计数据
    meeting_file_status: HOST.api + 'meetings/{meetingId}/files/status', // 获取会议文件状态
    get_meeting_files: HOST.api + 'meetings/{meetingId}/files', // 根据会议id获取文件列表
    operate_meeting_file: HOST.api + 'meetings/files/{fileId}', // 删除文件
    derive_meeting_file: HOST.api + 'meetings/{meetingId}/files/export', // 导出文件
    meeting_files: HOST.api + 'meetings/files', // 生成/更新文件
    generate_dong_jian_gao_analysis_file: HOST.api + 'meetings/files/analysis_file/dong_jian_gao', // 生成出席情况报告
    check_meeting_file_exist: HOST.api + 'meetings/{meetingId}/files/existence', // 校验文件是否已存在
    company_mail_box_setting_info: HOST.api + 'meeting/companies/{companyId}/mailboxes/settings', // 企业邮箱
    test_mail_box: HOST.api + 'meeting/mailboxes/test', // 邮箱测试
    send_mailboxes_verify_code: HOST.api + 'meeting/mailboxes/verify', // 发送邮箱验证码
    default_mailboxes_settings: HOST.api + 'meeting/companies/{companyId}/mailboxes/settings/{mailBoxId}/default', // 设置默认发件人邮箱
    get_schedule_notify_info: HOST.api + 'meetings/{meetingId}/notifications/schedules', // 查看定时发送计划
    delete_schedule_notify_time: HOST.api + 'meeting/notifications/schedules/{meetingId}', // 删除定时发送计划
    update_schedule_notify_time: HOST.api + 'meeting/notifications/schedules/{meetingId}/send_time', // 修改定时发送时间
    get_sent_message_notify_info: HOST.api + 'meetings/{meetingId}/notifications/histories', // 获取通知发送记录
    update_meeting_phrase: HOST.api + 'meetings/{meetingId}/phrase', // 更新会议状态
    check_enterprise_personnel_changes: HOST.api + 'meetings/{meetingId}/person/changed', // 查询企业管理成员是否变动
    get_meeting_message_notify: HOST.api + 'companies/{companyId}/meetings/{meetingId}/notifications', // 查询出席和列席人员详细信息
    get_participant_info: HOST.api + 'companies/{companyId}/meetings/{meetingId}/participants', // 查询发送通知草稿
    save_meeting_message_notify_info: HOST.api + 'meetings/{meetingId}/notifications', // 保存发送通知内容
    get_meeting_template: HOST.api + 'meetings/{companyId}/template_files', // 获取三会模板信息
    check_meeting_template: HOST.api + 'meetings/{institutionId}/template_files/existence', // 三会模板重命名校验名字是否重复
    meeting_template_files: HOST.api + 'meetings/template_files/{fileId}', // 重命名/删除三会文件
    open_blank_meeting_template: HOST.api + 'meetings/{institutionId}/template_files', // 创建空白wps文档
    initiate_signature: HOST.api + 'meetings/{meetingId}/signature_item', // 发起三会模板电子签名
    get_meeting_signature_info: HOST.api + 'electronic-signature/meeting/item_files', // 获取三会签署文件信息
    export_meeting_signature_info: HOST.api + 'meetings/{meetingId}/signature_files/export', // 导出全部签署文件
    meeting_file_switch: HOST.api + 'meetings/template_files/{fileId}/switch', // 模板设置-模板状态切换
    recover_meeting_template: HOST.api + 'meetings/template_files/{fileId}/init', // 恢复至初始模板
    check_trade_date_rules: HOST.api + 'meetings/{orgId}/check/trade_date/rules/v2', // 校验是否符合交易日规则
    meetings_proposal_file: HOST.api + 'meetings/proposal_files/{fileId}', // 股东大会重命名议案文件名称
    stockholder_meeting_hold_meeting: HOST.api + 'meetings/stockholder_meeting/hold_meeting/{meetingId}', // 获取股东大会召开会议tab页信息
    vote_count_export: HOST.api + 'meetings/stockholder_meeting/vote_count/{meetingId}/export', // 导出表决统计
    proposal_check_file_name: HOST.api + 'meetings/stockholder/{meetingId}/proposal/check_file_name', // 议案文件上传前校验是否存在同名文件
    proposal_sequence: HOST.api + 'meetings/{meetingId}/proposal/sequence', // 更新议案文件顺序
    meetings_get_file_folder: HOST.api + 'meetings/{meetingId}/file/folder', // 获取当前公司所有的文件夹
    meetings_update_file_folder: HOST.api + 'meetings/file_folder', // 保存、编辑文件夹
    meetings_delete_file_folder: HOST.api + 'meetings/file/folder/{folderId}', // 删除文件夹
    meetings_move_file_folder: HOST.api + 'meetings/file/folder', // 移动文件到文件夹
    meetings_org_last_analysis_record: HOST.api + 'meetings/org/{organizationId}/last_analysis_record', // 获取上一次分析记录信息
    meetings_org_import: HOST.api + 'meetings/org/{orgId}/import/{fileAnalysisId}', // 导入历史会议
    meetings_analysis_error_result: HOST.api + 'meetings/analysis/{fileAnalysisId}/error_result', // 获取解析后的错误信息结果
    meetings_analysis_meetings: HOST.api + 'meetings/analysis/{analysisId}/meetings', // 获取某次分析对应生成的会议集合
    meetings_import_meeting_detail: HOST.api + 'meetings/{meetingId}/import_meeting_detail', // 获取导入会议的详情信息
    meetings_copy_meeting: HOST.api + 'meetings/{meetingId}/copy_meeting', // 复制会议
    meetings_analysis_record: HOST.api + 'meetings/org/{organizationId}/analysis_record', // 分页查询当前公司的历史导入信息
    delete_meetings_analysis: HOST.api + 'meetings/analysis/{analysisId}', // 删除导入失败的分析记录
    meeting_contact_user_check_email: HOST.api + 'meetings/org/{organizationId}/meeting_contact_users/email_check', // 校验邮箱是否存在
    get_meeting_contact_users: HOST.api + 'meetings/org/{organizationId}/meeting_contact_users', // 获取三会联系人列表
    add_meeting_contact_user: HOST.api + 'meetings/org/{organizationId}/meeting_contact_user', // 保存三会联系人
    operate_meeting_contact_user: HOST.api + 'meetings/org/{organizationId}/meeting_contact_users/{userId}', // 删除三会联系人、更新三会联系人
    meeting_convene_form: HOST.api + 'meeting/organizations/{organizationId}/meeting_convene_form', // 查询该企业的三会会议召开方式集合 || 自定义三会会议召开方式
    meetings_template_sms: HOST.api + 'meetings/{organizationId}/template_sms', // 查询不同会议类型的短信模板
    update_meetings_template_files: HOST.api + 'meetings/template_sms/{templateSmsId}', // 保存短信模板
    meetings_files_export_running_task: HOST.api + 'meetings/{meetingId}/files/export/running_task', // 查询导出文件任务状态
    meeting_document_management_files: HOST.api + 'meeting/document_management/files', // 文档管理获取三会文件接口
    meeting_save_files: HOST.api + 'meeting/files', // 保存会议文件
    add_meeting_proposal_files: HOST.api + 'meeting/{meetingId}/proposal/file/batch', // 添加议案文件
    meeting_template_files_creation: HOST.api + 'meeting/template/files/creation', // 保存模板文件
    meeting_analysis_files_creation: HOST.api + 'meeting/analysis/files/creation', // 保存解析文件
    save_mail_attachments: HOST.api + 'meetings/{meetingId}/mail_attachments',
    delete_proposal_file: HOST.api + 'meeting/{meetingId}/proposal/file', // 准备资料阶段 - 删除自定义上传的文件
    meetings_template_files_has_update: HOST.api + 'meetings/template_files/{fileId}', // 获取模板是否有更新
    meetings_system_template_files_preview: HOST.api + 'meetings/system_template_files/{fileId}/preview' // 获取初始模板信息
  },
  // 任务管理
  framework: {
    get_file_url_by_oss_key: HOST.api + 'paperless/files/preview', // 获取文件临时访问地址
    get_tasks_progress: HOST.api + 'framework/tasks/progress', // 生成文件任务状态反馈
    get_file_oss_address: HOST.api + 'framework/document/file/address', // 根据fileId获取oss访问路径
    get_document_file_record: HOST.api + 'framework/document/file/record', // 根据fileId获取文档记录
    wps_sessions: HOST.api + 'framework/document/wps/sessions', // 查询/发送 wps文件打开页面数量
    wps_address: HOST.api + 'framework/document/wps/address' // 获取调用wps组件的地址接口
  },
  esg: {
    organizations_subsidiaries: HOST.api + 'esg/organizations/{organizationId}/subsidiaries',
    operate_subsidiary: HOST.api + 'esg/organizations/subsidiaries/{id}',
    organizations_members: HOST.api + 'esg/organizations/{organizationId}/members',
    issue_allocations: HOST.api + 'esg/tasks/{taskId}/issues/allocation', // 任务分配指标列表
    issue_allocation: HOST.api + 'esg/tasks/{taskId}/subsidiaries/{subsidiaryId}/issues/allocation', // 议题、填报人获取
    issue_allocation_draft: HOST.api + 'esg/tasks/{taskId}/issues/allocation/draft', // 保存填报数据
    issue_allocation_send: HOST.api + 'esg/tasks/{taskId}/issues/allocation', // 填报任务下发
    member_subsidiary_relation: HOST.api + 'esg/subsidiaries/members/{memberId}',
    organizations_tasks: HOST.api + 'esg/organizations/{organizationId}/tasks', // 获取任务列表
    task_status: HOST.api + 'esg/organizations/tasks/{taskId}/status', // 更新任务状态
    operate_task: HOST.api + 'esg/organizations/tasks/{taskId}', // 获取/删除任务详情
    task_draft: HOST.api + 'esg/organizations/{organizationId}/tasks/draft', // 保存任务
    organizations_subtasks: HOST.api + 'esg/organizations/{organizationId}/subtasks', // 获取填报任务列表
    subtask_status: HOST.api + 'esg/subtasks/{taskId}/status', // 更新填报任务状态
    filling_data: HOST.api + 'esg/subtasks/{taskId}/issues', // 保存填报数据
    get_filling_data: HOST.api + 'esg/subtasks/{taskId}/issues/{informantId}', // 获取填报数据
    filling_data_notify: HOST.api + 'esg/subtasks/{taskId}/issues/notify', // 填报数据保存并通知
    filling_data_submitAudit: HOST.api + 'esg/subtasks/{taskId}/issues/audit', // 提交填报数据
    report_years: HOST.api + 'esg/organizations/{organizationId}/reports/year', // 获取报告可选年份列表
    organizations_reports: HOST.api + 'esg/organizations/{organizationId}/reports', // 获取报告列表
    generate_report: HOST.api + 'esg/organizations/reports/generation', // 生成报告
    delete_report: HOST.api + 'esg/organizations/reports/{reportId}', // 删除报告
    contact_record: HOST.api + 'esg/user/contact/record', // 记录点击咨询服务按钮次数
    permission_control_list: HOST.api + 'esg/companies/{companyId}/permissions', // 获取权限设置列表
    add_esg_member: HOST.api + 'esg/companies/{companyId}/members/permissions' // 添加/删除ESG用户组
  },
  // 舆情
  sentiment: {
    sentiment_csrc_industries: HOST.api + 'sentiment/csrc/industries', // 获取所有证监会行业，传入公司代码可获取当前公司所属行业
    chart_sentiment_and_stock_trend: HOST.api + 'sentiment/companies/sentiment_and_stock_trend', // 舆情与股价走势
    chart_sentiment_tendency: HOST.api + 'sentiment/companies/sentiment/distribution', // 公司或行业舆情情感分布
    list_company_sentiments: HOST.api + 'sentiment/company/sentiment/search', // 公司舆情搜索
    industry_company_sentiments: HOST.api + 'sentiment/industry/sentiment/search', // 行业舆情搜索
    sentiment_monitor_plan: HOST.api + 'sentiment/companies/{companyId}/sentiment/monitor/plans', // 获取/新增公司默认舆情方案
    operate_sentiment_monitor_plans: HOST.api + 'sentiment/company/sentiment/monitor/plans/{planId}', // 操作舆情监控方案
    sentiment_report: HOST.api + 'sentiment/companies/{companyId}/sentiment/reports',
    delete_sentiment_report: HOST.api + 'sentiment/company/sentiment/reports/{id}',
    company_sentiments: HOST.api + 'sentiment/search/by_filter',
    sentiment_company_info: HOST.api + 'company/companyInfo',
    get_sentiment_message_list_by_key: HOST.api + 'sentiment/monitor/plans/{planId}', // 获取推送舆情的消息列表
    compare_company_sentiments: HOST.api + 'sentiment/compare_company/search/by_filter', // 查询可比公司
    sentiment_error_correction: HOST.api + 'sentiment/feedback/error_correction', // 情感纠错（列表情感纠错）
    sentiment_surveillance_system: HOST.api + 'sentiment/feedback/surveillance_system', // 舆情监控系统及其他反馈入口(侧边悬浮按钮)
    get_traceability_list: HOST.api + 'sentiment/traceability/list', // 溯源分析
    get_sentiment_user_preferences: HOST.api + 'sentiment/user/{companyId}/preferences', // 获取舆情用户偏好
    update_sentiment_user_preferences: HOST.api + 'sentiment/user/{companyId}/preferences/update', // 更新用户偏好设置
    get_company_lasted_sentiment: HOST.api + 'sentiment/companies/sentiment/latest_sentiments', // 公司最新舆情
    get_company_media_type_statics: HOST.api + 'sentiment/companies/sentiment/media_type/statistics', // 公司各渠道舆情分布
    get_media_num_statics: HOST.api + 'sentiment/companies/sentiment/source/statistics', // 公司舆情媒体量分布
    get_media_change_trend: HOST.api + 'sentiment/companies/sentiment/source/change', // 公司舆情媒体量变化趋势
    get_source_trend_change: HOST.api + 'sentiment/companies/sentiment/media_type/change', // 公司各渠道舆情变化趋势
    get_excel_url: HOST.api + 'sentiment/companies/sentiment/download/{companyId}/excel', // 公司舆情导出表格
    get_excel_task: HOST.api + 'sentiment/company_sentiment/download_path/by_task_id', // 获取任务进度
    get_sentiment_details: HOST.api + 'sentiment/{sentimentId}/details', // 获取舆情详细信息
    judge_is_business_customers: HOST.api + 'sentiment/judge/{companyId}/business_customer', // 判断业务客户
    chart_industry_sentiment_tendency: HOST.api + 'sentiment/industry/sentiment/{companyId}/distribution', // 行业情感分布图
    get_industry_lasted_sentiment: HOST.api + 'sentiment/industry/sentiment/{companyId}/latest_sentiments', // 行业最新舆情
    get_industry_hot_sentiment: HOST.api + 'sentiment/industry/sentiment/{companyId}/hot_company', // 行业热门舆情
    get_industry_emotion_trend: HOST.api + 'sentiment/industry/sentiment/{companyId}/tendency_trend', // 行业舆情情感趋势
    get_industry_media_distribution: HOST.api + 'sentiment/industry/sentiment/source/{companyId}/statistics', // 行业舆情情感分布
    get_industry_media_change_trend: HOST.api + 'sentiment/industry/sentiment/source/{companyId}/change', // 行业舆情媒体量变化趋势
    get_industry_source_sentiment: HOST.api + 'sentiment/industry/sentiment/media_type/{companyId}/statistics', // 行业各舆情渠道分布
    get_industry_source_change_trend: HOST.api + 'sentiment/industry/sentiment/media_type/{companyId}/change', // 行业各渠道舆情变化趋势
    get_compare_company_sentiment_list: HOST.api + 'sentiment/comparison/search', // 可比公司舆情查询
    get_compare_company_sentiment_distribution: HOST.api + 'sentiment/compare/company/distribution', // 可比公司舆情数据
    get_compare_company_lasted_sentiment: HOST.api + 'sentiment/compare/company/latest_sentiments', // 可比公司最新舆情
    get_compare_company_hot_sentiment: HOST.api + 'sentiment/compare/company/hot_company', // 可比公司热门舆情
    get_compare_company_emotion_trend: HOST.api + 'sentiment/compare/company/tendency_trend', // 可比公司舆情情感趋势
    get_compare_company_media_distribution: HOST.api + 'sentiment/compare/company/source/statistics', // 可比公司媒体分布
    get_compare_company_media_change_trend: HOST.api + 'sentiment/compare/company/source/change', // 可比公司媒体变化趋势
    get_compare_company_source_sentiment: HOST.api + 'sentiment/compare/company/media_type/statistics', // 可比公司舆情来源
    get_compare_company_source_change_trend: HOST.api + 'sentiment/compare/company/media_type/change', // 可比公司舆情来源变化趋势
    get_all_net_sentiment_list: HOST.api + 'sentiment/global/search', // 全网舆情查询
    list_market_sentiments: HOST.api + 'sentiment/market/search', // 市场舆情搜索
    get_custom_sentiment_list: HOST.api + 'sentiment/custom/search', // 自定义舆情
    get_sentiment_monitor_List: HOST.api + 'sentiment/companies/{companyId}/sentiment/monitor/default_plans', // 获取监控方案列表
    get_market_trace_analysis: HOST.api + 'sentiment/market/traceability/list', // 资本市场溯源分析
    sentiment_reports_generate: HOST.api + 'sentiment/companies/{companyId}/sentiment/reports/generate', // 生成舆情报告
    get_traceability_companyId: HOST.api + 'sentiment/traceability/{rel}/companyIds', // 获取溯源分析公司
    get_sentiment_report_dialog: HOST.api + 'sentiment/comprehensive/search', // 获取溯源分析公司
    get_sentiment_summary_remain_count: HOST.api + 'sentiment/summary/remain/count', // 获取生成摘要剩余次数
    company_sentiment_summary: HOST.api + 'sentiment/company/sentiment/{sentimentId}/summary', // 获取公司舆情摘要
    market_sentiment_summary: HOST.api + 'sentiment/market/sentiment/{sentimentId}/summary' // 获取市场舆情摘要
  },
  // 信披事项
  disclosure: {
    add_info_closure_item_main: HOST.api + 'disclosure_list/items', // 增加信批事项
    operate_disclosure_list_items: HOST.api + 'disclosure_list/items/{itemId}', // 删除信批事项
    get_info_disclosure_event_by_company_id: HOST.api + 'disclosure_list/company/{companyId}/disclosures/items', // 查询系统事项模板
    get_info_disclosure_item_main: HOST.api + 'disclosure_list/user/v2/items', // 获取信批事项
    get_custom_template_by_user_id: HOST.api + 'disclosure_list/user/items/custom_template', // 查询自定义事项模板
    archived_item_main: HOST.api + 'disclosure_list/items/{itemId}/archive', // 归档
    get_enterprise_user_list: HOST.api + 'disclosure_list/companies/{companyId}/user', // 获取企业版用户信息
    generate_annual_financial_report: HOST.api + 'companies/{companyCode}/finances/annual_reports/generate', // 获取生成报告任务Id
    delete_doc_record: HOST.api + 'companies/finances/annual_reports/document_records/{id}', // 删除财务分析报告
    get_export_report_time_list: HOST.api + 'company/reports/export_times',
    get_doc_record_list: HOST.api + 'companies/finances/annual_reports/document_records',
    check_update: HOST.api + 'companies/{companyCode}/financial_report/check_update', // 检查财务分析报告是否被变更
    operate_custom_template: HOST.api + 'disclosure_list/items/custom_template', // 自定义模板
    operate_custom_template_by_id: HOST.api + 'disclosure_list/items/custom_template/{itemId}', // 删除/编辑自定义模板
    download_template_file: HOST.api + 'disclosure_list/items/{itemId}/download', // 根据事项id下载信息披露清单
    validte_disclosure_template_name: HOST.api + 'disclosure_list/items/check_template_name', // 校验模板名称是否重复
    validte_disclosure_issue_name: HOST.api + 'disclosure_list/items/check_item_name', // 检查事项名称是否存在的接口
    get_issue_detail: HOST.api + 'disclosure_list/user/items/{itemId}', // 获取事项详情
    check_can_archive_issue: HOST.api + 'disclosure_list/items/{itemId}/archive_enable', // 校验是否可以归档事项
    export_disclosure_item_events: HOST.api + 'disclosure_list/user/items/{itemId}/export' // 信披清单导出事项
  },
  // 公告编制工具
  announcement: {
    get_announcement_type_tree: HOST.api + 'announcement_preparation/announcement_type/tree/{organizationId}', // 获取公告类别树
    get_announcement_node_detail: HOST.api + 'announcement_preparation/announcement_node/detail/{nodeId}', // 获取详情信息
    download_all_file_template: HOST.api + 'announcement_preparation/announcement_templates/download/{nodeId}', // 下载某个节点下所有模板
    create_disclosure: HOST.api + 'announcement_preparation/announcement_templates/disclosure', // 创建拟披露事项
    get_disclosure_list: HOST.api + 'announcement_preparation/announcement_templates/query/disclosure', // 查询拟披露事项列表
    operate_disclosure: HOST.api + 'announcement_preparation/announcement_templates/disclosure/{disclosureId}', // 删除/获取拟披露事项
    get_meetings_files: HOST.api + 'meeting/organization/{organizationId}/meetings_files', // 获取当前公司下非创建会议阶段的所有会议以及会议文件
    get_signature_files_library: HOST.api + 'electronic-signature/files/item_file_library', // 电子签名文件库
    add_choose_file: HOST.api + 'announcement_preparation/announcement_file/choose/{disclosureId}', // 新增披露事项文件
    delete_announcement_file: HOST.api + 'announcement_preparation/disclosure/announcement_file/{disclosureId}/{fileId}', // 删除事项下的文件
    operate_announcement_file: HOST.api + 'announcement_preparation/disclosure/announcement_file/{disclosureId}', // 实时查询/更新披露事项文件列表
    set_announcement_file_order: HOST.api + 'announcement_preparation/disclosure/announcement_file/order/{disclosureId}', // 实时更新文件排序
    save_disclosure: HOST.api + 'announcement_preparation/announcement_templates/disclosure/save', // 保存拟披露事项
    download_disclosure: HOST.api + 'announcement_preparation/announcement_file/download/{disclosureId}', // 下载所选的文件
    get_all_disclosure: HOST.api + 'announcement_preparation/disclosure/query/archive/{organizationId}', // 查询所有归档事项列表（不带分页）
    operate_disclosure_point: HOST.api + 'announcement_preparation/disclosure/disclosure_point/{disclosureId}', // 实时查询/更新披露事项披露要点
    get_materials_submitted: HOST.api + 'announcement_preparation/disclosure/materials_submitted/{disclosureId}', // 获取事项报批材料
    get_announcement_file_by_task_id: HOST.api + 'announcement_preparation/disclosure/announcement_file/by_task_id', // 根据异步taskid获取文件oss地址
    announcement_preparation_document_management_files: HOST.api + 'announcement_preparation/document_management/files', // 文档管理中心获取公告编制工具事项文件接口
    document_management_files_upload: HOST.api + 'announcement_preparation/document_management/file/upload' // 保存文件
  },
  // 互动e
  interact: {
    top_ten_interact_summary: HOST.api + 'interactions/top_ten' // e互动TOP10
  },
  //  股票行情相关API
  stock: {
    get_stock_k_line_data: HOST.api + 'companies/{companyCode}/stock/k_line', //  获取K线
    get_stock_info_by_codes: HOST.api + 'company/stock/by_multi_code', // 批量获取行情（需传fullCode）
    list_same_industry_company: HOST.api + 'company/stocks/{companyId}/same_industry_company', // 申万 和证监会
    finance_same_industry_indicator: HOST.api + 'company/finance/same_industry/indicator', // 比较
    search_stock: HOST.api + 'user/attentions/stocks/search',
    attention_company: HOST.api + 'user/attentions/companies',
    delete_attention: HOST.api + 'user/attentions/companies/{companyCode}/cancel',
    batch_delete_attention: HOST.api + 'user/attentions/companies/one_key_cancel',
    get_stock_change_announcement: HOST.api + 'company/stock/undulation_announcements', // 获取股票异常波动公告
    get_stock_change_for_report_list: HOST.api + 'company/stock/undulation_reports', // 获取股票异常波动报告
    get_company_type_by_userId: HOST.api + 'company/user/company/type', //  获取用户当前公司类型
    stock_change_status: HOST.api + 'company/stocks/{code}/undulation_status', //  根据公司代码获取异动状态
    stock_change_calculate: HOST.api + 'company/stocks/{code}/realtime/undulation_calculate', //  根据公司代码获取异动详情
    get_company_fullCode: HOST.api + 'company/fullCode', //  获取公司fullCode
    redeem_reset_info: HOST.api + 'companies/convert_bond/{companyCode}/redeem_reset_info', //  可转债历史行情数据
    company_market_info: HOST.api + 'companies/{companyCode}', // 获取公司基本信息
    isTradeDate: HOST.api + 'company/stock/isTradeDate', // 判断当前日期是否为交易日
    stock_sw_industry: HOST.api + 'companies/stock/sw_industry', // 查询申万行业接口
    stock_k_line_v2: HOST.api + 'companies/stock/k_line/v2', //  获取K线v2
    k_line_option: HOST.api + 'companies/stock/k_line/index' // K线图指数下拉列表
  },
  // 资金
  capital: {
    get_company_capital_today: HOST.api + 'orgs/{companyCode}/capitals/today',
    company_tenDay_capitalFlow: HOST.api + 'companies/{companyCode}/capital/ten_day_flow'
  },
  message: {
    // get
    get_message_by_type: HOST.api + 'user/messages/sitemsg' //  分类查看推送消息
  },
  // 简况
  company: {
    get_realtime_quotes: HOST.api + 'company/stocks/{code}/dashboard/realtime/overview', // 实时行情
    get_company_districts: HOST.api + 'company/districts', // 获取省市列表
    get_company_info_by_company_code: HOST.api + 'org/companies/{companyCode}',
    get_convert_bond_company_info: HOST.api + 'companies/convert_bond/{code}' // 可转债债券基本信息
  },
  // 制度
  institution: {
    add_institutions_attachment: HOST.api + 'disclosure_list/institutions/attachment', // 保存公司规则制度附件
    select_all_for_company_catalog: HOST.api + 'disclosure_list/institutions/company_catalogs/{catalogId}',
    select_all_by_catalog_type: HOST.api + 'disclosure_list/institutions/catalogs/{catalogType}',
    search_list_company_catalog: HOST.api + 'disclosure_list/company_catalogs/search',
    edit_regulations: HOST.api + 'disclosure_list/institutions/regulations/{id}',
    company_catalogs_by_catalogId: HOST.api + 'disclosure_list/company_catalogs/{catalogId}',
    delete_regulations: HOST.api + 'disclosure_list/institutions/regulations/{regulationsId}',
    add_company_catalog: HOST.api + 'disclosure_list/company_catalogs',
    list_all_for_governance_cataLog: HOST.api + 'disclosure_list/institutions/governance_catalogs/v2/{catalogId}',
    document_management_files: HOST.api + 'disclosure_list/institutions/document_management/files'
  },
  // 团队收藏夹 Favourite
  teamFavourite: {
    get_team_default_favorite: HOST.api + 'users/default_team_favourite', // 获取团队默认收藏夹
    get_favourite_by_userId: HOST.api + 'users/favourite', // 根据用户Id获取收藏夹
    get_team_favourite_info_by_id: HOST.api + 'users/team_favourites/{favouriteId}', // 根据Id获取收藏夹信息
    users_favourites: HOST.api + 'users/favourites/{id}', // 团队收藏夹
    add_team_favourite: HOST.api + 'users/team_favourites' // 新建团队收藏夹
  },
  // 企业公司
  enterpriseCompany: {
    company_compare: HOST.api + 'orgs/{companyId}/compared_companies', // 获取、添加、删除可比公司
    switch_company: HOST.api + 'user/orgs/switch/{companyId}', // 切换公司
    search_company: HOST.api + 'orgs/compared_companies/search', // 根据关键字查询上市公司
    send_exit_sms: HOST.api + 'org/sms/exit_org',
    add_enterprise_order: HOST.api + 'org/enterprise_edition', // 添加续费企业订单
    exit_company: HOST.api + 'orgs/{companyId}/user/exit', // 退出公司,
    a_stock_listed_companies: HOST.api + 'companies/a_stock/listed', // 查询A股上市公司（过滤B股）
    search_infaith_company: HOST.api + 'org/user/served_companies/search', // 内部用户搜索服务公司
    add_infaith_company: HOST.api + 'org/internal_user/served_companies', // 添加内部用户服务公司
    delete_infaith_company: HOST.api + 'org/internal_user/served_companies', // 删除内部用户服务公司
    get_my_infaith_company_list: HOST.api + 'org/user/followed_companies', // 获取我关注的公司列表
    get_served_companies: HOST.api + 'org/user/served_companies/v2', // 获取服务公司
    set_top_service_company: HOST.api + 'org/internal_user/served_company/sticky', // 置顶服务公司
    cancel_set_top_service_company: HOST.api + 'org/internal_user/served_company/unSticky', // 取消置顶服务公司

    // 都是served开头的命名，由于历史原因，这里其实是获取的关注公司信息
    served_companies_market_info: HOST.api + 'companies/served_companies/statistics/market_info', // 获取服务公司行情数据
    served_companies_announce_info: HOST.api + 'companies/served_companies/statistics/announce_info', // 获取服务公司公告信息
    served_companies_sentiment_info: HOST.api + 'companies/served_companies/statistics/sentiment_info', // 获取服务公司舆情信息

    // 获取服务公司信息
    companies_dashboard_statistics_market_info: HOST.api + 'companies/dashboard/statistics/market_info', // 公司行情数据
    companies_dashboard_statistics_announce_info: HOST.api + 'companies/dashboard/statistics/announce_info', // 公司公告信息
    companies_dashboard_statistics_sentiment_info: HOST.api + 'companies/dashboard/statistics/sentiment_info', // 公司舆情信息

    get_infaith_company_sentiment_total: HOST.api + 'org/user/served_companies/sentiments/statistics', // 服务公司舆情统计
    independent_director_companies: HOST.api + 'org/independent_director_companies', // 获取任职公司
    set_independent_director_companies: HOST.api + 'org/independent_director_companies', // 设置任职公司
    get_dashboard_type: HOST.api + 'users/switch_dashboard', // 查询用户dashborad页面类型
    infaith_get_enterprise_user_list: HOST.api + 'equitychange/member', // 信公咨询-获取企业成员列表
    get_project_all_permission: HOST.api + 'equitychange/role_permission', // 获取项目角色权限
    get_project_role_permission: HOST.api + 'equitychange/role_permission/{roleId}', // 获取项目角色权限
    check_can_save_user: HOST.api + 'equitychange/member/pre_check', // 保存成员前置校验
    save_enterprise_user: HOST.api + 'equitychange/member', // 新增成员
    edit_enterprise_user: HOST.api + 'equitychange/members/{id}', // 编辑成员
    check_enterprise_user: HOST.api + 'equitychange/members/{id}', // 查看成员
    delete_enterprise_user: HOST.api + 'equitychange/members/{id}', // 删除成员
    get_enterprise_user_position: HOST.api + 'equitychange/members/position', // 岗位下拉选
    get_enterprise_user_sub_company: HOST.api + 'equitychange/members/sub_company', // 分部下拉选
    get_enterprise_user_company: HOST.api + 'equitychange/members/department', // 部门下拉选
    compared_companies_by_company_id_search: HOST.api + 'orgs/compared_companies_by_company_id/search', // 查询可比公司
    compared_companies_by_company_id: HOST.api + 'orgs/compared_companies_by_company_id', // 查询可比公司
    company_search_with_focus_on_info: HOST.api + 'company/user/focus_on_all_companies_search', // 企业-关注-查询全部公司
    focus_on_company_list_search: HOST.api + 'company/user/focus_on_company_list_search', // 企业-查询关注公司
    user_attentions_single: HOST.api + 'user/attentions/single', // 企业-增加、删除关注公司
    served_companies_search_v2: HOST.api + 'org/user/served_companies/search/v2', // 咨询公司-关注-查询全部
    followed_companies_search: HOST.api + 'org/user/followed_companies/search', // 咨询公司 查询关注公司
    served_companies_single: HOST.api + 'org/internal_user/served_companies_single', // 咨询-增加、删除关注公司
    independent_companies_search_v2: HOST.api + 'companies/independent_director/companies/search', // 独董-查询全部
    independent_director_companies_search: HOST.api + 'org/independent_director_companies_search', // 独董-查询服务公司
    independent_director_companies_single: HOST.api + 'org/independent_director_companies_single', // 独董-增加、删除服务公司
    supervised_companies_search: HOST.api + 'orgs/supervised_companies/search/v2', // 监管-查询监管公司
    supervised_companies_single: HOST.api + 'orgs/supervised_companies/single' // 监管-增加、删除监管公司

  },
  //  投资者关系
  investor: {
    interact_conditions: HOST.api + 'interact/search/conditions/v2', // 获取e互动搜索的筛选条件
    investor_search: HOST.api + 'interact/search',
    interact_user_questions: HOST.api + 'interact/user/questions',
    get_href_by_company_code: HOST.api + 'companies/{companyCode}/interactions/href', // 根据公司代码获取跳转链接
    get_relation_question: HOST.api + 'interactions/questions/{id}/related_questions', // 根据公司代码获取跳转链接
    get_question_detail: HOST.api + 'interactions/questions/{id}', // 问答详情
    get_invoster_question_company: HOST.api + 'interactions/questions/companies', // 问答详情
    get_market_average_summary: HOST.api + 'interact/market/average', // 获取市场平均数据
    get_max_question_count_user: HOST.api + 'companies/{companyCode}/interactions/most_questions_user', // 获取市场平均数据
    get_company_market_compare_data: HOST.api + 'companies/{companyCode}/interactions/compare/market', // 公司与行业对比数据接口
    get_company_index_from_market: HOST.api + 'interact/company/markets/rank', // 获取公司市场排名
    get_industry_index_data: HOST.api + 'companies/{companyCode}/interactions/industry/questions', // 获取同行业问答情况及公司排名
    get_company_summary_by_enum: HOST.api + 'companies/{companyCode}/interactions/summary', // 获取公司问答情况总览
    get_market_summary_by_enum: HOST.api + 'interact/market/summary', // 获取市场问答情况总览
    get_top_ten_by_type: HOST.api + 'interact/industries/top_ten', // 获取前十排行榜
    download_interact: HOST.api + 'interact/download', // e互动数据下载
    download_all_company_interact: HOST.api + 'interact/download/all' // 下载本公司全部数据
  },
  common: {
    get_advert_by_type: HOST.api + 'misc/adverts', // 获取广告详情
    get_advert_type_list: HOST.api + 'misc/advert_types', // 获取广告分类
    get_advert_banner: HOST.api + 'misc/adverts/banner', // 获取广告banner
    get_web_popup: HOST.api + 'misc/web/popup', // 获取宣传弹窗配置
    web_popup_click_count: HOST.api + 'misc/web/click_count', // 弹窗点击统计
    user_preference: HOST.api + 'user/preference', // 查询用户的偏好设置
    update_user_preference: HOST.api + 'user/preference/update', // 保存用户偏好设置
    related_company_search: HOST.api + 'companies/related_company/search' // 根据关键词模糊查找公司
  },
  superviseCompany: {
    supervise_company_list: HOST.api + 'orgs/supervised_companies/batch', // 批量处理监管公司
    get_all_supervise_company_by_user: HOST.api + 'user/orgs/{organizationId}/supervised_companies', // 通过userId获取用户所有的监管公司
    get_supervise_company_list: HOST.api + 'companies/{organizationId}/supervised_companies/statistics', // 通过userId搜索用户的监管公司行情等详细信息
    get_supervise_company_sentiment_total: HOST.api + 'user/orgs/{organizationId}/supervised_companies/sentiment_statistics', // 获取监管公司舆情统计
    search_company_by_keyword: HOST.api + 'orgs/supervised_companies/search' // 根据关键词搜索上市公司
  },
  // 法规笔记
  lawnote: {
    law_notes: HOST.api + 'laws/law_notes', // 新增/搜索法规笔记
    update_law_notes: HOST.api + 'laws/law_notes/{noteId}', // 更新笔记
    batch_law_notes: HOST.api + 'laws/law_notes/batch', // 批量更新/删除笔记
    xiaoan_note_permission: HOST.api + 'laws/law_notes/user_permission', // 检查是否有创建小安笔记权限
    law_notes_laws: HOST.api + 'laws/law_notes/laws' // 查询做了笔记的法规
  },
  publish: {
    get_ipo_industries: HOST.api + 'ipo/industries' // 获取 IPO 行业筛选条件
  },
  yunyi: {
    translate_file: HOST.api + 'misc/translation', // 云译文件接口
    delete_translate_file: HOST.api + 'misc/translations/{cloudId}', // 删除文件接口
    add_evaluate: HOST.api + 'misc/translation/evaluations', // 添加评价接口
    remaining_str: HOST.api + 'misc/cloud_translation/remaining_str', // 获取用户使用云译的剩余可用字符数
    trans_wai_token: HOST.api + 'misc/cloud_translation/trans_wai_token', // 获取token信息
    trans_statics: HOST.api + 'misc/cloud_translation/statics', // 云译页面数据埋点接口
    trans_purchase_url: HOST.api + 'misc/cloud_translation/purchase_url', // 获取购买链接
    trans_tongchuan_url: HOST.api + 'misc/cloud_translation/tongchuan_url', // 获取云译同传跳转url
    misc_translation_file: HOST.api + 'misc/translation/file' // 翻译上传的文件
  },
  authority: {
    get_menu_info: HOST.api + 'user/menus' // 用户菜单权限接口
  },
  errorCollection: {
    error_collection_to_ding: HOST.api + 'message/dingding/frontend_alarm/v2' // 发送网络请求错误到钉钉
  },
  signature: {
    check_signature_name: HOST.api + 'electronic-signature/items/check', // 创建事项 - 事项名称前置校验
    create_signature: HOST.api + 'electronic-signature/items', // 创建新的电子签事项
    signature_item: HOST.api + 'electronic-signature/items/{itemId}', // 电子签名事项
    save_signature_item: HOST.api + 'electronic-signature/items/draft', // 保存电子签事项
    save_sign_users: HOST.api + 'electronic-signature/items/{itemId}/sign/user', // 保存电子签事项的签名人员
    signature_item_file: HOST.api + 'electronic-signature/items/files/{fileId}', // 签名文件信息
    signature_status: HOST.api + 'electronic-signature/files/{fileId}/signature_status', // 根据文件id获取文件相关合同签署情况
    seal_status: HOST.api + 'electronic-signature/files/{transactionId}/seal_status', // 根据transactionId获取文件相关合同用印情况
    file_preview_url: HOST.api + 'electronic-signature/file_preview_url', // 根据私有url获取预览url
    check_authenticated_seal_org: HOST.api + 'electronic-signature/authenticated_seal_org/check', // 校验当前profile下是否存在已经实名认证的企业
    check_admin: HOST.api + 'electronic-signature/user/check_admin', // 校验用户是否是管理员
    save_sign_position: HOST.api + 'electronic-signature/files/{fileId}/sign/position', // 保存签名文件的签名位置等详情
    send_signature: HOST.api + 'electronic-signature/items/official', // 发起电子签事项
    save_file_library_select: HOST.api + 'electronic-signature/items/files', // 保存文件库中选择的文件列表
    get_sign_files_by_itemId: HOST.api + 'electronic-signature/items/{itemId}/files', // 单独获取文件列表
    batch_file_upload_task: HOST.api + 'electronic-signature/batch_file_upload_task', // 根据批量上传批次id获取对应任务id
    get_signature_items: HOST.api + 'electronic-signature/items', // 事项列表
    get_sign_item_detail: HOST.api + 'electronic-signature/items/{itemId}/sign/status', // 获取签名事项详情
    get_undirected_sign_item_detail: HOST.api + 'electronic-signature/items/{itemId}/undirected_sign/status', // 获取非定向签名事项详情
    undirected_terminate: HOST.api + 'electronic-signature/files/{fileId}/terminate', // 非定向-标记为手工签署
    terminate_signing: HOST.api + 'electronic-signature/items/{itemId}/terminate_signing', // 签名详情页面 - 终止签署
    genarate_pack_file: HOST.api + 'electronic-signature/items/{itemId}/files/packaging', // 打包签名文件
    delete_sign_file_after_sponsor: HOST.api + 'electronic-signature/items/files/{fileId}/sign', // 删除签名文件
    item_validation: HOST.api + 'electronic-signature/items/{itemId}/validation', // 发起事项前的校验
    file_sign_reminder: HOST.api + 'electronic-signature/files/{fileId}/sign/send_reminder', // 发送文件签署提醒
    delete_issue_remind_info: HOST.api + 'electronic-signature/items/notify_info/{itemId}', // 删除事项提醒信息
    seal_file: HOST.api + 'electronic-signature/items/{itemId}/seal_file', // 获取事项用印文件列表
    signature_sms_record: HOST.api + 'electronic-signature/items/{itemId}/sms_record', // 获取事项短信记录
    re_send_sms: HOST.api + 'electronic-signature/sms/{smsId}/re_send_sms', // 重新发送短信
    file_upload_result: HOST.api + 'electronic-signature/items/{itemId}/file_upload_result', // 轮询查询文件上传状态接口
    batch_del_upload_failed_file: HOST.api + 'electronic-signature/items/{itemId}/upload_failed_file', // 删除事项下上传失败的文件
    signature_filing: HOST.api + 'electronic-signature/items/{itemId}/filing', // 归档事项
    download_summary_signature: HOST.api + 'electronic-signature/files/{contractId}/download_url', // 根据contractId获取合同下载地址 - 目前仅支持汇总签
    electronic_signature_upload_files: HOST.api + 'electronic-signature/upload/files', // 保存上传文件

    get_sign_info: HOST.api + 'electronic-signature/sign_info', // 获取公司签署信息
    pre_check: HOST.api + 'electronic-signature/{itemId}/deposit_confirmation_collection/pre_check', // 判断全部存证函文件是否生成完毕
    deposit_confirmation_collection: HOST.api + 'electronic-signature/{itemId}/deposit_confirmation_collection', // 导出全部存证函
    deposit_confirmation_collection_v2: HOST.api + 'electronic-signature/{itemId}/deposit_confirmation_collection/v2', // 导出全部存证函-新
    preview_url: HOST.api + 'electronic-signature/preview_url', // 根据ossKey获取临时地址
    set_manual_sign_users: HOST.api + 'electronic-signature/files/{fileId}/manual_signature', // 设置手工签名人员名单
    batch_notification: HOST.api + 'electronic-signature/items/{itemId}/sign/send_reminder', // 批量发送文件签署提醒
    change_need_sign_status: HOST.api + 'electronic-signature/file/need_to_sign', // 更改文件是否需要签署状态接口
    get_folders: HOST.api + 'electronic-signature/items/folders', // 获取事项文件夹列表
    save_folder: HOST.api + 'electronic-signature/item_folder', // 保存事项文件夹
    delete_folders: HOST.api + 'electronic-signature/items/folders/{folderId}', // 删除事项文件夹
    move_signature_to_folder: HOST.api + 'electronic-signature/items/folder', // 移动事项至文件夹
    deposit_confirmation_export: HOST.api + 'electronic-signature/{itemId}/files/{fileId}/deposit_confirmation/export', // 非定向
    undirected_sign_deposit_collection: HOST.api + 'electronic-signature/{itemId}/files/{fileId}/deposit_confirmation_collection', // 非定项-签名存正下载
    fileOrder: HOST.api + 'electronic-signature/items/{itemId}/file_order', // 更新拖拽文件顺序
    check_user_is_admin_or_initiator: HOST.api + 'electronic-signature/items/{itemId}/draft/operation_authority/check', // 草稿事项操作权限校验
    send_reminder_pre_check: HOST.api + 'electronic-signature/items/{itemId}/sign/send_reminder_pre_check', // 一键发送签署提醒前置校验
    get_unSign_users: HOST.api + 'electronic-signature/items/{itemId}/unSign_users', // 获取事项未签署完成用户
    re_initiate_signature: HOST.api + 'electronic-signature/items/{itemId}/re_initiate', // 重新发起事项
    re_initiate_matter: HOST.api + 'electronic-signature/items/{itemId}/files/re_initiate', // 重新发起事项下部分文件
    re_initiate_del_matter: HOST.api + 'electronic-signature/items/{itemId}/files/sign', // 已发起事项 - 批量文件删除
    get_sensitive_words: HOST.api + 'message/sensitive_words', // 敏感词列表
    set_re_send_custom_sms: HOST.api + 'electronic-signature/sms/re_send_custom_sms', // 自定义内容重发
    electronic_signature_get_importable_files: HOST.api + 'electronic-signature/files/importable', // 获取可导入文件列表
    electronic_signature_check_import_file: HOST.api + 'electronic-signature/import_file/check', // 导入校验接口
    electronic_signature_import_sign_user: HOST.api + 'electronic-signature/items/{itemId}/sign/user/import', // 导入签名人员接口

    // 企业用印管理
    organization_authentication: HOST.api + 'electronic-signature/organization_authentication', // 添加企业认证
    credit_code: HOST.api + 'paperless/credit_code', // 根据公司代码获取统一社会信用代码
    bank_list: HOST.api + 'paperless/bank_list', // 获取开户行列表
    verify_organization_information: HOST.api + 'paperless/verify_organization_information', // 进行企业信息验证
    initiate_payment: HOST.api + 'paperless/initiate_payment', // 发起打款
    verify_payment_amount: HOST.api + 'paperless/verify_payment_amount', // 验证打款金额
    verify_amount: HOST.api + 'paperless/verify_amount', // 验证打款金额
    organization_authentication_status: HOST.api + 'paperless/organization_authentication_status', // 查询企业实名认证状态信息
    sign_verify: HOST.api + 'paperless/sign_verify', // 验签
    seal_organization: HOST.api + 'electronic-signature/seal_organization', // 获取用印企业列表
    delete_seal_organizations: HOST.api + 'electronic-signature/seal_organizations/{sealOrganizationId}', // 删除用印企业
    organization_re_authentication: HOST.api + 'electronic-signature/organization_re_authentication/{sealOrganizationId}', // 企业认证成功后重新认证
    seal_record: HOST.api + 'electronic-signature/seal_organizations/{sealOrganizationId}/seal_record', // 获取企业用印记录
    continueCertification: HOST.api + 'electronic-signature/organization_authentications_continue_url/{sealOrganizationId}', // 获取继续认证链接
    contractInfo: HOST.api + 'paperless/contracts/contract_info', // 获取合同信息
    get_seal_pic_url: HOST.api + 'paperless/seals/seal_pic_url', // 获取印章图片临时访问地址
    redirect_url: HOST.api + 'paperless/org_authentication/redirect_url', // 获取企业实名认证重定向地址

    // 印章管理
    get_seals: HOST.api + 'electronic-signature/seal_organizations/{sealOrganizationId}/seals', // 获取印章列表
    set_seal: HOST.api + 'electronic-signature/seal', // 保存印章信息
    get_seal_manager: HOST.api + 'electronic-signature/seal_organizations/{sealOrganizationId}/seal_manager', // 获取企业印章管理员
    set_seal_manager: HOST.api + 'electronic-signature/seal_organizations/{sealOrganizationId}/seal_manager', // 设置新企业印章管理员
    check_deal: HOST.api + 'electronic-signature/seals/{id}/check', // 校验印章是否在途
    delete_seal: HOST.api + 'electronic-signature/seals/{id}', // 删除印章
    get_seal_info: HOST.api + 'electronic-signature/seals/{id}', // 获取印章信息
    get_seal_organizations: HOST.api + 'electronic-signature/seal_organizations/seals', // 获取企业下所有印章
    get_seal_authorized_users: HOST.api + 'electronic-signature/seals/{sealId}/seal_authorized_users', // 获取印章授权人列表
    fdd_item_remain_check: HOST.api + 'electronic-signature/fdd_item_remain_check', // 校验是否有法大大遗留事项

    paperless_sign_status: HOST.api + 'paperless/sign/status', // 查询签署相关状态
    check_initiation_status: HOST.api + 'electronic-signature/items/{itemId}/check_initiation_status', // 校验事项发起状态
    save_seal_only_result: HOST.api + 'electronic-signature/items/{itemId}/seal_only', // 仅用印勾选结果保存
    seal_only_pre_check: HOST.api + 'electronic-signature/items/{itemId}/seal_only_pre_check', // 仅用印校验
    change_file_to_seal_only: HOST.api + 'electronic-signature/items/{itemId}/change_file_to_seal_only', // 事项下文件变更为仅用印
    electronic_signature_document_management_files: HOST.api + 'electronic-signature/document_management/files' // 文档管理中心获取电子签名文件接口
  },
  onlineTest: {
    online_test_user: HOST.api + 'online_test/user',
    get_assciation_info: HOST.api + 'online_test/associations',
    update_user_info: HOST.api + 'online_test/user/associations',
    get_test_property_info_by_test_name: HOST.api + 'online_test/tests/{testName}/properties',
    start_exercise: HOST.api + 'online_test/user/tests/{testName}/exercise/start_time',
    get_user_exercise_status: HOST.api + 'online_test/user/tests/{testName}/exercise/status',
    get_user_exercise_info: HOST.api + 'online_test/user/tests/{testName}/exercise',
    get_execrise_result_info: HOST.api + 'online_test/user/tests/{testName}/exercise/result',
    record_user_exercise_info: HOST.api + 'online_test/user/tests/exercise/{paperId}',
    get_related_courses: HOST.api + 'online_test/tests/{testName}/related_courses',
    get_answer_detail: HOST.api + 'online_test/user/tests/{testName}/answers',
    get_available_question_bank: HOST.api + 'online_test/available_question_bank', // 获取用户可使用的题库
    get_question_bank: HOST.api + 'online_test/question_bank'// 获取在线测试题库
  },
  onlineService: {
    online_service_customer: HOST.api + 'misc/customer', // 获取访问用户详情信息
    online_service_tag: HOST.api + 'misc/user/tags/sync/qiyu', // 更新用户在七鱼的标签信息
    access_detail: HOST.api + 'misc/user/access/path' // 保存用户路径
  },
  // 市值分析
  capAnalysis: {
    list_other_industry_cap_day_distribute: HOST.api + 'companies/{companyCode}/industry/market_value', // 分页获取其他行业日统计数据
    get_max_market_value: HOST.api + 'company/cap_analysis/max_market_value', // 获取最大市值
    cap_distribution_of_AShare: HOST.api + 'company/cap_analysis/a_share/distribution', // A股市场整体市值分布
    cap_variation_chart: HOST.api + 'companies/{companyCode}/cap_analysis/variation_chart', // 公司市值变动概览图表
    cap_statistic_chart: HOST.api + 'companies/{companyCode}/cap_analysis/statistic_chart', // 公司市值变动情况表格
    related_index_day_pctChange: HOST.api + 'companies/{companyCode}/cap_analysis/daily_price_limit', // 相关指数涨跌变动概览图表
    related_index_range_pctChange: HOST.api + 'companies/{companyCode}/cap_analysis/price_change_limit', // 相关指数涨跌变动表格
    stock_trend_and_significant_event_announcement: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/announcement', // 重大事项图表 公告
    stock_trend_and_significant_event_case_law: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/case_law', // 重大事项图表 违规事项
    stock_trend_and_significant_event_report: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/report', // 重大事项图表 研究报告
    stock_trend_and_significant_event_sentiment: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/sentiment', // 重大事项图表 公司舆情
    stock_trend_and_significant_event_stock: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/stock', // 重大事项图表 股价走向信息
    stock_trend_and_significant_event_stock_change: HOST.api + 'companies/{companyCode}/cap_analysis/stock_trend_and_significant_event/stock_change', // 重大事项图表 异动情况
    stock_price_rang_overview: HOST.api + 'company/{companyCode}/cap_analysis/stock_price_rang', // 查询股价变动区间概览
    annual_dividend_overview: HOST.api + 'companies/{companyCode}/cap_analysis/annual_dividend', // 公司最近N年现金分红概览
    significant_announcements: HOST.api + 'companies/{companyCode}/cap_analysis/significant_announcements', // 重大事项-公告
    significant_reports: HOST.api + 'companies/{companyCode}/cap_analysis/significant_reports', // 重大事项-研报
    significant_caseLaws: HOST.api + 'companies/{companyCode}/cap_analysis/significant_case_laws', // 重大事项-违规案例
    significant_sentiments: HOST.api + 'companies/{companyCode}/cap_analysis/significant_sentiments', // 重大事项-舆情
    rating_changes: HOST.api + 'research_report/rating_changes_type', // 研报-评级调整
    analysts_forecast: HOST.api + 'company/cap_analysis/analysts_forecast', // 分析师预测情况接口
    analysts_forecast_detail: HOST.api + 'company/cap_analysis/analysts_forecast/detail', // 分析师预测表接口
    forecast_chart: HOST.api + 'company/cap_analysis/forecast_chart', // 归母净利润接口
    forecast_describe: HOST.api + 'company/cap_analysis/forecast_describe', // 业绩预测 描述接口
    indicator_forecast: HOST.api + 'company/cap_analysis/indicator_forecast', // 归母净利润接口
    market_value_comparison: HOST.api + 'companies/{companyCode}/cap_analysis/market_value_comparison', // 市值概览对比
    market_value_comparison_v2: HOST.api + 'companies/{companyCode}/cap_analysis/market_value_comparison/v2', // 市值概览对比-新
    search_companies_company_info: HOST.api + 'companies/{companyCode}/cap_analysis/market_value_comparison/company_completion', // 模糊搜索可比公司
    valuation_situation_comparison: HOST.api + 'company/cap_analysis/valuation_situation_comparison', // 估值情况对比
    market_value_changes: HOST.api + 'company/cap_analysis/market_value_changes', // 市值变动图
    annual_dividends: HOST.api + 'company/cap_analysis/annual_dividends', // 年度分红情况
    stock_market: HOST.api + 'company/stocks/{code}/sh_market', // 公司类型 返回值是 boolean 沪市：true  其它:false
    cap_analysis_report: HOST.api + 'companies/{companyCode}/cap_analysis/report', // 导出分析报告
    report_export_option: HOST.api + 'companies/cap_analysis/report/export_option', // 获取市值分析报告导出选项
    distribution_daily_in_years: HOST.api + 'company/cap_analysis/a_share/distribution_daily_in_years', // 市场橄榄-统计分析图表数据
    get_scrc_level_two: HOST.api + 'companies/stock/industry/scrc_level_two', // 获取所有证监会二级行业
    get_sw_level_three: HOST.api + 'companies/stock/industry/sw_level_three', // 获取所有申万三级行业
    get_industry_interval_data: HOST.api + 'companies/cap_analysis/industry/interval_data' // 获取行业市值统计变动图数据

  },
  // 智能报告
  smart_report: {
    get_company_info: HOST.api + 'misc/sentiment_smart_reports/list_company/industry', // 获取公司信息
    delete_supplement_sentiments: HOST.api + 'sentiment/sentiment_smart_reports/supplement', // 删除智能报告
    update_supplement_sentiments: HOST.api + 'sentiment/sentiment_smart_reports/supplement', // 智能报告更新补录数据
    get_supplement_sentiments: HOST.api + 'sentiment/sentiment_smart_reports/supplement', // 获取舆情补录数据
    supplement_sentiment: HOST.api + 'sentiment/sentiment_smart_reports/supplement', // 智能报告补录数据
    sentiment_report: HOST.api + 'misc/sentiment_smart_reports/{reportId}', // 舆情报告 编辑、查看、复制并创建
    save_sentiment_report: HOST.api + 'misc/sentiment_smart_reports', // 舆情报告 保存
    initialization_required_reportId: HOST.api + 'misc/{organizationId}/director_office_summary_report/initialization_required_reportId', // 获取需要生成"html模块文本"的系统预生成报告id
    smart_reports: HOST.api + 'misc/{organizationId}/smart_reports', // 智能报告信息
    get_smart_report_task_id: HOST.api + 'misc/smart_report/reports/{companyId}/generate/{reportId}', // 获取下载智能报告的taskId
    financial_indicators: HOST.api + 'misc/smart_report/financial_indicators', // 获取可视化报告-财务分析模块筛选项
    get_shareholder_number: HOST.api + 'misc/smart_report/visualization_report/get_shareholder_number', // 可视化报告-获取股东数量
    get_top_ten_shareholder: HOST.api + 'misc/smart_report/visualization_report/get_top_ten_shareholder', // 可视化报告-获取前10大股东
    corporate_governance: HOST.api + 'misc/smart_report/corporate_governance', // 可视化报告-获取公司治理文案
    main_business: HOST.api + 'misc/visual_smart_report/{companyId}/main_business', // 获取主营收人信息
    financial_indicators_data: HOST.api + 'misc/smart_report/financial_indicators_data', // 获取可视化报告-财务分析模块筛选项
    market_performance_message: HOST.api + 'misc/smart_report/market_performance_message', // 可视化报告-业绩概览
    disclosure_date: HOST.api + 'misc/visual_smart_report/{companyId}/disclosure_date', // 获取定期报告实际披露日期
    reporting_period: HOST.api + 'orgs/{companyCode}/reports/reporting_period', // 获取定期报告实际披露日期
    director_office_summary_report: HOST.api + 'misc/{organizationId}/director_office_summary_report', // 创建董办总结报告
    operate_director_office_summary_reports: HOST.api + 'misc/director_office_summary_reports/{reportId}', // 删除、复制并创建董办总结报告
    get_director_office_summary_reports_taskId: HOST.api + 'misc/director_office_summary_reports/reports/{companyId}/generate/{reportId}', // 获取下载董办总结报告的taskId
    get_director_office_summary_reports_presets: HOST.api + 'misc/director_office_summary_reports/presets', // 获取报告预设值
    get_director_office_summary_reports_periods: HOST.api + 'misc/director_office_summary_report/periods', // 获取报告周期
    daily_samrt_report: HOST.api + 'misc/smart_reports/daily', // 编辑、查看、复制并创建日常报告
    visual_report: HOST.api + 'misc/smart_reports/visual'// 创建、编辑、查看、复制并创建可视化报告
  },
  // 独董助手
  independentDirector: {
    delete_and_switch: HOST.api + 'users/profiles/{profileId}', // 不再试用
    market_value_comparison: HOST.api + 'companies/cap_analysis/market_value_comparison', // 任职公司市值对比分析
    independent_director_companies_statistics: HOST.api + 'companies/independent_director_companies/statistics', // 任职公司市值对比分析
    companies_search: HOST.api + 'companies/search', // 查询所有上市公司
    market_value_changes: HOST.api + 'company/cap_analysis/market_value_changes/by_codes',
    independent_director_trial: HOST.api + 'user/combos/{comboId}/trial', // 点击试用
    query_combos: HOST.api + 'user/combos/{comboId}', // 查询套餐状态''
    active_profiles: HOST.api + 'users/active_profiles', // 自动切换独董
    latest_list: HOST.api + 'sentiment/independent_director/latest_list', // 获取最新的任职公司舆情（最近5条）
    by_all_markets_case: HOST.api + 'violation_case/by_all_markets', // 沪深北以及新三板违规案例查询
    by_all_markets_affiche: HOST.api + 'announcements/by_all_markets', // 沪深北以及新三板公告查询
    invoices_status: HOST.api + 'misc/invoices', // 获取用户开票进度
    user_orders: HOST.api + 'user/orders'// 查询我的购买记录
  },
  // 在线支付
  payment: {
    payment_commodities: HOST.api + 'payment/commodities', // 查询所有商品
    order_status: HOST.api + 'payment/orders/{orderNo}/status', // 轮训订单状态
    create_order: HOST.api + 'payment/orders', // 创建订单
    payment_order_status: HOST.api + 'payment/orders/{orderNo}', // 查询订单状态
    apply_invoice: HOST.api + 'misc/invoices' // 开发票
  },
  // 问询函件
  inquiryLetters: {
    inquiry_letters_search_v2: HOST.api + 'inquiry_letters/dashboard_search/v2', // 问询函-搜函件
    inquiry_letters_search: HOST.api + 'inquiry_letters/dashboard_search/v4', // 问询函-搜函件
    inquiry_question_answer_v3: HOST.api + 'inquiry_letters/question_answer/dashboard_search/v4', // 问询问答查询 用于PC端/H5问询函问答检索
    inquiry_letters_condition: HOST.api + 'inquiry_letters/filter_conditions_web', // 问询函件筛选项
    inquiry_letters_detail: HOST.api + 'inquiry_letters/{id}', // 问询函件详情
    get_all_inquiry_tags: HOST.api + 'inquiry_letters/getAllInquiryTags', // 获取问询函所有标签
    get_all_qa_inquiry_tags: HOST.api + 'inquiry_letters/inquiry_question_answer/getAllInquiryTags', // 获取问询函问答所有标签
    get_inquiry_question_answer_detail: HOST.api + 'inquiry_letters/getInquiryQuestionAnswerDetail/{id}/web', // 问询函件问答详情
    inquiry_letters_download: HOST.api + 'inquiry_letters/download/{id}' // 问询详下载
  },
  // IPO问询函件
  ipoInquiryLetters: {
    ipo_inquiry_letters_search: HOST.api + 'inquiry_letters/ipo_inquiry/dashboard_search', // IPO问询函-搜函件
    ipo_inquiry_question_answer: HOST.api + 'inquiry_letters/ipo_question_answer/dashboard_search', // IPO问询函-搜问答
    get_all_ipo_inquiry_tags: HOST.api + 'inquiry_letters/ipo_inquiry/getAllInquiryTags', // 获取IPO问询函-函件所有标签
    get_all_qa_ipo_inquiry_tags: HOST.api + 'inquiry_letters/ipo_inquiry_question_answer/getAllInquiryTags', // 获取IPO问询函-问答所有标签
    ipo_inquiry_conditions: HOST.api + 'inquiry_letters/ipo_inquiry/conditions', // IPO问询函-检索条件
    ipo_inquiry_company_complete: HOST.api + 'inquiry_letters/ipo_inquiry/conditions/getCompanyInfo', // IPO问询函-检索条件公司联想接口
    ipo_inquiry_detail: HOST.api + 'inquiry_letters/ipo_inquiry/{id}', // IPO问询函 函件详情数据
    ipo_inquiry_qa_detail: HOST.api + 'inquiry_letters/ipo_inquiry_question_answer/{id}/web', // IPO问询函 问答详情数据
    ipo_inquiry_progress: HOST.api + 'inquiry_letters/ipo_inquiry/inquiry_progress/{id}', // IPO问询函 函件 进程
    ipo_inquiry_progress_doc: HOST.api + 'inquiry_letters/ipo_inquiry/inquiry_progress_doc/{id}', // IPO问询函 函件文件
    ipo_inquiry_download: HOST.api + 'inquiry_letters/ipo_inquiry/download/{id}', // IPO问询函 函件文件下载
    ipo_inquiry_zip_download: HOST.api + 'inquiry_letters/ipo_inquiry_letter/download/{id}', // IPO问询函 函件压缩包下载
    ipo_inquiry_question_distribution: HOST.api + 'inquiry_letters/ipo_inquiry/question_distribution', // IPO问询函 统计分析 问题分布
    ipo_inquiry_statistical_company: HOST.api + 'inquiry_letters/ipo_inquiry/statistical_company', // IPO问询函 统计分析 被问询公司列表
    ipo_inquiry_dashboard_search_abstract: HOST.api + 'inquiry_letters/ipo_inquiry/dashboard_search/abstract', // IPO问询函  函件查询摘要
    ipo_inquiry_statistical_company_total: HOST.api + 'inquiry_letters/ipo_inquiry/statistical_company_total' // IPO问询函  统计分析 被问询公司总数
  },
  // 履职清单
  dutyList: {
    duty_list_topic: HOST.api + 'independent_director/performance_duty/topic', // 独董履职清单
    create_issue: HOST.api + 'independent_director/performance_duty/item', // 创建独董履职事项
    performance_duty_detail: HOST.api + 'independent_director/performance_duty/item/{itemId}', // 获取事项详情
    post_questionnaire: HOST.api + 'independent_director/performance_duty/questionnaire', // 新增询问表
    get_duty_company: HOST.api + 'independent_director/performance_duty/item/company', // 获取“进行中”/"已归档"的独董履职事项中的公司列表，供筛选项使用
    duty_search: HOST.api + 'independent_director/performance_duty/item/search', // 搜索进行中的独董履职事项
    delete_matter: HOST.api + 'independent_director/performance_duty/item/{itemId}', // 删除事项
    issue_filing: HOST.api + 'independent_director/performance_duty/item/{itemId}/filing', // 归档事项
    get_item_base_info: HOST.api + 'independent_director/performance_duty/item/{itemId}/point/{pointId}/title', // 获取事项的类型、议题和要点的标题信息
    get_item_operating_record: HOST.api + 'independent_director/performance_duty/item/{itemId}/point/{pointId}/operating_record', // 获取事项要点的操作记录
    get_item_communication_record: HOST.api + 'independent_director/performance_duty/item/{itemId}/point/{pointId}/communication_record', // 获取要点的沟通记录
    get_issue_communication_record: HOST.api + 'independent_director/performance_duty/item/{itemId}/questionnaire', // 获取事项的沟通记录
    get_issue_answer_record: HOST.api + 'independent_director/performance_duty/questionnaire/{questionnaireId}/record', // 获取问卷答复记录
    update_question_status: HOST.api + 'independent_director/performance_duty/item/{itemId}/questionnaire/{questionnaireId}/status' // 获取问卷答复记录
  },
  // 独董数据库
  independentDirectorDatabase: {
    report_years: HOST.api + 'independent_base_info/data_overview/report_years', // 获取年份
    data_overview: HOST.api + 'independent_base_info/data_overview/{reportYear}', // 查询数据概览
    independent_directors: HOST.api + 'independent_base_info/independent_directors', // 查询独立董事列表
    independent_director_database_conditions: HOST.api + 'independent_base_info/search/conditions' // 独立董事列表查询条件
  },
  // 信公咨询组和监管用户首页
  dashboardRegulation: {
    violate_cases: HOST.api + 'big-data/dashboard/companies/violate_cases', // 展示违规案例类型的饼图
    market_overviews: HOST.api + 'big-data/dashboard/market_overviews', // 首页市场概览相关的数据 包含新规和监管动态
    violation_cases: HOST.api + 'big-data/dashboard/warning/violation_cases', // 首页的预警 包含违规案例
    service_company_overviews: HOST.api + 'big-data/dashboard/service_company/overviews', // 首页的服务公司概览
    sentiment_count: HOST.api + 'sentiment/supervise/market_overview/sentiment_count', // 市场概览-市场舆情数量
    negative_sentiment_count: HOST.api + 'sentiment/supervised_companies/negative_sentiment_count', // 预警-负面舆情数量
    transaction_count: HOST.api + 'companies/supervised_companies/transaction_count', // 预警-异动数量
    transaction_count_stat: HOST.api + 'companies/supervised_companies/transaction_count_stat', // 预警-盘中盘后异动数量
    latest_market_value: HOST.api + 'companies/supervised_companies/latest_market_value', // 公司概览-总市值
    market_value_statistics: HOST.api + 'companies/supervised_companies/market_value/statistics' // 获取公司市值图
  },
  // 资源中心
  resourceCenter: {
    resource_center_click: HOST.api + 'business/resource_center/click', // 资源中心埋点统计
    white_papers: HOST.api + 'business/resource_center/white_papers', // 白皮书列表
    white_papers_detail: HOST.api + 'business/resource_center/white_paper/{id}', // 白皮书详情
    selected_publication: HOST.api + 'business/resource_center/selected_publication/search', // 精选刊物列表
    selected_publication_detail: HOST.api + 'business/resource_center/selected_publication/{id}', // 精选刊物详情
    publication_categories: HOST.api + 'business/resource_center/publication_categories', // 精选刊物类别
    activities: HOST.api + 'business/resource_center/activities' // 最新活动列表
  },
  // 定期报告复核
  reviewReport: {
    table_dicts: HOST.api + 'periodic_reports/{reportId}/table_dicts', // 获取表格菜单
    check_result_tables: HOST.api + 'periodic_reports/{reportId}/check_result_tables/{tableId}', // 获取具体表的复核结果
    selected_tables_check: HOST.api + 'periodic_reports/{reportId}/tables/check', // 指定表格重新复核
    search_conditions: HOST.api + 'periodic_reports/search_conditions', // 定期报告复核下拉框列表数据
    regular_reports: HOST.api + 'periodic_reports', // 定期报告列表页搜索
    delete_regular_reports: HOST.api + 'periodic_reports/{reportId}', // 删除定期报告
    edit_regular_reports: HOST.api + 'periodic_reports/{reportId}', // 编辑定期报告
    collaborator: HOST.api + 'periodic_reports/{reportId}/collaborator', // 编辑/获取报告的协作人（包含创建者）
    delete_oss_file: HOST.api + 'periodic_reports/oss_file/{fileId}', // 删除OSS上的定期报告
    get_additional_financial_data: HOST.api + 'periodic_reports/{reportId}/additional_financial_data', // 查询补录的财务指标数据
    save_additional_financial_data: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/additional_financial_data', // 保存补录的财务指标数据
    analysis_roll_v2: HOST.api + 'gxf/regularReport/register/analysis', // 解析定期报告名册文件
    get_latest_analysis_task_id: HOST.api + 'gxf/regularReport/register/analysis_task_id/latest', // 获取当前定期报告最新解析任务id
    get_upload_records: HOST.api + 'gxf/regularReport/register/upload_records', // 查询当前定期报告名册上传记录
    check_top_10_shareholders: HOST.api + 'periodic_reports/{reportId}/tables/check_top_10_shareholders', // 复核前10名股东持股情况
    check_single_table: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/check', // 复核单个表
    save_table_check_param: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/table_check_param', // 保存详情页用户对该表自定义选项，以用于复核单表
    get_table_check_param: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/table_check_param', // 查询用户的自定义复核参数
    ignore_cell_error: HOST.api + 'periodic_reports/{reportId}/check_result_tables/{tableId}/cell', // 忽略单元格错误
    post_additional_table: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/additional_table', // 上传用户在富文本编辑器中的上传的表格
    get_additional_table: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/additional_table', // 获取用户在富文本编辑器中的上传的表格
    analyze_excel_error_result: HOST.api + 'periodic_reports/{reportId}/tables/{tableId}/analyze_excel_error_result', // 查询解析excel失败原因
    reset_table_check_result: HOST.api + 'periodic_reports/{reportId}/check_result_tables/revert', // 重置表格复核状态
    regularReport_register_verify: HOST.api + 'gxf/regularReport/register/verify', // 定期报告名册类型校验
    regularReport_register_verify_result: HOST.api + 'gxf/regularReport/register/verify_tasks/{taskId}/verify_result', // 获取定期报告文件校验结果
    save_top_10_shareholders_param: HOST.api + 'periodic_reports/{reportId}/tables/top_10_shareholders_param', // 保存在详情页用户对前10名股东复核时的自定义选项
    no_review_save_additional_financial_data: HOST.api + 'periodic_reports/{reportId}/tables/save_additional_financial_data', // 保存主要财务数据用户录入数据(不复核)
    periodic_reports_check_result_tables: HOST.api + 'periodic_reports/{reportId}/check_result_tables', // 获取报告中所有表格数据
    delete_regularReport_roll: HOST.api + 'gxf/regularReport/{reportId}/register', // 删除定期报告所上传名册文件
    get_feature_release_note: HOST.api + 'periodic_reports/feature_release_note', // 获取功能更新日志
    edit_table_error_remark: HOST.api + 'periodic_reports/{reportId}/check_result_tables/{tableId}/errorRemark', // 编辑表格忽略错误备注
    disclosure_date_search: HOST.api + 'periodic_reports/disclosure_date/search', // 查询定期报告披露预约时间
    periodic_reports_report_dates: HOST.api + 'periodic_reports/report_dates', // 查询最近4个定期报告
    disclosure_date_excel: HOST.api + 'periodic_reports/disclosure_date/excel' // 导出定期报告披露预约时间
  },
  complianceAssistant: {
    submit_question: HOST.api + 'misc/chat/question', // 提交问题
    submit_law_question: HOST.api + 'misc/chat/law/question', // 合规助手提交问题
    cancel_generate: HOST.api + 'misc/chat/answer/{answerId}/cancel', // 停止生成
    session_list: HOST.api + 'misc/chat/{type}/session/list', // 会话列表
    session_detail: HOST.api + 'misc/chat/session/{sessionId}/detail', // 会话详情
    law_session_detail: HOST.api + 'misc/chat/law/session/{sessionId}/detail', // 合规助手会话详情
    save_session_info: HOST.api + 'misc/chat/session/{sessionId}', // 更新会话信息
    get_remain_count: HOST.api + 'misc/chat/{type}/remain/count', // 获取剩余提问次数
    chat_counting: HOST.api + 'misc/chat/counting' // 埋点统计
  },
  // 监控提醒助手
  monitor: {
    get_company_monitor_rules: HOST.api + 'company/monitor/rules', // 获取监控列表
    get_disable_rules_nums: HOST.api + 'company/monitor/disable/rules', // 获取未启用监控助手数量
    put_company_monitor_rules_status: HOST.api + 'company/monitor/rule/status/{id}', // 修改状态
    get_monitor_rule_template: HOST.api + 'company/monitor/rule/template/{id}', // 编辑监控规则
    check_monitor_name: HOST.api + 'company/monitor/rule/name/existence', // 重名检测
    check_monitor_existence: HOST.api + 'company/monitor/rule/existence', // 校验监控是否存在
    get_monitor_rule_template_change: HOST.api + 'company/monitor/rule/template', // 获取新的规则
    get_indicator_usage: HOST.api + 'company/monitor/indicator/usage', // 查看指标常用场景
    save_monitor_rule: HOST.api + 'company/monitor/rule', // 保存监控规则
    delete_monitor_rule: HOST.api + 'company/monitor/rule/{id}', // 删除自定义监控
    get_monitor_center_list: HOST.api + 'company/monitor/alarms', // 监控中心列表获取

    get_monitor_detail: HOST.api + 'company/monitor/data/event/detail', // 监控提醒详情
    download_monitor_data: HOST.api + 'company/monitor/data/result/export', // 下载行情数据

    get_range_transaction_data: HOST.api + 'company/monitor/data/range', // 获取区间交易数据
    get_range_transaction_detail: HOST.api + 'company/monitor/data/range/detail', // 区间交易数据-详情
    get_abnormal_event_data: HOST.api + 'company/monitor/data/event', // 异常事件数据查询
    get_monitor_indicator_data: HOST.api + 'company/monitor/data/indicator/data', // 参考指标数据
    get_monitor_data_usage: HOST.api + 'company/monitor/data/usage', // 获取指标常用场景
    monitor_data_range_export: HOST.api + 'company/monitor/data/range/export' // 区间交易数据-导出

  },
  planningResourcePool: {
    get_all_csrc_industry: HOST.api + 'companies/stock/scrc_industry', // 获取所有证监会行业，获取当前公司所属行业
    retrieve_case: HOST.api + 'companies/non_public_offering/equity_incentive/case/search', // 股权激励案例检索
    employee_case: HOST.api + 'companies/non_public_offering/esop/case/search', // 员工持股案例检索
    retrieve_case_detail: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/programme/detail', // 股权激励案例方案详情
    employee_case_detail: HOST.api + 'companies/non_public_offering/esop/cases/{caseId}', // 股权激励案例方案详情
    base_information: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/base_information', // 案例基本信息
    company_information: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/company_information', // 案例公司信息
    salaries_level: HOST.api + 'companies/non_public_offering/equity_incentive/company/dong_jian_gao/salaries_level', // 董监高薪酬水平
    finance_last_three_years: HOST.api + 'companies/non_public_offering/equity_incentive/company/finance/analysis_data/last_three_years', // 本公司三年财务数据分析
    finance_details_last_three_years: HOST.api + 'companies/non_public_offering/equity_incentive/company/finance/analysis_data/details/last_three_years', // 本公司三年财务数据详情
    add_compare_cases: HOST.api + 'companies/non_public_offering/equity_incentive/user/companies/{companyId}/contrastive_cases/{caseId}', // 增加新对比案例
    delete_compare_cases: HOST.api + 'companies/non_public_offering/equity_incentive/user/companies/{companyId}/contrastive_cases/{caseId}', //  删除新对比案例
    batch_delete_compare_cases: HOST.api + 'companies/non_public_offering/equity_incentive/user/companies/{companyId}/contrastive_case', // 批量删除对比案例
    contrastive_case: HOST.api + 'companies/non_public_offering/equity_incentive/user/companies/{companyId}/contrastive_case', // 当前用户案例库简要对比列表
    contrastive_detail: HOST.api + 'companies/non_public_offering/equity_incentive/user/contrastive_cases/contrastive_detail', // 股权激励案例对比明细
    employee_contrastive_detail: HOST.api + 'companies/non_public_offering/equity_incentive/user/employee_stock_ownership/contrastive_cases/contrastive_detail', // 员工持股计划案例对比明细
    equity_incentive_summary: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/summary', // 股权激励案例 - 方案概览
    process_stock_price: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/tools/{toolId}/process_stock_price', // 激励进程和公司股价数据
    process_detail: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/tools/{toolId}/progress', // 激励进程详情数据
    income_trial: HOST.api + 'companies/non_public_offering/equity_incentive/cases/{caseId}/income_trial', // 收益测算列表数据
    contrastive_cases_income_trial: HOST.api + 'companies/non_public_offering/equity_incentive/user/contrastive_cases/income_trial' // 获取股权激励对比案例-收益测算
  },
  noPublicOffering: {
    search: HOST.api + 'companies/non_public_offering/cases/search', // 非公开发行案例搜索
    time_line: HOST.api + '/companies/non_public_offering/case/{caseId}/time_line', // 非公开发行进程时间轴
    search_conditions: HOST.api + 'companies/non_public_offering/cases/search_conditions', // 下拉框全部数据
    tag_tree: HOST.api + 'companies/non_public_offering/cases/tag_tree', // 左侧筛选框
    no_public_offering_company_info: HOST.api + 'companies/non_public_offering/cases/{caseId}/company_info', // 非公开发行案例公司基本信息
    no_public_offering_over_view: HOST.api + 'companies/non_public_offering/cases/{caseId}/overview', // 非公开发行案例详情
    no_public_offering_related_transation: HOST.api + 'companies/non_public_offering/cases/{caseId}/related_transaction', // 是否构成关联交易
    no_public_offering_financial_data: HOST.api + 'companies/non_public_offering/cases/{caseId}/financial_data', // 案例公司财务数据
    no_public_offering_company_annual_dividend: HOST.api + 'companies/non_public_offering/cases/{caseId}/annual_dividend', // 年度分红
    no_public_offering_new_listed_overview: HOST.api + 'companies/non_public_offering/new_listed/{caseId}/overview', // 非公开发行新股上市发行详情
    no_public_offering_stock_price_trend: HOST.api + 'companies/non_public_offering/cases/{caseId}/stock_price_trend', // 非公开发行股价走势图
    no_public_offering_time_line: HOST.api + 'companies/non_public_offering/case/{caseId}/time_line', // 非公开发行进程时间轴
    no_public_offering_case_intermediary: HOST.api + 'companies/non_public_offering/cases/{caseId}/intermediary'// 获取中介机构信息
  },
  convertibleBonds: {
    convertible_bond_tag_tree: HOST.api + 'companies/convertible_bond/cases/tag_tree', // 左侧筛选框
    convertible_bond_search_conditions: HOST.api + 'companies/convertible_bond/cases/screening_condition', // 列表筛选条件
    convertible_bond_issue_case: HOST.api + 'companies/convertible/bond/issue/case', // 可转债案例列表
    convertible_bond_company_info: HOST.api + 'companies/convertible_bond/cases/{caseId}/company_info', // 根据案例id 查询对应的公司详情
    convertible_bond_overview: HOST.api + 'companies/convertible_bond/cases/{caseId}/overview', // 根据案例id 查询对应的案例详情
    convertible_bond_time_line: HOST.api + 'companies/convertible_bond/case/{caseId}/time_line', // 可转债行进程时间轴
    convertible_bond_cases_holder: HOST.api + 'companies/convertible_bond/cases/holder', // 获取十大持有人数据
    convertible_bond_cases_premium_rate: HOST.api + 'companies/convertible_bond/cases/premium_rate' // 获取转股溢价率数据
  },
  ipoCases: {
    current_company_default_industry: HOST.api + 'ipo/ipo_case/currentCompanyDefaultIndustry', // 获取Profile的公司行业信息
    nearly_year_ipo_industry_issue: HOST.api + 'ipo/ipo_case/nearlyYearIpoIndustryIssue', // 近一年行业平均IPO发行水平
    dashboard_ipo_list: HOST.api + 'ipo/ipo_case/dashboard', // Ipo Dashboard IPO 案例库
    ipo_case_filter_conditions: HOST.api + 'ipo/ipo_case/search/filter_conditions', // ipo案例库列表过滤条件
    ipo_case_search: HOST.api + 'ipo/ipo_case/search', // ipo案例库列表
    ipo_case_listing_standard: HOST.api + 'ipo/ipo_case/listing_standard_statistics', // ipo案例库上市标准
    issuance_status_statistics: HOST.api + 'ipo/ipo_case/issuance_status_statistics', // IPO发行情况统计
    fundraising_scale_statistics: HOST.api + 'ipo/ipo_case/fundraising_scale_statistics', // IPO募集规模统计
    process_stage_statistics: HOST.api + 'ipo/ipo_case/process_stage_statistics', // IPO案例库进程历时统计
    intermediary_issuance_statistics: HOST.api + 'ipo/ipo_case/intermediary_issuance_statistics', // 中介机构发行情况统计
    ipo_case_detail: HOST.api + 'ipo/ipo_case/detail/{id}', // 获取IPO案例库详情-公司信息
    ipo_case_inquiry_reply: HOST.api + 'ipo/ipo_case/inquiry_reply/{id}', // 获取IPO案例库详情-问询与回复
    ipo_case_financial_info: HOST.api + 'ipo/ipo_case/financial_info/{id}', // 获取IPO案例库详情-财务概述
    ipo_case_raised_funds_use: HOST.api + 'ipo/ipo_case/raised_funds_use/{id}', // 获取IPO案例库详情-募集资金用途
    ipo_case_company_process: HOST.api + 'ipo/ipo_case/company_process', // 获取IPO进程
    approved_statistics_trend: HOST.api + 'ipo/ipo_case/approved_statistics/trend', // 过会情况-趋势
    approved_statistics: HOST.api + 'ipo/ipo_case/approved_statistics/by_type' // 过会情况-统计
  },
  // 权益变动
  equityChange: {
    get_total_share: HOST.api + 'equitychange/companies/{orgId}/total_share', // 获取公司总股本
    get_share_ratio: HOST.api + 'equitychange/shareholders/{id}/share_ratio', // 获取股东持股比例
    create_base_info: HOST.api + 'equitychange/companies/{orgId}/shareholders/base_info', // 新增股东信息
    edit_shareholder_info: HOST.api + 'equitychange/shareholders/{id}/base_info', // 编辑股东信息
    transaction_record: HOST.api + 'equitychange/companies/{orgId}/shareholders/{shareholderId}/transaction_record', // 交易记录列表
    // create_important_point: HOST.api + 'equitychange/companies/important_point', // 新增重要时点
    get_compny_info: HOST.api + 'equitychange/companies/{orgId}/info', // 获取服务公司信息
    get_share_holder_statistics: HOST.api + 'equitychange/shareholders/{id}/share_statistics',
    get_share_holder_detail: HOST.api + '/equitychange/shareholders/share/{id}', // 获取股东具体股份数据
    add_share_holder_share: HOST.api + 'equitychange/shareholders/{id}/share', // 新增股东股份信息
    edit_share_holder_share: HOST.api + 'equitychange/shareholders/share/{id}', // 编辑股东股份信息
    delete_share_holder_share: HOST.api + 'equitychange/shareholders/share/{id}', // 删除股东股份信息
    edit_share_statistics: HOST.api + 'equitychange/shareholders/{id}/share_statistics', // 编辑股本股东总览信息
    del_share_holder_share: HOST.api + 'equitychange/shareholders/share/{id}',

    get_shareholder_information: HOST.api + 'equitychange/companies/{orgId}/shareholders', // 获取股东信息列表
    delete_shareholder_information_list: HOST.api + 'equitychange/shareholders/{id}', // 删除股东信息
    get_shareholder_base_info: HOST.api + 'equitychange/shareholders/{id}/base_info', // 获取股东信息
    get_company_information: HOST.api + 'equitychange/companies/{orgId}/info', // 获取公司信息
    edit_company_information: HOST.api + 'equitychange/companies/{orgId}/info', // 编辑公司信息
    get_window_period_list: HOST.api + 'equitychange/companies/{orgId}/window_events', // 获取公司窗口期列表
    edit_window_period_detail: HOST.api + 'equitychange/companies/window_events/{id}', // 编辑窗口期详情
    add_window_period_detail: HOST.api + 'equitychange/companies/window_event', // 新增窗口期详情
    delete_window_period_detail: HOST.api + 'equitychange/companies/window_events/{id}', // 删除窗口期
    get_shareholder_list: HOST.api + 'equitychange/companies/{shareholderId}/commitment/list', // 股东承诺列表
    add_shareholder_information: HOST.api + 'equitychange/companies/commitment', // 新增股东承诺
    delete_shareholder_information: HOST.api + 'equitychange/companies/{commitmentId}/commitment', // 删除股东承诺
    edit_shareholder_information: HOST.api + 'equitychange/companies/commitment', // 更新股东承诺
    get_concerted_action_person_list: HOST.api + 'equitychange/companies/{orgId}/shareholders/{shareholderId}/concerted_action_person', // 一致行动人列表
    add_concerted_action_person: HOST.api + 'equitychange/shareholders/concerted_action_person', // 新增一致行动人
    edit_concerted_action_person: HOST.api + 'equitychange/shareholders/concerted_action_persons/{id}', // 新增/编辑一致行动人
    delete_concerted_action_person: HOST.api + 'equitychange/concerted_action_person/{id}', // 删除一致行动人
    get_shareholders_concerted_action_person: HOST.api + 'equitychange/companies/{orgId}/shareholders/for_select', // 获取公司下股东列表
    get_violation_item_list: HOST.api + 'equitychange/companies/{shareholderId}/violation/list', // 重要违规事项列表
    add_violation_item: HOST.api + 'equitychange/companies/violation', // 新增违规事项
    delete_violation_item: HOST.api + 'equitychange/companies/{violationId}/violation', // 删除违规事项
    edit_violation_item: HOST.api + 'equitychange/companies/violation', // 更新违规事项
    consultation_items_compliance_view: HOST.api + 'equitychange/consultation_items/compliance/view/{id}', // 获取咨询事项 合规要点;修改咨询事项 合规要点
    consultation_items_procedure_view: HOST.api + 'equitychange/consultation_items/procedure/view/{id}', // 获取咨询事项 增持/减持 计算过程数据
    get_service_company_shareholder_info: HOST.api + 'equitychange/companies/{orgId}/shareholders/for_select', // 获取公司下股东列表 - 下拉选使用
    get_consultation_matters_detail: HOST.api + 'equitychange/consultation_item/{id}', // 获取咨询事项详情
    edit_consultation_matters_detail: HOST.api + 'equitychange/consultation_item/{id}', // 编辑咨询事项保存
    save_consultation_matters_detail: HOST.api + 'equitychange/consultation_item', // 新增咨询事项保存
    save_consultation_items_reply: HOST.api + 'equitychange/consultation_items/{id}/reply', // 新增咨询事项回复客户信息
    edit_consultation_items_reply: HOST.api + 'equitychange/consultation_items/{id}/reply', // 编辑回复客户信息
    get_consultation_items_reply: HOST.api + 'equitychange/consultation_items/{id}/reply', // 获取回复客户信息
    post_approval_consulting_matters: HOST.api + 'equitychange/consultation_items/{id}/approval', // 新增咨询事项审批
    get_approval_consulting_matters: HOST.api + 'equitychange/consultation_items/{id}/approval', // 获取事项下的所有审批
    edit_approval_consulting_matters: HOST.api + 'equitychange/consultation_items/{id}/approval', // 获取事项下的所有审批
    important_point: HOST.api + 'equitychange/companies/important_point', // 新增重要时点
    important_point_list: HOST.api + 'equitychange/companies/{shareholderId}/important_point/list', // 重要时点列表
    delete_transaction_record: HOST.api + 'equitychange/transaction_records/{id}', // 删除交易记录
    delete_important_point: HOST.api + 'equitychange/companies/{pointId}/important_point', // 删除重要时点
    add_transaction_record: HOST.api + 'equitychange/shareholders/transaction_record', // 新增交易记录
    edit_transaction_record: HOST.api + 'equitychange/shareholders/transaction_records/{id}', // 编辑交易记录
    important_point_param: HOST.api + 'equitychange/companies/{orgId}/important_point/param', // 重要时点弹窗参数
    consultation_items: HOST.api + 'equitychange/consultation_items', // 咨询事项列表
    get_project_member: HOST.api + 'equitychange/{orgId}/project_member', // 获取项目成员信息
    delete_consultation_item: HOST.api + 'equitychange/consultation_item/{id}', // 删除咨询事项
    post_matter_consultation_item: HOST.api + 'equitychange/consultation_item/{id}/post_matter', // 追加后期事项
    get_post_matter_consultation_item: HOST.api + 'equitychange/consultation_item/{id}/post_matter', // 获取追加的后期事项
    put_approval: HOST.api + 'equitychange/consultation_items/{itemId}/approvals/{approvalId}', // 审批
    get_matters_approval_record: HOST.api + 'equitychange/consultation_items/{id}/approval_records', // 获取事项下的所有审批记录
    pending_approval: HOST.api + 'equitychange/pending_approval', // 获取待我审批列表
    approved: HOST.api + 'equitychange/approved', // 获取我已审批列表
    submit_approved: HOST.api + 'equitychange/consultation_items/{id}/official_approval', // 获取我已审批列表
    get_btn_permission: HOST.api + 'equitychange/companies/{orgId}/role_permission', // 获取不同服务公司下的权限
    get_shareholder_operate: HOST.api + 'equitychange/shareholders/change/logs', // 获取股东变动日志
    get_shareholder_operate_detail: HOST.api + 'equitychange/shareholders/change/log/{id}', // 获取股东变动日志-详细对比
    export_consultation_items: HOST.api + 'equitychange/consultation_items/procedure/view/file/{id}', // 导出增持过程比例计算
    get_reduce_plan_detail: HOST.api + 'equitychange/consultation_items/procedure/reduction/view/{id}', // 获取减持计划详情
    post_reduce_plan_detail: HOST.api + 'equitychange/consultation_items/procedure/reduction/view/{id}', // 新增减持计划详情
    edit_reduce_plan_detail: HOST.api + 'equitychange/consultation_items/procedure/reduction/view/{id}', // 修改减持计划详情
    get_share_change_notice: HOST.api + 'equitychange/shareholders/{id}/share_change/notice', // 获取股东股份/比例变动提示
    get_shareholders_instant_compliance: HOST.api + 'equitychange/shareholders/{id}/instant/compliance', // 获取股东股份/比例变动提示
    decrease_export_data: HOST.api + 'equitychange/shareholders/{id}/instant/procedure/file', // 股东权益变动查询页面(实时增减持导出)
    validate_person_business_type: HOST.api + 'equitychange/project/business_type/check', // 校验人员项目组是否存在指定项目业务/产品类型
    check_expiration: HOST.api + 'equitychange/consultation_items/{itemId}/staling', // 事项原始数据是否过期检查
    refresh_item_data: HOST.api + 'equitychange/consultation_items/{itemId}/staling', // 根据原始数据刷新事项数据
    check_shareholders_existence: HOST.api + 'equitychange/shareholders/{id}/existence' // 根据原始数据刷新事项数据
  },
  // 独董履职管理
  performance: {
    get_project_list: HOST.api + 'independent_director/performance_duty/project_list', // 获取独董履职计划列表
    get_project_item_list: HOST.api + 'independent_director/performance_duty/project/{projectId}/item', // 获取独董履职计划的事项列表
    create_project: HOST.api + 'independent_director/performance_duty/project', // 创建独董履职计划
    change_project_status: HOST.api + 'independent_director/performance_duty/project/{projectId}/item/{itemId}/finish', // 将独董履职计划的事项标记为完成
    delete_project: HOST.api + 'independent_director/performance_duty/project/{projectId}', // 删除独董履职计划
    get_project_item_detail: HOST.api + 'independent_director/performance_duty/project/{projectId}', // 获取履职计划详情
    get_performance_duty_user: HOST.api + 'independent_director/performance_duty/user', // 获取企业下的独董用户
    edit_project_item: HOST.api + 'independent_director/performance_duty/project/{projectId}/item', // 保存独董履职计划事项，包括新增和修改
    delete_project_item: HOST.api + 'independent_director/performance_duty/project/{projectId}/item', // 删除独董履职计划下的事项
    get_record_type_select_option: HOST.api + 'independent_director/performance_duty/record_types', // 获取记录类型筛选项
    get_record_source_select_option: HOST.api + 'independent_director/performance_duty/record_sources', // 获取记录来源筛选项
    get_performance_record_list: HOST.api + 'independent_director/performance_duty/record_list', // 获取履职记录列表（明细tab）
    create_performance_record: HOST.api + 'independent_director/performance_duty/record', // 新增履职记录
    check_performance_record_detail: HOST.api + 'independent_director/performance_duty/record/{recordId}', // 获取履职记录详情
    edit_performance_record: HOST.api + 'independent_director/performance_duty/record/{recordId}', // 编辑履职记录
    get_record_statistics_option: HOST.api + 'independent_director/performance_duty/record_years', // 获取所属年度筛选项
    get_record_statistics_data: HOST.api + 'independent_director/performance_duty/record/statistic', // 统计履职记录
    delete_performance_record: HOST.api + 'independent_director/performance_duty/record/{recordId}', // 删除履职记录
    get_meeting_list: HOST.api + 'independent_director/performance_duty/record/meeting_list', // 获取三会会议列表
    save_performance_record_from_meeting: HOST.api + 'independent_director/performance_duty/record/import', // 从三会导入履职记录
    add_to_do_list: HOST.api + 'independent_director/performance_duty/to_do_list', // 添加待办
    get_report_template_list: HOST.api + 'independent_director/report_template/list', // 获取模板列表
    modify_report_template: HOST.api + 'independent_director/report_template/{templateId}', // 模版基础信息修改
    delete_report_template: HOST.api + 'independent_director/report_template/{templateId}', // 删除模版
    get_report_template_types: HOST.api + 'independent_director/report_template/types', // 获取模板类型选项
    add_report_template: HOST.api + 'independent_director/report_template', // 新增模版
    restore_report_template: HOST.api + 'independent_director/report_template/restore', // 恢复至初始模板
    get_record_existing_types: HOST.api + 'independent_director/performance_duty/record/existing_types', // 获取记录中已存在的类型
    get_report_existing_users: HOST.api + 'independent_director/report/existing_users', // 获取报告中已存在的独董接口
    get_report_existing_years: HOST.api + 'independent_director/report/existing_years', // 获取报告中已存在的年度
    get_report_existing_types: HOST.api + 'independent_director/report/existing_types', // 获取报告中已存在的模板类型
    get_report_list: HOST.api + 'independent_director/report_list', // 获取独董报告列表
    delete_report_list: HOST.api + 'independent_director/report/{reportId}', // 获取独董报告列表
    generate_independent_director_report: HOST.api + 'independent_director/report', // 异步生成报告接口
    independent_director_document_management_files: HOST.api + 'independent_director/document_management/files', // 文档管理中心获取独董报告文件接口
    get_record_condititon_list: HOST.api + 'independent_director/performance_duty/record/condititon', // 获取履职筛选条件
    export_performance_record: HOST.api + 'independent_director/performance_duty/record/export', // 导出履职记录
    export_performance_duty_by_projectId: HOST.api + 'independent_director/performance_duty/project/{projectId}/item/export' // 导出履职计划excel
  },
  documentManagement: {
    document_management_my_files: HOST.api + 'misc/document_management/my/files', // 获取我的文件（只获取同一层级）
    document_management_library_my_files: HOST.api + 'misc/document_management/library/my/files', // 获取我的文件（获取所有层级）
    create_my_files_file_folder: HOST.api + 'misc/document_management/my/files/file_folder', // 我的文件-创建文件夹
    document_management_rename_my_files: HOST.api + 'misc/document_management/my/files/{id}', // 重命名我的文件
    document_management_my_usage: HOST.api + 'misc/document_management/my/usage', // 获取我的文件容量使用情况
    document_management_files_download: HOST.api + 'misc/document_management/files/download', // 异步批量下载文件
    document_management_files_user_upload_download: HOST.api + 'misc/document_management/files/user_upload/download', // 异步批量下载文件
    announcement_review_document_management_files: HOST.api + 'misc/announcement_review/document_management/files', // 异文档管理中心获取公告智能复核文件接口
    review_document: HOST.api + 'misc/intelligent_review/document/review', // 复核文档
    file_name_check: HOST.api + 'misc/document_management/my/files/file_name_check', // 我的文件 - 文件/文件夹名称校验
    usage_info: HOST.api + 'misc/intelligent_review/usage_info', // 获取用户使用次数
    document_management_share_files: HOST.api + 'misc/document_management/share/files', // 获取共享文件
    create_share_files_file_folder: HOST.api + 'misc/document_management/share/files/file_folder', // 共享文件-创建文件夹
    document_management_rename_share_files: HOST.api + 'misc/document_management/share/files/{id}', // 重命名共享文件/文件夹
    document_management_delete_share_files: HOST.api + 'misc/document_management/share/files/{id}', // 删除共享文件/文件夹
    check_share_file_name: HOST.api + 'misc/document_management/share/files/file_name_check', // 共享文件 - 文件/文件夹名称校验
    document_management_share_usage: HOST.api + 'misc/document_management/share/usage', // 获取共享的文件容量使用情况
    upload_document_management_files: HOST.api + 'misc/document_management/files', // 批量上传文件
    document_management_files_path: HOST.api + 'misc/document_management/files/{documentId}/path' // 批获取文件/文件夹完整路径
  },
  periodicReportChapter: {
    periodic_report_chapter_directory: HOST.api + 'periodic_report_chapter/directory', // 获取定期报告拆解章节目录
    periodic_report_chapter_selections: HOST.api + 'periodic_report_chapter/selections', // 获取下拉选
    directory_settings: HOST.api + 'periodic_report_chapter/directory/settings', // 获取目录设置
    directory_selections: HOST.api + 'periodic_report_chapter/directory/selections', // 获取目录设置下拉选
    periodic_report_chapter_list: HOST.api + 'periodic_report_chapter/list', // 获取定期报告拆解章节列表
    periodic_report_chapter_content: HOST.api + 'periodic_report_chapter/{chapterId}/content', // 获取定期报告拆解章节内容
    periodic_report_chapter_content_announcements: HOST.api + 'periodic_report_chapter/content/announcements/{announcementId}' // 获取定期报告拆解章节内容 - 全文检索时，传入公告id
  },
  // 内幕信息知情人
  insider: {
    major_issues_insider_list: HOST.api + 'major_issues/insider/list', // 重大事项知情人
    major_issues_exist_item_name: HOST.api + 'major_issues/exist_item_name', // 判断名称是否重复
    major_issues_item_name: HOST.api + 'major_issues/create_item', // 外部创建重大事项
    major_issues_insider: HOST.api + 'major_issues/insider', // 新增/编辑一个知情人
    major_issues_insider_detail: HOST.api + 'major_issues/insider/{id}', // 获取知情人详情
    major_issues_insider_short_info: HOST.api + 'major_issues/insider_short_info', // 获取新增/编辑知情人亲属下拉选择数据
    major_issues_insider_pull_down_condition: HOST.api + 'major_issues/insider/pull_down_condition', // 获取所有下拉条件
    major_issues_insider_importExcel: HOST.api + 'major_issues/insider/importExcel/{fileId}', // 导入知情人excel
    major_issues_insider_analyze_excel_result: HOST.api + 'major_issues/insider/analyze_excel_result/{fileId}', // 查询解析excel结果
    major_issues_insider_quote: HOST.api + 'major_issues/insider/quote/{id}', // 删除知情人时判断相关引用
    major_issues_item_list: HOST.api + 'major_issues/item/list', // 重大事项 列表
    major_issues_insider_delete: HOST.api + 'major_issues/insider/{id}', // 删除知情人
    major_issues_item_save: HOST.api + 'major_issues/item', // 重大事项 保存(有id)/更新(无id)
    major_issues_item_pull_down_condition: HOST.api + 'major_issues/item/pull_down_condition', // 重大事项管理- 获取所有下拉条件
    major_issues_item_detail: HOST.api + 'major_issues/item/{itemId}', // 重大事项管理- 详情
    major_issues_item_delete: HOST.api + 'major_issues/item/{itemId}', // 重大事项管理- 删除
    major_issues_item_memorandum_detail: HOST.api + 'major_issues/item/memorandum/{itemId}', // 获取所有备忘录数据
    major_issues_item_memorandum: HOST.api + 'major_issues/item/memorandum', // 保存/更新 备忘录数据
    major_issues_item_memorandum_generate_word: HOST.api + 'major_issues/item/memorandum/generate_word/{itemId}', // 生成备忘录签署文件
    major_issues_item_commitment_letter_generate_word: HOST.api + 'major_issues/item/commitment_letter/generate_word/{itemId}', // 生成承诺函签署文件支持在线编辑
    major_issues_issues_insider_list: HOST.api + 'major_issues/issues_insider/list/{itemId}', // 事项 知情人列表
    major_issues_supplement_insider_save: HOST.api + 'major_issues/supplement_insider/save', // 事项 知情人 去补充 保存数据
    major_issues_self_registration: HOST.api + 'major_issues/self_registration/{itemId}', // 自主登记 详情
    major_issues_supplement_insider_pull_down: HOST.api + 'major_issues/supplement_insider/pull_down', // 补充内幕知情人 下拉
    major_issues_self_registration_init: HOST.api + 'major_issues/self_registration_init', // 自主登记 初始化详情
    major_issues_sms_record: HOST.api + 'major_issues/sms_record', // 短信记录
    major_issues_supplement_insider_init: HOST.api + 'major_issues/supplement_insider/init', // 事项 去补充 保存初始化数据
    major_issues_self_registration_saveOrUpdate: HOST.api + 'major_issues/self_registration/saveOrUpdate', // 自主登记 保存（无id）/更新（有id）
    major_issues_get_signature_by_item: HOST.api + 'major_issues/signature/preparation/{itemId}', // 发起签名文件准备
    major_issues_get_signature_by_item_info: HOST.api + 'major_issues/signature/preparation/{itemId}/users', // 获取签名文件签名人员信息
    major_issues_initiation_signature: HOST.api + 'major_issues/signature/initiation', // 发起电子签名
    major_issues_issues_insider_delete: HOST.api + 'major_issues/issues_insider/{id}', // 事项 知情人删除
    major_issues_signature_list: HOST.api + 'major_issues/signature/list', // 事项类型列表
    major_issues_issues_insider_update: HOST.api + 'major_issues/update/issues_insider', // 事项 知情人 更新
    major_issues_item_insider_unsigned: HOST.api + 'major_issues/item_insider/unsigned/{itemId}', // 支持事项知情人进行数据在补充和重新发起签名
    major_issues_item_unsigned: HOST.api + 'major_issues/item/unsigned/{itemId}', // 支持事项知情人进行数据在补充和重新发起签名（归档时用）
    major_issues_item_file: HOST.api + 'major_issues/item/file/{itemId}', // 归档
    major_issues_signature_detail_delete: HOST.api + 'major_issues/signature/{itemId}/detail/{businessId}', // 重大事项签名-删除签名合同
    major_issues_signature_download: HOST.api + 'major_issues/signature/download', // 重大事项签名-下载签名合同相关
    major_issues_signature_detail: HOST.api + 'major_issues/signature/{itemId}/detail/{businessId}', // 重大事项签名-签署情况查询-单个签署状况查询
    major_issues_signature_manual_sign: HOST.api + 'major_issues/signature/manual-sign', // 重大事项签名-设置为手动签署
    major_issues_signature_manual_notify: HOST.api + 'major_issues/signature/manual-notify', // 重大事项签名-手动发送提醒
    major_issues_signature_detail_withdraw: HOST.api + 'major_issues/signature/{itemId}/detail/{businessId}/withdraw', // 重大事项签名-撤回时的删除合同接口
    major_issues_item_exportWord: HOST.api + 'major_issues/item/{itemId}/exportWord', // 导出word登记表
    major_issues_item_exportExcel: HOST.api + 'major_issues/item/{itemId}/exportExcel', // 导出excel登记表
    major_issues_self_registration_send_record: HOST.api + 'major_issues/self_registration/send_record', // 自主登记发送记录
    major_issues_permission: HOST.api + 'major_issues/permission', // 查看重大事项权限配置
    major_issues_user_permission: HOST.api + 'major_issues/user/permission' // 查看当前用户重大事项权限配置
  }

}
