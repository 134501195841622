<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <scroll-up></scroll-up>
    <!--无权限提示-->
    <permissionDialog
      :permissionDialog="showPermissionDialog"
      @permissionDialogChange="permissionDialogChange"
    ></permissionDialog>
    <div v-if="$auth.isIndependentDirectorUser() && $common.hasPermission('INDEPENDENT_DIRECTOR_HOME', 'BASIC')">
      <xa-dialog width="520px" title="小安提示" :visible="independentDirectorDialog" :show-close="false">
        <p :style="{ 'margin-left': payData.status == 3 ? '70px' : '20px' }">
          <span v-if="payData.status == 3">您的试用已到期，若需继续使用，请点击“立即开通”。</span>
          <span v-else>您购买的“独立董事”服务已到期，若需继续使用，请点击“立即续费”。</span>
          <br />
          <span>若您确认不再使用本服务，请点击“不再使用”。</span>
        </p>
        <span slot="footer">
          <xa-button @click="noLongerUse">不再使用</xa-button>
          <xa-button type="primary" @click="confirm">{{ payData.status == 3 ? '立即开通' : '立即续费' }}</xa-button>
        </span>
      </xa-dialog>
    </div>
    <xa-dialog width="520px" title="小安提示" :visible="agreementVisible" :show-close="false">
      <div class="text-box">
        <p>为了更好地为您提供服务并保障您的合法利益，</p>
        <p>
          请先阅读并知晓
          <a href="/agreement" target="_blank" class="text-primary">《用户协议》</a>和
          <a href="/privacy" target="_blank" class="text-primary">《隐私政策》</a>。
        </p>
      </div>
      <span slot="footer">
        <xa-button type="primary" @click="agreementClick">我已知晓</xa-button>
      </span>
    </xa-dialog>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import ScrollUp from './components/scrollerUp/scrollerUp'
import permissionDialog from '@/components/global/permissionDialog'

export default {
  name: 'app',
  components: {
    ScrollUp,
    permissionDialog
  },
  computed: {
    showPermissionDialog() {
      return this.$store.state.storeData.showPermissionDialog
    },
    // 是否独董已过期
    independentDirectorOverdue() {
      return this.$store.state.storeData.independentDirectorOverdue
    },
    // 是否展独董续费弹窗
    independentDirectorDialog() {
      return this.$store.state.storeData.independentDirectorDialog
    }
  },
  created() {
    this.userInfo = this.$storage.getUserInfo()
    this.selectInfo = this.$storage.getSelectInfo()
    this.updateJwt()
    if (this.$storage.isLogin()) {
      this.checkPayStatus()
    }
    // 切换服务公司后，更新selectInfo，防止切到其他tab页再回来时，当前页面由于初始selectInfo未更新从而触发一次刷新
    this.$eventBus.$on('updateSelectInfo', val => {
      this.selectInfo = val
    })
  },
  data() {
    return {
      payData: {},
      // 付费过期后任职公司允许访问的页面，不做弹窗判断处理
      directorAllowed: [
        '/rules',
        '/affiches',
        '/inquiry-letters',
        '/violation-cases',
        '/reports',
        '/manage/account',
        '/creation/rule-notes',
        '/follow/companies',
        '/collections',
        '/share',
        '/dashboard/propaganda',
        '/online-service',
        '/dashboard/independent-director/online-pay'
      ],
      agreementVisible: false, // 用户协议、隐私政策通告弹框
      agreementWhiteList: ['/agreement', '/privacy'], // 用户协议、隐私政策弹框不出现页面列表
      userInfo: {},
      selectInfo: {}
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (this.independentDirectorOverdue) {
          let temp = false
          this.directorAllowed.forEach(ele => {
            if (val.fullPath.includes(ele)) {
              if (
                val.fullPath.includes('inputCode=%E4%BB%BB%E8%81%8C%E5%85%AC%E5%8F%B8') ||
                val.fullPath.includes('isLimitAccess=1')
              ) {
                // 此判断用于任职公司公告过滤（inputCode=任职公司）
                temp = false
              } else {
                temp = true
              }
            }
          })
          this.$store.commit('INDEPENDENT_DIRECTOR_DIALOG', !temp)
        }
        if (this.$storage.isLogin() && this.agreementWhiteList.indexOf(val.path) < 0) {
          const userInfo = this.$storage.getUserInfo()
          if (userInfo.promptUserAgreement) {
            this.agreementVisible = true
          }
        }
      }
    }
  },
  mounted() {
    // 当前页面被iframe嵌入
    if (window.top !== window.self) {
      // 顶层窗口地址非小安
      if (
        window.top.location.host.indexOf('in-hope.cn') < 0 &&
        window.top.location.host.indexOf('in-hope.com.cn') < 0
      ) {
        window.top.location = window.location
      }
    }
    setTimeout(() => {
      if (!this.storageAvailable('localStorage')) {
        Sentry.captureException(new Error('问题排查 | 用户localStorage被禁用'))
        alert('您的浏览器限制了“cookie和站点数据”，这将影响您正常登录，您可以更改浏览器的隐私设置，解除限制后重试。')
      }
    }, 500)
    window.addEventListener('scroll', this.handleFun)
    document.addEventListener('visibilitychange', this.handleVisibleChange)
    window.addEventListener('storage', this.storageChange)
  },
  methods: {
    // 用户协议点击“我已知晓”
    agreementClick() {
      this.$axios.user
        .confirm_user_agreement()
        .then(() => {
          this.agreementVisible = false
          this.$auth.updateUserInfo(true)
        })
        .catch(err => {
          if (err.response && err.response.status == 500 && err.response.data.returnMsg) {
            this.$xaToast({ type: 'error', message: err.response.data.returnMsg })
          } else {
            this.$xaToast({ type: 'error', message: '请求失败' })
          }
        })
    },
    // 切换浏览器tab页时检测进入当前路由是否需要登录，若需要登录且未登录跳转至登录页，登录后重定向至当前页面
    handleVisibleChange() {
      // header.vue内切换profile后跳转到企业首页，也触发了visibilityChange事件
      // 根据查阅的信息：如果URL跳转导致页面完全重新加载，那么visibilityChange事件可能会在页面重新加载后被触发
      if (document.visibilityState === 'hidden') {
        this.pageHided = true
      } else if (document.visibilityState === 'visible' && this.pageHided) {
        if (!this.$storage.isLogin() && this.$route.meta.loginState > 0) {
          this.$router.push({ path: '/login', query: { redirect_url: this.$route.fullPath } })
          return
        }
        // 切换浏览器tab页时检测用户是否变更，若变更就刷新当前页面，同步信息(切换套餐是否需要刷新待产品确认)
        const userInfo = this.$storage.getUserInfo()
        const selectInfo = this.$storage.getSelectInfo()

        if (
          this.userInfo.userId != userInfo.userId ||
          this.userInfo.authorityRole != userInfo.authorityRole ||
          this.userInfo.companyId != userInfo.companyId
        ) {
          if (this.$route.meta.redirectPathAfterSwitchProfile) {
            location.href = this.$route.meta.redirectPathAfterSwitchProfile
          } else {
            window.location.reload()
          }
        } else if (
          this.selectInfo.companyCode != selectInfo.companyCode &&
          this.$auth.isInfaithUser() &&
          this.$route.meta.serviceCompany
        ) {
          // 切换服务公司后信公咨询首页无需刷新
          window.location.reload()
        }
      }
    },
    handleFun() {
      if (!document.getElementById('header')) {
        return
      }
      setTimeout(() => {
        const isScrollTop = document.documentElement.scrollTop
        const isScrollLeft = document.documentElement.scrollLeft

        if (document.querySelector('#header .header-menu.menu-fixed')) {
          document.querySelector('#header .header-menu.menu-fixed').style.left = `-${isScrollLeft}px`
        } else {
          document.querySelector('#header .header-menu').style.left = 0
        }
      })
    },
    permissionDialogChange() {
      this.$store.commit('SHOW_PERMISSION_DIALOG', 0)
    },
    // 校验localStorage、sessionStorage是否被禁用
    storageAvailable(type) {
      let storage
      try {
        storage = window[type]
        const x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
      } catch (e) {
        return (
          e instanceof DOMException &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        )
      }
    },
    async updateJwt() {
      const currentTime = this.$common.getCurrentTime()
      const updateTime = this.$storage.get('JWT_updateTime')
      if (!this.$storage.isLogin() || currentTime === updateTime) {
        return
      }

      try {
        const res = await this.$axios.user.refresh_token()
        if (res.data) {
          this.$storage.set('JWT', res.data.returnObject.token, res.data.returnObject.daysOfValidity)
          this.$storage.set('JWT_updateTime', this.$common.getCurrentTime())
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 检查付费状态
    checkPayStatus() {
      this.$axios.independentDirector.query_combos(101).then(res => {
        // 1 试用中
        // 2 付费中
        // 3 试用到期
        // 4 付费到期
        this.payData = res.data.returnObject || {}
        if (this.payData.status == 3 || this.payData.status == 4) {
          this.$store.commit('INDEPENDENT_DIRECTOR_OVERDUE', true)
        }
        // 若访问页面并非企业版独享,不弹窗
      })
    },
    async noLongerUse() {
      try {
        // 获取所有上下文，判断是否有独董
        const {
          data: { returnObject: profileList }
        } = await this.$axios.manage.get_users_profiles()

        this.$store.commit('INDEPENDENT_DIRECTOR_DIALOG', false)
        this.$store.commit('INDEPENDENT_DIRECTOR_OVERDUE', false)
        const id = profileList.filter(v => v.active == 1)[0].id
        this.$axios.independentDirector.delete_and_switch(id).then(() => {
          this.$xaToast({
            type: 'success',
            message: '操作成功'
          })
          location.reload()
        })
      } catch (error) {}
    },
    confirm() {
      this.$router.push('/dashboard/independent-director/online-pay')
      this.$store.commit('INDEPENDENT_DIRECTOR_DIALOG', false)
    },
    async storageChange(event) {
      if (event.key === 'LOG_OUT') {
        this.$auth.clearAuth('退出登录')
        if (this.$route.query?.redirect_url) {
          location.href = this.$route.query.redirect_url
        } else {
          location.href = '/'
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleFun)
    window.removeEventListener('storage', this.storageChange)
  }
}
</script>

<style lang="scss">
.v-modal {
  // 解决el-dialog偶现遮罩层变纯黑的问题 INHOPE-16971
  opacity: unset !important;
  background: rgba(0, 0, 0, 0.5) !important;
}
$scrollHeight: 15px;
.html-body-overflow {
  padding-right: $scrollHeight !important;
  overflow: hidden !important;
  #header {
    .header-bar,
    .header-menu {
      padding-right: $scrollHeight;
    }
  }
}
#app {
  height: 100%;
  .content {
    width: 1240px;
    height: auto;
    margin: 0 auto;
    position: relative;
  }
  .flex-content {
    width: 100%;
    min-width: 1240px;
    max-width: 1920px;
    padding: 16px 20px;
    height: auto;
    margin: 0 auto;
    position: relative;
    display: flex;
    .flex-content-fixed-width {
      margin-left: 12px;
      flex-shrink: 0;
    }
    .flex-content-adaptive-width {
      flex: 1;
      overflow: hidden;
      width: 0%;
    }
  }
}
</style>

<style scoped lang="scss">
.text-box {
  p {
    text-align: center;
  }
}
</style>
