/*
 * @Description:文档管理
 * @Author: luwen.yang
 */
import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'
export default {
  // 获取我的文件（只获取同一层级）
  document_management_my_files(params) {
    return Axios({ method: 'GET', url: api.documentManagement.document_management_my_files, params })
  },
  // 获取我的文件（获取所有层级）
  document_management_library_my_files(params) {
    return Axios({ method: 'GET', url: api.documentManagement.document_management_library_my_files, params })
  },
  // 重命名我的文件
  document_management_rename_my_files(id, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.documentManagement.document_management_rename_my_files, [['id', id]]), params: params, originUrl: api.calendar.document_management_rename_my_files })
  },
  // 删除我的文件
  document_management_delete_my_files(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.documentManagement.document_management_rename_my_files, [['id', id]]), originUrl: api.calendar.document_management_rename_my_files })
  },
  // 获取我的文件容量使用情况
  document_management_my_usage() {
    return Axios({ method: 'GET', url: api.documentManagement.document_management_my_usage })
  },
  // 我的文件-创建文件夹
  create_my_files_file_folder(data) {
    return Axios({ method: 'POST', url: api.documentManagement.create_my_files_file_folder, data })
  },
  // 异步批量下载文件
  document_management_files_download(data) {
    return Axios({ method: 'POST', url: api.documentManagement.document_management_files_download, data })
  },
  // 异步批量下载文件
  document_management_files_user_upload_download(data) {
    return Axios({ method: 'POST', url: api.documentManagement.document_management_files_user_upload_download, data })
  },
  // 文档管理中心获取公告智能复核文件接口
  announcement_review_document_management_files(params) {
    return Axios({ method: 'GET', url: api.documentManagement.announcement_review_document_management_files, params })
  },
  // 复核文档
  review_document(data) {
    return Axios({ method: 'POST', url: api.documentManagement.review_document, data })
  },
  // 我的文件 - 文件/文件夹名称校验
  file_name_check(params) {
    return Axios({ method: 'GET', url: api.documentManagement.file_name_check, params })
  },
  // 获取用户使用次数
  usage_info(params) {
    return Axios({ method: 'GET', url: api.documentManagement.usage_info, params })
  },
  // 获取共享文件
  document_management_share_files(params) {
    return Axios({ method: 'GET', url: api.documentManagement.document_management_share_files, params })
  },
  // 创建共享文件夹
  create_share_files_file_folder(data) {
    return Axios({ method: 'POST', url: api.documentManagement.create_share_files_file_folder, data })
  },
  // 重命名共享文件/文件夹
  document_management_rename_share_files(id, params) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.documentManagement.document_management_rename_share_files, [['id', id]]), params, originUrl: api.calendar.document_management_rename_share_files })
  },
  // 删除共享文件
  document_management_delete_share_files(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.documentManagement.document_management_delete_share_files, [['id', id]]), originUrl: api.calendar.document_management_delete_share_files })
  },
  // 共享文件 - 文件/文件夹名称校验
  check_share_file_name(params) {
    return Axios({ method: 'GET', url: api.documentManagement.check_share_file_name, params })
  },
  // 获取共享的文件容量使用情况
  document_management_share_usage() {
    return Axios({ method: 'GET', url: api.documentManagement.document_management_share_usage })
  },
  // 添加我的文件/共享文件
  upload_document_management_files(data) {
    return Axios({ method: 'POST', url: api.documentManagement.upload_document_management_files, data })
  },
  // 批获取文件/文件夹完整路径
  document_management_files_path(documentId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.documentManagement.document_management_files_path, [['documentId', documentId]]), originUrl: api.documentManagement.document_management_files_path })
  }
}
