<template>
  <div id="filterContainerItem">
    <div class="wrapper" :style="{ display: isOperateItem ? 'flex' : 'block', 'align-items': 'top' }">
      <div :class="{ 'item-box': isOperateItem }">
        <slot> </slot>
      </div>
      <div class="operate-wrapper" v-if="isOperateItem">
        <xa-button type="primary" class="search-button" @click="search">搜索</xa-button>
        <xa-button size="mini" class="search-button clear" style="font-size: 12px" @click="clear" type="simple"
          >清空条件</xa-button
        >
        <slot name="operate"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filterContainerItem',
  props: {
    // 是否是操作项
    isOperateItem: Boolean
  },
  methods: {
    search() {
      this.$emit('search')
    },
    clear() {
      this.$emit('clear')
    }
  }
}
</script>

<style lang='scss' scoped>
#filterContainerItem {
  height: 40px;
  width: 25%;
  .wrapper {
    width: 100%;

    .item-box {
      flex: 1;
      overflow: hidden;
    }
    .operate-wrapper {
      width: 50%;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      .search-button {
        margin-left: 4%;

        width: 60%;
        min-width: 60px;
        &.clear {
          min-width: 60px;
        }
      }
      /deep/ .xa-button {
        font-size: 12px;
        max-width: 110px;
        min-width: 0;
        text-align: center;
        padding: 0;
        height: 32px;
      }
    }
  }

  .clear-button {
    color: #999999;
  }
}
</style>
