import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 添加模板事件
  add_template_events(params) {
    return Axios({ method: 'POST', url: api.calendar.template_events, data: params })
  },
  // 检查股东大会召开日和股权登记日是否合法
  check_date(params, templateEventId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.check_date, [['templateEventId', templateEventId]]), params: params, originUrl: api.calendar.check_date })
  },
  // 编辑模板事件
  edit_template_event(params) {
    return Axios({ method: 'POST', url: api.calendar.edit_template_event, data: params })
  },
  // 获取事件节点信息
  get_template_event_contains(params, templateEventId) {
    return Axios({ method: 'POST', url: api.calendar.get_template_event_contains, data: params })
  },
  // 获取事件节点信息
  get_template_event_info(params) {
    return Axios({ method: 'GET', url: api.calendar.get_template_event_info, params: params })
  },
  // 删除事件
  delete_template_event(eventId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.calendar.delete_template_event, [['eventId', eventId]]), originUrl: api.calendar.delete_template_event })
  },
  // 日常事件
  // 添加日常事件
  add_normal_event(data, eventTemplateId) {
    return Axios({ method: 'POST', url: Common.pathFilter(api.calendar.add_normal_event, [['eventTemplateId', eventTemplateId]]), data, originUrl: api.calendar.add_normal_event })
  },
  // 编辑普通事件详情
  edit_calendar_events(params, eventId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.calendar_events_by_id, [['eventId', eventId]]), data: params, originUrl: api.calendar.calendar_events_by_id })
  },
  // 获取通知对象
  get_inviters_by_eventId(params, eventId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.get_inviters_by_eventId, [['eventId', eventId]]), params: params, originUrl: api.calendar.get_inviters_by_eventId })
  },
  // 根据时间段获取事件列表
  get_calendar_events(params) {
    return Axios({ method: 'GET', url: api.calendar.get_calendar_events, params: params })
  },
  // 获取普通事件详情
  get_calendar_events_by_id(params, eventId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.calendar_events_by_id, [['eventId', eventId]]), params: params, originUrl: api.calendar.calendar_events_by_id })
  },
  // 获取所有模板
  get_calendar_templates(params) {
    return Axios({ method: 'GET', url: api.calendar.get_calendar_templates, params: params })
  },
  // 根据事件名称搜索个人事件列表
  search_event_list(params) {
    return Axios({ method: 'GET', url: api.calendar.search_event_list, params: params })
  },
  // 删除事件
  delete_calendar_events(eventId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.calendar.calendar_events_by_id, [['eventId', eventId]]), originUrl: api.calendar.calendar_events_by_id })
  },
  // dashbord获取待办事项
  get_enterprise_events(params) {
    return Axios({ method: 'GET', url: api.calendar.enterprise_events, params: params })
  },
  // dashboard获取我的待办
  dashboard_get_backlogs(params) {
    return Axios({ method: 'POST', url: api.calendar.dashboard_get_backlogs, data: params })
  },
  // 日历
  // 获取休市日期
  get_close_dates(params) {
    return Axios({ method: 'GET', url: api.calendar.get_close_dates, params: params })
  },
  // 创建日历
  add_calendar(params) {
    return Axios({ method: 'POST', url: api.calendar.user_calendars, params: params })
  },
  // 获取所属自己的日历
  get_calendar_by_user_id() {
    return Axios({ method: 'GET', url: api.calendar.get_calendar_by_user_id })
  },
  // 编辑修改个人日历
  edit_user_calendar(params, calendarId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.operate_calendar, [['calendarId', calendarId]]), params: params, originUrl: api.calendar.operate_calendar })
  },
  // 修改日历选中状态
  updateCalendarCheckedStatus(params) {
    return Axios({ method: 'PUT', url: api.calendar.checked_status, data: params })
  },
  // 删除个人日历
  delete_user_calendar(params, calendarId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.calendar.operate_calendar, [['calendarId', calendarId]]), params: params, originUrl: api.calendar.operate_calendar })
  },
  // 共享日历链接获取
  get_calendar_share_link(calendarId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.calendar_share_link, [['calendarId', calendarId]]), originUrl: api.calendar.calendar_share_link })
  },
  // 获取日历共享人
  get_calendar_share_users(calendarId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.calendar_share_users, [['calendarId', calendarId]]), originUrl: api.calendar.calendar_share_users })
  },
  // 更新共享链接
  update_calendar_share_link(calendarId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.calendar_share_link, [['calendarId', calendarId]]), originUrl: api.calendar.calendar_share_link })
  },
  // 修改日历颜色
  modify_calendar_color(params, calendarId) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.modify_calendar_color, [['calendarId', calendarId]]), params: params, originUrl: api.calendar.modify_calendar_color })
  },
  // 根据userID获取个人日历列表
  get_user_canlendar_list() {
    return Axios({ method: 'GET', url: api.calendar.user_calendars })
  },
  // 共享日历查看
  get_share_calendar_info(params) {
    return Axios({ method: 'GET', url: api.calendar.get_share_calendar_info, params: params })
  },
  // 共享日历添加
  add_shared_calendar(params) {
    return Axios({ method: 'POST', url: api.calendar.add_shared_calendar, params: params })
  },
  // 取消日历共享人
  cancel_calendar_share_users(params, calendarId) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.calendar.calendar_share_users, [['calendarId', calendarId]]), params: params, originUrl: api.calendar.calendar_share_users })
  },
  // 查询需要弹窗提醒的披露事件
  get_disclosure_tip() {
    return Axios({ method: 'GET', url: api.calendar.disclosure_tip })
  },
  // 不再提示-披露事件提醒弹窗
  cancel_disclosure_tip(params) {
    return Axios({ method: 'PUT', url: api.calendar.disclosure_tip, params })
  },
  update_template_event_contain(params) {
    return Axios({ method: 'POST', url: api.calendar.update_template_event_contain, data: params })
  },
  calculate_normal_event_confirm_date(params) {
    return Axios({ method: 'POST', url: api.calendar.calculate_normal_event_confirm_date, data: params })
  },
  get_calendar_backlogs(params) {
    return Axios({ method: 'POST', url: api.calendar.get_calendar_backlogs, data: params })
  },
  add_calendar_backlog(params) {
    return Axios({ method: 'POST', url: api.calendar.calendar_backlog, data: params })
  },
  edit_calendar_backlog(params) {
    return Axios({ method: 'PUT', url: api.calendar.calendar_backlog, data: params })
  },
  get_backlogs_source_enums() {
    return Axios({ method: 'GET', url: api.calendar.get_backlogs_source_enums })
  },
  user_finish_backlog(id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.user_finish_backlog, [['id', id]]), originUrl: api.calendar.user_finish_backlog })
  },
  finish_backlog(id) {
    return Axios({ method: 'PUT', url: Common.pathFilter(api.calendar.finish_backlog, [['id', id]]), originUrl: api.calendar.finish_backlog })
  },
  get_backlog_detail(id) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.calendar.get_backlog_detail, [['id', id]]), originUrl: api.calendar.get_backlog_detail })
  },
  del_backlog(id) {
    return Axios({ method: 'DELETE', url: Common.pathFilter(api.calendar.del_backlog, [['id', id]]), originUrl: api.calendar.del_backlog })
  },
  // 获取企业下用户信息
  get_orgs_user() {
    return Axios({ method: 'GET', url: api.calendar.get_orgs_user })
  }
}
