import Storage from '@/service/storage'
import Api from '@/service/api'
import router from '@/router'
import Axios from '../axios/index'
import Store from '../store'
import Common from '@/utils/common'
import * as Sentry from '@sentry/vue'

import Vue from 'vue'

export default {
  // 存储用户信息 cookie、store、localstorage
  setStore(userData, storeCommit = true) {
    const info = userData
    // delete 掉不需要的参数 防止userInfo中信息大于cookie的单条最大容量(4k)
    // userhome接口返回已清理无用字段，由于登录成功接口返回的用户信息内服务端还未清理，以下代码暂时保留
    Vue.delete(info, 'authorityGroup')
    Vue.delete(info, 'authorityMenuList')
    Vue.delete(info, 'registerFrom')
    Vue.delete(info, 'lyzUrl')
    Vue.delete(info, 'hasLyz')
    Vue.delete(info, 'isXiaoAnUser')
    Vue.delete(info, 'xiaoeUserId')
    Vue.delete(info, 'cityId')
    Vue.delete(info, 'companyStreet')
    Vue.delete(info, 'enterpriseTime')
    Vue.delete(info, 'isTestUser')
    Vue.delete(info, 'companyTown')
    Vue.delete(info, 'attentioned')
    Vue.delete(info, 'isTestUser')
    Vue.delete(info, 'companyCity')
    Vue.delete(info, 'isApplyEnterprise')
    Vue.delete(info, 'enterpriseEndDate')
    Vue.delete(info, 'invited')
    Vue.delete(info, 'regDatetime')
    Vue.delete(info, 'jobStatus')
    Vue.delete(info, 'companyInviteState')
    Vue.delete(info, 'lockStatus')
    Vue.delete(info, 'testUser')
    Vue.delete(info, 'companyProvince')
    Vue.delete(info, 'superviseArea')
    Vue.delete(info, 'innerCode')
    Vue.delete(info, 'stockType')
    Vue.delete(info, 'loginCount')
    Vue.delete(info, 'areaId')
    Storage.set('userId', info.userId)
    info.enterpriseCompanyCode = info.enterpriseCompanyCode || '' // 值为null 则改为''
    info.enterpriseCompanyFullCode = info.enterpriseCompanyFullCode || ''
    Storage.set('userInfo', info)
    // 更新sentry上传时的用户信息
    process.env.NODE_ENV === 'production' && Sentry.setContext('character', {
      JWT: Storage.get('JWT') || '',
      userInfo: Storage.getUserInfo()
    })

    // vuex
    storeCommit && Store.commit('USER_DATA', info)
    storeCommit && Store.commit('IS_LOGIN', true)
  },
  /**
   * 查询用户信息
   * @param {*} needSetStore 是否需要更新存储用户信息
   */
  getUserInfo(needSetStore = true, userId = '') {
    return new Promise((resolve) => {
      Axios.user.get_user_info().then(async res => {
        const info = res.data.returnObject
        needSetStore && this.setStore(info)
        resolve(res)
      }).catch(err => {
        console.log(err)
        resolve({})
      })
    })
  },
  /**
   * 更新用户信息
   * @param {*} needSetStore 是否需要更新存储用户信息
   */
  updateUserInfo(needSetStore) {
    return new Promise((resolve) => {
      if (!Storage.isLogin()) {
        this.clearAuth('updateUserInfo 未登录')
        resolve({})
        return
      }
      this.getUserInfo(needSetStore).then(async res => {
        if (res.data) {
          const info = res.data.returnObject
          // 更新我服务的公司列表
          await this.getAllEspecialCompany(info, false)
          // getCompanyListByUserId接口依赖getAllEspecialCompany更新cookie内selectInfo
          this.getCompanyListByUserId()
          this.getMenuInfo()
        } else {
          this.clearAuth(`获取用户信息异常，code: ${res.data.returnCode}`)
        }
        resolve(res)
      }).catch(err => {
        console.log(err)
        if (err.response?.status == 500) {
          this.clearAuth(`获取用户信息异常，code: ${err.response.data.returnCode}`)
        }
        resolve({})
      })
    })
  },
  // 重置 股权激励-问答解答 提示
  resetTipShowObj() {
    const tipShowObj = {
      common: true,
      latest: true,
      combineOrder: true
    }
    Storage.set('tipShowObj', tipShowObj)
  },
  /**
   * 登录、注册后设置auth，完善信息判断
   * @param {*} userData 用户信息
   * @param {*} isLoginSetAuth 是否登录后设置auth
   * @param {*} redirect_url 登录成功后跳转url
   */
  async setAuth(userData, isLoginSetAuth, redirect_url) {
    if (!userData.companyTypeOne) {
      this.setStore(userData)
      Vue.prototype.$xaToast({ message: '请先完善个人信息！', type: 'info' })
      router.push({ path: '/complete-personal-info' })
    } else {
      this.resetTipShowObj()
      if (isLoginSetAuth) {
        this.setStore(userData, false)
        this.loginRedirect(redirect_url, userData)
      } else {
        this.setStore(userData)
        this.getCompanyListByUserId()
        this.getMenuInfo()
      }
      await this.getAllEspecialCompany(userData, false)
    }
  },
  loginRedirect(redirect_url, userData) {
    if (redirect_url) {
      if (userData.authorityRole == 'INFAITH' && redirect_url == '/dashboard/propaganda') {
        Storage.set('infaithDirector', true)
        location.href = '/'
      } else {
        let redirectUrl = decodeURIComponent(redirect_url)
        if (redirectUrl.length > 4 && redirectUrl.substring(0, 4) == 'http') {
          const whiteList = [
            '.in-hope.cn',
            '.in-hope.com.cn'
          ]
          const result = whiteList.some(item => redirectUrl.includes(item))
          if (!result) {
            redirectUrl = '/'
          }
        }
        // 跳转
        if (redirectUrl.indexOf('&code=') >= 0 && redirectUrl.indexOf('&state=') >= 0) { // 去掉微信登录时自动添加的参数code和state
          location.href = redirectUrl.slice(0, redirectUrl.lastIndexOf('&code='))
        } else {
          location.href = redirectUrl
        }
      }
    } else {
      if (userData.enterpriseCompanyCode) {
        if (process.env.NODE_ENV === 'development') {
          location.href = '/'
        } else {
          location.href = this.getDomainWithComanyCode(userData)
        }
      } else {
        location.href = '/'
      }
    }
  },
  // 退出登录后跳转
  logout_redirect() {
    if (!Storage.isLogin()) {
      router.push('/login?redirect_url=' + location.href)
    }
  },
  // 获取当前的域名（带公司Code的）
  getDomainWithComanyCode(userData) {
    if (!this.isEnterpriseUser(userData.authorityRole)) {
      return Api.host
    } else {
      const code = userData.enterpriseCompanyCode.toString().replace(/(^\s*)|(\s*$)/g, '')
      return Api.enterpriseHost.replace('{{code}}', code)
    }
  },
  // 登录后跳转判断 有到计时
  checkAuth() {
    if (!Storage.isLogin()) {
      this.clearAuth('checkAuth 未登录')
      this.authRedirect('/login?redirect_url=' + location.href, '请先登录！', '登录页')
    }
  },
  /**
   * 根据公司列表 设置selectInfo
   * @param {*} selectCompanyList
   * @param {*} isUseFirstItem 是否强制使用selectCompanyList的第一项设置SelectInfo
   */
  setSelectInfoByCompanyList(selectCompanyList, isUseFirstItem, authority) {
    if (isUseFirstItem) {
      if (authority != 'INFAITH') {
        Storage.set('selectInfo', selectCompanyList[0] || {})
        return
      } else {
        const selectCompany = selectCompanyList.filter(v => v.listed)[0]
        Storage.set('selectInfo', selectCompany || {})
        return
      }
    }
    const oldSelectInfo = Storage.getSelectInfo()
    let newSelectInfo
    if (oldSelectInfo && Object.keys(oldSelectInfo).length > 0) {
      // 监管用户改造决定使用organizationId概念代替companyId
      if (authority != 'SUPERVISE') {
        newSelectInfo = selectCompanyList.find(v => v.companyId == oldSelectInfo.companyId)
      } else if (this.isIndependentDirectorUser()) {
        newSelectInfo = selectCompanyList.find(v => v.companyCode == oldSelectInfo.companyCode)
      } else {
        newSelectInfo = selectCompanyList.find(v => v.companyId == oldSelectInfo.companyId)
      }
      if (newSelectInfo) {
        Storage.set('selectInfo', newSelectInfo)
      }
    }
    // 缓存中selectInfo不匹配当前服务公司、监管公司列表时，设置selectinfo
    if (!oldSelectInfo || !newSelectInfo) {
      if (authority != 'INFAITH') {
        Storage.set('selectInfo', selectCompanyList[0] || {})
      } else {
        const selectCompany = selectCompanyList.filter(v => v.listed)[0]
        Storage.set('selectInfo', selectCompany || {})
      }
    }
  },
  getAllEspecialCompany(userInfo, isUseFirstItem) {
    Storage.set('selectPageNum', 1)
    const companyItem = {
      companyName: userInfo.enterpriseCompanyName || userInfo.companyName, // ENTERPRISE取enterpriseCompanyName,外部用户无enterpriseCompanyName取companyName
      companyId: userInfo.companyId,
      companyCode: userInfo.enterpriseCompanyCode || userInfo.companyCode, // 纯外部用户无enterpriseCompanyCode取companyCode
      companyFullCode: userInfo.enterpriseCompanyFullCode // 外部用户无enterpriseCompanyFullCode
    }
    const params = {
      organizationId: userInfo.companyId
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let companyList
      let infaithCompanyList
      let superviseCompanyList
      let independentDirectorCompanyList

      switch (userInfo.authorityRole) {
        case 'OUTER':
          if (userInfo.independentDirector) {
            independentDirectorCompanyList = await this.getMyIndependentDirectorCompanyList()
            Storage.set('SELECT_COMPANY_LIST', independentDirectorCompanyList)
            this.setSelectInfoByCompanyList(independentDirectorCompanyList, isUseFirstItem, 'OUTER')
          } else {
            companyList = [companyItem]
            Storage.set('SELECT_COMPANY_LIST', companyList)
            Storage.set('selectInfo', companyList[0])
          }
          resolve()
          break
        case 'ENTERPRISE':
          companyList = [companyItem]
          Storage.set('SELECT_COMPANY_LIST', companyList)
          Storage.set('selectInfo', companyList[0])
          resolve()
          break
        case 'INFAITH':
          infaithCompanyList = await this.getMyInfaithCompanyList(params, companyItem)
          Storage.set('SELECT_COMPANY_LIST', infaithCompanyList)
          this.setSelectInfoByCompanyList(infaithCompanyList, isUseFirstItem, 'INFAITH')
          resolve()
          break
        case 'SUPERVISE':
          superviseCompanyList = await this.getAllSuperviseCompanyByUser(params, companyItem)
          Storage.set('SELECT_COMPANY_LIST', superviseCompanyList)
          this.setSelectInfoByCompanyList(superviseCompanyList, isUseFirstItem, 'SUPERVISE')
          resolve()
          break
        case 'INTERMEDIARY':
          companyList = [companyItem]
          Storage.set('SELECT_COMPANY_LIST', companyList)
          Storage.set('selectInfo', companyList[0])
          resolve()
          break
        default:
          resolve()
      }
    })
  },
  getMyInfaithCompanyList(params, companyItem) {
    return new Promise((resolve) => {
      const promiseList = [
        Axios.enterpriseCompany.get_my_infaith_company_list(params),
        Axios.enterpriseCompany.get_served_companies(params)
      ]
      Promise.all(promiseList).then(([followCompanyRes, serviceCompanyRes]) => {
        const followCompanyList = followCompanyRes.data.returnObject?.data?.map(v => {
          v.infaithCompanyType = 'followCompany'
          v.listed = true
          return v
        }) || []
        const serviceCompanyList = serviceCompanyRes.data.returnObject?.data?.map(v => {
          v.infaithCompanyType = 'serviceCompany'
          return v
        }) || []

        const infaithFollowCompanyList = followCompanyList.map(e => {
          return Object.assign({}, companyItem, {
            companyName: e.companyName,
            companyId: e.companyId,
            companyCode: e.companyCode,
            companyFullCode: e.companyFullCode,
            infaithCompanyType: 'followCompany',
            listed: true
          })
        })

        const infaithServiceCompanyList = serviceCompanyList.map(e => {
          return Object.assign({}, companyItem, {
            companyName: e.companyName,
            companyId: e.companyId,
            companyCode: e.companyCode,
            companyFullCode: e.companyFullCode,
            infaithCompanyType: 'serviceCompany',
            listed: e.listed
          })
        })
        const wholeCompanyData = [...serviceCompanyList, ...followCompanyList]
        const infaithAllList = [...infaithServiceCompanyList, ...infaithFollowCompanyList]
        Store.commit('SET_INFAITH_COMPANY_LIST', wholeCompanyData)
        resolve(infaithAllList)
      })
    })
  },
  getMyIndependentDirectorCompanyList(companyItem) {
    return new Promise((resolve) => {
      Axios.enterpriseCompany.independent_director_companies().then(res => {
        const IndependentDirectorCompanyList = res.data.returnObject.map(e => {
          return Object.assign({}, companyItem, {
            companyName: e.companyName,
            companyId: e.companyId,
            companyCode: e.companyCode,
            companyFullCode: e.companyFullCode
          })
        })
        resolve(IndependentDirectorCompanyList)
      })
    })
  },
  getAllSuperviseCompanyByUser(params, companyItem) {
    return new Promise((resolve) => {
      Axios.superviseCompany.get_all_supervise_company_by_user(params.organizationId).then(res => {
        const superviseCompanyList = res.data.returnObject.data.map(e => {
          return Object.assign({}, companyItem, {
            companyName: e.companyName,
            companyCode: e.companyCode,
            companyFullCode: e.companyFullCode,
            companyId: e.organizationId
          })
        })
        Store.commit('SET_SUPERVISE_COMPANY_LIST', res.data.returnObject.data)
        resolve(superviseCompanyList)
      })
    })
  },
  authRedirect(url, msg, name) {
    const data = {
      url: url,
      msg: msg,
      name: name
    }
    Store.commit('REDIRECT', data)
    router.push('/redirect')
  },
  // 清除store and cookie and SELECT_COMPANY_LIST
  clearAuth(source) {
    Sentry.captureException(new Error(`问题排查 | clearAuth：${source}, userId: ${Storage.getUserInfo().userId}`))
    Store.commit('IS_LOGIN', false)
    Store.commit('USER_DATA', {})
    Storage.remove('JWT')
    Storage.remove('JWT_updateTime')
    Storage.remove('userId')
    Storage.remove('userInfo')
    Storage.remove('selectInfo')
    Storage.remove('selectPageNum')
    Storage.remove('SELECT_COMPANY_LIST')
    Storage.remove('isClickSentimentTrialTip') // 舆情模块是否点击了关闭提示
    Storage.remove('enterpriseUserCompanyInfo')
    Storage.remove('readHistory')
    // 更新sentry上传时的用户id
    process.env.NODE_ENV === 'production' && Sentry.setUser({ 'id': '' })
    this.getMenuInfo()
    if (window.ysf) {
      // 退出登录后登出七鱼客服，清空信息
      window.ysf('logoff')
    }
  },
  async getMenuInfo() {
    const userInfo = Storage.getUserInfo()
    const params = {
      companyId: userInfo.companyId || '',
      xiaoyun: Store.state.storeData.isXiaoYun
    }
    try {
      const menuInfoRes = await Axios.authority.get_menu_info(params)
      const menuData = menuInfoRes.data.returnObject
      this.appendParamToMenu(menuData)
      this.waterfall(menuData)
      Store.commit('MENU_LIST', menuData)
    } catch (error) {
      console.log(error)
    }
  },
  // 计算菜单瀑布流
  waterfall(menuList) {
    menuList.forEach(menu => {
      if (menu.isSimpleMenu) {
        return
      }
      const columnHeights = [0, 0, 0, 0, 0]
      menu.children.forEach((child, index) => {
        const minIndex = index % 5
        child.top = `${columnHeights[minIndex] > 0 ? columnHeights[minIndex] : 0}px`
        // 20是左侧padding值
        child.left = `${minIndex * 200 + 20}px`
        columnHeights[minIndex] = columnHeights[minIndex] + child.children.length * 25 + 56
        if (child.name === '公告复核管理' && this.isInfaithUser()) {
          // 信公咨询套餐公告复核管理菜单特殊处理
          child.top = '81px'
          child.left = '620px'
        } else if (child.name === '权益变动管理' && this.isInfaithUser()) {
          // 信公咨询套餐公告复核管理菜单特殊处理
          child.top = '101px'
          child.left = '20px'
        }
      })
      menu.height = Math.max(...columnHeights) + 30
    })
  },
  appendParamToMenu(menu) {
    menu.forEach((e, i) => {
      if (['研习社', '股权激励', '小安实验室', '小云实验室', '资源中心'].includes(e.name) && Array.isArray(e.children) && e.children.length > 0) {
        Vue.set(e, 'isSimpleMenu', true) // 简易菜单，只有2级
      } else {
        Vue.set(e, 'isSimpleMenu', false)
      }
    })
  },
  // 获取套餐切换列表
  getCompanyListByUserId() {
    Axios.manage.get_users_profiles().then(res => {
      const companyList = res.data.returnObject
      Store.commit('COMPANY_LIST', companyList || [])
    })
  },
  // 百度统计条件
  default_hmt(info) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`当前是${process.env.VUE_APP_XA_ENV}`)
      return
    }
    if (Storage.isLogin()) {
      // 登录
      if (window._hmt) {
        const roleMap = {
          'INFAITH': 'internal', // 内部用户
          'ENTERPRISE': 'enterprise', // 企业用户
          'OUTER': 'free', // 普通用户
          'SUPERVISE': 'supervisor', // 监管用户
          'INTERMEDIARY': 'intermediary' // 中介机构
        }
        _hmt.push(['_setUserId', Storage.getUserInfo().userId])
        // 普通用户返回用户信息内fundamentalRole可能为null，用OUTER兜底
        _hmt.push(['_setCustomVar', 1, 'customer', roleMap[info.fundamentalRole || 'OUTER'], 1])
      }
    } else {
      // 没登录
      // 百度统计 增加没登录的用户访问记录  设置为（null）
      if (window._hmt) {
        // 未登录
        _hmt.push(['_setCustomVar', 1, 'customer', 'unregister', 1])
      }
    }
  },
  // 判断板块类型 科创板/创业版。。。
  checkMarketType(companyCode) {
    return new Promise((resolve, reject) => {
      Axios.lookuptables.get_market_by_code(companyCode).then(res => {
        resolve(res.data.returnObject)
      })
    })
  },
  // 获取公司信息（包括非上市公司）
  getCompanyInfo(data) {
    return new Promise((resolve, reject) => {
      const params = {
        companyCode: data.companyCode || null,
        serviceOrganizationId: data.serviceOrganizationId || null
      }
      Axios.lookuptables.get_company_info(params).then(res => {
        resolve(res.data.returnObject)
      })
    })
  },
  // 根据用户角色获取对应的首页url
  // 为避免用户角色后台发生变化刷新页面时因缓存数据未更新出现死循环，用户当前角色首页无权限时，重定向至所属企业页面
  getHomeUrlByRole(authorityRole) {
    const userInfo = Storage.getUserInfo()
    switch (authorityRole) {
      // 监管公司和信公咨询的逻辑一致
      case 'SUPERVISE':
      case 'INFAITH':
        return Common.hasPermission('INFAITH_HOME', 'BASIC') ? '/dashboard/regulator' : '/manage/switch-company'
      case 'ENTERPRISE':
        if (userInfo.enterpriseCompanyFullCode?.includes('HK')) {
          // 港股上市公司以法规作为首页
          return '/rules'
        }
        if (userInfo.activeEnterpriseComboId === 15) {
          // 基础版（BJ/NQ/IPO）以市场行情作为首页
          return '/market-condition'
        }
        return Common.hasPermission('ENTERPRISE_EDITION_HOME', 'BASIC') ? '/dashboard' : '/manage/switch-company'
      case 'OUTER':
        if (this.isIndependentDirectorUser()) {
          // 若独董角色未更新，且已无独董权限,跳转至个人中心页面，account文件内会更新存储的用户信息，更新角色
          return Common.hasPermission('INDEPENDENT_DIRECTOR_HOME', 'BASIC') ? '/dashboard/independent-director' : '/manage/account'
        } else {
          return '/'
        }
      case 'INTERMEDIARY':
        return '/rules'
      default:
        return '/'
    }
  },

  goMainPage(context, userInfo) {
    if (!userInfo || !userInfo.authorityRole) {
      userInfo = Storage.getUserInfo()
    }
    context.$router.push({ path: this.getHomeUrlByRole(userInfo.authorityRole) })
  },
  // 信工咨询组
  isInfaithUser(authorityRole) {
    authorityRole = authorityRole || Storage.getUserInfo().authorityRole
    return authorityRole === 'INFAITH'
  },
  // 企业版
  isEnterpriseUser(authorityRole) {
    authorityRole = authorityRole || Storage.getUserInfo().authorityRole
    return authorityRole === 'ENTERPRISE'
  },
  // 监管公司
  isSuperviseUser(authorityRole) {
    authorityRole = authorityRole || Storage.getUserInfo().authorityRole
    return authorityRole === 'SUPERVISE'
  },
  // 外部用户
  isOuterUser(authorityRole) {
    authorityRole = authorityRole || Storage.getUserInfo().authorityRole
    return authorityRole === 'OUTER' && !Storage.getUserInfo().independentDirector
  },
  // 独立董事用户 独董用户
  isIndependentDirectorUser() {
    return Storage.getUserInfo().independentDirector
  },
  // 中介公司
  isIntermediaryUser(authorityRole) {
    authorityRole = authorityRole || Storage.getUserInfo().authorityRole
    return authorityRole === 'INTERMEDIARY'
  },
  // 检查用户是否具有组合搜索权限
  checkSearchGroupByUser(group) {
    const allGroup = ['服务公司', '本公司', '关注公司', '可比公司', '监管公司', '任职公司']
    if (!allGroup.includes(group)) {
      // 不是组合
      return true
    }
    let groups = []
    if (!Storage.isLogin()) {
      groups = []
    }
    if (this.isInfaithUser()) {
      groups = ['服务公司', '关注公司']
    }
    if (this.isEnterpriseUser()) {
      groups = ['本公司', '关注公司', '可比公司']
    }
    if (this.isSuperviseUser()) {
      groups = ['监管公司']
    }
    if (this.isOuterUser()) {
      groups = ['本公司', '关注公司']
    }
    if (this.isIndependentDirectorUser()) {
      groups = ['任职公司']
    }
    if (this.isIntermediaryUser()) {
      groups = ['关注公司']
    }
    // 无组合权限
    return groups.includes(group)
  },
  // 是否需要受到访问限制
  isLimitAccess() {
    return !Storage.isLogin() || this.isOuterUser() || (this.isIndependentDirectorUser() && Store.state.storeData.independentDirectorOverdue)
  }
}
