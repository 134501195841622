<!--
 * @Description: 组织架构树组件
-->
<template>
  <div class="organization-tree">
    <div
      class="organization-item"
      :class="{ selected: organization.id === selectedId }"
      @click="selectOrganization(organization)"
      v-for="(organization, organizationIndex) in organizationListFlatten"
      :key="organization.id"
      :style="{ 'padding-left': 26 * organization.level + 16 + 'px' }"
    >
      <div class="prefix">
        <span
          @click.stop="expandNode(organization, organizationIndex)"
          class="iconfont fold-icon"
          :class="arrowClassHandler(organization)"
        ></span>
        <span class="row-line"></span>
        <span
          class="column-line"
          :class="{
            'last-level-node':
              !organization.enterpriseOrganizationList || organization.enterpriseOrganizationList.length === 0,
          }"
          :style="{ height: getVerticalLineHeight(organization, organizationIndex) }"
        ></span>
        <span
          class="top-column-line"
          v-show="
            organizationListFlatten[organizationIndex - 1] &&
            organizationListFlatten[organizationIndex - 1].level === organization.level - 1
          "
        ></span>
      </div>
      <xa-tooltip
        :content="organization.organizationName"
        placement="right"
        v-if="organization.organizationName.length > 10"
      >
        <span class="organization-name">{{ organization.organizationName }}</span>
      </xa-tooltip>
      <span class="organization-name" v-else>{{ organization.organizationName }}</span>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'organizationTree',
  data: function() {
    return {
      selectedId: '',
      organizationListFlatten: [],
      organizationListFlattenCopy: []
    }
  },
  props: {
    organizationList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    organizationList: {
      handler(val) {
        this.organizationListFlatten = []
        this.flattening(val)
        this.organizationListFlatten.forEach((item, index) => {
          this.$set(item, 'expandedIndex', index)
        })
        this.organizationListFlattenCopy = cloneDeep(this.organizationListFlatten)
        if (val.length > 0) {
          this.selectedId = this.organizationListFlatten[0].id
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    flattening(organizationList) {
      organizationList?.length > 0 && organizationList.forEach((organization, organizationIndex) => {
        this.organizationListFlatten.push(Object.assign({}, organization, { expanded: true }))
        this.flattening(organization.enterpriseOrganizationList)
      })
    },
    isChildNode(organization) {
      // 是否是叶子节点
      return !(organization.enterpriseOrganizationList && organization.enterpriseOrganizationList.length)
    },
    arrowClassHandler(item) {
      let res
      if (this.isChildNode(item)) {
        res = {
          'blank-holder': true
        }
      } else {
        res = {
          'web-jian1': item.expanded === true,
          'web-jia3': item.expanded === false
        }
      }
      return res
    },
    expandNode(organization, organizationIndex) {
      // 展开tree
      if (!('blank-holder' in this.arrowClassHandler(organization))) {
        const childNodeIds = this.getChildNodeIds(organization)
        if (organization.expanded) {
          this.organizationListFlatten = this.organizationListFlatten.filter(item => !childNodeIds.includes(item.id))
        } else {
          const sliceList = this.organizationListFlattenCopy.filter(item => childNodeIds.includes(item.id))
          this.organizationListFlatten.splice(organizationIndex + 1, 0, ...cloneDeep(sliceList))
        }
        this.$set(organization, 'expanded', !organization.expanded)
      }
    },
    selectOrganization(organization) {
      this.selectedId = organization.id
      this.$emit('selectChange', organization)
    },
    getChildNodeIds(organization) {
      const nodes = []
      const nodePushHandle = function(organization) {
        if (organization.enterpriseOrganizationList?.length > 0) {
          organization.enterpriseOrganizationList.forEach((subOrganization) => {
            nodes.push(subOrganization.id)
            nodePushHandle(subOrganization)
          })
        }
      }
      nodePushHandle(organization)
      return nodes
    },
    // 获取当前节点下方垂直线段长度
    getVerticalLineHeight(organization, organizationIndex) {
      for (let index = 0; index < this.organizationListFlatten.length; index++) {
        const item = this.organizationListFlatten[index]
        if (item && item.level === organization.level && index > organizationIndex && item.parentId === organization.parentId) {
          const height = (index - organizationIndex) * 40
          return height + 'px'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.organization-tree {
  width: 100%;
  position: relative;
  .organization-item {
    position: relative;
    display: flex;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #323232;
    background: #f7f8f9;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    &:hover {
      color: #ffb148;
      background: rgba(255, 239, 218, 1);
    }
    &.selected {
      color: #ffb148;
      background: rgba(255, 239, 218, 1);
    }
    .prefix {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 26px;
      .row-line {
        position: relative;
        flex: 1;
        display: inline-block;
        border-bottom: 1px dashed #ffb148;
      }
      .column-line {
        position: absolute;
        top: 19px;
        left: 7px;
        z-index: 1;
        border-left: 1px dashed #ffb148;
      }
      .top-column-line {
        position: absolute;
        height: 28px;
        top: -10px;
        left: 7px;
        z-index: 1;
        border-left: 1px dashed #ffb148;
      }
      .fold-icon {
        font-size: 14px;
        color: #b7b7b8;
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        position: relative;
        &::before {
          position: absolute;
          background: #f7f8f9;
          z-index: 10;
        }
        &.blank-holder {
          width: 8px;
          height: 8px;
          display: inline-block;
        }
      }
    }
    .organization-name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}
</style>
