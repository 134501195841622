/*
 * describe: common javascript file
 * */
import Vue from 'vue'
import md5 from 'js-md5'
import * as htmlparser2 from 'htmlparser2'
import parser from 'ua-parser-js'
import { methods } from '@xiaoan/utils'
import Storage from '@/service/storage'
import Store from '@/store'
import Config from '@/config/config'
import { isValidPhoneNumber } from 'libphonenumber-js'
import areaCodeData from '@/service/area_code_data.json'
import * as Sentry from '@sentry/vue'

export default {

  /**
   * oss图片，添加自动旋转参数
   */
  addAutoOrientToImageUrl(srcUrl) {
    if (!srcUrl) {
      return srcUrl
    }
    if (srcUrl.includes('?')) {
      return srcUrl + '&x-oss-process=image/auto-orient,1'
    }
    return srcUrl + '?x-oss-process=image/auto-orient,1'
  },

  /**
   * 根据路由上的参数设置排序栏高亮与排序状态
   * @param {*} sortConfig 排序参数配置
   * @param {*} sortRule 排序规则
   * @param {*} sortType 排序方式
   */
  setSortBarFromRoute(sortConfig, sortRule, sortType) {
    if (!sortRule) {
      return sortConfig
    }
    sortConfig.forEach(item => {
      if (item.rule == sortRule) {
        item.isActive = true
        if (item.type && sortType) {
          item.type = sortType
        }
      } else {
        item.isActive = false
      }
    })
    return sortConfig
  },
  trim(val) {
    if (val === null) {
      return ''
    }
    if (typeof val != 'string') {
      return val
    }
    return val.trim()
  },
  // 去除换行
  clearBr(key) {
    if (key) {
      key = key.replace(/<br\/>/g, '')
      key = key.replace(/[\r\n]/g, '')
    } else {
      key = ''
    }
    return key
  },
  // 控制筛选的类的显示文字
  number(value, num) {
    if (!value) {
      return ''
    }
    if (num) {
      if (value.length >= num) {
        value = value.substring(0, num) + '...'
      }
      return value
    } else {
      if (value.length >= 10) {
        value = value.substring(0, 10) + '...'
      }
      return value
    }
  },
  // todo: 只有一处内调用，且暂无法被真是使用到，根据代码分析该方法功能时数组去重
  unique(arr) {
    var result = []
    var obj = {}
    for (let i = 0; i < arr.length; i++) {
      if (!Object.prototype.hasOwnProperty.call(obj, arr[i])) {
        result.push(arr[i])
        obj[arr[i]] = 0
      }
    }
    return result
  },
  // 多关键字 空格替换逗号处理
  transStr(str) {
    if (str) {
      return this.ImpArr(str.split(' ')).toString()
    } else {
      return ''
    }
  },
  // 去除空元素
  ImpArr(array) {
    const arr = array
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === '' || typeof (arr[i]) === 'undefined') {
        arr.splice(i, 1)
        i = i - 1
      }
    }
    return (arr)
  },
  // 返回顶部
  returnTop() {
    var timer = setInterval(function () {
      var ct = document.documentElement.scrollTop || document.body.scrollTop

      ct -= 50

      if (ct > 5) {
        window.scrollTo(0, ct)
      } else {
        window.scrollTo(0, 0)
        clearInterval(timer)
      }
    }, 10)
  },
  // 判断是否是PC
  ispc() {
    const system = { win: false, mac: false, xll: false }
    const p = navigator.platform
    system.win = p.indexOf('Win') == 0
    system.mac = p.indexOf('Mac') == 0
    system.xll = (p == 'X11') || (p.indexOf('Linux') == 0)
    const iphone = navigator.userAgent.indexOf('iPhone') < 0
    const Android = navigator.userAgent.indexOf('Android') < 0
    return ((system.win || system.mac || system.xll) && iphone && Android)
  },
  // 判断是否是微信浏览器的函数
  isWeiXin() {
    const ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    return ua.match(/MicroMessenger/i) == 'micromessenger'
  },
  // 小数点后保留两位 没有两位填0
  toDecimal2(x) {
    let f = parseFloat(x)
    if (isNaN(f)) {
      return x
    }
    f = Math.round(x * 100) / 100
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
      rs = s.length
      s += '.'
    }
    while (s.length <= rs + 2) {
      s += '0'
    }
    return s
  },
  /**
   *
   * @param {*} str 数值
   * @returns
   */
  autoPrice(str) {
    const value = parseFloat(str) // 将输入的值转换为数值，以处理可能的字符串数字
    const strValue = str ? str.toString() : '--' // 将输入的值转换为字符串
    if (typeof str == 'string' && str.indexOf('%') > -1) {
      return str
    } else if (strValue === 'y') {
      return 'y'
    } else if (isNaN(value)) {
      return '--'
    }

    const isMinus = value < 0
    const absValue = Math.abs(value)

    let unit = ''
    let divisor = 1// 单位

    // 逢千进万
    if (absValue >= 100000000000) {
      divisor = 1000000000000
      unit = '万亿'
    } else if (absValue >= 10000000) {
      divisor = 100000000
      unit = '亿'
    } else if (absValue >= 1000) {
      divisor = 10000
      unit = '万'
    }

    const formattedValue = this.toThousands(absValue / divisor, 2)
    return (isMinus ? '-' : '') + formattedValue + unit
  },
  // 以 亿 为单位
  yiUnit(str) {
    const num = parseFloat(str)

    if (str === '--' || str === null) {
      return '--'
    } else if (str === 'y') {
      return 'y'
    } else if (typeof str === 'string' && str.includes('%')) {
      return str
    } else if (isNaN(num)) {
      return str
    }

    const formattedValue = (Math.abs(num) / 100000000).toFixed(2)

    return `${num < 0 ? '-' : ''}${this.toThousands(formattedValue, 2)}亿`
  },
  // 以 万元 为单位 isForceConvert是否需要强制转换
  wanUnit(str, isForceConvert = false) {
    if (str === '--' || str === null) {
      return '--'
    } else if (str === 'y') {
      return 'y'
    } else if (typeof value === 'string' && str.includes('%')) {
      return str
    } else if (isNaN(str)) {
      return str
    }
    const num = parseFloat(str)
    let unit = ''
    let formattedValue = num.toFixed(2)

    if (Math.abs(num) >= 1000 || isForceConvert) {
      unit = '万'
      formattedValue = (Math.abs(num) / 10000).toFixed(2)
    }

    return `${num < 0 ? '-' : ''}${this.toThousands(formattedValue, 2)}${unit}`
  },
  printf(content, extraStyle) {
    const newWindow = window.open('', '_blank') // 打开新窗口
    if (!newWindow) {
      Vue.prototype.$xaToast({ type: 'info', message: '当前环境不支持打印，请用其他浏览器打开页面并打印' })
      return
    }
    newWindow.document.write(content + Config.printStyle + extraStyle) // 向文档写入HTML表达式或者JavaScript代码
    newWindow.document.close() // 关闭document的输出流, 显示选定的数据
    if (!newWindow) {
      console.log('问题排查日志')
      Sentry.captureException(new Error('问题排查 | 浏览器窗口为空'))
    }
    newWindow.print() // 打印当前窗口
  },
  // 获取当前用来签名的时间戳
  getSignTime() {
    let t = new Date().getTime()
    if (Storage.get('clientTimeDiff')) {
      t += Number(Storage.get('clientTimeDiff'))
    }
    return t
  },
  getMD5Id(id) {
    const md5Str = md5(`${this.getSignTime()}`)
    const md5Id = `${md5Str.slice(0, 8)}${id}${md5Str.slice(24)}`
    return md5Id
  },
  /**
   * 校验是否有对应资源权限
   * @param {*} module 模块
   * @param {*} resource 资源
   * @param {*} operation 操作
   * @param {*} checkOrg 是否校验用户所在机构权限
   */
  hasPermission(module = '.+', resource = '.+', operation = '.+', checkOrg = false) {
    // eslint-disable-next-line no-useless-escape
    const reg = new RegExp(`^(${module}|\\*):(${resource}|\\*):(${operation}|\\*)$`)
    if (checkOrg) {
      return Store.state.storeData.orgPermissionList.some(elem => {
        return reg.test(elem)
      })
    } else {
      return Store.state.storeData.personPermissionList.some(elem => {
        return reg.test(elem)
      })
    }
  },
  /**
   * @description 转义RegExp字符串中特殊的字符 "?", "(", ")", "[", "]", "{", "}","|"
   * @param {String} string 需要转义的字符串
   * @returns
   */
  escapeRegExp(string) {
    const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
    const reHasRegExpChar = new RegExp(reRegExpChar.source)
    return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string
  },
  checkPhoneNumberWithArea(phoneNumber, area = 'CN') {
    if (!phoneNumber) {
      return false
    }
    if (area === 'CN') {
      // libphonenumber-js认为10位也符合国内手机号格式，10位是以前的手机号，现在的格式要求是11位
      return Vue.prototype.$regx.telphone(phoneNumber)
    }
    return isValidPhoneNumber(phoneNumber, area) === true
  },
  getAreaByCode(code = '+86') {
    const area = areaCodeData.areaCodeData.find(item => item.areaCode == code)
    if (!area) {
      return 'CN'
    }
    return area.areaAbbreviation
  },
  // 获取对特殊字符处理后的关键字匹配正则表达式
  getRegxByKey(key) {
    const regx = new RegExp(
      '^' +
      key.replace(/[\\^$.*+?()[\]{}|]/g, function (txt) {
        return `\\${txt}`
      }) +
      '',
      'i'
    )
    return regx
  },
  // 判断是否是null或undefined并进行兜底，因当前版本element-ui中不支持使用Null判断运算符新增此方法
  nullValuePatch(val) {
    return val ?? '--'
  },
  // 过滤html字符串内的html标签，返回纯文本信息
  stripTags(htmlString) {
    let result = ''
    const parser = new htmlparser2.Parser({
      ontext(text) {
        result += text
      }
    })
    parser.write(htmlString)
    parser.end()
    return result
  },
  // 去除空元素
  deleteEmptyElement(array) {
    if (Array.isArray(array)) {
      return array.filter(ele => !!ele)
    } else {
      return []
    }
  },
  // 小数转换百分数并保留两位小数
  toPercent(point) {
    var str = Number(point).toFixed(2)
    return str
  },
  // 深层嵌套数组的扁平化
  deepFlatten(arr) {
    return [].concat(...arr.map(v => (Array.isArray(v) ? this.deepFlatten(v) : v)))
  },
  // 只能输入数字并且最多允许小数点两位 type为数据的类型 range为数据范围 hasZero为是否允许为0
  clearNoNum(num, hasZero, type, range) {
    if (num === 0 && hasZero) {
      return num
    }

    if (!num) {
      return
    }
    num = num.toString()
    if (type === 'num') {
      num = num.replace(/[^\d]/g, '') // 清除“数字”以外的字符
      if (range) {
        num = num.slice(0, range) // 限制位数
      }
    }
    num = num.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    num = num.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    num = num.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    num = num.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
    if (num.indexOf('.') < 0 && num != '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      num = parseFloat(num)
    }
    if (num === '.') {
      num = 0
    }
    return num
  },
  // 只能输入数字并且最多允许小数点两位 type为数据的类型 range为数据范围 hasZero为是否允许为0，数字允许大于-100的实数，是否为整数isInt
  clearNoNumll(num, hasZero, type, range, isInt = false) {
    if (num === 0 && hasZero) {
      return num
    }
    if (!num) {
      return
    }
    num = num.toString()
    if (type === 'num') {
      num = num.replace(/[^\d]/g, '') // 清除“数字”以外的字符
    }
    if (range && num) {
      if (type === 'intLength') {
        if (num.indexOf('.') == -1) { // 没小数点
          num = num.slice(0, range)
        }
      } else {
        num = num.slice(0, range) // 限制位数
        if (num.length === range && num[num.length - 1] === '.') {
          num = num.replace('.', '')
        }
      }
    }

    num = num.replace(/[^\-\d.]/g, '') // 清除“数字”“.”和"-"以外的字符
    num = num.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    // num = num.replace(/\.{2,}/g, '-') // 只保留第一个. 清除多余的
    num = num.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    num = num.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
    if (num.indexOf('.') < 0 && num != '') { // 没有小数点，不为空
      if (num[0] != '-' || num[2] == '0') { // 没有小数点的非负数
        num = parseFloat(num)
      } else { // 没有小数点负数
        if (num.indexOf('-', 1) > 0) { // ‘-’负号不在第一位
          num = '-'
        }
      }
    } else { // 有小数点
      if (num.indexOf('-', 1) > 0 || num === '0.00' || num === '-0.00') { //
        num = parseFloat(num)
      }
    }
    if (isInt && num !== '-') {
      num = String(num).split('.')[0]
      num = Number(num)
    }
    return num
  },
  downloadBlob(blob, filename = '') {
    if (this.wechatBlobIntercept()) {
      return
    }
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    }
  },
  setMarketXh(data) {
    const tmp = data.map((item) => {
      if (item) {
        if (item.indexOf('沪市') > -1) {
          item = item.replace('沪市', '沪')
        }
        if (item.indexOf('深市主板') > -1) {
          item = item.replace('深市', '深')
        }
        if (item.indexOf('深市') > -1 && item != '深市主板') {
          item = item.replace('深市', '')
        }
      }
      return item
    })
    return tmp.join('&nbsp;/&nbsp;')
  },
  /**
   * 四舍五入保留指定位数小数，为null或undefined返回默认值
   * @param {要处理的值} value
   * @param {保留小数位数，负数不处理小数} fixed
   * @param {默认值} defaultValue
   */
  toFixed(value, fixed = -1, defaultValue = '--') {
    if (value === null || value === undefined) {
      return defaultValue
    }
    if (typeof value === 'string') {
      value = parseFloat(value)
    }
    if (typeof value !== 'number' || isNaN(value)) {
      return defaultValue
    }
    if (fixed < 0) {
      return value
    } else {
      return value.toFixed(fixed)
    }
  },
  /**
 * @description 姓名&备注名（包含中文的字符串过滤中间、首尾空格，全英文首尾空格）
 * @param {String} name 姓名&备注名
 */
  nameRemoveSpace(name) {
    let resName = ''
    const pattern = /^[a-zA-Z·.\s]+$/g
    if (name === undefined || name === null) {
      name = ''
    }
    name = name + ''
    if (pattern.test(name)) {
      // 包含英文、点、空格
      resName = name.replace(/^\s*|\s*$/g, '')
    } else {
      resName = name.replace(/\s*/g, '')
    }

    return resName
  },
  guidanceOldFormMobileRoute(to) {
    let to_url
    if (to.path == '/index') {
      to_url = '/rules'
    } else if (to.path == '/rules/compilation') {
      to_url = '/rules'
    } else if (to.path == '/affiches') {
      to_url = '/affiches/list'
    } else {
      to_url = to.fullPath
    }
    return `${to_url}`
  },
  wechatBlobIntercept() {
    const parserUA = parser(navigator.userAgent)
    if (parserUA?.browser?.name === 'WeChat') {
      Vue.prototype.$xaConfirm({ message: '当前浏览器暂不支持下载该文件，请至Chrome或Edge中操作。', showCancelButton: false, showClose: false })
      return true
    }
  },
  // 用于将后端描述激励类型的数字或枚举类转换成前端展示的文字
  toolMapping(val) {
    switch (val) {
      case '0':
      case 0:
      case 'FIRST_RESTRICT_STOCK':
        return '一类限制性股票'
      case '1':
      case 1:
      case 'STOCK_OPTION':
        return '股票期权'
      case '2':
      case 2:
      case 'SECOND_RESTRICT_STOCK':
        return '二类限制性股票'
      case 'STOCK_APPRECIATION_RIGHTS':
        return '股票增值权'
      default: return val
    }
  },
  /**
   * 四舍五入保留指定位数小数，为null或undefined返回默认值
   * @param {要处理的值} value
   * @param {保留小数位数} num
   * @param {后缀} prefix
   */
  toThousandsWithSuffix(value, num, prefix = '') {
    if (value === null || value === undefined) {
      return '--'
    }
    return this.toThousands(value, num) + prefix
  },
  ...methods
}
