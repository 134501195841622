
const storeData = {
  state: {
    isXiaoYun: false, // 判断是否是小云域名
    isLogin: false,
    userData: {},
    comparisonCompany: [],
    companyList: [],
    redirect: {
      url: '/index',
      msg: '',
      name: '首页'
    },
    institutionAccountInfo: {}, // 个人股东信息
    shareholderDates: [], // 股东 总期数
    menuList: [],
    scaleShowState: true, // 侧边栏是否全屏显示的状态
    answerExclude: [], // 信乎社区回答
    infaithCompanyList: [],
    superviseCompanyList: [],
    selectInfo: {},
    personPermissionList: [],
    orgPermissionList: [],
    hasGetPermissions: false,
    hasCompanyType: false, // 是否已经获取了companyType
    showPermissionDialog: 0, // 0：不展示无权限弹窗  1：普通无权限弹窗  2：明确无权限模块的弹窗
    permissionDialogModule: '', // 无权限弹窗对应的具体模块
    permissionPageModule: '', // 无权限页面对应的具体模块
    unReadMsgCount: 0,
    independentDirectorOverdue: false, // 是否为已过期独董
    independentDirectorDialog: false, // 是否展示独董续费弹窗
    vxaNotifyExist: false, // 是否存在vxaNotify实例
    errorReportedToDingDing: [], // 已上报至钉钉的错误
    dashboardType: null
  },
  mutations: {
    SCALE_SHOW_STATE: (state, data) => {
      state.scaleShowState = data
    },
    SHAREHOLDER_DATES: (state, data) => {
      state.shareholderDates = data
    },
    INSTITUTION_ACCOUNT_INFO: (state, data) => {
      state.institutionAccountInfo = data
    },
    IS_LOGIN: (state, data) => {
      state.isLogin = data
    },
    USER_DATA: (state, data) => {
      state.userData = data
    },
    COMPANY_LIST: (state, data) => {
      state.companyList = data
    },
    REDIRECT: (state, data) => {
      state.redirect = data
    },
    MENU_LIST: (state, data) => {
      state.menuList = data
    },
    SET_ANSWER_EXCLUDE: (state, data) => {
      state.answerExclude = data
    },
    SET_INFAITH_COMPANY_LIST: (state, data) => {
      state.infaithCompanyList = data
    },
    SET_SUPERVISE_COMPANY_LIST: (state, data) => {
      state.superviseCompanyList = data
    },
    SELECT_INFO: (state, data) => {
      state.selectInfo = data
    },
    PERMISSIONS: (state, data) => {
      state.personPermissionList = data.personal
      state.orgPermissionList = data.organization
      state.hasGetPermissions = true
    },
    PERMISSIONS_STORAGE: (state, data) => {
      state.personPermissionList = data.personal
      state.orgPermissionList = data.organization
    },
    CLEAR_PERMISSIONS: (state) => {
      state.hasGetPermissions = false
    },
    SHOW_PERMISSION_DIALOG: (state, data) => {
      state.showPermissionDialog = data
    },
    PERMISSION_DIALOG_MODULE: (state, data) => {
      state.permissionDialogModule = data
    },
    PERMISSION_PAGE_MODULE: (state, data) => {
      state.permissionPageModule = data
    },
    UPDATEHAS_COMPANY_TYPE: (state, data) => {
      state.hasCompanyType = data
    },
    SET_UNREAD_MSG: (state, data) => {
      state.unReadMsgCount = data
    },
    SET_XIAO_YUN: (state, data) => {
      state.isXiaoYun = data
    },
    INDEPENDENT_DIRECTOR_OVERDUE: (state, data) => {
      state.independentDirectorOverdue = data
    },
    INDEPENDENT_DIRECTOR_DIALOG: (state, data) => {
      state.independentDirectorDialog = data
    },
    VXA_NOTIFY_EXIST: (state, data) => {
      state.vxaNotifyExist = data
    },
    ERROR_REPORTED_TO_DINGDING: (state, data) => {
      state.errorReportedToDingDing = data
    },
    SET_COMPARISON_COMPANY: (state, data) => {
      state.comparisonCompany = data
    },
    DASHBOARD_TYPE: (state, data) => {
      state.dashboardType = data
    }
  },
  getters: {
    GET_INFAITH_COMPANY_LIST: state => {
      return state.infaithCompanyList
    }
  }
}
export default storeData
