
const esgguanli = [
  {
    subIssueTitle: '高级决策者声明',
    subIssueTooltipContent: `对应GRI标准: 102-14<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '公司最高决策者（如CEO、主席或同等高级职位）就可持续发展与公司的相关性及可持续发展战略的声明。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '董事会参与ESG管理',
    subIssueTooltipContent: `对应GRI标准: 102-26, 102-28, 102-31<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 强制披露规定`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括董事会对ESG事宜的监管评审、董事会的ESG管理方针、策略、管理过程；董事会和高管在制定、批准和更新公司在经济、环境和社会议题方面的宗旨、价值观、使命、战略、政策和目标中的作用。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: 'ESG管理架构',
    subIssueTooltipContent: `对应GRI标准: 102-18, 102-19, 102-20<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '负责经济、环境和社会议题决策的委员会与职责。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: 'ESG管理体系',
    subIssueTooltipContent: `对应GRI标准: 102-29<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括公司的ESG管理模型、ESG管理的实质性议题与管理方法、目标等内容。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: 'ESG融入公司业务',
    subIssueTooltipContent: `对应GRI标准: 无<br>
        对应MSCI ESG指标: 责任投资<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述ESG理念与公司业务或产品融合的情况。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: 'ESG风险管理',
    subIssueTooltipContent: `对应GRI标准: 102-30<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '描述公司对经济、社会、环境等议题的风险分析与管理，公司董事会及管理层在评估有关经济、环境和社会议题的风险管理流程的效果方面的作用。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: 'ESG培训与能力建设',
    subIssueTooltipContent: `对应GRI标准: 102-27<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括公司为加强管理层、员工对于经济、环境和社会议题的集体认识与能力而采取的措施。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: '',
      quantifyDataList: [{
        fillBlockType: 'quantifyData',
        title: '',
        tooltipContent: '',
        taskYear: '2020',
        indicatorDataList: [
          { title: 'ESG培训次数', key: 'ESG_MANAGEMENT_1', unit: '次', yearDataList: ['', '', ''] },
          { title: 'ESG培训覆盖人次', key: 'ESG_MANAGEMENT_2', unit: '人次', yearDataList: ['', '', ''] }
        ]
      }]
    }]
  },
  {
    subIssueTitle: '董事会在可持续发展报告方面的作用',
    subIssueTooltipContent: `对应GRI标准: 102-32<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 强制披露规定`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括正式评审和批准组织的可持续发展报告并确保已涵盖所有实质性议题的。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  },
  {
    subIssueTitle: '利益相关方沟通',
    subIssueTooltipContent: `对应GRI标准: 102-21, 102-33, 102-34<br>
        对应MSCI ESG指标: 无<br>
        对应港交所ESG指引: 无`,
    fillBlockList: [{
      fillBlockType: 'contentExplain',
      isNeedFill: true,
      title: '包括利益相关方和最高管治机构就经济、环境和社会议题磋商的过程；如果授权磋商，说明授权的对象和向最高管治机构提供反馈的过程。',
      tooltipContent: '',
      description: '',
      fileUrl: '',
      relatedContentLink: ''
    }]
  }
]

export default esgguanli
