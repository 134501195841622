import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  //  股票行情想关API
  //  获取K线
  get_stock_k_line_data(params, companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.get_stock_k_line_data, [['companyCode', companyCode]]), params: params, originUrl: api.stock.get_stock_k_line_data })
  },
  //  批量获取行情
  get_stock_info_by_codes(params) {
    return Axios({ method: 'POST', url: api.stock.get_stock_info_by_codes, data: params })
  },
  //  申万 和证监会
  list_same_industry_company(params, companyId) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.list_same_industry_company, [['companyId', companyId]]), params: params, originUrl: api.stock.list_same_industry_company })
  },
  //  比较
  finance_same_industry_indicator(params) {
    return Axios({ method: 'POST', url: api.stock.finance_same_industry_indicator, data: params })
  },
  search_stock(params) {
    return Axios({ method: 'GET', url: api.stock.search_stock, params: params })
  },
  attention_company(params) {
    return Axios({ method: 'POST', url: api.stock.attention_company, data: params })
  },
  delete_attention(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.delete_attention, [['companyCode', companyCode]]), originUrl: api.stock.delete_attention })
  },
  batch_delete_attention(params) {
    return Axios({ method: 'DELETE', url: api.stock.batch_delete_attention, params: params })
  },
  get_stock_change_announcement(params) {
    return Axios({ method: 'GET', url: api.stock.get_stock_change_announcement, params: params })
  },
  get_stock_change_for_report_list(params) {
    return Axios({ method: 'POST', url: api.stock.get_stock_change_for_report_list, data: params })
  },
  // 查询公司类型
  get_company_type_by_userId(params) {
    return Axios({ method: 'GET', url: api.stock.get_company_type_by_userId, params })
  },
  stock_change_status(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.stock_change_status, [['code', code]]), originUrl: api.stock.stock_change_status })
  },
  stock_change_calculate(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.stock_change_calculate, [['code', code]]), originUrl: api.stock.stock_change_calculate })
  },
  //  获取公司fullCode
  get_company_fullCode(params) {
    return Axios({ method: 'GET', url: api.stock.get_company_fullCode, params })
  },
  //  可转债历史行情数据
  redeem_reset_info(companyCode) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.redeem_reset_info, [['companyCode', companyCode]]), originUrl: api.stock.redeem_reset_info })
  },
  // 获取公司基本信息
  company_info(code) {
    return Axios({ method: 'GET', url: Common.pathFilter(api.stock.company_market_info, [['companyCode', code]]), originUrl: api.stock.company_market_info })
  },
  // 判断当前日期是否为交易日
  isTradeDate(params) {
    return Axios({ method: 'GET', url: api.stock.isTradeDate, params })
  },
  // 查询申万行业接口
  stock_sw_industry(params) {
    return Axios({ method: 'GET', url: api.stock.stock_sw_industry, params })
  },
  //  获取K线v2
  stock_k_line_v2(data) {
    return Axios({ method: 'POST', url: api.stock.stock_k_line_v2, data })
  },
  k_line_option(params) {
    return Axios({ method: 'GET', url: api.stock.k_line_option, params })
  }
}
