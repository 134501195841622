<!--
 * @Description: 搜索并选择公司信息，显示公司名称和代码，以及在下拉框中选择不同的公司
-->
<template>
  <div id="changeCompany">
    <div class="top-pop-box" :class="{ 'top-pop-box-noLookup': haveHover }">
      <span class="companyCode" v-if="showCode">{{ companyItem.companyFullCode }}</span>
      <xa-popover
        visible-arrow
        v-if="isOnlyShowName"
        @show="handleShowPopover"
        @hide="() => (popOpen = false)"
        :placement="placement"
        v-model="showPopover"
        style="width: 100%"
        :width="companyTypeList.length ? 310 : 220"
        trigger="click"
        popper-class="companyPopper"
      >
        <div
          slot="reference"
          id="company-name"
          :class="{ companyName: true, clearable: clearable && companyItem.companyName }"
        >
          <span v-show="companyItem.companyName">{{ companyItem.companyName }}</span>
          <span v-show="!companyItem.companyName" class="placeholder">请选择</span>
          <template v-if="clearable">
            <i class="el-icon-arrow-down i-caret" :class="{ 'pop-open': popOpen }"></i>
            <i
              v-show="companyItem.companyName"
              class="el-icon-circle-close i-caret clearable-icon"
              @click="popoverClearable"
            ></i>
          </template>
          <template v-else>
            <i class="el-icon-caret-bottom i-caret" :class="{ 'pop-open': popOpen }"></i>
          </template>
        </div>
        <div class="global-company-content">
          <div class="company-type_tab" v-if="companyTypeList.length">
            <div
              class="tab-item_wrapper"
              :class="{
                active: infaithSetCompanyType === tab.tabValue,
              }"
              v-for="tab in companyTypeList"
              :key="tab.tabValue"
              @click="changeCompanyType(tab)"
            >
              {{ tab.tabName }}
            </div>
          </div>
          <div
            class="company-content-box"
            :style="{ 'border-left': companyTypeList.length ? '1px solid #f6f6f6' : 'none' }"
          >
            <!-- 全部公司 -->
            <template v-if="infaithSetCompanyType === 'allCompany'">
              <div class="search-page">
                <xa-input
                  v-model.trim="searchWord"
                  :maxlength="100"
                  class="autocomplete-wrapper changeCompany-input-location"
                  size="small"
                  placeholder="公司简称、证券代码"
                  @input="throttleHandle"
                >
                  <i slot="suffix" class="el-input__icon el-icon-search icon-search" @click.stop="throttleHandle"></i>
                </xa-input>
              </div>
              <div class="company-item-box">
                <div
                  class="company-item"
                  v-for="item in companyList"
                  :key="item.companyCode"
                  @click="enterpriseSetCompany(item)"
                >
                  <span v-html="$common.highLight(item.code, searchWord, true, true)"></span>
                  <span v-html="$common.highLight(item.name, searchWord, true, true)"></span>
                </div>
              </div>
              <div class="view-self-company" v-if="isShowViewSelfCompanyBtn && this.$auth.isEnterpriseUser()">
                <span class="btn-span" @click="viewSelfCompany">查看本公司</span>
              </div>
            </template>

            <template v-else>
              <div class="search-page">
                <xa-input
                  v-model.trim="searchWord"
                  :maxlength="100"
                  class="input-search changeCompany-input-location"
                  size="small"
                  placeholder="公司简称、证券代码"
                  @input="searchThrottle"
                >
                  <i slot="suffix" class="el-input__icon el-icon-search icon-search" @click.stop="searchThrottle"></i>
                </xa-input>
                <v-page
                  v-if="showPagination"
                  class="vpage"
                  :flip="showPageNum"
                  :total="total"
                  v-on:addition="addition"
                  v-on:subtraction="subtraction"
                ></v-page>
              </div>
              <div class="company-item-box">
                <template>
                  <div
                    class="company-item"
                    v-for="(item, index) in companyList"
                    :key="index"
                    :class="{
                      'company-select':
                        (!$auth.isInfaithUser() && item.companyCode == companyItem.companyCode) ||
                        ($auth.isInfaithUser() &&
                          item.companyId === companyItem.companyId &&
                          companyItem.infaithCompanyType === infaithSetCompanyType),
                      'top-company_wrapper': item.stickied,
                    }"
                    v-show="showCompany(index)"
                    @click="setCompany(item)"
                  >
                    <span>{{ item.companyFullCode || '--' }}</span>
                    <span>{{ item.companyName }}</span>
                  </div>
                </template>
                <template v-if="$auth.isInfaithUser() && companyObject.length === 0">
                  <div class="no-info-empty-box" style="">
                    <img
                      class="empty-img"
                      alt="暂无数据"
                      :src="`${$api.aliyunDomain}xiaoan/web_static/empty/ic_empty_small_module.png`"
                    />
                    <div v-if="infaithSetCompanyType === 'serviceCompany'" class="empty-text">暂无服务公司</div>
                    <div v-else class="empty-text">
                      <span style="font-size: 14px">点击添加</span>
                      <span style="color: #ffb148; cursor: pointer" @click="addFollowCompanies">关注公司</span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </xa-popover>

      <div v-else>{{ companyItem.companyName }}</div>
    </div>
  </div>
</template>

<script>
import page from '@/components/pagination/customPage.vue'
import { throttle, cloneDeep } from 'lodash'
export default {
  name: 'changeCompany',
  data() {
    return {
      popOpen: false,
      showPopover: false,
      searchWord: '',
      companyItem: {},
      companyList: [], // 筛选 搜索后的数据
      companyObject: [], // 接口完整数据
      infaithServiceCompanyList: [],
      infaithFollowCompanyList: [],
      allCompanyList: [], // 全部公司
      pageNum: 1,
      pageSize: 10000,
      total: 0,
      infaithSetCompanyType: 'serviceCompany',
      companyTypeList: []
    }
  },
  components: {
    'v-page': page
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    showCode: {
      type: Boolean,
      default: false
    },
    haveHover: {
      type: Boolean,
      default: true
    },
    // 当公司只有一家时，是否只展示名称，不展示下拉选择
    onlyShowName: {
      type: Boolean,
      default: false
    },
    // 是否搜索所有公司
    showAllCompany: {
      type: Boolean,
      default: false
    },
    isNotSupportUnlisted: {
      type: Boolean,
      default: true
    },
    // 是否可以清空选项，在此情况下，公司的选择不会影响到全局其他地方
    clearable: {
      type: Boolean,
      default: false
    },
    // 是否使用分页
    showPagination: {
      type: Boolean,
      default: false
    },
    // 是否只查询A股上市公司，过滤掉B股
    aStock: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPageNum() {
      if (this.total === 0) {
        return 0
      }
      return this.pageNum
    },
    isOnlyShowName() {
      if (this.$auth.isInfaithUser()) {
        return true
      } else if (this.disabled) {
        return false
      } else {
        return this.companyObject.length !== 1 || !this.onlyShowName
      }
    },
    isShowViewSelfCompanyBtn() {
      return this.companyItem.companyName != this.userInfo.enterpriseCompanyName
    },
    enterpriseUserCompanyInfo() {
      return this.$storage.getEnterpriseUserCompanyInfo()
    },
    companyType() {
      return this.$storage.get('companyType')
    },
    selectInfo() {
      return this.$storage.getSelectInfo()
    },
    userInfo() {
      return this.$storage.getUserInfo()
    }
  },
  created() {
    this.init()
  },
  beforeUnmount() {
    if (this.throttleHandle) {
      this.throttleHandle.cancel()
    }
    if (this.searchThrottle) {
      this.searchThrottle.cancel()
    }
  },
  methods: {
    init() {
      if (this.throttleHandle) {
        this.throttleHandle.cancel()
      }
      if (this.searchThrottle) {
        this.searchThrottle.cancel()
      }
      this.searchThrottle = throttle(e => {
        this.search()
      }, 600)
      this.throttleHandle = throttle(e => {
        this.getAllCompanyList()
      }, 600)
      if (this.showPagination) {
        this.pageSize = 10
      }
      if (this.$auth.isInfaithUser()) {
        this.companyTypeList = [
          {
            tabName: '服务公司',
            tabValue: 'serviceCompany'
          },
          {
            tabName: '关注公司',
            tabValue: 'followCompany'
          }
        ]
      }
      if (this.showAllCompany) {
        if (this.companyTypeList.length > 0) {
          this.companyTypeList.push({
            tabName: '全部公司',
            tabValue: 'allCompany'
          })
        } else {
          this.changeCompanyType({
            tabName: '全部公司',
            tabValue: 'allCompany'
          })
        }
      }
      if (this.$auth.isSuperviseUser()) {
        this.getMySupervisedCompanyList('create')
      } else if (this.$auth.isInfaithUser()) {
        this.getMyInfaithCompanyList('create')
      } else if (this.$auth.isIndependentDirectorUser()) {
        this.getIndependentDirectorCompanyList()
      }
      if (!this.$auth.isInfaithUser()) {
        if (this.$auth.isEnterpriseUser() && this.showAllCompany && this.enterpriseUserCompanyInfo) {
          this.companyItem = this.enterpriseUserCompanyInfo
        } else {
          this.companyItem = this.$storage.getSelectInfo()
        }
      }
      if (this.companyTypeList.length) {
        this.changeCompanyType(this.companyTypeList[0])
      }

      if (this.$storage.get('selectPageNum')) {
        const selectPageNum = this.$storage.get('selectPageNum')
        this.pageNum = parseInt(selectPageNum)
      }
    },
    changeCompanyType(tab) {
      this.infaithSetCompanyType = tab.tabValue
      this.searchWord = ''
      this.companyObject = cloneDeep(
        this.infaithSetCompanyType === 'serviceCompany' ? this.infaithServiceCompanyList : this.infaithFollowCompanyList
      )
      switch (this.infaithSetCompanyType) {
        case 'serviceCompany':
          this.companyObject = cloneDeep(this.infaithServiceCompanyList)
          break
        case 'followCompany':
          this.companyObject = cloneDeep(this.infaithFollowCompanyList)
          break
        case 'allCompany':
          this.companyObject = cloneDeep(this.allCompanyList)
          if (this.$auth.isEnterpriseUser()) {
            if (this.enterpriseUserCompanyInfo) {
              if (this.companyType == 0 || this.enterpriseUserCompanyInfo.companyName != this.selectInfo.companyName) {
                this.searchWord = this.enterpriseUserCompanyInfo.companyCode
              }
            } else {
              if (this.companyType == 0) {
                this.searchWord = this.selectInfo.companyCode
              }
            }
          }
          break
      }
      this.companyList = cloneDeep(this.companyObject)
      this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
      this.pageNum = 1
    },
    async handleShowPopover() {
      if (this.showAllCompany) {
        await this.getAllCompanyList()
      }
      this.popOpen = true
    },
    goSetCompany() {
      this.$router.push('/dashboard/regulator')
    },
    search() {
      this.companyList = []
      this.companyObject.forEach(item => {
        let regx
        if (!this.searchWord) {
          regx = this.$common.getRegxByKey(this.searchWord)
        }
        if (
          (item.companyFullCode && item.companyFullCode.indexOf(this.searchWord) > -1) ||
          item.companyName.indexOf(this.searchWord) > -1 ||
          (item.companyPinyin && regx && regx.test(item.companyPinyin))
        ) {
          this.companyList.push(item)
        }
      })

      this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
      this.pageNum = 1
    },
    showCompany(index) {
      if (!this.showPagination) {
        return true
      }
      const start = (this.pageNum - 1) * this.pageSize
      const end = this.pageNum * this.pageSize
      return index >= start && index < end
    },
    setCompany(item) {
      const data = {
        companyName: item.companyName,
        companyId: this.$auth.isSuperviseUser() ? item.organizationId : item.companyId,
        companyCode: item.companyCode,
        companyFullCode: item.companyFullCode
      }
      if (this.$auth.isInfaithUser()) {
        data.listed = item.listed || true
        // 信工咨询-服务公司-某些模块不支持未上市公司
        if (
          item.infaithCompanyType === 'serviceCompany' &&
          !item.listed &&
          item.listed !== null &&
          this.isNotSupportUnlisted
        ) {
          this.$xaConfirm({
            message: '当前选中的是非上市公司，非上市公司暂不支持此功能。',
            showCancelButton: false,
            confirmButtonText: '确 认'
          })
            .then(() => {})
            .catch(() => {})
          return
        }
      }
      if (!this.clearable) {
        this.$storage.set('selectInfo', data)
        if (this.$auth.isInfaithUser() && this.infaithSetCompanyType === 'serviceCompany') {
          this.$eventBus.$emit('updateSelectInfo', data)
        }

        // 用于股东数据管理内，上传文件能实时更新companyCode
        this.$store.commit('SELECT_INFO', data)
        this.$storage.set('selectPageNum', this.pageNum)
        this.$auth.getMenuInfo()
      }
      this.$auth.resetTipShowObj()
      this.companyItem = item
      this.showPopover = false
      this.$router.replace({ query: { ...this.$route.query, companyCode: item.companyCode } })

      this.$emit('set-company', item)
    },
    // 清空选择
    popoverClearable() {
      this.companyItem = {}
      this.$emit('set-company', this.companyItem)
    },
    // 页数加一
    addition() {
      if (this.pageNum < this.total) {
        this.pageNum += 1
      }
    },
    // 页数减一
    subtraction() {
      if (this.pageNum > 1) {
        this.pageNum -= 1
      }
    },
    enterpriseSetCompany(item) {
      this.searchWord = item.code
      this.$storage.set('enterpriseUserCompanyInfo', item)
      this.companyItem = item
      this.companyList = [item]
      this.showPopover = false
      this.$emit('set-company', item)
    },
    // 获取所有公司
    getAllCompanyList() {
      return new Promise(resolve => {
        const params = {
          key: this.searchWord
        }
        const request = this.aStock
          ? this.$axios.enterpriseCompany.a_stock_listed_companies(params)
          : this.$axios.affiche.get_related_company(params)

        request
          .then(res => {
            this.allCompanyList = res.data.returnObject.map(v => ({
              ...v,
              companyName: v.name,
              companyFullCode: v.fullCode,
              companyCode: v.code
            }))
            if (this.infaithSetCompanyType === 'allCompany') {
              this.companyList = cloneDeep(this.allCompanyList)
            }
          })
          .catch(err => {
            console.error(err)
            this.allCompanyList = []
            if (this.infaithSetCompanyType === 'allCompany') {
              this.companyList = cloneDeep(this.allCompanyList)
            }
          })
          .finally(() => {
            resolve()
          })
      })
    },
    // 获取所有信工咨询 服务公司以及关注公司
    getMyInfaithCompanyList() {
      const promiseList = [
        this.$axios.enterpriseCompany.get_my_infaith_company_list(),
        this.$axios.enterpriseCompany.get_served_companies()
      ]

      Promise.all(promiseList).then(async ([followCompanyRes, serviceCompanyRes]) => {
        this.infaithFollowCompanyList = followCompanyRes.data.returnObject.data.map(v => {
          v.infaithCompanyType = 'followCompany'
          return v
        })
        this.infaithServiceCompanyList = serviceCompanyRes.data.returnObject.data.map(v => {
          v.infaithCompanyType = 'serviceCompany'
          return v
        })

        this.companyItem = this.$storage.getSelectInfo()

        this.infaithSetCompanyType = this.infaithServiceCompanyList.some(
          v => v.companyId === this.companyItem.companyId
        )
          ? 'serviceCompany'
          : 'followCompany'
        this.companyItem.infaithCompanyType = this.infaithSetCompanyType

        // 初始化加载地址栏公司时
        if (this.$route.query.fromRegu == 1 || this.$route.query.fromTodaySentiment == 1) {
          let hasCompany = false

          this.infaithFollowCompanyList.forEach((e, i) => {
            if (
              e.companyFullCode === this.$route.query.companyCode ||
              e.companyCode === this.$route.query.companyCode
            ) {
              hasCompany = true
              this.infaithSetCompanyType = 'followCompany'
              this.setCompany(e)

              const num = Math.ceil((i + 1) / 10)

              if (!this.clearable) {
                this.$storage.set('selectPageNum', num)
              }
              this.pageNum = num
            }
          })

          this.infaithServiceCompanyList.forEach((e, i) => {
            if (
              e.companyFullCode === this.$route.query.companyCode ||
              e.companyCode === this.$route.query.companyCode
            ) {
              hasCompany = true
              this.infaithSetCompanyType = 'serviceCompany'
              this.setCompany(e)

              const num = Math.ceil((i + 1) / 10)

              if (!this.clearable) {
                this.$storage.set('selectPageNum', num)
              }
              this.pageNum = num
            }
          })

          if (!hasCompany && this.showAllCompany) {
            this.infaithSetCompanyType = 'allCompany'
          }
        }

        switch (this.infaithSetCompanyType) {
          case 'serviceCompany':
            this.companyObject = cloneDeep(this.infaithServiceCompanyList)
            break
          case 'followCompany':
            this.companyObject = cloneDeep(this.infaithFollowCompanyList)
            break
          default:
            this.searchWord = this.$route.query.companyCode
            await this.getAllCompanyList()
            this.companyObject = cloneDeep(this.allCompanyList)
            this.allCompanyList.forEach((e, i) => {
              if (
                e.companyFullCode === this.$route.query.companyCode ||
                e.companyCode === this.$route.query.companyCode
              ) {
                this.enterpriseSetCompany(e)
              }
            })
            break
        }
        this.$emit(
          'infaithAllCompanyLoaded',
          cloneDeep(this.infaithServiceCompanyList),
          cloneDeep(this.infaithFollowCompanyList)
        )

        this.companyList = cloneDeep(this.companyObject)

        if (this.companyObject.toString() != '') {
          this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
        } else {
          this.showNotification()
        }
        if (
          (this.infaithSetCompanyType === 'serviceCompany' || this.infaithSetCompanyType === 'followCompany') &&
          !this.companyItem.listed &&
          this.isNotSupportUnlisted
        ) {
          const allList = [...this.infaithServiceCompanyList, ...this.infaithFollowCompanyList]
          const correctCompany = allList.filter(v => v.listed || v.listed === null)[0]
          this.setCompany(correctCompany)
        }
        if (this.clearable) {
          this.companyItem = {}
          this.pageNum = 1
        }
      })
    },
    // 获取所有任职公司
    getIndependentDirectorCompanyList() {
      this.$axios.enterpriseCompany.independent_director_companies().then(res => {
        this.companyObject = res.data.returnObject
        this.companyList = res.data.returnObject
        if (this.companyObject.toString() != '') {
          this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
        } else {
          this.showNotification()
        }
        if (this.$route.query.fromRegu == 1 || this.$route.query.fromTodaySentiment == 1) {
          this.companyList.forEach((e, i) => {
            if (e.companyFullCode == this.$route.query.companyCode || e.companyCode == this.$route.query.companyCode) {
              this.setCompany(e)
              const num = Math.ceil((i + 1) / 10)

              this.$storage.set('selectPageNum', num)
              this.pageNum = num
            }
          })
        }
        // 如果本地存储的公司已经不在所有任职公司选项中，则默认选中第一个
        const result = this.companyList.find(item => item.companyCode == this.$storage.getSelectInfo().companyCode)
        if (!result) {
          this.setCompany(this.companyList[0])
        }
      })
    },

    showNotification() {
      const type = this.$auth.isSuperviseUser() ? '监管' : '服务'
      let message = `你还未设置${type}公司，前往设置。`
      if (this.$auth.isInfaithUser()) {
        message = '你还未设置服务公司或关注公司，前往设置。'
      }
      this.$xaConfirm({
        title: '小安提示',
        message: message,
        confirmButtonText: '确定',
        showCancelButton: false
      })
        .then(() => {
          this.goSetCompany()
        })
        .catch(() => {})
    },
    getMySupervisedCompanyList() {
      this.$axios.superviseCompany
        .get_all_supervise_company_by_user(this.userInfo.companyId)
        .then(res => {
          this.companyObject = res.data.returnObject.data
          this.companyList = res.data.returnObject.data
          if (this.companyObject.toString() != '') {
            this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
          } else {
            this.showNotification()
          }
          this.companyList.forEach((e, i) => {
            if (e.companyFullCode == this.$route.query.companyCode || e.companyCode == this.$route.query.companyCode) {
              this.setCompany(e)
              const num = Math.ceil((i + 1) / 10)

              this.$storage.set('selectPageNum', num)
              this.pageNum = num
            }
          })
        })
        .catch(e => {})
    },
    async viewSelfCompany() {
      if (this.companyType == 0) {
        this.searchWord = this.selectInfo.companyCode
        await this.getAllCompanyList()
        const selfCompany = this.companyList[0]
        if (!selfCompany) {
          return
        }
        selfCompany.companyId = this.$storage.getUserInfo().companyId
        this.enterpriseSetCompany(selfCompany)
      } else {
        this.searchWord = ''
        this.$storage.set('enterpriseUserCompanyInfo', this.$storage.getSelectInfo())
        this.companyItem = {}
        this.companyList = []
        this.showPopover = false
        this.$emit('set-company', {
          companyName: this.$storage.getSelectInfo().companyName,
          companyFullCode: '',
          selfCompanyCode: this.$storage.getSelectInfo().companyCode
        })
        this.companyItem = this.$storage.getSelectInfo()
      }
    },
    addFollowCompanies() {
      this.$router.push('/follow/companies')
    }
  }
}
</script>

<style lang="scss">
.change-company-autocomplete_wrapper {
  width: 232px !important;
}
.companyPopper {
  display: inline-block;
  padding: 10px 0;
  .companyCode {
    display: inline-block;
    margin-right: 5px;
    color: #ffb148;
    outline: none;
    cursor: pointer;
  }
  .companyName {
    color: #ffb148;
    outline: none;
    cursor: pointer;
  }
  .i-caret {
    font-size: 14px;
    color: #ffb148;
  }
  .global-company-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    .company-type_tab {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      flex-shrink: 0;
      padding-left: 12px;

      .tab-item_wrapper {
        position: relative;
        cursor: pointer;
        padding: 10px 10px;
        &.active {
          color: #ffb148;
          background-color: #fdf5ea;
        }
      }
    }
    .company-content-box {
      width: 220px;
      padding: 0 12px;

      .search-page {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 10px;
        .input-search {
          width: 100%;
        }
        .icon-search {
          color: #ffb148;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .company-item-box {
        max-height: 400px;
        overflow: auto;
        padding-right: 4px;
        .company-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          padding-left: 10px;
          cursor: pointer;
          &:hover {
            color: #ffb148;
          }
        }
        .company-select {
          color: #ffb148;
        }
        .no-info-empty-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20%;
          height: 180px;
          .empty-img {
            width: 80%;
          }
          .empty-text {
            color: #d5d5d5;
          }
        }
      }
      .view-self-company {
        text-align: center;
        font-size: 14px;
        color: #ffb148;
        height: 24px;
        line-height: 24px;
        .btn-span {
          cursor: pointer;
        }
      }
    }
  }
}
#changeCompany {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  .top-pop-box {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .companyCode {
      margin-right: 5px;
      color: #ffb148;
      outline: none;
      cursor: pointer;
    }
    .companyName {
      color: #ffb148;
      outline: none;
      cursor: pointer;

      .placeholder {
        color: #aeaeae;
      }
      .i-caret {
        color: #ffb148 !important;
        transform: rotate(0deg);
        transition: transform 0.2s linear;

        &.pop-open {
          transform: rotate(180deg);
          transition: transform 0.2s linear;
        }
      }
    }
    .clearable {
      .i-caret {
        visibility: visible;
      }
      .clearable-icon {
        visibility: hidden;
      }

      &:hover {
        .i-caret {
          visibility: hidden;
        }
        .clearable-icon {
          visibility: visible;
        }
      }
    }
  }
  .top-pop-box-noLookup {
    .companyCode,
    .companyName {
      color: #ffffff;
      .i-caret {
        color: #ffffff !important;
      }
    }
  }
}
</style>
