/*
 * @Description: lei.wu@infaith.com.cn
 * @Author: lei.wu
 */
import Axios from '@/service/http'
import api from '@/service/api'

export default {
  // 用于前台首页展示违规案例类型的饼图
  violate_cases(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.violate_cases, data: params })
  },
  // 首页市场概览相关的数据 包含新规和监管动态
  market_overviews(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.market_overviews, data: params })
  },
  // 首页的预警 包含违规案例
  violation_cases(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.violation_cases, data: params })
  },
  // 首页的服务公司概览
  service_company_overviews(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.service_company_overviews, data: params })
  },
  // 市场概览-市场舆情数量
  sentiment_count(params) {
    return Axios({ method: 'GET', url: api.dashboardRegulation.sentiment_count, params: params })
  },
  // 预警-负面舆情数量
  negative_sentiment_count(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.negative_sentiment_count, data: params })
  },
  // 预警-异动数量
  transaction_count(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.transaction_count, data: params })
  },
  // 预警-盘中盘后异动数量
  transaction_count_stat(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.transaction_count_stat, data: params })
  },
  // 公司概览-总市值
  latest_market_value(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.latest_market_value, data: params })
  },
  // 获取公司市值图
  market_value_statistics(params) {
    return Axios({ method: 'POST', url: api.dashboardRegulation.market_value_statistics, data: params })
  }
}
