import Axios from '@/service/http'
import api from '@/service/api'
import Common from '@/utils/common'

export default {
  // 任务状态反馈
  get_tasks_progress(params = []) {
    return Axios({ method: 'GET', url: api.framework.get_tasks_progress, params: { ids: params.join(',') } })
  },
  // 获取文件临时访问地址
  get_file_url_by_oss_key(data) {
    return Axios({ method: 'POST', url: api.framework.get_file_url_by_oss_key, data })
  },
  // 根据fileId获取oss访问路径
  get_file_oss_address(params, specifiedToken) {
    return Axios({ method: 'GET', url: api.framework.get_file_oss_address, params: params, specifiedToken })
  },
  // 根据fileId获取文档记录
  get_document_file_record(params = {}, specifiedToken) {
    return Axios({ method: 'GET', url: api.framework.get_document_file_record, params, specifiedToken })
  },
  // 查询用户打开wps文档数量
  get_wps_sessions(params, specifiedToken) {
    return Axios({ method: 'GET', url: api.framework.wps_sessions, params, specifiedToken })
  },
  // 统计wps文档打开数量
  send_wps_sessions(params) {
    return Axios({ method: 'POST', url: api.framework.wps_sessions, params })
  },
  // 获取调用wps组件的地址接口
  wps_address(params, specifiedToken) {
    return Axios({ method: 'GET', url: api.framework.wps_address, params: params, specifiedToken })
  }
}
