import Api from '@/service/api'

export default {
  headerImg: `${Api.aliyunDomain}xiaoan/web_static/user/user_calendar_headerImg.png`,
  noEvent: `${Api.aliyunDomain}xiaoan/web_static/user/user_calendar_noEvent.jpg`,
  feishangshi: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_feishangshi.jpg`,
  sh: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_sh.jpg`,
  sz: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_sz.jpg`,
  add: `${Api.aliyunDomain}xiaoan/web_static/user/user_add.png`,
  cross: `${Api.aliyunDomain}xiaoan/web_static/user/user_cross.png`,
  fire: `${Api.aliyunDomain}xiaoan/web_static/user/user_fire.png`,
  folde: `${Api.aliyunDomain}xiaoan/web_static/user/user_folde.png`,
  iaccount: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-account.png`,
  iaccountact: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-account-act.png`,
  iadd: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-add.png`,
  iaddact: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-add-act.png`,
  icollect: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-collect.png`,
  icollectact: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-collect-act.png`,
  iperson: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-person.png`,
  ipersonact: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-person-act.png`,
  iquxiaodingyue: `${Api.aliyunDomain}xiaoan/web_static/user/user_i-quxiaodingyue.png`,
  nocollect: `${Api.aliyunDomain}xiaoan/web_static/user/user_nocollect.png`,
  noshare: `${Api.aliyunDomain}xiaoan/web_static/user/user_noshare.png`,
  nosubscribe: `${Api.aliyunDomain}xiaoan/web_static/user/user_nosubscribe.png`,
  pdf: `${Api.aliyunDomain}xiaoan/web_static/user/user_pdf.png`,
  person: `${Api.aliyunDomain}xiaoan/web_static/user/user_person.png`,
  qrcode: `${Api.aliyunDomain}xiaoan/web_static/user/user_qrcode.png`,
  qrcode2: `${Api.aliyunDomain}xiaoan/web_static/user/user_qrcode2.jpeg`,
  leftArrow: `${Api.aliyunDomain}xiaoan/web_static/user/user_leftArrow.png`,
  rightArrow: `${Api.aliyunDomain}xiaoan/web_static/user/user_rightArrow.png`,
  s1: `${Api.aliyunDomain}xiaoan/web_static/user/user_s1.png`,
  s2: `${Api.aliyunDomain}xiaoan/web_static/user/user_s2.png`,
  s3: `${Api.aliyunDomain}xiaoan/web_static/user/user_s3.png`,
  s4: `${Api.aliyunDomain}xiaoan/web_static/user/user_s4.png`,
  share: `${Api.aliyunDomain}xiaoan/web_static/user/user_share.png`,
  tick: `${Api.aliyunDomain}xiaoan/web_static/user/user_tick.png`,
  timg2x: `${Api.aliyunDomain}xiaoan/web_static/user/user_timg2x.png`,
  weixinJpg: `${Api.aliyunDomain}xiaoan/web_static/user/user_weixin.jpg`,
  weixinpng: `${Api.aliyunDomain}xiaoan/web_static/user/user_weixin_new.png`,
  calendar: {
    headerImg: `${Api.aliyunDomain}xiaoan/web_static/user/user_calendar_headerImg.png`,
    noEvent: `${Api.aliyunDomain}xiaoan/web_static/user/user_calendar_noEvent.jpg`
  },
  lookuptables: {
    feishangshi: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_feishangshi.jpg`,
    sh: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_sh.jpg`,
    sz: `${Api.aliyunDomain}xiaoan/web_static/user/user_lookuptables_sz.jpg`
  },
  set: `${Api.aliyunDomain}xiaoan/web_static/user/dashboardset.png`
}
