<!--
 * @Description: 员工选择输入框组件
-->
<template>
  <div class="vxa-staffSelectInput-input">
    <div :class="{ 'input-container': true, disabled: disabled }">
      <div class="vxa-staffSelectInput-innerInput" :style="{ 'padding-right': 16 * btnText.length + 24 + 'px' }">
        <div class="item-container">
          <template v-if="multiple">
            <div ref="valueListRef">
              <template v-if="dataInited">
                <div
                  :class="{
                    item: true,
                  }"
                  v-for="(item, i) in valueList"
                  :key="item.userId"
                >
                  <span class="item-text">
                    <template v-if="showCertStatus && item.userCertStatusEnum">
                      <xa-tooltip>
                        <div slot="content">
                          <span>
                            {{ userCertStatus[item.userCertStatusEnum].label }}
                            <span v-if="item.userCertStatusEnum === 'AUDIT_UN_PASS'">
                              ：{{ item.auditFailReason || '暂无失败信息' }}
                            </span>
                          </span>
                        </div>
                        <img
                          v-if="item.userCertStatusEnum"
                          :src="userCertStatus[item.userCertStatusEnum].url"
                          :alt="userCertStatus[item.userCertStatusEnum].label"
                        />
                      </xa-tooltip>
                    </template>
                    {{ item.realName || item.userRealName || item.name }}
                    <span :class="{ item_invalid: item.invalid }">
                      <template v-if="extraInfo && extraInfo.key"> ({{ item | extraText(extraInfo) }}) </template>
                    </span>
                  </span>
                  <i
                    v-if="checkDeleteIconShow(item)"
                    class="iconfont web-close-x"
                    @click.stop="multipleDeleteItem(i)"
                  ></i>
                </div>
                <div class="disabled-cover" v-if="disabled"></div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="item" :class="{ item_invalid: valueList[0].invalid }" v-if="valueList[0] && dataInited">
              <span class="item-text">
                {{ valueList[0].realName || valueList[0].userRealName || valueList[0].name }}
                <template v-if="extraInfo && extraInfo.key"> ({{ valueList[0] | extraText(extraInfo) }}) </template>
              </span>
              <i class="el-icon-close" v-if="!disabled" @click="deleteAll"></i>
            </div>
          </template>
        </div>
        <span
          v-if="multiple"
          :class="{ 'select-btn': true, 'select-btn_disabled': disabled }"
          :disabled="disabled"
          @click="selectClick"
        >
          {{ btnText }}
        </span>
        <span class="select-btn" :class="{ 'select-btn_disabled': disabled }" @click="selectClick" v-else> 选择 </span>
      </div>
      <div
        class="deleteAll"
        v-if="multiple && valueList.length > 0 && !disabled"
        :style="{ right: 16 * btnText.length + 8 + 'px' }"
        @click="deleteAll"
      >
        <i class="iconfont web-qingchu"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'

export default {
  name: 'VxaStaffInput',
  props: {
    size: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    // 标识数据是否初始化完毕，外部传入数据需要在main.vue init内进行匹配补全信息
    dataInited: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: '选择'
    },
    // 需要展示出来的额外信息，该信息同时会被视为必要信息，若不存在会有标红提示
    extraInfo: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 显示用户认证状态
    showCertStatus: {
      type: Boolean,
      default: false
    },
    // 固定添加人员 人员列表，且不可删除
    // [id1,id2]
    fixedUserList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      userCertStatus: {
        UN_AUTHENTICATION: {
          type: 'UN_AUTHENTICATION',
          label: '未认证',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/notCertified.png`
        },
        VERIFIED: {
          type: 'VERIFIED',
          label: '认证成功',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/success.png`
        },
        COMMIT_AUDIT: {
          type: 'COMMIT_AUDIT',
          label: '审核中',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/examining.png`
        },
        AUDIT_UN_PASS: {
          type: 'AUDIT_UN_PASS',
          label: '认证失败',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/fail.png`
        },
        COMPLETE_MOBILE: {
          type: 'COMPLETE_MOBILE',
          label: '待补全手机号',
          url: `${this.$api.aliyunDomain}xiaoan/web_static/icon/success.png`
        }
      }
    }
  },
  computed: {
    valueList: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  filters: {
    extraText(item, extraInfo) {
      let text
      if (extraInfo.key === 'mobile') {
        text = `${item.areaCode}-${item.mobile}`
      } else {
        text = item[extraInfo.key]
      }
      return text || `${extraInfo.name || extraInfo.key}缺失`
    }
  },
  mounted() {
    if (!this.multiple) {
      return
    }
    const el = this.$refs.valueListRef
    Sortable.create(el, {
      animation: 150,
      onUpdate: evt => {
        this.positionExchange(this.valueList, evt.oldIndex, evt.newIndex)
      }
    })
  },
  methods: {
    positionExchange(list, oldIndex, newIndex) {
      if (list.length <= oldIndex || list.length <= newIndex) {
        return false
      }
      const item = list[oldIndex]
      list.splice(oldIndex, 1)
      list.splice(newIndex, 0, item)
    },
    multipleDeleteItem(index) {
      this.valueList.splice(index, 1)
      this.$emit('delete')
    },
    deleteAll() {
      if (this.fixedUserList?.length) {
        this.valueList = this.valueList.filter(item => {
          return this.fixedUserList.some(userId => userId === item.userId)
        })
      } else {
        this.valueList.splice(0)
      }
      this.$emit('delete')
    },
    selectClick() {
      if (this.disabled) return
      this.$emit('selectClick')
    },
    checkDeleteIconShow(item) {
      if (this.disabled) {
        return false
      }
      if (this.fixedUserList.some(userId => userId === item.userId)) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.vxa-staffSelectInput-input {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  min-height: 32px;
  text-align: left;
  font-size: 0;

  .input-container {
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    flex: 1;

    &:hover {
      .deleteAll {
        display: block;
      }
    }
  }
  .disabled {
    background-color: #f6f6f6;
  }
  .vxa-staffSelectInput-innerInput {
    padding: 3px 35px 0 8px;
    color: rgba(174, 174, 174, 1);
    vertical-align: middle;
    max-height: 88px;
    overflow: auto;

    .item-container {
      position: relative;
      min-height: 26px;
    }
  }
  .item {
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 5px;
    max-width: 100%;
    line-height: 22px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    color: #666666;
    background-color: #f4f4f5;
    border: 1px solid #e9e9eb;
    margin-right: 8px;
    margin-bottom: 3px;
    cursor: move;

    .item-text {
      max-width: 180px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      img {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }

      .item_invalid {
        color: #f00;
      }
    }
    i {
      cursor: pointer;
      &:hover {
        color: #ffb148;
      }
    }
  }
  .deleteAll {
    display: none;
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 12px;
    line-height: 30px;
    color: rgba(174, 174, 174, 1);
    i {
      font-size: 12px;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        color: #ffb148;
      }
    }
  }
  .select-btn {
    position: absolute;
    height: 100%;
    right: 10px;
    top: 0;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    color: #ffb148;

    &_disabled {
      color: #e3e3e3;
      cursor: not-allowed;
    }
  }
  .multiple-btn {
    margin-left: 5px;
  }
  .is-disabled {
    border: 1px solid transparent;
  }
  .disabled-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
  }
}
</style>
