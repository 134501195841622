<!--
 * @Description: 回到顶部
-->
<template>
  <div id="scroll-component-up">
    <el-backtop :target="target" :bottom="38" :right="6">
      <div class="up"><i class="iconfont web-dingbu"></i></div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'scrollerUp',
  props: {
    target: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
#scroll-component-up {
  .el-backtop {
    width: 44px;
    height: 44px;
    background: transparent;
    box-shadow: none;
    .up {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: #fff;
      box-shadow: 0 0 12px 6px rgba(28, 31, 35, 0.06);
      cursor: pointer;
      i {
        color: #000;
        display: block;
        font-size: 26px;
      }
    }
  }
}
</style>
