const rTelphone = /^(13|14|15|16|17|18|19)[0-9]{9}$/
const rImgCode = /^\d{4}$/
const rLongImgCode = /^\d{6}$/
const invalidPassword = /[^a-zA-Z\d~!@#$%^&*_]/
const rPassword = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d~!@#$%^&*_]{8,20}$/
const rBlank = /^\s*$/
const rmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
const rIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
const rnumEnglish = /^[a-z0-9]+$/i
const rUrl = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i
const rChinese = /[\u4e00-\u9fa5]/gm
const rLoginPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/ // 8到20位数字字母
const regx = {
  _tel: rTelphone,
  _img: rImgCode,
  _pwd: rPassword,
  _blank: rBlank,
  _mail: rmail,
  _idCard: rIdCard,
  _numEnglish: rnumEnglish,
  _url: rUrl,
  _chinese: rChinese,
  _loginPassword: rLoginPassword,
  invalidPassword,
  telphone(val) {
    if (val != null) {
      val += ''
      return rTelphone.test(val)
    }
    return false
  },
  imgCode(val) {
    if (val != null) {
      val += ''
      return rImgCode.test(val)
    }
  },
  longImgCode(val) {
    if (val != null) {
      val += ''
      return rLongImgCode.test(val)
    }
  },
  password(val) {
    if (val != null) {
      val += ''
      return rPassword.test(val)
    }
  },
  blank(val) {
    return rBlank.test(val)
  },
  mail(val) {
    if (val != null) {
      val += ''
      return rmail.test(val)
    }
  },
  idCard(val) {
    if (val != null) {
      val += ''
      return rIdCard.test(val)
    }
  },
  numEnglish(val) {
    if (val != null) {
      val += ''
      return rnumEnglish.test(val)
    }
  },
  url(val) {
    if (val != null) {
      val += ''
      return rUrl.test(val)
    }
  },
  chinese(val) {
    if (val != null) {
      val += ''
      return rChinese.test(val)
    }
  },
  loginPassword(val) {
    if (val != null) {
      val += ''
      return rLoginPassword.test(val)
    }
  },
  int1: /^(?!0$|0\.00|0\.0|0\d+$)([1-9]?\d+(\.\d*)|(\\s&&[^\\f\\n\\r\\t\\v])|([1-9]*[1-9][0-9]*)?)$/,
  integer: /^[0-9]+$/,
  float1: /^[0-9]+.?[0-9]*$/,
  float2: /^[0-9]+(.[0-9]{1,2})?$/, // 验证有两位小数的正实数
  float4: /^[0-9]+(.[0-9]{1,4})?$/ // 验证有4位小数的正实数
}

export default regx
