<!--
 * @Description: 选择监管公司或服务公司
-->
<template>
  <div id="regulatorCompany">
    <div class="top-pop-box" :class="{ 'top-pop-box-noLookup': haveHover }">
      <span class="companyCode" v-if="showCode">{{ companyItem.companyFullCode || '' }}</span>
      <el-popover :placement="placement" v-model="showPopover" width="232" trigger="click" popper-class="companyPopper">
        <span slot="reference" class="companyName">
          {{ companyItem.companyName }}
          <i class="el-icon-caret-bottom i-caret" v-if="companyItem.companyName"></i>
        </span>
        <div class="company-content">
          <div class="search-page">
            <xa-input
              v-model="searchWord"
              class="input-search"
              size="mini"
              :maxlength="100"
              @keyup.enter.native="search"
            >
              <i slot="suffix" class="el-input__icon el-icon-search icon-search" @click.stop="search"></i>
            </xa-input>
            <v-page
              class="vpage"
              :flip="pageNum"
              :total="total"
              v-on:addition="addition"
              v-on:subtraction="subtraction"
            ></v-page>
          </div>
          <div
            class="company-item"
            v-for="(item, index) in companyList"
            :key="index"
            v-show="showCompany(index)"
            @click="setCompany(item)"
            :class="{ 'company-select': item.companyCode == companyItem.companyCode }"
          >
            <span>{{ item.companyFullCode }}</span>
            <span>{{ item.companyName }}</span>
          </div>
        </div>
      </el-popover>
    </div>
    <xa-dialog
      title="提示"
      custom-class="regulatorCompany"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <span>你还未设置监管公司，前往设置。</span>
      <span slot="footer" class="dialog-footer">
        <xa-button style="min-width: 60px; height: 28px" type="primary" size="mini" @click="goSetCompany"
          >确 定</xa-button
        >
      </span>
    </xa-dialog>
  </div>
</template>

<script>
import page from '@/components/pagination/customPage.vue'

export default {
  name: 'regulatorCompany',
  data() {
    return {
      userInfo: {},
      dialogVisible: false,
      showPopover: false,
      searchWord: '',
      companyItem: {},
      companyList: [], // 筛选 搜索后的数据
      companyObject: [], // 接口完整数据
      pageNum: 1,
      pageSize: 10,
      total: 0,
      userType: 0
    }
  },

  components: {
    'v-page': page
  },
  props: {
    placement: {
      type: String,
      default: 'bottom'
    },
    showCode: {
      type: Boolean,
      default: false
    },
    haveHover: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.userInfo = this.$storage.getUserInfo()
    if (this.$auth.isSuperviseUser()) {
      this.userType = 0 // 监管用户组
      this.getAllSuperviseCompanyByUser('create')
    }
    if (this.$auth.isInfaithUser()) {
      this.userType = 1 // 信工咨询组
      this.getMyInfaithCompanyList('create')
    }
  },
  methods: {
    goSetCompany() {
      this.$router.push('/dashboard/regulator')
    },
    search() {
      this.companyList = []
      this.companyObject.forEach(item => {
        const regx = new RegExp('^' + this.searchWord + '', 'i')
        if (
          item.companyFullCode.indexOf(this.searchWord) > -1 ||
          item.companyName.indexOf(this.searchWord) > -1 ||
          regx.test(item.companyPinyin)
        ) {
          this.companyList.push(item)
        }
      })
      this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
      this.pageNum = 1
    },
    showCompany(index) {
      const start = (this.pageNum - 1) * this.pageSize
      const end = this.pageNum * this.pageSize
      if (index >= start && index < end) {
        return true
      } else {
        return false
      }
    },
    setCompany(item) {
      this.$storage.set('regulator_company', item.companyFullCode)
      this.showPopover = false
      this.companyItem = item
      this.$router.replace({ query: { companyCode: this.companyItem.companyFullCode } })
      this.$emit('set-company', item)
      const data = {
        companyName: item.companyName,
        companyId: item.id,
        companyCode: item.companyCode,
        companyFullCode: item.companyFullCode
      }
      this.$storage.set('selectInfo', data)
    },
    // 页数加一
    addition() {
      if (this.pageNum < this.total) {
        this.pageNum += 1
      }
    },
    // 页数减一
    subtraction() {
      if (this.pageNum > 1) {
        this.pageNum -= 1
      }
    },
    // 获取所有监管公司
    getAllSuperviseCompanyByUser() {
      const storageCompanyCode = this.$storage.get('regulator_company')

      this.$axios.superviseCompany.get_all_supervise_company_by_user(this.userInfo.companyId).then(res => {
        this.companyObject = res.data.returnObject.data
        this.companyList = res.data.returnObject.data
        if (this.companyObject.toString() != '') {
          this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
          if (!this.$route.query.companyCode) {
            this.companyObject.forEach(item => {
              if (storageCompanyCode == item.companyFullCode) {
                this.companyItem = item
              }
            })

            if (!this.companyItem.companyFullCode) {
              this.companyItem = this.companyList[0]
            }

            this.companyItem = Object.assign({}, this.companyItem)

            this.$router.replace({ query: { companyCode: this.companyItem.companyFullCode } })
          } else {
            this.companyItem = this.companyList[0]
            this.companyObject.forEach(item => {
              if (this.$route.query.companyCode == item.companyFullCode) {
                this.companyItem = item
              }
            })
          }
          this.$storage.set('regulator_company', this.companyItem.companyFullCode)
        } else {
          this.dialogVisible = true
        }
      })
    },
    // 获取所有服务公司
    getMyInfaithCompanyList() {
      const storageCompanyCode = this.$storage.get('regulator_company')

      this.$axios.enterpriseCompany.get_my_infaith_company_list().then(res => {
        this.companyObject = res.data.returnObject.data
        this.companyList = res.data.returnObject.data
        if (this.companyObject.toString() != '') {
          this.total = this.$common.totalPage(this.companyList.length, this.pageSize)
          if (!this.$route.query.companyCode) {
            this.companyObject.forEach(item => {
              if (storageCompanyCode == item.companyFullCode) {
                this.companyItem = item
              }
            })
            if (!this.companyItem.companyFullCode) {
              this.companyItem = this.companyList[0]
            }

            this.companyItem = Object.assign({}, this.companyItem)

            this.$router.replace({ query: { companyCode: this.companyItem.companyFullCode } })
          } else {
            this.companyItem = this.companyList[0]
            this.companyObject.forEach(item => {
              if (this.$route.query.companyCode == item.companyFullCode) {
                this.companyItem = item
              }
            })
          }
          this.$storage.set('regulator_company', this.companyItem.companyFullCode)
        } else {
          this.dialogVisible = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
.companyPopper {
  display: inline-block;
  .companyCode {
    display: inline-block;
    margin-right: 5px;
    color: #ffb148;
    outline: none;
    cursor: pointer;
  }
  .companyName {
    color: #ffb148;
    outline: none;
    cursor: pointer;
  }
  .i-caret {
    color: #ffb148;
  }
  .company-content {
    width: 100%;
    padding: 0 10px;
    .search-page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 10px;
      .input-search {
        width: 100px;
      }
      .icon-search {
        color: #ffb148;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .company-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      cursor: pointer;
      &:hover {
        color: #ffb148;
      }
    }
    .company-select {
      color: #ffb148;
    }
  }
}
#regulatorCompany {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  .top-pop-box {
    .companyCode {
      margin-right: 5px;
      color: #ffb148;
      outline: none;
      cursor: pointer;
    }
    .companyName {
      color: #ffb148;
      outline: none;
      cursor: pointer;
      .i-caret {
        color: #ffb148 !important;
      }
    }
  }
  .top-pop-box-noLookup {
    .companyCode,
    .companyName {
      color: #ffffff;
      .i-caret {
        color: #ffffff !important;
      }
    }
  }
  .el-dialog--center .el-dialog__body {
    padding: 15px 25px 10px;
  }
  .el-dialog__footer {
    padding: 0 10px 10px;
  }
}
</style>
