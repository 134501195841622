import Api from '@/service/api'

export default {
  attent: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_attent.png`,
  attentyet: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_attentyet.png`,
  closefoc: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_closefoc.png`,
  edit: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_edit.png`,
  edittopic: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_edittopic.png`,
  foc: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_foc.png`,
  headerImg: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_headerImg.png`,
  hotTopic: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_hotTopic.png`,
  invite: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_invite.png`,
  inviteyet: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_inviteyet.png`,
  maTop: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_maTop.png`,
  questionadd: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_question-add.png`,
  questionaddyet: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_question-addyet.png`,
  refresh: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_refresh.png`,
  relatedQuestion: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_relatedQuestion.png`,
  rz: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_rz.png`,
  show: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_show.png`,
  v: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_v.png`,
  write: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_write.png`,
  xhAdmin: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_Admin.png`,
  xhCollect: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_Collect.png`,
  xhLaud: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_Laud.png`,
  xhUser: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_User.png`,
  zhan3x: `${Api.aliyunDomain}xiaoan/web_static/xinhu/xh_zhan3x.png`
}
