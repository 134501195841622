import Api from '@/service/api'

export default {
  comment: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_comment.png`,
  copy: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_copy.png`,
  daishengxiao: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_daishengxiao.png`,
  delete: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_delete.png`,
  dialog: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_dialog.png`,
  mynote: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_mynote.png`,
  reply: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_reply.png`,
  sidebarnode: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_sidebarnode.png`,
  yishixiao: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_yishixiao.png`,
  PhotobyWilliamIven: `${Api.aliyunDomain}xiaoan/web_static/rule/rule_photo_by_william_Iven.png`
}
